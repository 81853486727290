import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import DateFnsUtils from '@date-io/date-fns';

import { XGrid } from '@material-ui/x-grid';
import { makeStyles, Fab } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';

import Tooltip from 'components/Tooltip';
import QuickFilterSearch from 'components/RMSSearch/components/QuickFilterSearch';

import {
  searchVehicleRegistrations,
  searchSpecificVehicle,
  getPtsPerson,
  getPtsPlace,
} from 'reducers/SearchReducer';
import {
  showDisplayDataWindow,
  showAddVehicle,
  showEditVehicle,
  showVehicleEditReg,
} from 'reducers/PersonDialogsReducer';
import { handleError } from 'reducers/ErrorReducer';
import { showSpinner, hideSpinner } from 'reducers/UiReducer';
import { displayDate, displayDateTime } from 'reducers/TimeReducer';

const useStyles = makeStyles((theme) => ({
  filters: {
    display: 'flex',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
    '& > div': {
      marginRight: theme.spacing(2),
    },
  },
  gridWrap: {
    width: '100%',
    boxSizing: 'border-box',
    height: 600,
  },
  filterWrap: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  filter: {
    width: '100%',
    marginBottom: '8px',
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
}));

const bool = (params) => (params.value ? <CheckIcon /> : <span></span>);

const regColumns = [
  { field: 'VehicleID', headerName: 'Vehicle ID', width: 150 },
  { field: 'PlateNumber', headerName: 'Plate Number', width: 150 },
  { field: 'PlateState', headerName: 'Plate State', width: 150 },
  {
    field: 'PlateExpiration',
    headerName: 'Plate Expiration',
    width: 150,
    valueFormatter: (params) => displayDate(params.value),
    format: 'date',
  },
  { field: 'VIN', headerName: 'VIN', width: 150 },
  { field: 'PartyID', headerName: 'Party ID', width: 150 },
  { field: 'PlateType', headerName: 'Plate Type', width: 150 },
  {
    field: 'Created',
    headerName: 'Created',
    width: 200,
    valueFormatter: (params) => displayDateTime(params.value),
  },
  { field: 'CreatedBy', headerName: 'Created By', width: 150 },
  {
    field: 'Updated',
    headerName: 'Updated',
    width: 200,
    valueFormatter: (params) => displayDateTime(params.value),
  },
  { field: 'UpdatedBy', headerName: 'Updated By', width: 150 },
];

function Registrations(props) {
  const classes = useStyles();
  const { ptsVehicleID, dataUpdate } = props;

  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [selection, setSelection] = useState(null);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (dataUpdate && dataUpdate.type === 'vehicle-reg') fetchData();
  }, [dataUpdate]);

  const fetchData = async () => {
    setLoaded(false);
    setSelection(null);
    try {
      const regData = await searchVehicleRegistrations(ptsVehicleID);
      const vehicleData = await searchSpecificVehicle(ptsVehicleID);
      const processed = await processData(regData, vehicleData);
      setRows(processed);
    } catch (err) {
      props.handleError(err);
    }
    setLoaded(true);
  };

  const processData = async (regData, vehData) => {
    const processed = regData.map(async (row, idx) => {
      const vehicleData = { ...vehData[0] };
      const data = Object.assign(vehicleData, row);
      let partyId = await getPartyID(row);
      return {
        ...data,
        PartyID: partyId,
        id: idx,
      };
    });
    return Promise.all(processed);
  };

  const getPartyID = async (reg) => {
    const { PartyType, ptsParentID } = reg;
    if (PartyType === 'Person' || PartyType === 'Caller') {
      const party = await getPtsPerson(ptsParentID);
      return party[0].PersonID;
    } else {
      const party = await getPtsPlace(ptsParentID);
      return party.PlaceID;
    }
  };

  const addReg = () => {
    props.showVehicleEditReg({ ptsVehicleID, type: 'vehicle' });
  };

  const editReg = () => {
    props.showVehicleEditReg({ ...rows[selection], type: 'vehicle' });
  };

  return (
    <div className="m-4">
      <h5>Vehicle Registrations</h5>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <div className={classes.filterWrap}>
          <div className={classes.filterWrap}>
            <QuickFilterSearch rows={rows} columns={regColumns} setFilteredRows={setFilteredRows} />
          </div>
          <div className={classes.actions}>
            <Fab
              onClick={addReg}
              size="small"
              color="secondary"
              disabled={props.parentPermission && !props?.parentPermission.Edit}>
              <AddIcon />
            </Fab>
            {selection !== null && (
              <Tooltip title="Edit Vehicle">
                <Fab
                  className="ml-2"
                  size="small"
                  color="secondary"
                  onClick={editReg}
                  disabled={props.parentPermission && !props?.parentPermission.Edit}>
                  <EditIcon />
                </Fab>
              </Tooltip>
            )}
          </div>
        </div>
      </MuiPickersUtilsProvider>
      <div className={classes.gridWrap}>
        <XGrid
          columns={regColumns}
          rows={filteredRows}
          loading={!loaded}
          rowHeight={38}
          disableMultipleSelection={true}
          showToolbar
          disableColumnFilter
          onSelectionModelChange={(newSelection) => {
            setSelection(newSelection.selectionModel[0]);
          }}
          onColumnVisibilityChange={(col) => setHiddenColumns([...hiddenColumns, col.field])}
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    dataUpdate: state.dataUpdate,
  };
};

export default connect(mapStateToProps, {
  showSpinner,
  hideSpinner,
  handleError,
  showDisplayDataWindow,
  showAddVehicle,
  showEditVehicle,
  showVehicleEditReg,
})(Registrations);
