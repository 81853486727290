import SimpleSchema from 'simpl-schema';

import store from '../../config/configureStore';
import { getCategoryOfCode } from 'utils/functions';

SimpleSchema.setDefaultMessages({
  messages: {
    en: {
      'NIBRS-2019.2-102': 'When Substance Unit must be 1 when the Drug Measure is XX.',
      'NIBRS-2019.2-101':
        'When Substance Quantity is provided, then Drug Category and Drug Measure must be provided.',
      'NIBRS-2019.2-104':
        'When Substance Unit is provided, then Drug Category and Drug Quantity must be provided.',
      'NIBRS-2019.2-97':
        'No more than three Substance records can be provided in an Incident Report.',
      'NIBRS-2019.2-103':
        'When Substance Unit code NP-Number of Plants is only valid for Drug Category of (E, G, or K).',
      'NIBRS-2019.2-92':
        'When the Offense code = 240 and Item Status = Stolen, and the Offense Attempted is false to indicate the offense had been completed, then the number of Property Quantity is required.',
      'NIBRS-2019.2-95':
        'When the Offense code = 240 and Item Status = Recovered, and the Offense Attempted is false to indicate the offense had been completed, then the number of Property Quantity is required.',
      'NIBRS-2019.2-99': 'Only one Drug Category can be provided with the Drug Category = X.',
      'NIBRS-2019.2-70 Type':
        'When the Offense code indicates Drug/Narcotic Violations (35A) and the Attempted/Completed is Completed with a Substance entity Property Loss of NONE, then Drug Category is required',
      'NIBRS-2019.2-70 Quantity':
        'When the Offense code indicates Drug/Narcotic Violations (35A) and the Attempted/Completed is Completed with a Substance entity Property Loss of NONE, then Drug Quantity must be omitted.',
      'NIBRS-2019.2-70 Measure':
        'When the Offense code indicates Drug/Narcotic Violations (35A) and the Attempted/Completed is Completed with a Substance entity Property Loss of NONE, then Drug Measure must be omitted.',
    },
  },
});

export const propertiesSchema = new SimpleSchema({
  substanceQuantity: {
    type: SimpleSchema.Integer,
    optional: true,
    custom() {
      if (this.obj.property.values?.parentType?.toLowerCase() === 'substance') {
        const codeDrugMeasurements = store.store.getState().dictionary.codeDrugMeasurements;
        const quantityMeasureCategory = getCategoryOfCode(
          codeDrugMeasurements,
          this.obj.property?.values.quantityMeasure
        );
        // NIBRS-2019.2-102: Property Drug Quantity
        if (quantityMeasureCategory === 'xx') {
          if (this.value !== 1) {
            return 'NIBRS-2019.2-102';
          }
        }

        // NIBRS-2019.2-104: Property Drug Measure
        if (this.obj.property.values.quantityMeasure) {
          if (!this.obj.property.values.quantity) {
            return 'NIBRS-2019.2-104';
          }
        }
      }
    },
  },
  quantityMeasure: {
    type: String,
    optional: true,
    custom() {
      const codePropertyLossType = store.store.getState().dictionary.codePropertyLossType;
      const propertyStatusCategory = getCategoryOfCode(
        codePropertyLossType,
        this.obj.incProperty?.values?.status
      );

      if (this.obj.property.values?.parentType?.toLowerCase() === 'substance') {
        // NIBRS-2019.2-101: Property Drug Quantity
        if (this.obj.property.values.quantity) {
          if (!this.value) {
            return 'NIBRS-2019.2-101';
          }
        }
      }

      // NIBRS-2019.2-70: Property Lose Type
      if (this.obj.incPropertyOffense) {
        if (
          this.obj.incPropertyOffense.values.statuteDetails?.FBICode === '35A' &&
          this.obj.incPropertyOffense.values.offenseAttempted &&
          propertyStatusCategory === 'none' &&
          this.value
        ) {
          return 'NIBRS-2019.2-70 Measure';
        }
      }
    },
  },
  quantity: {
    type: SimpleSchema.Integer,
    optional: true,
    custom() {
      if (this.obj.property.values?.parentType?.toLowerCase() === 'item') {
        const codePropertyLossType = store.store.getState().dictionary.codePropertyLossType;
        const propertyStatusCategory = getCategoryOfCode(
          codePropertyLossType,
          this.obj.incProperty?.values?.status
        );

        if (this.obj.incPropertyOffense) {
          if (
            this.obj.incPropertyOffense.values.statuteDetails?.FBICode === '240' &&
            propertyStatusCategory === 'stolen' &&
            !this.obj.incPropertyOffense.values.offenseAttempted &&
            (!this.value || this.value === 0)
          ) {
            return 'NIBRS-2019.2-92';
          }

          if (
            this.obj.incPropertyOffense.values.statuteDetails?.FBICode === '240' &&
            propertyStatusCategory === 'recovered' &&
            !this.obj.incPropertyOffense.values.offenseAttempted &&
            (!this.value || this.value === 0)
          ) {
            return 'NIBRS-2019.2-95';
          }

          // NIBRS-2019.2-70: Property Lose Type
          if (
            this.obj.incPropertyOffense.values.statuteDetails?.FBICode === '35A' &&
            this.obj.incPropertyOffense.values.offenseAttempted &&
            propertyStatusCategory === 'none' &&
            this.value
          ) {
            return 'NIBRS-2019.2-70 Quantity';
          }
        }
      }
    },
  },
  substanceType: {
    type: String,
    optional: true,
    custom() {
      const codeDrugMeasurements = store.store.getState().dictionary.codeDrugMeasurements;
      const codeSubstanceCategory = store.store.getState().dictionary.codeSubstanceCategory;
      const codePropertyLossType = store.store.getState().dictionary.codePropertyLossType;
      const quantityMeasureCategory = getCategoryOfCode(
        codeDrugMeasurements,
        this.obj.property?.values.quantityMeasure
      );

      let substanceTypeCategory;
      if (this.value !== undefined)
        substanceTypeCategory = getCategoryOfCode(codeSubstanceCategory, this.value);
      const propertyStatusCategory = getCategoryOfCode(
        codePropertyLossType,
        this.obj.incProperty?.values?.status
      );

      // NIBRS-2019.2-99: Property Drug Type
      if (substanceTypeCategory === 'x') {
        let countTypeX = 1;
        this.obj.properties?.forEach((property) => {
          if (
            property?.propertyDetails.values?.parentType?.toLowerCase() === 'substance' &&
            property?.propertyDetails.values?.substanceType === 'X' &&
            property.propertyDetails.incIncidentPropertyId !==
              this.obj.property.incIncidentPropertyId
          ) {
            countTypeX++;
          }
        });
        if (countTypeX > 1) {
          return 'NIBRS-2019.2-99';
        }
      }
      // NIBRS-2019.2-103: Property Drug Measure
      if (quantityMeasureCategory === 'np') {
        if (!['e', 'g', 'k'].includes(substanceTypeCategory)) {
          return 'NIBRS-2019.2-103';
        }
      }

      if (this.obj.property.values?.parentType?.toLowerCase() === 'substance') {
        // NIBRS-2019.2-101: Property Drug Quantity
        if (this.obj.property.values.quantity) {
          if (!this.value) {
            return 'NIBRS-2019.2-101';
          }
        }
      }

      // NIBRS-2019.2-104: Property Drug Measure
      if (this.obj.property.values.quantityMeasure) {
        if (!this.obj.property.values.substanceType) {
          return 'NIBRS-2019.2-104';
        }
      }

      // NIBRS-2019.2-70: Property Lose Type
      if (this.obj.incPropertyOffense) {
        if (
          this.obj.incPropertyOffense.values.statuteDetails?.FBICode === '35A' &&
          this.obj.incPropertyOffense.values.offenseAttempted &&
          propertyStatusCategory === 'none' &&
          !this.obj.property.values.substanceType
        ) {
          return 'NIBRS-2019.2-70 Type';
        }
      }
    },
  },
  propertySubstance: {
    type: String,
    optional: true,
    custom() {
      //NIBRS-2019.2-97: Property Drug Type
      // if (this.obj.properties.length !== 0) {
      //   let substanceCount = 0;
      //   for (let p of this.obj.properties) {
      //     if (p?.propertyDetails?.values?.parentType?.toLowerCase() === 'substance')
      //       substanceCount = substanceCount + 1;
      //   }

      //   if (substanceCount > 3) {
      //     return 'NIBRS-2019.2-97';
      //   }
      // }
    },
  },
});
