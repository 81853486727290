import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function iconsagencytypemedicalIcon(props) {
  return (
    <SvgIcon viewBox="0 0 50 50" {...props}>
      <path d="M22 5a1 1 0 0 0-1 1v12.102L10.496 12.09a1.004 1.004 0 0 0-.76-.098 1.01 1.01 0 0 0-.605.469L6.15 17.67a.998.998 0 0 0 .37 1.363L16.947 25 6.523 30.967a1 1 0 0 0-.37 1.363l2.98 5.21a.993.993 0 0 0 1.363.37L21 31.898V44a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V31.898l10.504 6.012c.23.13.5.167.76.098.255-.07.473-.24.605-.469l2.98-5.209a.998.998 0 0 0-.37-1.363L33.053 25l10.424-5.967a1 1 0 0 0 .37-1.363l-2.98-5.21a.993.993 0 0 0-1.363-.37L29 18.102V6a1 1 0 0 0-1-1h-6z" />
    </SvgIcon>
  );
}

export default iconsagencytypemedicalIcon;
