import React from 'react';

function PoliceBadgeIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" {...props}>
      <g transform="translate(0.000000,50.000000) scale(0.100000,-0.100000)" stroke="none">
        <path d="M62 477 c-20 -21 -21 -27 -11 -56 7 -18 9 -54 5 -82 -3 -27 -9 -72 -12 -99 -13 -100 31 -162 149 -215 l57 -25 57 25 c118 53 162 115 149 215 -3 27 -9 72 -12 99 -4 29 -2 64 5 82 10 30 9 36 -13 57 -20 21 -27 22 -46 12 -13 -7 -42 -10 -74 -6 -28 3 -59 5 -68 5 -98 -3 -135 -2 -148 4 -11 5 -23 0 -38 -16z m209 -149 c11 -27 21 -34 52 -38 l39 -5 -30 -28 c-26 -25 -29 -33 -21 -60 11 -40 7 -43 -30 -21 -29 17 -32 17 -60 1 -39 -23 -41 -22 -34 19 5 29 1 40 -22 64 l-27 29 38 3 c33 3 41 8 53 36 8 17 17 32 21 32 4 0 13 -15 21 -32z" />
      </g>
    </svg>
  );
}

export default PoliceBadgeIcon;
