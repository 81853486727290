import React, { useState, useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Tooltip from 'components/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import { showSpinner, hideSpinner } from 'reducers/UiReducer';
import { handleError } from 'reducers/ErrorReducer';
import CheckIcon from '@material-ui/icons/Check';
import RMSXGrid from 'components/RMSXGrid';
import QuickFilterSearch from 'components/RMSSearch/components/QuickFilterSearch';
import { getPeopleAssociate, createPeopleAssociate } from 'reducers/SearchReducer';
import formStyles, { gridStyle } from 'utils/formStyles';
import { notifyDataUpdate } from 'reducers/DataUpdateReducer';
import PrintsSearch2 from 'components/RMSSearch/components/PrintsSearch2';
import { showPersonAssociate } from 'reducers/PersonDialogsReducer';
import { getService } from 'reducers/service';
import { notify } from 'reducers/NotifierReducer';
import { getFullPermissions } from 'reducers/PermissionsReducer';

const useStyles = makeStyles((theme) => ({
  ...formStyles,
  item: gridStyle(150, 220),
  lookup: gridStyle(300, 550),
  wrap: {
    padding: theme.spacing(3),
  },
  filterWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(2),
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
  lookupActions: {
    marginLeft: 8,
    '& button': {
      marginLeft: 8,
    },
    '& svg': {
      marginRight: 8,
    },
  },
  closeButton: { color: theme.button.color },
}));

const bool = (params) => (params.value ? <CheckIcon /> : <span></span>);

const defaultColumns = [
  'FullName',
  'Relationship',
  { field: 'RelationshipForAssociate', headerName: 'Relationship For Associated Person' },
  { field: 'IsKeepSeparate', renderCell: bool },
  'Created',
  'CreatedBy',
  'Updated',
  'UpdatedBy',
];

const PersonAssociate = (props) => {
  const classes = useStyles();
  const { personId, dataUpdate } = props;
  const [rows, setRows] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [filteredRows, setFilteredRows] = useState([]);
  const [selection, setSelection] = useState(null);
  const [columns, setColumns] = useState(defaultColumns);
  const [isDeleted, setIsDeleted] = useState(false);
  const mountedRef = useRef(true);
  const perms = getFullPermissions('globals', 'People', 'any');
  const canEditPerson = perms.Edit;
  useEffect(() => {
    personId && getData();
  }, [isDeleted, personId]);

  useEffect(() => {
    if (dataUpdate?.type === 'person-associate') getData();
  }, [dataUpdate]);

  const getData = async () => {
    setLoaded(false);
    try {
      const data = await getPeopleAssociate(personId);
      if (!mountedRef.current) return;
      setRows(processData(data));
      setLoaded(true);
    } catch (err) {
      props.handleError(err);
    }
  };

  const processData = (data) => {
    return data.map((row) => {
      return {
        ...row,
        id: row.ptsAssociateID,
      };
    });
  };

  const renderActions = () => {
    const onAdd = () => {
      props.showPersonAssociate({ ptsPersonID: personId });
    };

    const onEdit = () => {
      const item = rows.find((a) => a.id === selection);
      props.showPersonAssociate(item);
    };

    const onDelete = async () => {
      const item = rows.find((a) => a.id === selection);
      try {
        props.showSpinner();
        const service = getService('record-person-associates');
        await service.remove({
          ptsPersonID: item.ptsPersonID,
          ptsAssociateID: item.ptsAssociateID,
        });
        props.notifyDataUpdate({ type: 'person-associate' });
        props.notify('Person Associate Deleted', 'warning');
        setIsDeleted(!isDeleted);
      } catch (err) {
        props.handleError(err);
      } finally {
        props.hideSpinner();
      }
    };

    return (
      <div className={classes.actions}>
        <Tooltip title="Print">
          <PrintsSearch2 title="Person Associates" data={filteredRows} cols={columns} />
        </Tooltip>
        <Tooltip title="Add Person Associate">
          <Fab size="small" color="secondary" disabled={!canEditPerson} onClick={onAdd}>
            <AddIcon />
          </Fab>
        </Tooltip>
        {selection !== null && (
          <>
            <Tooltip title="Edit Person Associate">
              <Fab size="small" color="secondary" disabled={!canEditPerson} onClick={onEdit}>
                <EditIcon />
              </Fab>
            </Tooltip>
            <Tooltip title="Delete Person Associate">
              <Fab size="small" color="secondary" disabled={!canEditPerson} onClick={onDelete}>
                <DeleteIcon />
              </Fab>
            </Tooltip>
          </>
        )}
      </div>
    );
  };

  return (
    <div className={classes.wrap}>
      <div className={classes.filterWrap}>
        <div className={classes.filterWrap}>
          <QuickFilterSearch
            rows={rows}
            columns={columns}
            setFilteredRows={setFilteredRows}
            style={{ marginTop: 0, paddingBottom: 14 }}
          />
          {renderActions()}
        </div>
      </div>
      <RMSXGrid
        name="PeopleAssociate"
        columns={columns}
        setColumns={setColumns}
        rows={filteredRows}
        loading={!loaded}
        setSelection={setSelection}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    editPeopleEmployment: state.dialogs.editPeopleEmployment,
    dataUpdate: state.dataUpdate,
  };
};

export default connect(mapStateToProps, {
  handleError,
  showSpinner,
  hideSpinner,
  notifyDataUpdate,
  showPersonAssociate,
  notify,
})(PersonAssociate);
