export const incidentHasArrestee = (offenses) => {
  let isArrestees = false;
  offenses?.forEach((offense) => {
    let arresteeIds = offense?.offenseDetails?.values?.arresteeIds?.length;
    if (arresteeIds) {
      isArrestees = true;
      return;
    }
  });
  return isArrestees;
};
