import React, { useState, useEffect, useRef } from 'react';
import { connect, useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import { Button, RadioGroup, FormControl, Tooltip, Fab, Box } from '@material-ui/core';
import { Fade, List, ListItem, ListItemText, alpha } from '@mui/material';

import SaveIcon from '@material-ui/icons/Save';
import { PinDrop } from '@material-ui/icons';

import FormDivider from 'components/RMSFormDivider';
import RMSTextField from 'components/RMSTextField';
import RMSCheckbox2 from 'components/RMSCheckbox/RMSCheckbox2';
import Radio2 from 'components/RMSRadio/Radio2';
import Dialog from 'components/RMSDialog';
import Dictionary from 'components/RMSAutoComplete/RMSDictonary';
import BackDatedRecordHistory from 'components/BackDatedRecordHistory';

import formStyles, {
  gridStyle,
  Row,
  RowInner,
  ColCard,
  Fills,
  useError,
  validatePostcode,
  parsePostcode,
} from 'utils/formStyles';
import { getAddressFromLocation } from 'utils/mapFunctions';

import { getAddressDetails } from 'reducers/AddressReducer';
import { getService } from 'reducers/service';
import { notify } from 'reducers/NotifierReducer';
import { showAddressDialog, showDuplicateAddressDialog } from 'reducers/PersonDialogsReducer';
import { notifyDataUpdate } from 'reducers/DataUpdateReducer';
import { getAccessPermission } from 'reducers/PermissionsReducer';
import { duplicateCheck, duplicateParamsCheck } from 'reducers/helpers/duplicateCheckHelpers';
import { handleError } from 'reducers/ErrorReducer';

const useStyles = makeStyles((theme) => ({
  ...formStyles,
  wrap: {
    padding: theme.spacing(3),
  },
  addressCategory: gridStyle(220, 220),
  item: gridStyle(200, 320),
  btn: {
    marginTop: theme.spacing(3),
    // display: 'block',
    // marginRight: 0,
    marginLeft: '5px',
    '& svg': {
      marginRight: theme.spacing(1),
    },
  },
  topRowMargin: {
    marginTop: theme.spacing(5),
  },
  duplicateButton: {
    position: 'fixed',
    top: theme.spacing(14),
    right: theme.spacing(2),
    visibility: 'hidden',
    zIndex: 999,
    marginRight: theme.spacing(2),
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.warning.main,
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: theme.palette.warning.main,
    },
    margin: '5px 4px',
    height: '35px',
  },
  visible: {
    visibility: 'visible',
  },
  secondaryListItem: {
    color: theme.palette.colors.text1,
  },
  listItemRoot: {
    color: theme.palette.colors.text1,
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.1),
    },
  },
  divContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  divItem: {
    // margin: '0 5px',
  },
}));

function AddressEdit(props) {
  // TODO: validation as in: src\Dialogs\Address\AddressParties.js
  const classes = useStyles();
  const { dictionary, ptsAddressID, copyAddress } = props;
  const {
    codeAddressCategories,
    codeStreetDirections,
    codeStreetTypes,
    codeCities,
    codeStates,
    codeCounties,
    codeCountries,
    codeAddressUnitTypes,
    codeAddressFloorTypes,
    cadESNs,
  } = dictionary;
  const [data, setData] = useState(null);
  const [AddressCategory, setAddressCategory] = useState(null);
  const [AddressNumber, setAddressNumber] = useState('');
  const [AddressNumberPrefix, setAddressNumberPrefix] = useState('');
  const [AddressNumberSuffix, setAddressNumberSuffix] = useState('');
  const [PreDirection, setPreDirection] = useState(null);
  const [PostDirection, setPostDirection] = useState(null);
  const [StreetName, setStreetName] = useState('');
  const [StreetType, setStreetType] = useState(null);
  const [City, setCity] = useState(null);
  const [State, setState] = useState(null);
  const [Postal, setPostal] = useState('');
  const [County, setCounty] = useState(null);
  const [Country, setCountry] = useState(null);
  const [Notes, setNotes] = useState('');
  const [UnitType, setUnitType] = useState(null);
  const [UnitIdentifier, setUnitIdentifier] = useState('');
  const [FloorType, setFloorType] = useState(null);
  const [FloorIdentifier, setFloorIdentifier] = useState('');
  const [BldgType, setBldgType] = useState(null);
  const [BldgIdentifier, setBldgIdentifier] = useState('');
  const [ShowInEventLookup, setShowInEventLookup] = useState(false);
  const [SexOffender, setSexOffender] = useState(false);
  const [Parolee, setParolee] = useState(false);
  const [Probation, setProbation] = useState(false);
  const [NonValidatedAddress, setNonValidatedAddress] = useState(false);
  const [CleryLocationRadio, setCleryLocationRadio] = useState(null);
  const [CrossStreet, setCrossStreet] = useState('');
  const [Highway, setHighway] = useState('');
  const [Esn, setEsn] = useState(null);
  // const [SubdivisionName, setSubdivisionName] = useState('');
  const [Landmark, setLandmark] = useState('');
  const [SurroundingArea, setSurroundingArea] = useState('');
  const [Area, setArea] = useState('');
  const [Description, setDescription] = useState('');
  const [RangeDescription, setRangeDescription] = useState('');
  const [MapLocation, setMapLocation] = useState('');
  const [RelativeLocation, setRelativeLocation] = useState('');
  const [AltitudeMeasure, setAltitudeMeasure] = useState('');
  const [DepthMeasure, setDepthMeasure] = useState('');
  const [GeographicalElevation, setGeographicalElevation] = useState('');
  const [Coordinates, setCoordinates] = useState('');
  const [UtmCoordinate, setUtmCoordinate] = useState('');
  const [TwoDGeographicalLocation, setTwoDGeographicalLocation] = useState('');
  const [FullAddressText, setFullAddressText] = useState('');
  const [addressDuplicateParams, setAddressDuplicateParams] = useState([]);
  const [showDuplicateFound, setShowDuplicateFound] = useState(false);
  const [showDuplicateListDialog, setShowDuplicateListDialog] = useState(false);
  const [errors, setErrors] = useState();
  const [recordHistoryData, setRecordHistoryData] = useState({
    shouldVisible: true,
    isDisabled: true,
    createUpdateInfo: {},
  });
  const [showRecordHistory, setShowRecordHistory] = useState(false);
  const [createdBy, setCreatedBy] = useState(null);
  const [created, setCreated] = useState(null);
  const [updatedBy, setUpdatedBy] = useState(null);
  const [updated, setUpdated] = useState(null);

  const { err, isValid } = useError(errors, setErrors, true);
  const mountedRef = useRef(true);

  const userName = useSelector((state) => state.user.userData?.user.Username);
  const duplicateListOfAddress = useSelector((state) => state.uiModal.duplicateListOfAddress);

  useEffect(() => {
    if (!data) return;

    setCreated(data.Created);
    setCreatedBy(data.CreatedBy);
    setUpdated(data.Updated);
    setUpdatedBy(data.UpdatedBy);
    setRecordHistoryData({
      shouldVisible: true,
      isDisabled: !data.Created,
      createUpdateInfo: {
        created: data.Created,
        createdBy: data.CreatedBy,
        updated: data.Updated,
        updatedBy: data.UpdatedBy,
      },
    });

    setAddressCategory(codeAddressCategories.find((a) => a.Code === data.AddressCategory) || null);
    setAddressNumber(data.AddressNumber || '');
    setAddressNumberPrefix(data.AddressNumberPrefix || '');
    setAddressNumberSuffix(data.AddressNumberSuffix || '');
    setPreDirection(codeStreetDirections.find((d) => d.Code === data.PreDirection) || null);
    setPostDirection(codeStreetDirections.find((d) => d.Code === data.PostDirection) || null);
    setStreetName(data.StreetName || '');
    setStreetType(codeStreetTypes.find((st) => st.Code === data.StreetType) || null);
    setCity(codeCities.find((c) => c.ptsCityID === data.ptsCityID) || null);
    setState(codeStates.find((s) => s.Code === data.State) || null);
    renderExistingPostal(data.PostalCode, data.PostalCodeExtension);
    setCounty(codeCounties.find((c) => c.Code === data.County) || null);
    setCountry(codeCountries.find((c) => c.Code === data.Country) || null);
    setNotes(data.Notes || '');
    setUnitType(codeAddressUnitTypes.find((a) => a.Code === data.UnitType) || null);
    setUnitIdentifier(data.UnitIdentifier || '');
    setFloorType(codeAddressFloorTypes.find((a) => a.Code === data.FloorType) || null);
    setFloorIdentifier(data.FloorIdentifier || '');
    setBldgType(data.BldgType);
    setBldgIdentifier(data.BldgIdentifier || '');
    setShowInEventLookup(data.ShowInEventLookup);
    setSexOffender(data.SexOffender);
    setParolee(data.Parolee);
    setProbation(data.Probation);
    setNonValidatedAddress(data.NonValidatedAddress);
    //setCleryLocationRadio(data.CLERYLocationType)
    if (data.IsCampusResidential === true) setCleryLocationRadio('CampusResidential');
    else if (data.IsCampusNonResidential === true) setCleryLocationRadio('CampusNonResidential');
    else if (data.IsNonCampus === true) setCleryLocationRadio('NonCampus');
    else if (data.IsPublic === true) setCleryLocationRadio('Public');
    setCrossStreet(data.CrossStreet || '');
    setHighway(data.Highway || '');
    setEsn(cadESNs.find((e) => e.ESN === data.ESN) || null);
    //setSubdivisionName();  //cant find in fetched data
    setLandmark(data.LandmarkText || '');
    setSurroundingArea(data.SurroundingArea || '');
    setArea(data.Area || '');
    setDescription(data.Description || '');
    setRangeDescription(data.RangeDescription || '');
    setMapLocation(data.MapLocation || '');
    setRelativeLocation(data.RelativeLocation || '');
    setAltitudeMeasure(data.AltitudeMeasure || '');
    setDepthMeasure(data.DepthMeasure || '');
    setGeographicalElevation(data.GeographicElevation || '');
    setCoordinates(data.MGRSCoordinate || '');
    setUtmCoordinate(data.UTMCoordinate || '');
    setTwoDGeographicalLocation(data.TwoDGeographicCoordinate || '');
    setFullAddressText(data.FullAddressText ? data.FullAddressText : '');
  }, [data]);

  useEffect(() => {
    const address = getAddressObj();
    const FullAddressText = getAddressFromLocation(address);
    setFullAddressText(FullAddressText);
    // eslint-disable-next-line
  }, [
    AddressNumber,
    AddressNumberPrefix,
    AddressNumberSuffix,
    PreDirection,
    PostDirection,
    StreetName,
    StreetType,
    City,
    State,
    Postal,
    UnitType,
    UnitIdentifier,
  ]);

  useEffect(() => {
    if (!ptsAddressID) return;
    getAddress();
    return () => {
      mountedRef.current = false;
    };
    // eslint-disable-next-line
  }, [ptsAddressID]);

  useEffect(async () => {
    if (ptsAddressID) {
      setAddressDuplicateParams([]);
      return;
    }
    let duplicateParams = await duplicateParamsCheck('AddDuplicate');
    setAddressDuplicateParams(duplicateParams);
  }, [ptsAddressID]);

  useEffect(async () => {
    if (addressDuplicateParams.length === 0) return;
    let address = duplicateAddressObj();
    const duplicateFound = await duplicateCheck(addressDuplicateParams, address, 'address');
    if (duplicateFound) {
      setShowDuplicateFound(true);
    } else {
      setShowDuplicateFound(false);
    }
  }, [
    AddressNumberPrefix,
    AddressNumber,
    AddressNumberSuffix,
    PreDirection,
    PostDirection,
    StreetName,
    StreetType,
    City,
    State,
    Postal,
  ]);

  const duplicateAddressObj = () => {
    const { PostalCode, PostalCodeExtension } = parsePostcode(Postal);
    return {
      values: {
        addressNumberPrefix: AddressNumberPrefix,
        addressNumber: AddressNumber,
        addressNumberSuffix: AddressNumberSuffix,
        preDirection: PreDirection?.Code,
        postDirection: PostDirection?.Code,
        streetName: StreetName,
        streetType: StreetType?.Code,
        cityId: City?.ptsCityID,
        state: State?.Code,
        postalCode: PostalCode,
        postalCodeExtension: PostalCodeExtension,
      },
    };
  };

  const getAddress = async () => {
    try {
      const data = await getAddressDetails(ptsAddressID);
      if (!mountedRef.current || !data.data || !data.data.length) return;
      setData(data.data[0]);
      processData(data.data[0]);
    } catch (err) {
      props.handleError(err, 'Error receiving event location data.');
    }
  };

  const processData = (data) => {
    const AddressCategory =
      codeAddressCategories.find((a) => a.Code === data.AddressCategory) || null;
    setAddressCategory(AddressCategory);
  };

  const handleCloseRecordHistoryDialog = () => setShowRecordHistory(false);

  const renderExistingPostal = (code, ext) => {
    if (!code) return;
    if (!ext) setPostal(code);
    else setPostal(`${code} - ${ext}`);
  };

  const getAddressObj = () => {
    const { PostalCode, PostalCodeExtension } = parsePostcode(Postal);
    return {
      ...data,
      ptsAddressID: ptsAddressID,
      AddressCategory: AddressCategory?.Code || null,
      AddressNumber: AddressNumber || null,
      AddressNumberPrefix: AddressNumberPrefix || null,
      AddressNumberSuffix: AddressNumberSuffix || null,
      PreDirection: PreDirection?.Code || null,
      PostDirection: PostDirection?.Code || null,
      StreetName: StreetName || null,
      StreetType: StreetType?.Code || null,
      ptsCityID: City?.ptsCityID || null,
      State: State?.Code || null,
      PostalCode,
      PostalCodeExtension,
      County: County?.Code || null,
      Country: Country?.Code || null,
      Notes: Notes || null,
      UnitType: UnitType?.Code || null,
      UnitIdentifier: UnitIdentifier,
      FloorType: FloorType?.Code || null,
      FloorIdentifier: FloorIdentifier,
      BldgType: BldgType?.Code || null,
      BldgIdentifier: BldgIdentifier,
      ShowInEventLookup: ShowInEventLookup === true ? 1 : 0,
      SexOffender: SexOffender === true ? 1 : 0,
      Parolee: Parolee === true ? 1 : 0,
      Probation: Probation === true ? 1 : 0,
      NonValidatedAddress: NonValidatedAddress === true ? 1 : 0,
      IsCampusResidential: CleryLocationRadio === 'CampusResidential' ? 1 : 0,
      IsCampusNonResidential: CleryLocationRadio === 'CampusNonResidential' ? 1 : 0,
      IsNonCampus: CleryLocationRadio === 'NonCampus' ? 1 : 0,
      IsPublic: CleryLocationRadio === 'Public' ? 1 : 0,
      CrossStreet: CrossStreet,
      Highway: Highway,
      ESN: Esn?.ESN || null,
      LandmarkText: Landmark,
      SurroundingArea: SurroundingArea,
      Area: Area,
      Description: Description,
      RangeDescription: RangeDescription,
      MapLocation: MapLocation,
      RelativeLocation: RelativeLocation,
      AltitudeMeasure: AltitudeMeasure,
      DepthMeasure: DepthMeasure,
      GeographicElevation: GeographicalElevation,
      MGRSCoordinate: Coordinates,
      UTMCoordinate: UtmCoordinate,
      TwoDGeographicCoordinate: TwoDGeographicalLocation,
      FullAddressText,
    };
  };

  const handleSave = () => {
    const address = getAddressObj();
    if (copyAddress) address.ptsAddressID = undefined;
    const service = getService('cad-history-edit-address');
    service
      .create({ ...address, CurrentUser: userName })
      .then((data) => {
        const { ptsAddressID } = data;
        const currentDateTime = new Date();
        const currentUserName = userName;

        if (address.ptsAddressID) {
          setRecordHistoryData({
            shouldVisible: true,
            isDisabled: !created,
            createUpdateInfo: {
              created: created,
              createdBy: createdBy,
              updated: currentDateTime,
              updatedBy: currentUserName,
            },
          });
        } else {
          setRecordHistoryData({
            shouldVisible: true,
            isDisabled: !currentDateTime,
            createUpdateInfo: {
              created: currentDateTime,
              createdBy: currentUserName,
              updated: currentDateTime,
              updatedBy: currentUserName,
            },
          });
        }

        props.notifyDataUpdate({ type: 'Address', data });
        if (ptsAddressID) props.showAddressDialog({ ptsAddressID });
        props.notify('Address saved', 'success');
      })
      .catch((err) => props.handleError(err, 'Error saving address'));
  };

  const renderAddressCategory = () => {
    const onChange = (ev, val) => setAddressCategory(val);
    return (
      <Dictionary
        options="codeAddressCategories"
        className={classes.addressCategory}
        onChange={onChange}
        formType="add"
        value={AddressCategory}
        label="Address Category"
      />
    );
  };

  const renderAddressNumberPrefix = () => {
    const onChange = (ev, val) => setAddressNumberPrefix(val);
    const error =
      addressDuplicateParams.includes('AddressNumberPrefix') && showDuplicateFound
        ? 'Duplicate found'
        : null;
    return (
      <RMSTextField
        className={classes.item}
        label="Prefix"
        value={AddressNumberPrefix}
        onChange={onChange}
        max={20}
        error={error}
      />
    );
  };

  const renderAddressNumberSuffix = () => {
    const onChange = (ev, val) => setAddressNumberSuffix(val);
    const error =
      addressDuplicateParams.includes('AddressNumberSuffix') && showDuplicateFound
        ? 'Duplicate found'
        : null;
    return (
      <RMSTextField
        className={classes.item}
        label="Suffix"
        value={AddressNumberSuffix}
        onChange={onChange}
        max={20}
        error={error}
      />
    );
  };

  const renderAddressNumber = () => {
    const onChange = (ev, val) => setAddressNumber(val);
    // const error = err(AddressNumber, 'AddressNumber');
    const error =
      addressDuplicateParams.includes('AddressNumber') && showDuplicateFound
        ? 'Duplicate found'
        : null;
    return (
      <RMSTextField
        className={classes.item}
        label="Number"
        value={AddressNumber}
        onChange={onChange}
        type="number"
        error={error}
      />
    );
  };

  const renderPreDirection = () => {
    const onChange = (ev, val) => setPreDirection(val);
    const error =
      addressDuplicateParams.includes('PreDirection') && showDuplicateFound
        ? 'Duplicate found'
        : null;
    return (
      <Dictionary
        options="codeStreetDirections"
        className={classes.item}
        formType="add"
        onChange={onChange}
        value={PreDirection}
        label="Predirectional"
        error={error}
      />
    );
  };

  const renderStreetName = () => {
    const onChange = (ev, val) => setStreetName(val);
    const duplicateError =
      addressDuplicateParams.includes('StreetName') && showDuplicateFound
        ? 'Duplicate found'
        : null;
    const error = err(StreetName.length > 1, 'StreetName') || duplicateError;
    return (
      <RMSTextField
        className={classes.item}
        label="Street Name"
        value={StreetName}
        onChange={onChange}
        error={error}
        max={100}
      />
    );
  };

  const renderStreetType = () => {
    const onChange = (ev, val) => setStreetType(val);
    const error =
      addressDuplicateParams.includes('StreetType') && showDuplicateFound
        ? 'Duplicate found'
        : null;
    return (
      <Dictionary
        options="codeStreetTypes"
        className={classes.item}
        formType="add"
        onChange={onChange}
        value={StreetType}
        label="Street Type"
        error={error}
      />
    );
  };

  const renderPostDirection = () => {
    const onChange = (ev, val) => setPostDirection(val);
    const error =
      addressDuplicateParams.includes('PostDirection') && showDuplicateFound
        ? 'Duplicate found'
        : null;
    return (
      <Dictionary
        options="codeStreetDirections"
        className={classes.item}
        formType="add"
        onChange={onChange}
        value={PostDirection}
        label="Postdirectional"
        error={error}
      />
    );
  };

  const renderCities = () => {
    const onChange = (ev, val) => setCity(val);
    const duplicateError =
      addressDuplicateParams.includes('ptsCityID') && showDuplicateFound ? 'Duplicate found' : null;
    const error = err(City, 'City') || duplicateError;
    return (
      <Dictionary
        options="codeCities"
        className={classes.item}
        onChange={onChange}
        formType="add"
        value={City}
        label="City"
        error={error}
      />
    );
  };

  const renderState = () => {
    const onChange = (ev, val) => setState(val);
    const duplicateError =
      addressDuplicateParams.includes('State') && showDuplicateFound ? 'Duplicate found' : null;
    const error = err(State, 'State') || duplicateError;
    return (
      <Dictionary
        options="codeStates"
        className={classes.item}
        onChange={onChange}
        formType="add"
        value={State}
        label="State"
        error={error}
      />
    );
  };

  const renderPostal = () => {
    const onChange = (ev, val) => setPostal(val);
    const isValid = !Postal || validatePostcode(Postal);
    const duplicateError =
      (addressDuplicateParams.includes('PostalCode') ||
        addressDuplicateParams.includes('PostalCodeExtension')) &&
      showDuplicateFound
        ? 'Duplicate found'
        : null;
    const error = err(isValid, 'Postal', 'Must consist of 5 or 9 digits') || duplicateError;
    return (
      <RMSTextField
        className={classes.item}
        label="Postal Code - Ext."
        value={Postal}
        onChange={onChange}
        error={error}
        type="postcode"
      />
    );
  };

  const renderCounty = () => {
    const onChange = (ev, val) => setCounty(val);
    return (
      <Dictionary
        className={classes.item}
        options="codeCounties"
        formType="add"
        value={County}
        onChange={onChange}
        label="County"
      />
    );
  };

  const renderCountry = () => {
    const onChange = (ev, val) => setCountry(val);
    return (
      <Dictionary
        className={classes.item}
        options="codeCountries"
        formType="add"
        value={Country}
        onChange={onChange}
        label="Country"
      />
    );
  };

  const renderNotes = () => {
    const onChange = (ev, val) => setNotes(val);
    return (
      <RMSTextField
        className={classes.w100pr}
        label="Notes"
        value={Notes}
        onChange={onChange}
        multiline
        rows={4}
      />
    );
  };

  const renderUnitType = () => {
    const onChange = (ev, val) => setUnitType(val);
    const error = !UnitType && UnitIdentifier ? 'Unit Type is missing' : null;
    return (
      <Dictionary
        className={classes.item}
        options="codeAddressUnitTypes"
        formType="add"
        value={UnitType}
        onChange={onChange}
        label="Unit Type"
        error={error}
      />
    );
  };

  const renderUnitIdentifier = () => {
    const onChange = (ev, val) => setUnitIdentifier(val);
    const error = UnitType && !UnitIdentifier ? 'Unit Identifier is missing' : null;
    return (
      <RMSTextField
        className={classes.item}
        label="Unit Identifier"
        value={UnitIdentifier}
        onChange={onChange}
        max={20}
        error={error}
        autoFocus={copyAddress}
      />
    );
  };

  const renderFloorType = () => {
    const onChange = (ev, val) => setFloorType(val);
    return (
      <Dictionary
        className={classes.item}
        options="codeAddressFloorTypes"
        formType="add"
        value={FloorType}
        onChange={onChange}
        label="Floor Type"
      />
    );
  };

  const renderFloorIdentifier = () => {
    const onChange = (ev, val) => setFloorIdentifier(val);
    return (
      <RMSTextField
        className={classes.item}
        label="Floor Identifier"
        value={FloorIdentifier}
        onChange={onChange}
        max={20}
      />
    );
  };

  const renderBldgType = () => {
    const onChange = (ev, val) => setBldgType(val);
    return (
      <Dictionary
        className={classes.item}
        options="codeAddressBldgTypes"
        formType="add"
        value={BldgType}
        onChange={onChange}
        label="Building Type"
      />
    );
  };

  const renderBldgIdentifier = () => {
    const onChange = (ev, val) => setBldgIdentifier(val);
    return (
      <RMSTextField
        className={classes.item}
        label="Building identifier"
        value={BldgIdentifier}
        onChange={onChange}
        max={20}
      />
    );
  };

  const renderShowInEventLookup = () => {
    const onChange = () => setShowInEventLookup(!ShowInEventLookup);
    const access = getAccessPermission('cad', 'Setup Event Address Lookup');
    return (
      <RMSCheckbox2
        checked={ShowInEventLookup}
        onChange={onChange}
        label="Show In Event Lookup"
        disabled={!access}
      />
    );
  };

  const renderSexOffender = () => {
    const onChange = () => setSexOffender(!SexOffender);
    return <RMSCheckbox2 checked={SexOffender} onChange={onChange} label="Sex Offender" />;
  };

  const renderParolee = () => {
    const onChange = () => setParolee(!Parolee);
    return <RMSCheckbox2 checked={Parolee} onChange={onChange} label="Parolee" />;
  };

  const renderProbation = () => {
    const onChange = () => setProbation(!Probation);
    return <RMSCheckbox2 checked={Probation} onChange={onChange} label="Probation" />;
  };

  const renderNonValidatedAddress = () => {
    const onChange = () => setNonValidatedAddress(!NonValidatedAddress);
    return (
      <RMSCheckbox2
        checked={NonValidatedAddress}
        onChange={onChange}
        label="Non Validated Address"
      />
    );
  };

  const renderCleryRadio = () => {
    const onChange = (ev, val) => setCleryLocationRadio(val);
    return (
      <FormControl component="fieldset">
        <RadioGroup row value={CleryLocationRadio} onChange={onChange}>
          <Radio2 value="CampusResidential" label="Campus Residential" />
          <Radio2 value="CampusNonResidential" label="Campus Non-Residential" />
          <Radio2 value="NonCampus" label="Non-Campus" />
          <Radio2 value="Public" label="Public" />
        </RadioGroup>
      </FormControl>
    );
  };

  const renderCrossStreet = () => {
    const onChange = (ev, val) => setCrossStreet(val);
    return (
      <RMSTextField
        className={classes.item}
        label="Cross Street"
        value={CrossStreet}
        onChange={onChange}
        max={100}
      />
    );
  };

  const renderHighway = () => {
    const onChange = (ev, val) => setHighway(val);
    return (
      <RMSTextField
        className={classes.item}
        label="Highway"
        value={Highway}
        onChange={onChange}
        max={50}
      />
    );
  };

  const renderESN = () => {
    const onChange = (ev, val) => setEsn(val);
    return (
      <Dictionary
        className={classes.item}
        options="cadESNs"
        formType="add"
        value={Esn}
        onChange={onChange}
        label="ESN"
      />
    );
  };

  const renderLandmark = () => {
    const onChange = (ev, val) => setLandmark(val);
    return (
      <RMSTextField
        className={classes.item}
        label="Landmark"
        value={Landmark}
        onChange={onChange}
        max={50}
      />
    );
  };

  const renderSurroundingArea = () => {
    const onChange = (ev, val) => setSurroundingArea(val);
    return (
      <RMSTextField
        className={classes.item}
        label="Surrounding Area"
        value={SurroundingArea}
        onChange={onChange}
        max={50}
      />
    );
  };

  const renderArea = () => {
    const onChange = (ev, val) => setArea(val);
    return (
      <RMSTextField
        className={classes.item}
        label="Area"
        value={Area}
        onChange={onChange}
        max={50}
      />
    );
  };

  const renderDescription = () => {
    const onChange = (ev, val) => setDescription(val);
    return (
      <RMSTextField
        className={classes.item}
        label="Description"
        value={Description}
        onChange={onChange}
        max={100}
      />
    );
  };

  const renderRangeDescription = () => {
    const onChange = (ev, val) => setRangeDescription(val);
    return (
      <RMSTextField
        className={classes.item}
        label="Range Description"
        value={RangeDescription}
        onChange={onChange}
        max={25}
      />
    );
  };

  const renderMapLocation = () => {
    const onChange = (ev, val) => setMapLocation(val);
    return (
      <RMSTextField
        className={classes.item}
        label="Map Location"
        value={MapLocation}
        onChange={onChange}
        max={50}
      />
    );
  };

  const renderRelativeLocation = () => {
    const onChange = (ev, val) => setRelativeLocation(val);
    return (
      <RMSTextField
        className={classes.item}
        label="Relative Location"
        value={RelativeLocation}
        onChange={onChange}
        max={25}
      />
    );
  };

  const renderAltitudeMeasure = () => {
    const onChange = (ev, val) => setAltitudeMeasure(val);
    return (
      <RMSTextField
        className={classes.item}
        label="Altitude Measure"
        value={AltitudeMeasure}
        onChange={onChange}
        max={25}
      />
    );
  };

  const renderDepthMeasure = () => {
    const onChange = (ev, val) => setDepthMeasure(val);
    return (
      <RMSTextField
        className={classes.item}
        label="Depth Measure"
        value={DepthMeasure}
        onChange={onChange}
        max={25}
      />
    );
  };

  const renderGeographicalElevation = () => {
    const onChange = (ev, val) => setGeographicalElevation(val);
    return (
      <RMSTextField
        className={classes.item}
        label="Geo. Elevation"
        value={GeographicalElevation}
        onChange={onChange}
        max={25}
      />
    );
  };

  const renderCoordinates = () => {
    const onChange = (ev, val) => setCoordinates(val);
    return (
      <RMSTextField
        className={classes.item}
        label="Coordinates"
        value={Coordinates}
        onChange={onChange}
        max={25}
      />
    );
  };

  const renderUtmCoordinate = () => {
    const onChange = (ev, val) => setUtmCoordinate(val);
    return (
      <RMSTextField
        className={classes.item}
        label="Utm Coordinate"
        value={UtmCoordinate}
        onChange={onChange}
        max={25}
      />
    );
  };

  const renderTwoDGeographicalLocation = () => {
    const onChange = (ev, val) => setTwoDGeographicalLocation(val);
    return (
      <RMSTextField
        className={classes.item}
        label="2-D Geographical Location"
        value={TwoDGeographicalLocation}
        onChange={onChange}
        max={25}
      />
    );
  };

  const closeDuplicateListDialog = () => {
    setShowDuplicateListDialog(false);
  };
  const renderDuplicateListActions = () => {
    return (
      <div>
        <Button
          color="primary"
          variant="contained"
          onClick={closeDuplicateListDialog}
          className={classes.closeButton}>
          Close
        </Button>
      </div>
    );
  };
  const renderDuplicateListDialog = () => {
    return (
      <List>
        {duplicateListOfAddress.map((a) => (
          <ListItem key={a.FullAddressText} className={classes.listItemRoot}>
            <div className={classes.divContainer}>
              <span
                className={classes.divItem}
                style={{ marginRight: 'auto', minWidth: '400px', maxWidth: '400px' }}>
                <ListItemText
                  classes={classes.secondaryListItem}
                  primary={`AddressID: ${a.AddressID || ''}`}
                  secondary={
                    <span className={classes.secondaryListItem}>
                      {`Full Address: ${a.FullAddressText || ''}`}
                    </span>
                  }
                />
              </span>
              <span style={{ marginLeft: 'auto' }}>
                <Tooltip title="View Duplicate Address">
                  <Fab
                    size="small"
                    color="secondary"
                    onClick={() => viewDuplicateAddress(a.ptsAddressID)}>
                    <PinDrop />
                  </Fab>
                </Tooltip>
              </span>
            </div>
          </ListItem>
        ))}
      </List>
    );
  };

  const viewDuplicateAddress = (ptsAddressID) => {
    props.showDuplicateAddressDialog({ ptsAddressID });
  };

  // create columns and set min and max width of the column
  return (
    <div className={classes.wrap}>
      <Fade in={showDuplicateFound}>
        <Button
          className={`${classes.duplicateButton} ${showDuplicateFound && classes.visible}`}
          size="medium"
          onClick={() => setShowDuplicateListDialog(true)}>
          Duplicate Found! Show Duplicate List
        </Button>
      </Fade>
      <div className={`${classes.rowSpaces} ${showDuplicateFound && classes.topRowMargin}`}>
        <h4 className={classes.w50pr}>{FullAddressText.replace(/^, /, '')}</h4>
        {renderAddressCategory()}
      </div>
      <Row>
        <ColCard minWidth={500}>
          <FormDivider title="Address Details" />
          <RowInner>
            {renderAddressNumberPrefix()}
            {renderAddressNumber()}
            {renderAddressNumberSuffix()}
            {renderPreDirection()}
            {renderStreetName()}
            {renderStreetType()}
            {renderPostDirection()}
            {renderCities()}
            {renderState()}
            {renderPostal()}
            {renderCounty()}
            {renderCountry()}
            <Fills className={classes.item} />
          </RowInner>
          <FormDivider title="Notes" />
          <RowInner>{renderNotes()}</RowInner>
        </ColCard>
        <ColCard minWidth={500}>
          <FormDivider title="Identifiers" />
          <RowInner>
            {renderUnitType()}
            {renderUnitIdentifier()}
            {renderFloorType()}
            {renderFloorIdentifier()}
            {renderBldgType()}
            {renderBldgIdentifier()}
            <Fills className={classes.item} />
          </RowInner>
          <FormDivider title="Flags" />
          <RowInner>
            {renderShowInEventLookup()}
            {renderSexOffender()}
            {renderParolee()}
            {renderProbation()}
            {renderNonValidatedAddress()}
            <Fills className={classes.item} />
          </RowInner>
          <FormDivider title="Clery Location" />
          <RowInner>{renderCleryRadio()}</RowInner>
        </ColCard>
      </Row>
      <Row>
        <ColCard minWidth={500}>
          <FormDivider title="Location" />
          <RowInner>
            {renderCrossStreet()}
            {renderHighway()}
            {renderESN()}
            {/* {renderSubdivisionName()} */}
            {renderLandmark()}
            {renderSurroundingArea()}
            {renderArea()}
            {renderDescription()}
            {renderRangeDescription()}
            {renderMapLocation()}
            {renderRelativeLocation()}
            <Fills className={classes.item} />
          </RowInner>
        </ColCard>
        <ColCard minWidth={500}>
          <FormDivider title="Geographical Details" />
          <RowInner>
            {renderAltitudeMeasure()}
            {renderDepthMeasure()}
            {renderGeographicalElevation()}
            {renderCoordinates()}
            {renderUtmCoordinate()}
            {renderTwoDGeographicalLocation()}
            <Fills className={classes.item} />
          </RowInner>
        </ColCard>
      </Row>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        {recordHistoryData?.shouldVisible && (
          <Box className={classes.btn}>
            <BackDatedRecordHistory
              showRecordHistory={showRecordHistory}
              setShowRecordHistory={setShowRecordHistory}
              disabled={recordHistoryData?.isDisabled}
              handleCloseRecordHistoryDialog={handleCloseRecordHistoryDialog}
              createUpdateInfo={recordHistoryData?.createUpdateInfo}></BackDatedRecordHistory>
          </Box>
        )}
        <Button
          className={classes.btn}
          color="primary"
          variant="contained"
          autoFocus
          onClick={handleSave}
          disabled={!isValid() || !props.canSave}>
          <SaveIcon /> Save
        </Button>
      </div>
      <Dialog
        open={showDuplicateListDialog}
        title="Duplicate Address List"
        actions={renderDuplicateListActions()}
        onClose={closeDuplicateListDialog}
        toolbar>
        {renderDuplicateListDialog()}
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    dictionary: state.dictionary,
  };
};

export default connect(mapStateToProps, {
  handleError,
  notify,
  showAddressDialog,
  notifyDataUpdate,
  showDuplicateAddressDialog,
})(AddressEdit);
