import { createMuiTheme } from '@material-ui/core';
import typography from './typography';

/* eslint-disable import/no-unresolved, import/no-webpack-loader-syntax */
import vars from '!!sass-vars-to-js-loader!../assets/core/_variables-mui.scss';

export const DayTheme = createMuiTheme({
  palette: {
    type: 'light',
    colors: {
      text1: '#3b3e66',
      grey1: '#dedfe3',
      label: 'white',
      borderColor: vars.bgElementsLight,
      chipBg: vars.bgElementsLight,
      borderColorList: '#B5B5B5',
    },
    primary: {
      main: vars.primary,
    },
    topBar: {
      main: '#FFFEFC',
      submenu: '#1977D4',
      underline: '#FFFEFC',
    },
    grey: {
      300: vars.inheritDefault1,
      A100: vars.inheritDefault2,
    },
    secondary: {
      main: vars.secondary,
    },
    error: {
      main: '#d32f2f',
      light: '#d32f2f',
      dark: 'rgb(179, 39, 39)',
    },
    success: {
      main: vars.green,
    },
    warning: {
      main: vars.orange,
    },
    helpers: {
      primary: vars.blue,
      main: 'rgba(25, 46, 91, .035)',
    },
    customBg: '#1977D4',
    bg: vars.bgLight,
    bgElements: vars.bgElementsLight,
    colorFont: '#000',
    toolbarColor: vars.primary,
    contrastThreshold: 3,
    tonalOffset: 0.1,
  },
  shape: {
    borderRadius: '0.5rem',
  },
  overrides: {
    MuiButton: {
      text: {
        paddingLeft: '14px',
        paddingRight: '14px',
      },
      containedSizeSmall: {
        paddingLeft: '14px',
        paddingRight: '14px',
      },
      root: {
        textTransform: 'none',
        fontWeight: 'normal',
      },
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: vars.second,
        padding: '8px 16px',
        fontSize: '13px',
      },
      arrow: {
        color: vars.second,
      },
    },
  },
  typography,
  colors: {
    errorContrast: '#ffffff',
    grey1: '#dedfe3',
  },
  card: {
    bg: '#fff',
    hr: '#dedfe3',
    warrantColor: '#D33D1D',
  },
  button: {
    color: '#1976d2',
  },
  accordion: {
    background: '#fff',
  },
  content: {
    bg: '#e5e5e5',
  },
});

export const NightTheme = createMuiTheme({
  palette: {
    type: 'dark',
    text: {
      primary: '#d4d6d7',
      button: '#d4d6d7',
    },
    primary: {
      main: vars.primary,
    },
    topBar: {
      main: '#1977D4',
      submenu: '#1977D4',
      topBar: '#1977D4',
    },
    grey: {
      300: vars.inheritDefault1,
      A100: vars.inheritDefault2,
    },
    secondary: {
      main: vars.secondary,
    },
    error: {
      main: '#d32f2f',
      light: '#d32f2f',
      dark: 'rgb(179, 39, 39)',
    },
    success: {
      main: vars.green,
    },
    warning: {
      main: vars.orange,
    },
    helpers: {
      primary: vars.blue,
      main: 'rgba(25, 46, 91, .035)',
    },
    bg: vars.bgDark,
    customBg: '#515151',
    bgElements: vars.bgElementsDark,
    colorFont: '#fff',
    toolbarColor: '#748EEB',
    contrastThreshold: 3,
    tonalOffset: 0.1,
    colors: {
      grey1: '#343434',
      borderColor: '#7c7c7c',
      chipBg: vars.bgElementsLight,
      text1: '#d4d6d7',
      label: '#d4d6d7',
      borderColorList: '#7c7c7c',
    },
  },
  shape: {
    borderRadius: '0.5rem',
  },
  overrides: {
    MuiInputLabel: {
      root: {
        '&.Mui-focused': { color: '#fff' },
      },
    },
    MuiMenuItem: {
      root: {
        '&.MuiMenuItem-root:hover': { color: '#fff' },
      },
    },
    MuiButton: {
      backgroundColor: '#515151',
      text: {
        paddingLeft: '14px',
        paddingRight: '14px',
      },
      containedSizeSmall: {
        paddingLeft: '14px',
        paddingRight: '14px',
      },
      root: {
        textTransform: 'none',
        fontWeight: 'normal',
      },
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: vars.second,
        padding: '8px 16px',
        fontSize: '13px',
      },
      arrow: {
        color: vars.second,
      },
    },
    MuiToolbar: {
      regular: {
        minHeight: '80px',
      },
    },
  },
  typography,
  colors: {
    errorContrast: '#f7f7f7',
    grey1: '#343434',
  },
  card: {
    bg: '#fff',
    hr: '#343434',
    warrantColor: '#D33D1D',
  },
  button: {
    color: '#fff',
  },
  accordion: {
    background: '#424242',
  },
  content: {
    bg: '#212325',
  },
});
