import React from 'react';

import HistoryIcon from '@material-ui/icons/History';
import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';

import RecordHistoryDialog from './RMSModals/RecordHistoryDialog';

const useStyles = makeStyles((theme) => ({
  fabStyle: {
    backgroundColor: theme.palette.secondary.main,
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.colors.chipBg,
    },
  },
}));

const BackDatedRecordHistory = (props) => {
  const classes = useStyles();
  const {
    disabled = false,
    tooltipText = 'Record History',
    showRecordHistory,
    setShowRecordHistory,
    handleCloseRecordHistoryDialog,
    createUpdateInfo,
    children,
  } = props;

  return (
    <>
      <Tooltip title={tooltipText}>
        <Button
          color="primary"
          variant="contained"
          autoFocus
          disabled={disabled}
          onClick={() => setShowRecordHistory(true)}>
          <HistoryIcon /> History
        </Button>
      </Tooltip>

      <RecordHistoryDialog
        openEditHistoryDialog={showRecordHistory}
        onCloseEditHistoryDialog={handleCloseRecordHistoryDialog}
        createUpdateInfo={createUpdateInfo}>
        {children}
      </RecordHistoryDialog>
    </>
  );
};

export default BackDatedRecordHistory;
