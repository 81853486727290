import React, { useState, useEffect, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';

import {
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Fab,
  IconButton,
  Button,
  makeStyles,
} from '@material-ui/core';
import { XGrid } from '@material-ui/x-grid';

import InfoIcon from '@material-ui/icons/Info';
import CheckIcon from '@material-ui/icons/Check';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

import formStyles, { useError, gridStyle, Row, RowInner, ColCard } from 'utils/formStyles';

import PrintsSearch from 'components/RMSSearch/components/PrintsSearch';
import Tooltip from 'components/Tooltip';
import PersonLookup2 from 'components/RMSPersonLookup/PersonLookup2';
import RMSTextField from 'components/RMSTextField';
import Dictionary from 'components/RMSAutoComplete/RMSDictonary';
import PlaceLookup from 'components/RMSPlaceLookup';

import { getPersonWarrant } from 'reducers/WarrantReducer';
import { showDisplayDataWindow } from 'reducers/PersonDialogsReducer';
import { handleError } from 'reducers/ErrorReducer';
import { saveAddressParty, delAddressParty, getAddressParties } from 'reducers/AddressReducer';
import { getPlaceDetails } from 'reducers/SearchReducer';
import { displayDateTime } from 'reducers/TimeReducer';

const bool = (params) => (params.value ? <CheckIcon /> : <span></span>);

const getPartyName = (row) => {
  const { PartyType, PlaceName, PersonName } = row;
  switch (PartyType) {
    case 'Place':
      return PlaceName;
    case 'Person':
      return PersonName;
    default:
      return '';
  }
};

const getPartyID = (row) => {
  const { PartyType, PlaceID, PersonID } = row;
  switch (PartyType) {
    case 'Place':
      return PlaceID;
    case 'Person':
      return PersonID;
    default:
      return '';
  }
};

const renderWarrant = (params) => {
  const dispatch = useDispatch();
  const { ActiveWarrant } = params.row;
  if (!ActiveWarrant) return '';

  const onClick = async (ev) => {
    ev.stopPropagation();
    try {
      const ptsPersonID = params.row.ptsPartyID;
      const result = await getPersonWarrant(ptsPersonID);
      const warrants = result.data;
      dispatch(
        showDisplayDataWindow({
          data: processVarrants(warrants),
          title: `Active Warrants for ${params.row.Name}`,
        })
      );
    } catch (err) {
      props.handleError(err, 'Error, Cannot display events');
    }
  };

  const processVarrants = (warrants) => {
    warrants.forEach((warrant) => {
      delete warrant.ptsPersonID;
      delete warrant.ptsStatuteID;
      delete warrant.ptsWarrantID;
      delete warrant.ptsIncidentID;
    });
    return warrants;
  };

  return (
    <IconButton size="small" onClick={onClick}>
      <InfoIcon style={{ color: '#fff' }} />
    </IconButton>
  );
};

const columns = [
  { field: 'Name', headerName: 'Name', width: 150 },
  { field: 'PartyID', headerName: 'Party ID', width: 150 },
  { field: 'PartyType', headerName: 'Party Type', width: 150 },
  { field: 'PriorityOrder', headerName: 'Priority Order', width: 150 },
  { field: 'AdvisoryText', headerName: 'Advisory Text', width: 150 },
  { field: 'Relationship', headerName: 'Relationship', width: 150 },
  { field: 'ActiveWarrant', headerName: 'Active Warrant', width: 150, renderCell: renderWarrant },
  {
    field: 'Created',
    headerName: 'Created',
    width: 200,
    valueFormatter: (params) => displayDateTime(params.value),
  },
  { field: 'CreatedBy', headerName: 'Created By', width: 150 },
  {
    field: 'Updated',
    headerName: 'Updated',
    width: 200,
    valueFormatter: (params) => displayDateTime(params.value),
  },
  { field: 'UpdatedBy', headerName: 'Updated By', width: 150 },
  {
    field: 'IsDeleted',
    headerName: 'Is Deleted',
    width: 120,
    format: 'bool',
    renderCell: bool,
  },
  { field: 'DeletedBy', headerName: 'Deleted By', width: 120 },
  { field: 'DeletedIP', headerName: 'Deleted IP', width: 120 },
];

const useStyles = makeStyles((theme) => ({
  ...formStyles,
  item: gridStyle(150, 400),
  wrap: {
    padding: theme.spacing(3),
  },
  col1: {
    ...gridStyle(350, '100%'),
  },
  col2: {
    ...gridStyle(250, '100%'),
  },
  editActions: {
    textAlign: 'right',
    paddingBottom: 8,
    '& hr': {
      margin: '8px 0',
    },
    '& button': {
      marginLeft: 8,
    },
    '& svg': {
      marginRight: 5,
    },
  },
  gridWrap: {
    width: '100%',
    boxSizing: 'border-box',
    height: 600,
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(2),
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
  warrant: {
    background: theme.palette.error.main,
    color: theme.colors.errorContrast,
    '&:hover': {
      background: `${theme.palette.error.main} !important`,
      color: theme.colors.errorContrast,
    },
    '&.Mui-selected': {
      background: `${theme.palette.error.main} !important`,
      color: theme.colors.errorContrast,
    },
  },
  closeButton: { color: theme.button.color },
}));

function AddressParties(props) {
  const { ptsAddressID, dictionary } = props;
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [selection, setSelection] = useState(null);
  const [hiddenColumns, setHiddenColumns] = useState([]);
  const [edited, setEdited] = useState(false);
  const [PartyType, SetPartyType] = useState('Person');
  const [person, setPerson] = useState(null);
  const [place, setPlace] = useState(null);
  const [PriorityOrder, setPriorityOrder] = useState(0);
  const [PartyRelationship, setPartyRelationship] = useState(null);
  const [errors, setErrors] = useState();
  const [AdvisoryText, setAdvisoryText] = useState('');
  const [ROWGUID, setROWGUID] = useState();
  const [ptsPersonID, setPtsPersonID] = useState();
  const [editedPlace, setEditedPlace] = useState();
  const mountedRef = useRef(true);
  const { err, delErr, clearErr, isValidAndChanged, formChanged } = useError(errors, setErrors);

  useEffect(() => {
    getData();
    return () => (mountedRef.current = false);
    // eslint-disable-next-line
  }, []);

  const getData = async () => {
    try {
      const data = await getAddressParties(ptsAddressID);
      if (!mountedRef.current) return;
      setData(processData(data));
      setLoaded(true);
    } catch (err) {
      props.handleError(err, 'Error receiving event location data.');
    }
  };

  const processData = (data) => {
    return data.map((row) => {
      return {
        ...row,
        id: row.ROWGUID,
        Name: getPartyName(row),
        PartyID: getPartyID(row),
        Relationship: row.AddressPartyRelationship,
        RelationshipDescription: row.RelationshipDescription,
      };
    });
  };

  const viewDetails = () => {
    const item = data.find((row) => row.id === selection);
    if (!item) return;
    props.showDisplayDataWindow({ data: filterData(item), title: item.Name });
  };

  const filterData = (rawData) => {
    const {
      Name,
      PartyID,
      PartyType,
      PriorityOrder,
      AdvisoryText,
      Relationship,
      RelationshipDescription,
      CreatedBy,
      Updated,
      UpdatedBy,
      IsDeleted,
      DeletedBy,
      DeletedIP,
    } = rawData;
    return {
      Name,
      PartyID,
      PartyType,
      PriorityOrder,
      AdvisoryText,
      Relationship,
      RelationshipDescription,
      CreatedBy,
      Updated,
      UpdatedBy,
      IsDeleted,
      DeletedBy,
      DeletedIP,
    };
  };

  const handleAddPartyClick = () => {
    clearData();
    setEdited(true);
  };

  const clearData = () => {
    setPerson(null);
    setPlace(null);
    setPriorityOrder(0);
    setPartyRelationship(null);
    setAdvisoryText('');
    clearErr();
    setROWGUID();
    setPtsPersonID();
    setEditedPlace();
  };

  const delParty = async () => {
    const item = data.find((row) => row.id === selection);
    if (!item) return;
    if (!window.confirm('Are you sure you want to delete this record?')) return;
    setLoaded(false);
    try {
      await delAddressParty(item.ROWGUID);
      if (!mountedRef.current) return;
      await getData();
    } catch (err) {
      props.handleError(err);
    }
  };

  const getPlace = async (ptsPlaceID) => {
    let place;
    setLoaded(false);
    try {
      place = await getPlaceDetails(ptsPlaceID);
    } catch (err) {
      props.handleError(err);
    }
    setLoaded(true);
    return place;
  };

  const editParty = async () => {
    const item = data.find((row) => row.id === selection);
    if (!item) return;
    const { ptsPartyID, PartyType, Relationship, PriorityOrder, AdvisoryText, ROWGUID } = item;
    const PartyRelationship = dictionary.PartyRelationships.find((p) => p.Code === Relationship);
    if (!PartyRelationship) return;
    if (PartyType === 'Person') {
      setPtsPersonID(ptsPartyID);
      setPerson({ ptsPersonID: ptsPartyID });
    } else {
      const place = await getPlace(ptsPartyID);
      setEditedPlace(place);
      setPlace(item);
    }
    setPriorityOrder(PriorityOrder);
    setAdvisoryText(AdvisoryText);
    setROWGUID(ROWGUID);
    setEdited(true);
    setPartyRelationship(PartyRelationship);
    SetPartyType(PartyType);
  };

  const renderActions = () => {
    return (
      <div className={classes.actions}>
        <Tooltip title="Add party">
          <Fab
            size="small"
            color="secondary"
            disabled={!props.canSave}
            onClick={handleAddPartyClick}>
            <AddIcon />
          </Fab>
        </Tooltip>
        <PrintsSearch title="SOP Records" data={data} cols={columns} hiddenCols={hiddenColumns} />
        {selection !== null && (
          <>
            <Tooltip title="View details">
              <Fab size="small" color="secondary" onClick={viewDetails}>
                <InfoIcon />
              </Fab>
            </Tooltip>
            {/* <Tooltip title="Edit party">
              <Fab size="small" color="secondary" onClick={editParty}>
                <EditIcon />
              </Fab>
            </Tooltip> */}
            {/* <Tooltip title="Delete party">
              <Fab size="small" color="secondary" onClick={delParty}>
                <DeleteIcon />
              </Fab>
            </Tooltip> */}
          </>
        )}
      </div>
    );
  };

  const renderXGrid = () => {
    return (
      <div className={classes.gridWrap}>
        <XGrid
          columns={columns}
          rows={data}
          loading={!loaded}
          rowHeight={38}
          disableMultipleSelection={true}
          showToolbar
          disableColumnFilter
          disableSelectionOnClick={edited}
          onSelectionModelChange={(newSelection) => {
            setSelection(newSelection.selectionModel[0]);
          }}
          onColumnVisibilityChange={(col) => setHiddenColumns([...hiddenColumns, col.field])}
          getRowClassName={(params) => (params.row.ActiveWarrant ? classes.warrant : null)}
        />
      </div>
    );
  };

  const onEditClose = () => {
    clearData();
    SetPartyType('Person');
    setEdited(false);
  };

  const renderEditActions = () => {
    return (
      <div className={classes.editActions}>
        <hr />
        <Button
          color="primary"
          variant="contained"
          onClick={save}
          disabled={!isValidAndChanged() || !loaded || !props.canSave}>
          <SaveIcon /> Save
        </Button>
        <Button color="primary" onClick={onEditClose} className={classes.closeButton}>
          <CloseIcon /> Close
        </Button>
      </div>
    );
  };

  const save = async () => {
    let ptsPartyID;
    if (PartyType === 'Person') {
      ptsPartyID = person.ptsPersonID;
    } else {
      ptsPartyID = place.ptsPlaceID;
    }

    const data = {
      ptsAddressID,
      ptsPartyID,
      PartyType,
      AddressPartyRelationship: PartyRelationship.Code,
      PriorityOrder: parseInt(PriorityOrder),
      AdvisoryText,
      ROWGUID,
    };
    setLoaded(false);
    try {
      await saveAddressParty(data);
      if (!mountedRef.current) return;
      onEditClose();
      await getData();
    } catch (err) {
      props.handleError(err);
    }
  };

  const renderAdvisoryText = () => {
    const onChange = (ev) => {
      setAdvisoryText(ev.target.value);
      formChanged();
    };

    return (
      <RMSTextField
        className={classes.w100pr}
        label="Advisory Text"
        placeholder="Advisory Text"
        multiline
        value={AdvisoryText}
        onChange={onChange}
        rows={4}
        inputProps={{ style: { height: 102 } }}
      />
    );
  };

  const renderType = () => {
    const onChange = (ev) => {
      setPerson(null);
      setPlace(null);
      delErr(['Place', 'Person']);
      SetPartyType(ev.target.value);
    };

    return (
      <FormControl variant="outlined" size="small" className={classes.item}>
        <InputLabel>Party</InputLabel>
        <Select label="Party" value={PartyType} onChange={onChange}>
          <MenuItem value="Person">Person</MenuItem>
          <MenuItem value="Place">Place</MenuItem>
        </Select>
      </FormControl>
    );
  };

  const renderPriority = () => {
    const onChange = (ev, val) => {
      setPriorityOrder(parseInt(val));
      formChanged();
    };

    return (
      <RMSTextField
        className={classes.item}
        value={PriorityOrder}
        onChange={onChange}
        min={0}
        label="Priority Order"
        type="number"
      />
    );
  };

  const renderPlaceSearch = () => {
    const error = err(place, 'Place');
    return (
      <PlaceLookup
        error={error}
        ptsPlaceID={editedPlace?.ptsPlaceID}
        onChange={setPlace}
        className={classes.item}
      />
    );
  };

  const renderPersonLookup = () => {
    const onChange = (val) => {
      setPerson(val);
      setPtsPersonID();
      formChanged();
    };
    const error = err(person, 'Person');
    return (
      <PersonLookup2
        className={classes.item}
        onPersonChange={onChange}
        error={error}
        label="Search Person"
        ptsPersonID={ptsPersonID}
      />
    );
  };

  const renderPartyRelationship = () => {
    const onChange = (ev, val) => {
      setPartyRelationship(val);
      formChanged();
    };
    const error = err(PartyRelationship, 'party');
    return (
      <Dictionary
        className={classes.item}
        options="codeAddressPartyRelationship"
        onChange={onChange}
        value={PartyRelationship}
        label="Party Relationship"
        error={error}
      />
    );
  };

  const renderEditForm = () => {
    return (
      <Row>
        <ColCard minWidth={500} maxWidth={960}>
          <Row style={{ display: 'flex' }}>
            <div className={classes.col1}>
              <RowInner>
                {renderType()}
                {renderPriority()}
              </RowInner>
              <RowInner>
                {PartyType === 'Person' && renderPersonLookup()}
                {PartyType === 'Place' && renderPlaceSearch()}
                {renderPartyRelationship()}
              </RowInner>
            </div>
            <div className={classes.col2}>
              <RowInner>{renderAdvisoryText()}</RowInner>
            </div>
          </Row>
          <div>{renderEditActions()}</div>
        </ColCard>
      </Row>
    );
  };

  return (
    <div className={classes.wrap}>
      {edited && renderEditForm()}
      {!edited && renderActions()}
      {renderXGrid()}
    </div>
  );
}

const mapStateToProps = (state) => ({
  dictionary: state.dictionary,
});

export default connect(mapStateToProps, {
  handleError,
  showDisplayDataWindow,
})(AddressParties);
