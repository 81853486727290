import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { closePersonFeature } from 'reducers/PersonDialogsReducer';
import Dialog from 'components/RMSDialog';
import BackDatedRecordHistory from 'components/BackDatedRecordHistory';
import Autocomplete2 from 'components/RMSAutoComplete/RMSAutocomplete2';
import Dictionary from 'components/RMSAutoComplete/RMSDictonary';

import { gridStyle, formatSaveData } from 'utils/formStyles';
import { addPersonFeature, editPersonFeature } from 'reducers/SearchReducer';
import { handleError } from 'reducers/ErrorReducer';
import { notifyDataUpdate } from 'reducers/DataUpdateReducer';

const useStyles = makeStyles((theme) => ({
  item: gridStyle(200, 800),
  closeButton: { color: theme.button.color },
}));

const attributeCategories = [
  'Appearance',
  'Builds',
  'Complexions',
  'EyeDefect',
  'FacialHair',
  'Glasses',
  'MethodOfOperation',
  'SpeechQuality',
  'Talk',
  'Teeth',
];

function ExampleWindow(props) {
  const classes = useStyles();
  const { data } = props;
  const { ptsPersonID } = data;
  const [AttributeCategory, setAttributeCategory] = useState(null);
  const [Attribute, setAttribute] = useState(null);

  const [recordHistoryData, setRecordHistoryData] = useState({
    shouldVisible: true,
    isDisabled: true,
    createUpdateInfo: {},
  });
  const [showRecordHistory, setShowRecordHistory] = useState(false);

  useEffect(() => {
    if (data) {
      setRecordHistoryData({
        shouldVisible: true,
        isDisabled: !data.Created,
        createUpdateInfo: {
          created: data.Created,
          createdBy: data.CreatedBy,
          updated: data.Updated,
          updatedBy: data.UpdatedBy,
        },
      });
    }
    if (data.AttributeCategory) {
      setAttributeCategory(data.AttributeCategory);
    }
    if (data.Attribute) {
      setAttribute(data.Attribute);
    }
  }, [data]);

  const handleCloseRecordHistoryDialog = () => setShowRecordHistory(false);

  const close = () => {
    props.closePersonFeature();
  };

  const save = async () => {
    const featureData = formatSaveData({
      Attribute,
      AttributeCategory,
      ptsPersonID,
    });
    try {
      if (data.ptsFeatureID) {
        featureData.ptsFeatureID = data.ptsFeatureID;
        await editPersonFeature(data.ptsFeatureID, featureData);
      } else {
        await addPersonFeature(featureData);
      }
      props.notifyDataUpdate({ type: 'person-feature', data: {} });
      props.closePersonFeature();
    } catch (err) {
      props.handleError(err, 'Error saving feature');
    }
  };

  const renderActions = () => {
    return (
      <>
        {recordHistoryData?.shouldVisible && (
          <Box className="">
            <BackDatedRecordHistory
              showRecordHistory={showRecordHistory}
              setShowRecordHistory={setShowRecordHistory}
              disabled={recordHistoryData?.isDisabled}
              handleCloseRecordHistoryDialog={handleCloseRecordHistoryDialog}
              createUpdateInfo={recordHistoryData?.createUpdateInfo}></BackDatedRecordHistory>
          </Box>
        )}
        <Button color="primary" variant="contained" autoFocus onClick={save} disabled={!Attribute}>
          <SaveIcon /> Save
        </Button>
        <Button color="primary" onClick={close} className={classes.closeButton}>
          <CloseIcon /> Close
        </Button>
      </>
    );
  };

  const renderAttributeCategory = () => {
    const handleChange = (ev, val) => {
      setAttributeCategory(val);
      setAttribute(null);
    };
    return (
      <Autocomplete2
        options={attributeCategories}
        className={classes.item}
        onChange={handleChange}
        value={AttributeCategory}
        label="Attribute Category"
        getOptionSelected={(option, value) => option === value}
        getOptionLabel={(option) => option}
        renderOption={(option) => <span>{option}</span>}
      />
    );
  };

  const renderAttributeCode = () => {
    const handleChange = (ev, val) => {
      setAttribute(val);
    };
    return (
      <Dictionary
        options={AttributeCategory}
        className={classes.item}
        onChange={handleChange}
        value={Attribute}
        formType="add"
        label="Attribute"
        compact
      />
    );
  };

  return (
    <Dialog
      toolbar
      onClose={close}
      title={data.ptsFeatureID ? 'Edit Feature' : 'Add Feature'}
      actions={renderActions()}>
      {renderAttributeCategory()}
      {AttributeCategory && renderAttributeCode()}
    </Dialog>
  );
}

export default connect(null, { closePersonFeature, handleError, notifyDataUpdate })(ExampleWindow);
