export const colors = {
  first: '#5383ff',
  second: 'black',
  blue: '#263055',
  defaultBlue: '#1e8dfa',
  defaultBlueDarker: '#1976d2',
  defaultBlueDarker2: '#0b55a1',
  indigo: '#7420ff',
  purple: '#793de6',
  pink: '#fc26a4',
  red: '#f83245',
  orange: '#f4772e',
  yellow: '#ffc926',
  green: '#1bc943',
  teal: '#18e1a5',
  cyan: '#11c5db',
  gold: '#ffd700',
  tulipTree: '#eab33b',
  curiousBlue: '#2596d1',
  cadetBlue: 'abb5c8',
  cadetBlue2: '#a9b2c3',
  albescentWhite: 'f6eada',
};
