import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent } from '@material-ui/core';
import splashScreen from '../assets/images/login-splash.jpg';

const useStyles = makeStyles(() => ({
  pageWrap: {
    minHeight: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: `url(${splashScreen})`,
    backgroundSize: 'cover',
  },
  card: {
    width: 450,
    maxWidth: '95%',
    textAlign: 'center',
    '& img': {
      width: '100%',
    },
  },
}));

export default function Page404() {
  const classes = useStyles();

  return (
    <div className={classes.pageWrap}>
      <Card className={classes.card}>
        <CardContent>
          <h1>
            Error 404.
            <br /> Page not found.
          </h1>
        </CardContent>
      </Card>
    </div>
  );
}
