import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  wrap: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'rgba(255, 255, 255, 0.4)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 9999,
    '& svg': {
      color: '#0153a3',
    },
  },
}));

function FormSpinner() {
  const classes = useStyles();

  return (
    <div className={classes.wrap}>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <CircularProgress />
        <Typography style={{ color: 'black', fontSize: '18px', marginTop: '8px' }}>
          Please wait...
        </Typography>
      </div>
    </div>
  );
}

export default FormSpinner;
