const propertieRelatedsUrls = [
  '/records/:type',
  '/records/add/item',
  '/records/edit/item',
  '/records/edit/firearm',
  '/records/add/firearm',
  '/records/add/jewelry',
  '/records/edit/jewelry',
  '/records/edit/vehicle/:vehicleId',
  '/records/add/vehicle',
  '/records/add/substance',
  '/records/edit/substance',
  '/records/edit/structure',
  '/records/add/structure',
  '/search/vehicle-towings',
];

const personRelatedUrls = [
  '/search/images',
  '/search/credentials',
  '/search/phone-numbers',
  '/names',
];
const formatUrls = (list = [], parent) => {
  const obj = {};
  list.forEach((item) => {
    obj[item] = { parent };
  });
  return obj;
};
const routePermissionDefination = {
  '/search/agencies': {
    name: 'Agencies',
    type: 'globals',
    agency: 'any',
  },
  '/search/employees': {
    name: 'Employees',
    type: 'globals',
    agency: 'any',
  },

  '/search/zones': {
    name: 'Zones',
    type: 'globals',
    agency: 'any',
  },
  '/properties': {
    name: 'Property',
    type: 'rms',
    agency: 'specific',
  },
  '/persons': {
    name: 'People',
    type: 'globals',
    agency: 'any',
  },

  ...formatUrls(personRelatedUrls, '/persons'),
  ...formatUrls(propertieRelatedsUrls, '/properties'),
};
export default routePermissionDefination;
