import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { makeStyles, MenuItem, Typography } from '@material-ui/core';

import { displayDateTime } from 'reducers/TimeReducer';
import { setIsFormDirty } from 'reducers/IncReducer';

import RMSConfirmationModal from 'components/RMSModals/RMSConfirmationModal/index';
import { getFullPermissions } from 'reducers/PermissionsReducer';

const useStyles = makeStyles((theme) => ({
  fieldsetWrapperShareActivity: {
    border: '1px solid #1e8dfa',
    padding: '15px 15px 15px',
    marginTop: '10px',
    marginBottom: '10px',
    width: '200px',
  },
  fieldsetTitleShareActivity: {
    position: 'absolute',
    marginTop: '-28px',
    marginLeft: '-5px',
    color: 'white',
    background: '#1e8dfa',
    borderRadius: '10px',
    padding: '2px 8px',
  },
  fieldsetWrapperActivityList: {
    border: '1px solid #1e8dfa',
    padding: '15px 5px 10px',
    margin: '10px',
    width: '240px',
  },
  fieldsetTitleActivityList: {
    position: 'absolute',
    marginTop: '-28px',
    color: 'white',
    background: '#1e8dfa',
    borderRadius: '10px',
    padding: '2px 8px',
  },
  activityWrapper: {
    minHeight: '100px',
    marginTop: '10px',
  },
  text: {
    color: theme.palette.colorFont,
  },
}));

const RMSTopbarShareDialog = (props) => {
  const { sharesData, readShare } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const userAgency = useSelector((state) => state.user.userAgency);
  const incidentsPermissions = getFullPermissions('rms', 'Incidents', userAgency);

  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [shareID, setShareID] = useState(null);
  const [incidentID, setIncidentID] = useState(null);

  const isFormDirty = useSelector((state) => state.incident.isFormDirty);

  const handleShareItemClick = (shareId, incidentId) => {
    setShareID(shareId);
    setIncidentID(incidentId);

    if (isFormDirty) setOpenConfirmation(true);
    else {
      setShareID(null);
      setIncidentID(null);

      readShare(shareId, incidentId);
    }
  };

  const handleConfirmationClick = () => {
    dispatch(setIsFormDirty(false));
    readShare(shareID, incidentID);
    handleCloseDialog();
  };

  const handleCloseDialog = () => {
    setOpenConfirmation(false);
    setShareID(null);
    setIncidentID(null);
  };

  return (
    <>
      <div className={classes.fieldsetWrapperActivityList}>
        <div className={classes.fieldsetTitleActivityList}>Latest Share Activity</div>
        {incidentsPermissions.Read && (
          <div className={classes.activityWrapper}>
            <PerfectScrollbar>
              {!sharesData && <p className="ml-2">No Shares</p>}

              {sharesData && sharesData?.length === 0 && <p className="ml-2">No Shares</p>}
              {sharesData &&
                sharesData.map((item) => {
                  let fullName;
                  if (item.sharedFrom.length > 0) {
                    const { FirstName, MiddleName, LastName } = item.sharedFrom[0]?.Person;

                    fullName = `${LastName}, ${FirstName} ${MiddleName}`;
                  }

                  return (
                    <MenuItem
                      onClick={() =>
                        handleShareItemClick(item.ptsShareID, item.Incident.ptsIncidentID)
                      }>
                      <div className={classes.fieldsetWrapperShareActivity}>
                        <div className={classes.fieldsetTitleShareActivity}>
                          {displayDateTime(item.ShareDate)}
                        </div>
                        <div className="mx-1 mt-1">
                          <Typography style={{ fontWeight: 'bolder' }}>
                            {item.Incident.IncidentID}
                          </Typography>
                          <div
                            style={{
                              whiteSpace: 'initial',
                              minWidth: '150px',
                              fontWeight: 'bolder',
                            }}>
                            Agency: {item.AgencyID}
                          </div>
                          <div
                            style={{
                              whiteSpace: 'initial',
                              minWidth: '150px',
                              fontWeight: 'bolder',
                            }}>
                            Shared By: {fullName}
                          </div>
                          <div
                            className="opacity-8"
                            style={{ whiteSpace: 'initial', minWidth: '150px' }}>
                            <p className="pr-1">{item.Comment}</p>
                          </div>
                        </div>
                      </div>
                    </MenuItem>
                  );
                })}
            </PerfectScrollbar>
          </div>
        )}
        {!incidentsPermissions.Read && (
          <div className="py-3 px-2">
            <h6 className={classes.text}>You don't have permission to see the list</h6>
          </div>
        )}
      </div>
      <RMSConfirmationModal
        title="You have unsaved changes!"
        text="You are about to leave this incident to access a different incident. Any unsaved data will be lost. Do you wish to proceed?"
        saveBtnName="Yes"
        noBtnName="No"
        open={openConfirmation}
        closeDialog={handleCloseDialog}
        handleModalSave={handleConfirmationClick}
      />
    </>
  );
};

export default RMSTopbarShareDialog;
