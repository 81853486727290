import { getService } from './service';
import { handleError } from './ErrorReducer';
import store from 'config/configureStore';
import { DICTIONARY_PERMISSIONS } from './PermissionsReducer';
import { hideSpinner, showSpinner } from './UiReducer';

const GET_DICTIONARY = 'GET_DICTIONARY';
const ADD_DICTIONARY_VALUE = 'ADD_DICTIONARY_VALUE';
const UPDATE_DICTIONARY_VALUE = 'UPDATE_DICTIONARY_VALUE';

let dictionaryService;
export const subscribeDictionary = () => {
  return async (dispatch) => {
    try {
      dictionaryService = getService('dictionary');
      dictionaryService.on('updated', (models) => {
        const state = store.store.getState();
        const { dictionary } = state;
        Object.keys(dictionary).forEach((dict) => {
          if (models.indexOf(dict.toLocaleLowerCase()) !== -1) {
            dispatch(getDictionaries([dict]));
          }
        });
      });
      dictionaryService.on('error', () => console.log('Error in dictionary service listener'));
      dictionaryService.on('unhandledRejection', (reason, p) => {
        console.log('DectionaryReducer Unhandled Rejection at: Promise ', p, ' reason: ', reason);
      });
    } catch (error) {
      dispatch(handleError(error));
    }
  };
};

export const unsubscribeDictionary = () => {
  if (dictionaryService) {
    try {
      dictionaryService.off('created');
      dictionaryService.off('error');
      dictionaryService.off('unhandledRejection');
      dictionaryService = false;
    } catch (error) {
      console.log('DectionaryReducer/unsubscribeDictionary: error: ', error, error.code);
    }
  }
  return () => {};
};

export const getDictionary = (dictionary) => {
  const service = getService('rms-dictionary');
  return service.get(dictionary);
};

export const getDictionaries = (options = 'rms') => {
  const userAgency = store.store.getState().user?.userAgency;

  return async (dispatch) => {
    try {
      dispatch(showSpinner());
      const service = getService('rms-dictionary');
      const data = await service.find({
        query: { options },
      });

      /* Filter zones options based on the user's default agency */
      const codeZonesFiltered = data.dictionary.codeZones.filter((obj) => {
        return obj.AgencyID === userAgency;
      });
      data.dictionary.codeZones = codeZonesFiltered;

      /* Combining ptsStatute & ptsFBICode data into statuteFBICodes */
      let arr = [];
      let sortedArray = data.dictionary.ptsStatute
        .filter((s) => s.Description !== '')
        .sort((a, b) => (a.Description > b.Description ? 1 : -1));

      sortedArray.forEach((s) => {
        let fbCode = data.dictionary.ptsFBICode.find((fb) => fb.ptsFBICodeID === s.ptsFBICodeID)
          ?.FBICode;
        s.FBICode = fbCode;
        arr.push(s);
      });
      data.dictionary.statuteFBICodes = arr;
      if (Object.keys(data.dictionary).length > 0) {
        dispatch(hideSpinner());
      }
      dispatch({ type: GET_DICTIONARY, payload: data.dictionary });
      dispatch({ type: DICTIONARY_PERMISSIONS, payload: data.permissions });
    } catch (error) {
      dispatch(handleError(error));
    }
  };
};

export const saveDictionary = (dictionary, data) => {
  const service = getService('dictionary');
  if (data.ROWGUID) {
    return service.patch(dictionary, data);
  } else {
    delete data.ROWGUID;
    return service.create({ data, dictionary });
  }
};

export const deleteDictionary = (dictionary, ROWGUID) => {
  const service = getService('dictionary');
  return service.remove(dictionary, { query: { ROWGUID } });
};

export const addDictionaryValue = (value, name) => {
  return { type: ADD_DICTIONARY_VALUE, payload: { value, name } };
};

export const updateDictionaryValue = (value, name) => {
  return { type: UPDATE_DICTIONARY_VALUE, payload: { value, name } };
};

export default function reducer(state = {}, action) {
  switch (action.type) {
    case GET_DICTIONARY:
      return { ...state, ...action.payload };
    case ADD_DICTIONARY_VALUE:
      return {
        ...state,
        [action.payload.name]: [action.payload.value, ...state[action.payload.name]],
      };
    case UPDATE_DICTIONARY_VALUE:
      return {
        ...state,
        [action.payload.name]: [...action.payload.value],
      };
    default:
      return state;
  }
}
