import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';

import Fab from '@material-ui/core/Fab';
import Tooltip from 'components/Tooltip';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import DeleteIcon from '@material-ui/icons/Delete';

import RMSXGrid from 'components/RMSXGrid';
import RMSTextField from 'components/RMSTextField';
import PrintsSearch2 from 'components/RMSSearch/components/PrintsSearch2';
import RMSAutocomplete2 from 'components/RMSAutoComplete/RMSAutocomplete2';
import QuickFilterSearch from 'components/RMSSearch/components/QuickFilterSearch';

import { handleError } from 'reducers/ErrorReducer';
import { notifyDataUpdate } from 'reducers/DataUpdateReducer';
import { showSpinner, hideSpinner } from 'reducers/UiReducer';
import { displayDateTime } from 'reducers/TimeReducer';
import { getFullPermissions } from 'reducers/PermissionsReducer';
import formStyles, { gridStyle, RowInner, formatSaveData } from 'utils/formStyles';
import { getPeopleTags, savePersonTags, removePersonTags } from 'reducers/SearchReducer';

const useStyles = makeStyles((theme) => ({
  ...formStyles,
  item: gridStyle(500, 500),
  wrap: {
    padding: theme.spacing(3),
  },
  filterWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(2),
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
  lookupActions: {
    marginLeft: 8,
    '& button': {
      marginLeft: 8,
    },
    '& svg': {
      marginRight: 8,
    },
  },
  closeButton: { color: theme.button.color },
}));

const bool = (params) => (params.value ? <CheckIcon /> : <span></span>);

const defaultColumns = [
  { field: 'ptsTagID', headerName: 'Tag ID' },
  'ParentType',
  'Type',
  { field: 'Value', headerName: 'Description' },
  { field: 'IsDeleted', renderCell: bool },
  {
    field: 'Created',
    headerName: 'Created',
    width: 200,
    valueFormatter: (params) => displayDateTime(params.value),
    hide: false,
  },
  { field: 'CreatedBy', headerName: 'Created By', width: 150, hide: false },
  {
    field: 'Updated',
    headerName: 'Updated',
    width: 200,
    valueFormatter: (params) => displayDateTime(params.value),
    hide: false,
  },
  { field: 'UpdatedBy', headerName: 'Updated By', width: 150, hide: false },
];

const Tags = (props) => {
  const classes = useStyles();
  const { ptsPersonID, user, codeTagTypes, addTags, setAddTags, dataUpdate } = props;
  const [rows, setRows] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [filteredRows, setFilteredRows] = useState([]);
  const [selection, setSelection] = useState(null);
  const [columns, setColumns] = useState(defaultColumns);
  const [editing, setEditing] = useState(false);
  const [type, setType] = useState(null);
  const [description, setDescription] = useState(null);
  const [actionType, setActionType] = useState(null);
  const [tagTypes, setTagTypes] = useState([]);
  const mountedRef = useRef(true);
  const perms = getFullPermissions('globals', 'People', 'any');
  const canEditPerson = perms.Edit;
  useEffect(() => {
    ptsPersonID && getData();
  }, [dataUpdate]);

  useEffect(() => {
    if (addTags && loaded) {
      onAdd();
      setAddTags(false);
    }
  }, [addTags, loaded]);

  useEffect(() => {
    let filteredTags = [];

    codeTagTypes.forEach((t) => {
      let found = false;

      rows.forEach((r) => {
        if (r.IsDeleted === true) return;

        if (t.Code === r.Type) found = true;
      });

      if (!found && t.Parent === 'Person') filteredTags.push(t);
    });

    setTagTypes(filteredTags);
  }, [rows, codeTagTypes]);

  const getData = async () => {
    setLoaded(false);
    try {
      const data = await getPeopleTags(ptsPersonID);

      if (!mountedRef.current) return;

      setRows(processData(data.data));
      setLoaded(true);
      // setEditing(false);
      setActionType(null);
    } catch (err) {
      props.handleError(err);
    }
  };

  const processData = (data) => {
    return data.map((row) => {
      return {
        id: row.ptsTagID,
        ptsTagID: row.ptsTagID,
        ParentType: row.ParentType,
        Type: row.Type,
        Value: row.Value,
        IsDeleted: row.IsDeleted,
        Created: row.Created,
        CreatedBy: row.CreatedBy,
        Updated: row.Updated,
        UpdatedBy: row.UpdatedBy,
      };
    });
  };

  const getCurrentItem = () => rows.find((a) => a.ptsTagID === selection);

  const save = () => {
    let data;
    if (actionType === 'add') {
      data = formatSaveData({
        ParentType: 'Person',
        ptsParentID: ptsPersonID,
        Type: type,
        Value: description,
        IsLocked: false,
        IsSealed: false,
        IsDeleted: false,
        CreatedBy: user,
        UpdatedBy: user,
      });
    } else if (actionType === 'edit') {
      data = formatSaveData({
        ptsTagID: selection,
        ParentType: 'Person',
        ptsParentID: ptsPersonID,
        Type: type,
        Value: description,
        IsLocked: false,
        IsSealed: false,
        IsDeleted: false,
        UpdatedBy: user,
      });
    }

    props.showSpinner();
    savePersonTags(data)
      .then(() => {
        onClose();
      })
      .catch(props.handleError)
      .finally(() => {
        props.hideSpinner();
        props.setData();
        props.notifyDataUpdate({ type: 'Person', data: {} });
      });
  };

  const renderEdit = () => {
    return (
      <>
        <div>
          <RowInner>
            <RMSAutocomplete2
              options={tagTypes}
              value={type}
              label="Tag Type"
              className={classes.item}
              onChange={(ev, val) => setType(val)}
            />
            <RMSTextField
              className={classes.item}
              label="Description"
              value={description}
              onChange={(ev, val) => setDescription(val)}
              max={100}
              disabled={!type}
            />
          </RowInner>
        </div>

        <div className={classes.lookupActions}>
          <Button color="primary" variant="contained" autoFocus onClick={save} disabled={!type}>
            <SaveIcon /> Save
          </Button>
          <Button color="primary" onClick={() => onClose()} className={classes.closeButton}>
            <CloseIcon /> Close
          </Button>
        </div>
      </>
    );
  };

  const onClose = () => {
    setEditing(false);
    setActionType(null);
  };

  const onAdd = () => {
    setEditing(true);
    setActionType('add');
    setType(null);
    setDescription(null);
  };
  const renderActions = () => {
    const onEdit = () => {
      const item = getCurrentItem();
      setType(item.Type);
      setDescription(item.Value);
      setEditing(true);
      setActionType('edit');
    };

    const onDelete = () => {
      let query = {
        ptsTagID: selection,
        IsDeleted: false,
      };

      props.showSpinner();
      removePersonTags(query)
        .then(() => {})
        .catch(props.handleError)
        .finally(() => {
          props.hideSpinner();
          props.setData();
          props.notifyDataUpdate({ type: 'Person', data: {} });
        });
    };

    return (
      <div className={classes.actions}>
        <Tooltip title="Print">
          <PrintsSearch2 title="Tags" data={filteredRows} cols={columns} />
        </Tooltip>
        <Tooltip title="Add Tags">
          <Fab size="small" color="secondary" disabled={!canEditPerson} onClick={onAdd}>
            <AddIcon />
          </Fab>
        </Tooltip>
        {selection !== null && (
          <>
            <Tooltip title="Edit Tag">
              <Fab size="small" color="secondary" disabled={!canEditPerson} onClick={onEdit}>
                <EditIcon />
              </Fab>
            </Tooltip>
            <Tooltip title="Delete Tag">
              <Fab size="small" color="secondary" disabled={!canEditPerson} onClick={onDelete}>
                <DeleteIcon />
              </Fab>
            </Tooltip>
          </>
        )}
      </div>
    );
  };

  return (
    <div className={classes.wrap}>
      {editing && (
        <div className={classes.filterWrap}>
          <div className={classes.filterWrap}>{renderEdit()}</div>
        </div>
      )}
      {!editing && (
        <div className={classes.filterWrap}>
          <div className={classes.filterWrap}>
            <QuickFilterSearch
              rows={rows}
              columns={columns}
              setFilteredRows={setFilteredRows}
              style={{ marginTop: 0, paddingBottom: 14 }}
            />
            {renderActions()}
          </div>
        </div>
      )}
      <RMSXGrid
        name="PeopleTags"
        columns={columns}
        setColumns={setColumns}
        rows={filteredRows}
        loading={!loaded}
        setSelection={setSelection}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user.userData?.user.Username,
    codeTagTypes: state.dictionary.codeTagTypes,
    dataUpdate: state.dataUpdate,
  };
};

export default connect(mapStateToProps, {
  handleError,
  showSpinner,
  hideSpinner,
  notifyDataUpdate,
})(Tags);
