export const form = [
  // General
  {
    label: 'Event Type',
    serviceName: 'codeincidentcategories',
    fields: [['Code', 'Description']],
  },
  {
    label: 'Status',
    serviceName: 'codecadstatuses',
    fields: [['Code', 'Description']],
  },
  {
    label: 'Category',
    serviceName: 'codeincidenttypes',
    fields: [['Code', 'Description']],
  },
  {
    label: 'City',
    serviceName: 'codecities',
    fields: [
      // TODO: check ptsCityID is the right choice.
      ['ptsCityID', 'CityDescription'],
    ],
  },
  {
    label: 'Person Race NDEX',
    serviceName: 'coderace',
    fields: [['Code', 'Description']],
  },
  {
    label: 'Person Sex',
    serviceName: 'codesex',
    fields: [['Code', 'Description']],
  },
  {
    label: 'Person Ethnicity',
    serviceName: 'codeethnicity',
    fields: [['Code', 'Description']],
  },
  {
    label: 'suffix',
    serviceName: 'codenamesuffixes',
    fields: [['Code', 'Description']],
  },
  {
    label: 'Eye Color',
    serviceName: 'codeeyecolor',
    fields: [['Code', 'Description']],
  },
  {
    label: 'Hair Color',
    serviceName: 'codehaircolor',
    fields: [['Code', 'Description']],
  },
  {
    label: '',
    serviceName: '',
    fields: [['Code', 'Description']],
  },
  {
    label: 'Hair Style',
    serviceName: 'codehairstyle',
    fields: [['Code', 'Description']],
  },
  {
    label: 'Hair Length',
    serviceName: 'codehairlength',
    fields: [['Code', 'Description']],
  },
  {
    label: 'Blood Type',
    serviceName: 'codebloodtype',
    fields: [['Code', 'Description']],
  },
  {
    label: 'Handed',
    serviceName: 'codehanded',
    fields: [['Code', 'Description']],
  },
  {
    label: 'State',
    serviceName: 'codestates',
    fields: [['Code', 'Description']],
  },
  {
    label: 'Weather',
    serviceName: 'codeweather',
    fields: [['Code', 'Description']],
  },
  {
    label: 'Lighting',
    serviceName: 'codelighting',
    fields: [['Code', 'Description']],
  },
  {
    label: 'Type',
    serviceName: 'codestreettypes',
    fields: [['Code', 'Description']],
  },
  {
    label: 'Predirectional',
    serviceName: 'codestreetdirections',
    fields: [['Code', 'Description']],
  },
  {
    label: 'Postdirectional',
    serviceName: 'codestreetdirections',
    fields: [['Code', 'Description']],
  },
  {
    label: 'Condition',
    serviceName: 'codeitemconditions',
    fields: [['Code', 'Description']],
  },
  {
    label: 'Make',
    serviceName: 'codeitemmakes',
    fields: [['Code', 'Description']],
  },
  {
    label: 'Model',
    serviceName: 'codeitemmodels',
    fields: [['Code', 'Description']],
  },
  {
    label: 'Caliber',
    serviceName: 'codefirearmcaliber',
    fields: [['Code', 'Description']],
  },
  {
    label: 'Firearm Category',
    serviceName: 'codefirearmcategory',
    fields: [['Code', 'Description']],
  },
  {
    label: 'Barrel Length Measure',
    serviceName: 'codemeasures',
    fields: [['Code', 'Description']],
  },
  {
    label: '',
    serviceName: '',
    fields: [['Code', 'Description']],
  },
  // Evidence
  {
    label: 'Category',
    serviceName: 'codeevidencecategory',
    fields: [['Code', 'Description']],
  },

  // Properties
  {
    label: 'Material',
    serviceName: 'codematerial',
    fields: [['Code', 'Description']],
  },
  {
    label: 'Jewelry Category',
    serviceName: 'codejewelrycategory',
    fields: [['Code', 'Description']],
  },
  {
    label: 'Garage Type',
    serviceName: 'codegaragetypes',
    fields: [['Code', 'Description']],
  },
  {
    label: 'Primary Color',
    serviceName: 'codestructurecolors',
    fields: [['Code', 'Description']],
  },
  {
    label: 'Secondary Color',
    serviceName: 'codestructurecolors',
    fields: [['Code', 'Description']],
  },

  // Offense
  {
    label: 'Offense Factor',
    serviceName: 'codeoffensefactor',
    fields: [['Code', 'Description']],
  },
  {
    label: 'Substance Unit',
    serviceName: 'codedrugmeasurements',
    fields: [['Code', 'Description']],
  },
  {
    label: 'DEA Class',
    serviceName: 'codedeadrugclasses',
    fields: [['Code', 'Description']],
  },
  {
    label: 'Style',
    serviceName: 'codevehiclestyles',
    fields: [['Code', 'Description']],
  },
  {
    label: 'Primary Color',
    serviceName: 'codevehiclecolors',
    fields: [['Code', 'Description']],
  },
  {
    label: 'Secondary Color',
    serviceName: 'codevehiclecolors',
    fields: [['Code', 'Description']],
  },
  {
    label: 'Interior',
    serviceName: 'codematerial',
    fields: [['Code', 'Description']],
  },
  {
    label: 'Classification',
    serviceName: 'codevehicleclassifications',
    fields: [['Code', 'Description']],
  },
  {
    label: 'Brand',
    serviceName: 'codevehiclebrands',
    fields: [['Code', 'Description']],
  },
  {
    label: 'Brander',
    serviceName: 'codevehiclebranders',
    fields: [['Code', 'Description']],
  },
  {
    label: 'Property Code',
    serviceName: 'codevehicleproperty',
    fields: [['Code', 'Description']],
  },
  {
    label: 'Commercial Code',
    serviceName: 'codevehiclecommercial',
    fields: [['Code', 'Description']],
  },
  {
    label: 'Transmission Code',
    serviceName: 'codetransmissions',
    fields: [['Code', 'Description']],
  },
  {
    label: 'Garage Facility',
    serviceName: 'codegaragefacilities',
    fields: [['Code', 'Description']],
  },
  {
    label: 'DNA Collection',
    serviceName: 'codednacollection',
    fields: [['Code', 'Description']],
  },
  {
    label: 'Offender Involvement',
    serviceName: 'codesubjectinvolvement',
    fields: [['Code', 'Description']],
  },
  {
    label: 'Offender MO',
    serviceName: 'codesubjectmo',
    fields: [['Code', 'Description']],
  },
  {
    label: 'Offender Status',
    serviceName: 'codesubjectstatus',
    fields: [['Code', 'Description']],
  },
  {
    label: 'Offender Weapon',
    serviceName: 'codesubjectweapon',
    fields: [['Code', 'Description']],
  },
  {
    label: 'Make',
    serviceName: 'codevehiclemakes',
    fields: [['Code', 'Description']],
  },
  {
    label: 'Model',
    serviceName: 'codevehiclemakesmodels',
    fields: [['MakeCode', 'ModelCode', 'MakeDescription', 'ModelDescription']],
  },
  {
    label: 'Victim Category',
    serviceName: 'codevictimcategory',
    fields: [['Code', 'Description']],
  },
  {
    label: 'Victim Disposition',
    serviceName: 'codevictimdisposition',
    fields: [['Code', 'Description']],
  },
  {
    label: 'Victim Treatment',
    serviceName: 'codevictimtreatment',
    fields: [['Code', 'Description']],
  },
  {
    label: 'Person Resident',
    serviceName: 'coderesident',
    fields: [['Code', 'Description']],
  },
  {
    label: 'Enforcement Official Activity Category',
    serviceName: 'codeofficeractivity',
    fields: [['Code', 'Description']],
  },
  {
    label: 'Enforcement Official Assignment Category',
    serviceName: 'codeofficerassignment',
    fields: [['Code', 'Description']],
  },
  {
    label: 'Item Status',
    serviceName: 'codepropertylosstype',
    fields: [['Code', 'Description']],
  },
  {
    label: 'Item Category NIBRS Property Category',
    serviceName: 'codepropertycategory',
    fields: [['Code', 'Description']],
  },
  {
    label: 'Property Description',
    serviceName: 'codepropertydescription',
    fields: [['Code', 'Description']],
  },
  {
    label: 'Arrest Category',
    serviceName: 'codearrestcategories',
    fields: [['Code', 'Description']],
  },
  {
    label: 'Arrestee Juvenile Disposition',
    serviceName: 'codejuveniledisposition',
    fields: [['Code', 'Description']],
  },
  {
    label: 'Arrestee Armed With',
    serviceName: 'codearresteearmed',
    fields: [['Code', 'Description']],
  },
  {
    label: 'Evidence Type',
    serviceName: 'codeevidencetype',
    fields: [['Code', 'Description']],
  },
  {
    label: 'Required Action',
    serviceName: 'codeevidencerequiredaction',
    fields: [['Code', 'Description']],
  },
  {
    label: 'Temporary Location',
    serviceName: 'codeevidencetemporarylocation',
    fields: [['Code', 'Description']],
  },
  {
    label: 'Custody From',
    serviceName: 'codecustodyoptions',
    fields: [['Code', 'Description']],
  },
  {
    label: 'Custody To',
    serviceName: 'codecustodyoptions',
    fields: [['Code', 'Description']],
  },
  {
    label: 'Disposal Code',
    serviceName: 'codeevidencedisposalcode',
    fields: [['Code', 'Description']],
  },
  {
    label: 'Status',
    serviceName: 'codeevidencecustodystatus',
    fields: [['Code', 'Description']],
  },
  {
    label: 'Reason',
    serviceName: 'codeevidencecustodyreason',
    fields: [['Code', 'Description']],
  },
  {
    label: 'Storage Location',
    serviceName: 'codeevidencestoragelocation',
    fields: [['Code', 'Description']],
  },
  {
    label: 'Sub-Location',
    serviceName: 'codeevidencestoragesublocation',
    fields: [['Code', 'Description', 'ParentCode']],
  },
  {
    label: 'Container',
    serviceName: 'codeevidencestoragecontainer',
    fields: [['Code', 'Description']],
  },
  {
    label: 'Final Disposition',
    serviceName: 'codeevidencefinaldisposition',
    fields: [['Code', 'Description']],
  },
  {
    label: 'Drug Category',
    serviceName: 'codesubstancecategory',
    fields: [['Code', 'Description']],
  },
  {
    label: 'Unit Type',
    serviceName: 'codeaddressunittypes',
    fields: [['Code', 'Description']],
  },
  {
    label: 'Building Type',
    serviceName: 'codeaddressbldgtypes',
    fields: [['Code', 'Description']],
  },
  {
    label: 'Floor Type',
    serviceName: 'codeaddressfloortypes',
    fields: [['Code', 'Description']],
  },
  {
    label: 'Datum',
    serviceName: 'codecoordinatedatumtypes',
    fields: [['Code', 'Description']],
  },
  {
    label: 'Altitude Unit of Measure',
    serviceName: 'codemeasures',
    fields: [['Code', 'Description']],
  },
  {
    label: 'Speed Unit of Measure',
    serviceName: 'codemeasures',
    fields: [['Code', 'Description']],
  },
  {
    label: '',
    serviceName: '',
    fields: [['Code', 'Description']],
  },
  {
    label: 'Image Type Code',
    serviceName: 'codemugshottypes',
    fields: [['Code', 'Description']],
  },
  {
    label: 'Type',
    serviceName: 'codetagtypes',
    fields: [['Code', 'Description', 'Priority']],
  },
  {
    label: 'Force Category',
    serviceName: 'codeForceCategory',
    fields: [['Code', 'Description']],
  },
  {
    label: 'Criminal Activity Category',
    serviceName: 'codecriminalactivities',
    fields: [['Code', 'Description']],
  },
  {
    label: 'Offense Factor Bias Motivation',
    serviceName: 'codebiasmotivations',
    fields: [['Code', 'Description']],
  },
  {
    label: 'Victim Aggravated Assault Homicide Factor',
    serviceName: 'codeaggassaulttype',
    fields: [['Code', 'Description']],
  },
  {
    label: 'Location Category',
    serviceName: 'codelocationcategories',
    fields: [['Code', 'Description']],
  },
  {
    label: 'Injury Category',
    serviceName: 'codeinjurycategory',
    fields: [['Code', 'Description']],
  },
  {
    label: 'Criminal Activity Category',
    serviceName: 'codecriminalactivities',
    fields: [['Code', 'Description']],
  },
  {
    label: 'Incident Exceptional Clearance',
    serviceName: 'codeibrexceptionalclearance',
    fields: [['Code', 'Description']],
  },
  {
    label: 'IBR Status',
    serviceName: 'codeibrstatus',
    fields: [['Code', 'Description']],
  },
  {
    label: 'Arrestee Subject Count',
    serviceName: 'codearresteesegment',
    fields: [['Code', 'Description']],
  },
  {
    label: 'Arrest Offense Description',
    serviceName: 'ptsfbicode',
    fields: [['FBICode', 'Description']],
  },
  {
    label: 'Victim To Subject Relationship',
    serviceName: 'codevictimsubjectrelationship',
    fields: [['Code', 'Description']],
  },
  {
    label: 'Type',
    serviceName: 'codedispositions',
    fields: [['AgencyID', 'Code', 'Description']],
  },
  {
    label: 'Narrative Type',
    serviceName: 'codenarrativetypes',
    fields: [['Code', 'Description']],
  },
  {
    label: 'Place Type',
    serviceName: 'codeplacetypes',
    fields: [['Code', 'Description']],
  },
  {
    label: 'Zone',
    serviceName: 'codezones',
    fields: [['ZoneCode', 'Description']],
  },
];
