import React from 'react';
import { useDispatch } from 'react-redux';

import { IconButton, TextField } from '@material-ui/core';
import { AddIcon } from 'evergreen-ui';

import {
  updateFormOpen,
  setModalServiceName,
  setModalSetTextFieldFunction,
  setExtraValuesForModel,
} from 'reducers/AddEntityFormReducer';
import { setService, setMode, setLabel, resetForm } from 'reducers/CodeCrudFormReducer';

import { popupModal } from 'reducers/CodeCrudFormReducer';
export default function RMSComboBox(props) {
  const {
    label,
    serviceName,
    setField,
    permission,
    hasDifferentPrimaryKey,
    parentCode,
    disabled = false,
  } = props;
  const dispatch = useDispatch();
  if (permission?.Create) {
    return (
      <>
        <TextField
          autoComplete="off"
          size="small"
          {...props}
          InputProps={{
            ...props.InputProps,
            autoComplete: 'off',
            startAdornment: (
              <IconButton
                style={{ margin: 0, padding: 0 }}
                aria-label="add"
                disabled={disabled}
                onClick={() => {
                  if (hasDifferentPrimaryKey) {
                    dispatch(resetForm());
                    dispatch(setMode('add'));
                    dispatch(setService(serviceName));
                    dispatch(setLabel(label));
                    dispatch(popupModal(true));
                  } else {
                    if (serviceName) dispatch(setModalServiceName(serviceName));
                    if (setField) dispatch(setModalSetTextFieldFunction(setField));
                    if (serviceName === 'codeEvidenceStorageSubLocation') {
                      dispatch(setExtraValuesForModel({ parentCode }));
                    }
                    dispatch(updateFormOpen(label));
                  }
                }}>
                <AddIcon fontSize="small" />
              </IconButton>
            ),
          }}
        />
      </>
    );
  } else {
    return <TextField autoComplete="hidden" size="small" {...props} />;
  }
}
