import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';

import { Button, Box, makeStyles } from '@material-ui/core';

import SaveIcon from '@material-ui/icons/Save';

import { getCoords, parseCoords } from 'utils/mapFunctions';
import formStyles, { gridStyle, Row, ColCard, Fills } from 'utils/formStyles';

import FormDivider from 'components/RMSFormDivider';
import RMSAutocomplete2 from 'components/RMSAutoComplete/RMSAutocomplete2';
import RMSTextField from 'components/RMSTextField';
import RMSDatePicker from 'components/RMSDatePicker/RMSDatePicker';
import BackDatedRecordHistory from 'components/BackDatedRecordHistory';
import Dictionary from 'components/RMSAutoComplete/RMSDictonary';

import { notify } from 'reducers/NotifierReducer';
import { getService } from 'reducers/service';
import { getAddressCoordinates, getPtsCoordinateID } from 'reducers/AddressReducer';
import { handleError } from 'reducers/ErrorReducer';

const useStyles = makeStyles((theme) => ({
  ...formStyles,
  wrap: {
    padding: theme.spacing(3),
  },
  item: gridStyle(200, 320),
  btn: {
    marginTop: theme.spacing(3),
    // display: 'block',
    // marginRight: 0,
    marginLeft: '5px',
    '& svg': {
      marginRight: theme.spacing(1),
    },
  },
}));

const AddressCoordinates = (props) => {
  const classes = useStyles();
  const { dictionary, ptsAddressID } = props;
  const { codeMeasures } = dictionary;
  const [uncertaintyDistance, setUncertaintyDistance] = useState('');
  const [datum, setDatum] = useState(null);
  const [coorDt, setCoorDt] = useState(null);
  const [lat, setLat] = useState('');
  const [lng, setLng] = useState('');
  const [altitude, setAltitude] = useState('');
  const [pointOfReference, setPointOfReference] = useState('');
  const [cellID, setCellID] = useState('');
  const [sectorID, setSectorID] = useState('');
  const [speed, setSpeed] = useState('');
  const [altitudeUnitOfMeasure, setAltitudeUnitOfMeasure] = useState(null);
  const [speedUnitOfMeasure, setSpeedUnitOfMeasure] = useState(null);
  const [data, setData] = useState(null);
  const [coorID, setCoorID] = useState(null);

  const [recordHistoryData, setRecordHistoryData] = useState({
    shouldVisible: true,
    isDisabled: true,
    createUpdateInfo: {},
  });
  const [showRecordHistory, setShowRecordHistory] = useState(false);
  const [createdBy, setCreatedBy] = useState(null);
  const [created, setCreated] = useState(null);
  const [updatedBy, setUpdatedBy] = useState(null);
  const [updated, setUpdated] = useState(null);

  const currentUserName = useSelector((state) => state.user.userData?.user.Username);

  const datumOptions = [
    { Code: 'Coor', Description: 'Coordinates' },
    { Code: 'Deg', Description: 'Degrees' },
  ];

  useEffect(() => {
    getCoordinatesData();
  }, []);

  useEffect(() => {
    if (!data) return;

    setCreated(data.Created);
    setCreatedBy(data.CreatedBy);
    setUpdated(data.Updated);
    setUpdatedBy(data.UpdatedBy);
    setRecordHistoryData({
      shouldVisible: true,
      isDisabled: !data.Created,
      createUpdateInfo: {
        created: data.Created,
        createdBy: data.CreatedBy,
        updated: data.Updated,
        updatedBy: data.UpdatedBy,
      },
    });

    setUncertaintyDistance(data.UnCertaintyDistance || '');
    setDatum(datumOptions.find((d) => d.Code === data.Datum) || null);
    setCoorDt(data.CoordinateDT || null);
    setAltitude(data.Altitude || '');
    setPointOfReference(data.AltitudeRefPoint || '');
    setCellID(data.CellIDText || '');
    setSectorID(data.CellSectorIDText || '');
    setSpeed(data.Speed || '');
    setAltitudeUnitOfMeasure(codeMeasures.find((m) => m.Code === data.AltitudeUnitMeasure) || null);
    setSpeedUnitOfMeasure(codeMeasures.find((m) => m.Code === data.SpeedUnitMeasure) || null);
    const { lat, lng } = getCoords(data);
    setLat(lat || '');
    setLng(lng || '');
  }, [data]);

  const getCoordinatesData = async () => {
    const coorID = await getPtsCoordinateID(ptsAddressID);
    const { ptsCoordinateID } = coorID;
    if (!ptsCoordinateID) return;
    setCoorID(ptsCoordinateID);
    const coorData = await getAddressCoordinates(ptsCoordinateID);
    setData(coorData);
    const { lat, lng } = getCoords(coorData);
    setLat(lat || '');
    setLng(lng || '');
  };

  const handleSave = () => {
    const coordinate = getCoordinateObj();
    coordinate.ptsCoordinateID = coorID;
    const service = getService('cad-address-coordinates');
    service
      .create(coordinate)
      .then((res) => {
        const { ptsCoordinateID } = res;
        const service = getService('cad-address-coordinates');
        service.patch(ptsAddressID, { ptsCoordinateID });

        const currentDateTime = new Date();

        if (coordinate.ptsCoordinateID) {
          setRecordHistoryData({
            shouldVisible: true,
            isDisabled: !created,
            createUpdateInfo: {
              created: created,
              createdBy: createdBy,
              updated: currentDateTime,
              updatedBy: currentUserName,
            },
          });
        } else {
          setRecordHistoryData({
            shouldVisible: true,
            isDisabled: !currentDateTime,
            createUpdateInfo: {
              created: currentDateTime,
              createdBy: currentUserName,
              updated: currentDateTime,
              updatedBy: currentUserName,
            },
          });
        }
      })
      .then(() => {
        props.notify('Coordinate saved', 'success');
        getCoordinatesData();
      })
      .catch((err) => props.handleError(err, 'Error saving coordinate'));
  };

  const getCoordinateObj = () => {
    const { LatitudeSign, LatitudeDegree, LongitudeSign, LongitudeDegree } = parseCoords({
      lat,
      lng,
    });
    return {
      LatitudeSign,
      LatitudeDegree,
      LongitudeSign,
      LongitudeDegree,
      UnCertaintyDistance: uncertaintyDistance || null,
      Datum: datum?.Code || null,
      CoordinateDT: coorDt || null,
      Altitude: altitude || null,
      AltitudeRefPoint: pointOfReference || null,
      CellIDText: cellID || null,
      CellSectorIDText: sectorID || null,
      Speed: speed || null,
      AltitudeUnitMeasure: altitudeUnitOfMeasure?.Code || null,
      SpeedUnitMeasure: speedUnitOfMeasure?.Code || null,
    };
  };

  const renderLat = () => {
    const onChange = (ev, val) => setLat(val);
    return (
      <RMSTextField
        className={classes.item}
        label="Latitude"
        value={lat}
        onChange={onChange}
        type="number"
        min={-90}
        max={90}
      />
    );
  };

  const renderLng = () => {
    const onChange = (ev, val) => setLng(val);
    return (
      <RMSTextField
        className={classes.item}
        label="Longitude"
        value={lng}
        onChange={onChange}
        type="number"
        min={-180}
        max={180}
      />
    );
  };

  const renderUncertaintyDistance = () => {
    const onChange = (ev, val) => setUncertaintyDistance(val);
    return (
      <RMSTextField
        className={classes.item}
        label="Uncertainty Distance"
        value={uncertaintyDistance}
        onChange={onChange}
        type="number"
        min={0}
      />
    );
  };

  const renderDatum = () => {
    const onChange = (ev, val) => setDatum(val);
    return (
      <RMSAutocomplete2
        className={classes.item}
        options={datumOptions}
        value={datum}
        onChange={onChange}
        label="Datum"
        formType={coorID ? 'edit' : 'add'}
        getOptionSelected={(option, value) => option.Code === value.Code}
        getOptionLabel={(option) => (option.Code ? option.Code : '')}
        renderOption={(option) => (
          <span>
            {option.Code} - {option.Description}
          </span>
        )}
      />
    );
  };

  const renderCoordinateDateTime = () => {
    return (
      <RMSDatePicker
        onChange={(date) => setCoorDt(date)}
        label="Coor DT"
        value={coorDt}
        className={classes.item}
      />
    );
  };

  const renderAltitude = () => {
    const onChange = (ev, val) => setAltitude(val);
    return (
      <RMSTextField
        className={classes.item}
        label="Altitude"
        value={altitude}
        onChange={onChange}
        type="number"
        min={0}
      />
    );
  };

  const renderPointOfReference = () => {
    const onChange = (ev, val) => setPointOfReference(val);
    return (
      <RMSTextField
        className={classes.item}
        label="Point Of Reference"
        value={pointOfReference}
        onChange={onChange}
        type="number"
        min={0}
      />
    );
  };

  const renderCellID = () => {
    const onChange = (ev) => setCellID(ev.target.value);
    return (
      <RMSTextField
        className={classes.item}
        label="CellID"
        value={cellID}
        onChange={onChange}
        max={2000}
      />
    );
  };

  const renderSectorID = () => {
    const onChange = (ev) => setSectorID(ev.target.value);
    return (
      <RMSTextField
        className={classes.item}
        label="SectorID"
        value={sectorID}
        onChange={onChange}
        max={2000}
      />
    );
  };

  const renderSpeed = () => {
    const onChange = (ev) => setSpeed(ev.target.value);
    return (
      <RMSTextField
        className={classes.item}
        label="Speed"
        value={speed}
        onChange={onChange}
        type="number"
        min={0}
      />
    );
  };

  const renderAltitudeUnitOfMeasure = () => {
    const onChange = (ev, val) => setAltitudeUnitOfMeasure(val);
    return (
      <Dictionary
        options="codeMeasures"
        className={classes.item}
        onChange={onChange}
        formType={coorID ? 'edit' : 'add'}
        value={altitudeUnitOfMeasure}
        label="Altitude Units"
      />
    );
  };

  const renderSpeedUnitOfMeasure = () => {
    const onChange = (ev, val) => setSpeedUnitOfMeasure(val);
    return (
      <Dictionary
        options="codeMeasures"
        className={classes.item}
        onChange={onChange}
        formType={coorID ? 'edit' : 'add'}
        value={speedUnitOfMeasure}
        label="Speed Units"
      />
    );
  };

  const handleCloseRecordHistoryDialog = () => setShowRecordHistory(false);

  return (
    <div className={classes.wrap}>
      <Row>
        <ColCard minWidth={500}>
          <FormDivider title="Coordinates" />
          <div className={classes.row}>
            {renderLat()}
            {renderLng()}
            {renderUncertaintyDistance()}
            {renderDatum()}
            {renderCoordinateDateTime()}
            <Fills className={classes.item} />
          </div>
        </ColCard>
        <ColCard minWidth={500}>
          <FormDivider title="Geographical Details" />
          <div className={classes.row}>
            {renderAltitude()}
            {renderPointOfReference()}
            {renderAltitudeUnitOfMeasure()}
            {renderCellID()}
            {renderSectorID()}
            {renderSpeed()}
            {renderSpeedUnitOfMeasure()}
            <Fills className={classes.item} />
          </div>
        </ColCard>
      </Row>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        {recordHistoryData?.shouldVisible && (
          <Box className={classes.btn}>
            <BackDatedRecordHistory
              showRecordHistory={showRecordHistory}
              setShowRecordHistory={setShowRecordHistory}
              disabled={recordHistoryData?.isDisabled}
              handleCloseRecordHistoryDialog={handleCloseRecordHistoryDialog}
              createUpdateInfo={recordHistoryData?.createUpdateInfo}></BackDatedRecordHistory>
          </Box>
        )}
        <Button
          className={classes.btn}
          color="primary"
          variant="contained"
          autoFocus
          disabled={!props.canSave}
          onClick={handleSave}>
          <SaveIcon /> Save
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    dictionary: state.dictionary,
  };
};

export default connect(mapStateToProps, {
  handleError,
  notify,
})(AddressCoordinates);
