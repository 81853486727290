import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';

import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

import Dialog from 'components/RMSDialog';
import Dictionary from 'components/RMSAutoComplete/RMSDictonary';
import RMSTextField from 'components/RMSTextField';
import RMSCheckbox from 'components/RMSCheckbox';
import BackDatedRecordHistory from 'components/BackDatedRecordHistory';

import { closeContactMethod } from 'reducers/PersonDialogsReducer';
import styles, {
  ColCard,
  gridStyle,
  Row,
  RowInner,
  useError,
  validatePhoneNo,
} from 'utils/formStyles';
import { validateEmail } from 'utils/functions';

import { getService } from 'reducers/service';
import { showSpinner, hideSpinner } from 'reducers/UiReducer';
import { handleError } from 'reducers/ErrorReducer';
import { notifyDataUpdate } from 'reducers/DataUpdateReducer';
import { notify } from 'reducers/NotifierReducer';

const useStyles = makeStyles((theme) => ({
  ...styles,
  fileInfo: {
    minHeight: 35,
    marginBottom: 16,
    '& img': {
      maxHeight: 24,
      borderRadius: 3,
      marginRight: 8,
    },
  },
  item: gridStyle(300, 500),
  txtField: {
    minWidth: 350,
  },
  closeButton: { color: theme.button.color },
}));

function ContactMethodDialog(props) {
  const classes = useStyles();
  const { data } = props;
  const dispatch = useDispatch();
  const [ContactMethod, setContactMethod] = useState('');
  const [MethodType, setMethodType] = useState('Phone');
  const [Info, setInfo] = useState('');
  const [IsDefault, setIsDefault] = useState(false);
  const [Notes, setNotes] = useState('');
  const [errors, setErrors] = useState();

  const [recordHistoryData, setRecordHistoryData] = useState({
    shouldVisible: true,
    isDisabled: true,
    createUpdateInfo: {},
  });
  const [showRecordHistory, setShowRecordHistory] = useState(false);

  const { err, delErr, isValidAndChanged, formChanged } = useError(errors, setErrors);

  const user = useSelector((state) => state.user);

  useEffect(() => {
    if (data.ptsInfoID) {
      const { Notes, ContactMethod, MethodType, Info, IsDefault } = data;

      setRecordHistoryData({
        shouldVisible: true,
        isDisabled: !data.Updated,
        createUpdateInfo: {
          created: data.Updated,
          createdBy: data.CreatedBy,
          updated: data.Updated,
          updatedBy: data.UpdatedBy,
        },
      });
      setContactMethod(ContactMethod);
      setMethodType(MethodType);
      setInfo(Info);
      setNotes(Notes);
      setIsDefault(IsDefault);
    }
  }, []);

  const handleCloseRecordHistoryDialog = () => setShowRecordHistory(false);

  const close = () => {
    dispatch(closeContactMethod());
  };

  const save = async () => {
    try {
      const username = user.userData?.user.Username;
      let formData = {
        PartyType: data.PartyType,
        ContactMethod: ContactMethod.Code || '',
        MethodType: MethodType.Code || '',
        IsDefault: IsDefault || false,
        Info: Info || '',
        Notes: Notes || '',
      };
      // console.log('formData', formData);
      props.showSpinner();
      const service = getService('record-person-contact');
      if (data.ptsParentID && data.ptsInfoID) {
        const res = await service.patch(data.ptsInfoID, { ...formData, UpdatedBy: username });
        // console.log('edit res', res);
        dispatch(notify('Contact updated', 'success'));
      } else {
        await service.create({
          ...formData,
          ptsParentID: data.ptsParentID,
          CreatedBy: username,
        });
        dispatch(notify('Contact Created', 'success'));
      }
    } catch (err) {
      props.handleError(err);
    } finally {
      close();
      props.hideSpinner();
    }
  };

  const renderActions = () => {
    return (
      <>
        {recordHistoryData?.shouldVisible && (
          <Box className="">
            <BackDatedRecordHistory
              showRecordHistory={showRecordHistory}
              setShowRecordHistory={setShowRecordHistory}
              disabled={recordHistoryData?.isDisabled}
              handleCloseRecordHistoryDialog={handleCloseRecordHistoryDialog}
              createUpdateInfo={recordHistoryData?.createUpdateInfo}></BackDatedRecordHistory>
          </Box>
        )}
        <Button
          color="primary"
          variant="contained"
          autoFocus
          onClick={save}
          disabled={!isValidAndChanged()}>
          <SaveIcon /> Save
        </Button>
        <Button color="primary" onClick={close} className={classes.closeButton}>
          <CloseIcon /> Close
        </Button>
      </>
    );
  };

  const renderContactMethod = () => {
    const onChange = (ev, val) => {
      setContactMethod(val);
      // setInfo('');
      formChanged();
      delErr('Info');
      if (!val) setMethodType(null);
    };
    return (
      <Dictionary
        className={classes.item}
        options="ContactMethods"
        onChange={onChange}
        formType={data.ptsInfoID ? 'edit' : 'add'}
        value={ContactMethod}
        label="Contact Method"
      />
    );
  };

  // const renderPartyType = () => {
  //   const onChange = (ev, val) => {
  //     setPartyType(val);
  //     formChanged();
  //   };
  //   return (
  //     <Dictionary
  //       className={classes.item}
  //       options="PartyTypes"
  //       onChange={onChange}
  //       value={PartyType}
  //       label="Party Type"
  //     />
  //   );
  // };
  const renderMethodType = () => {
    const onChange = (ev, val) => {
      setMethodType(val);
      formChanged();
    };
    const errors = err(!ContactMethod || (ContactMethod && MethodType), 'ContactMethodType');
    return (
      <Dictionary
        className={classes.item}
        options="codeContactInfoMethodTypes"
        onChange={onChange}
        formType={data.ptsInfoID ? 'edit' : 'add'}
        value={MethodType}
        label="Contact Type"
        error={errors}
        disabled={!ContactMethod}
      />
    );
  };

  const renderInfo = () => {
    const onChange = (ev, val) => {
      setInfo(val);
      formChanged();
    };
    const type = ContactMethod?.Code;
    let error;
    if (type === 'Phone') {
      error = err(validatePhoneNo(Info), 'Info', 'Invalid phone number');
    }
    if (type === 'Email') {
      error = err(validateEmail(Info), 'Info', 'Invalid email address');
    }

    return (
      <RMSTextField
        className={classes.item}
        label="Info"
        value={Info}
        onChange={onChange}
        max={100}
        error={error}
        type={type}
        disabled={!ContactMethod}
      />
    );
  };

  const renderIsDefault = () => {
    const onChange = (ev, val) => {
      setIsDefault(val);
      formChanged();
    };
    return (
      <div>
        <RMSCheckbox selectedCheckbox={IsDefault} handleFormChange={onChange} label="Is Default" />
      </div>
    );
  };

  const renderNotes = () => {
    const onChange = (ev, val) => setNotes(val);
    return (
      <RMSTextField
        className={classes.w100pr}
        label="Notes"
        value={Notes}
        onChange={onChange}
        max={5000}
        rows={3}
        multiline
      />
    );
  };

  return (
    <Dialog toolbar onClose={close} title="Edit Contacts" actions={renderActions()}>
      <Row>
        <ColCard minWidth={500}>
          <RowInner>
            {renderContactMethod()}
            {renderMethodType()}
          </RowInner>
          <RowInner>
            {renderIsDefault()}
            {renderInfo()}
          </RowInner>
          <RowInner>{renderNotes()}</RowInner>
        </ColCard>
      </Row>
    </Dialog>
  );
}

export default connect(null, {
  showSpinner,
  hideSpinner,
  handleError,
  notifyDataUpdate,
})(ContactMethodDialog);
