import React from 'react';

function PassFailIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" {...props}>
      <g transform="translate(0.000000,50.000000) scale(0.100000,-0.100000)" stroke="none">
        <path d="M220 485 c-8 -9 -36 -15 -83 -17 l-72 -3 0 -220 0 -220 185 0 185 0 0 220 0 220 -72 3 c-47 2 -75 8 -83 17 -7 8 -20 15 -30 15 -10 0 -23 -7 -30 -15z m46 -20 c4 -8 17 -15 30 -15 15 0 24 -6 24 -15 0 -12 -14 -15 -70 -15 -56 0 -70 3 -70 15 0 9 9 15 24 15 13 0 26 7 30 15 3 8 10 15 16 15 6 0 13 -7 16 -15z m104 -141 c0 -12 -42 -74 -49 -74 -4 0 -16 9 -26 20 -21 23 -13 39 9 21 12 -10 18 -8 31 13 16 24 35 35 35 20z m-120 -34 c0 -6 -27 -10 -60 -10 -33 0 -60 4 -60 10 0 6 27 10 60 10 33 0 60 -4 60 -10z m58 -102 c8 -8 15 -8 27 2 20 17 32 5 15 -15 -10 -12 -10 -18 0 -30 17 -20 5 -32 -15 -15 -12 10 -18 10 -30 0 -20 -17 -32 -5 -15 15 10 12 10 19 2 27 -7 7 -12 16 -12 20 0 12 14 10 28 -4z m-58 -28 c0 -6 -27 -10 -60 -10 -33 0 -60 4 -60 10 0 6 27 10 60 10 33 0 60 -4 60 -10z" />
      </g>
    </svg>
  );
}

export default PassFailIcon;
