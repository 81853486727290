import React, { useState } from 'react';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Fab,
  Tooltip,
  Typography,
  Button,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import RemoveCircle from '@material-ui/icons/RemoveCircle';

export default function RMSDeleteButton(props) {
  const { onClick, disabled, tooltipText, size, entityName } = props;

  const [open, setOpen] = useState(false);

  const closeDialog = () => setOpen(false);
  const handleClick = () => setOpen(true);
  const handleRemove = () => {
    onClick();
    closeDialog();
  };

  let dialogMsg = 'Are you sure you want to remove?';
  const removeDialogHeader =
    entityName === 'offenses' ? 'WARNING' : entityName === 'person' ? 'DELETE' : 'REMOVE';
  if (entityName === 'offenses') {
    dialogMsg = (
      <div>
        <p>Are you sure you want to remove this offense?</p>
        <ul>
          <li>
            All data related to the offense will be deleted (Including Offenders, Victims, Arrestees
            and Properties).
          </li>
          <li>Removing the offense will also remove IBRS errors related to the offense.</li>
        </ul>
      </div>
    );
  } else if (entityName === 'incidentList') {
    dialogMsg = 'Confirmation, are you sure you want to remove this incident?';
  } else if (entityName === 'person') {
    dialogMsg = (
      <div>
        <p>
          This will delete the individual's person record but will leave <br /> his/her involvement
          in incidents, citations and all others intact.
          <br />
          Do you wish to proceed?
        </p>
      </div>
    );
  }
  return (
    <>
      <Tooltip title={tooltipText || 'Delete'}>
        <Fab
          size="small"
          disabled={disabled}
          color="secondary"
          aria-label="edit"
          className={props.className ? props.className : ''}
          onClick={handleClick}>
          {entityName === 'workflow' ? (
            <RemoveCircle style={{ fontSize: size }} />
          ) : (
            <DeleteIcon style={{ fontSize: size }} />
          )}
        </Fab>
      </Tooltip>
      <Dialog open={open} onClose={closeDialog} maxWidth={'md'}>
        <DialogTitle style={{ background: '#1976d2', color: 'white' }}>
          <Typography variant="h5">{removeDialogHeader}</Typography>
        </DialogTitle>
        <DialogContent style={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="subtitle2" gutterBottom component="div">
            {dialogMsg}
          </Typography>
        </DialogContent>
        <DialogActions>
          <div className=" mb-1">
            <Button style={{ background: '#f1f1f1', color: 'black' }} onClick={closeDialog}>
              Cancel
            </Button>
            <Button
              className="ml-2"
              variant="contained"
              style={{ background: '#1976D2', color: 'white' }}
              onClick={handleRemove}>
              {entityName === 'person' ? 'Delete' : 'Remove'}
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
}
