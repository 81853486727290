import React from 'react';
export const getTitleForDialog = (permission, label) => {
  return permission.Edit ? (
    label ? (
      `${label}`
    ) : (
      `Edit`
    )
  ) : (
    <div className="d-flex" style={{ width: '100%', justifyContent: 'left', textAlign: 'left' }}>
      <span style={{ flex: 0.4 }}> {label ? label : 'Edit'}</span>
      <span style={{ flex: 0.5, color: 'red', fontWeight: 'bold', fontSize: '21px' }}>
        VIEW ONLY
      </span>
    </div>
  );
};
export default function ReadOnlyText(props) {
  const { fontSize = 22 } = props || {};
  return (
    <p
      style={{
        fontWeight: 'bold',
        fontSize: fontSize,
        color: 'red',
        textAlign: 'center',
        width: '100%',
      }}>
      {props.text ? props.text : 'VIEW ONLY'}
    </p>
  );
}
