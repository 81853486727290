import React, { useState, useRef, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';

import Fab from '@material-ui/core/Fab';
import { makeStyles } from '@material-ui/core/styles';

import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import DeleteIcon from '@material-ui/icons/Delete';

import Tooltip from 'components/Tooltip';
import XGrid3 from 'components/RMSXGrid';
import QuickFilterSearch from 'components/RMSSearch/components/QuickFilterSearch';

import { getService } from 'reducers/service';
import { handleError } from 'reducers/ErrorReducer';
import { getContactMethods } from 'reducers/SearchReducer';
import { showSpinner, hideSpinner } from 'reducers/UiReducer';
import { displayDateTime } from 'reducers/TimeReducer';
import { showContactMethod } from 'reducers/PersonDialogsReducer';

const useStyles = makeStyles((theme) => ({
  wrap: {
    padding: theme.spacing(3),
  },
  gridWrap: {
    width: '100%',
    boxSizing: 'border-box',
    height: 600,
  },
  filterWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    marginBottom: 8,
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(2),
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
  fileInput: {
    display: 'none',
  },
}));
const bool = (params) => (params.value ? <CheckIcon /> : <span></span>);

const defaultColumns = [
  'ContactMethod',
  { field: 'MethodType', headerName: 'Type' },
  'Info',
  {
    field: 'IsDefault',
    headerName: 'IsDefault',
    width: 100,
    format: 'bool',
    renderCell: bool,
  },
  {
    field: 'Created',
    headerName: 'Created',
    valueFormatter: (params) => displayDateTime(params.value),
    width: 200,
  },
  { field: 'CreatedBy', headerName: 'Created By', width: 150 },
  {
    field: 'Updated',
    headerName: 'Updated',
    valueFormatter: (params) => displayDateTime(params.value),
    width: 200,
  },
  { field: 'UpdatedBy', headerName: 'Updated By', width: 150 },
];
const PlaceContact = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { ptsPlaceID } = props;

  const [rows, setRows] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [filteredRows, setFilteredRows] = useState([]);
  const [selection, setSelection] = useState(null);
  const [columns, setColumns] = useState(defaultColumns);
  const [isDeleted, setIsDeleted] = useState(false);

  const mountedRef = useRef(true);

  const editContactMethod = useSelector((state) => state.dialogs.editContactMethod);

  useEffect(() => {
    const data = processData(props.contactInfo);
    setRows(data);
    setLoaded(true);
  }, [props.contactInfo]);

  useEffect(() => {
    ptsPlaceID && getData();
  }, [editContactMethod, isDeleted]);

  const getData = async () => {
    try {
      const data = await getContactMethods(ptsPlaceID, 'Place');
      if (!mountedRef.current) return;

      setRows(processData(data.data));
      setLoaded(true);
    } catch (err) {
      dispatch(handleError(err));
    }
  };

  const processData = (data) => {
    return data.map((row, idx) => {
      return {
        ...row,
        id: idx,
      };
    });
  };

  const add = () => {
    dispatch(showContactMethod({ ptsParentID: ptsPlaceID, PartyType: 'Place' }));
  };

  const edit = () => {
    const item = rows.find((row) => row.id === selection);
    dispatch(showContactMethod({ ...item }));
  };

  const deleteContact = async () => {
    if (!window.confirm('Are you sure you want to remove this record?')) return;
    const item = rows.find((row) => row.id === selection);
    try {
      dispatch(showSpinner());
      const service = getService('record-person-contact');
      await service.remove(item.ptsInfoID);
      setIsDeleted(!isDeleted);
    } catch (err) {
      dispatch(handleError(err));
    } finally {
      dispatch(hideSpinner());
    }
  };

  return (
    <div className={classes.wrap}>
      <div className={classes.filterWrap}>
        <div className={classes.filterWrap}>
          <QuickFilterSearch rows={rows} columns={columns} setFilteredRows={setFilteredRows} />
        </div>
        <div className={classes.actions}>
          <Tooltip title="Add Credential">
            <Fab size="small" color="secondary" disabled={!props.canSave} onClick={add}>
              <AddIcon />
            </Fab>
          </Tooltip>

          {selection !== null && (
            <>
              <Tooltip title="Edit Credential">
                <Fab size="small" color="secondary" disabled={!props.canSave} onClick={edit}>
                  <EditIcon />
                </Fab>
              </Tooltip>

              <Tooltip title="Download Credential">
                <Fab
                  size="small"
                  color="secondary"
                  disabled={!props.canSave}
                  onClick={deleteContact}>
                  <DeleteIcon />
                </Fab>
              </Tooltip>
            </>
          )}
        </div>
      </div>
      <XGrid3
        name="PlaceContact"
        columns={columns}
        setColumns={setColumns}
        rows={filteredRows}
        loading={!loaded}
        setSelection={setSelection}
      />
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    editContactMethod: state.dialogs.editContactMethod,
  };
};

export default connect(mapStateToProps, { handleError, showSpinner, hideSpinner })(PlaceContact);
