import React from 'react';

function PoliticianIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" {...props}>
      <g transform="translate(0.000000,50.000000) scale(0.100000,-0.100000)" stroke="none">
        <path d="M201 483 c-24 -21 -30 -91 -12 -136 18 -45 14 -55 -29 -77 -48 -25 -60 -45 -60 -104 0 -39 3 -46 20 -46 11 0 20 7 20 15 0 28 24 65 42 65 25 0 24 -36 -2 -43 -11 -3 -20 -12 -20 -21 0 -12 10 -16 39 -16 35 0 38 2 33 25 -3 21 0 25 18 25 18 0 21 -4 18 -25 -5 -23 -2 -25 33 -25 29 0 39 4 39 16 0 9 -9 18 -20 21 -26 7 -27 43 -2 43 13 0 23 -13 32 -40 9 -27 19 -40 32 -40 15 0 18 8 18 46 0 59 -12 79 -61 105 -45 22 -44 21 -20 114 13 53 5 83 -30 100 -37 20 -62 19 -88 -2z m72 -12 c35 -13 38 -31 20 -105 -23 -92 -59 -101 -81 -21 -29 108 -7 152 61 126z m-38 -201 c3 -6 3 -20 -1 -32 l-6 -21 -13 24 c-13 24 -11 39 5 39 5 0 12 -5 15 -10z m59 2 c2 -4 -2 -18 -9 -31 l-13 -24 -6 21 c-10 31 13 59 28 34z" />
        <path d="M77 94 c-12 -13 -7 -34 8 -34 9 0 15 -9 15 -25 l0 -25 150 0 150 0 0 25 c0 16 6 25 16 25 9 0 14 7 12 18 -3 15 -21 17 -174 20 -93 1 -173 -1 -177 -4z" />
      </g>
    </svg>
  );
}

export default PoliticianIcon;
