import React, { useState } from 'react';

import Dialog from 'components/RMSDialog';
import { closePersonFeatureMultiple } from 'reducers/PersonDialogsReducer';
import { connect } from 'react-redux';
import { Button, Grid, makeStyles } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import Dictionary from 'components/RMSAutoComplete/RMSDictonary';
import { handleError } from 'reducers/ErrorReducer';
import { notifyDataUpdate } from 'reducers/DataUpdateReducer';
import { format } from 'date-fns';
import { formatSaveData, gridStyle } from 'utils/formStyles';
import { addPersonFeature } from 'reducers/SearchReducer';

const useStyles = makeStyles((theme) => ({
  item: gridStyle(200, 800),
  closeButton: { color: theme.button.color },
}));

function AddMultipleFeatureDialog(props) {
  const classes = useStyles();
  const { data } = props;
  const { ptsPersonID } = data;

  const [appearance, setAppearance] = useState(null);
  const [builds, setBuilds] = useState(null);
  const [complexions, setComplexions] = useState(null);
  const [eyeDefect, setEyeDefect] = useState(null);
  const [facialHair, setFacialHair] = useState(null);
  const [glasses, setGlasses] = useState(null);
  const [methodOfOperation, setMethodOfOperation] = useState(null);
  const [speechQuality, setSpeechQuality] = useState(null);
  const [talk, setTalk] = useState(null);
  const [teeth, setTeeth] = useState(null);

  const close = () => {
    props.closePersonFeatureMultiple();
  };

  const save = async () => {
    try {
      if (appearance) {
        const featureData = formatSaveData({
          Attribute: appearance,
          AttributeCategory: 'Appearance',
          ptsPersonID,
        });
        await addPersonFeature(featureData);
      }
      if (builds) {
        const featureData = formatSaveData({
          Attribute: builds,
          AttributeCategory: 'Builds',
          ptsPersonID,
        });
        await addPersonFeature(featureData);
      }
      if (complexions) {
        const featureData = formatSaveData({
          Attribute: complexions,
          AttributeCategory: 'Complexions',
          ptsPersonID,
        });
        await addPersonFeature(featureData);
      }
      if (eyeDefect) {
        const featureData = formatSaveData({
          Attribute: eyeDefect,
          AttributeCategory: 'EyeDefect',
          ptsPersonID,
        });
        await addPersonFeature(featureData);
      }
      if (facialHair) {
        const featureData = formatSaveData({
          Attribute: facialHair,
          AttributeCategory: 'FacialHair',
          ptsPersonID,
        });
        await addPersonFeature(featureData);
      }
      if (glasses) {
        const featureData = formatSaveData({
          Attribute: glasses,
          AttributeCategory: 'Glasses',
          ptsPersonID,
        });
        await addPersonFeature(featureData);
      }
      if (methodOfOperation) {
        const featureData = formatSaveData({
          Attribute: methodOfOperation,
          AttributeCategory: 'MethodOfOperation',
          ptsPersonID,
        });
        await addPersonFeature(featureData);
      }
      if (speechQuality) {
        const featureData = formatSaveData({
          Attribute: speechQuality,
          AttributeCategory: 'SpeechQuality',
          ptsPersonID,
        });
        await addPersonFeature(featureData);
      }
      if (talk) {
        const featureData = formatSaveData({
          Attribute: talk,
          AttributeCategory: 'Talk',
          ptsPersonID,
        });
        await addPersonFeature(featureData);
      }
      if (teeth) {
        const featureData = formatSaveData({
          Attribute: teeth,
          AttributeCategory: 'Teeth',
          ptsPersonID,
        });
        await addPersonFeature(featureData);
      }
      props.notifyDataUpdate({ type: 'person-feature', data: {} });
      props.closePersonFeatureMultiple();
    } catch (e) {
      props.handleError(e, 'Error saving feature');
    }
  };

  const renderActions = () => {
    return (
      <>
        <Button color="primary" variant="contained" autoFocus onClick={save}>
          <SaveIcon /> Save
        </Button>
        <Button color="primary" onClick={close} className={classes.closeButton}>
          <CloseIcon /> Close
        </Button>
      </>
    );
  };

  const renderAppearance = () => {
    return (
      <Dictionary
        options={'Appearance'}
        label={'Appearance'}
        onChange={(ev, value) => {
          setAppearance(value);
        }}
        value={appearance}
        formType="add"
        compact
        className={classes.item}
      />
    );
  };

  const renderBuilds = () => {
    return (
      <Dictionary
        options={'Builds'}
        label={'Builds'}
        onChange={(ev, value) => {
          setBuilds(value);
        }}
        value={builds}
      />
    );
  };

  const renderComplexions = () => {
    return (
      <Dictionary
        options={'Complexions'}
        label={'Complexions'}
        onChange={(ev, value) => {
          setComplexions(value);
        }}
        value={complexions}
      />
    );
  };

  const renderEyeDefect = () => {
    return (
      <Dictionary
        options={'EyeDefect'}
        label={'EyeDefect'}
        onChange={(ev, value) => {
          setEyeDefect(value);
        }}
        value={eyeDefect}
      />
    );
  };

  const renderFacialHair = () => {
    return (
      <Dictionary
        options={'FacialHair'}
        label={'FacialHair'}
        onChange={(ev, value) => {
          setFacialHair(value);
        }}
        value={facialHair}
      />
    );
  };

  const renderGlasses = () => {
    return (
      <Dictionary
        options={'Glasses'}
        label={'Glasses'}
        onChange={(ev, value) => {
          setGlasses(value);
        }}
        value={glasses}
        className={classes.item}
      />
    );
  };

  const renderMethodOfOperation = () => {
    return (
      <Dictionary
        options={'MethodOfOperation'}
        label={'MethodOfOperation'}
        onChange={(ev, value) => {
          setMethodOfOperation(value);
        }}
        value={methodOfOperation}
      />
    );
  };

  const renderSpeechQuality = () => {
    return (
      <Dictionary
        options={'SpeechQuality'}
        label={'SpeechQuality'}
        onChange={(ev, value) => {
          setSpeechQuality(value);
        }}
        value={speechQuality}
      />
    );
  };

  const renderTalk = () => {
    return (
      <Dictionary
        options={'Talk'}
        label={'Talk'}
        onChange={(ev, value) => {
          setTalk(value);
        }}
        value={talk}
      />
    );
  };

  const renderTeeth = () => {
    return (
      <Dictionary
        options={'Teeth'}
        label={'Teeth'}
        onChange={(ev, value) => {
          setTeeth(value);
        }}
        value={teeth}
      />
    );
  };

  return (
    <Dialog toolbar onClose={close} title={'Add Multiple Features'} actions={renderActions()}>
      <Grid container>
        <Grid item xs={12} sm={6}>
          {renderAppearance()}
        </Grid>
        <Grid item xs={12} sm={6}>
          {renderGlasses()}
        </Grid>
        <Grid item xs={12} sm={6}>
          {renderBuilds()}
        </Grid>
        <Grid item xs={12} sm={6}>
          {renderComplexions()}
        </Grid>
        <Grid item xs={12} sm={6}>
          {renderEyeDefect()}
        </Grid>
        <Grid item xs={12} sm={6}>
          {renderFacialHair()}
        </Grid>
        <Grid item xs={12} sm={6}>
          {renderMethodOfOperation()}
        </Grid>
        <Grid item xs={12} sm={6}>
          {renderSpeechQuality()}
        </Grid>
        <Grid item xs={12} sm={6}>
          {renderTalk()}
        </Grid>
        <Grid item xs={12} sm={6}>
          {renderTeeth()}
        </Grid>
      </Grid>
    </Dialog>
  );
}

export default connect(null, { closePersonFeatureMultiple, handleError, notifyDataUpdate })(
  AddMultipleFeatureDialog
);
