import Tooltip from './Tooltip';
import { withStyles } from '@material-ui/core/styles';

export default withStyles((theme) => ({
  tooltip: {
    padding: 6,
    borderRadius: 5,
    backgroundColor: theme.palette.type === 'light' ? '#0153a3' : '#778eeb',
    fontSize: theme.typography.pxToRem(12),
    border: `1px solid ${theme.palette.type === 'light' ? '#999999' : '#7c7c7c'}`,
    fontWeight: 400,
    boxShadow: `1px 1px 3px ${
      theme.palette.type === 'light' ? 'rgba(0, 0, 0, 0.5)' : 'rgba(255, 255, 255, 1)'
    }`,
    '& b': {
      fontWeight: 600,
    },
    '& table': {
      color: theme.palette.type === 'light' ? '#3f3f3f' : '#ccc',
      background: theme.palette.type === 'light' ? '#fff' : '#424242',
      width: '100%',
      marginBottom: 7,
      '&:last-of-type': {
        marginBottom: 0,
      },
    },
    '& h4': {
      textAlign: 'center',
      fontSize: 14,
      color: theme.palette.type === 'light' ? '#fff' : '#424242',
      textTransform: 'uppercase',
      marginTop: 0,
      marginBottom: 4,
    },
    '& th': {
      border: `1px solid ${theme.palette.type === 'light' ? '#dedfe3' : '#5b5b5b'}`,
      padding: '2px 5px',
      textTransfrom: 'uppercase',
      whiteSpace: 'nowrap',
      background: theme.palette.type === 'light' ? '#f9f9f9' : '#393939',
    },
    '& td': {
      border: `1px solid ${theme.palette.type === 'light' ? '#dedfe3' : '#5b5b5b'}`,
      padding: '2px 5px',
    },
  },
}))(Tooltip);
