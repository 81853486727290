import React from 'react';

function CityHallIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="50"
      viewBox="0 0 50 50"
      {...props}
      preserveAspectRatio="xMidYMid meet">
      <g transform="translate(0.000000,50.000000) scale(0.100000,-0.100000)" stroke="none">
        <path d="M240 483 c-1 -10 -15 -27 -32 -38 -56 -36 -48 -45 42 -45 90 0 98 9 42 45 -17 11 -31 28 -32 38 0 9 -4 17 -10 17 -5 0 -10 -8 -10 -17z" />
        <path d="M154 364 c3 -9 6 -31 6 -50 0 -19 5 -34 10 -34 6 0 10 18 10 40 0 22 5 40 10 40 6 0 10 -16 10 -36 0 -21 4 -33 10 -29 6 3 10 19 10 36 0 16 5 29 10 29 6 0 10 -11 10 -25 0 -14 5 -25 10 -25 6 0 10 11 10 25 0 14 5 25 10 25 6 0 10 -13 10 -29 0 -17 5 -33 10 -36 6 -4 10 8 10 29 0 20 5 36 10 36 6 0 10 -18 10 -40 0 -22 5 -40 10 -40 6 0 10 15 10 34 0 19 3 41 6 50 5 14 -7 16 -96 16 -89 0 -101 -2 -96 -16z" />
        <path d="M150 252 c-85 -34 -95 -40 -98 -65 l-3 -27 201 0 201 0 -3 28 c-3 24 -12 31 -83 58 -44 17 -89 34 -100 37 -12 4 -59 -9 -115 -31z" />
        <path d="M70 70 l0 -70 65 0 65 0 0 39 c0 42 20 71 48 71 31 0 44 -17 49 -64 l6 -46 63 0 64 0 0 70 0 70 -180 0 -180 0 0 -70z" />
      </g>
    </svg>
  );
}

export default CityHallIcon;
