import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';

import AddressLookup from 'components/RMSAddressLookup';
import RMSCheckbox from 'components/RMSCheckbox';
import Dialog from 'components/RMSDialog';

import { Row, RowInner, ColCard } from 'utils/formStyles';

import { handleError } from 'reducers/ErrorReducer';
import { showSpinner, hideSpinner } from 'reducers/UiReducer';
import { notifyDataUpdate } from 'reducers/DataUpdateReducer';
import { notify } from 'reducers/NotifierReducer';
import { savePersonAddress, getPeopleAddresses, updatePersonAddress } from 'reducers/SearchReducer';
import { closePeopleAddress } from 'reducers/PersonDialogsReducer';

const useStyles = makeStyles((theme) => ({
  closeButton: { color: theme.button.color },
  addressWidget: {
    width: 550,
    display: 'inline-block',
  },
}));

function EditPersonAddressDialog(props) {
  const classes = useStyles();
  const { data } = props;

  const [address, setAddress] = useState(null);
  const [IsPrimary, setIsPrimary] = useState(false);

  const onAddAddress = (address) => {
    setAddress(address);
  };

  const clearPlace = () => {
    setAddress(null);
  };

  const close = () => {
    props.closePeopleAddress();
  };

  const save = async () => {
    const saveData = {
      ptsPersonID: data.ptsPersonID,
      ptsAddressID: address.ptsAddressID,
      IsPrimary,
    };

    props.showSpinner();

    const add = await getPeopleAddresses({
      ptsPersonID: data.ptsPersonID,
      ptsAddressID: address.ptsAddressID,
      IsDeleted: true,
    });

    if (add.total > 0) {
      saveData.IsDeleted = false;
      updatePersonAddress(saveData)
        .then(() => {
          props.notify('Address created', 'success');
          props.notifyDataUpdate({ type: 'Address', data: {} });
          close();
        })
        .catch(props.handleError)
        .finally(props.hideSpinner);
    } else {
      savePersonAddress(saveData)
        .then(() => {
          props.notify('Address created', 'success');
          props.notifyDataUpdate({ type: 'Address', data: {} });
          close();
        })
        .catch(props.handleError)
        .finally(props.hideSpinner);
    }
  };

  const renderActions = () => {
    return (
      <>
        <Button color="primary" variant="contained" autoFocus onClick={save}>
          <SaveIcon /> Save
        </Button>
        <Button color="primary" onClick={close} className={classes.closeButton}>
          <CloseIcon /> Close
        </Button>
      </>
    );
  };

  const renderAddress = () => {
    return (
      <AddressLookup
        className={classes.addressWidget}
        ptsPlaces={false}
        ptsAddresses={true}
        googleAddresses={false}
        onPlaceValueSet={onAddAddress}
        onAddressValueSet={onAddAddress}
        onReset={clearPlace}
        label="Search Address"
        compact
      />
    );
  };

  const renderIsPrimary = () => {
    const onChange = () => setIsPrimary(!IsPrimary);
    return (
      <RMSCheckbox selectedCheckbox={IsPrimary} handleFormChange={onChange} label="Is Primary" />
    );
  };

  return (
    <Dialog toolbar onClose={close} title="Add Address" actions={renderActions()}>
      <Row>
        <ColCard minWidth={500}>
          <RowInner>{renderAddress()}</RowInner>
          <RowInner>{renderIsPrimary()}</RowInner>
        </ColCard>
      </Row>
    </Dialog>
  );
}

export default connect(null, {
  handleError,
  showSpinner,
  hideSpinner,
  notifyDataUpdate,
  notify,
  closePeopleAddress,
})(EditPersonAddressDialog);
