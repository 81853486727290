import React from 'react';

function ChildIcon2(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" {...props}>
      <g transform="translate(0.000000,50.000000) scale(0.100000,-0.100000)" stroke="none">
        <path d="M146 454 c-9 -8 -16 -21 -16 -29 0 -7 -9 -15 -20 -18 -11 -3 -20 -12 -20 -21 0 -18 21 -21 37 -5 8 8 17 7 32 -3 17 -11 25 -11 42 0 15 10 24 11 32 3 16 -16 37 -13 37 5 0 9 -9 18 -20 21 -11 3 -20 11 -20 18 0 20 -29 45 -50 45 -10 0 -26 -7 -34 -16z" />
        <path d="M316 418 c-8 -29 -18 -65 -22 -80 -8 -34 -44 -38 -74 -8 -11 11 -29 20 -40 20 -11 0 -29 -9 -40 -20 -13 -13 -33 -20 -60 -20 -22 0 -40 -4 -40 -10 0 -6 77 -10 210 -10 133 0 210 4 210 10 0 6 -4 10 -10 10 -5 0 -12 16 -16 36 -7 37 -51 96 -85 115 -16 8 -20 3 -33 -43z" />
        <path d="M74 241 c16 -30 47 -54 86 -66 14 -4 35 -14 48 -22 31 -19 13 -45 -27 -37 -50 10 -80 -45 -45 -80 20 -20 48 -20 68 0 23 22 20 40 -3 20 -27 -24 -56 -17 -56 14 0 17 6 26 20 29 17 3 18 1 6 -13 -26 -31 -1 -29 39 3 l40 32 40 -32 c40 -32 65 -34 39 -3 -12 14 -11 16 6 13 14 -3 20 -12 20 -29 0 -31 -29 -38 -56 -14 -23 20 -26 2 -3 -20 20 -20 48 -20 68 0 35 35 5 90 -45 80 -33 -6 -57 15 -36 33 8 6 33 18 57 26 52 18 67 30 86 67 l15 28 -191 0 -191 0 15 -29z m193 -82 c-14 -8 -22 -8 -30 0 -8 8 -3 11 19 11 25 -1 27 -2 11 -11z" />
      </g>
    </svg>
  );
}

export default ChildIcon2;
