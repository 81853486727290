import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import moment from 'moment';
import Slider from 'react-slick';

import { Card, makeStyles, Typography, Grid } from '@material-ui/core';

import avatarPlaceholder from '../../assets/images/avatars/avatar-526x526.png';

import RMSPersonCell from 'components/RMSList/RMSEnhancedCells/RMSPersonCell';
import RMSEditButton from 'components/RMSButtons/RMSEditButton';
import RMSPrintButton from 'components/RMSButtons/RMSPrintButton';
import ReportsDialog from 'components/RMSModals/ReportsDialog';

import { showEditPerson } from 'reducers/PersonDialogsReducer';
import { getFullPermissions } from 'reducers/PermissionsReducer';

const useStyles = makeStyles(() => ({
  itemWrap: {
    fontWeight: 500,
    fontSize: '13px',
    textAlign: 'center',
    height: '20px',
  },
  itemLabel: {
    display: 'block',
    fontWeight: 400,
    background: '#B6C4D2',
    color: '#0b55a1',
    padding: '5px',
    marginBottom: '3px',
    textTransform: 'uppercase',
    borderRadius: '0.2rem',
  },
  imageItem: {
    display: 'block',
    width: '100%',
    height: '100%',
  },
  imgCon: {
    height: '140px',
    margin: '5px 10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  sliderStyle: {
    textAlign: 'center',
    width: '100%',
  },
  editButton: {
    cursor: 'pointer',
    fontSize: '21px',
    marginLeft: '8px',
    color: '#0153a3',
  },
  listRoot: {
    margin: '0px',
    padding: '0px',
  },
}));

// Slider Settings
const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const PersonSearchInfoCard = (props) => {
  /** region Component Variables and Props */
  const { data, wsClient, dataUpdate, setTab, infoCard, dispositionIntCode } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  const [personId, setPersonId] = useState('');
  const [loaded, setLoaded] = useState(false);
  const [personInfo, setPersonInfo] = useState({});
  const [mugshot, setMugshot] = useState([]);
  const [open, setOpen] = useState(false);
  const [reportsData, setReportsData] = useState({});

  const userAgency = useSelector((state) => state.user.userAgency);
  const perosnPermission = getFullPermissions('globals', 'People', 'any');
  /** endregion */
  /** region React Hooks */
  useEffect(() => {
    setPersonInfo({
      PersonId: data?.PersonID || data?.PersonId,
      PersonID: data?.PersonID || data?.PersonId,
      ptsPersonID: data?.ptsPersonID || data?.ptsPersonID,
      fullName: data?.fullName || data?.FullName,
      ageRangeIncrement: data?.AgeRangeIncrement,
      dob: data?.Born ? moment(data?.Born).format('MM/DD/YYYY') : null,
      firstName: data?.firstName || data?.FirstName,
      middleName: data?.middleName || data?.MiddleName,
      lastName: data?.lastName || data?.LastName,
      birthdate: data?.birthdate || data?.Born,
      suffix: data?.suffix || data?.Suffix,
      race: data?.race || data?.Race,
      sex: data?.sex || data?.Sex,
      hairColor: data?.hairColor || data?.HairColor,
      hairLength: data?.hairLength || data?.HairLength,
      eyeColor: data?.eyeColor || data?.EyeColor,
      height: data?.height || data?.Height,
      weight: data?.weight || data?.Weight,
      age: data?.age || data?.Age,
      ssn: data?.PersonCredentialSSN ? data?.PersonCredentialSSN[0]?.Number : '',
      oln: data?.PersonCredentialSSN ? data?.PersonCredentialOLN[0]?.Number : '',
      fullAddressText: data?.PartiesAddresses?.FullAddressText || data?.FullAddressText || '',
      email: data?.Email || '',
      phone1: data?.Phone1 || '',
      phone2: data?.Phone2 || '',
      handed: data?.handed || data?.Handed,
      ethnicity: data?.ethnicity || data?.Ethnicity,
      bloodType: data?.bloodType || data?.BloodType,
      hairStyle: data?.hairStyle || data?.HairStyle,
      sexOffender: data?.SexOffender,
      tags: data?.tags,
      IsDeleted: data?.IsDeleted || false,
    });
    setPersonId(data?.ptsPersonID);
  }, [data]);

  useEffect(() => {
    if (!wsClient?.websocket) {
      return;
    }
    if (personId) getPersonPhotos();
  }, [wsClient, personId, dataUpdate]);
  /** endregion */
  /** region Helper functions */
  const getPersonPhotos = async () => {
    const service = wsClient?.websocket.service('record-person-photo');
    service.timeout = 200000;
    setLoaded(false);
    try {
      const result = await service.get(personId);
      if (result) {
        setLoaded(true);
        let parsedLocalMugshots = result.Mugshots !== null ? JSON.parse(result.Mugshots) : null;
        if (parsedLocalMugshots) {
          const removedMarks = parsedLocalMugshots.filter((item) => !item.IsMark);
          setMugshot(removedMarks);
        }
      }
      setLoaded(true);
    } catch (error) {
      setLoaded(true);
      console.log(error);
    }
  };

  const onDialogClose = () => setOpen(false);

  const handlePersonHistoryPrint = () => {
    setOpen(true);
    setReportsData({ id: 250, data: { ptsPersonID: personId, AgencyID: userAgency } });
  };
  /** endregion */
  return (
    <Grid container spacing={3} wrap="nowrap">
      <Grid item xs={12} sm={2}>
        <Card className={classes.imgCon} onClick={() => setTab('photo')}>
          {loaded ? (
            mugshot.length > 0 ? (
              <Slider {...settings} className={classes.sliderStyle}>
                {mugshot.map((m) => (
                  <a href="#/" onClick={(e) => e.preventDefault()}>
                    <img
                      alt="..."
                      className="card-img-top rounded"
                      src={'data:image/png;base64, ' + m?.FileObject}
                      style={{
                        objectFit: 'contain',
                        maxWidth: '150px',
                        maxHeight: '120px',
                        margin: 'auto',
                      }}
                    />
                  </a>
                ))}
              </Slider>
            ) : (
              <Slider {...settings} className={classes.sliderStyle}>
                <a href="#/" onClick={(e) => e.preventDefault()} className="card-img-wrapper ">
                  <img
                    alt="..."
                    className="card-img-top rounded"
                    src={avatarPlaceholder}
                    style={{
                      objectFit: 'contain',
                      maxWidth: '150px',
                      maxHeight: '120px',
                      margin: 'auto',
                    }}
                  />
                </a>
              </Slider>
            )
          ) : (
            <Typography
              variant="body2"
              color="textSecondary"
              component="p"
              style={{
                width: '100%',
                height: '100%',
                padding: '10px',
              }}>
              Loading...
            </Typography>
          )}
        </Card>
      </Grid>

      <Grid item xs={12} sm={props.personSearch ? 10 : 12}>
        <RMSPersonCell
          row={personInfo}
          duplicateCheck={props.duplicateCheck}
          entityType={'records'}
          infoCard={true}
          origin={props.origin}
          dispositionIntCode={dispositionIntCode}
        />
      </Grid>
      {dispositionIntCode && (
        <div style={{ position: 'absolute', bottom: 16, right: 16 }}>
          <RMSEditButton
            viewOnly={!perosnPermission.Edit}
            onClick={() => dispatch(showEditPerson(personInfo.ptsPersonID))}
          />
        </div>
      )}
      {props.origin === 'personInfoCard' && (
        <div style={{ position: 'absolute', top: 20, right: 20 }}>
          <RMSPrintButton
            tooltipText="Print Person History Report"
            onClick={handlePersonHistoryPrint}
          />
        </div>
      )}
      {open && <ReportsDialog open={open} onClose={onDialogClose} report={reportsData} />}
    </Grid>
  );
};

PersonSearchInfoCard.propTypes = {
  props: PropTypes,
};

const mapStateToProps = (state) => ({
  wsClient: state.websocket,
  dataUpdate: state.dataUpdate,
});

export default connect(mapStateToProps, {})(PersonSearchInfoCard);
