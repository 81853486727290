import React, { useState, useRef, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';

import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';

import Tooltip from 'components/Tooltip';
import RMSXGrid from 'components/RMSXGrid';
import QuickFilterSearch from 'components/RMSSearch/components/QuickFilterSearch';
import PrintsSearch2 from 'components/RMSSearch/components/PrintsSearch2';

import { showSpinner, hideSpinner } from 'reducers/UiReducer';
import { handleError } from 'reducers/ErrorReducer';
import { showContactMethod } from 'reducers/PersonDialogsReducer';
import { getContactMethods } from 'reducers/SearchReducer';
import { getService } from 'reducers/service';
import { notify } from 'reducers/NotifierReducer';
import { getFullPermissions } from 'reducers/PermissionsReducer';
import { displayDateTime } from 'reducers/TimeReducer';

const useStyles = makeStyles((theme) => ({
  wrap: {
    padding: theme.spacing(3),
  },
  gridWrap: {
    width: '100%',
    boxSizing: 'border-box',
    height: 600,
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(2),
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
  fileInput: {
    display: 'none',
  },
  filterWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
}));

const defaultColumns = [
  'ptsInfoID',
  'ContactMethod',
  {
    field: 'MethodType',
    headerName: 'Type',
  },
  'PartyType',
  'Info',
  {
    field: 'Created',
    headerName: 'Created',
    width: 200,
    valueFormatter: (params) => displayDateTime(params.value),
    hide: false,
  },
  { field: 'CreatedBy', headerName: 'Created By', width: 150, hide: false },
  {
    field: 'Updated',
    headerName: 'Updated',
    width: 200,
    valueFormatter: (params) => displayDateTime(params.value),
    hide: false,
  },
  { field: 'UpdatedBy', headerName: 'Updated By', width: 150, hide: false },
];
const ContactMethods = (props) => {
  const classes = useStyles();
  const { personId, editContactMethod } = props;
  const [rows, setRows] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [filteredRows, setFilteredRows] = useState([]);
  const [selection, setSelection] = useState(null);
  const [columns, setColumns] = useState(defaultColumns);
  const dispatch = useDispatch();
  const mountedRef = useRef(true);
  const [isDeleted, setIsDeleted] = useState(false);
  const perms = getFullPermissions('globals', 'People', 'any');
  const canEditPerson = perms.Edit;
  useEffect(() => {
    personId && getData();
  }, [editContactMethod, isDeleted]);

  const getData = async () => {
    try {
      const data = await getContactMethods(personId);
      if (!mountedRef.current) return;

      setRows(processData(data.data));
      setLoaded(true);
    } catch (err) {
      props.handleError(err);
    }
  };

  const processData = (data) => {
    return data.map((row, idx) => {
      return {
        ...row,
        id: idx,
      };
    });
  };

  const addContact = () => {
    dispatch(showContactMethod({ ptsParentID: personId, PartyType: 'Person' }));
  };

  const edit = () => {
    const item = rows.find((row) => row.id === selection);
    dispatch(showContactMethod({ ...item }));
  };

  const deleteContact = async () => {
    const item = rows.find((row) => row.id === selection);
    try {
      props.showSpinner();
      const service = getService('record-person-contact');
      await service.remove(item.ptsInfoID);
      dispatch(notify('Contact Deleted', 'warning'));
      setIsDeleted(!isDeleted);
    } catch (err) {
      props.handleError(err);
    } finally {
      props.hideSpinner();
    }
  };

  const renderActions = () => (
    <div className={classes.actions}>
      <Tooltip title="Print">
        <PrintsSearch2 title="Contact Methods" data={filteredRows} cols={columns} />
      </Tooltip>
      <Tooltip title="Add Contact">
        <Fab size="small" color="secondary" onClick={addContact} disabled={!canEditPerson}>
          <AddIcon />
        </Fab>
      </Tooltip>

      {selection !== null && (
        <>
          <Tooltip title="Edit Contact">
            <Fab size="small" color="secondary" onClick={edit} disabled={!canEditPerson}>
              <EditIcon />
            </Fab>
          </Tooltip>
          <Tooltip title="Delete Contact">
            <Fab size="small" color="secondary" onClick={deleteContact} disabled={!canEditPerson}>
              <DeleteIcon />
            </Fab>
          </Tooltip>
        </>
      )}
    </div>
  );

  return (
    <div className={classes.wrap}>
      <div className={classes.filterWrap}>
        <div className={classes.filterWrap}>
          <QuickFilterSearch
            rows={rows}
            columns={columns}
            setFilteredRows={setFilteredRows}
            setData={props.setData}
            style={{ marginTop: 0, paddingBottom: 14 }}
          />
          {renderActions()}
        </div>
      </div>
      <RMSXGrid
        name="contactMethod"
        columns={columns}
        setColumns={setColumns}
        rows={filteredRows}
        loading={!loaded}
        setSelection={setSelection}
      />
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    editContactMethod: state.dialogs.editContactMethod,
  };
};
export default connect(mapStateToProps, { handleError, showSpinner, hideSpinner })(ContactMethods);
