import SimpleSchema from 'simpl-schema';

import store from '../../config/configureStore';

import { getCategoryOfCode } from 'utils/functions';

SimpleSchema.setDefaultMessages({
  messages: {
    en: {
      'NIBRS-2019.2-127':
        'When a Person is a victim and the Relationship to the Offender indicates Spouse then the Victim Age must be 13 or greater.',
      'NIBRS-2019.2-149':
        'A Victim cannot be associated to a Offender when the Victim Type is not (I,L).',
      'NIBRS-2019.2-154': `When the Age of Offender is 00, Sex of Offender is 'U', and Race of Offender is 'U', then Victim To Subject Relationship must be 'Relationship Unknown.`,
      'NIBRS-2019.2-150':
        'When the Offense Victim Association contains Offense Code of (Crime Against Person or Crime Against Property), then the same Victim must have a Subject Victim Association.',
      'NIBRS-2019.2-148': 'A Victim can be associated with up to ten (10) different Offenders.',
      'NIBRS-2019.2-152': 'A Victim can only have one spousal relationship with an Offender.',
      'NIBRS-2019.2-162':
        'When the Offense Code is 11A, then Sex of Victim can not be the same as the Sex of Offender.',
      'NIBRS-2019.2-159':
        'The age of the Offender must be consistent with the type of relationship between the Victim and Offender.',
      'NIBRS-2019.2-109':
        'Each Offense Segment with a valid Offense code, must have an Offense Victim Association.',
      'NIBRS-2019.2-160':
        'The age of the Offender must be consistent with the type of relationship between the Victim and Offender.',
    },
  },
});

const validRelationshipsCounts = (subjectIds, victimIds) => {
  let totalRelationships = 0;

  subjectIds.map((sID) => {
    totalRelationships += victimIds.includes(sID) ? victimIds?.length - 1 : victimIds?.length;
  });

  return totalRelationships;
};

export const incSubjectVictimRelationshipSchema = new SimpleSchema({
  allRelations: {
    type: Array,
    optional: true,
    custom() {
      // NIBRS-2019.2-109: Victim Connected to Offense
      let crimeAgainst = this.obj.offensesOffenseForm.values?.statuteDetails?.CrimeAgainst;
      let missingRelations =
        !this.value.length ||
        this.value.length !== validRelationshipsCounts(this.obj.subjectIds, this.obj.victimIds) ||
        this.value.some((r) => !(r.Relationship ?? r.relationship));

      if (
        missingRelations &&
        crimeAgainst !== 'SOCIETY' &&
        (crimeAgainst === 'PERSON' || (crimeAgainst === 'PROPERTY' && this.obj.victimType))
      ) {
        return 'NIBRS-2019.2-109';
      }
    },
  },
  'allRelations.$': {
    type: Object,
    optional: true,
    blackbox: true,
    custom() {},
  },
  victimOffenders: {
    type: Array,
    optional: true,
    custom() {
      if (this.value.length > 10) {
        return 'NIBRS-2019.2-148';
      }
    },
  },
  'victimOffenders.$': {
    type: SimpleSchema.Integer,
    optional: true,
    custom() {},
  },
  victimSpouses: {
    type: Array,
    optional: true,
    custom() {
      if (this.value.length > 1) {
        return 'NIBRS-2019.2-152';
      }
    },
  },
  'victimSpouses.$': {
    type: SimpleSchema.Integer,
    optional: true,
    custom() {},
  },
  relationship: {
    type: String,
    label: 'Relationship',
    optional: true,
    custom() {
      const codeVictimSubjectRelationship = store.store.getState().dictionary
        .codeVictimSubjectRelationship;
      const victimRelationshipCategory = getCategoryOfCode(
        codeVictimSubjectRelationship,
        this.value
      );

      if (victimRelationshipCategory === 'se') {
        if (this.obj.victim.age < 13) return 'NIBRS-2019.2-127';
      }

      if (victimRelationshipCategory === 'pa' || victimRelationshipCategory === 'gp') {
        if (this.obj.victim.age < this.obj.subject.age) return 'NIBRS-2019.2-159';
      }

      if (victimRelationshipCategory === 'ch' || victimRelationshipCategory === 'gc') {
        if (this.obj.victim.age > this.obj.subject.age) return 'NIBRS-2019.2-160';
      }

      /* The Relationship tab is disabled for this case */
      // NIBRS-2019.2-149: Offender Number
      // const codeVictimCategory = store.store.getState().dictionary.codeVictimCategory;
      // if (!['i', 'l'].includes(getCategoryOfCode(codeVictimCategory, this.obj.victim.category))) {
      //   if (this.value) {
      //     return 'NIBRS-2019.2-149';
      //   }
      // }

      /* TODO: Need to adjust the validation based on the new relationship changes */
      //NIBRS-2019.2-150: Offender Number
      // if (
      //   this.obj.offense?.values?.statuteDetails?.CrimeAgainst === 'PERSON' ||
      //   (this.obj.offense?.values?.statuteDetails?.CrimeAgainst === 'PROPERTY' &&
      //     this.obj.victim?.category === 'I')
      // ) {
      //   if (this.value === undefined || this.value === null || this.value === '') {
      //     return 'NIBRS-2019.2-150';
      //   }
      // }

      //NIBRS-2019.2-154: Relationship to Victim
      const codeRace = store.store.getState().dictionary.codeRace;
      const codeSex = store.store.getState().dictionary.codeSex;
      if (
        this.obj.offenderDetails.age === 0 &&
        getCategoryOfCode(codeRace, this.obj.offenderDetails.race) === 'u' &&
        getCategoryOfCode(codeSex, this.obj.offenderDetails.sex) === 'u'
      ) {
        if (victimRelationshipCategory !== 'ru') {
          return 'NIBRS-2019.2-154';
        }
      }
    },
  },
  offenderSex: {
    type: String,
    optional: true,
    custom() {
      /* TODO: Need to adjust validation based on the new relationship changes */
      //NIBRS-2019.2-162: Offender Sex
      // if (this.obj.offense?.values?.statuteDetails?.FBICode === '11A') {
      //   if (this.obj.offenderDetails.sex === this.obj.victimDetails.sex) {
      //     return 'NIBRS-2019.2-162';
      //   }
      // }
    },
  },
});
