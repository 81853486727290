import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function iconsagencytypesheriffIcon(props) {
  return (
    <SvgIcon viewBox="0 0 30 30" {...props}>
      <path d="M15 2a1 1 0 0 0-1 1 1 1 0 0 0 .5.865L11.535 9H6a1 1 0 0 0-1-1 1 1 0 0 0-1 1 1 1 0 0 0 1 1 1 1 0 0 0 .176-.016L8.072 15l-2.896 5.016A1 1 0 0 0 5 20a1 1 0 0 0-1 1 1 1 0 0 0 1 1 1 1 0 0 0 1-1h5.535l2.965 5.135A1 1 0 0 0 14 27a1 1 0 0 0 1 1 1 1 0 0 0 1-1 1 1 0 0 0-.5-.865L18.465 21H24a1 1 0 0 0 1 1 1 1 0 0 0 1-1 1 1 0 0 0-1-1 1 1 0 0 0-.176.016L21.928 15l2.896-5.016A1 1 0 0 0 25 10a1 1 0 0 0 1-1 1 1 0 0 0-1-1 1 1 0 0 0-1 1h-5.535L15.5 3.865A1 1 0 0 0 16 3a1 1 0 0 0-1-1zm0 8a5 5 0 1 1-.001 10.001A5 5 0 0 1 15 10zm0 2a3 3 0 1 0 0 6 3 3 0 0 0 0-6z" />
    </SvgIcon>
  );
}

export default iconsagencytypesheriffIcon;
