import React, { useState } from 'react';
import { connect } from 'react-redux';

import { Button, Badge, Menu, List, ListItem, makeStyles, Tooltip } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getExcerpt } from 'utils/functions';

import { handleError } from 'reducers/ErrorReducer';
import { showBulletinDetails } from 'reducers/PersonDialogsReducer';
import { displayDateTime } from 'reducers/TimeReducer';
import { markBulletinRead } from 'reducers/BulletinReducer';
import { getFullPermissions } from 'reducers/PermissionsReducer';

const useStyles = makeStyles((theme) => ({
  itemsBtn: {
    color: theme.palette.colors.label,
    padding: '7px 16px',
  },
  label: {
    flexDirection: 'column',
  },
  lstItem: {
    borderBottom: '1px solid grey',
  },
  menuInner: {
    width: 300,
  },
  list: {
    padding: 0,
    marginTop: -4,
    marginBottom: -4,
    fontSize: 13,
  },
  menu: {
    //marginLeft: -80,
  },
  table: {
    width: '100%',
    '& td:first-child button': {
      marginLeft: -4,
      marginBottom: -4,
    },
    '& td:nth-child(2)': {
      textAlign: 'right',
      '& button': {
        marginRight: -4,
        marginBottom: -4,
      },
    },
  },
}));

function UnreadAlerts(props) {
  const { unreadBulletins } = props;
  const classes = useStyles();
  const bulletinePermission = getFullPermissions('cad', 'Alerts', 'any');

  const [bellAnchorEl, setBellAnchorEl] = useState(null);

  const handleBellClick = (event) => {
    setBellAnchorEl(event.currentTarget);
  };

  const handleBellMenuClose = () => {
    setBellAnchorEl(null);
  };

  const renderBellFab = () => {
    return (
      <Tooltip title="Bulletins">
        <Button
          color="inherit"
          classes={{ root: classes.itemsBtn, label: classes.label }}
          onClick={handleBellClick}
          disabled={!unreadBulletins?.length || !bulletinePermission.Read}>
          <Badge badgeContent={unreadBulletins?.length} color="secondary">
            <FontAwesomeIcon icon="bell" className="font-size-xl" />
          </Badge>
          Bulletin
        </Button>
      </Tooltip>
    );
  };

  const renderBulletinList = () => {
    return (
      <Menu
        anchorEl={bellAnchorEl}
        keepMounted
        open={Boolean(bellAnchorEl) && unreadBulletins.length > 0}
        onClose={handleBellMenuClose}
        className={classes.menu}
        PaperProps={{
          className: classes.menuInner,
        }}>
        <List className={classes.list}>
          {unreadBulletins.length > 10 && (
            <ListItem className={classes.lstItem} style={{ justifyContent: 'flex-end' }}>
              <Button
                disabled={!bulletinePermission.Read}
                size="small"
                color="primary"
                onClick={() => props.markBulletinRead('all')}>
                Mark All as Read
              </Button>
            </ListItem>
          )}

          {unreadBulletins.map((bulletin) => {
            const {
              ptsAlertID,
              AlertID,
              AlertType,
              Notification,
              OLN,
              OLNState,
              Plate,
              PlateState,
              Priority,
              Ranges,
              Created,
              Person,
            } = bulletin;
            return (
              <ListItem key={bulletin.ptsAlertID} className={classes.lstItem}>
                <table className={classes.table}>
                  <tbody>
                    <tr>
                      <td>
                        <strong>{AlertID}</strong>
                      </td>
                      <td style={{ whiteSpace: 'nowrap' }}>{displayDateTime(Created)}</td>
                    </tr>
                    {(Boolean(AlertType) || Boolean(Priority)) && (
                      <tr>
                        <th>{AlertType}</th>
                        <td>Priority: {Priority}</td>
                      </tr>
                    )}
                    {Boolean(Notification) && (
                      <tr>
                        <td colSpan={2}>{getExcerpt(Notification, 80)}</td>
                      </tr>
                    )}
                    {Boolean(Person) && (
                      <tr>
                        <th>Person:</th>
                        <td>{Person}</td>
                      </tr>
                    )}
                    {Boolean(OLN) && (
                      <tr>
                        <th>OLN:</th>
                        <td>
                          {OLN} {OLNState}
                        </td>
                      </tr>
                    )}
                    {Boolean(Plate) && (
                      <tr>
                        <th>Plate:</th>
                        <td>
                          {Plate} {PlateState}
                        </td>
                      </tr>
                    )}
                    {Boolean(Ranges) && (
                      <tr>
                        <th>Range:</th>
                        <td>{Ranges}</td>
                      </tr>
                    )}
                    <tr>
                      <td>
                        <Button
                          size="small"
                          color="primary"
                          onClick={() => props.showBulletinDetails(bulletin)}>
                          Show Details
                        </Button>
                      </td>
                      <td>
                        <Button
                          size="small"
                          color="primary"
                          disabled={!bulletinePermission.Read}
                          onClick={() => props.markBulletinRead(ptsAlertID)}>
                          Mark as Read
                        </Button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </ListItem>
            );
          })}
        </List>
      </Menu>
    );
  };

  return (
    <div>
      {renderBellFab()}
      {Boolean(bellAnchorEl) && renderBulletinList()}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    unreadBulletins: state.unreadBulletins,
  };
};

export default connect(mapStateToProps, {
  showBulletinDetails,
  handleError,
  markBulletinRead,
})(UnreadAlerts);
