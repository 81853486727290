import React, { useState, useRef, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';

import Fab from '@material-ui/core/Fab';
import { makeStyles } from '@material-ui/core/styles';

import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import { AddToPhotos } from '@material-ui/icons';
import DeleteIcon from '@material-ui/icons/Delete';

import Tooltip from 'components/Tooltip';
import RMSXGrid from 'components/RMSXGrid';
import PrintsSearch2 from 'components/RMSSearch/components/PrintsSearch2';
import QuickFilterSearch from 'components/RMSSearch/components/QuickFilterSearch';

import { getService } from 'reducers/service';
import { notify } from 'reducers/NotifierReducer';
import { handleError } from 'reducers/ErrorReducer';
import { getPeopleFeatures } from 'reducers/SearchReducer';
import { showSpinner, hideSpinner } from 'reducers/UiReducer';
import { displayDateTime } from 'reducers/TimeReducer';
import { getFullPermissions } from 'reducers/PermissionsReducer';
import { showPersonFeature, showPersonFeatureMultiple } from 'reducers/PersonDialogsReducer';

const useStyles = makeStyles((theme) => ({
  wrap: {
    padding: theme.spacing(3),
  },
  gridWrap: {
    width: '100%',
    boxSizing: 'border-box',
    height: 600,
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(2),
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
  fileInput: {
    display: 'none',
  },
  filterWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
}));

const defaultColumns = [
  'ptsFeatureID',
  'AttributeCategory',
  'Attribute',
  {
    field: 'Created',
    headerName: 'Created',
    width: 200,
    valueFormatter: (params) => displayDateTime(params.value),
    hide: false,
  },
  { field: 'CreatedBy', headerName: 'Created By', width: 150, hide: false },
  {
    field: 'Updated',
    headerName: 'Updated',
    width: 200,
    valueFormatter: (params) => displayDateTime(params.value),
    hide: false,
  },
  { field: 'UpdatedBy', headerName: 'Updated By', width: 150, hide: false },
];
const Features = (props) => {
  const classes = useStyles();
  const { ptsPersonID, dataUpdate } = props;
  const [rows, setRows] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [filteredRows, setFilteredRows] = useState([]);
  const [selection, setSelection] = useState(null);
  const [tables, setTables] = useState(null);
  const [colsSeq, setColsSeq] = useState([]);
  const [columns, setColumns] = useState(defaultColumns);
  const dispatch = useDispatch();
  const mountedRef = useRef(true);
  const [isDeleted, setIsDeleted] = useState(false);
  const perms = getFullPermissions('globals', 'People', 'any');
  const canEditPerson = perms.Edit;
  useEffect(() => {
    ptsPersonID && getData();
  }, [isDeleted]);

  useEffect(() => {
    if (dataUpdate?.type === 'person-feature') getData();
  }, [dataUpdate]);

  const getData = async () => {
    try {
      const data = await getPeopleFeatures(ptsPersonID);
      if (!mountedRef.current) return;

      setRows(processData(data.data));
      setLoaded(true);
    } catch (err) {
      props.handleError(err);
    }
  };
  const processData = (data) => {
    return data.map((row, idx) => {
      return {
        ...row,
        id: idx,
      };
    });
  };

  const handleAdd = () => props.showPersonFeature({ ptsPersonID });

  const handleAddMultiple = () => props.showPersonFeatureMultiple({ ptsPersonID });

  const edit = () => {
    const item = rows.find((row) => row.id === selection);
    props.showPersonFeature({ ...item });
  };

  const deleteFeature = async () => {
    const item = rows.find((row) => row.id === selection);
    try {
      props.showSpinner();
      const service = getService('cad-person-features');
      await service.remove(item.ptsFeatureID);
      props.hideSpinner();
      setIsDeleted(!isDeleted);
      props.notify('Feature deleted successfully', 'warning');
    } catch (err) {
      props.handleError(err);
    } finally {
      props.hideSpinner();
    }
  };

  const renderActions = () => (
    <div className={classes.actions}>
      <Tooltip title="Print">
        <PrintsSearch2 title="Features" data={filteredRows} cols={columns} />
      </Tooltip>
      <Tooltip title="Add Feature">
        <Fab size="small" color="secondary" disabled={!canEditPerson} onClick={handleAdd}>
          <AddIcon />
        </Fab>
      </Tooltip>
      <Tooltip title="Add Multiple Features">
        <Fab size="small" color="secondary" disabled={!canEditPerson} onClick={handleAddMultiple}>
          <AddToPhotos />
        </Fab>
      </Tooltip>
      {selection !== null && (
        <>
          <Tooltip title="Edit Feature">
            <Fab size="small" color="secondary" disabled={!canEditPerson} onClick={edit}>
              <EditIcon />
            </Fab>
          </Tooltip>
          <Tooltip title="Delete Feature">
            <Fab size="small" color="secondary" disabled={!canEditPerson} onClick={deleteFeature}>
              <DeleteIcon />
            </Fab>
          </Tooltip>
        </>
      )}
    </div>
  );

  return (
    <div className={classes.wrap}>
      <div className={classes.filterWrap}>
        <div className={classes.filterWrap}>
          <QuickFilterSearch
            rows={rows}
            columns={columns}
            setFilteredRows={setFilteredRows}
            style={{ marginTop: 0, paddingBottom: 14 }}
          />
          {renderActions()}
        </div>
      </div>
      <RMSXGrid
        name="PeopleFeatures"
        columns={columns}
        setColumns={setColumns}
        rows={filteredRows}
        loading={!loaded}
        setSelection={setSelection}
      />
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    editPeopleEmployment: state.dialogs.editPeopleEmployment,
    dataUpdate: state.dataUpdate,
  };
};
export default connect(mapStateToProps, {
  handleError,
  showSpinner,
  hideSpinner,
  showPersonFeature,
  showPersonFeatureMultiple,
  notify,
})(Features);
