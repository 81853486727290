import React, { useState, useEffect, useRef } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';

import ContactMethods from './ContactMethods';
import Credential from './Credential';
import Employment from './Employment';
import PersonAssociate from './PersonAssociate';
import Features from './Features';
import Names from './Names';
import PersonVehicle from './PersonVehicle';
import Addresses from './Addresses';
import Tags from './Tags';
import Sops from './Sops';
import DnaSamples from './DnaSamples';
import Photo from './Photo';
import RelatedRecords from './RelatedRecords';
import Bulletins from './Bulletins';

import Dialog from 'components/RMSDialog';
import PersonTab from 'components/RMSRecordsDialogs/AddPersonDialog/PersonTab.js';
import RMSAttachments2 from 'components/RMSAttachments/RMSAttachments2';
import PersonSearchInfoCard from 'components/RMSPersonInfoCard/PersonSearchInfoCard';
import RMSPersonTagsCard from 'components/RMSPersonTagsCard';
import RMSConfirmationModal from 'components/RMSModals/RMSConfirmationModal/index';

import { closeEditPerson, setNewCreatedPesonAsSelected } from 'reducers/PersonDialogsReducer';
import { handleError } from 'reducers/ErrorReducer';
import { setIsRecordFormDirty } from 'reducers/PersonDialogsReducer';
import SMTRecords from './SMTRecords';
import { getFullPermissions } from 'reducers/PermissionsReducer';

const useStyles = makeStyles((theme) => ({
  wrap: {
    padding: theme.spacing(3),
  },
  content: {
    margin: -theme.spacing(3),
  },
  gridBg: {
    width: '98%',
    margin: '15px 0px 15px 0px',
    border: '2px solid',
    borderColor: theme.palette.colors.borderColor,
    display: 'block',
    height: '155px',
    paddingTop: '2px',
  },
  fieldsetTitle: {
    position: 'absolute',
    marginTop: '3px',
    zIndex: 9999,
    color: 'white',
    background: theme.palette.colors.chipBg,
    borderRadius: '10px',
    padding: '2px 10px',
    marginLeft: '10px',
  },
}));

function Person(props) {
  const classes = useStyles();
  const mountedRef = useRef(true);
  const { ptsPersonID, setIsRecordFormDirty } = props;
  const permissions = getFullPermissions('globals', 'People', 'any');

  const [tab, setTab] = useState('edit');
  const [personInfo, setPersonInfo] = useState({});
  const [addTags, setAddTags] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [tabValue, setTabValue] = useState('edit');

  const wsClient = useSelector((state) => state.websocket);
  const codeTagTypes = useSelector((state) => state.dictionary.codeTagTypes);
  const isRecordFormDirty = useSelector((state) => state.dialogs.isRecordFormDirty);
  const dispatch = useDispatch();
  const newAddress = !ptsPersonID;

  useEffect(() => {
    if (props.tab) setTab(props.tab);

    return () => {
      mountedRef.current = false;
    };
  }, [props.tab]);

  useEffect(() => {
    getPerson();
  }, [wsClient]);

  const close = (data) => {
    props.closeEditPerson(data);
    if (props.origin === 'party') {
      dispatch(setNewCreatedPesonAsSelected(ptsPersonID));
    }
    setIsRecordFormDirty(false);
  };

  const changeTab = (value) => {
    if (isRecordFormDirty && (permissions.Edit || permissions.Create)) {
      setOpenConfirmation(true);
      setTabValue(value);
    } else setTab(value);
  };

  const handleCloseConfirmation = () => {
    setOpenConfirmation(false);
    setTabValue('edit');
  };

  const handleConfirmationClick = () => {
    setOpenConfirmation(false);
    setIsRecordFormDirty(false);
    setTab(tabValue);
  };

  const getPerson = async () => {
    if (!wsClient?.websocket) {
      return;
    }

    const service = wsClient?.websocket.service('record-person-details');
    service.timeout = 200000;

    const result = await service.get(ptsPersonID);

    if (result) {
      let processedTags = result.tags;

      processedTags.forEach((t) => {
        let code = codeTagTypes.find((c) => c.Code === t.Type);

        if (!code) return;

        t.Code = code.Code;
        t.CodeDesc = code.Description;
        t.Priority = code.Priority;
        t.IsActive = code.IsActive;
        t.Icon = code.Icon;
      });

      result.tags = processedTags.sort((a, b) => (a.Priority < b.Priority ? 1 : -1));
    }

    setPersonInfo(result);
  };

  return (
    <>
      <Dialog
        toolbar
        onClose={close}
        title={
          newAddress ? (
            'Add Person'
          ) : permissions.Edit ? (
            'Edit Person'
          ) : (
            <div
              className="d-flex"
              style={{ width: '100%', justifyContent: 'left', textAlign: 'left' }}>
              <span style={{ flex: 0.5 }}>Edit Person</span>
              <span style={{ flex: 0.5, color: 'red', fontWeight: 'bold', fontSize: '21px' }}>
                VIEW ONLY MODE
              </span>
            </div>
          )
        }
        fullScreen>
        <div className={classes.content}>
          <Grid container>
            <Grid container item xs={10}>
              <Paper
                className={classes.gridBg}
                container
                spacing={1}
                wrap="nowrap"
                style={{ marginLeft: '15px' }}>
                <Grid spacing={1} item xs={12}>
                  <PersonSearchInfoCard
                    data={personInfo}
                    personSearch={true}
                    setTab={setTab}
                    origin="personInfoCard"
                  />
                </Grid>
              </Paper>
            </Grid>
            <Grid container item xs={2}>
              <div className={classes.fieldsetTitle}>Tags</div>
              <Paper
                className={classes.gridBg}
                style={{ marginRight: '20px' }}
                container
                spacing={1}
                wrap="nowrap">
                <RMSPersonTagsCard
                  tags={personInfo.tags}
                  setTab={setTab}
                  setAddTags={setAddTags}
                  setData={getPerson}
                  personPermissions={permissions}
                />
              </Paper>
            </Grid>
          </Grid>

          <Paper square>
            <Tabs variant="scrollable" value={tab} onChange={(ev, val) => changeTab(val)}>
              <Tab label="Edit Person" value="edit" />
              <Tab label="Addresses" value="addresses" />
              <Tab label="Associate" value="associate" />
              <Tab label="Attachments" value="attachments" />
              <Tab label="Bulletins" value="bulletins" />
              <Tab label="Contact Methods" value="contactMethods" />
              <Tab label="Credential" value="credential" />
              <Tab label="DNA Samples" value="dna" />
              <Tab label="Employment" value="employment" />
              <Tab label="Features" value="features" />
              <Tab label="Names" value="names" />
              <Tab label="Photo" value="photo" />
              <Tab label="Related Records" value="relatedRecords" />
              <Tab label="SMT Records" value="smt" />
              <Tab label="Sops" value="sops" />
              <Tab label="Tags" value="tags" />
              <Tab label="Vehicle Registrations" value="vehicle" />
              {/* <Tab label="Coordinates" value="coordinates" /> */}
            </Tabs>
          </Paper>
          {tab === 'edit' && (
            <div className={classes.wrap}>
              <PersonTab personId={ptsPersonID} close={close} setData={getPerson} />
            </div>
          )}
          {tab === 'tags' && (
            <Tags
              ptsPersonID={ptsPersonID}
              setData={getPerson}
              addTags={addTags}
              setAddTags={setAddTags}
            />
          )}
          {tab === 'employment' && <Employment ptsPersonID={ptsPersonID} />}
          {tab === 'contactMethods' && (
            <ContactMethods personId={ptsPersonID} setData={getPerson} />
          )}
          {tab === 'credential' && <Credential personId={ptsPersonID} setData={getPerson} />}
          {tab === 'associate' && <PersonAssociate personId={ptsPersonID} />}
          {tab === 'features' && <Features ptsPersonID={ptsPersonID} />}
          {tab === 'names' && <Names ptsPersonID={ptsPersonID} setData={getPerson} />}
          {tab === 'vehicle' && <PersonVehicle personId={ptsPersonID} setData={getPerson} />}
          {tab === 'addresses' && <Addresses ptsPersonID={ptsPersonID} setData={getPerson} />}
          {tab === 'sops' && <Sops personId={ptsPersonID} />}
          {tab === 'attachments' && (
            <RMSAttachments2 type="Person" id={ptsPersonID} permissions={permissions} />
          )}
          {tab === 'dna' && <DnaSamples ptsPersonID={ptsPersonID} />}
          {tab === 'smt' && <SMTRecords ptsPersonID={ptsPersonID} />}
          {tab === 'photo' && <Photo ptsPersonID={ptsPersonID} />}
          {tab === 'relatedRecords' && <RelatedRecords ptsPersonID={ptsPersonID} />}
          {tab === 'bulletins' && <Bulletins person={{ ptsPersonID }} />}
        </div>
      </Dialog>
      <RMSConfirmationModal
        title="You have unsaved changes!"
        text="You have unsaved changes in the person form. Any unsaved data will be lost. Do you wish to proceed?"
        saveBtnName="Yes"
        noBtnName="No"
        open={openConfirmation}
        closeDialog={handleCloseConfirmation}
        handleModalSave={handleConfirmationClick}
      />
    </>
  );
}

export default connect(null, {
  closeEditPerson,
  handleError,
  setIsRecordFormDirty,
})(Person);
