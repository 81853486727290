import SimpleSchema from 'simpl-schema';

import store from '../../config/configureStore';
import { getCategoryOfCode } from 'utils/functions';

SimpleSchema.setDefaultMessages({
  messages: {
    en: {
      'NIBRS-2019.2-124':
        'When a Person age is provided it must contain a valid value of 00-99 or (NN,NB,BB).',
      'NIBRS-2019.2-176': 'The Age of the Arrestee must be provided.',
      'NIBRS-2019.2-180': 'The Person Race NDEX of the Arrestee must be provided.',
      'NIBRS-2019.2-178': 'The Person Sex of the Arrestee must be provided.',
      'NIBRS-2019.2-179': 'For Person Sex of the Arrestee, U = Unknown is not a valid value.',
      'NIBRS-2019.2-128':
        'When a Victim Category of (L) is provided then the Victim Age must be 17-98 or zero for Unknown.',
      'NIBRS-2019.2-130':
        'The Victim Age must be less than 18 when the Victim Connected to Offense code is 36B.',
      'NIBRS-2019.2-131':
        'The Victim Person Sex must be M or F when the Victim Connected to Offense code is 11A or 36B.',
      'NIBRS-2019.2-129 Age':
        'A Victim Age can only be provided when the Victim Category is (I,L).',
      'NIBRS-2019.2-129 Sex':
        'A Victim Person Sex can only be provided when the Victim Category is (I,L).',
      'NIBRS-2019.2-129 Race':
        'A Victim Person Race NDEX can only be provided when the Victim Category is (I,L).',
      'NIBRS-2019.2-129 Resident':
        'A Victim Person Resident can only be provided when the Victim Category is (I,L).',
      'NIBRS-2019.2-129 Ethnicity':
        'A Victim Person Ethnicity can only be provided when the Victim Category is (I,L).',
      'NIBRS-2019.2-123(Race)':
        'When a Victim is (I,L) is provided then Person Race NDEX is required.',
      'NIBRS-2019.2-123(Age)': 'When a Victim is (I,L) is provided then Age is required.',
      'NIBRS-2019.2-123(Sex)': 'When a Victim is (I,L) is provided then Person Sex is required.',
      'NIBRS-2019.2-157(Race)':
        'When the Offense Code = 09C, then Offender Person Race NDEX must be known.',
      'NIBRS-2019.2-157(Age)': 'When the Offense Code = 09C, then Offender Age must be known.',
      'NIBRS-2019.2-157(Sex)':
        'When the Offense Code = 09C, then Offender Person Sex must be known.',
      'NIBRS-2019.2-126':
        'When a Person age range is provided then the minimum age value must be greater than zero.',
      'NIBRS-2019.2-158 Age':
        'A Offender Age can only be provided when the Offender Sequence Number is not zero - Unknown.',
      'NIBRS-2019.2-158 Race':
        'A Offender Person Race NDEX can only be provided when the Offender Sequence Number is not zero - Unknown.',
      'NIBRS-2019.2-158 Sex':
        'A Offender Person Sex can only be provided when the Offender Sequence Number is not zero - Unknown.',
    },
  },
});

export const partiesSchema = new SimpleSchema({
  age: {
    type: SimpleSchema.Integer,
    label: 'Person Age',
    optional: true,
    custom() {
      const codeVictimCategory = store.store.getState().dictionary.codeVictimCategory;
      if (this.obj.victims.length) {
        if (this.value && (this.value < 0 || this.value > 99)) {
          return 'NIBRS-2019.2-124';
        }

        //NIBRS-2019.2-126: Person Age
        if (this.obj.personForm.values?.ageRangeIncrement) {
          let value = parseInt(this.obj.personForm.values?.age);
          if (value === 0 || value < 0) {
            return 'NIBRS-2019.2-126';
          }
        }

        for (let victimOffense of this.obj.victimOffenses) {
          if (victimOffense) {
            if (
              victimOffense.offenseDetails?.values?.statuteDetails?.FBICode === '36B' &&
              this.value > 18
            ) {
              return 'NIBRS-2019.2-130';
            }
          }
        }

        for (let victim of this.obj.victims) {
          const codeVictimCategoryType = getCategoryOfCode(
            codeVictimCategory,
            victim.incVictimDetails.values?.category
          );
          if (codeVictimCategoryType === 'l') {
            if (this.value < 17 || this.value > 98) {
              return 'NIBRS-2019.2-128';
            }
          }

          if (!['i', 'l'].includes(codeVictimCategoryType) && this.value) {
            return 'NIBRS-2019.2-129 Age';
          }

          if (['i', 'l'].includes(codeVictimCategoryType)) {
            if (this.obj.personForm.values?.isUnknownParty !== true) {
              if (!this.value || this.value === 0) {
                return 'NIBRS-2019.2-123(Age)';
              }
            }
          }
        }
      } else if (this.obj.offenders.length) {
        // NIBRS-2019.2-158: Property Drug Quantity
        for (let offender of this.obj.offenders) {
          if (offender?.incSubjectDetails?.values?.unknown) {
            if (this.value) {
              return 'NIBRS-2019.2-158 Age';
            }
          }
        }

        for (let offenderOffense of this.obj.offenderOffenses) {
          if (offenderOffense) {
            if (
              offenderOffense?.offenseDetails?.values?.statuteDetails?.FBICode === '09C' &&
              (!this.value || this.value === 0)
            ) {
              return 'NIBRS-2019.2-157(Age)';
            }
          }
        }

        //NIBRS-2019.2-126: Person Age
        if (this.obj.personForm.values?.ageRangeIncrement) {
          let value = parseInt(this.obj.personForm.values?.age);
          if (value === 0 || value < 0) {
            return 'NIBRS-2019.2-126';
          }
        }

        //NIBRS-2019.2-124: Person Age
        if (this.value && (this.value < 0 || this.value > 99)) {
          return 'NIBRS-2019.2-124';
        }
      }
    },
  },

  sex: {
    type: String,
    label: 'Person Sex',
    optional: true,
    custom() {
      const codeVictimCategory = store.store.getState().dictionary.codeVictimCategory;
      const codeSex = store.store.getState().dictionary.codeSex;
      if (this.obj.victims.length) {
        const sexCategory = getCategoryOfCode(codeSex, this.value);

        for (let victimOffense of this.obj.victimOffenses) {
          if (victimOffense) {
            if (
              victimOffense.offenseDetails.values.statuteDetails?.FBICode === '11A' ||
              victimOffense.offenseDetails.values.statuteDetails?.FBICode === '36B'
            ) {
              if (!sexCategory === 'm' || !sexCategory === 'f') {
                return 'NIBRS-2019.2-131';
              }
            }
          }
        }

        for (let victim of this.obj.victims) {
          const codeVictimCategoryType = getCategoryOfCode(
            codeVictimCategory,
            victim.incVictimDetails.values?.category
          );

          if (
            !['i', 'l', 's'].includes(codeVictimCategoryType) &&
            this.value &&
            this.value.length !== 0
          ) {
            return 'NIBRS-2019.2-129 Sex';
          }

          if (['i', 'l'].includes(codeVictimCategoryType)) {
            if (!this.value || this.value.length === 0) {
              return 'NIBRS-2019.2-123(Sex)';
            }
          }
        }
      }

      if (this.obj.offenders.length) {
        for (let offenderOffense of this.obj.offenderOffenses) {
          if (offenderOffense) {
            if (
              offenderOffense?.offenseDetails?.values?.statuteDetails?.FBICode === '09C' &&
              !this.value
            ) {
              return 'NIBRS-2019.2-157(Sex)';
            }
          }
        }

        for (let offender of this.obj.offenders) {
          if (offender?.incSubjectDetails?.values?.unknown) {
            if (this.value) {
              return 'NIBRS-2019.2-158 Sex';
            }
          }
        }
      }
    },
  },

  race: {
    type: String,
    label: 'Person Race',
    optional: true,
    custom() {
      const codeVictimCategory = store.store.getState().dictionary.codeVictimCategory;
      if (this.obj.victims.length) {
        for (let victim of this.obj.victims) {
          const codeVictimCategoryType = getCategoryOfCode(
            codeVictimCategory,
            victim.incVictimDetails.values?.category
          );

          if (
            !['i', 'l', 's'].includes(codeVictimCategoryType) &&
            this.value &&
            this.value.length !== 0
          ) {
            return 'NIBRS-2019.2-129 Race';
          }

          if (['i', 'l'].includes(codeVictimCategoryType)) {
            if (!this.value || this.value.length === 0) {
              return 'NIBRS-2019.2-123(Race)';
            }
          }
        }
      } else if (this.obj.offenders.length) {
        for (let offenderOffense of this.obj.offenderOffenses) {
          if (offenderOffense) {
            if (
              offenderOffense?.offenseDetails?.values?.statuteDetails?.FBICode === '09C' &&
              !this.value
            ) {
              return 'NIBRS-2019.2-157(Race)';
            }
          }
        }

        for (let offender of this.obj.offenders) {
          if (offender?.incSubjectDetails?.values?.unknown) {
            if (this.value) {
              return 'NIBRS-2019.2-158 Race';
            }
          }
        }
      }
    },
  },

  ethnicity: {
    type: String,
    label: 'Person Ethnicity',
    optional: true,
    custom() {
      const codeVictimCategory = store.store.getState().dictionary.codeVictimCategory;
      if (this.obj.victims.length) {
        for (let victim of this.obj.victims) {
          const codeVictimCategoryType = getCategoryOfCode(
            codeVictimCategory,
            victim.incVictimDetails.values?.category
          );

          if (
            !['i', 'l', 's'].includes(codeVictimCategoryType) &&
            this.value &&
            this.value.length !== 0
          ) {
            return 'NIBRS-2019.2-129 Ethnicity';
          }
        }
      }
    },
  },

  arresteeAge: {
    type: SimpleSchema.Integer,
    label: 'NIBRS-2019.2-176: Arrestee Age',
    optional: true,
    custom() {
      if (!this.value || this.value === 0) {
        return 'NIBRS-2019.2-176';
      }

      if (this.value && (this.value < 0 || this.value > 99)) {
        return 'NIBRS-2019.2-124';
      }
    },
  },

  arresteeRace: {
    type: String,
    label: 'NIBRS-2019.2-180: Arrestee Race',
    optional: true,
    custom() {
      if (!this.value) {
        return 'NIBRS-2019.2-180';
      }
    },
  },

  arresteeSex: {
    type: String,
    label: 'NIBRS-2019.2-178: Arrestee Sex | NIBRS-2019.2-179: Arrestee Sex',
    optional: true,
    custom() {
      const codeSex = store.store.getState().dictionary.codeSex;
      const sexCategory = getCategoryOfCode(codeSex, this.value);

      if (!this.value) {
        return 'NIBRS-2019.2-178';
      } else {
        if (sexCategory === 'u') {
          return 'NIBRS-2019.2-179';
        }
      }
    },
  },
});
