import React from 'react';

function Army67(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="67"
      height="67"
      viewBox="0 0 67 67"
      {...props}
      preserveAspectRatio="xMidYMid meet">
      <g transform="translate(0.000000,67.000000) scale(0.100000,-0.100000)" stroke="none">
        <path d="M225 632 c-72 -23 -165 -118 -188 -191 -50 -157 14 -311 160 -383 127 -62 267 -36 363 68 59 65 83 124 84 210 1 130 -80 248 -201 294 -65 24 -149 25 -218 2z m221 -60 c50 -26 109 -87 129 -134 18 -44 20 -135 3 -190 -16 -53 -78 -121 -135 -149 -165 -80 -369 47 -372 232 -2 109 76 222 175 253 62 20 148 15 200 -12z" />
        <path d="M313 444 l-20 -59 -61 -3 c-34 -2 -62 -6 -62 -11 0 -4 20 -22 45 -41 25 -19 45 -34 45 -35 0 -1 -9 -28 -20 -60 -11 -32 -18 -60 -16 -62 2 -3 26 12 54 31 27 20 53 36 57 36 4 0 29 -16 55 -35 26 -19 50 -35 53 -35 3 0 -3 25 -14 57 -10 31 -19 59 -19 63 0 4 20 23 45 41 24 19 45 37 45 41 0 4 -28 8 -62 10 l-63 3 -21 59 -21 60 -20 -60z" />
      </g>
    </svg>
  );
}

export default Army67;
