/** 
 * Universal reducer that notifies other components about changes in the data
 * Notification is automatically cleared
 *  e.g. added/edited address so address list should be updated
 */

const NOTIFY_DATA_UPDATE = 'NOTIFY_DATA_UPDATE';
let validTimeout = 0;

export const notifyDataUpdate = ({ type, data, valid = 1000 }) => {
  return async (dispatch) => {
    clearTimeout(validTimeout);
    dispatch({ type: NOTIFY_DATA_UPDATE, payload: { type, data } });
    validTimeout = setTimeout(() => {
      dispatch({ type: NOTIFY_DATA_UPDATE, payload: null });
    }, valid);
  };
};

export default function reducer(state = null, action) {
  switch (action.type) {
    case NOTIFY_DATA_UPDATE:
      return action.payload;
    default:
      break;
  }
  return state;
}
