import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Autocomplete } from '@material-ui/lab';
import { Box, FormControlLabel, Grid, TextField, Checkbox, Button } from '@material-ui/core';

import BackDatedRecordHistory from 'components/BackDatedRecordHistory';

import { getService } from 'reducers/service';
import { notifyDataUpdate } from 'reducers/DataUpdateReducer';
import { notify } from 'reducers/NotifierReducer';

const obj = {
  ZoneCode: '',
  Description: '',
  IsDefault: false,
  IsActive: false,
  IsDeleted: false,
  Flags: false,
  Category: '',
  Code2: '',
  Code3: '',
  Code4: '',
  Description2: '',
  Description3: '',
  Description4: '',
  AgencyID: '',
  Zone1: '',
  Zone2: '',
  Zone3: '',
  Zone4: '',
  Zone5: '',
};

export const formatCodeZones = (values) => ({
  ZoneCode: values.ZoneCode,
  Description: values.Description || values.Code,
  AgencyID: values.AgencyID,
  IsDefault: values.IsDefault,
  IsActive: values.IsActive,
  IsDeleted: false,
  Flags: values.Flags || null,
  Category: values.Category,
  Code2: values.Code2,
  Code3: values.Code3,
  Code4: values.Code4,
  Description2: values.Description2 || values.Code2,
  Description3: values.Description3 || values.Code3,
  Description4: values.Description4 || values.Code4,
  Zone1: values.Zone1,
  Zone2: values.Zone2,
  Zone3: values.Zone3,
  Zone4: values.Zone4,
  Zone5: values.Zone5,
});
const Zone = (props) => {
  const dispatch = useDispatch();
  const service = getService('codezones');
  const [values, setValues] = useState(obj);
  const [agencyOptions, setAgencyOptions] = useState([]);

  const [recordHistoryData, setRecordHistoryData] = useState({
    shouldVisible: true,
    isDisabled: true,
    createUpdateInfo: {},
  });
  const [showRecordHistory, setShowRecordHistory] = useState(false);

  useEffect(() => {
    const fetchAgencies = async () => {
      const service = getService('ptsagencies');
      const response = await service.find({ query: { IsDeleted: false } });
      const newArray = response?.data.map((el) => el?.AgencyID);
      setAgencyOptions(newArray);
    };
    fetchAgencies();
  }, []);

  const handleCloseRecordHistoryDialog = () => setShowRecordHistory(false);

  const handleChange = (key, value) => {
    setValues((prevState) => ({ ...prevState, [key]: value }));
  };

  const checkDuplication = async (query) => {
    const isAlreadyExist = await service.find({ query });
    if (isAlreadyExist.data.length) {
      dispatch(notify('Item already exist!', 'error'));
      return true;
    }
    return false;
  };

  const handleSave = async () => {
    const { ZoneCode } = values;
    const isItemExist = await checkDuplication({ ZoneCode, IsDeleted: false });
    if (isItemExist) return;
    await service.create(formatCodeZones(values));
    dispatch(notifyDataUpdate({ type: 'zone-created' }));
    props.close();
  };

  const codeZonesForm = () => (
    <>
      <Grid container item xs={12} style={{ marginTop: 10 }}>
        <Grid item xs={6} style={{ width: '100%' }}>
          <Box style={{ marginLeft: 10 }}>
            <TextField
              //fullWidth
              style={{ width: '100%' }}
              type={'text'}
              value={values.ZoneCode}
              //onChange={handleChange}
              onChange={(e) => handleChange('ZoneCode', e.target.value)}
              helperText={''}
              label={'Zone Code'}
              //disabled={mode === 'edit'}
              //error={error}
              variant={'outlined'}
            />
          </Box>
        </Grid>
        <Grid item xs={6} style={{ width: '100%' }}>
          <Box style={{ marginLeft: 10 }}>
            <Autocomplete
              options={agencyOptions}
              value={values.AgencyID}
              renderInput={(params) => <TextField {...params} label="Agency" variant="outlined" />}
              onChange={(event, selection) => handleChange('AgencyID', selection)}
              //disabled={mode === 'edit'}
            />
          </Box>
        </Grid>
      </Grid>
      <Grid container item xs={12} style={{ marginTop: 10 }}>
        <Grid item xs={6} style={{ width: '100%' }}>
          <Box style={{ marginLeft: 10 }}>
            <TextField
              //fullWidth
              style={{ width: '100%' }}
              type={'text'}
              value={values.Zone1}
              //onChange={handleChange}
              onChange={(e) => handleChange('Zone1', e.target.value)}
              helperText={''}
              label={'Zone1'}
              //disabled={mode === 'edit'}
              //error={error}
              variant={'outlined'}
            />
          </Box>
        </Grid>
        <Grid item xs={6} style={{ width: '100%' }}>
          <Box style={{ marginLeft: 10 }}>
            <TextField
              //fullWidth
              style={{ width: '100%' }}
              type={'text'}
              value={values.Zone2}
              //onChange={handleChange}
              onChange={(e) => handleChange('Zone2', e.target.value)}
              helperText={''}
              label={'Zone2'}
              //disabled={mode === 'edit'}
              //error={error}
              variant={'outlined'}
            />
          </Box>
        </Grid>
      </Grid>
      <Grid container item xs={12} style={{ marginTop: 10 }}>
        <Grid item xs={6} style={{ width: '100%' }}>
          <Box style={{ marginLeft: 10 }}>
            <TextField
              //fullWidth
              style={{ width: '100%' }}
              type={'text'}
              value={values.Zone3}
              //onChange={handleChange}
              onChange={(e) => handleChange('Zone3', e.target.value)}
              helperText={''}
              label={'Zone3'}
              //disabled={mode === 'edit'}
              //error={error}
              variant={'outlined'}
            />
          </Box>
        </Grid>
        <Grid item xs={6} style={{ width: '100%' }}>
          <Box style={{ marginLeft: 10 }}>
            <TextField
              //fullWidth
              style={{ width: '100%' }}
              type={'text'}
              value={values.Zone4}
              //onChange={handleChange}
              onChange={(e) => handleChange('Zone4', e.target.value)}
              helperText={''}
              label={'Zone4'}
              //disabled={mode === 'edit'}
              //error={error}
              variant={'outlined'}
            />
          </Box>
        </Grid>
      </Grid>
      <Grid container item xs={12} style={{ marginTop: 10 }}>
        <Grid item xs={6} style={{ width: '100%' }}>
          <Box style={{ marginLeft: 10 }}>
            <TextField
              //fullWidth
              style={{ width: '100%' }}
              type={'text'}
              value={values.Zone5}
              //onChange={handleChange}
              onChange={(e) => handleChange('Zone5', e.target.value)}
              helperText={''}
              label={'Zone5'}
              //disabled={mode === 'edit'}
              //error={error}
              variant={'outlined'}
            />
          </Box>
        </Grid>
        <Grid item xs={6} style={{ width: '100%' }}>
          <Box style={{ marginLeft: 10 }}>
            <TextField
              //fullWidth
              style={{ width: '100%' }}
              type={'text'}
              value={values.Description}
              //onChange={handleChange}
              onChange={(e) => handleChange('Description', e.target.value)}
              helperText={''}
              label={'Description'}
              //error={error}
              variant={'outlined'}
            />
          </Box>
        </Grid>
      </Grid>

      <Grid container item xs={12} style={{ marginTop: 10 }}>
        <Grid item xs={6} style={{ width: '100%' }}>
          <Box style={{ marginLeft: 10 }}>
            <TextField
              //fullWidth
              style={{ width: '100%' }}
              type={'text'}
              value={values.Code2}
              label={'Code 2/Description 2'}
              //error={error}
              variant={'outlined'}
              onChange={(e) => handleChange('Code2', e.target.value)}
            />
          </Box>
        </Grid>
        <Grid item xs={6} style={{ width: '100%' }}>
          <Box style={{ marginLeft: 10 }}>
            <TextField
              //fullWidth
              style={{ width: '100%' }}
              type={'text'}
              value={values.Description2}
              //onChange={handleChange}
              onChange={(e) => handleChange('Description2', e.target.value)}
              helperText={''}
              label={'Description 2'}
              //error={error}
              variant={'outlined'}
            />
          </Box>
        </Grid>
      </Grid>
      <Grid container item xs={12} style={{ marginTop: 10 }}>
        <Grid item xs={6} style={{ width: '100%' }}>
          <Box style={{ marginLeft: 10 }}>
            <TextField
              //fullWidth
              style={{ width: '100%' }}
              type={'text'}
              value={values.Code3}
              label={'Code 3/ Description 3'}
              //error={error}
              variant={'outlined'}
              onChange={(e) => handleChange('Code3', e.target.value)}
            />
          </Box>
        </Grid>
        <Grid item xs={6} style={{ width: '100%' }}>
          <Box style={{ marginLeft: 10 }}>
            <TextField
              //fullWidth
              style={{ width: '100%' }}
              type={'text'}
              value={values.Description3}
              //onChange={handleChange}
              onChange={(e) => handleChange('Description3', e.target.value)}
              helperText={''}
              label={'Description 3'}
              //error={error}
              variant={'outlined'}
            />
          </Box>
        </Grid>
      </Grid>
      <Grid container item xs={12} style={{ marginTop: 10 }}>
        <Grid item xs={6} style={{ width: '100%' }}>
          <Box style={{ marginLeft: 10 }}>
            <TextField
              //fullWidth
              style={{ width: '100%' }}
              type={'text'}
              value={values.Code4}
              label={'Code 4/ Description 4'}
              //error={error}
              variant={'outlined'}
              onChange={(e) => handleChange('Code4', e.target.value)}
            />
          </Box>
        </Grid>
        <Grid item xs={6} style={{ width: '100%' }}>
          <Box style={{ marginLeft: 10 }}>
            <TextField
              //fullWidth
              style={{ width: '100%' }}
              type={'text'}
              value={values.Description4}
              //onChange={handleChange}
              onChange={(e) => handleChange('Description4', e.target.value)}
              helperText={''}
              label={'Description 4'}
              //error={error}
              variant={'outlined'}
            />
          </Box>
        </Grid>
      </Grid>
      <Grid container item xs={12} style={{ marginTop: 10 }}>
        <Grid item xs={6} style={{ width: '100%' }}>
          <Box style={{ marginLeft: 10 }}>
            <TextField
              //fullWidth
              style={{ width: '100%' }}
              type={'text'}
              value={values.Category}
              label={'Category'}
              //error={error}
              variant={'outlined'}
              onChange={(e) => handleChange('Category', e.target.value)}
            />
          </Box>
        </Grid>

        <Grid item xs={2}>
          <Box style={{ marginLeft: 10 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.IsDefault}
                  onChange={(e) => handleChange('IsDefault', e.target.checked)}
                />
              }
              label="Default"
            />
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box style={{ marginLeft: 10 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.IsActive}
                  onChange={(e) => handleChange('IsActive', e.target.checked)}
                />
              }
              label="Active"
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
  const renderActions = () => {
    return (
      <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
        {recordHistoryData?.shouldVisible && (
          <Box className="mr-2">
            <BackDatedRecordHistory
              showRecordHistory={showRecordHistory}
              setShowRecordHistory={setShowRecordHistory}
              disabled={recordHistoryData?.isDisabled}
              handleCloseRecordHistoryDialog={handleCloseRecordHistoryDialog}
              createUpdateInfo={recordHistoryData?.createUpdateInfo}></BackDatedRecordHistory>
          </Box>
        )}
        <Button
          color="primary"
          variant="contained"
          onClick={handleSave}
          disabled={!values.ZoneCode || !values.AgencyID}>
          Save
        </Button>
      </div>
    );
  };
  return (
    <>
      {codeZonesForm()}
      {renderActions()}
    </>
  );
};

export default Zone;
