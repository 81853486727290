import MaskedInput from 'react-text-mask';
import emailMask from 'text-mask-addons/dist/emailMask';
import React from 'react';

export default function EmailTextMask(props) {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={emailMask}
      placeholderChar={' '}
      showMask
      guide={false}
    />
  );
}
