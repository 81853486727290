import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';

import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import Dialog from 'components/RMSDialog';
import RMSCheckbox from 'components/RMSCheckbox';
import RMSDictionary from 'components/RMSAutoComplete/RMSDictonary';
import BackDatedRecordHistory from 'components/BackDatedRecordHistory';

import { gridStyle, Row, RowInner, ColCard, Fills, formatSaveData } from 'utils/formStyles';

import { closePersonPhotoEdit } from 'reducers/PersonDialogsReducer';
import { editPersonImage } from 'reducers/SearchReducer';
import { notifyDataUpdate } from 'reducers/DataUpdateReducer';
import { notify } from 'reducers/NotifierReducer';
import { handleError } from 'reducers/ErrorReducer';
import { uploadPersonMarks } from 'reducers/helpers/fileHelpers';
// import Defa
const useStyles = makeStyles((theme) => ({
  item: gridStyle(200, 800),
}));

function EditPersonPhotoDialog(props) {
  const classes = useStyles();
  const { data, IsMarkType, rows } = props;
  const [ImageTypeCode, setImageTypeCode] = useState(null);
  const [MarkType, setMarkType] = useState(null);
  const [Location, setLocation] = useState(null);
  const [Gang, setGang] = useState(null);
  const [IsMark, setIsMark] = useState(false);
  const [Description, setDescription] = useState('');
  const [IsDefault, setIsDefault] = useState(false);

  const [recordHistoryData, setRecordHistoryData] = useState({
    shouldVisible: true,
    isDisabled: true,
    createUpdateInfo: {},
  });
  const [showRecordHistory, setShowRecordHistory] = useState(false);

  const codeMugshotTypes = useSelector((state) => state.dictionary.codeMugshotTypes);

  useEffect(() => {
    if (IsMarkType && !data.ptsImageID) {
      renderInitailDataForMark();
    } else {
      renderExistingValues();
    }
  }, [IsMarkType]);

  const renderExistingValues = () => {
    const { ImageTypeCode, MarkType, Location, Gang, IsMark, Description, IsDefault } = data;

    setRecordHistoryData({
      shouldVisible: true,
      isDisabled: !data.Created,
      createUpdateInfo: {
        created: data.Created,
        createdBy: data.CreatedBy,
        updated: data.Updated,
        updatedBy: data.UpdatedBy,
      },
    });

    setIsMark(IsMark);
    setDescription(Description);
    setImageTypeCode(ImageTypeCode);
    setMarkType(MarkType);
    setLocation(Location);
    setGang(Gang);
    setIsDefault(IsDefault);
  };

  const renderInitailDataForMark = () => {
    const defaultMugshotType = codeMugshotTypes.find(
      (item) => item.Category.toLowerCase() === 'marks'
    );

    setImageTypeCode(defaultMugshotType || null);
    setIsMark(true);
    setIsDefault(false);
  };

  const handleCloseRecordHistoryDialog = () => setShowRecordHistory(false);

  const close = () => {
    props.closePersonPhotoEdit();
  };

  const save = async () => {
    const saveObj = formatSaveData({
      ImageTypeCode,
      IsMark,
      Location,
      Gang,
      Description,
      MarkType,
      IsDefault,
    });

    try {
      if (IsMarkType && !data.ptsImageID) {
        saveObj.ptsPersonID = data.ptsPersonID;
        await uploadPersonMarks(saveObj);
        props.notifyDataUpdate({ type: 'person-image-upload', data: {} });
        props.notify('Successfully Created', 'success');
      } else {
        saveObj.ptsPersonID = data.ptsPersonID;
        await editPersonImage(data.ptsImageID, saveObj);
        props.notifyDataUpdate({ type: 'person-image-upload', data: {} });
        props.notify('Info updated', 'success');
      }
    } catch (err) {
      props.handleError(err);
    }
    close();
  };

  const handleDefaultValue = (value) => {
    const hasDefault = rows.find((row) => row.IsDefault);
    if (value && hasDefault)
      if (
        !window.confirm(
          'This record already has a default photo. Would you like to make this photo the default instead?'
        )
      )
        return;

    setIsDefault(!IsDefault);
  };

  const renderActions = () => {
    return (
      <>
        {recordHistoryData?.shouldVisible && (
          <Box className="">
            <BackDatedRecordHistory
              showRecordHistory={showRecordHistory}
              setShowRecordHistory={setShowRecordHistory}
              disabled={recordHistoryData?.isDisabled}
              handleCloseRecordHistoryDialog={handleCloseRecordHistoryDialog}
              createUpdateInfo={recordHistoryData?.createUpdateInfo}></BackDatedRecordHistory>
          </Box>
        )}
        <Button color="primary" variant="contained" autoFocus onClick={save}>
          <SaveIcon /> Save
        </Button>
        <Button color="primary" onClick={close}>
          <CloseIcon /> Close
        </Button>
      </>
    );
  };

  return (
    <Dialog toolbar onClose={close} title={IsMarkType ? 'Add' : 'Edit'} actions={renderActions()}>
      <div>
        <RMSDictionary
          options="codeMugshotTypes"
          className={classes.item}
          onChange={(ev, val) => setImageTypeCode(val)}
          value={ImageTypeCode}
          label="Image Type"
          IsMark={IsMarkType ? true : false}
          compact
        />
        {!IsMarkType && (
          <>
            <RMSCheckbox
              selectedCheckbox={IsMark}
              handleFormChange={(e) => {
                setIsMark(e.target.checked);
                if (e.target.checked) {
                  setIsDefault(false);
                }
              }}
              label="Scar, Mark, or Tattoo"
            />
            <RMSCheckbox
              selectedCheckbox={IsDefault}
              handleFormChange={(e) => handleDefaultValue(e.target.checked)}
              label="Is Default"
              disabled={IsMark}
            />
          </>
        )}

        {IsMark && (
          <Row>
            <ColCard minWidth={500}>
              <RowInner>
                <RMSDictionary
                  options="codeMarks"
                  className={classes.item}
                  onChange={(ev, val) => setMarkType(val)}
                  value={MarkType}
                  label="Mark Type"
                  compact
                />
                <RMSDictionary
                  options="codeMarkLocation"
                  className={classes.item}
                  onChange={(ev, val) => setLocation(val)}
                  value={Location}
                  label="Location"
                  compact
                />
              </RowInner>
              <RowInner>
                <RMSDictionary
                  options="codeGang"
                  className={classes.item}
                  onChange={(ev, val) => setGang(val)}
                  value={Gang}
                  label="Gang"
                  compact
                />
              </RowInner>
              <RowInner>
                <TextField
                  label="Description"
                  variant="outlined"
                  size="small"
                  value={Description}
                  onChange={(e) => setDescription(e.target.value)}
                  className={classes.item}
                />
              </RowInner>
            </ColCard>
          </Row>
        )}
      </div>
    </Dialog>
  );
}

// export default connect(null, { closePersonPhotoEdit })(EditPersonPhotoDialog);
export default connect(null, {
  closePersonPhotoEdit,
  notify,
  handleError,
  notifyDataUpdate,
})(EditPersonPhotoDialog);
