import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import DateFnsUtils from '@date-io/date-fns';

import { Button, makeStyles, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from '@material-ui/pickers';

import Dialog from 'components/RMSDialog';
import PersonLookup2 from 'components/RMSPersonLookup/PersonLookup2';
import VehicleLookup from 'components/RMSVehicleLookup';
import Dictionary from 'components/RMSAutoComplete/RMSDictonary';
import AddressLookup from 'components/RMSAddressLookup';

import { getFormStyle, getRowStyle, get100prStyle } from 'utils/functions';

import { closeEditBulletin } from 'reducers/PersonDialogsReducer';
import { editAlert, getBulletin } from 'reducers/BulletinReducer';
import { showSpinner, hideSpinner } from 'reducers/UiReducer';
import { handleError } from 'reducers/ErrorReducer';
import {
  formatSaveDate,
  getFormat24,
  getDateTimeFormat,
  getCurrentDate,
  dateTimePicker,
} from 'reducers/TimeReducer';
import { notifyDataUpdate } from 'reducers/DataUpdateReducer';
import { getFullPermissions } from 'reducers/PermissionsReducer';
import { getTitleForDialog } from 'global-components/ReadOnlyText';

const useStyles = makeStyles((theme) => ({
  autocomplete: {
    margin: '0 4px 8px',
  },
  option: {
    fontSize: 14,
  },
  row: getRowStyle(),
  w90: getFormStyle(90, 90),
  w100x200: getFormStyle(100, 200),
  w170x400: getFormStyle(170, 400),
  agency: getFormStyle(300, 600),
  date: getFormStyle(200, 300),
  w100pr: get100prStyle(),
  notes: {
    fontSize: 14,
  },
}));

const priorities = ['1', '2', '3', '4', '5'];

function EditBulletin(props) {
  const { ptsAlertID } = props;

  const classes = useStyles();
  const format24 = getFormat24();

  const [Beginning, setBeginning] = useState(getCurrentDate());
  const [Ending, setEnding] = useState(null);
  const [AlertType, setAlertType] = useState(null);
  const [Agency, setAgency] = useState(null);
  const [rangeOfEffect, setRangeOfEffect] = useState('');
  const [person, setPerson] = useState(null);
  const [personId, setPersonId] = useState(null);
  const [address, setAddress] = useState(null);
  const [Priority, setPriority] = useState('1');
  const [vehicle, setVehicle] = useState(null);
  const [vehicleId, setVehicleId] = useState(null);
  const [ptsAddressID, setPtsAddressID] = useState(null);
  const [Plate, setPlate] = useState('');
  const [PlateState, setPlateState] = useState(null);
  const [OLN, setOLN] = useState('');
  const [OLNState, setOLNState] = useState(null);
  const [Notification, setNotification] = useState('');
  const bulletinePermission = getFullPermissions('cad', 'Alerts', 'any');

  useEffect(async () => {
    props.showSpinner();
    try {
      await getBulletin(ptsAlertID).then((result) => {
        if (!result) return;
        const { AlertType, AgencyID, PlateState, OLNState } = result;
        setAlertType(AlertType ? { Code: result.AlertType } : null);
        setAgency(AgencyID ? { AgencyID: result.AgencyID } : null);
        setPriority(result.Priority);
        setBeginning(dateTimePicker(result.Beginning));
        setEnding(dateTimePicker(result.Ending));
        setRangeOfEffect(result.RangeOfEffectiveness);
        setPlate(result.Plate);
        setPlateState(PlateState ? { Code: result.PlateState } : null);
        setOLN(result.OLN);
        setOLNState(OLNState ? { Code: result.OLNState } : null);
        setNotification(result.Notification);
        setPersonId(result.ptsPersonID);
        setPerson({ ptsPersonID: result.ptsPersonID });
        setVehicleId(result.ptsVehicleID);
        setVehicle({ ptsVehicleID: result.ptsVehicleID });
        setPtsAddressID(result.ptsAddressID);
      });
    } catch (err) {
      props.handleError(err, 'Error getting bulletin.');
    }
    props.hideSpinner();
  }, [ptsAlertID]);

  const close = () => {
    props.closeEditBulletin();
  };

  const save = async () => {
    const data = {
      AlertType: AlertType ? AlertType.Code : null,
      Flags: AlertType ? AlertType.Flags : null,
      Beginning: formatSaveDate(Beginning),
      Ending: formatSaveDate(Ending),
      Notification,
      ptsPersonID: person ? person.ptsPersonID : null,
      ptsAddressID: address ? address.ptsAddressID : null,
      ptsVehicleID: vehicle ? vehicle.ptsVehicleID : null,
      Plate,
      PlateState: PlateState ? PlateState.Code : null,
      OLN,
      OLNState: OLNState ? OLNState.Code : null,
      AgencyID: Agency ? Agency.AgencyID : null,
      Priority: Priority,
      RangeOfEffectiveness: rangeOfEffect,
    };
    props.showSpinner();

    try {
      await editAlert(ptsAlertID, data);
      props.notifyDataUpdate({ type: 'Bulletin', data: ptsAlertID });
      props.closeEditBulletin();
    } catch (err) {
      props.handleError(err, 'Error saving bulletin.');
    }
    props.hideSpinner();
  };

  const onVehicleSelect = (vehicle) => {
    setVehicle(vehicle);
    if (vehicle) {
      vehicle.PlateNumber && setPlate(vehicle.PlateNumber);
      vehicle.PlateState && setPlateState({ Code: vehicle.PlateState });
    }
  };

  const renderAlertTypes = () => {
    const onChange = (ev, val) => setAlertType(val);
    return (
      <Dictionary
        options="AlertTypes"
        className={classes.w100pr}
        onChange={onChange}
        value={AlertType}
        label="Bulletin type"
        compact="true"
        editingForm={true}
      />
    );
  };

  const renderAgencies = () => {
    const onChange = (ev, val) => setAgency(val);
    return (
      <Dictionary
        options="Agencies"
        className={classes.agency}
        onChange={onChange}
        value={Agency}
        label="Agency"
        compact="true"
        editingForm={true}
      />
    );
  };

  const renderPriority = () => {
    return (
      <Autocomplete
        options={priorities}
        classes={{
          option: classes.option,
          root: classes.autocomplete,
        }}
        className={classes.w90}
        autoHighlight
        onChange={(ev, newValue) => setPriority(newValue)}
        value={Priority}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Priority"
            variant="outlined"
            size="small"
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password',
            }}
          />
        )}
      />
    );
  };

  const renderDates = () => {
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDateTimePicker
          ampm={!format24}
          disableToolbar
          variant="inline"
          inputVariant="outlined"
          format={getDateTimeFormat()}
          margin="normal"
          label="Date From"
          value={Beginning}
          onChange={setBeginning}
          maxDate={Ending}
          autoOk
          size="small"
          className={classes.date}
        />
        <KeyboardDateTimePicker
          ampm={!format24}
          disableToolbar
          variant="inline"
          inputVariant="outlined"
          margin="normal"
          label="Date To"
          value={Ending}
          onChange={setEnding}
          minDate={Beginning}
          autoOk
          size="small"
          className={classes.date}
          format={getDateTimeFormat()}
        />
      </MuiPickersUtilsProvider>
    );
  };

  const renderRangeOfEffect = () => {
    const onChange = (ev) => setRangeOfEffect(ev.target.value.substr(0, 50));
    return (
      <TextField
        className={classes.w100pr}
        label="Range of Effectiveness"
        value={rangeOfEffect}
        onChange={onChange}
        variant="outlined"
        size="small"
      />
    );
  };

  const renderPlate = () => {
    const onChange = (ev) => setPlate(ev.target.value.substr(0, 25));
    return (
      <TextField
        className={classes.w170x400}
        label="Plate"
        value={Plate}
        onChange={onChange}
        variant="outlined"
        size="small"
      />
    );
  };

  const renderOLN = () => {
    const onChange = (ev) => setOLN(ev.target.value.substr(0, 50));
    return (
      <TextField
        className={classes.w170x400}
        label="OLN"
        value={OLN}
        onChange={onChange}
        variant="outlined"
        size="small"
      />
    );
  };

  const renderActions = () => {
    return (
      <>
        <Button
          color="primary"
          disabled={!bulletinePermission.Edit}
          variant="contained"
          autoFocus
          onClick={save}>
          <SaveIcon /> Save
        </Button>
        <Button color="primary" onClick={close}>
          <CloseIcon /> Close
        </Button>
      </>
    );
  };

  const renderPlateState = () => {
    const onChange = (ev, val) => setPlateState(val);
    return (
      <Dictionary
        options="codeStates"
        className={classes.w100x200}
        onChange={onChange}
        value={PlateState}
        label="Plate State"
        compact="true"
        editingForm={true}
      />
    );
  };

  const renderOLNState = () => {
    const onChange = (ev, val) => setOLNState(val);
    return (
      <Dictionary
        options="codeStates"
        className={classes.w100x200}
        onChange={onChange}
        value={OLNState}
        label="OLN State"
        compact="true"
        editingForm={true}
      />
    );
  };

  const renderNotes = () => {
    return (
      <TextField
        label="Notes"
        multiline
        rows={4}
        variant="outlined"
        className={classes.w100pr}
        value={Notification}
        onChange={(ev) => setNotification(ev.target.value)}
        inputProps={{ className: classes.notes }}
      />
    );
  };

  const renderPerson = () => {
    const handlePersonChange = (value) => setPerson(value);
    return (
      <PersonLookup2
        className={classes.w100pr}
        onPersonChange={handlePersonChange}
        label="Person"
        ptsPersonID={personId}
        compact="true"
      />
    );
  };

  const renderAddress = () => {
    return (
      <AddressLookup
        className={classes.w100pr}
        onPlaceValueSet={setAddress}
        onAddressValueSet={setAddress}
        defaultLocation={null}
        onReset={() => setAddress(null)}
        ptsAddressID={ptsAddressID}
        compact="true"
        label="Search Address"
      />
    );
  };

  const renderVehicle = () => {
    return (
      <VehicleLookup
        className={classes.w100pr}
        onChange={onVehicleSelect}
        label="Search Vehicle"
        ptsVehicleID={vehicleId}
      />
    );
  };

  return (
    <Dialog
      toolbar
      onClose={close}
      title={getTitleForDialog(bulletinePermission, 'Edit Bulletin')}
      actions={renderActions()}>
      <div className={classes.row}>{renderAlertTypes()}</div>
      <div className={classes.row}>
        {renderAgencies()}
        {renderPriority()}
      </div>
      <div className={classes.row}>{renderDates()}</div>
      <div className={classes.row}>{renderRangeOfEffect()}</div>
      <div className={classes.row}>{renderPerson()}</div>
      <div className={classes.row}>{renderAddress()}</div>
      <div className={classes.row}>{renderVehicle()}</div>
      <div className={classes.row}>
        {renderPlate()}
        {renderPlateState()}
      </div>
      <div className={classes.row}>
        {renderOLN()}
        {renderOLNState()}
      </div>
      <div className={classes.row}>{renderNotes()}</div>
    </Dialog>
  );
}

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, {
  closeEditBulletin,
  showSpinner,
  hideSpinner,
  handleError,
  notifyDataUpdate,
})(EditBulletin);
