import React from 'react';

import { Fab, Tooltip } from '@material-ui/core';

export default function RMSRoundedButton(props) {
  const { tooltipText, onClick, isBtnDisabled } = props;

  return (
    <Tooltip title={tooltipText}>
      <Fab
        onClick={onClick}
        disabled={isBtnDisabled}
        //style={{ marginLeft: '10px' }}
        size="small"
        color="secondary"
        className={props.className ? props.className : ''}
        aria-label={tooltipText}>
        {props.children}
      </Fab>
    </Tooltip>
  );
}
