import store from '../../config/configureStore';

import { incSubjectVictimRelationshipSchema } from 'simpl-schema-validation/schema';
import { generateHandleEditOffense } from 'simpl-schema-validation/helpers/generateHandleEdit';
import { getDataOfDictionaryOptions } from 'utils/functions';

export const validateAllIncSubVicRelations = () => {
  const currentState = store.store.getState().incident;
  const {
    offenses,
    offensesOffenseForm,
    turnOfValidation,
    incVictims,
    incRelationships,
    incSubjects,
    incRelations,
  } = currentState;
  if (turnOfValidation) return [];

  let validationErrors = [];
  let victimIds = [];
  let subjectIds = [];

  incSubjects.map((s) => {
    if (!subjectIds.includes(s?.incSubjectDetails?.ptsIncPersonId))
      subjectIds.push(s?.incSubjectDetails?.ptsIncPersonId);
  });

  incVictims.map((v) => {
    let vicCategory = getDataOfDictionaryOptions(
      'codeVictimCategory',
      'Code',
      'Category',
      v?.incVictimDetails?.values?.category
    );
    if (
      !victimIds.includes(v?.incVictimDetails?.ptsIncPersonId) &&
      (vicCategory === 'I' || vicCategory === 'L')
    )
      victimIds.push(v?.incVictimDetails?.ptsIncPersonId);
  });

  let victimType = incVictims.find(
    (v) =>
      getDataOfDictionaryOptions(
        'codeVictimCategory',
        'Code',
        'Category',
        v?.incVictimDetails?.values?.category
      ) === 'I'
  );

  offenses.forEach((offense) => {
    if (
      offense.offenseDetails.values.excludeOffense ||
      offense.offenseDetails.values?.statuteDetails?.FBICode === '0'
    )
      return;

    let objToValidate = {
      allRelations: incRelationships
        ? incRelationships.map((relation) => relation.incRelationshipDetails.values.relationship)
        : incRelations
        ? incRelations.map((relation) => relation.Relationship)
        : [],
      victimIds: victimIds ?? [],
      subjectIds: subjectIds ?? [],
      offensesOffenseForm: offense.offenseDetails,
      victimType,
    };

    let allIncSubVicRelationshipsContext = incSubjectVictimRelationshipSchema.newContext();
    let result = allIncSubVicRelationshipsContext.validate(objToValidate, {
      keys: ['allRelations'],
    });

    if (!result) {
      allIncSubVicRelationshipsContext.validationErrors().forEach((error) => {
        if (offense.offenseDetails.ptsOffenseId) {
          validationErrors.push({
            id: offense.offenseDetails.ptsOffenseId,
            idType: 'ptsOffenseId',
            ptsOffenseId: offense.offenseDetails.ptsOffenseId,
            ptsIncidentId: currentState.ptsIncidentId,
            errType: error.type,
            field: error.name,
            stepper: 3,
            screen: 'Offense Relationship',
            name:
              offense.offenseDetails.values.statuteDetails?.Description +
              ' | ' +
              offense.offenseDetails.values.statuteDetails?.Code,
            message: allIncSubVicRelationshipsContext.keyErrorMessage(error.name),
            identifier: `${currentState.ptsIncidentId}-${error.type.split('-').pop()}-${
              error.name
            }-OffenseRelationship-${offense.offenseDetails.ptsOffenseId}`,
            handleEdit: generateHandleEditOffense(
              currentState.ptsIncidentId,
              offense.offenseDetails.ptsOffenseId,
              3
            ),
          });
        }
      });
    }
  });

  return validationErrors;
};
