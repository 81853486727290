/* eslint-disable array-callback-return */
/**
 * Helper function for setting nibrs errors for a particular incident
 * @param {int} id
 * @param {object} currentIncident
 * @param {array} incErrors
 * @param {generateHandleEditOffense} setStateFunc
 * @param {generateHandleEditIncident} setStateFunc
 * @param {function} dispatch
 */

export const setNibrsErrorsForIncident = (
  id,
  currentIncident,
  incErrors,
  generateHandleEditOffense,
  generateHandleEditIncident,
  setValidationErrors,
  setNibrsErrors,
  dispatch
) => {
  let errorsList = [];
  let offenseErrors = [];
  let propertiesErrors = [];
  let partiesErrors = [];
  let arresteesErrors = [];
  let incPropertiesErrors = [];
  let incRelationshipErrors = [];
  let incVictimErrors = [];
  let incIncidentErrors = [];

  if (incErrors) {
    incErrors.map((e) => {
      if (e.View === 'Offense') {
        offenseErrors.push({
          id: e.ptsOffenseID,
          ptsIncidentId: e.ptsIncidentID,
          errType: e.NIBRSCode,
          field: e.InputField,
          name: e.Title,
          message: e.Message,
          ptsOffenseId: e.ptsOffenseID,
          ptsIBRSId: e.ptsIBRSID,
          identifier: e.ErrorIdentifier,
          handleEdit: generateHandleEditOffense(e.ptsIncidentID, e.ptsOffenseID, e.Stepper),
        });
      } else if (e.View === 'Incident Offense') {
        offenseErrors.push({
          id: e.ptsOffenseID,
          ptsIncidentId: e.ptsIncidentID,
          errType: e.NIBRSCode,
          field: e.InputField,
          name: e.Title,
          message: e.Message,
          ptsOffenseId: e.ptsOffenseID,
          ptsIBRSId: e.ptsIBRSID,
          identifier: e.ErrorIdentifier,
          handleEdit: generateHandleEditIncident(e.ptsIncidentID, e.Stepper),
        });
      } else if (e.View === 'Property') {
        propertiesErrors.push({
          id: e.incIncidentPropertyID,
          ptsIncidentId: e.ptsIncidentID,
          errType: e.NIBRSCode,
          field: e.InputField,
          name: e.Title,
          message: e.Message,
          incIncidentPropertyId: e.incIncidentPropertyID,
          propertyType: e.PropertyType,
          ptsIBRSId: e.ptsIBRSID,
          identifier: e.ErrorIdentifier,
        });
      } else if (e.View === 'Party') {
        partiesErrors.push({
          id: e.ptsIncPersonID,
          ptsIncidentId: e.ptsIncidentID,
          errType: e.NIBRSCode,
          field: e.InputField,
          name: e.Title,
          message: e.Message,
          ptsIncPersonId: e.ptsIncPersonID,
          partyType: e.PartyType,
          ptsIBRSId: e.ptsIBRSID,
          identifier: e.ErrorIdentifier,
        });
      } else if (e.View === 'Offense Arrestee') {
        arresteesErrors.push({
          id: e.ptsIncPersonID,
          ptsIncidentId: e.ptsIncidentID,
          errType: e.NIBRSCode,
          field: e.InputField,
          name: e.Title,
          message: e.Message,
          ptsIncPersonId: e.ptsIncPersonID,
          ptsOffenseId: e.ptsOffenseID,
          errorStepper: e.Stepper,
          ptsIBRSId: e.ptsIBRSID,
          identifier: e.ErrorIdentifier,
          handleEdit: generateHandleEditOffense(e.ptsIncidentID, e.ptsOffenseID, e.Stepper),
        });
      } else if (e.View === 'Offense Property') {
        incPropertiesErrors.push({
          id: e.incIncidentPropertyId,
          ptsIncidentId: e.ptsIncidentID,
          errType: e.NIBRSCode,
          field: e.InputField,
          name: e.Title,
          message: e.Message,
          incIncidentPropertyId: e.incIncidentPropertyID,
          ptsOffenseId: e.ptsOffenseID,
          errorStepper: e.Stepper,
          ptsIBRSId: e.ptsIBRSID,
          identifier: e.ErrorIdentifier,
          handleEdit: generateHandleEditOffense(e.ptsIncidentID, e.ptsOffenseID, e.Stepper),
        });
      } else if (e.View === 'Offense Victim') {
        incVictimErrors.push({
          id: e.ptsIncPersonID,
          ptsIncidentId: e.ptsIncidentID,
          ptsIncPersonId: e.ptsIncPersonID,
          errType: e.NIBRSCode,
          field: e.InputField,
          name: e.Title,
          message: e.Message,
          ptsOffenseId: e.ptsOffenseID,
          errorStepper: e.Stepper,
          ptsIBRSId: e.ptsIBRSID,
          identifier: e.ErrorIdentifier,
          handleEdit: generateHandleEditOffense(e.ptsIncidentID, e.ptsOffenseID, e.Stepper),
        });
      } else if (e.View === 'Offense Relationship') {
        incRelationshipErrors.push({
          victimId: e.ptsVictimID,
          offenderId: e.ptsSubjectID,
          ptsIncidentId: e.ptsIncidentID,
          errType: e.NIBRSCode,
          field: e.InputField,
          name: e.Title,
          message: e.Message,
          ptsOffenseId: e.ptsOffenseID,
          errorStepper: e.Stepper,
          ptsIBRSId: e.ptsIBRSID,
          identifier: e.ErrorIdentifier,
          handleEdit: generateHandleEditOffense(e.ptsIncidentID, e.ptsOffenseID, e.Stepper),
        });
      } else if (e.View === 'Incident Property') {
        propertiesErrors.push({
          ptsIncidentId: e.ptsIncidentID,
          errType: e.NIBRSCode,
          field: e.InputField,
          name: e.Title,
          message: e.Message,
          propertyType: e.PropertyType,
          ptsIBRSId: e.ptsIBRSID,
          identifier: e.ErrorIdentifier,
          handleEdit: generateHandleEditIncident(e.ptsIncidentID, e.Stepper),
        });
      } else if (e.View === 'Incident') {
        incIncidentErrors.push({
          ptsIncidentId: e.ptsIncidentID,
          errType: e.NIBRSCode,
          field: e.InputField,
          name: e.Title,
          message: e.Message,
          errorStepper: e.Stepper,
          propertyType: e.PropertyType,
          ptsIBRSId: e.ptsIBRSID,
          identifier: e.ErrorIdentifier,
          handleEdit: generateHandleEditIncident(e.ptsIncidentID, e.Stepper),
        });
      }
    });
  }

  errorsList.push({
    ptsIncidentId: id,
    errors: {
      offenseErrors,
      partiesErrors,
      propertiesErrors,
      incPropertiesErrors,
      incRelationshipErrors,
      arresteesErrors,
      incVictimErrors,
      incIncidentErrors,
    },
  });

  // console.log('Errors List: ', errorsList);

  dispatch(setValidationErrors(errorsList));
  dispatch(setNibrsErrors(incErrors));
};

/**
 * Helper function for grouping incidents by key
 * @param {array} list
 * @param {string} keyGetter
 */
export const groupIncidents = (list, keyGetter) => {
  const result = list.reduce(function (r, a) {
    const groupingName = a[keyGetter] ? a[keyGetter] : 'Others';

    r[groupingName] = r[groupingName] || [];
    r[groupingName].push(a);
    return r;
  }, Object.create(null));

  return result;
};

/**
 * Helper function for sorting incidents by key
 * @param {array} list
 * @param {string} keyGetter
 */
export const sortIncidents = (list, keyGetter) => {
  list.sort((a, b) => {
    var keyA = a[keyGetter],
      keyB = b[keyGetter];

    if (keyGetter === 'occurred' || keyGetter === 'received') {
      keyA = new Date(a[keyGetter]);
      keyB = new Date(b[keyGetter]);
    }

    if (keyA < keyB) return -1;
    if (keyA > keyB) return 1;
    return 0;
  });

  console.log('Sorted List: ', list);

  return list;
};

/**
 * Helper function for preparing incident attachments data in ArrayBuffer format
 * @param {array} incAttachments
 * @param {array} parties
 * @param {array} properties
 * @param {array} narratives
 * @param {array} evidences
 */
export const prepareIncidentAttachmentsData = (
  incAttachments,
  parties,
  properties,
  narratives,
  evidences
) => {
  let allAttachments = [],
    buffer = '';

  if (incAttachments.length > 0)
    incAttachments.map((ia) => {
      ia.FileObject ? (buffer = Buffer.from(ia.FileObject, 'base64')) : '';
      allAttachments.push({ ...ia, FileObject: buffer });
    });
  if (parties.length > 0)
    parties.map((p) => {
      if (p.Attachments) {
        let partiesAttachments = JSON.parse(p.Attachments);
        partiesAttachments.map((pa) => {
          pa.FileObject ? (buffer = Buffer.from(pa.FileObject, 'base64')) : '';
          allAttachments.push({ ...pa, FileObject: buffer });
        });
      }
    });
  if (properties.length > 0)
    properties.map((pr) => {
      if (pr.Attachments) {
        pr.Attachments.map((pra) => {
          pra.FileObject ? (buffer = Buffer.from(pra.FileObject, 'base64')) : '';
          allAttachments.push({ ...pra, FileObject: buffer });
        });
      }
    });
  if (narratives.length > 0)
    narratives.map((n) => {
      if (n.Attachments) {
        n.Attachments.map((na) => {
          na.FileObject ? (buffer = Buffer.from(na.FileObject, 'base64')) : '';
          allAttachments.push({ ...na, FileObject: buffer });
        });
      }
    });
  if (evidences.length > 0)
    evidences.map((e) => {
      if (e.Attachments) {
        e.Attachments.map((ea) => {
          ea.FileObject ? (buffer = Buffer.from(ea.FileObject, 'base64')) : '';
          allAttachments.push({ ...ea, FileObject: buffer });
        });
      }
    });

  return allAttachments;
};
