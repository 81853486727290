import store from '../../config/configureStore';
import { incVictimSchema } from 'simpl-schema-validation/schema';
import { generateHandleEditOffense } from 'simpl-schema-validation/helpers/generateHandleEdit';

export const validateIncVictims = () => {
  const currentState = store.store.getState().incident;
  const { offenses, turnOfValidation } = currentState;
  const incVictims = currentState.incVictims;
  const parties = currentState.parties;
  const offensesOffenseForm = currentState.offensesOffenseForm;
  const addresses = currentState.addresses;

  if (turnOfValidation) return [];

  let validationErrors = [];

  for (let incVictim of incVictims) {
    let victimParty = parties.find(
      (p) => p.ptsIncPersonId === incVictim.incVictimDetails.ptsIncPersonId
    );
    let victimPlace = addresses.find(
      (p) => p.ptsIncPlaceId === incVictim.incVictimDetails.ptsIncPlaceId
    );

    if (!victimParty && !victimPlace) continue;

    const offense = offenses.find(
      (offense) =>
        offense.offenseDetails.ptsOffenseId === incVictim.incVictimDetails.ptsOffenseId &&
        offense.offenseDetails.values.excludeOffense === false &&
        offense.offenseDetails.values?.statuteDetails?.FBICode !== '0'
    );

    if (!offense) continue;

    let fullName = victimParty
      ? `${victimParty.PersonId} | ${victimParty.personDetail.values.lastName || ''}, ${
          victimParty.personDetail.values.firstName || ''
        } ${victimParty.personDetail.values.middleName || ''}`
      : `${victimPlace.placeId} | ${victimPlace.addressDetails.values.placeName}`;

    const offensesIncVictims = incVictims.filter(
      (incV) => incV.incVictimDetails.ptsOffenseId === incVictim.incVictimDetails.ptsOffenseId
    );

    let objToValidate = {
      injuryType: incVictim.incVictimDetails.values.injuryType,
      incVictim: incVictim.incVictimDetails,
      category: incVictim.incVictimDetails.values.category,
      offense: offense.offenseDetails,
      incident: currentState.incident,
      aggravatedAssaultHomicide: incVictim.incVictimDetails.values.aggravatedAssaultHomicide,
      justifiableHomicide: incVictim.incVictimDetails.values.justifiableHomicide,
      victimParty: victimParty,
      victimPlace: victimPlace,
      incVictims: offensesIncVictims,
      additionalJustifiableHomicide:
        incVictim.incVictimDetails.values.additionalJustifiableHomicide,
    };
    let incVictimsValidationContext = incVictimSchema.newContext();
    let result = incVictimsValidationContext.validate(objToValidate, {
      keys: [
        'injuryType',
        'category',
        'aggravatedAssaultHomicide',
        'victimType',
        'justifiableHomicide',
        'officerActivity',
        'officerAssignment',
        'additionalJustifiableHomicide',
      ],
    });

    if (!result) {
      incVictimsValidationContext.validationErrors().forEach((error) => {
        validationErrors.push({
          id: incVictim.incVictimDetails.ptsVictimId,
          ptsIncPersonId: incVictim.incVictimDetails.ptsIncPersonId,
          ptsIncPlaceId: incVictim.incVictimDetails.ptsIncPlaceId,
          ptsOffenseId: incVictim.incVictimDetails.ptsOffenseId,
          idType: 'ptsVictimId',
          errType: error.type,
          field: error.name,
          name: fullName,
          stepper: 2,
          screen: 'Offense Victim',
          message: incVictimsValidationContext.keyErrorMessage(error.name),
          ptsIncidentId: currentState.ptsIncidentId,
          handleEdit: generateHandleEditOffense(
            currentState.ptsIncidentId,
            incVictim.incVictimDetails.ptsOffenseId,
            2
          ),
          identifier: `${currentState.ptsIncidentId}-${error.type.split('-').pop()}-${
            error.name
          }-OffenseVictim-${
            incVictim.incVictimDetails.ptsIncPersonId || incVictim.incVictimDetails.ptsIncPlaceId
          }-${incVictim.incVictimDetails.ptsOffenseId}`,
        });
      });
    }
  }

  return validationErrors;
};
