import React, { useEffect, useState } from 'react';
import {
  Card,
  Grid,
  TextField,
  Typography,
  makeStyles,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Button,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import ArrowDropDownCircleIcon from '@material-ui/icons/ArrowDropDownCircle';
import PlusCircleButton from 'components/RMSButtons/RMSAddButton/plusCircleButton';
import { alpha } from '@mui/material';
import ReadOnlyIcon from 'assets/favicon/FilledReadOnlyIcon.png';

import { connect } from 'react-redux';

import { Description } from '@material-ui/icons';

import { Row, gridStyle } from 'utils/formStyles';
import { getService } from 'reducers/service';
import { compareDate, displayDateTime } from 'reducers/TimeReducer';
import clsx from 'clsx';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
const PRIMARY_COLOR = '#1977D4';
const useStyles = makeStyles((theme) => ({
  cardView: {
    paddingTop: 0,
    backgroundColor: theme.accordion.background,
    color: 'inherit',
    width: '100%',
    borderRadius: '5px',
  },
  spanHeader: {
    color: theme.palette.colors.label,
    fontWeight: 'bold',
    backgroundColor: theme.palette.customBg,
    width: '100%',
    padding: '3px',
    paddingLeft: '15px',
    borderRadius: '5px',
    // paddingTop: '5px',
  },
  svg_icons: {
    paddingLeft: '5x',
    color: PRIMARY_COLOR,
  },
  svg_icons_rotated: {
    paddingLeft: '5x',
    transform: 'rotate(180deg)',
    color: PRIMARY_COLOR,
  },
  list_div: {
    border: `2px solid #B5B5B5`,
    borderColor: theme.palette.type === 'dark' ? '#7C7C7C' : '#B5B5B5',
    borderRadius: '10px',
    width: '100%',
    marginLeft: '28px',
  },
  listHeader: {
    background: alpha('#1976d2', 0.2),
    color: theme.palette.colorFont,
    borderRadius: '5px',
    paddingTop: '2px',
    paddingRight: '8px',
    height: '30px',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  parent_div: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  default_div: {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  list_root: {
    width: '100%',
    paddingTop: '2px',
    // paddingLeft: '30px',
    overflowY: 'scroll',
  },
  listItem_root: {
    padding: '0px',
  },
  listItem: {
    borderTop: '.5px solid ',
    borderColor: theme.palette.type === 'light' ? '#d0d0d0' : '#616161',
    '&:hover': {
      backgroundColor: alpha('#1976d2', 0.2),
    },
  },
  fabStyle: {
    backgroundColor: theme.palette.colors.chipBg,
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  rowActionsSingle: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(3) + 2,
    opacity: 0,
    transition: 'opacity 0.3s',
  },
  rowActionsMultiple: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(6) + 2,
    opacity: 0,
    transition: 'opacity 0.3s',
  },
  note: {
    padding: '10px 15px 10px',
    position: 'relative',
    '& p': {
      fontSize: 13,
      marginBottom: 5,
      paddingRight: 50,
      whiteSpace: 'pre-line',
    },
    borderBottom: `1px solid ${theme.card.hr}`,
    '&:hover': {
      '& $rowActionsSingle': {
        opacity: 1,
      },
      '& $rowActionsMultiple': {
        opacity: 1,
      },
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& h4': {
      fontSize: '1em',
      marginBottom: '0.25em',
    },
  },
  notes: {
    textAlign: 'left',
    maxHeight: 300,
    overflowY: 'auto',
    width: '100%',
  },
  item2: gridStyle('95%', 800),
}));

function Note(props) {
  const classes = useStyles();
  const { canEdit, canDelete, note } = props;
  const { CreatedBy, Comment, Created, ptsCommentID, Updated, UpdatedBy } = note;
  const editNote = () => {
    props.editNote(ptsCommentID, Comment);
  };

  const delNote = () => {
    props.delNote(ptsCommentID);
  };

  return (
    <div className={classes.note}>
      <div className={classes.header}>
        <h4>Created By: {CreatedBy}</h4>
        <span>{displayDateTime(Created)}</span>
      </div>
      {!compareDate(Created, Updated) && (
        <div className={classes.header}>
          <h4>Updated By: {UpdatedBy}</h4>
          <span>{displayDateTime(Updated)}</span>
        </div>
      )}
      <p>{Comment}</p>
      <div
        className={clsx({
          [classes.rowActionsMultiple]: !compareDate(Created, Updated),
          [classes.rowActionsSingle]: compareDate(Created, Updated),
        })}>
        <Tooltip title={canEdit ? 'Edit note' : 'View Note'}>
          <IconButton color="primary" size="small" onClick={editNote}>
            {canEdit ? <EditIcon /> : <img src={ReadOnlyIcon} width={25} />}
          </IconButton>
        </Tooltip>

        <Tooltip title="Delete note">
          <IconButton disabled={!canDelete} color="primary" size="small" onClick={delNote}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </div>
    </div>
  );
}
const initNote = { ptsCommentID: null, Comment: '' };
function WarrantNotes(props) {
  const { warrant, onChange } = props;
  const classes = useStyles();

  const [cardExpanded, setCardExpanded] = useState(false);
  const [open, setOpen] = useState(false);
  const [note, setNote] = useState(initNote);
  const [data, setData] = useState([]);
  const [actionType, setActionType] = useState('add');
  const service = getService('rms-warrant');

  const getNotesByWarrantID = async () => {
    const res = await service.find({
      query: {
        type: 'warrant_note',
        data: { ptsWarrantID: warrant.ptsWarrantID },
      },
    });
    setData(res);
  };
  const handleClose = () => {
    setNote(initNote);
    setOpen(false);
  };
  const handleSave = async () => {
    const { ptsWarrantID } = warrant;
    if (actionType === 'add') {
      await service.create({
        type: 'warrant_note',
        data: { ptsWarrantID, note: note.Comment },
      });
    } else {
      await service.patch(null, {
        type: 'warrant_note',
        data: { ptsCommentID: note.ptsCommentID, note: note.Comment },
      });
    }
    await getNotesByWarrantID();
    setNote(initNote);
    setOpen(false);
  };
  const addNote = () => {
    setActionType('add');
    setOpen(true);
  };
  const delNote = async (ptsCommentID) => {
    await service.patch(null, {
      type: 'remove_warrant_note',
      data: { ptsCommentID },
    });
    await getNotesByWarrantID();
  };
  const editNote = (ptsCommentID, Comment) => {
    setActionType('edit');
    setNote({ ptsCommentID, Comment });
    setOpen(true);
  };

  useEffect(() => {
    if (warrant) getNotesByWarrantID();
  }, [warrant]);

  return (
    <Card className={classes.cardView}>
      <Grid container spacing={1}>
        <Grid item container style={{ marginLeft: '10px', marginBottom: '3px' }} xs={12}>
          <Grid
            item
            xs={12}
            style={{
              marginTop: '6px',
              display: 'flex',
              alignItems: 'center',
              marginBottom: '10px',
            }}>
            <span style={{ marginRight: '5px' }}></span>
            <Description style={{ color: PRIMARY_COLOR }} />
            <Typography variant="span" className={classes.spanHeader}>
              Notes ({data.length})
            </Typography>
            <span
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                paddingRight: '20px',
                paddingBottom: '3px',
                paddingTop: '3px',
              }}>
              <span
                style={{
                  padding: '0px',
                  '&:hover': {
                    background: 'transparent',
                  },
                  border: 'none',
                  background: 'transparent',
                  marginLeft: '-7px',
                }}>
                <PlusCircleButton disabled={props.viewOnly} onClick={addNote} />
              </span>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <ArrowDropDownCircleIcon
                  onClick={() => setCardExpanded(!cardExpanded)}
                  className={cardExpanded ? classes.svg_icons_rotated : classes.svg_icons}
                  style={{
                    marginLeft: '10px',
                  }}
                />
              </div>
            </span>
          </Grid>

          {cardExpanded && (
            <Row>
              <Grid
                container
                item
                xs={12}
                className={classes.list_div}
                spacing={1}
                style={{ paddingTop: '10px', paddingRight: '10px' }}>
                <div className={classes.notes}>
                  {data.length
                    ? data.map((note, idx) => (
                        <Note
                          key={idx}
                          note={note}
                          delNote={delNote}
                          editNote={editNote}
                          canEdit={props.viewOnly ? false : true} // need to add permission later
                          canDelete={props.viewOnly ? false : true} // need to add permission later
                          disableEdit={false}
                        />
                      ))
                    : null}
                </div>
              </Grid>
            </Row>
          )}
        </Grid>
      </Grid>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth={'md'}>
        <DialogTitle>{actionType === 'add' ? 'Add' : 'Edit'} Note</DialogTitle>
        <DialogContent>
          <TextField
            label="Notes"
            variant="outlined"
            onChange={(e) => setNote((prevState) => ({ ...prevState, Comment: e.target.value }))}
            value={note.Comment}
            className={classes.item2}
            size="medium"
            multiline
            rows={4}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <Button
            onClick={handleSave}
            disabled={!note.Comment || (actionType !== 'add' && props.viewOnly)}>
            {actionType === 'add' ? 'Save' : 'Update'}
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
}

export default connect(null, {})(WarrantNotes);
