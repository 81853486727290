/* eslint-disable prettier/prettier */
import SimpleSchema from 'simpl-schema';
import { groupAOffenseCodes } from '../CategoryCodes/groupAOffenses';
import { groupBOffenses } from './../CategoryCodes/groupBOffenses';
import { biasMotivationMutuallyExclusiveCodes } from 'simpl-schema-validation/helpers/Codes/MutuallyExclusive';
import store from '../../config/configureStore';
import { getCategoryOfCode } from 'utils/functions';

SimpleSchema.setDefaultMessages({
  messages: {
    en: {
      'NIBRS-2019.2-38': 'The Location Category is required for each offense.',
      'NIBRS-2019.2-39':
        'When Location Category indicates "58" Cyberspace, then the Incident Report must contain one of the following Offense codes:(210, 250, 270, 280, 290, 370, 510, 26A, 26B, 26C, 26D, 26E, 26F, 26G, 39A, 39B, 39C, 13C, 35A, 35B, 520, 64A, 64B, 40A, 40B, 40C).',
      'NIBRS-2019.2-41': 'The Number of Premises Entered must be 01–99 when provided.',
      'NIBRS-2019.2-37':
        'The Offense Factor Bias Motivation must equal "NONE" (88) when the Offense Code is 09C (Justifiable Homicide).',
      'NIBRS-2019.2-49':
        'Criminal Activity Category type is required when the Offense code is 250, 280, 35A, 35B, 39C, 370, 520, 720, 09A, 09B, 100, 11A, 11B, 11C, 11D, 120, 13A, 13B, 13C.',
      'NIBRS-2019.2-48':
        'When the Criminal Activity Category code is A, F, I, S the Offense code must be 720.',
      'NIBRS-2019.2-47':
        'When the Criminal Activity Category code is J, G, N the Offense code must be 09A, 09B, 100, 11A, 11B, 11C, 11D, 120, 13A, 13B, 13C.',
      'NIBRS-2019.2-46':
        'When the Criminal Activity Category code is B, C, D, E, O, P, T, U the Offense code must be 250, 280, 35A, 35B, 39C, 370, or 520.',
      'NIBRS-2019.2-40':
        'The Number of Premises Entered is required when the Offense Code = 220 and the Location category = 14, or 19.',
      'NIBRS-2019.2-55':
        'When the Offense is a homicide offense (09A, 09B, 09C) then the Force Category cannot be 99.',
      'NIBRS-2019.2-53':
        'Force Category type must be omitted when the Offense code is not 09A, 09B, 09C, 100, 11A, 11B, 11C, 11D, 120, 13A, 13B, 210, 520, 64A, 64B.',
      'NIBRS-2019.2-54':
        'When the Offense code = 13B then the Force Category must be 40, 90, 95, or 99.',
      'NIBRS-2019.2-52':
        'Force Category is required when the Offense code is 09A, 09B, 09C, 100, 11A, 11B, 11C, 11D, 120, 13A, 13B, 210, 520, 64A, 64B.',
      'NIBRS-2019.2-42': 'The Method of Entry must be provided when Offense Code = 220.',
      'NIBRS-2019.2-43': 'The Method of Entry must be omitted when Offense Code is not equal 220.',
      'NIBRS-2019.2-56':
        'When the Offense code = 13B and the Force Category code = 11, 12, 13, 14, or 15 then the Offense code should = 13A.',
      'NIBRS-2019.2-34':
        'When submitting a Group A Incident Report, the Offense Factor Bias Motivation is required.',
      'NIBRS-2019.2-30':
        'When submitting a Group A Incident Report and the Offense Code indicates Assault or Homicide (09A, 09B, 09C, 13A, 13B, or 13C), the Offense Attempted/Completed indicator must be "false".',
      'NIBRS-2019.2-31':
        'When submitting a Group A Incident Report, the Offense Factor is required.',
      'NIBRS-2019.2-23 Subjects':
        'When submitting a Group A Incident Report to be ingested into the NIBRS system, Subject must be provided.',
      'NIBRS-2019.2-23 Victims':
        'When submitting a Group A Incident Report to be ingested into the NIBRS system, Victim must be provided.',
      'NIBRS-2019.2-194': `When the nibrs report category is Group B and the action code = 'A' then Arrest, Arrestee must be provided.`,
      'NIBRS-2019.2-24': 'When submitting a Group A Incident Report, the Offense code is required.',
      'NIBRS-2019.2-51':
        'Mutually exclusive Force Category value of "99" cannot be entered with any other data value for the Offense.',
      'NIBRS-2019.2-45':
        'Mutually exclusive Criminal Activity Category value of "N" cannot be entered with any other data value for the Offense.',
      'NIBRS-2019.2-36':
        'The Offense Factor Bias Motivation cannot contain a mutually exclusive value with an other value within the Offense.',
      'NIBRS-2019.2-33':
        'The Offense Factor cannot contain a mutually exclusive value with an other value within the Offense.',
    },
  },
});

export const offenseDetailsSchema = new SimpleSchema({
  incVictims: {
    type: Array,
    optional: true,
    custom() {
      // NIBRS-2019.2-23: Report Entity Validation
      if (groupAOffenseCodes.includes(this.obj.offense.values.statuteDetails?.FBICode)) {
        if (!this.value.length) {
          return 'NIBRS-2019.2-23 Victims';
        }
      }
    },
  },
  'incVictims.$': {
    type: Number,
    optional: true,
    blackbox: true,
    custom() {},
  },

  incSubjects: {
    type: Array,
    optional: true,
    custom() {
      // NIBRS-2019.2-23: Report Entity Validation
      if (groupAOffenseCodes.includes(this.obj.offense.values.statuteDetails?.FBICode)) {
        if (!this.value.length) {
          return 'NIBRS-2019.2-23 Subjects';
        }
      }
    },
  },
  'incSubjects.$': {
    type: Number,
    optional: true,
    blackbox: true,
    custom() {},
  },

  incArrestees: {
    type: Array,
    optional: true,
    custom() {
      // NIBRS-2019.2-194: NIBRS Group B Report segments
      // if (groupBOffenses.includes(this.obj.offense.values.statuteDetails?.FBICode)) {
      //   if (!this.value.length) {
      //     return 'NIBRS-2019.2-194';
      //   }
      // }
    },
  },
  'incArrestees.$': {
    type: Number,
    optional: true,
    blackbox: true,
    custom() {},
  },

  locationCategory: {
    type: String,
    label: 'NIBRS-2019.2-38: Location',
    optional: true,
    custom() {
      if (!this.value) {
        return 'NIBRS-2019.2-38';
      }
    },
  },

  structuresEntered: {
    type: SimpleSchema.Integer,
    optional: true,
    custom() {
      if (this.value && (this.value < 1 || this.value > 99)) return 'NIBRS-2019.2-41';

      //NIBRS-2019.2-40
      if (this.obj.offense.values.statuteDetails?.FBICode === '220') {
        const codeLocationCategories = store.store.getState().dictionary.codeLocationCategories;
        const locationCategory = getCategoryOfCode(
          codeLocationCategories,
          this.obj.offense.values.locationCategory
        );
        if (['14', '19'].includes(locationCategory)) {
          if (!this.value) {
            return 'NIBRS-2019.2-40';
          }
        }
      }
    },
  },

  biasMotivation: {
    type: String,
    optional: true,
    custom() {
      // NIBRS-2019.2-34: Offense Factor Bias Motivation
      if (groupAOffenseCodes.includes(this.obj.offense.values.statuteDetails?.FBICode)) {
        if (!this.value || !JSON.parse(this.value)?.length) {
          if (this.obj.offense.values.statuteDetails?.FBICode !== '35A') {
            return 'NIBRS-2019.2-34'; 
          }
        }
      }

      if (!this.value) return;
      const parsedValues = JSON.parse(this.value);
      if (!parsedValues) return;
      const codeBiasMotivations = store.store.getState().dictionary.codeBiasMotivations;
      const biasMotivationCategories =
        parsedValues.map((value) => getCategoryOfCode(codeBiasMotivations, value)) || [];
      // NIBRS-2019.2-36: Offense Factor Bias Motivation
      let errorFlag = false;
      Object.keys(biasMotivationMutuallyExclusiveCodes).forEach((key) => {
        let count = 0;
        biasMotivationMutuallyExclusiveCodes[key].forEach((item) => {
          if (biasMotivationCategories.includes(item.category)) {
            count++;
          }
        });
        if (count > 1) {
          errorFlag = true;
        }
      });
      if (errorFlag) {
        return 'NIBRS-2019.2-36';
      }

      // NIBRS-2019.2-37: Offense Factor Bias Motivation
      if (this.obj.offense.values.statuteDetails?.FBICode === '09C') {
        if (biasMotivationCategories.includes('88')) {
          if (parsedValues.length > 1) {
            return 'NIBRS-2019.2-37';
          }
        } else {
          return 'NIBRS-2019.2-37';
        }
      }
    },
  },

  offenseAttempted: {
    type: Boolean,
    optional: true,
    custom() {
      // NIBRS-2019.2-30: Offense Attempted/Completed
      if (groupAOffenseCodes.includes(this.obj.offense.values.statuteDetails?.FBICode)) {
        if (
          ['09A', '09B', '09C', '13A', '13B', '13B'].includes(
            this.obj.offense.values.statuteDetails?.FBICode
          )
        ) {
          if (this.value !== false) {
            return 'NIBRS-2019.2-30';
          }
        }
      }
    },
  },

  offenderSuspectedOfUsing: {
    type: String,
    optional: true,
    custom() {
      // NIBRS-2019.2-31: Offense Factor
      if (groupAOffenseCodes.includes(this.obj.offense.values.statuteDetails?.FBICode)) {
        if (!this.value || !JSON.parse(this.value)?.length) {
          return 'NIBRS-2019.2-31';
        }
      }

      if (!this.value) return;
      const parsedValues = JSON.parse(this.value);
      if (!parsedValues) return;

      const codeOffenseFactor = store.store.getState().dictionary.codeOffenseFactor;
      const suspectedOfUsingCategories =
        parsedValues.map((value) => getCategoryOfCode(codeOffenseFactor, value)) || [];

      // NIBRS-2019.2-33: Offense Factor
      if (suspectedOfUsingCategories.includes('n')) {
        if (parsedValues.length > 1) {
          return 'NIBRS-2019.2-33';
        }
      }
    },
  },

  forceCategory: {
    type: String,
    optional: true,
    custom() {
      // NIBRS-2019.2-52: Weapon/Force Involved
      if (
        [
          '09A',
          '09B',
          '09C',
          '100',
          '11A',
          '11B',
          '11C',
          '11D',
          '120',
          '13A',
          '13B',
          '210',
          '520',
          '64A',
          '64B',
        ].includes(this.obj.offense.values.statuteDetails?.FBICode)
      ) {
        if (!this.value || !JSON.parse(this.value)?.length) {
          return 'NIBRS-2019.2-52';
        }
      }

      if (!this.value) return;
      const parsedValues = JSON.parse(this.value);
      if (!parsedValues) return;

      const codeForceCategory = store.store.getState().dictionary.codeForceCategory;
      const forceCodeValues =
        parsedValues?.map((value) => getCategoryOfCode(codeForceCategory, value)) || [];

      // NIBRS-2019.2-51: Weapon/Force Involved
      if (forceCodeValues.includes('99')) {
        if (parsedValues.length > 1) {
          return 'NIBRS-2019.2-51';
        }
      }

      // NIBRS-2019.2-55: Weapon/Force Involved
      if (['09A', '09B', '09C'].includes(this.obj.offense.values.statuteDetails?.FBICode)) {
        if (forceCodeValues.includes('99')) {
          return 'NIBRS-2019.2-55';
        }
      }
      // NIBRS-2019.2-53: Weapon/Force Involved
      if (
        ![
          '09A',
          '09B',
          '09C',
          '100',
          '11A',
          '11B',
          '11C',
          '11D',
          '120',
          '13A',
          '13B',
          '210',
          '520',
          '64A',
          '64B',
        ].includes(this.obj.offense.values.statuteDetails?.FBICode)
      ) {
        if (parsedValues.length) {
          return 'NIBRS-2019.2-53';
        }
      }

      // NIBRS-2019.2-56: Weapon/Force Involved
      if (this.obj.offense.values.statuteDetails?.FBICode === '13B') {
        if (
          forceCodeValues.includes('11') ||
          forceCodeValues.includes('12') ||
          forceCodeValues.includes('13') ||
          forceCodeValues.includes('14') ||
          forceCodeValues.includes('15')
        ) {
          return 'NIBRS-2019.2-56';
        }
      }

      // NIBRS-2019.2-54: Weapon/Force Involved
      if (this.obj.offense.values.statuteDetails?.FBICode === '13B') {
        let errorFlag = false;
        forceCodeValues.forEach((value) => {
          if (!['40', '90', '95', '99'].includes(value)) {
            errorFlag = true;
          }
        });
        if (errorFlag) {
          return 'NIBRS-2019.2-54';
        }
      }
    },
  },

  criminalActivity: {
    type: String,
    optional: true,
    custom() {
      // NIBRS-2019.2-49: Criminal Activity/Gang Information
      if (
        [
          '250',
          '280',
          '35A',
          '35B',
          '39C',
          '370',
          '520',
          '720',
          '09A',
          '09B',
          '100',
          '11A',
          '11B',
          '11C',
          '11D',
          '120',
          '13A',
          '13B',
          '13C',
        ].find((element) => element === this.obj.offense.values.statuteDetails?.FBICode)
      ) {
        if (!this.value || !JSON.parse(this.value)?.length) {
          return 'NIBRS-2019.2-49';
        }
      }

      if (!this.value) return;
      const parsedValues = JSON.parse(this.value);
      if (!parsedValues) return;

      const codeCriminalActivity = store.store.getState().dictionary.codeCriminalActivities;
      const criminalActivityCodeValues =
        parsedValues?.map((value) => getCategoryOfCode(codeCriminalActivity, value)) || [];

      // NIBRS-2019.2-45: Criminal Activity/Gang Information
      if (criminalActivityCodeValues.includes('n')) {
        if (parsedValues.length > 1) {
          return 'NIBRS-2019.2-45';
        }
      }

      // NIBRS-2019.2-48: Criminal Activity/Gang Information
      if (
        criminalActivityCodeValues.includes('a') ||
        criminalActivityCodeValues.includes('f') ||
        criminalActivityCodeValues.includes('i') ||
        criminalActivityCodeValues.includes('s')
      ) {
        if (this.obj.offense.values.statuteDetails?.FBICode !== '720') {
          return 'NIBRS-2019.2-48';
        }
      }

      // NIBRS-2019.2-47: Criminal Activity/Gang Information
      if (
        criminalActivityCodeValues.includes('j') ||
        criminalActivityCodeValues.includes('g') ||
        criminalActivityCodeValues.includes('n')
      ) {
        if (
          !['09A', '09B', '100', '11A', '11B', '11C', '11D', '120', '13A', '13B', '13C'].includes(
            this.obj.offense.values.statuteDetails?.FBICode
          )
        ) {
          return 'NIBRS-2019.2-47';
        }
      }

      // NIBRS-2019.2-46: Criminal Activity/Gang Information
      if (
        criminalActivityCodeValues.includes('b') ||
        criminalActivityCodeValues.includes('c') ||
        criminalActivityCodeValues.includes('d') ||
        criminalActivityCodeValues.includes('e') ||
        criminalActivityCodeValues.includes('o') ||
        criminalActivityCodeValues.includes('p') ||
        criminalActivityCodeValues.includes('t') ||
        criminalActivityCodeValues.includes('u')
      ) {
        if (
          !['250', '280', '35A', '35B', '39C', '370', '520'].includes(
            this.obj.offense.values.statuteDetails?.FBICode
          )
        ) {
          return 'NIBRS-2019.2-46';
        }
      }
    },
  },

  methodOfEntry: {
    type: String,
    optional: true,
    custom() {
      // NIBRS-2019.2-42: Method of Entry
      if (this.obj.offense.values.statuteDetails?.FBICode === '220') {
        if (!this.value) {
          return 'NIBRS-2019.2-42';
        }
      }

      // NIBRS-2019.2-43: Method of Entry
      if (this.obj.offense.values.statuteDetails?.FBICode !== '220') {
        if (this.value) {
          return 'NIBRS-2019.2-43';
        }
      }
    },
  },

  statuteDetails: {
    type: String,
    optional: true,
    custom() {
      const codeLocationCategories = store.store.getState().dictionary.codeLocationCategories;
      const locationCategory = getCategoryOfCode(
        codeLocationCategories,
        this.obj.offense.values.locationCategory
      );

      //NIBRS-2019.2-39: Location
      if (locationCategory === '58') {
        if (
          ![
            '210',
            '250',
            '270',
            '280',
            '290',
            '370',
            '510',
            '26A',
            '26B',
            '26C',
            '26D',
            '26E',
            '26F',
            '26G',
            '39A',
            '39B',
            '39C',
            '13C',
            '35A',
            '35B',
            '520',
            '64A',
            '64B',
            '40A',
            '40B',
            '40C',
          ].includes(this.obj.offense.values.statuteDetails?.FBICode)
        ) {
          return 'NIBRS-2019.2-39';
        }
      }
    },
  },
});
