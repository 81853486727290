import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

import Dialog from 'components/RMSDialog';
import RMSTextField from 'components/RMSTextField';
import Dictionary from 'components/RMSAutoComplete/RMSDictonary';
import BackDatedRecordHistory from 'components/BackDatedRecordHistory';

import formStyles, { gridStyle, Row, Fills, useError, formatSaveData } from 'utils/formStyles';

import { showSpinner, hideSpinner } from 'reducers/UiReducer';
import { createPersonName, editPersonName, getName } from 'reducers/SearchReducer';
import { handleError } from 'reducers/ErrorReducer';
import { notifyDataUpdate } from 'reducers/DataUpdateReducer';
import { notify } from 'reducers/NotifierReducer';
import { closePersonNameDialog } from 'reducers/PersonDialogsReducer';

const useStyles = makeStyles((theme) => ({
  ...formStyles,
  content: {
    width: 700,
    maxWidth: '100%',
  },
  fullName: {
    justifyContent: 'space-between',
  },
  item: gridStyle(200, '100%'),
  nameType: gridStyle(150, 220),
  closeButton: { color: theme.button.color },
}));

function PersonNameDialog(props) {
  const classes = useStyles();
  const { data, executePersonSave, setDataForNamesDialog } = props;

  const [FirstName, setFirstName] = useState('');
  const [MiddleName, setMiddleName] = useState('');
  const [LastName, setLastName] = useState('');
  const [Prefix, setPrefix] = useState(null);
  const [Suffix, setSuffix] = useState(null);
  const [NickName, setNickName] = useState('');
  const [Notes, setNotes] = useState('');
  const [NameType, setNameType] = useState(null);
  const [errors, setErrors] = useState();

  const [recordHistoryData, setRecordHistoryData] = useState({
    shouldVisible: true,
    isDisabled: true,
    createUpdateInfo: {},
  });
  const [showRecordHistory, setShowRecordHistory] = useState(false);

  const title = data.ptsNameID ? 'Edit Person Name' : 'Add Person Name';
  const { err, isValid, formChanged } = useError(errors, setErrors, true);

  useEffect(() => {
    if (data.ptsNameID) {
      setRecordHistoryData({
        shouldVisible: true,
        isDisabled: !data.Created,
        createUpdateInfo: {
          created: data.Created,
          createdBy: data.CreatedBy,
          updated: data.Updated,
          updatedBy: data.UpdatedBy,
        },
      });

      getNameDetails();
    }
  }, [data.ptsNameID]);
  useEffect(() => {
    if (data.origin === 'name-change') {
      processData(data);
    }
  }, [data]);

  const getNameDetails = () => {
    if (data.origin === 'nameSearch') {
      props.showSpinner();
      getName(data.ptsNameID).then(processData).catch(props.handleError).finally(props.hideSpinner);
    } else {
      processData(data);
    }
  };

  const processData = (result) => {
    if (result) {
      const { FirstName, MiddleName, LastName, Prefix, Suffix, NickName, Notes, NameType } = result;

      setFirstName(FirstName);
      setMiddleName(MiddleName);
      setLastName(LastName);
      setPrefix(Prefix);
      setSuffix(Suffix);
      setNickName(NickName);
      setNotes(Notes);
      setNameType(NameType);
    }
  };

  const handleCloseRecordHistoryDialog = () => setShowRecordHistory(false);

  const close = () => {
    if (data?.origin === 'name-change') {
      setDataForNamesDialog(null);
    } else {
      props.closePersonNameDialog();
    }
  };

  const save = () => {
    const saveData = formatSaveData({
      FirstName,
      MiddleName,
      LastName,
      Prefix,
      Suffix,
      NickName,
      Notes,
      NameType,
      IsDefault: false,
      IsActive: true,
    });
    saveData.FullName = getFullName();

    props.showSpinner();

    if (data.ptsPersonID && data.ptsNameID) {
      editPersonName(data.ptsNameID, saveData)
        .then(() => {
          props.notify('Name updated', 'success');
          props.notifyDataUpdate({ type: 'person-name' });
          props.closePersonNameDialog();
        })
        .catch(props.handleError)
        .finally(props.hideSpinner);
    } else {
      saveData.ptsPersonID = data.ptsPersonID;
      createPersonName(saveData)
        .then(() => {
          props.notify('Name created', 'success');
          props.notifyDataUpdate({ type: 'person-name' });
          props.closePersonNameDialog();
          if (data?.origin === 'name-change') {
            setDataForNamesDialog(null);
            executePersonSave();
          }
        })
        .catch(props.handleError)
        .finally(props.hideSpinner);
    }
  };

  const renderActions = () => {
    return (
      <>
        {recordHistoryData?.shouldVisible && (
          <Box className="">
            <BackDatedRecordHistory
              showRecordHistory={showRecordHistory}
              setShowRecordHistory={setShowRecordHistory}
              disabled={recordHistoryData?.isDisabled}
              handleCloseRecordHistoryDialog={handleCloseRecordHistoryDialog}
              createUpdateInfo={recordHistoryData?.createUpdateInfo}></BackDatedRecordHistory>
          </Box>
        )}
        <Button color="primary" variant="contained" autoFocus onClick={save} disabled={!isValid()}>
          <SaveIcon /> Save
        </Button>
        <Button color="primary" onClick={close} className={classes.closeButton}>
          <CloseIcon /> Close
        </Button>
      </>
    );
  };

  const getFullName = () => {
    let FullName = Prefix ? Prefix.Code + ' ' : '';
    FullName += FirstName ? FirstName + ' ' : '';
    FullName += MiddleName ? MiddleName + ' ' : '';
    FullName += LastName ? LastName + ' ' : '';
    FullName += Suffix ? Suffix.Code : '';
    return FullName.trim();
  };

  const renderFullName = () => {
    return data?.origin === 'name-change' ? (
      <p className={classes.item}>
        Previous Name for This person:
        <span style={{ fontWeight: 'bold', fontSize: '16px' }}>{getFullName()}</span> will be saved,
        Select a Type for this previous name
      </p>
    ) : (
      <h5 className={classes.item}>{getFullName()}</h5>
    );
  };

  const renderNameType = () => {
    const onChange = (ev, val) => {
      setNameType(val);
      formChanged();
    };
    const error = err(NameType, 'NameType');
    return (
      <Dictionary
        options="codePersonNameTypes"
        className={classes.nameType}
        onChange={onChange}
        formType={data.ptsNameID ? 'edit' : 'add'}
        value={NameType}
        autoFocus={data?.origin === 'name-change' ? true : false}
        label="Name Type"
        error={error}
        compact
      />
    );
  };

  const renderPrefix = () => {
    const onChange = (ev, val) => {
      setPrefix(val);
      formChanged();
    };
    return (
      <Dictionary
        options="codeNamePrefixes"
        className={classes.item}
        onChange={onChange}
        value={Prefix}
        formType={data.ptsNameID ? 'edit' : 'add'}
        label="Prefix"
        compact
      />
    );
  };

  const renderFirstName = () => {
    const onChange = (ev, val) => {
      setFirstName(val);
      formChanged();
    };
    return (
      <RMSTextField
        className={classes.item}
        label="First Name"
        value={FirstName}
        onChange={onChange}
        max={100}
        compact
      />
    );
  };

  const renderMiddleName = () => {
    const onChange = (ev, val) => {
      setMiddleName(val);
      formChanged();
    };
    return (
      <RMSTextField
        className={classes.item}
        label="Middle Name"
        value={MiddleName}
        onChange={onChange}
        max={50}
        compact
      />
    );
  };

  const renderLastName = () => {
    const onChange = (ev, val) => {
      setLastName(val);
      formChanged();
    };
    const error = err(LastName, 'LastName');
    return (
      <RMSTextField
        className={classes.item}
        label="Last Name"
        value={LastName}
        onChange={onChange}
        error={error}
        compact
        max={100}
      />
    );
  };

  const renderSuffix = () => {
    const onChange = (ev, val) => {
      setSuffix(val);
      formChanged();
    };
    return (
      <Dictionary
        options="codeNameSuffixes"
        className={classes.item}
        onChange={onChange}
        formType={data.ptsNameID ? 'edit' : 'add'}
        value={Suffix}
        label="Suffix"
        compact
      />
    );
  };

  const renderNickName = () => {
    const onChange = (ev, val) => {
      setNickName(val);
      formChanged();
    };
    return (
      <RMSTextField
        className={classes.item}
        label="Nick Name"
        value={NickName}
        onChange={onChange}
        max={20}
        compact
      />
    );
  };

  const renderNotes = () => {
    const onChange = (ev, val) => {
      setNotes(val);
      formChanged();
    };
    return (
      <RMSTextField
        className={classes.w100pr}
        label="Notes"
        value={Notes}
        onChange={onChange}
        max={2000}
        multiline
        rows={5}
        compact
      />
    );
  };

  return (
    <Dialog toolbar onClose={close} title={title} actions={renderActions()}>
      <div className={classes.content}>
        <Row className={classes.fullName}>
          {renderFullName()}
          {renderNameType()}
        </Row>
        {data?.origin !== 'name-change' && (
          <Row>
            {renderPrefix()}
            {renderFirstName()}
            {renderMiddleName()}
            {renderLastName()}
            {renderSuffix()}
            {renderNickName()}
            <Fills className={classes.item} />
          </Row>
        )}
        <Row>{renderNotes()}</Row>
      </div>
    </Dialog>
  );
}

export default connect(null, {
  closePersonNameDialog,
  showSpinner,
  hideSpinner,
  handleError,
  notifyDataUpdate,
  notify,
})(PersonNameDialog);
