import { lazy } from 'react';
// Layout Blueprints
import { RmsLayout } from './layout-blueprints';
// Incident Pages
const PageMain = lazy(() => import('./pages/PageMain'));
const PageRMSIncidents = lazy(() => import('./pages/PageRMSIncidents'));
const AddEditIncident = lazy(() => import('./pages/PageRMSIncidents/PageAddEditIncident'));
const PageDashboard = lazy(() => import('./pages/PageRMSDashboard/PageDashboard'));
const PageRMSRecords = lazy(() => import('./pages/PageRMSRecords'));
const PageRMSReports = lazy(() => import('./pages/PageRMSReports'));
const PageRMSSettings = lazy(() => import('./pages/PageRMSSettings'));
const AddEditAddress = lazy(() => import('./pages/PageRMSAddress/'));
const PageRMSAfterActionReport = lazy(() => import('./pages/PageRMSAfterActionReport/'));
const AddEditActionReportParties = lazy(() => import('./pages/PageRMSActionReportParties'));
const AddEditParties = lazy(() => import('./pages/PageRMSParties'));
const AddEditItem = lazy(() => import('./pages/PageRMSProperties/PageRMSItem/AddEditItem'));
const AddEditFirearm = lazy(() =>
  import('./pages/PageRMSProperties/PageRMSFirearm/AddEditFirearm')
);

const AddEditStructure = lazy(() =>
  import('./pages/PageRMSProperties/PageRMSStructure/AddEditStructure')
);
const AddEditSubstance = lazy(() =>
  import('./pages/PageRMSProperties/PageRMSSubstance/AddEditSubstance.js')
);
const AddEditJewelry = lazy(() =>
  import('./pages/PageRMSProperties/PageRMSJewelry/AddEditJewelry.js')
);
const AddEditVehicle = lazy(() =>
  import('./pages/PageRMSProperties/PageRMSVehicle/AddEditVehicle.js')
);
const AddEditOffense = lazy(() => import('./pages/PageRMSOffenses'));
const AddEditEvidenceParties = lazy(() => import('./pages/PageRMSEvidenceParties'));
const AddEditNarratives = lazy(() => import('./pages/PageRMSNarratives/AddEditNarratives'));
const AddEditDomesticViolence = lazy(() => import('./pages/PageRMSDomesticViolence'));
const AddEditMissingPerson = lazy(() =>
  import('./pages/PageRMSMissingPerson/AddEditMissingPerson')
);
const AddEditDispositions = lazy(() => import('./pages/PageRMSDispositions'));
const AddEditTags = lazy(() => import('./pages/PageRMSTags'));
const AddEditSOP = lazy(() => import('./pages/PageRMSSOP'));
const AddEditZones = lazy(() => import('pages/PageRMSZones/AddEditZones'));
const AddEditAlerts = lazy(() => import('pages/PageRMSAlerts/AddEditAlerts'));
const AddEditNotifications = lazy(() => import('pages/PageRMSNotifications/AddEditNotifications'));
const IncIbrsScreen = lazy(() => import('components/RMSForms/RMSIncidentForm/IncIBRSStep'));

// Records Pages
const AddEditJewelryRecord = lazy(() => import('pages/PageRMSRecords/AddEditJewelryRecord'));
const AddEditItemRecord = lazy(() => import('pages/PageRMSRecords/AddEditItemRecord'));
const AddEditFirearmRecord = lazy(() => import('pages/PageRMSRecords/AddEditFirearmRecord'));
const AddEditVehicleRecord = lazy(() => import('pages/PageRMSRecords/AddEditVehicleRecord'));
const AddEditSubstanceRecord = lazy(() => import('pages/PageRMSRecords/AddEditSubstanceRecord'));
const AddEditStructureRecord = lazy(() => import('pages/PageRMSRecords/AddEditStructureRecord'));
const AddEditEvidenceRecord = lazy(() =>
  import('pages/PageRMSRecords/AddEditEvidenceRecord/index')
);

const EvidenceRecord = lazy(() => import('pages/PageRMSRecords/EvidenceRecord/index'));
const PeopleSearch = lazy(() => import('pages/PageRMSRecords/PeopleSearch'));
const AddressesSearch = lazy(() => import('pages/PageRMSRecords/AddressesSearch'));
const PlacesSearch = lazy(() => import('pages/PageRMSRecords/PlacesSearch'));
const BulletinSearch = lazy(() => import('pages/PageRMSRecords/BulletinSearch'));
const AgenciesSearch = lazy(() => import('pages/PageRMSRecords/AgenciesSearch'));
const NamesSearch = lazy(() => import('pages/PageRMSRecords/NamesSearch'));
const CredentialsSearch = lazy(() => import('pages/PageRMSRecords/CredentialsSearch'));
const EmployeesSearch = lazy(() => import('pages/PageRMSRecords/EmployeesSearch'));
const ImagesSearch = lazy(() => import('pages/PageRMSRecords/ImagesSearch'));
const PhoneNumbersSearch = lazy(() => import('pages/PageRMSRecords/PhoneNumbersSearch'));
const VehicleTowingsSearch = lazy(() => import('pages/PageRMSRecords/VehicleTowingsSearch'));
const ZonesSearch = lazy(() => import('pages/PageRMSRecords/ZonesSearch'));

// Other Pages
const HostingValidation = lazy(() => import('pages/PageRMSHostingValidation/HostingValidation'));
const PageRMSEvents = lazy(() => import('pages/PageRMSEvents'));
const PageRMSCitation = lazy(() => import('pages/PageRMSCitation'));
const PageAddEditCitation = lazy(() => import('pages/PageRMSCitation/PageAddEditCitation'));
const PageRMSIbrs = lazy(() => import('pages/PageRMSIbrs'));
const WarrantsSearch = lazy(() => import('pages/PageRMSRecords/WarrantsSearch'));
const PropertiesSearch = lazy(() => import('pages/PageRMSRecords/PropertiesSearch'));

export default [
  { url: '/home', component: PageMain, layout: RmsLayout },
  { url: '/dashboard/', component: PageDashboard, layout: RmsLayout },

  // Incident Routes
  { url: '/incidents/add/notifications', component: AddEditNotifications, layout: RmsLayout },
  {
    url: '/incidents/edit/:incId/notifications/:propId',
    component: AddEditNotifications,
    layout: RmsLayout,
  },
  { url: '/incidents/add/alerts', component: AddEditAlerts, layout: RmsLayout },
  {
    url: '/incidents/edit/:incId/alerts/:propId',
    component: AddEditAlerts,
    layout: RmsLayout,
  },
  { url: '/incidents/add/zones', component: AddEditZones, layout: RmsLayout },
  {
    url: '/incidents/edit/:incId/zones/:propId',
    component: AddEditZones,
    layout: RmsLayout,
  },
  { url: '/incidents/add/sop', component: AddEditSOP, layout: RmsLayout },
  {
    url: '/incidents/edit/:incId/sop/:propId',
    component: AddEditSOP,
    layout: RmsLayout,
  },
  { url: '/incidents/add/dispositions', component: AddEditDispositions, layout: RmsLayout },
  {
    url: '/incidents/edit/:incId/dispositions/:propId',
    component: AddEditDispositions,
    layout: RmsLayout,
  },
  { url: '/incidents/add/tags', component: AddEditTags, layout: RmsLayout },
  {
    url: '/incidents/edit/:incId/tags/:propId',
    component: AddEditTags,
    layout: RmsLayout,
  },
  { url: '/incidents/add/addresses', component: AddEditAddress, layout: RmsLayout },
  {
    url: '/incidents/edit/:incId/addresses/:addressId',
    component: AddEditAddress,
    layout: RmsLayout,
  },
  {
    url: '/incidents/:incId/actionReport/:actionReportId/add/parties',
    component: AddEditActionReportParties,
    layout: RmsLayout,
  },
  {
    url: '/incidents/edit/:incId/actionReport/:actionReportId/parties/:incPersonId',
    component: AddEditActionReportParties,
    layout: RmsLayout,
  },
  {
    url: '/incidents/add/actionReport',
    component: PageRMSAfterActionReport,
    layout: RmsLayout,
  },
  {
    url: '/incidents/edit/:incId/actionReport/:actionReportId',
    component: PageRMSAfterActionReport,
    layout: RmsLayout,
  },
  { url: '/incidents/add/parties', component: AddEditParties, layout: RmsLayout },
  {
    url: '/incidents/edit/:incId/parties/:incPersonId',
    component: AddEditParties,
    layout: RmsLayout,
  },
  { url: '/incidents/add/suspects', component: AddEditParties, layout: RmsLayout },
  {
    url: '/incidents/edit/:incId/suspects/:incPersonId',
    component: AddEditParties,
    layout: RmsLayout,
  },
  { url: '/incidents/add/item', component: AddEditItem, layout: RmsLayout },
  { url: '/incidents/edit/:incId/item/:propId', component: AddEditItem, layout: RmsLayout },
  { url: '/incidents/add/firearm', component: AddEditFirearm, layout: RmsLayout },
  { url: '/incidents/edit/:incId/firearm/:propId', component: AddEditFirearm, layout: RmsLayout },
  { url: '/incidents/add/jewelry', component: AddEditJewelry, layout: RmsLayout },
  { url: '/incidents/edit/:incId/jewelry/:propId', component: AddEditJewelry, layout: RmsLayout },
  { url: '/incidents/add/vehicle', component: AddEditVehicle, layout: RmsLayout },
  { url: '/incidents/edit/:incId/vehicle/:propId', component: AddEditVehicle, layout: RmsLayout },
  { url: '/incidents/add/structure', component: AddEditStructure, layout: RmsLayout },
  {
    url: '/incidents/edit/:incId/structure/:propId',
    component: AddEditStructure,
    layout: RmsLayout,
  },
  { url: '/incidents/add/substance', component: AddEditSubstance, layout: RmsLayout },
  {
    url: '/incidents/edit/:incId/substance/:propId',
    component: AddEditSubstance,
    layout: RmsLayout,
  },
  { url: '/incidents/add/offenses', component: AddEditOffense, layout: RmsLayout },
  {
    url: '/incidents/edit/:incId/offenses/:offenseId',
    component: AddEditOffense,
    layout: RmsLayout,
  },
  {
    url: '/incidents/:incId/evidence/:evidenceId/add/parties',
    component: AddEditEvidenceParties,
    layout: RmsLayout,
  },
  {
    url: '/incidents/:incId/evidence/:evidenceId/edit/parties/:incPersonId',
    component: AddEditEvidenceParties,
    layout: RmsLayout,
  },

  { url: '/incidents/add/evidence', component: AddEditEvidenceRecord, layout: RmsLayout },
  {
    url: '/incidents/edit/:incId/evidence/:evidenceId',
    component: AddEditEvidenceRecord,
    layout: RmsLayout,
  },

  { url: '/incidents/add/narratives', component: AddEditNarratives, layout: RmsLayout },
  {
    url: '/incidents/edit/:incId/narratives/:narrativeId',
    component: AddEditNarratives,
    layout: RmsLayout,
  },
  {
    url: '/incidents/add/domestic-violence',
    component: AddEditDomesticViolence,
    layout: RmsLayout,
  },

  { url: '/incidents/add/missing-person', component: AddEditMissingPerson, layout: RmsLayout },
  {
    url: '/incidents/edit/missing-person/:ptsIncPersonId',
    component: AddEditMissingPerson,
    layout: RmsLayout,
  },
  { url: '/incidents/add', component: AddEditIncident, layout: RmsLayout },
  { url: '/incidents/edit/:incId', component: AddEditIncident, layout: RmsLayout },
  { url: '/incidents/ibrs', component: IncIbrsScreen, layout: RmsLayout },
  { url: '/incidents', component: PageRMSIncidents, layout: RmsLayout },

  // Event Routes
  { url: '/events', component: PageRMSEvents, layout: RmsLayout },

  // Citation Routes
  { url: '/citations/edit/:id', component: PageAddEditCitation, layout: RmsLayout },
  { url: '/citations/add/', component: PageAddEditCitation, layout: RmsLayout },
  { url: '/citations', component: PageRMSCitation, layout: RmsLayout },

  // Records Routes
  {
    url: '/records/evidence/:evidenceId/add/parties',
    component: AddEditEvidenceParties,
    layout: RmsLayout,
  },
  {
    url: '/records/evidence/:evidenceId/edit/parties/:incPersonId',
    component: AddEditEvidenceParties,
    layout: RmsLayout,
  },

  { url: '/records/add/evidence', component: AddEditEvidenceRecord, layout: RmsLayout },
  {
    url: '/records/edit/evidence/:evidenceId',
    component: AddEditEvidenceRecord,
    layout: RmsLayout,
  },

  { url: '/records/add/item', component: AddEditItemRecord, layout: RmsLayout },
  { url: '/records/edit/item', component: AddEditItemRecord, layout: RmsLayout },
  { url: '/records/edit/firearm', component: AddEditFirearmRecord, layout: RmsLayout },
  { url: '/records/add/firearm', component: AddEditFirearmRecord, layout: RmsLayout },
  { url: '/records/add/jewelry', component: AddEditJewelryRecord, layout: RmsLayout },
  { url: '/records/edit/jewelry', component: AddEditJewelryRecord, layout: RmsLayout },
  { url: '/records/edit/vehicle/:vehicleId', component: AddEditVehicleRecord, layout: RmsLayout },
  { url: '/records/add/vehicle', component: AddEditVehicleRecord, layout: RmsLayout },
  { url: '/records/add/substance', component: AddEditSubstanceRecord, layout: RmsLayout },
  { url: '/records/edit/substance', component: AddEditSubstanceRecord, layout: RmsLayout },
  { url: '/records/edit/structure', component: AddEditStructureRecord, layout: RmsLayout },
  { url: '/records/add/structure', component: AddEditStructureRecord, layout: RmsLayout },
  { url: '/persons', component: PeopleSearch, layout: RmsLayout },
  { url: '/names', component: NamesSearch, layout: RmsLayout },
  { url: '/warrants', component: WarrantsSearch, layout: RmsLayout },
  { url: '/addresses', component: AddressesSearch, layout: RmsLayout },
  { url: '/records/:type', component: PageRMSRecords, layout: RmsLayout },
  { url: '/evidence', component: EvidenceRecord, layout: RmsLayout },
  { url: '/places', component: PlacesSearch, layout: RmsLayout },
  { url: '/properties', component: PropertiesSearch, layout: RmsLayout },

  //Others
  { url: '/reports', component: PageRMSReports, layout: RmsLayout },
  { url: '/settings', component: PageRMSSettings, layout: RmsLayout },
  { url: '/hosting-validation', component: HostingValidation, layout: RmsLayout },
  { url: '/ibrs', component: PageRMSIbrs, layout: RmsLayout },
  { url: '/search/bulletin', component: BulletinSearch, layout: RmsLayout },
  { url: '/search/agencies', component: AgenciesSearch, layout: RmsLayout },
  { url: '/search/credentials', component: CredentialsSearch, layout: RmsLayout },
  { url: '/search/employees', component: EmployeesSearch, layout: RmsLayout },
  { url: '/search/images', component: ImagesSearch, layout: RmsLayout },
  { url: '/search/phone-numbers', component: PhoneNumbersSearch, layout: RmsLayout },
  { url: '/search/vehicle-towings', component: VehicleTowingsSearch, layout: RmsLayout },
  { url: '/search/zones', component: ZonesSearch, layout: RmsLayout },
];
