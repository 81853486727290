import React from 'react';
import { Fab, IconButton, createStyles, makeStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
const useStyles = makeStyles((theme) => ({
  fabStyle: {
    padding: '0px',
    marginLeft: '10px',
    backgroundColor: theme.palette.colors.chipBg,
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
}));
function PlusCircleButton(props) {
  const classes = useStyles();
  return (
    <Fab
      color="secondary"
      aria-label="add"
      ToolTip="Add"
      size="small"
      className={classes.fabStyle}
      onClick={props.onClick}
      disabled={props.disabled}
      style={{}}>
      <AddIcon />
    </Fab>
  );
}

export default PlusCircleButton;
