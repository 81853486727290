import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { filterResults } from 'utils/functions';

const useStyles = makeStyles((theme) => ({
  filter: {
    minWidth: 548,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
}));

function AddressHistoryQuickFilterSearch(props) {
  const classes = useStyles();
  const { rows, columns, setFilteredRows, duration } = props;
  const [filter, setFilter] = useState('');

  useEffect(() => {
    if (!rows) return;
    let results = rows;
    if (filter) results = filterResults(filter, rows, columns);

    let durStart = 365,
      durEnd = 0;
    switch (duration) {
      case 'Day':
        durStart = 1;
        break;
      case 'Week':
        durStart = 7;
        break;
      case 'Month':
        durStart = 30;
        break;
      case 'More-Month':
        durEnd = 30;
        break;
      default:
        break;
    }
    const startDate = new Date();
    startDate.setDate(startDate.getDate() - durStart);
    const endDate = new Date();
    endDate.setDate(endDate.getDate() - durEnd);

    let durationFiltered;
    if (duration === 'More-Month')
      durationFiltered = results.filter((row) => new Date(row.Received) < endDate);
    else durationFiltered = results.filter((row) => new Date(row.Received) > startDate);

    setFilteredRows(durationFiltered);

    // eslint-disable-next-line
  }, [filter, rows, duration]);

  const quickFilter = (ev) => {
    const val = ev.target.value;
    setFilter(val);
  };

  return (
    <TextField
      label="Quick filter"
      type="search"
      className={classes.filter}
      variant="outlined"
      size="small"
      onChange={quickFilter}
      value={filter}
    />
  );
}

export default AddressHistoryQuickFilterSearch;
