import store from '../config/configureStore';

import Cookies from 'js-cookie';
import { notify } from './NotifierReducer';
import { getDictionaries } from './DictionaryReducer';
import { updateIfNewVersionAvail, checkForNewVersion, updateApp } from './AppInfoReducer';
import { formatSaveDate } from 'reducers/TimeReducer';
import {
  setOccuredFromDate,
  setOccuredToDate,
  setOccurredTimePeriodValue,
} from 'reducers/ui/UIHomeScreenReducer';
import { resetUserPermissions } from '../reducers/PermissionsReducer';
import { getTimePeriod } from 'reducers/TimeReducer';
import { handleError } from './ErrorReducer';
import { findUnreadUserBulletins } from 'reducers/BulletinReducer';

// User
export const USER_SET_AUTHENTICATED = 'USER/SET_AUTHENTICATED';
export const USER_SET_DATA = 'USER/SET_DATA';
export const USER_SET_AGENCY = 'USER/SET_AGENCY';
export const USER_SET_INCIDENT_REPORT_VERSION = 'USER/SET_INCIDENT_REPORT_VERSION';
export const USER_SET_EVIDENCE_LABEL_REPORT_VERSION = 'USER/SET_EVIDENCE_LABEL_REPORT_VERSION';
export const USER_SET_CLEARANCE_LEVEL = 'USER/SET_CLEARANCE_LEVEL';
export const USER_SET_CLEARANCE_OPTIONS = 'USER/SET_CLEARANCE_OPTIONS';
export const USER_CLEAR_DATA = 'USER/USER_CLEAR_DATA';
const PASSWORD_FIELD_SHOULD_RESET = 'PASSWORD_FIELD_SHOULD_RESET';

let logOutSent = false;

const defaultState = {
  // User
  isAuthenticated: false,
  userData: null,
  userAgency: '',
  incidentReportVersion: '',
  evidenceLabelReportVersion: '',
  userCurrentClearanceLevel: { Level: '', Description: '' },
  userClearanceOptions: [],
};

const filter = {
  incidentFilter: {
    sortBy: '',
    groupBy: '',
    incidentListCurrentView: 'incidentCardView',
    expandOrNotValue: false,
    occuredFrom: null,
    occuredTo: null,
    occurredTimePeriod: '',
    receivedTimePeriod: '',
    receivedFrom: null,
    receivedTo: null,
    quickFilter: '',
    ptsUserIdShareFilter: null,
    filterForm: {
      placeOrAddress: '',
      lowBlock: '',
      highBlock: '',
      predirectional: '',
      streetName: '',
      postdirectional: '',
      streetType: '',
      unitType: '',
      unitNumber: '',
      city: '',
      zone: '',
      partyType: '',
      partyName: '',
      lastName: '',
      firstName: '',
      middleName: '',
      contactMethod: '',
      contactInfo: '',
      cadEventNumber: '',
      status: '',
      tagType: '',
      tagValue: '',
      offense: '',
      disposition: '',
    },
    gridFilters: {
      rowFilterModel: {
        items: [],
        linkOperator: 'and',
      },
      columnVisibleModel: {
        actions: true,
        address: true,
        agency: true,
        assignedOfficer: true,
        eventType: true,
        incidentNumber: true,
        lastUpdated: true,
        occurredDate: true,
      },
      pinnedColumns: {
        left: [],
        right: [],
      },
      sortingModels: [],
      groupFilterKey: '',
    },
  },
};

export const saveUserSettings = async () => {
  const state = store.store.getState();
  const client = state.websocket.websocket;

  const { incidentFilter } = state.uiHomeScreen;
  const settings = { incidentFilter: incidentFilter };
  const jsonValue = JSON.stringify(settings);
  let userSettings = [];
  let response;

  try {
    if (!client) {
      return;
    }

    const service = client.service('user-settings');
    service.timeout = 20000;

    if (state.user.userData?.user.UserSettings) {
      userSettings = JSON.parse(state.user.userData?.user.UserSettings);
      let ptsUserSettingID;

      userSettings.map((setting) => {
        if (setting.SettingApp === 'RMS' || setting.ApplicationName === 'RMS') {
          setting.JsonValue = jsonValue;
          ptsUserSettingID = setting.ptsUserSettingID;
        }

        return setting;
      });

      state.user.userData.user.UserSettings = JSON.stringify(userSettings);

      response = await service.patch(ptsUserSettingID, {
        JsonValue: jsonValue,
      });
    }
  } catch (err) {
    console.log('error', err);
  }
};

export const sendPassResetLink = (email) => {
  const client = store.store.getState().websocket.websocket;
  if (!client) {
    return;
  }
  const service = client.service('pass-reset');
  // service.timeout = 40000;

  return service.create({
    action: 'pass-reset',
    email,
    ResetMethod: 'email',
    AppName: 'RMS',
    RedirectTo: process.env.REACT_APP_PUBLIC_URL + '/Reset-password',
  });
};

export const validateResetPassHash = (hash) => {
  const client = store.store.getState().websocket.websocket;
  if (!client) {
    return;
  }
  const service = client.service('pass-reset');
  // service.timeout = 40000;
  return service.get(hash);
};

export const resetPass = (hash, newPass) => {
  const client = store.store.getState().websocket.websocket;
  if (!client) {
    return;
  }
  const service = client.service('pass-reset');
  // service.timeout = 40000;
  return service.patch(hash, { newPass, AppName: 'RMS' });
};

export const setPasswordFieldShouldReset = (flag = false) => {
  return (dispatch) => {
    dispatch({ type: PASSWORD_FIELD_SHOULD_RESET, payload: flag });
  };
};

export const submitLoginForm = (username, password, reAuthUser) => {
  const client = store.store.getState().websocket.websocket;
  return async (dispatch) => {
    try {
      let user = null;
      if (reAuthUser) {
        user = reAuthUser;
      } else {
        user = await client.authenticate({
          strategy: 'local',
          username: username,
          password: password,
          AppName: 'RMS',
        });
      }

      if (user) {
        let rmsSetting;

        if (user.user.UserSettings) {
          const settings = JSON.parse(user.user.UserSettings);

          rmsSetting = settings.filter(
            (setting) => setting.ApplicationName === 'RMS' || setting.SettingApp === 'RMS'
          );
        }

        const service = client.service('user-settings');
        service.timeout = 20000;

        if (user.user.UserSettings === null || rmsSetting.length === 0) {
          const { fromDate, toDate } = getTimePeriod('Last 7 Days');

          let changedFromDate = formatSaveDate(fromDate);
          let changedToDate = formatSaveDate(toDate);

          filter.incidentFilter.occurredTimePeriod = 'Last 7 Days';
          filter.incidentFilter.occuredFrom = changedFromDate;
          filter.incidentFilter.occuredTo = changedToDate;

          dispatch(setOccuredFromDate(changedFromDate));
          dispatch(setOccuredToDate(changedToDate));
          dispatch(setOccurredTimePeriodValue('Last 7 Days'));

          let createObject = {
            ptsUserID: user.user.ptsUserID,
            ApplicationName: 'RMS',
            JsonValue: JSON.stringify(filter),
          };

          await service.create(createObject).then((res) => {
            let userSettings = [];

            createObject.ptsUserSettingID = res.ptsUserSettingID;
            userSettings.push(createObject);

            user.user.UserSettings = JSON.stringify(userSettings);

            logOutSent = false;
            dispatch({
              type: USER_SET_AGENCY,
              payload: user.user.AgencyID !== null ? user.user.AgencyID : '',
            });
            dispatch({ type: USER_SET_DATA, payload: user });
            dispatch({ type: USER_SET_AUTHENTICATED, payload: true });
            dispatch(updateIfNewVersionAvail());
          });
        } else {
          dispatch({
            type: USER_SET_AGENCY,
            payload: user.user.AgencyID !== null ? user.user.AgencyID : '',
          });
          dispatch({ type: USER_SET_DATA, payload: user });
          dispatch({ type: USER_SET_AUTHENTICATED, payload: true });
          // dispatch(updateIfNewVersionAvail());
        }
        await getUserClearanceLevel(user.user.ptsClearanceLevelID);
      }
    } catch (error) {
      console.log(error);
      const { code, message } = error;
      switch (code) {
        case 408:
          dispatch(
            notify(
              'It takes too long to connect to the server. Please check your connection and try again.',
              'warning'
            )
          );
          break;
        case 401:
          if (message) {
            dispatch({ type: PASSWORD_FIELD_SHOULD_RESET, payload: true });
            dispatch(notify(message, 'error'));
          } else {
            dispatch(notify('Login failed. Please try again.', 'error'));
          }
          break;
        default:
          if (message) {
            dispatch(notify(message, 'error'));
          } else {
            dispatch(
              notify(`Unknown error ${code}. Please check you connection and try again.`, 'error')
            );
          }
      }
      dispatch({ type: USER_SET_AUTHENTICATED, payload: false });
    }
  };
};

export const setUserAuthenticated = (state) => {
  const client = store.store.getState().websocket.websocket;
  return (dispatch) => {
    if (client) {
      if (state === false) {
        dispatch(logOut());
      }
    }
    dispatch({ type: USER_SET_AUTHENTICATED, payload: state });
  };
};

export const setUserData = (data) => {
  return (dispatch) => {
    dispatch({ type: USER_SET_DATA, payload: data });
  };
};

export const setUserAgency = (agency) => {
  return (dispatch) => {
    dispatch({ type: USER_SET_AGENCY, payload: agency });
    dispatch(getDictionaries('rms'));
    dispatch(findUnreadUserBulletins());
  };
};

export const setAgencyReportsVersion = (agency) => {
  const { Agencies } = store.store.getState().dictionary;

  let incReportVersion = null,
    eviReportVersion = null;

  if (Agencies?.length) {
    incReportVersion = Agencies.find((a) => a.AgencyID === agency)?.IncidentReportVersion || null;
    eviReportVersion =
      Agencies.find((a) => a.AgencyID === agency)?.EvidenceLabelReportVersion || null;
  }

  return (dispatch) => {
    dispatch({ type: USER_SET_INCIDENT_REPORT_VERSION, payload: incReportVersion });
    dispatch({ type: USER_SET_EVIDENCE_LABEL_REPORT_VERSION, payload: eviReportVersion });
  };
};

export const setUserClearanceLevel = (clearanceID) => {
  return (dispatch) => {
    dispatch({ type: USER_SET_CLEARANCE_LEVEL, payload: clearanceID });
  };
};
export const setUserClearanceOptions = (clearanceOptions) => {
  return (dispatch) => {
    dispatch({ type: USER_SET_CLEARANCE_OPTIONS, payload: clearanceOptions });
  };
};
export const getUserClearanceLevel = async (clearanceID = 3) => {
  const client = store.store.getState().websocket.websocket;
  if (!client) return;
  const service = client.service('settings-sec-clearance-level');
  const res = await service.find();
  let clearanceValue = res.find((item) => item.ptsClearanceLevelID === clearanceID);

  if (!clearanceValue) clearanceValue = res.find((item) => item.Level === '0');

  const agency = store.store.getState().user.userAgency;
  const userDefaultAgency = store.store.getState().user.userData?.user.AgencyID;
  if (agency === userDefaultAgency) {
    const clearanceOptions = res.filter(
      (item) => parseInt(item.Level) <= parseInt(clearanceValue.Level)
    );
    store.store.dispatch(setUserClearanceLevel(clearanceValue));
    store.store.dispatch(setUserClearanceOptions(clearanceOptions));
  } else {
    const clearanceOption = res.reduce((min, current) =>
      parseInt(current.Level) < parseInt(min.Level) ? current : min
    );
    store.store.dispatch(setUserClearanceOptions([clearanceOption]));
    store.store.dispatch(setUserClearanceLevel(clearanceOption));
  }
};

export const logOut = () => async (dispatch) => {
  if (logOutSent) return;
  logOutSent = true;
  try {
    const state = store.store.getState();
    const { appInfo } = state;
    const client = state.websocket.websocket;

    if (client) {
      client
        .service('authenticate')
        .remove('RMS')
        .catch((e) => {});
      client.logout();
    } else {
      window.localStorage.removeItem('auth');
    }

    dispatch({ type: USER_CLEAR_DATA });
    dispatch({ type: USER_SET_AUTHENTICATED, payload: false });
    dispatch(resetUserPermissions());
    // check if new version of app is available and log out if so
    const { registration } = appInfo;
    if (registration) {
      updateApp(registration);
    } else {
      const { buildNo } = appInfo;
      const newVer = await checkForNewVersion();
      if (buildNo && buildNo !== newVer?.build) window.location.reload();
    }
  } catch (err) {
    console.log(err);
  }
};

export const getUserSettings = () => {
  return async (dispatch) => {
    try {
      const state = store.store.getState();
      const client = state.websocket.websocket;

      const service = client.service('user-settings');
      service.timeout = 20000;

      let localUserData = '';
      let userId = '';
      if (state.user.userData?.user.ptsUserID) {
        userId = state.user.userData?.user.ptsUserID;
        localUserData = state.user.userData;
        userId = parseInt(userId);
        const result = await service.find({
          query: {
            ptsUserID: userId,
            ApplicationName: 'RMS',
            IsDeleted: 0,
          },
        });

        if (result && result.data) {
          const jsonValue = JSON.stringify(result.data);
          localUserData = {
            ...localUserData,
            user: { ...localUserData.user, UserSettings: jsonValue },
          };
          dispatch({ type: USER_SET_DATA, payload: localUserData });
        }
      }
    } catch (error) {
      dispatch(handleError(error));
    }
  };
};

export const authorizeExternalApp = () => {
  const isProduction = process.env.NODE_ENV !== 'development';
  const auth = window.localStorage.getItem('auth');
  const domain = window.location.hostname;
  const parentDomain = domain.substr(domain.indexOf('.') + 1);
  Cookies.set('auth', auth, {
    expires: new Date(new Date().getTime() + 2500),
    path: '/',
    domain: parentDomain,
    secure: isProduction,
  });
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    // User
    case USER_SET_AUTHENTICATED:
      return {
        ...state,
        isAuthenticated: action.payload,
      };
    case USER_SET_DATA:
      return {
        ...state,
        userData: action.payload,
      };
    case USER_SET_AGENCY:
      return {
        ...state,
        userAgency: action.payload,
      };
    case USER_SET_INCIDENT_REPORT_VERSION:
      return {
        ...state,
        incidentReportVersion: action.payload,
      };
    case USER_SET_EVIDENCE_LABEL_REPORT_VERSION:
      return {
        ...state,
        evidenceLabelReportVersion: action.payload,
      };
    case USER_SET_CLEARANCE_LEVEL:
      return {
        ...state,
        userCurrentClearanceLevel: action.payload,
      };
    case USER_SET_CLEARANCE_OPTIONS:
      return {
        ...state,
        userClearanceOptions: action.payload,
      };
    case PASSWORD_FIELD_SHOULD_RESET:
      return {
        ...state,
        passwordFieldShouldReset: action.payload,
      };
    case USER_CLEAR_DATA:
      return defaultState;
    default:
      break;
  }
  return state;
}
