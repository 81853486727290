import SimpleSchema from 'simpl-schema';
import moment from 'moment';

import store from '../../config/configureStore';
import { groupBOffenses } from 'simpl-schema-validation/CategoryCodes/groupBOffenses';
import { getCategoryOfCode, getDataOfDictionaryOptions } from 'utils/functions';

SimpleSchema.setDefaultMessages({
  messages: {
    en: {
      'NIBRS-2019.2-902': 'Arrest Date is required.',
      'NIBRS-2019.2-164': 'Arrest Date cannot be earlier than the Incident Date/Hour.',
      'NIBRS-2019.2-170': 'The Arrestee Armed With code is required for each Arrestee.',
      'NIBRS-2019.2-166': 'The Arrestee Subject Count is required when an Arrestee is provided.',
      'NIBRS-2019.2-177':
        'When the Age of the Arrestee is under 18, the Juvenile Disposition of Arrestee Under 18 must be provided.',
      'NIBRS-2019.2-181':
        'When the Age of the Arrestee is 18 or older, then then Juvenile Disposition of Arrestee Under 18 must be omitted.',
      'NIBRS-2019.2-165': 'Arrest Category must be provided when the Arrest activity is provided.',
      'NIBRS-2019.2-171':
        'A maximum of two Arrestee Armed With codes can be provided for each Arrestee.',
      'NIBRS-2019.2-173':
        'For each Arrestee Segment and the Arrestee Armed With Code is 01 - Unarmed, then no other Armed With Code can be provided.',
      'NIBRS-2019.2-172': 'For all Arrestee Segments, the Arrestee Armed With Code must be unique.',
      'NIBRS-2019.2-168':
        'The Arrest Offense Code of 09C - Justifiable Homicide is invalid arrest offense.',
      'NIBRS-2019.2-169': 'The Arrest Offense Code must be a valid Group B Offense Code.',
      'NIBRS-2019.2-167':
        'The Arrest Offense Code is required when an Arrest Activity is provided.',
    },
  },
});

export const arresteesSchema = new SimpleSchema({
  offenseDescription: {
    type: String,
    optional: true,
    custom() {
      // NIBRS-2019.2-167: Arrest Offense Code
      if (!this.value) {
        return 'NIBRS-2019.2-167';
      }

      // NIBRS-2019.2-168: Arrest Offense Code
      if (this.value === '09C') {
        return 'NIBRS-2019.2-168';
      }

      // NIBRS-2019.2-169: Arrest Offense Code
      const offenseFBICode = this.obj.currentOffense.values?.statuteDetails?.FBICode;
      const arrestOffenseFBICode = getDataOfDictionaryOptions(
        'statuteFBICodes',
        'Description',
        'FBICode',
        this.value
      );

      if (groupBOffenses.includes(offenseFBICode)) {
        if (!groupBOffenses.includes(arrestOffenseFBICode)) {
          return 'NIBRS-2019.2-169';
        }
      }
    },
  },

  arrestDate: {
    type: Date,
    label: 'NIBRS-2019.2-164: Arrest Date',
    optional: true,
    custom() {
      // NIBRS-2019.2-902: Arrest date
      if (!this.value) {
        return 'NIBRS-2019.2-902';
      }
      let incidentOccuredDate = moment(this.obj.incident.values.occurred).format(
        'YYYY-MM-DD HH:mm:ss'
      );
      let arrestDate = moment(this.value).format('YYYY-MM-DD HH:mm:ss');

      if (new Date(arrestDate) < new Date(incidentOccuredDate)) {
        return 'NIBRS-2019.2-164';
      }
    },
  },

  arresteeArmedWith: {
    type: String,
    label: 'NIBRS-2019.2-170: Arrestee Armed With',
    optional: true,
    custom() {
      // NIBRS-2019.2-170: Arrestee Armed With
      if (!this.value || !JSON.parse(this.value)?.length) {
        return 'NIBRS-2019.2-170';
      }

      if (!this.value) return;
      const parsedValues = JSON.parse(this.value);
      if (!parsedValues) return;

      // NIBRS-2019.2-171: Arrestee Armed With
      if (parsedValues.length > 2) {
        return 'NIBRS-2019.2-171';
      }

      // NIBRS-2019.2-173: Arrestee Armed With
      let arresteeArmedWithCodes = [];
      const codeArresteeArmed = store.store.getState().dictionary.codeArresteeArmed;
      parsedValues.forEach((value) => {
        arresteeArmedWithCodes.push(getCategoryOfCode(codeArresteeArmed, value));
      });

      if (arresteeArmedWithCodes.includes('01') && parsedValues.length > 1) {
        return 'NIBRS-2019.2-173';
      }

      // NIBRS-2019.2-172: Arrestee Armed With
      let values = [];
      parsedValues.forEach((item) => {
        if (!values.includes(item)) {
          values.push(item);
        }
      });
      if (values.length < parsedValues.length) {
        return 'NIBRS-2019.2-172';
      }
    },
  },

  arresteeSegmentsIndicator: {
    type: String,
    label: 'NIBRS-2019.2-166: Arrestee Segments',
    optional: true,
    custom() {
      if (!this.value) {
        return 'NIBRS-2019.2-166';
      }
    },
  },

  juvenileDisposition: {
    type: String,
    optional: true,
    custom() {
      // NIBRS-2019.2-177: Arrestee Age
      if (this.obj.arresteeParty.personDetail.values.age < 18) {
        if (!this.value) {
          return 'NIBRS-2019.2-177';
        }
      }
      // NIBRS-2019.2-181: Disposition of Arrestee Under 18
      if (this.obj.arresteeParty.personDetail.values.age >= 18) {
        if (this.value) {
          return 'NIBRS-2019.2-181';
        }
      }
    },
  },

  arrestType: {
    type: String,
    optional: true,
    custom() {
      // NIBRS-2019.2-165: Arrest Type
      if (!this.value) {
        return 'NIBRS-2019.2-165';
      }
    },
  },
});
