import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Button, makeStyles, Box } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';

import Dialog from 'components/RMSDialog';
import { formatSaveData, gridStyle } from 'utils/formStyles';

import PersonLookup2 from 'components/RMSPersonLookup/PersonLookup2';
import Dictionary from 'components/RMSAutoComplete/RMSDictonary';
import RMSCheckbox from 'components/RMSCheckbox';
import BackDatedRecordHistory from 'components/BackDatedRecordHistory';

import { closePersonAssociate, closePersonFeature } from 'reducers/PersonDialogsReducer';
import { createPeopleAssociate, updatePeopleAssociate } from 'reducers/SearchReducer';
import { hideSpinner, showSpinner } from 'reducers/UiReducer';
import { notifyDataUpdate } from 'reducers/DataUpdateReducer';
import { handleError } from 'reducers/ErrorReducer';

const useStyles = makeStyles((theme) => ({
  item: gridStyle(200, 800),
  closeButton: { color: theme.button.color },
}));

function PeopleAssociateDialog(props) {
  const classes = useStyles();
  const { data } = props;
  const { ptsPersonID } = data;
  const dispatch = useDispatch();
  const [ptsAssociateID, setPtsAssociateID] = useState();
  const [associate, setAssociate] = useState(null);
  const [Relationship, setRelationship] = useState(null);
  const [RelationshipForAssociate, setRelationshipForAssociate] = useState(null);
  const [IsKeepSeparate, setIsKeepSeparate] = useState(false);

  const [recordHistoryData, setRecordHistoryData] = useState({
    shouldVisible: true,
    isDisabled: true,
    createUpdateInfo: {},
  });
  const [showRecordHistory, setShowRecordHistory] = useState(false);

  useEffect(() => {
    if (data?.ptsAssociateID && ptsPersonID) {
      setRecordHistoryData({
        shouldVisible: true,
        isDisabled: !data.Created,
        createUpdateInfo: {
          created: data.Created,
          createdBy: data.CreatedBy,
          updated: data.Updated,
          updatedBy: data.UpdatedBy,
        },
      });

      setPtsAssociateID(data.ptsAssociateID);
      setAssociate({ ptsPersonID: data.ptsAssociateID });
      setRelationship(data.Relationship);
      setIsKeepSeparate(data.IsKeepSeparate);
      setRelationshipForAssociate(data.RelationshipForAssociate);
    }
  }, [data]);

  const close = () => {
    dispatch(closePersonAssociate());
  };
  const save = async () => {
    const associateData = formatSaveData({
      ptsPersonID: ptsPersonID,
      IsKeepSeparate,
      Relationship,
      RelationshipForAssociate,
      ptsAssociateID: associate?.ptsPersonID,
    });
    try {
      if (ptsPersonID && data?.ptsAssociateID) {
        dispatch(showSpinner());
        await updatePeopleAssociate(
          { ptsPersonID: ptsPersonID, ptsAssociateID: ptsAssociateID },
          associateData
        );
      } else {
        await createPeopleAssociate(associateData);
      }
      dispatch(notifyDataUpdate({ type: 'person-associate', data: {} }));
      dispatch(closePersonAssociate());
    } catch (err) {
      dispatch(handleError(err));
    } finally {
      dispatch(hideSpinner());
    }
  };

  const handleCloseRecordHistoryDialog = () => setShowRecordHistory(false);

  const renderActions = () => {
    return (
      <>
        {recordHistoryData?.shouldVisible && (
          <Box className="">
            <BackDatedRecordHistory
              showRecordHistory={showRecordHistory}
              setShowRecordHistory={setShowRecordHistory}
              disabled={recordHistoryData?.isDisabled}
              handleCloseRecordHistoryDialog={handleCloseRecordHistoryDialog}
              createUpdateInfo={recordHistoryData?.createUpdateInfo}></BackDatedRecordHistory>
          </Box>
        )}
        <Button
          color="primary"
          variant="contained"
          autoFocus
          onClick={save}
          disabled={!associate || !Relationship}>
          <SaveIcon /> Save
        </Button>
        <Button color="primary" onClick={close} className={classes.closeButton}>
          <CloseIcon /> Close
        </Button>
      </>
    );
  };

  const renderAssociationForm = () => {
    return (
      <div>
        <PersonLookup2
          disabled={!!data?.ptsAssociateID}
          className={classes.item}
          ptsPersonID={ptsAssociateID}
          onChange={(val) => setAssociate(val)}
        />

        <Dictionary
          options="codeAssociateRelationships"
          value={Relationship}
          label="Relationship"
          className={classes.item}
          onChange={(ev, val) => setRelationship(val)}
          compact
        />
        <Dictionary
          options="codeAssociateRelationships"
          value={RelationshipForAssociate}
          label="Relationship For Associated Person"
          className={classes.item}
          onChange={(ev, val) => setRelationshipForAssociate(val)}
          compact
        />
        <div className={classes.item}>
          <RMSCheckbox
            selectedCheckbox={IsKeepSeparate}
            handleFormChange={(ev, val) => setIsKeepSeparate(val)}
            label="Keep Separate"
          />
        </div>
      </div>
    );
  };

  return (
    <Dialog toolbar title="Associate" actions={renderActions()} onClose={close}>
      {renderAssociationForm()}
    </Dialog>
  );
}

export default PeopleAssociateDialog;
