/* eslint-disable react-hooks/exhaustive-deps */
// import React, { useState, useEffect } from 'react';
import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import EventCard from './EventCard';
import EditEventTabs from './EditEventTabs';

const useStyles = makeStyles((theme) => ({
  contentWrap: {
    minWidth: 800,
    minHeight: 600,
  },
  evNotFound: {
    color: theme.palette.error.main,
  },
  root: {
    marginTop: 1,
  },
  seconderyBar: {
    display: 'flex',
    width: '100%',
    justifyContent: 'spaces-between',
    alignItems: 'center',
    '& > div': {
      '&:first-child': {
        width: 'calc(50% - 45px)',
      },
      '&:nth-child(2)': {
        textAlign: 'center',
        width: 90,
        flex: '1 0 90px',
      },
      '&:last-child': {
        textAlign: 'right',
        width: 'calc(50% - 45px)',
      },
    },
  },
}));

function EditEvent(props) {
  const classes = useStyles();
  const { event, incident, ptsEventID = null } = props;

  return (
    <div className={classes.contentWrap}>
      <EventCard event={event} />
      <EditEventTabs event={event} ptsEventID={incident.ptsEventId || ptsEventID} />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    wsClient: state.websocket,
    incident: state.incident.incident,
  };
};

export default connect(mapStateToProps, {})(EditEvent);
