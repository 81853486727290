import { Button, makeStyles } from '@material-ui/core';
import Dialog from 'components/RMSDialog';
import moment from 'moment';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Row, RowInner } from 'utils/formStyles';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import { closePersonTagInfo } from 'reducers/PersonDialogsReducer';
import { showSpinner, hideSpinner } from 'reducers/UiReducer';
import { removePersonTags } from 'reducers/SearchReducer';
import { handleError } from 'reducers/ErrorReducer';
import { notifyDataUpdate } from 'reducers/DataUpdateReducer';

const useStyles = makeStyles((theme) => ({
  closeButton: { color: theme.button.color },
}));

function RMSPersonTagsDialog(props) {
  const [open, setOpen] = useState(false);

  const classes = useStyles();
  const { data } = props;
  const dispatch = useDispatch();

  const close = () => {
    dispatch(closePersonTagInfo());
  };

  const onDelete = () => {
    setOpen(true);
  };

  const renderActions = () => {
    return (
      <div>
        <Button
          color="primary"
          variant="contained"
          autoFocus
          onClick={onDelete}
          disabled={!data.ptsTagID}>
          <DeleteIcon /> Delete
        </Button>
        <Button color="primary" onClick={close} className={classes.closeButton}>
          <CloseIcon /> Close
        </Button>
      </div>
    );
  };

  const renderDeleteActions = () => {
    return (
      <div>
        <Button color="primary" variant="contained" autoFocus onClick={handleConfirmDelete}>
          Yes
        </Button>
        <Button color="primary" onClick={handleCancel} className={classes.closeButton}>
          Cancel
        </Button>
      </div>
    );
  };

  const handleCancel = () => {
    setOpen(false);
  };
  const handleConfirmDelete = () => {
    setOpen(false);
    let query = {
      ptsTagID: data.ptsTagID,
      IsDeleted: false,
    };

    dispatch(showSpinner());
    removePersonTags(query)
      .then(() => {})
      .catch((error) => {
        dispatch(handleError(error));
      })
      .finally(() => {
        dispatch(hideSpinner());
        data.setData();
        dispatch(notifyDataUpdate({ type: 'Person', data: {} }));
      });
    dispatch(closePersonTagInfo());
  };

  return (
    <>
      <Dialog toolbar title={'Person Tag Information'} actions={renderActions()} onClose={close}>
        <Row>
          <RowInner>Tag Description: {data.typeDescription}</RowInner>
          <RowInner>Description: {data.description}</RowInner>
          <RowInner>Entered By: {data.updatedBy}</RowInner>
          <RowInner>Date Entered: {moment(data.updated).format('MM/DD/YYYY, h:mm a')}</RowInner>
        </Row>
      </Dialog>
      <Dialog
        open={open}
        onClose={handleCancel}
        toolbar
        title={'Delete Tag'}
        actions={renderDeleteActions()}>
        Are you sure you want to delete this tag?
      </Dialog>
    </>
  );
}

export default RMSPersonTagsDialog;
