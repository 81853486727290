export const crimeAgainstPersonCodes = [
  '09A',
  '09B',
  '09C',
  '100',
  '11A',
  '11B',
  '11C',
  '11D',
  '13A',
  '13B',
  '13C',
  '36A',
  '36B',
  '64A',
  '64B',
  // '90Z',
];

export const crimeAgainstSocietyCodes = [
  '35A',
  '35B',
  '370',
  '39A',
  '39B',
  '39C',
  '39D',
  '40A',
  '40B',
  '40C',
  '520',
  '720',
  '90B',
  '90C',
  '90D',
  '90E',
  '90F',
  '90G',
  '90H',
  '90J',
  // '90Z',
];

export const crimeAgainstPropertyCodes = [
  '200',
  '510',
  '220',
  '250',
  '290',
  '270',
  '210',
  '26A',
  '26B',
  '26C',
  '26D',
  '26E',
  '26F',
  '26G',
  '23A',
  '23B',
  '23C',
  '23D',
  '23E',
  '23F',
  '23G',
  '23H',
  '240',
  '120',
  '280',
  '90A',
  // '90Z',
];

export const kidnappingOffenses = ['100'];

export const drugNarcoticOffenses = ['35A', '35B'];

export const gamblingOffense = ['39B', '39C'];
