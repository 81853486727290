import React from 'react';

function MedicalIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" {...props}>
      <g transform="translate(0.000000,50.000000) scale(0.100000,-0.100000)" stroke="none">
        <path d="M210 385 l0 -65 -55 31 c-31 17 -57 30 -59 28 -15 -20 -36 -55 -36 -61 0 -4 22 -19 50 -34 28 -15 50 -30 50 -34 0 -4 -22 -19 -50 -34 -27 -15 -50 -30 -50 -34 0 -6 21 -41 36 -61 2 -2 28 11 59 28 l55 31 0 -65 0 -65 40 0 40 0 0 66 0 65 56 -31 c30 -18 56 -31 58 -29 16 22 36 55 36 61 0 4 -22 19 -50 35 -27 15 -50 30 -50 33 0 3 23 18 50 33 28 16 50 31 50 35 0 6 -20 39 -36 61 -2 2 -28 -11 -58 -29 l-56 -31 0 65 0 66 -40 0 -40 0 0 -65z" />
      </g>
    </svg>
  );
}

export default MedicalIcon;
