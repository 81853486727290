import store from '../../config/configureStore';
import { incPropertiesSchema } from 'simpl-schema-validation/schema';
import { generateHandleEditOffense } from 'simpl-schema-validation/helpers/generateHandleEdit';
import { validateAllIncProperty } from '.';

export const validateIncProperties = () => {
  const currentState = store.store.getState().incident;
  const {
    properties,
    offensesOffenseForm,
    offenses,
    incProperties,
    selectedProperty,
    currentOffense,
    turnOfValidation,
  } = currentState;
  if (turnOfValidation) return [];

  let validationErrors = [];

  for (let offense of offenses) {
    if (
      offense.offenseDetails.values.excludeOffense ||
      offense.offenseDetails.values?.statuteDetails?.FBICode === '0'
    )
      continue;

    const offensesIncProperties = incProperties.filter(
      (incProp) => incProp.incPropertyDetails.ptsOffenseId === offense.offenseDetails.ptsOffenseId
    );

    let objToValidate = {
      offense: offense.offenseDetails,
      incProperties: offensesIncProperties,
    };

    let incPropertiesValidationContext = incPropertiesSchema.newContext();
    let result = incPropertiesValidationContext.validate(objToValidate, {
      keys: ['propertyItem', 'propertySubstance'],
    });
    if (!result) {
      incPropertiesValidationContext.validationErrors().forEach((error) => {
        validationErrors.push({
          id: offense.offenseDetails.ptsOffenseId,
          ptsOffenseId: offense.offenseDetails.ptsOffenseId,
          idType: 'ptsOffenseId',
          errType: error.type,
          field: error.name,
          stepper: 4,
          screen: 'Offense Property',
          name:
            offense.offenseDetails.values.statuteDetails?.Description +
            ' | ' +
            offense.offenseDetails.values.statuteDetails?.Code,
          message: incPropertiesValidationContext.keyErrorMessage(error.name),
          ptsIncidentId: currentState.ptsIncidentId,
          handleEdit: generateHandleEditOffense(
            currentState.ptsIncidentId,
            offense.offenseDetails.ptsOffenseId,
            4
          ),
          identifier: `${currentState.ptsIncidentId}-${error.type.split('-').pop()}-${
            error.name
          }-OffenseProperty-${offense.offenseDetails.ptsOffenseId}`,
        });
      });
    }
  }

  for (let incProperty of incProperties) {
    let propertyOffense = offenses.find(
      (o) =>
        o.offenseDetails.ptsOffenseId === incProperty.incPropertyDetails.ptsOffenseId &&
        o.offenseDetails.values.excludeOffense === false &&
        o.offenseDetails.values?.statuteDetails?.FBICode !== '0'
    );

    let property = properties.find(
      (p) =>
        p.propertyDetails.incIncidentPropertyId ===
        incProperty.incPropertyDetails.incIncidentPropertyId
    );

    if (!propertyOffense || !property) {
      continue;
    }

    let propertyValue = parseInt(incProperty.incPropertyDetails.values.value);
    propertyValue = isNaN(propertyValue) ? null : propertyValue;

    const offensesIncProperties = incProperties.filter(
      (incProp) =>
        incProp.incPropertyDetails.ptsOffenseId === propertyOffense.offenseDetails.ptsOffenseId
    );

    let objToValidate = {
      status: incProperty.incPropertyDetails.values.status,
      value: propertyValue,
      // propertyDescription: incProperty.incPropertyDetails.values.propertyDescription,
      type: incProperty.incPropertyDetails.values.type,
      property: incProperty.incPropertyDetails,
      offense: propertyOffense?.offenseDetails || offensesOffenseForm,
      incident: currentState.incident,
      originProperty: property.propertyDetails,
      incProperty: incProperty,
      incProperties: offensesIncProperties,
      properties: properties,
      stolenVehicles: incProperty.incPropertyDetails.values.stolenVehicles,
      parentType: property.propertyDetails?.values.parentType?.toLowerCase(),
    };

    let propertiesValidationContext = incPropertiesSchema.newContext();

    let result = propertiesValidationContext.validate(objToValidate, {
      keys: [
        'status',
        'value',
        // 'propertyDescription',
        'propertyDrugType',
        'stolenVehicles',
        'type',
      ],
    });

    if (!result) {
      propertiesValidationContext.validationErrors().forEach((error) => {
        validationErrors.push({
          id: incProperty.incPropertyDetails.ptsPropertyId,
          incIncidentPropertyId: incProperty.incPropertyDetails.incIncidentPropertyId,
          ptsOffenseId: incProperty.incPropertyDetails.ptsOffenseId,
          idType: 'ptsPropertyId',
          errType: error.type,
          field: error.name,
          stepper: 4,
          screen: 'Offense Property',
          name: `${property.propertyDetails.values.parentType}`,
          message: propertiesValidationContext.keyErrorMessage(error.name),
          ptsIncidentId: currentState.ptsIncidentId,
          handleEdit: generateHandleEditOffense(
            currentState.ptsIncidentId,
            incProperty.incPropertyDetails.ptsOffenseId,
            4
          ),
          identifier: `${currentState.ptsIncidentId}-${error.type.split('-').pop()}-${
            error.name
          }-OffenseProperty-${incProperty.incPropertyDetails.ptsOffenseId}-${
            incProperty.incPropertyDetails.incIncidentPropertyId
          }`,
        });
      });
    }
  }

  validationErrors.push(...validateAllIncProperty());
  return validationErrors;
};
