import React from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router';

import PerfectScrollbar from 'react-perfect-scrollbar';
import Tooltip from '@material-ui/core/Tooltip';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { faCar } from '@fortawesome/free-solid-svg-icons';
import {
  Grid,
  Button,
  ListItem,
  ListItemText,
  DialogTitle,
  DialogActions,
  DialogContent,
  // DialogContentText,
  Card,
  Fab,
} from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';

import {
  resetDuplicateList,
  setDuplicateModal,
  setDuplicateFoundBtn,
} from 'reducers/ui/UiModalReducer';
import RMSEditButton from 'components/RMSButtons/RMSEditButton';
import {
  initFormModel,
  setSelectedDuplicateData,
  updateIncAddressForm,
  updateIncAddressIdentifiersForm,
  updateIncCoordinatesForm,
  updateIncPartiesPersonForm,
} from 'reducers/IncReducer';
import RMSPersonInfoCard from 'components/RMSPersonInfoCard';
import RMSCloseButton from '../RMSButtons/RMSCloseButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const useStyles = makeStyles(() => ({
  listItemRoot: {
    minWidth: '500px',
  },
  listStyl: {
    marginBottom: '20px',
  },
  secondaryListItem: {
    width: '400px',
  },
  accordinTypo: {
    width: '100%',
  },
  accordinRoot: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  scrollBar: {
    width: '100%',
    overflowX: 'hidden',
    overflowY: 'scroll',
    paddingRight: '5px',
    maxHeight: '400px',
  },
}));

const RMSDuplicateDialogModal = (props) => {
  /** region Component Variables and Props */
  const classes = useStyles();
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const [personList, setPersonList] = React.useState([]);
  const [vehicleList, setVehicleList] = React.useState([]);
  const [placeList, setPlaceList] = React.useState([]);
  const [addressList, setAddressList] = React.useState([]);
  const [contactAddressList, setContactAddressList] = React.useState([]);
  const [employmentAddressList, setEmploymentAddressList] = React.useState([]);
  const {
    uiDuplicateModalData,
    setDuplicateModal,
    setSelectedDuplicateData,
    updateIncAddressForm,
    updateIncAddressIdentifiersForm,
    updateIncCoordinatesForm,
    setDuplicateFoundBtn,
    updateIncPartiesPersonForm,
    records,
  } = props;
  const {
    duplicateModalMessage,
    duplicateListOfAddress,
    duplicateListOfAddressForContact,
    duplicateListOfAddressForEmployment,
    duplicateListOfPlace,
    duplicateListOfVehicle,
    duplicateListOfPerson,
  } = uiDuplicateModalData;
  const location = useLocation();
  const urlParams = location.pathname.split('/');
  const rootType = urlParams[1];
  /** endregion */
  /** region React Hooks */
  React.useEffect(() => {
    setPersonList([...duplicateListOfPerson]);
  }, [duplicateListOfPerson]);

  React.useEffect(() => {
    setVehicleList(duplicateListOfVehicle);
  }, [duplicateListOfVehicle]);

  React.useEffect(() => {
    setPlaceList(duplicateListOfPlace);
  }, [duplicateListOfPlace]);

  React.useEffect(() => {
    setAddressList(duplicateListOfAddress);
  }, [duplicateListOfAddress]);

  React.useEffect(() => {
    let arr = [...duplicateListOfAddressForContact];
    console.log(arr);
    setContactAddressList([...duplicateListOfAddressForContact]);
  }, [
    uiDuplicateModalData,
    uiDuplicateModalData.duplicateListOfAddressForContact,
    duplicateListOfAddressForContact,
    duplicateListOfAddressForContact.length,
  ]);

  React.useEffect(() => {
    setEmploymentAddressList(duplicateListOfAddressForEmployment);
  }, [duplicateListOfAddressForEmployment]);
  /** endregion */
  /** region Helper Functions */
  const close = () => {
    setOpen(false);
    setDuplicateFoundBtn(false);
    if (records.recordActive) return;
    setDuplicateModal(false);
    resetDuplicateList();
  };

  const handleEdit = (id, entity) => {
    if (entity !== 'vehicleRecord') close();
    if (entity === 'person') {
      history.push(`/records/edit/persons/${id}`);
    } else if (entity === 'address') {
      updateIncAddressForm({
        ...initFormModel,
      });
      updateIncAddressIdentifiersForm(initFormModel);
      updateIncCoordinatesForm(initFormModel);
      history.push(`/records/edit/address/${id}`);
    } else if (entity === 'place') {
      history.push(`/records/edit/place/${id}`);
    } else if (entity === 'vehicle' || entity === 'vehicleRecord') {
      history.push(`/records/edit/vehicle/${id}`);
    }
  };

  const handleSelect = (data) => {
    close();
    setSelectedDuplicateData(data);
  };

  const selectThisPerson = (i, person) => {
    close();

    // let selectedPerson = duplicateListOfPerson[i];

    // let personDetail = {
    //   ptsPersonId: rootType !== 'records' ? selectedPerson.ptsPersonID : undefined,
    //   values: {
    //     ptsPersonId: selectedPerson.ptsPersonID,
    //     firstName: selectedPerson.FirstName,
    //     lastName: selectedPerson.LastName,
    //     middleName: selectedPerson.MiddleName,
    //     suffix: selectedPerson.Suffix,
    //     race: selectedPerson.Race,
    //     sex: selectedPerson.Sex,
    //     ethnicity: selectedPerson.Ethnicity,
    //     age: selectedPerson.Age,
    //     ageRangeIncrement: selectedPerson.AgeRangeIncrement,
    //     eyeColor: selectedPerson.EyeColor,
    //     hairColor: selectedPerson.HairColor,
    //     hairLength: selectedPerson.HairLength,
    //     hairStyle: selectedPerson.HairStyle,
    //     bloodType: selectedPerson.BloodType,
    //     handed: selectedPerson.Handed,
    //     height: selectedPerson.Height,
    //     weight: selectedPerson.Weight,
    //     birthdate: selectedPerson.Born,
    //     ssn: selectedPerson.SSN,
    //     oln: selectedPerson.OLN,
    //   },
    // };

    setSelectedDuplicateData(person);
    // updateIncPartiesPersonForm(personDetail);
  };
  /** endregion */
  return (
    <Card>
      <DialogTitle
        className="mb-2 d-flex justify-content-between align-items-center"
        style={{ color: '#fff', background: '#1976d2' }}
        id="alert-dialog-title">
        {duplicateModalMessage}{' '}
      </DialogTitle>
      <DialogContent>
        <PerfectScrollbar className={classes.scrollBar}>
          {personList.length > 0 && (
            <div className={classes.listStyl}>
              <h6>PERSON</h6>
              {personList.map((p, index) => (
                <ListItem key={p.FirstName} button className={classes.listItemRoot}>
                  <div style={{ width: '750px' }}>
                    <RMSPersonInfoCard data={p} duplicateCheck={true} />
                  </div>
                  <Grid container wrap="nowrap" spacing={2} justify="flex-end">
                    <Grid item className="mt-2">
                      <Tooltip title="Select">
                        <Fab
                          size="small"
                          color="secondary"
                          aria-label="edit"
                          onClick={() => selectThisPerson(index, p)}>
                          <ArrowForwardIcon />
                        </Fab>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </ListItem>
              ))}
            </div>
          )}

          {vehicleList.length > 0 && (
            <div className={classes.listStyl}>
              <h6>VEHICLE</h6>
              {vehicleList.map((v) => (
                <ListItem key={v.ptsVehicleID} button className={classes.listItemRoot}>
                  <div style={{ width: '350px' }}>
                    <ListItemText
                      classes={{
                        secondary: classes.secondaryListItem,
                      }}
                      primary={`VechicleID: ${v.VechicleID || ''}`}
                      secondary={`VIN: ${v.VIN} | Plate Number: ${
                        v.PlateNumber || ''
                      } | Plate State: ${v.PlateState || ''}`}
                    />
                  </div>
                  {!records.recordActive && (
                    <Grid container wrap="nowrap" spacing={2} justify="flex-end">
                      <Grid item className="mt-2">
                        <RMSEditButton onClick={() => handleEdit(v.ptsVehicleID, 'vehicle')} />
                      </Grid>
                    </Grid>
                  )}
                  {records.recordActive && (
                    <Grid container wrap="nowrap" spacing={2} justify="flex-end">
                      <Grid item className="mt-2">
                        <Tooltip title="View This Vehicle">
                          <Fab
                            size="small"
                            color="secondary"
                            onClick={() => handleEdit(v.ptsVehicleID, 'vehicleRecord')}>
                            <FontAwesomeIcon icon={faCar} style={{ fontSize: 20 }} />
                          </Fab>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  )}
                </ListItem>
              ))}
            </div>
          )}

          {placeList.length > 0 && (
            <div className={classes.listStyl}>
              <h6>PLACE</h6>
              {placeList.map((p) => (
                <ListItem key={p.PlaceID} button className={classes.listItemRoot}>
                  <ListItemText
                    classes={{
                      secondary: classes.secondaryListItem,
                    }}
                    primary={`PlaceID: ${p.PlaceID || ''}`}
                    secondary={`Place Name: ${p.PlaceName}`}
                  />
                  <Grid container wrap="nowrap" spacing={2} justify="flex-end">
                    <Grid item className="mt-2">
                      <RMSEditButton onClick={() => handleEdit(p.ptsPlaceID, 'place')} />
                    </Grid>
                  </Grid>
                </ListItem>
              ))}
            </div>
          )}

          {addressList.length > 0 && (
            <div className={classes.listStyl}>
              <h6>ADDRESS</h6>
              {addressList.map((a) => (
                <ListItem key={a.FullAddressText} button className={classes.listItemRoot}>
                  <ListItemText
                    classes={{
                      secondary: classes.secondaryListItem,
                    }}
                    primary={`AddressID: ${a.AddressID || ''}`}
                    secondary={`Full Address: ${a.FullAddressText || ''}`}
                  />
                  <Grid container wrap="nowrap" spacing={2} justify="flex-end">
                    <Grid item className="mt-2">
                      <Tooltip title="Select">
                        <Fab
                          size="small"
                          color="secondary"
                          aria-label="edit"
                          onClick={() => handleSelect(a)}>
                          <ArrowForwardIcon />
                        </Fab>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </ListItem>
              ))}
            </div>
          )}

          {contactAddressList.length > 0 && (
            <div className={classes.listStyl}>
              <h6>ADDRESS (Contact)</h6>
              {contactAddressList.map((a) => (
                <ListItem key={a.FullAddressText} button className={classes.listItemRoot}>
                  <ListItemText
                    classes={{
                      secondary: classes.secondaryListItem,
                    }}
                    primary={`AddressID: ${a.AddressID || ''}`}
                    secondary={`Full Address: ${a.FullAddressText || ''}`}
                  />
                  <Grid container wrap="nowrap" spacing={2} justify="flex-end">
                    <Grid item className="mt-2">
                      <Tooltip title="Select">
                        <Fab
                          size="small"
                          color="secondary"
                          aria-label="edit"
                          onClick={() => handleSelect(a)}>
                          <ArrowForwardIcon />
                        </Fab>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </ListItem>
              ))}
            </div>
          )}

          {employmentAddressList.length > 0 && (
            <div className={classes.listStyl}>
              <h6>ADDRESS (Employment)</h6>
              {employmentAddressList.map((a) => (
                <ListItem key={a.FullAddressText} button className={classes.listItemRoot}>
                  <ListItemText
                    classes={{
                      secondary: classes.secondaryListItem,
                    }}
                    primary={`AddressID: ${a.AddressID || ''}`}
                    secondary={`Full Address: ${a.FullAddressText || ''}`}
                  />
                  <Grid container wrap="nowrap" spacing={2} justify="flex-end">
                    <Grid item className="mt-2">
                      <Tooltip title="Select">
                        <Fab
                          size="small"
                          color="secondary"
                          aria-label="edit"
                          onClick={() => handleSelect(a)}>
                          <ArrowForwardIcon />
                        </Fab>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </ListItem>
              ))}
            </div>
          )}
        </PerfectScrollbar>
      </DialogContent>
      <DialogActions>
        <RMSCloseButton onClick={close} size="20px" />
      </DialogActions>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  uiDuplicateModalData: state.uiModal,
  records: state.records,
});

const mapDispatchToProps = {
  setDuplicateModal,
  setSelectedDuplicateData,
  updateIncAddressForm,
  updateIncCoordinatesForm,
  setDuplicateFoundBtn,
  updateIncPartiesPersonForm,
  updateIncAddressIdentifiersForm,
};

export default connect(mapStateToProps, mapDispatchToProps)(RMSDuplicateDialogModal);
