const SET_LAYOUT_STATE = 'SET_LAYOUT_STATE';

export const setLayoutState = (flag) => {
  return async (dispatch) => {
    return dispatch({ type: SET_LAYOUT_STATE, payload: flag });
  };
};

export default function reducer(
  state = {
    layoutState: 'list',
  },
  action
) {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case SET_LAYOUT_STATE:
      return { ...state, layoutState: action.payload };
  }
  return state;
}
