import React from 'react';

function CrossWalkIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="50"
      viewBox="0 0 50 50"
      {...props}
      preserveAspectRatio="xMidYMid meet">
      <g transform="translate(0.000000,50.000000) scale(0.100000,-0.100000)" stroke="none">
        <path d="M155 456 c-60 -28 -87 -56 -114 -116 -36 -79 -19 -183 42 -249 33 -36 115 -71 167 -71 52 0 134 35 167 71 34 37 63 110 63 159 0 52 -35 134 -71 167 -37 34 -110 63 -159 63 -27 0 -65 -10 -95 -24z m183 -94 c4 -28 -24 -40 -45 -19 -14 13 -14 19 -3 32 18 22 44 15 48 -13z m-130 -30 c4 -28 -24 -40 -45 -19 -14 13 -14 19 -3 32 18 22 44 15 48 -13z m133 -34 c7 -12 21 -30 33 -39 27 -22 14 -36 -20 -20 -15 6 -29 10 -32 7 -2 -3 8 -26 22 -51 25 -42 27 -65 6 -65 -5 0 -16 12 -24 28 l-14 27 -11 -27 c-15 -40 -35 -36 -26 5 3 17 9 50 12 72 l6 40 -26 -23 c-37 -35 -43 -9 -6 32 36 41 63 46 80 14z m-139 -25 c22 -20 24 -45 3 -37 -21 8 -19 -4 7 -53 26 -48 16 -70 -14 -33 -12 14 -23 21 -25 15 -9 -25 -22 -37 -32 -31 -8 5 -6 17 4 43 17 40 20 73 6 73 -5 0 -12 -7 -15 -16 -6 -15 -26 -11 -26 6 0 14 42 50 59 50 8 0 23 -7 33 -17z" />
      </g>
    </svg>
  );
}

export default CrossWalkIcon;
