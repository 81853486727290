import store from '../../config/configureStore';

import { propertiesSchema } from 'simpl-schema-validation/schema';
import { generateHandleEditIncident } from 'simpl-schema-validation/helpers/generateHandleEdit';
import { isOffenseValid } from '../helpers/schemaValidationHelper';

export const validateProperties = () => {
  const currentState = store.store.getState().incident;
  const { properties, incProperties, offenses, turnOfValidation } = currentState;
  if (turnOfValidation) return [];

  let validationErrors = [];

  let objToValidate = { properties, propertySubstance: '' };
  let keys = ['propertySubstance'];

  let propertiesValidationContext = propertiesSchema.newContext();
  let result = propertiesValidationContext.validate(objToValidate, {
    keys,
  });

  if (!result) {
    propertiesValidationContext.validationErrors().forEach((error) => {
      validationErrors.push({
        id: '',
        idType: '',
        errType: error.type,
        field: error.name,
        name: 'Property',
        screen: 'Incident Property',
        stepper: 1,
        message: propertiesValidationContext.keyErrorMessage(error.name),
        ptsIncidentId: currentState.ptsIncidentId,
        propertyType: 'substance',
        handleEdit: generateHandleEditIncident(currentState.ptsIncidentId, 1),
        identifier: `${currentState.ptsIncidentId}-${error.type.split('-').pop()}-${
          error.name
        }-Property`,
      });
    });
  }

  for (let property of properties) {
    incProperties.forEach((incProperty) => {
      if (
        incProperty.incPropertyDetails.incIncidentPropertyId ===
          property.propertyDetails.incIncidentPropertyId &&
        isOffenseValid(incProperty.incPropertyDetails.ptsOffenseId)
      ) {
        let incPropertyOffense = offenses.find(
          (offense) =>
            offense.offenseDetails.ptsOffenseId === incProperty?.incPropertyDetails.ptsOffenseId &&
            offense.offenseDetails.values.excludeOffense === false &&
            offense.offenseDetails.values?.statuteDetails?.FBICode !== '0'
        );

        let objToValidate = {
          incPropertyOffense: incPropertyOffense ? incPropertyOffense.offenseDetails : null,
          incProperty: incProperty ? incProperty.incPropertyDetails : null,
          property: property.propertyDetails,
          parentType: property.propertyDetails.values.parentType,
          substanceQuantity: property.propertyDetails.values.quantity
            ? parseInt(property.propertyDetails.values.quantity)
            : 0,
          quantityMeasure:
            property.propertyType === 'substance'
              ? property.propertyDetails.values.quantityMeasure || ''
              : null,
          quantity: property.propertyDetails.values.quantity
            ? parseInt(property.propertyDetails.values.quantity)
            : 0,
          substanceType: property.propertyDetails.values.substanceType,
          properties,
        };

        let keys = ['substanceQuantity', 'quantityMeasure', 'substanceType', 'quantity'];

        let propertiesValidationContext = propertiesSchema.newContext();
        let result = propertiesValidationContext.validate(objToValidate, {
          keys,
        });

        if (!result) {
          propertiesValidationContext.validationErrors().forEach((error) => {
            const newError = {
              id: property.propertyDetails.incIncidentPropertyId,
              idType: 'incIncidentPropertyId',
              errType: error.type,
              field: error.name,
              screen: 'Property',
              name: property.propertyDetails.values.parentType.toUpperCase(),
              message: propertiesValidationContext.keyErrorMessage(error.name),
              ptsIncidentId: currentState.ptsIncidentId,
              propertyType: property.propertyType,
              parentType: property.propertyDetails.values.parentType,
              incIncidentPropertyId: property.propertyDetails.incIncidentPropertyId,
              identifier: `${currentState.ptsIncidentId}-${error.type.split('-').pop()}-${
                error.name
              }-Property-${property.propertyDetails.incIncidentPropertyId}-${
                property.propertyType
              }`,
            };

            const existError = validationErrors.find((ve) => ve.identifier === newError.identifier);

            !existError && validationErrors.push(newError);
          });
        }
      }
    });
  }

  return validationErrors;
};
