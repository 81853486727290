export const biasMotivationMutuallyExclusiveCodes = {
  RACE_ETHNICITY_ANCESTRY: [
    {
      code: 'ANTIARAB',
      description: '31 | Anti-Arab',
      category: '31',
    },
    {
      code: 'ANTIASIAN',
      description: '14 | Anti-Asian',
      category: '14',
    },
    {
      code: 'ANTIBLACK_AFRICAN AMERICAN',
      description: '12 | Anti-Black or African American',
      category: '12',
    },
    {
      code: 'ANTIWHITE',
      description: '11 | Anti-White',
      category: '11',
    },
    {
      code: 'ANTIHISPANIC_LATINO',
      description: '32 | Anti-Hispanic or Latino',
      category: '32',
    },
    {
      code: 'ANTIMULTIRACIAL GROUP',
      description: '15 | Anti-Multiple Races, Group',
      category: '15',
    },
    {
      code: 'ANTINATIVEHAWAIIAN_OTHERPACIFICISLANDER',
      description: '16 | Anti-Native Hawaiian or Other Pacific Islander',
      category: '16',
    },
    {
      code: 'ANTIOTHER ETHNICITY_NATIONAL ORIGIN',
      description:
        '33 | Anti-Other Race, Ethnicity, Ancestry, or National Origin_race ethnicity bias',
      category: '33',
    },
  ],

  RELIGION: [
    {
      code: 'ANTIJEWISH',
      description: '21 | Anti-Jewish',
      category: '21',
    },
    {
      code: 'ANTICATHOLIC',
      description: '22 | Anti-Catholic',
      category: '22',
    },
    {
      code: 'ANTIPROTESTANT',
      description: '23 | Anti-Protestant',
      category: '23',
    },
    {
      code: 'ANTIISLAMIC',
      description: '24 | Anti-Islamic (Muslim)',
      category: '24',
    },
    {
      code: 'ANTIOTHER RELIGION',
      description: '25 | Anti-Other Religion',
      category: '25',
    },
    {
      code: 'ANTIMULTIRELIGIOUS GROUP',
      description: '26 | Anti-Multiple Religions, Group',
      category: '26',
    },
    {
      code: 'ANTIATHEIST_AGNOSTIC',
      description: '27 | Anti-Atheism/ Agnosticism',
      category: '27',
    },
    {
      code: 'ANTIMORMON',
      description: '28 | Anti-Mormon',
      category: '28',
    },
    {
      code: 'ANTIJEHOVAHWITNESS',
      description: "29 | Anti-Jehovah's Witness",
      category: '29',
    },
    {
      code: 'ANTIEASTERNORTHODOX',
      description: '81 | Anti-Eastern Orthodox (Russian, Greek, Other)',
      category: '81',
    },
    {
      code: 'ANTIOTHER CHRISTIAN',
      description: '82 | Anti-Other Christian',
      category: '82',
    },
    {
      code: 'ANTIBUDDHIST',
      description: '83 | Anti Buddhist',
      category: '83',
    },
    {
      code: 'ANTIHINDU',
      description: '84 | Anti-Hindu',
      category: '84',
    },
    {
      code: 'ANTISIKH',
      description: '85 | Anti-Sikh',
      category: '85',
    },
  ],

  SEXUAL_ORIENTATION: [
    {
      code: 'ANTIMALE HOMOSEXUAL',
      description: '41 | Anti-Gay',
      category: '41',
    },
    {
      code: 'ANTIFEMALE HOMOSEXUAL',
      description: '42 | Anti-Lesbian',
      category: '42',
    },
    {
      code: 'ANTIHOMOSEXUAL',
      description: '43 | Anti-Lesbian, Gay, Bisexual, or Transgender (Mixed Group)',
      category: '43',
    },
    {
      code: 'ANTIHETEROSEXUAL',
      description: '44 | Anti-Heterosexual',
      category: '44',
    },
    {
      code: 'ANTIBISEXUAL',
      description: '45 | Anti-Bisexual',
      category: '45',
    },
  ],

  DISABILITY: [
    {
      code: 'ANTIPHYSICAL DISABILITY',
      description: '51 | Anti-Physical Disability',
      category: '51',
    },
    {
      code: 'ANTIMENTAL DISABILITY',
      description: '52 | Anti-Mental Disability',
      category: '52',
    },
  ],

  GENDER: [
    {
      code: 'ANTIMALE',
      description: '61 | Anti-Male',
      category: '61',
    },
    {
      code: 'ANTIFEMALE',
      description: '62 | Anti-Female',
      category: '62',
    },
  ],

  NONE_UNKNOWN: [
    {
      code: 'ANTIMALE',
      description: '61 | Anti-Male',
      category: '61',
    },
    {
      code: 'ANTIFEMALE',
      description: '62 | Anti-Female',
      category: '62',
    },
    {
      code: 'NONE',
      description: '88 | None (no bias)',
      category: '88',
    },
    {
      code: 'UNKNOWN',
      description: "99 | Unknown (offender's motivation not known)",
      category: '99',
    },
  ],
};
