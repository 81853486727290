import store from '../../config/configureStore';

import { arresteesSchema } from 'simpl-schema-validation/schema';
import { generateHandleEditOffense } from 'simpl-schema-validation/helpers/generateHandleEdit';

export const validateArrestees = () => {
  const currentState = store.store.getState().incident;
  const { offenses, incArrestees, parties, turnOfValidation } = currentState;
  if (turnOfValidation) return [];

  let validationErrors = [];

  for (let incArrestee of incArrestees) {
    let arresteeParty = parties.find(
      (p) => p.ptsIncPersonId === incArrestee.incArresteeDetails.ptsIncPersonId
    );

    if (!arresteeParty) continue;

    let fullName = `${arresteeParty.PersonId} | ${
      arresteeParty.personDetail.values.lastName || ''
    }, ${arresteeParty.personDetail.values.firstName || ''} ${
      arresteeParty.personDetail.values.middleName || ''
    }`;

    const arresteeOffense = offenses.find(
      (offense) =>
        offense.offenseDetails.ptsOffenseId === incArrestee.incArresteeDetails.ptsOffenseId &&
        offense.offenseDetails.values.excludeOffense === false &&
        offense.offenseDetails.values?.statuteDetails?.FBICode !== '0'
    );

    if (!arresteeOffense) continue;

    let objToValidate = {
      arresteeArmedWith: incArrestee.incArresteeDetails.values.arresteeArmedWith,
      arresteeSegmentsIndicator: incArrestee.incArresteeDetails.values.arresteeSegmentsIndicator,
      incident: currentState.incident,
      arresteeParty,
      juvenileDisposition: incArrestee.incArresteeDetails.values.juvenileDisposition,
      arrestType: incArrestee.incArresteeDetails.values.arrestType,
      offenseDescription: incArrestee.incArresteeDetails.values.offenseDescription,
      currentOffense: arresteeOffense.offenseDetails,
    };

    if (incArrestee.incArresteeDetails.values.arrestDate) {
      objToValidate.arrestDate = new Date(incArrestee.incArresteeDetails.values.arrestDate);
    }

    let arresteesValidationContext = arresteesSchema.newContext();
    let result = arresteesValidationContext.validate(objToValidate, {
      keys: [
        'arrestDate',
        'arresteeArmedWith',
        'arresteeSegmentsIndicator',
        'juvenileDisposition',
        'arrestType',
        'offenseDescription',
      ],
    });

    if (!result) {
      arresteesValidationContext.validationErrors().forEach((error) => {
        validationErrors.push({
          id: incArrestee.incArresteeDetails.ptsArresteeId,
          ptsIncPersonId: incArrestee.incArresteeDetails.ptsIncPersonId,
          ptsOffenseId: incArrestee.incArresteeDetails.ptsOffenseId,
          idType: 'ptsArresteeId',
          errType: error.type,
          field: error.name,
          name: fullName,
          screen: 'Offense Arrestee',
          stepper: 5,
          message: arresteesValidationContext.keyErrorMessage(error.name),
          ptsIncidentId: currentState.ptsIncidentId,
          handleEdit: generateHandleEditOffense(
            currentState.ptsIncidentId,
            incArrestee.incArresteeDetails.ptsOffenseId,
            5
          ),
          identifier: `${currentState.ptsIncidentId}-${error.type.split('-').pop()}-${
            error.name
          }-OffenseArrestee-${incArrestee.incArresteeDetails.ptsIncPersonId}-${
            incArrestee.incArresteeDetails.ptsOffenseId
          }`,
        });
      });
    }
  }

  return validationErrors;
};
