import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { sortObjArr } from '../../../../utils/functions';
import PerfectScrollbar from 'react-perfect-scrollbar';

const useStyles = makeStyles((theme) => ({
  routings: {
    maxHeight: '550px',
    overflowY: 'auto',
  },
  EventRouting: {
    textAlign: 'left',
  },
  route: {
    padding: '10px 15px 5px',
    borderBottom: '1px solid #D3D3D3',
    color: theme.palette.type === 'light' ? 'rgba(0, 0, 0, 0.87)' : '#d4d6d7',
    position: 'relative',
    '& p': {
      fontSize: 13,
      marginBottom: 5,
    },
    '&:hover': {
      '& $rowActions': {
        opacity: 1,
      },
    },
  },
  routing: {
    padding: '10px 15px 5px',
    position: 'relative',
    '& p': {
      fontSize: 13,
      marginBottom: 5,
    },
    '&:hover': {
      '& $rowActions': {
        opacity: 1,
      },
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& h4': {
      fontSize: '1em',
      marginBottom: '0.25em',
    },
  },
  actions: {
    padding: '5px 15px',
    textAlign: 'right',
    '& label': {
      marginBottom: 0,
    },
  },
  rowActions: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(3) + 2,
    opacity: 0,
    transition: 'opacity 0.3s',
  },
  selectWrap: {
    padding: '10px 15px 5px',
    width: '100%',
    '& strong': {
      fontWeight: 500,
    },
  },
  recommendations: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(0.5, 2),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
}));

function RoutingTab(props) {
  const classes = useStyles();
  const { sortOption } = props;
  const [data, setData] = useState([]);

  useEffect(() => {
    setData(sortObjArr(props.data, 'Code', sortOption));
  }, [props.data, sortOption]);

  return (
    <div className={classes.routings}>
      <PerfectScrollbar style={{ maxHeight: window.innerHeight - 500 + 'px' }}>
        {data.map(({ AgencyId }) => {
          return (
            <div className={classes.route} key={AgencyId}>
              <div className={classes.header}>
                <h4>{AgencyId}</h4>
              </div>
            </div>
          );
        })}
      </PerfectScrollbar>
    </div>
  );
}

export default RoutingTab;
