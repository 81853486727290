import React from 'react';

function DeafIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" {...props}>
      <g transform="translate(0.000000,50.000000) scale(0.100000,-0.100000)" stroke="none">
        <path d="M214 460 c-11 -4 -34 -24 -51 -43 l-30 -35 -41 41 c-23 23 -45 38 -49 34 -4 -4 86 -101 200 -214 113 -114 210 -204 214 -200 4 4 -21 36 -56 71 l-63 63 28 42 c38 58 44 126 14 180 -34 60 -105 86 -166 61z m117 -56 c38 -34 36 -120 -3 -169 l-21 -27 -47 47 c-39 38 -46 50 -42 76 7 50 73 64 87 18 8 -23 45 -26 45 -3 0 24 -56 74 -83 74 -29 0 -73 -33 -82 -61 -4 -11 -9 -18 -12 -16 -9 10 28 68 50 77 30 13 85 5 108 -16z" />
        <path d="M150 284 c0 -10 9 -25 21 -32 l21 -14 -21 -19 c-12 -11 -21 -28 -21 -39 0 -19 25 -50 40 -50 17 0 22 21 8 37 -10 13 -10 17 0 20 7 3 16 13 22 22 7 14 1 26 -30 56 -33 33 -40 36 -40 19z" />
        <path d="M254 128 c-15 -24 -32 -46 -38 -50 -24 -16 -48 -7 -59 22 -13 35 -47 42 -47 9 0 -28 53 -79 83 -79 33 0 73 33 97 80 17 32 17 40 5 50 -11 9 -19 3 -41 -32z" />
      </g>
    </svg>
  );
}

export default DeafIcon;
