/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';

import { makeStyles } from '@material-ui/core/styles';
import { sortObjArr } from 'utils/functions';
import { getService } from 'reducers/service';
import { handleError } from 'reducers/ErrorReducer';

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
}));

function SOPFilter(props) {
  const { wsClient, label = 'Search SOPs', error, compact, sopValue = null } = props;

  const classes = useStyles();
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [autocompleteOptions, setAutocompleteOptions] = useState([]); // list of search results
  const activeRef = useRef(false);

  useEffect(() => {
    if (sopValue) {
      setValue(sopValue);
    }
  }, [sopValue]);

  useEffect(() => {
    search();
    return () => {
      activeRef.current = false;
    };
  }, []);

  const search = async () => {
    try {
      const service = getService('ptssops');
      service.timeout = 200000;
      let sops = await service.find({
        query: {
          SOPID: {
            // $like: `%${inputValue}%`,
            $ne: null,
          },
          $limit: 1000,
          IsApproved: true,
          IsDeleted: false,
        },
      });
      if (sops.total > 0) {
        setAutocompleteOptions(sortObjArr(sops.data, 'SOPID'));
      }
    } catch (error) {
      props.handleError(error);
    }
  };

  const getOptionLabel = (option) => {
    return option.SOPID;
  };

  const onSelect = async (ev, newValue) => {
    if (newValue?.ptsSOPID) props.selectSOP(newValue.ptsSOPID);
    setValue(newValue);
  };

  const onInputChange = (ev, newInputValue) => {
    setInputValue(newInputValue);
  };

  const renderOption = (option) => {
    return (
      <Grid container alignItems="center">
        {option.SOPID}
      </Grid>
    );
  };

  const getOptionSelected = (option, value) => {
    return option.ptsSOPID === value.ptsSOPID;
  };

  const helperText = compact ? undefined : typeof error === 'string' ? error : ' ';

  return (
    <Autocomplete
      getOptionLabel={getOptionLabel}
      options={autocompleteOptions}
      autoComplete
      includeInputInList
      filterSelectedOptions
      className={clsx(classes.autocomplete, props.className)}
      style={props.style}
      value={value}
      onChange={onSelect}
      onInputChange={onInputChange}
      renderOption={renderOption}
      getOptionSelected={getOptionSelected}
      renderInput={(params) => (
        <TextField
          {...params}
          inputProps={{ ...params.inputProps, autoComplete: 'off' }}
          error={!!error}
          helperText={helperText}
          label={label}
          variant="outlined"
          size="small"
        />
      )}
    />
  );
}

const mapStateToProps = (state) => ({
  wsClient: state.websocket,
  dictionary: state.dictionary,
});

export default connect(mapStateToProps, { handleError })(SOPFilter);
