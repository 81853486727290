import { Fab, Tooltip } from '@material-ui/core';
import React from 'react';
import DownloadIcon from '@material-ui/icons/GetApp';

export default function RMSDownloadButton(props) {
  return (
    <Tooltip title={props.tooltipText}>
      <Fab
        disabled={props.disabled}
        style={{ marginLeft: '10px' }}
        size="small"
        color="secondary"
        aria-label="citation download"
        className={props.className ? props.className : ''}>
        <DownloadIcon onClick={props.onClick} />
      </Fab>
    </Tooltip>
  );
}
