import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';

import { makeStyles, Button, Box } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';

import Checkbox2 from 'components/RMSCheckbox';
import TextField2 from 'components/RMSTextField';
import FormDivider from 'components/RMSFormDivider';
import Dictionary from 'components/RMSAutoComplete/RMSDictonary';
import BackDatedRecordHistory from 'components/BackDatedRecordHistory';
import Autocomplete2 from 'components/RMSAutoComplete/RMSAutocomplete2';

import formStyles, {
  gridStyle,
  Row,
  RowInner,
  ColCard,
  Fills,
  validateVin,
  formatSaveData,
} from 'utils/formStyles';

import { handleError } from 'reducers/ErrorReducer';
import { notifyDataUpdate } from 'reducers/DataUpdateReducer';
import { closeEditVehicle } from 'reducers/PersonDialogsReducer';
import { searchSpecificVehicle, editVehicle } from 'reducers/SearchReducer';

const useStyles = makeStyles((theme) => ({
  ...formStyles,
  item: gridStyle(200, 320),
  field: gridStyle(210, 400),
  btn: {
    marginTop: theme.spacing(3),
    // display: 'block',
    // marginRight: 0,
    marginLeft: '5px',
    '& svg': {
      marginRight: theme.spacing(1),
    },
  },
  wrap: {
    padding: theme.spacing(3),
  },
}));

function EditVehicle(props) {
  const classes = useStyles();
  const { ptsVehicleID, dictionary } = props;
  const { codeVehicleMakes, codeVehicleMakesModels } = dictionary;
  const [Year, setYear] = useState('');
  const [Make, setMake] = useState(null);
  const [Model, setModel] = useState(null);
  const [Style, setStyle] = useState(null);
  const [DoorQuantity, setDoorQuantity] = useState('');
  const [PassengeSafeQty, setPassengeSafeQty] = useState('');
  const [SeatQty, setSeatQty] = useState('');
  const [PrimaryColor, setPrimaryColor] = useState(null);
  const [SecondaryColor, setSecondaryColor] = useState(null);
  const [InteriorColor, setInteriorColor] = useState(null);
  const [Classification, setClassification] = useState(null);
  const [Description, setDescription] = useState('');
  const [Brand, setBrand] = useState(null);
  const [Brander, setBrander] = useState(null);
  const [PropertyID, setPropertyID] = useState('');
  const [VIN, setVIN] = useState('');
  const [VINAnalysisText, setVINAnalysisText] = useState('');
  const [CommercialID, setCommercialID] = useState('');
  const [EmissionInspectionQty, setEmissionInspectionQty] = useState('');
  const [Property, setProperty] = useState(null);
  const [Commercial, setCommercial] = useState(null);
  const [Transmission, setTransmission] = useState(null);
  const [IsCMVIndicator, setIsCMVIndicator] = useState(false);
  const [IsRented, setIsRented] = useState(false);
  const [IsWanted, setIsWanted] = useState(false);
  const [GarageIndicator, setGarageIndicator] = useState(false);
  const [GarageFacility, setGarageFacility] = useState(null);
  const [makesModelsOptions, setMakesModelsOptions] = useState([]);

  const [recordHistoryData, setRecordHistoryData] = useState({
    shouldVisible: true,
    isDisabled: true,
    createUpdateInfo: {},
  });
  const [showRecordHistory, setShowRecordHistory] = useState(false);

  const currentUserName = useSelector((state) => state.user.userData?.user.Username);

  const dispatch = useDispatch();
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const data = await searchSpecificVehicle(ptsVehicleID);
    renderExistingData(data);
  };

  const handleCloseRecordHistoryDialog = () => setShowRecordHistory(false);

  const renderExistingData = (data) => {
    if (!data) return;

    const {
      Year,
      Make,
      Model,
      Brand,
      Brander,
      Classification,
      Commercial,
      CommercialID,
      Description,
      DoorQuantity,
      EmissionInspectionQty,
      GarageFacility,
      GarageIndicator,
      InteriorColor,
      IsCMVIndicator,
      IsRented,
      IsWanted,
      PassengerSafeQty,
      PrimaryColor,
      Property,
      PropertyID,
      SeatQty,
      SecondaryColor,
      Style,
      Transmission,
      VIN,
      VINAnalysisText,
      Created,
      CreatedBy,
      Updated,
      UpdatedBy,
    } = data[0];

    setRecordHistoryData({
      shouldVisible: true,
      isDisabled: !Created,
      createUpdateInfo: {
        created: Created,
        createdBy: CreatedBy,
        updated: Updated,
        updatedBy: UpdatedBy,
      },
    });

    setYear(Year);
    renderExistingMakeModel(Make, Model);
    setStyle(Style);
    setDoorQuantity(DoorQuantity);
    setPassengeSafeQty(PassengerSafeQty);
    setSeatQty(SeatQty);
    setPrimaryColor(PrimaryColor);
    setSecondaryColor(SecondaryColor);
    setInteriorColor(InteriorColor);
    setClassification(Classification);
    setDescription(Description);
    setBrand(Brand);
    setBrander(Brander);
    setPropertyID(PropertyID);
    setVIN(VIN);
    setVINAnalysisText(VINAnalysisText);
    setCommercialID(CommercialID);
    setEmissionInspectionQty(EmissionInspectionQty);
    setProperty(Property);
    setCommercial(Commercial);
    setTransmission(Transmission);
    setIsCMVIndicator(IsCMVIndicator);
    setIsRented(IsRented);
    setIsWanted(IsWanted);
    setGarageIndicator(GarageIndicator);
    setGarageFacility(GarageFacility);
  };

  const renderExistingMakeModel = (Make, Model) => {
    if (!Make) return;
    setMake(codeVehicleMakes.find((m) => m.Code === Make));
    const makesModels = codeVehicleMakesModels
      .filter((mm) => mm.MakeCode === Make)
      .map((m) => {
        return { Code: m.ModelCode, Description: m.ModelDescription };
      });
    setModel(makesModels.find((m) => m.Code === Model) || null);
    setMakesModelsOptions(makesModels);
  };

  const handleSave = async () => {
    if (props.parentPermission && !props?.parentPermission.Edit) {
      dispatch(handleError({ code: 440, message: 'You are not permitted to do this action.' }));
      return;
    }
    const data = formatSaveData({
      Year,
      Make,
      Model,
      Style,
      DoorQuantity,
      PassengeSafeQty,
      SeatQty,
      PrimaryColor,
      SecondaryColor,
      InteriorColor,
      Classification,
      Brand,
      Brander,
      PropertyID,
      VIN,
      VINAnalysisText,
      Commercial,
      CommercialID,
      Property,
      EmissionInspectionQty,
      Transmission,
      IsCMVIndicator,
      IsRented,
      IsWanted,
      GarageFacility,
      GarageIndicator,
      Description,
    });

    try {
      await editVehicle(ptsVehicleID, data);
      props.notifyDataUpdate({ type: 'edit-vehicle', data: { ptsVehicleID } });
      props.closeEditVehicle();
    } catch (err) {
      props.handleError(err, 'Error editing vehicle.');
    }
  };

  const renderYear = () => {
    const onChange = (ev, val) => setYear(val);
    return (
      <TextField2
        className={classes.item}
        label="Year"
        value={Year}
        onChange={onChange}
        type="number"
        min={0}
        max={2100}
      />
    );
  };

  const renderMake = () => {
    const onChange = (ev, val) => {
      setMake(val);
      setModel(null);
      if (val) {
        const makesModels = codeVehicleMakesModels
          .filter((mm) => mm.MakeCode === val.Code)
          .map((m) => {
            return { Code: m.ModelCode, Description: m.ModelDescription };
          });
        setMakesModelsOptions(makesModels);
      } else {
        setMakesModelsOptions(null);
      }
    };
    return (
      <Dictionary
        options="codeVehicleMakes"
        className={classes.item}
        onChange={onChange}
        formType="edit"
        value={Make}
        label="Make"
      />
    );
  };
  const renderModel = () => {
    const onChange = (ev, val) => setModel(val);
    return (
      <Autocomplete2
        options={makesModelsOptions}
        className={classes.item}
        onChange={onChange}
        value={Model}
        label="Model"
      />
    );
  };

  const renderStyle = () => {
    const onChange = (ev, val) => setStyle(val);
    return (
      <Dictionary
        options="codeVehicleStyles"
        className={classes.item}
        onChange={onChange}
        formType="edit"
        value={Style}
        label="Style"
      />
    );
  };

  const renderDoors = () => {
    const onChange = (ev, val) => setDoorQuantity(val);
    return (
      <TextField2
        className={classes.item}
        label="Doors"
        value={DoorQuantity}
        onChange={onChange}
        type="number"
        min={0}
        max={20}
      />
    );
  };

  const renderPassengeQuantity = () => {
    const onChange = (ev, val) => setPassengeSafeQty(val);
    return (
      <TextField2
        className={classes.item}
        label="Passenger Quantity"
        value={PassengeSafeQty}
        onChange={onChange}
        type="number"
        min={0}
        max={1000}
      />
    );
  };

  const renderSeatQuantity = () => {
    const onChange = (ev, val) => setSeatQty(val);
    return (
      <TextField2
        className={classes.item}
        label="Seat Quantity"
        value={SeatQty}
        onChange={onChange}
        type="number"
        min={0}
        max={1000}
      />
    );
  };

  const renderPrimaryColor = () => {
    const onChange = (ev, val) => setPrimaryColor(val);
    return (
      <Dictionary
        options="codeVehicleColors"
        className={classes.item}
        onChange={onChange}
        formType="edit"
        value={PrimaryColor}
        label="Primary Color"
      />
    );
  };

  const renderSecondaryColor = () => {
    const onChange = (ev, val) => setSecondaryColor(val);
    return (
      <Dictionary
        options="codeVehicleColors"
        className={classes.item}
        onChange={onChange}
        formType="edit"
        value={SecondaryColor}
        label="Secondary Color"
      />
    );
  };

  const renderInteriorColor = () => {
    const onChange = (ev, val) => setInteriorColor(val);
    return (
      <Dictionary
        options="codeVehicleColors"
        className={classes.item}
        onChange={onChange}
        formType="edit"
        value={InteriorColor}
        label="Interior Color"
      />
    );
  };

  const renderClassification = () => {
    const onChange = (ev, val) => setClassification(val);
    return (
      <Dictionary
        options="codeVehicleClassifications"
        className={classes.item}
        onChange={onChange}
        formType="edit"
        value={Classification}
        label="Classification"
      />
    );
  };

  const renderDescription = () => {
    const onChange = (ev, val) => setDescription(val);
    return (
      <TextField2
        className={classes.item}
        label="Description"
        value={Description}
        onChange={onChange}
        max={8000}
      />
    );
  };

  const renderBrand = () => {
    const onChange = (ev, val) => setBrand(val);
    return (
      <Dictionary
        options="codeVehicleBrands"
        className={classes.item}
        onChange={onChange}
        formType="edit"
        value={Brand}
        label="Brand"
      />
    );
  };

  const renderBrander = () => {
    const onChange = (ev, val) => setBrander(val);
    return (
      <Dictionary
        options="codeVehicleBranders"
        className={classes.item}
        onChange={onChange}
        formType="edit"
        value={Brander}
        label="Brander"
      />
    );
  };

  const renderVin = () => {
    const onChange = (ev, val) => setVIN(val.toUpperCase());
    const error = Boolean(VIN) && !validateVin(VIN);

    return (
      <TextField2
        className={classes.item}
        label="VIN"
        value={VIN}
        onChange={onChange}
        max={17}
        error={error}
      />
    );
  };
  const renderVinAnalysis = () => {
    const onChange = (ev, val) => setVINAnalysisText(val);
    return (
      <TextField2
        className={classes.item}
        label="VIN Analysis Test"
        value={VINAnalysisText}
        onChange={onChange}
        max={2000}
      />
    );
  };

  const renderPropertyID = () => {
    const onChange = (ev, val) => setPropertyID(val);
    return (
      <TextField2
        className={classes.item}
        label="Property ID"
        value={PropertyID}
        onChange={onChange}
        max={25}
      />
    );
  };

  const renderPropertyCode = () => {
    const onChange = (ev, val) => setProperty(val);
    return (
      <Dictionary
        options="codeVehicleProperty"
        className={classes.item}
        onChange={onChange}
        formType="edit"
        value={Property}
        label="Property Code"
      />
    );
  };

  const renderCommercialID = () => {
    const onChange = (ev, val) => setCommercialID(val);
    return (
      <TextField2
        className={classes.item}
        label="Commercial ID"
        value={CommercialID}
        onChange={onChange}
        max={25}
      />
    );
  };

  const renderCommercialCode = () => {
    const onChange = (ev, val) => setCommercial(val);
    return (
      <Dictionary
        options="codeVehicleCommercial"
        className={classes.item}
        onChange={onChange}
        formType="edit"
        value={Commercial}
        label="Commercial Code"
      />
    );
  };

  const renderEmissionInspection = () => {
    const onChange = (ev, val) => setEmissionInspectionQty(val);
    return (
      <TextField2
        className={classes.item}
        label="Emission Inspection"
        value={EmissionInspectionQty}
        onChange={onChange}
        type="number"
      />
    );
  };

  const renderTransmissionCode = () => {
    const onChange = (ev, val) => setTransmission(val);
    return (
      <Dictionary
        options="codeTransmissions"
        className={classes.item}
        onChange={onChange}
        formType="edit"
        value={Transmission}
        label="Transmission Code"
      />
    );
  };

  const renderCmv = () => {
    const onChange = () => setIsCMVIndicator(!IsCMVIndicator);
    return <Checkbox2 checked={IsCMVIndicator} onChange={onChange} label="CMV" />;
  };

  const renderRented = () => {
    const onChange = () => setIsRented(!IsRented);
    return <Checkbox2 checked={IsRented} onChange={onChange} label="Rented" />;
  };
  const renderWanted = () => {
    const onChange = () => setIsWanted(!IsWanted);
    return <Checkbox2 checked={IsWanted} onChange={onChange} label="Wanted" />;
  };

  const renderGarageIndicator = () => {
    const onChange = () => setGarageIndicator(!GarageIndicator);
    return <Checkbox2 checked={GarageIndicator} onChange={onChange} label="Garage Indicator" />;
  };

  const renderGarageFacility = () => {
    const onChange = (ev, val) => setGarageFacility(val);
    return (
      <Dictionary
        options="codeGarageFacilities"
        className={classes.item}
        onChange={onChange}
        formType="edit"
        value={GarageFacility}
        label="Garage Facility"
        disabled={!GarageIndicator}
      />
    );
  };

  return (
    <div className={classes.wrap}>
      <Row>
        <ColCard minWidth={500}>
          <FormDivider title="Details" />
          <RowInner>
            {renderYear()}
            {renderMake()}
            {renderModel()}
            {renderStyle()}
            {renderDoors()}
            {renderPassengeQuantity()}
            {renderSeatQuantity()}
            {renderPrimaryColor()}
            {renderSecondaryColor()}
            {renderInteriorColor()}
            {renderClassification()}
            {renderDescription()}
            {renderBrand()}
            {renderBrander()}
            <Fills className={classes.item} />
          </RowInner>
        </ColCard>
        <ColCard minWidth={500}>
          <FormDivider title="Credentials and Analysis" />
          <RowInner>
            {renderVin()}
            {renderVinAnalysis()}
            {renderPropertyID()}
            {renderPropertyCode()}
            {renderCommercialID()}
            {renderCommercialCode()}
            {renderCmv()}
            {renderEmissionInspection()}
            {renderTransmissionCode()}
            <Fills className={classes.item} />
          </RowInner>
          <FormDivider title="Flags" />
          <RowInner>
            {renderRented()}
            {renderWanted()}
            {renderGarageIndicator()}
            {renderGarageFacility()}
          </RowInner>
        </ColCard>
      </Row>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        {recordHistoryData?.shouldVisible && (
          <Box className={classes.btn}>
            <BackDatedRecordHistory
              showRecordHistory={showRecordHistory}
              setShowRecordHistory={setShowRecordHistory}
              disabled={recordHistoryData?.isDisabled}
              handleCloseRecordHistoryDialog={handleCloseRecordHistoryDialog}
              createUpdateInfo={recordHistoryData?.createUpdateInfo}></BackDatedRecordHistory>
          </Box>
        )}
        <Button
          className={classes.btn}
          color="primary"
          disabled={props.parentPermission && !props?.parentPermission.Edit}
          variant="contained"
          autoFocus
          onClick={handleSave}>
          <SaveIcon /> Save
        </Button>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    dictionary: state.dictionary,
  };
};

export default connect(mapStateToProps, {
  handleError,
  closeEditVehicle,
  notifyDataUpdate,
})(EditVehicle);
