import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import DateFnsUtils from '@date-io/date-fns';

import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

import { getTitleForDialog } from 'global-components/ReadOnlyText';

import Dialog from 'components/RMSDialog';
import BackDatedRecordHistory from 'components/BackDatedRecordHistory';
import PlaceLookup from 'components/RMSPlaceLookup';
import Dictionary from 'components/RMSAutoComplete/RMSDictonary';

import formStyles, { gridStyle, Row, RowInner, ColCard, Fills } from 'utils/formStyles';

import { closeEditCredential } from 'reducers/PersonDialogsReducer';
import { editCredential } from 'reducers/SearchReducer';
import { notify } from 'reducers/NotifierReducer';
import { handleError } from 'reducers/ErrorReducer';
import { notifyDataUpdate } from 'reducers/DataUpdateReducer';
import { getFullPermissions } from 'reducers/PermissionsReducer';

const useStyles = makeStyles((theme) => ({
  ...formStyles,
  item: gridStyle(200, 800),
  field: gridStyle(210, 400),
}));

function EditCredentialDialog(props) {
  const classes = useStyles();
  const { data, dictionary } = props;
  const { codeStates, codeCredentialTypes, codeCredentialClasses } = dictionary;
  const [idNumber, setIdNumber] = useState('');
  const [type, setType] = useState(null);
  const [state, setState] = useState(null);
  const [curClass, setCurClass] = useState(null);
  const [isCommercial, setIsCommercial] = useState(false);
  const [issuedBy, setIssuedBy] = useState(null);
  const [issuedDate, setIssuedDate] = useState(null);
  const [expDate, setExpDate] = useState(null);
  const [desc, setDesc] = useState('');
  const [notes, setNotes] = useState('');

  const [recordHistoryData, setRecordHistoryData] = useState({
    shouldVisible: true,
    isDisabled: true,
    createUpdateInfo: {},
  });
  const [showRecordHistory, setShowRecordHistory] = useState(false);

  const peoplePermission = getFullPermissions('globals', 'People', 'any');
  useEffect(() => {
    renderInitialData();
  }, []);

  const renderInitialData = () => {
    const {
      IDNumber,
      Type,
      State,
      Class,
      IsCommercial,
      Issued,
      ExpirationDate,
      Description,
      Notes,
      Created,
      CreatedBy,
      Updated,
      UpdatedBy,
    } = data;

    setRecordHistoryData({
      shouldVisible: true,
      isDisabled: !Created,
      createUpdateInfo: {
        created: Created,
        createdBy: CreatedBy,
        updated: Updated,
        updatedBy: UpdatedBy,
      },
    });

    setIdNumber(IDNumber || '');
    const credType = codeCredentialTypes.find((c) => c.Code === Type);
    setType(credType || null);
    const credState = codeStates.find((s) => s.Code === State);
    setState(credState || null);
    const credClass = codeCredentialClasses.find((c) => c.Code === Class);
    setCurClass(credClass || null);
    setIsCommercial(IsCommercial);
    setIssuedDate(Issued || null);
    setExpDate(ExpirationDate || null);
    setDesc(Description || '');
    setNotes(Notes || '');
  };

  const handleCloseRecordHistoryDialog = () => setShowRecordHistory(false);

  const close = () => {
    props.closeEditCredential();
  };

  const save = async () => {
    const dataObj = {
      Number: idNumber,
      Type: type ? type.Code : null,
      State: state ? state.Code : null,
      Class: curClass ? curClass.Code : null,
      IsCommercial: isCommercial,
      ptsIssuedByID: issuedBy ? issuedBy.ptsPlaceID : null,
      Issued: issuedDate,
      Expiration: expDate,
      Description: desc || null,
      Notes: notes || null,
    };
    try {
      const result = await editCredential(data.ptsCredentialID, dataObj);
      const { ptsCredentialID } = result;
      props.notifyDataUpdate({ type: 'edit-credential', data: { ptsCredentialID } });
      props.closeEditCredential();
      props.notify('Credential Updated', 'success');
    } catch (err) {
      props.handleError(err);
    }
  };

  const renderIdNumber = () => {
    return (
      <TextField
        label="ID Number"
        variant="outlined"
        onChange={(e) => setIdNumber(e.target.value)}
        value={idNumber}
        size="small"
        className={classes.item}
      />
    );
  };

  const renderType = () => {
    const onChange = (ev, val) => setType(val);
    return (
      <Dictionary
        options="codeCredentialTypes"
        className={classes.item}
        onChange={onChange}
        formType="edit"
        value={type}
        label="Type"
      />
    );
  };

  const renderState = () => {
    const onChange = (ev, val) => setState(val);
    return (
      <Dictionary
        options="codeStates"
        className={classes.item}
        onChange={onChange}
        formType="edit"
        value={state}
        label="State"
      />
    );
  };

  const renderClass = () => {
    const onChange = (ev, val) => setCurClass(val);
    return (
      <Dictionary
        options="codeCredentialClasses"
        className={classes.item}
        onChange={onChange}
        formType="edit"
        value={curClass}
        label="Class"
      />
    );
  };

  const renderIsCommercial = () => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={isCommercial}
            onChange={() => setIsCommercial(!isCommercial)}
            color="primary"
          />
        }
        label="Commercial"
      />
    );
  };

  const renderIssuedBy = () => {
    const handleChange = (value) => setIssuedBy(value);
    const { ptsIssuedByID } = data;
    return (
      <PlaceLookup
        onChange={handleChange}
        className={classes.item}
        label="Issued By"
        ptsPlaceID={ptsIssuedByID}
      />
    );
  };

  const renderIssuedDate = () => {
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          disableOpenOnEnter
          autoComplete="off"
          disableToolbar
          variant="inline"
          inputVariant="outlined"
          format="MM/dd/yyyy"
          margin="normal"
          label="Issued Date"
          value={issuedDate}
          onChange={(date) => setIssuedDate(date)}
          autoOk
          size="small"
          className={classes.item}
        />
      </MuiPickersUtilsProvider>
    );
  };

  const renderExpirationDate = () => {
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          disableOpenOnEnter
          autoComplete="off"
          disableToolbar
          variant="inline"
          inputVariant="outlined"
          format="MM/dd/yyyy"
          margin="normal"
          label="Expiration Date"
          value={expDate}
          onChange={(date) => setExpDate(date)}
          autoOk
          size="small"
          className={classes.item}
        />
      </MuiPickersUtilsProvider>
    );
  };

  const renderDescription = () => {
    return (
      <TextField
        label="Description"
        variant="outlined"
        onChange={(e) => setDesc(e.target.value)}
        value={desc}
        size="small"
        className={classes.item}
      />
    );
  };

  const renderNotes = () => {
    return (
      <TextField
        label="Notes"
        variant="outlined"
        onChange={(e) => setNotes(e.target.value)}
        value={notes}
        size="small"
        multiline
        rows={5}
        className={classes.item}
      />
    );
  };

  const renderActions = () => {
    return (
      <>
        {recordHistoryData?.shouldVisible && (
          <Box className="">
            <BackDatedRecordHistory
              showRecordHistory={showRecordHistory}
              setShowRecordHistory={setShowRecordHistory}
              disabled={recordHistoryData?.isDisabled}
              handleCloseRecordHistoryDialog={handleCloseRecordHistoryDialog}
              createUpdateInfo={recordHistoryData?.createUpdateInfo}></BackDatedRecordHistory>
          </Box>
        )}
        <Button
          color="primary"
          variant="contained"
          autoFocus
          disabled={!peoplePermission.Edit}
          onClick={save}>
          <SaveIcon /> Save
        </Button>
        <Button color="primary" onClick={close}>
          <CloseIcon /> Close
        </Button>
      </>
    );
  };

  return (
    <Dialog
      toolbar
      maxWidth="sm"
      onClose={close}
      title={getTitleForDialog(peoplePermission, 'Edit Credential')}
      actions={renderActions()}>
      <Row>
        <ColCard minWidth={500}>
          <RowInner>
            {renderIdNumber()}
            {renderType()}
          </RowInner>
          <RowInner>
            {renderState()}
            {renderClass()}
          </RowInner>
          <RowInner>
            {renderIsCommercial()}
            {renderIssuedBy()}
          </RowInner>
          <RowInner>
            {renderIssuedDate()}
            {renderExpirationDate()}
          </RowInner>
          <RowInner>{renderDescription()}</RowInner>
          <RowInner>{renderNotes()}</RowInner>
        </ColCard>
      </Row>
    </Dialog>
  );
}

const mapStateToProps = (state) => {
  return {
    dictionary: state.dictionary,
  };
};

export default connect(mapStateToProps, {
  closeEditCredential,
  notifyDataUpdate,
  notify,
  handleError,
})(EditCredentialDialog);
