import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import RMSAutocomplete2 from './RMSAutocomplete2';
import { showEditDictionary } from 'reducers/PersonDialogsReducer';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { checkJsonValidityAndParse } from 'utils/functions';
import { TbClockExclamation } from 'react-icons/tb';
const citationDictionaries = [
  'codePavementTypes',
  'codeWeather',
  'codeSearchTypes',
  'codeContrabandType',
  'codeArrestType',
  'codeConvictionStatus',
  'codeWarningTypes',
  'codeForceUsed',
  'codePavementConditions',
  'codeTrafficTypes',
  'codeLighting',
  'codeHighwayTypes',
];
function RMSDictonary(props) {
  const { dictionary, options, label, value, type, multiple } = props;
  let dictOptions = Array.isArray(options) ? options : dictionary[options] || [];
  const permissions = props.permissions[options];
  const showEdit = permissions?.Create || permissions?.Edit || permissions?.Delete;
  const valueValid = value === null || typeof value === 'object';
  if (options === 'codeMugshotTypes' && props.IsMark) {
    dictOptions = dictOptions.filter((item) => item?.Category?.toLowerCase() === 'marks');
  }
  useEffect(() => {
    if (valueValid) return;
    setProperValueObject();
  }, [valueValid, value]);

  const setProperValueObject = () => {
    if (value === undefined) return;
    let newVal;
    switch (options) {
      case 'codeCities':
        newVal = dictOptions.find((d) => d.ptsCityID === value);
        break;
      case 'cadESNs':
        newVal = dictOptions.find((d) => d.ESN === value);
        break;
      case 'Agencies':
        newVal = dictOptions.find((d) => d.AgencyID === value);
        break;
      case 'Citation_Court':
        newVal = dictOptions.find((d) => d.Court_type === value);
        break;
      case 'codeZones':
        newVal = dictOptions.find((d) => d.ZoneCode === value);
        break;
      default:
        newVal = dictOptions.find((d) => d.Code === value);
    }
    if (newVal) props.onChange(null, newVal);
  };

  const processOptions = () => {
    if (citationDictionaries.includes(options) && props.citation) {
      return dictOptions.filter((item) => {
        const parsedEntities = checkJsonValidityAndParse(item.Entities);

        if (
          parsedEntities &&
          Array.isArray(parsedEntities) &&
          parsedEntities.includes('citation')
        ) {
          return true;
        }
        return false;
      });
    }

    switch (options) {
      case 'ptsStatute': {
        const filtedOptions = dictOptions.filter((item) => {
          const { EnactmentDate } = item || {};
          if (EnactmentDate && new Date(EnactmentDate) <= new Date()) {
            return true;
          }
          return false;
        });

        return filtedOptions.sort(function (a, b) {
          return a.Description.localeCompare(b.Description);
        });
      }
      case 'codePartyRelationship':
        if (type === 'Warrants')
          return dictOptions
            .filter((item) => item.Entities && JSON.parse(item.Entities).includes(type))
            .sort(function (a, b) {
              return a.Description.localeCompare(b.Description);
            });
        else if (type === 'Citation')
          return dictOptions
            .filter((item) => item.Entities && JSON.parse(item.Entities).includes(type))
            .sort(function (a, b) {
              return a.Description.localeCompare(b.Description);
            });
        else
          return dictOptions
            .filter((item) => item.Entities && JSON.parse(item.Entities).includes('Rms'))
            .sort(function (a, b) {
              return a.Description.localeCompare(b.Description);
            });

      default:
        return dictOptions;
    }
  };

  if (!dictOptions) {
    return <div style={{ color: 'red' }}>Invalid data</div>;
  }
  let extraOpts = {};
  switch (options) {
    case 'codeCities':
      extraOpts = {
        getOptionSelected: (option, value) => option.ptsCityID === value.ptsCityID,
        getOptionLabel: (option) => (option.ptsCityID ? option.CityDescription : ''),
        renderOption: (option) => (
          <span>
            <strong>{option.CityDescription}</strong>
            {!!option.State && <> - {option.State}</>}
          </span>
        ),
      };
      break;
    case 'Agencies':
      extraOpts = {
        getOptionSelected: (option, value) => option.AgencyID === value.AgencyID,
        getOptionLabel: (option) => (option.AgencyID ? option.AgencyID : ''),
        renderOption: (option) => (
          <span>
            {option.AgencyID} - {option.Description}
          </span>
        ),
      };
      break;
    case 'cadESNs':
      extraOpts = {
        getOptionSelected: (option, value) => option.ESN === value.ESN,
        getOptionLabel: (option) => (option.ESN ? option.ESN : ''),
        renderOption: (option) => (
          <span>
            {option.ESN} - {option.Description}
          </span>
        ),
        filterOptions: createFilterOptions({
          stringify: (option) => option.ESN + option.Description,
        }),
      };
      break;
    case 'Citation_Court':
      extraOpts = {
        getOptionSelected: (option, value) => option.Court_type === value.Court_type,
        getOptionLabel: (option) => (option.Court_Name ? option.Court_Name : ''),
        renderOption: (option) => (
          <span>
            {option.Court_type} - {option.Court_Name}
          </span>
        ),
        filterOptions: createFilterOptions({
          stringify: (option) => option.Court_type + option.Court_Name,
        }),
      };
      break;
    case 'codeZones':
      extraOpts = {
        getOptionSelected: (option, value) => option.ZoneCode === value.ZoneCode,
        getOptionLabel: (option) => (option.ZoneCode ? option.ZoneCode : ''),
        renderOption: (option) => (
          <span>
            {option.ZoneCode} - {option.Description}
          </span>
        ),
        filterOptions: createFilterOptions({
          stringify: (option) => option.ZoneCode + option.Description,
        }),
      };
      break;
    case 'codePartyRelationship':
      extraOpts = {
        getOptionSelected: (option, value) => option.Description === value.Description,
        getOptionLabel: (option) => (option.Description ? option.Description : ''),
        renderOption: (option) => (
          <span>
            {option.Code} - {option.Description}
          </span>
        ),
        filterOptions: createFilterOptions({
          stringify: (option) => option.Code + option.Description,
        }),
      };
      break;
    case 'codeLocationCategories':
      extraOpts = {
        getOptionSelected: (option, value) => option.Description === value.Description,
        getOptionLabel: (option) => (option.Description ? option.Description : ''),
        renderOption: (option) => (
          <span>
            {option.Code} - {option.Description}
          </span>
        ),
        filterOptions: createFilterOptions({
          stringify: (option) => option.Code + option.Description,
        }),
      };
      break;
    case 'ptsStatute':
      extraOpts = {
        renderOption: (option) => (
          <span>
            <strong>{option.Code}</strong>
            {!!option.Description && <> - {option.Description}</>}
            {option.RepealDate && new Date(option.RepealDate) <= new Date() && (
              <span style={{ marginLeft: '25px' }}>
                <TbClockExclamation size={20} color="red" />{' '}
              </span>
            )}
          </span>
        ),
      };
      break;
    default:
  }

  const autocompleteOpts = { ...props, ...extraOpts };
  if (!valueValid) autocompleteOpts.value = null;
  return (
    <RMSAutocomplete2
      {...autocompleteOpts}
      options={processOptions()}
      multiple={multiple}
      table={options}
      onAdd={showEdit ? () => props.showEditDictionary({ name: options, title: label }) : undefined}
    />
  );
}

const mapStateToProps = (state) => ({
  dictionary: state.dictionary,
  permissions: state.permissions.dictionary,
});

export default connect(mapStateToProps, {
  showEditDictionary,
})(RMSDictonary);
