import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import DateFnsUtils from '@date-io/date-fns';

import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from '@material-ui/pickers';

import Dialog from 'components/RMSDialog';
import RMSTextField from 'components/RMSTextField';
import PersonLookup2 from 'components/RMSPersonLookup/PersonLookup2';
import PlaceLookup from 'components/RMSPlaceLookup';
import Dictionary from 'components/RMSAutoComplete/RMSDictonary';
import BackDatedRecordHistory from 'components/BackDatedRecordHistory';

import { getFormStyle } from 'utils/functions';
import { gridStyle, Row, RowInner, ColCard, formatSaveData } from 'utils/formStyles';

import { formatSaveDate, getFormat24, getDateTimeFormat } from 'reducers/TimeReducer';
import { createDnaSample, editDnaSample } from 'reducers/SearchReducer';
import { handleError } from 'reducers/ErrorReducer';
import { notifyDataUpdate } from 'reducers/DataUpdateReducer';
import { showSpinner, hideSpinner } from 'reducers/UiReducer';
import { notify } from 'reducers/NotifierReducer';
import { closePersonDnaSample } from 'reducers/PersonDialogsReducer';

const useStyles = makeStyles((theme) => ({
  date: getFormStyle(200, 300),
  item: gridStyle(200, 800),
  closeButton: { color: theme.button.color },
}));

function AddDnaSampleDialog(props) {
  const classes = useStyles();
  const { data } = props;

  const [DNASampleID, setDNASampleID] = useState('');
  const [Taken, setTaken] = useState(null);
  const [ptsTakenByPersonID, setPtsTakenByPersonID] = useState(null);
  const [Type, setType] = useState(null);
  const [Method, setMethod] = useState(null);
  const [Reason, setReason] = useState(null);
  const [Status, setStatus] = useState(null);
  const [ptsCrimeLabPlaceID, setPtsCrimeLabPlaceID] = useState(null);
  const [Notes, setNotes] = useState('');
  const [CrimeLabSentDateTime, setCrimeLabSetDateTime] = useState(null);

  const [recordHistoryData, setRecordHistoryData] = useState({
    shouldVisible: true,
    isDisabled: true,
    createUpdateInfo: {},
  });
  const [showRecordHistory, setShowRecordHistory] = useState(false);

  const title = data.ptsDNASampleID ? 'Edit DNA Sample' : 'Add DNA Sample';
  const format24 = getFormat24();

  useEffect(() => {
    if (data.ptsDNASampleID) {
      const {
        DNASampleID,
        ptsTakenByPersonID,
        Taken,
        Type,
        Method,
        Reason,
        Status,
        ptsCrimeLabPlaceID,
        CrimeLabSentDateTime,
        Notes,
      } = data;

      setRecordHistoryData({
        shouldVisible: true,
        isDisabled: !data.Created,
        createUpdateInfo: {
          created: data.Created,
          createdBy: data.CreatedBy,
          updated: data.Updated,
          updatedBy: data.UpdatedBy,
        },
      });

      setDNASampleID(DNASampleID);
      setPtsTakenByPersonID(ptsTakenByPersonID);
      setTaken(Taken);
      setType(Type);
      setMethod(Method);
      setReason(Reason);
      setStatus(Status);
      setPtsCrimeLabPlaceID(ptsCrimeLabPlaceID);
      setCrimeLabSetDateTime(CrimeLabSentDateTime);
      setNotes(Notes);
    }
  }, []);

  const handleCloseRecordHistoryDialog = () => setShowRecordHistory(false);

  const close = () => {
    props.closePersonDnaSample();
  };

  const save = () => {
    const saveData = formatSaveData({
      DNASampleID,
      Taken: formatSaveDate(Taken),
      ptsTakenByPersonID,
      Type,
      Method,
      Reason,
      Status,
      ptsCrimeLabPlaceID,
      Notes,
      CrimeLabSentDateTime: formatSaveDate(CrimeLabSentDateTime),
    });

    props.showSpinner();

    if (data.ptsPersonID && data.ptsDNASampleID) {
      editDnaSample(data.ptsDNASampleID, saveData)
        .then(() => {
          props.notify('DNA Sample updated', 'success');
          props.notifyDataUpdate({ type: 'dna-sample', data: {} });
          close();
        })
        .catch(props.handleError)
        .finally(props.hideSpinner);
    } else {
      saveData.ptsPersonID = data.ptsPersonID;
      createDnaSample(saveData)
        .then(() => {
          props.notify('DNA Sample created', 'success');
          props.notifyDataUpdate({ type: 'dna-sample', data: {} });
          close();
        })
        .catch(props.handleError)
        .finally(props.hideSpinner);
    }
  };

  const renderActions = () => {
    return (
      <>
        {recordHistoryData?.shouldVisible && (
          <Box className="">
            <BackDatedRecordHistory
              showRecordHistory={showRecordHistory}
              setShowRecordHistory={setShowRecordHistory}
              disabled={recordHistoryData?.isDisabled}
              handleCloseRecordHistoryDialog={handleCloseRecordHistoryDialog}
              createUpdateInfo={recordHistoryData?.createUpdateInfo}></BackDatedRecordHistory>
          </Box>
        )}
        <Button color="primary" variant="contained" autoFocus onClick={save}>
          <SaveIcon /> Save
        </Button>
        <Button color="primary" onClick={close} className={classes.closeButton}>
          <CloseIcon /> Close
        </Button>
      </>
    );
  };

  const renderDnaSampleId = () => {
    const onChange = (ev, val) => setDNASampleID(val);
    return (
      <RMSTextField
        className={classes.item}
        label="DNA Sample ID"
        value={DNASampleID}
        onChange={onChange}
        max={25}
      />
    );
  };

  const renderNotes = () => {
    const onChange = (ev, val) => setNotes(val);
    return (
      <RMSTextField
        className={classes.item}
        label="Notes"
        value={Notes}
        onChange={onChange}
        max={8000}
        rows={5}
        multiline
      />
    );
  };

  const renderTakenBy = () => {
    const handlePersonChange = (person) => setPtsTakenByPersonID(person?.ptsPersonID);
    return (
      <PersonLookup2
        className={classes.item}
        onPersonChange={handlePersonChange}
        label="Taken By"
        ptsPersonID={ptsTakenByPersonID}
      />
    );
  };

  const renderCrimeLab = () => {
    const handleChange = (place) => setPtsCrimeLabPlaceID(place?.ptsPlaceID);
    return (
      <PlaceLookup
        onChange={handleChange}
        className={classes.item}
        label="Crime Lab"
        ptsPlaceID={ptsCrimeLabPlaceID}
      />
    );
  };

  const renderTakenDate = () => {
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDateTimePicker
          disableOpenOnEnter
          autoComplete="off"
          ampm={!format24}
          disableToolbar
          variant="inline"
          inputVariant="outlined"
          format={getDateTimeFormat()}
          margin="normal"
          label="Taken Date"
          value={Taken}
          onChange={setTaken}
          autoOk
          size="small"
          className={classes.date}
        />
      </MuiPickersUtilsProvider>
    );
  };

  const renderSentDate = () => {
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDateTimePicker
          disableOpenOnEnter
          autoComplete="off"
          ampm={!format24}
          disableToolbar
          variant="inline"
          inputVariant="outlined"
          format={getDateTimeFormat()}
          margin="normal"
          label="Crime Lab Sent Date"
          value={CrimeLabSentDateTime}
          onChange={setCrimeLabSetDateTime}
          autoOk
          size="small"
          className={classes.date}
        />
      </MuiPickersUtilsProvider>
    );
  };

  const renderSampleStatus = () => {
    const onChange = (ev, val) => setStatus(val);
    return (
      <Dictionary
        options="codeDNACollection"
        className={classes.item}
        formType="add"
        onChange={onChange}
        value={Status}
        label="Sample Status"
        // compact
      />
    );
  };

  const renderSampleReason = () => {
    const onChange = (ev, val) => setReason(val);
    return (
      <Dictionary
        options="codeDNASampleReason"
        className={classes.item}
        onChange={onChange}
        formType="add"
        value={Reason}
        label="Sample Reason"
        // compact
      />
    );
  };

  const renderSampleMethod = () => {
    const onChange = (ev, val) => setMethod(val);
    return (
      <Dictionary
        options="codeDNASampleMethod"
        className={classes.item}
        onChange={onChange}
        value={Method}
        formType="add"
        label="Sample Method"
        // compact
      />
    );
  };

  const renderSampleType = () => {
    const onChange = (ev, val) => setType(val);
    return (
      <Dictionary
        options="codeDNASampleType"
        className={classes.item}
        onChange={onChange}
        value={Type}
        formType="add"
        label="Sample Type"
        // compact
      />
    );
  };

  return (
    <Dialog toolbar onClose={close} title={title} actions={renderActions()}>
      <Row>
        <ColCard minWidth={500}>
          <RowInner>{renderDnaSampleId()}</RowInner>
          <RowInner>
            {renderTakenBy()}
            {renderTakenDate()}
          </RowInner>
          <RowInner>
            {renderSampleType()}
            {renderSampleMethod()}
          </RowInner>
          <RowInner>
            {renderSampleReason()}
            {renderSampleStatus()}
          </RowInner>
          <RowInner>
            {renderCrimeLab()}
            {renderSentDate()}
          </RowInner>
          <RowInner>{renderNotes()}</RowInner>
        </ColCard>
      </Row>
    </Dialog>
  );
}

export default connect(null, {
  closePersonDnaSample,
  handleError,
  notifyDataUpdate,
  showSpinner,
  hideSpinner,
  notify,
})(AddDnaSampleDialog);
