import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { closeEditZone } from 'reducers/PersonDialogsReducer';
import Dialog from 'components/RMSDialog';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { handleError } from 'reducers/ErrorReducer';
import Sops from './Sops';
import Attachments from 'components/RMSAttachments/RMSAttachments2';
import Zone from './Zone';
import { getFullPermissions } from 'reducers/PermissionsReducer';
import { getTitleForDialog } from 'global-components/ReadOnlyText';
const useStyles = makeStyles((theme) => ({
  wrap: {
    padding: theme.spacing(3),
  },
  content: {
    margin: -theme.spacing(3),
  },
}));

function EditZone(props) {
  const classes = useStyles();
  const { data } = props;
  const { ZoneCode, IntCode } = data;
  const [tab, setTab] = useState('zone');
  const zonePermission = getFullPermissions('globals', 'Zones', 'any');

  const mountedRef = useRef(true);
  //const newAddress = !ptsPersonID;
  useEffect(() => {
    if (props.tab) setTab(props.tab);

    return () => {
      mountedRef.current = false;
    };
    // eslint-disable-next-line
  }, [props.tab]);

  const close = (data) => {
    props.closeEditZone();
  };

  return (
    <Dialog
      toolbar
      onClose={close}
      title={getTitleForDialog(zonePermission, `Edit Zone`)}
      fullScreen>
      <div className={classes.content}>
        <Paper square>
          <Tabs variant="scrollable" value={tab} onChange={(ev, val) => setTab(val)}>
            <Tab label="Zone" value="zone" />
            <Tab label="Sops" value="sops" />
            <Tab label="Attachments" value="attachments" />

            {/* <Tab label="Coordinates" value="coordinates" /> */}
          </Tabs>
        </Paper>

        {tab === 'zone' && <Zone ZoneCode={ZoneCode} />}
        {tab === 'sops' && <Sops IntCode={IntCode} />}
        {tab === 'attachments' && (
          <Attachments type="Zone" id={IntCode} permissions={zonePermission} />
        )}
      </div>
    </Dialog>
  );
}

export default connect(null, {
  //closeEditPerson,
  handleError,
  closeEditZone,
})(EditZone);
