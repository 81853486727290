import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { closeEditDictionary } from 'reducers/PersonDialogsReducer';
import Dialog from 'components/RMSDialog';
import { getDictionary, saveDictionary, deleteDictionary } from 'reducers/DictionaryReducer';
import { handleError } from 'reducers/ErrorReducer';
import CheckIcon from '@material-ui/icons/Check';
import { XGrid } from '@material-ui/x-grid';
import formStyles, { gridStyle, RowInner, ColCard, Fills } from 'utils/formStyles';
import RMSTextField from 'components/RMSTextField/index.js';
import RMSCheckbox2 from 'components/RMSCheckbox/RMSCheckbox2.js';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { showSpinner, hideSpinner } from 'reducers/UiReducer';
import { addDictionaryValue, updateDictionaryValue } from 'reducers/DictionaryReducer';
import RMSDictonary from 'components/RMSAutoComplete/RMSDictonary';
import { sortObjArr } from 'utils/functions';
import QuickFilterSearch from 'components/RMSSearch/components/QuickFilterSearch';
const useStyles = makeStyles((theme) => ({
  ...formStyles,
  item: gridStyle(250, '100%'),
  edit: {
    margin: '0 -4px',
  },
  gridWrap: {
    width: 1000,
    maxWidth: '100%',
    height: 400,
  },
  filterWrap: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  actions: {
    textAlign: 'right',
    marginBottom: 16,
    width: '100%',
    '& button': {
      marginLeft: 8,
      '& svg': {
        marginRight: theme.spacing(1),
      },
    },
  },
  gridActions: {
    textAlign: 'right',
    marginBottom: 16,
    '& > button': {
      marginLeft: 8,
    },
  },
}));
const bool = (params) => (params.value ? <CheckIcon /> : <span></span>);

const defaultPermissions = {
  Create: false,
  Delete: false,
  Edit: false,
  Read: true,
  ViewDeleted: false,
};
const defaultColumns = [
  { field: 'Code', headerName: 'Code', width: 100 },
  { field: 'Description', headerName: 'Description', width: 250 },
  { field: 'Category', headerName: 'Category', width: 130 },
  {
    field: 'IsDefault',
    headerName: 'Is Default',
    width: 120,
    format: 'bool',
    renderCell: bool,
  },
  {
    field: 'IsActive',
    headerName: 'Is Active',
    width: 120,
    format: 'bool',
    renderCell: bool,
  },
  {
    field: 'IsDeleted',
    headerName: 'Is Deleted',
    width: 120,
    format: 'bool',
    renderCell: bool,
  },
];

const cityColumns = [
  { field: 'CityDescription', headerName: 'City', width: 250 },
  { field: 'State', headerName: 'State', width: 130 },
  { field: 'Category', headerName: 'Category', width: 130 },
  {
    field: 'IsDefault',
    headerName: 'Is Default',
    width: 120,
    format: 'bool',
    renderCell: bool,
  },
  {
    field: 'IsActive',
    headerName: 'Is Active',
    width: 120,
    format: 'bool',
    renderCell: bool,
  },
  {
    field: 'IsDeleted',
    headerName: 'Is Deleted',
    width: 120,
    format: 'bool',
    renderCell: bool,
  },
];

function ExampleWindow(props) {
  const classes = useStyles();
  const { addDictionaryValue, updateDictionaryValue, dictionary } = props;
  const { name, title } = props.data;
  const [rawRows, setRawRows] = useState([]);
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [rawColumns, setRawColumns] = useState(defaultColumns);
  const [columns, setColumns] = useState(defaultColumns);
  const [selection, setSelection] = useState(null);
  const [Code, setCode] = useState('');
  const [Description, setDescription] = useState('');
  const [Category, setCategory] = useState('');
  const [CityDescription, setCityDescription] = useState('');
  const [ptsCityID, setPtsCityID] = useState('');
  const [IsDefault, setIsDefault] = useState(false);
  const [IsActive, setIsActive] = useState(true);
  const [IsDeleted, setIsDeleted] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [changed, setChanged] = useState(false);
  const [edited, setEdited] = useState(false);
  const [defaultDisabled, setDefaultDisabled] = useState(false);
  const [isNew, setIsNew] = useState(true);
  const [validationErrors, setValidationErrors] = useState({});
  const [State, setState] = useState('');
  const [ROWGUID, setROWGUID] = useState(null);
  const [initalStateChange, setInitialStateChange] = useState(true);
  const mountedRef = useRef(true);
  const formValid = changed && !Object.keys(validationErrors).length;
  const permissions = props.permissions[name] ? props.permissions[name] : defaultPermissions;

  useEffect(() => {
    getData();
    return () => {
      mountedRef.current = false;
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    validateForm();
    // eslint-disable-next-line
  }, [CityDescription, Code, Description, IsDefault, IsActive, IsDeleted, State, State?.Code]);

  useEffect(() => {
    let sortedRows = rawRows;
    if (name === 'codeCities') {
      sortedRows = sortObjArr(rawRows, 'CityDescription');
    }

    if (!permissions.ViewDeleted) {
      const columns = rawColumns.filter((col) => col.field !== 'IsDeleted');
      const rows = sortedRows.filter((row) => !row.IsDeleted);
      setColumns(columns);
      setRows(rows);
    } else {
      setColumns(rawColumns);
      setRows(sortedRows);
    }

    // eslint-disable-next-line
  }, [rawColumns, permissions, rawRows]);

  const validateForm = () => {
    if (!changed) {
      Object.keys.length && setValidationErrors({});
      return;
    }
    let validation = {};
    if (!Code && !CityDescription) validation.Code = 'Code cannot be empty';
    if (isNew && rows.findIndex((r) => r.Code === Code) !== -1)
      validation.Code = 'Code already exists';
    if (name === 'codeCities' && !CityDescription)
      validation.CityDescription = 'City cannot be empty';
    setValidationErrors(validation);
  };

  const getData = async () => {
    setLoaded(false);
    try {
      const result = await getDictionary(name);
      processResult(result);
      setLoaded(true);
      return result;
    } catch (err) {
      props.handleError(err);
    }
  };

  const processResult = (result) => {
    const { data, permissions } = result;
    let rows = data.map((d) => ({ ...d, id: d.ROWGUID }));
    let columns;
    switch (name) {
      case 'codeCities':
        columns = cityColumns;
        break;
      default:
        columns = defaultColumns;
    }
    setRawColumns(columns);
    setRawRows(rows);
    const { Create, Edit, Delete } = permissions;
    if (Create && !Edit && !Delete) add();
  };

  const close = (data) => {
    props.closeEditDictionary(data);
  };

  const save = async () => {
    let data;
    switch (name) {
      case 'codeCities':
        data = {
          CityDescription,
          State: State?.Code || null,
          Category,
          IsActive,
          IsDefault,
          IsDeleted,
          ROWGUID,
        };
        break;
      default:
        data = { Code, Description, Category, IsActive, IsDefault, IsDeleted, ROWGUID };
    }
    props.showSpinner();
    let updatedCodes = null;
    try {
      await saveDictionary(name, data);
      updatedCodes = await getData();
      setEdited(false);

      if (!IsDeleted) {
        const codesArray = dictionary[name];
        let index = -1;
        if (ptsCityID) {
          index = codesArray.findIndex((v) => v['ptsCityID'] === ptsCityID);
        } else {
          index = codesArray.findIndex((v) => v['Code'] === Code);
        }
        if (parseInt(index) > -1) {
          codesArray[index] = data;
          updateDictionaryValue(codesArray, name);
        } else {
          if (name === 'codeCities' && updatedCodes.data) {
            const sortedCodes = updatedCodes.data.sort(
              (item, item2) => item.Created > item2.Created
            );
            const lastItem = sortedCodes.pop();
            if (lastItem.ptsCityID) {
              data = lastItem;
            }
          }
          addDictionaryValue(data, name);
        }
      }
    } catch (err) {
      props.handleError(err);
    } finally {
      props.hideSpinner();
    }
  };

  const add = (ev, newRows) => {
    const dataRows = newRows ? newRows : rows;
    const defaultExists = dataRows.findIndex((row) => row.IsDefault) !== -1;
    setDefaultDisabled(defaultExists);
    setCode('');
    setDescription('');
    setCategory('');
    setIsDefault(false);
    setIsActive(true);
    setIsDeleted(false);
    setEdited(true);
    setIsNew(true);
    setChanged(false);
    setROWGUID(null);
    setCityDescription('');
    setPtsCityID('');
  };

  const edit = (ev) => {
    const row = rows.find((r) => r.ROWGUID === selection);
    const defaultRow = rows.find((row) => row.IsDefault);
    let defaultDisabled = false;
    if (defaultRow && defaultRow.Code !== row.Code) defaultDisabled = true;
    setDefaultDisabled(defaultDisabled);
    setCode(row.Code);
    setDescription(row.Description || '');
    setCategory(row.Category || '');
    setIsDefault(row.IsDefault);
    setIsActive(row.IsActive);
    setIsDeleted(row.IsDeleted);
    setEdited(true);
    setIsNew(false);
    setChanged(false);
    setROWGUID(selection);
    setCityDescription(row.CityDescription);
    setPtsCityID(row.ptsCityID);
    setState(row.State);
  };

  const del = async (ev) => {
    props.showSpinner();
    try {
      await deleteDictionary(name, selection);
      getData();
    } catch (err) {
      props.handleError(err);
    }

    let codesArray = dictionary[name];
    let selectedRow = rows.filter((v) => v['ROWGUID'] === selection);
    if (selectedRow[0]['ptsCityID']) {
      codesArray = codesArray.filter(
        (v) => parseInt(v['ptsCityID']) != parseInt(selectedRow[0]['ptsCityID'])
      );
    } else {
      codesArray = codesArray.filter((v) => v['Code'] != selectedRow[0]['Code']);
    }
    updateDictionaryValue(codesArray, name);

    props.hideSpinner();
  };

  const WindowActions = () => {
    return (
      <Button color="primary" onClick={close}>
        <CloseIcon /> Close
      </Button>
    );
  };

  const EditActions = () => {
    return (
      <div className={classes.actions}>
        <hr />
        <Button color="primary" variant="contained" onClick={save} disabled={!formValid}>
          <SaveIcon /> Save
        </Button>
        <Button color="primary" onClick={() => setEdited(false)}>
          <CloseIcon /> Close
        </Button>
      </div>
    );
  };

  const XgridActions = () => {
    return (
      <div className={classes.gridActions}>
        {permissions.Create && (
          <Fab size="small" color="secondary" onClick={add}>
            <AddIcon />
          </Fab>
        )}
        {permissions.Edit && (
          <Fab size="small" color="secondary" onClick={edit} disabled={!selection}>
            <EditIcon />
          </Fab>
        )}
        {permissions.Delete && (
          <Fab size="small" color="secondary" onClick={del} disabled={!selection}>
            <DeleteIcon />
          </Fab>
        )}
      </div>
    );
  };

  const CodeTextField = () => {
    const onChange = (ev, val) => {
      setCode(val);
      !changed && setChanged(true);
    };
    const error = validationErrors.Code;
    return (
      <RMSTextField
        className={classes.item}
        label="Code"
        value={Code}
        onChange={onChange}
        error={error}
        max={10}
        disabled={!isNew}
      />
    );
  };

  const CityDescriptionTextField = () => {
    const onChange = (ev, val) => {
      setCityDescription(val);
      !changed && setChanged(true);
    };
    return (
      <RMSTextField
        className={classes.item}
        label="City"
        value={CityDescription}
        onChange={onChange}
        max={100}
      />
    );
  };

  const DescriptionTextField = () => {
    const onChange = (ev, val) => {
      setDescription(val);
      !changed && setChanged(true);
    };
    return (
      <RMSTextField
        className={classes.item}
        label="Description"
        value={Description}
        onChange={onChange}
        max={100}
      />
    );
  };

  const CategoryTextField = () => {
    const onChange = (ev, val) => {
      setCategory(val);
      !changed && setChanged(true);
    };
    return (
      <RMSTextField
        className={classes.item}
        label="Category"
        value={Category}
        onChange={onChange}
        max={10}
      />
    );
  };

  const IsDefaultCheckbox = () => {
    const onChange = () => {
      setIsDefault(!IsDefault);
      !changed && setChanged(true);
    };

    return (
      <RMSCheckbox2
        checked={IsDefault}
        onChange={onChange}
        label="Is Default"
        disabled={defaultDisabled}
      />
    );
  };

  const IsActiveCheckbox = () => {
    const onChange = () => {
      setIsActive(!IsActive);
      !changed && setChanged(true);
    };
    return <RMSCheckbox2 checked={IsActive} onChange={onChange} label="Is Active" />;
  };

  const IsDeletedCheckbox = () => {
    const onChange = () => {
      setIsDeleted(!IsDeleted);
      !changed && setChanged(true);
    };
    return (
      <RMSCheckbox2 checked={IsDeleted} onChange={onChange} label="Is Deleted" disabled={isNew} />
    );
  };
  const renderState = () => {
    const onChange = (ev, val) => {
      if (!initalStateChange) {
        !changed && setChanged(true);
      } else {
        setInitialStateChange(false);
      }

      setState(val);
    };
    return (
      <RMSDictonary
        options="codeStates"
        // className={classes.w100x200}
        className={classes.item}
        onChange={onChange}
        value={State}
        label="State"
        compact="true"
      />
    );
  };

  const EditForm = () => {
    return (
      <div className={classes.edit}>
        <ColCard>
          <RowInner>
            {name !== 'codeCities' && CodeTextField()}
            {name !== 'codeCities' && DescriptionTextField()}
            {name === 'codeCities' && CityDescriptionTextField()}
            {name === 'codeCities' && renderState()}
            {CategoryTextField()}
            <Fills className={classes.item} />
          </RowInner>
          <RowInner>
            {IsDefaultCheckbox()}
            {IsActiveCheckbox()}
            {permissions.ViewDeleted && permissions.Delete && IsDeletedCheckbox()}
          </RowInner>
          <RowInner>{EditActions()}</RowInner>
        </ColCard>
      </div>
    );
  };

  return (
    <Dialog toolbar onClose={close} title={title} actions={<WindowActions />}>
      {edited && EditForm()}
      {!edited && (
        <div className={classes.filterWrap}>
          <QuickFilterSearch
            rows={rows}
            columns={columns}
            setFilteredRows={setFilteredRows}
            style={{ marginTop: 0, paddingBottom: 14 }}
          />
          {XgridActions()}
        </div>
      )}
      <XGrid
        className={classes.gridWrap}
        columns={columns}
        rows={filteredRows}
        loading={!loaded}
        rowHeight={38}
        disableMultipleSelection={true}
        showToolbar
        disableColumnFilter
        checkboxSelection={false}
        disableSelectionOnClick={edited}
        onSelectionModelChange={(newSelection) => {
          setSelection(newSelection.selectionModel[0]);
        }}
      />
    </Dialog>
  );
}

const mapStateToProps = (state) => {
  return {
    permissions: state.permissions.dictionary,
    dictionary: state.dictionary,
  };
};

export default connect(mapStateToProps, {
  closeEditDictionary,
  handleError,
  showSpinner,
  hideSpinner,
  addDictionaryValue,
  updateDictionaryValue,
})(ExampleWindow);
