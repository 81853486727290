import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { XGrid, GridToolbar } from '@material-ui/x-grid';
import { arraysWithSameValues, genColHeaderName } from '../../utils/functions';
import ColumnFinder from './ColumnFinder';
import CheckIcon from '@material-ui/icons/Check';
import { displayDateTime } from 'reducers/TimeReducer';
import PrintsSearch3 from 'components/RMSSearch/components/PrintsSearch3';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  gridWrap: {
    width: '100%',
    // height: '84vh',
  },
  gridContainer: {
    '& .MuiDataGridPanelWrapper-root': {
      boxSizing: 'content-box', // Override box-sizing property
    },
    '& .MuiDataGrid-toolbar > button': {
      color: theme.palette.toolbarColor,
    },
  },
  panel: {
    '& .MuiDataGridPanelFooter-root': {
      display: 'none',
    },
  },
  '@global': {
    '*::-webkit-scrollbar': {
      width: 10,
    },
    '*::-webkit-scrollbar-track': {
      width: 10,
      backgroundColor: '#ddd',
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '*::-webkit-scrollbar-thumb': {
      width: 10,
      backgroundColor: '#1976d2',
      borderRadius: 1,
    },
  },
}));

const bool = (params) => (params.value ? <CheckIcon /> : <span></span>);

function RMSXGrid(props) {
  const classes = useStyles();
  const {
    name,
    columns,
    setColumns,
    rows,
    setSelection,
    printFlag,
    viewDetails,
    refresh,
    keyboard,
    allowedKeys = ['F5', 'Ctrl+KeyP', 'Ctrl+KeyL'],
    disableMultipleSelection = true,
    xGridHeight = 600,
  } = props;
  const [tables, setTables] = useState(null);
  const [colsSeq, setColsSeq] = useState([]);
  const [xgridCols, setXgridCols] = useState([]);
  const [kbdKey, setKbdKey] = useState(null);
  const kbdTimeoutRef = useRef(0);

  useEffect(() => {
    const validatedCols = validateColumns();
    if (!name) return;
    const fetchedLSTables = getLocalSearchTables(validatedCols);
    setTables(fetchedLSTables);
    const { sequence } = fetchedLSTables[name];
    updateColsOrder(sequence, validatedCols);
    setColsSeq(sequence);
  }, []);

  useEffect(() => {
    const kbdSubscription = keyboard.subject.subscribe(
      (keyCode) => allowedKeys.indexOf(keyCode) !== -1 && setKbdKey(keyCode)
    );
    return () => {
      kbdSubscription.unsubscribe();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (colsSeq.length === 0 || xgridCols.length === 0) return;
    saveLSTSequence(name, colsSeq);
    updateColsOrder(colsSeq);
  }, [colsSeq]);

  useEffect(() => {
    if (!tables) return;
    saveLocalSearchTables(tables);
    updateColsHideProperty();
  }, [tables]);

  useEffect(() => {
    if (xgridCols.length > 0) setColumns(xgridCols);
  }, [xgridCols]);

  useEffect(() => {
    if (kbdKey) handleKeyPress();
    kbdTimeoutRef.current = setTimeout(() => {
      setKbdKey(null);
    }, 500);
  }, [kbdKey]);

  const handleKeyPress = () => {
    switch (kbdKey) {
      case 'F5':
        refresh();
        break;
      case 'Ctrl+KeyP':
        viewDetails();
        break;
    }
  };

  const validateColumns = () => {
    const newColumns = columns.map((col) => {
      if (typeof col === 'string') {
        for (const cf of ColumnFinder) {
          if (cf.field === col) return cf;
        }
        return colObj(col);
      }
      const { width, headerName, field, format } = col;
      if (!width) col.width = 150;
      if (!headerName) col.headerName = genColHeaderName(field);
      if (format === 'date') col.valueFormatter = (params) => displayDateTime(params.value);
      if (format === 'bool') col.renderCell = bool;
      return col;
    });
    setXgridCols(newColumns);
    return newColumns;
  };

  const colObj = (col) => {
    const obj = {
      field: col,
      headerName: genColHeaderName(col),
      width: 150,
    };
    return obj;
  };

  const saveLSTSequence = (table, cols, validatedCols = xgridCols) => {
    const currentTables = getLocalSearchTables(validatedCols);
    const targetTable = currentTables[[table]];
    const newTables = { ...currentTables, [table]: { ...targetTable, sequence: cols } };
    localStorage.setItem('SearchTables', JSON.stringify(newTables));
    setTables(newTables);
  };

  const getLocalSearchTables = (validatedCols) => {
    const initialColSeq = validatedCols.map((col) => col.field);
    const currentTable = {
      [name]: {
        hiddenCols: [],
        sequence: initialColSeq,
      },
    };
    const savedTables = localStorage.getItem('SearchTables');
    if (!savedTables) {
      localStorage.setItem('SearchTables', JSON.stringify(currentTable));
      return currentTable;
    }
    const parsedTables = JSON.parse(savedTables);
    if (parsedTables[name] && arraysWithSameValues(parsedTables[name].sequence, initialColSeq))
      return parsedTables;
    const newTables = { ...parsedTables, ...currentTable };
    localStorage.setItem('SearchTables', JSON.stringify(newTables));
    return newTables;
  };

  const saveLocalSearchTables = (tables) =>
    localStorage.setItem('SearchTables', JSON.stringify(tables));

  const updateColsHideProperty = () => {
    if (!name) return;
    const { hiddenCols } = tables[name];
    const columnsClone = [...xgridCols];
    for (const col of columnsClone) {
      col.hide = Boolean(hiddenCols.find((hc) => hc === col.field));
    }
    setXgridCols(columnsClone);
  };

  const handleColVisChange = (col) => {
    if (!col || !name) return;
    const tablesClone = { ...tables };
    if (col.isVisible) {
      const filteredHiddenCols = tablesClone[name].hiddenCols.filter((c) => c !== col.field);
      const filteredTables = {
        ...tablesClone,
        [name]: { ...tablesClone[name], hiddenCols: filteredHiddenCols },
      };
      setTables(filteredTables);
    } else {
      tablesClone[name].hiddenCols.push(col.field);
      setTables(tablesClone);
    }
  };

  const handleColOrderChange = (col) => {
    if (!col || !name) return;
    const seqClone = [...colsSeq];
    const { oldIndex, targetIndex } = col;
    const current = seqClone[oldIndex];
    if (oldIndex < targetIndex) {
      for (let i = oldIndex; i < targetIndex; i++) {
        seqClone[i] = seqClone[i + 1];
      }
    } else {
      for (let i = oldIndex; i > targetIndex; i--) {
        seqClone[i] = seqClone[i - 1];
      }
    }
    seqClone[targetIndex] = current;
    setColsSeq(seqClone);
  };

  const updateColsOrder = (sequence, validatedCols = xgridCols) => {
    if (!name) return;
    const columnsClone = [...validatedCols];
    const result = [];
    sequence.forEach((c) => {
      const current = columnsClone.find((cc) => cc.field === c);
      result.push(current);
    });
    setXgridCols(result);
  };

  const onSelectionModelChange = (newSelection) => {
    if (disableMultipleSelection) setSelection(newSelection.selectionModel[0]);
    else setSelection(newSelection.selectionModel);
  };
  return (
    <div className={clsx(classes.gridWrap, classes.gridContainer)} style={{ height: xGridHeight }}>
      <XGrid
        columns={xgridCols}
        rowHeight={38}
        {...props}
        disableMultipleSelection={disableMultipleSelection}
        showToolbar
        // disableColumnFilter
        onSelectionModelChange={onSelectionModelChange}
        components={{ Toolbar: GridToolbar }}
        // componentsProps={{ panel: { className: classes.panel } }}
        componentsProps={{ panel: { disablePortal: true } }}
        onColumnVisibilityChange={(col) => handleColVisChange(col)}
        onColumnOrderChange={(col) => handleColOrderChange(col)}
      />
      <PrintsSearch3 title={name} data={rows} cols={columns} kbdKey={kbdKey} print={printFlag} />
    </div>
  );
}

const mapStateToProps = (state) => ({
  keyboard: state.keyboard,
});

export default connect(mapStateToProps)(RMSXGrid);
