import React from 'react';

import { Fab, Tooltip } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

export default function RMSCloseButton(props) {
  const { onClick, disabled, size, styles, tooltipText } = props;

  return (
    <Tooltip title={tooltipText || 'Close'}>
      <Fab
        size="small"
        disabled={disabled}
        color="secondary"
        style={styles}
        aria-label="close"
        className={props.className ? props.className : ''}>
        <CloseIcon onClick={onClick} style={{ fontSize: size }} />
      </Fab>
    </Tooltip>
  );
}
