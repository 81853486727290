import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { advancedFilter } from 'utils/functions';

const useStyles = makeStyles((theme) => ({
  filter: {
    minWidth: 548,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
}));

function QuickFilterSearch(props) {
  const classes = useStyles();
  const { rows, columns, setFilteredRows, style } = props;
  const [filter, setFilter] = useState('');

  useEffect(() => {
    if (!filter) {
      setFilteredRows(rows);
    } else {
      setFilteredRows(advancedFilter(filter, rows, columns));
    }
    if (props.setData !== undefined) {
      props.setData();
    }
    // eslint-disable-next-line
  }, [filter, rows]);

  const quickFilter = (ev) => {
    const val = ev.target.value;
    setFilter(val);
  };

  return (
    <TextField
      label="Quick filter"
      type="search"
      className={classes.filter}
      variant="outlined"
      size="small"
      onChange={quickFilter}
      value={filter}
      style={style}
    />
  );
}

export default QuickFilterSearch;
