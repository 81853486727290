import store from '../../config/configureStore';
import { incIncidentSchema } from 'simpl-schema-validation/schema';
import { generateHandleEditIncident } from 'simpl-schema-validation/helpers/generateHandleEdit';

export const validateIncIncident = () => {
  const currentState = store.store.getState().incident;
  const { incident, offenses, turnOfValidation } = currentState;
  if (turnOfValidation) return [];

  let validationErrors = [];

  let fullName = `${incident.CustomId}`;

  let objToValidate = {
    ibrStatus: incident.values.ibrStatus,
    ibrSubStatusDate: incident.values.ibrSubStatusDate
      ? new Date(incident.values.ibrSubStatusDate)
      : null,
    offenses: offenses,
  };

  let incIncidentValidationContext = incIncidentSchema.newContext();
  let result = incIncidentValidationContext.validate(objToValidate, {
    keys: ['ibrStatus', 'ibrSubStatusDate'],
  });

  if (!result) {
    incIncidentValidationContext.validationErrors().forEach((error) => {
      validationErrors.push({
        id: currentState.ptsIncidentId,
        idType: 'ptsIncidentId',
        errType: error.type,
        field: error.name,
        screen: 'Incident',
        name: fullName,
        stepper: 3,
        message: incIncidentValidationContext.keyErrorMessage(error.name),
        ptsIncidentId: currentState.ptsIncidentId,
        handleEdit: generateHandleEditIncident(currentState.ptsIncidentId, 3),
        identifier: `${currentState.ptsIncidentId}-${error.type.split('-').pop()}-${
          error.name
        }-Incident`,
      });
    });
  }

  return validationErrors;
};
