import store from '../../config/configureStore';
import { setIsFormDirty } from 'reducers/IncReducer';
/**
 * Helper function for handling all form change events
 * @param {object} event
 * @param {object} newValue
 * @param {string} reason
 * @param {string} field
 * @param {function} setStateFunc
 * @param {string} optionFlag
 */

export const handleFormChangeNew = (
  event,
  newValue,
  reason,
  field,
  setStateFunc,
  optionFlag = ''
) => {
  const incState = store.store.getState().incident;
  if (reason === 'reset') return;

  if (reason !== 'setDefaultValue') store.store.dispatch(setIsFormDirty(true));

  if (field === 'relationship') {
    setStateFunc((formState) => ({
      ...formState,
      values: {
        relationship: {
          offenderId: formState.values.relationship.offenderId,
          victimId: formState.values.relationship.victimId,
          relationship: newValue,
        },
      },
    }));
  } else {
    if (newValue && optionFlag) {
      setStateFunc((formState) => ({
        ...formState,
        changes: true,
        values: {
          ...formState.values,
          [field]: newValue,
        },
      }));
    } else if (newValue) {
      setStateFunc((formState) => ({
        ...formState,
        changes: true,
        values: {
          ...formState.values,
          [field]: newValue,
        },
      }));
    } else {
      if (
        field === 'isUnknownParty' ||
        field === 'isSociety' ||
        field === 'isVictim' ||
        field === 'isOffender' ||
        field === 'excludeOffense'
      ) {
        setStateFunc((formState) => ({
          ...formState,
          values: {
            ...formState.values,
            [field]: false,
          },
        }));
      } else {
        setStateFunc((formState) => ({
          ...formState,
          values: {
            ...formState.values,
            [field]: '',
          },
        }));
      }
    }
  }
};

/**
 * Helper function for processing values from Autocomplete fields with multiple values and then saving the values
 * @param {object} event
 * @param {object} newValue
 * @param {string} reason
 * @param {string} field
 * @param {function} setStateFunc
 * @param {string} optionFlag
 */
export const handleFormChangeNewMultipleValue = (
  event,
  values,
  reason,
  field,
  setStateFunc,
  optionFlag = '',
  codesArray
) => {
  let newValues = JSON.stringify(
    values.map((v) => codesArray.find((el) => el.Description === v)?.Code ?? v)
  );
  handleFormChangeNew(event, newValues, reason, field, setStateFunc, optionFlag);
};

/**
 * Helper function for setting full address for address forms
 * @param {useState} formState
 * @returns {string}
 */
export const setFullAddressText = (formState) => {
  const address = `${formState.values.addressNumberPrefix || ''} ${
    formState.values.addressNumber || ''
  } ${formState.values.addressNumberSuffix || ''} ${formState.values.preDirection || ''} ${
    formState.values.streetName || ''
  } ${formState.values.streetType || ''} ${formState.values.postDirection || ''} ${
    formState.values.city || ''
  } ${formState.values.State || ''} ${formState.values.postalCode || ''} ${
    formState.values.postalCodeExtension || ''
  } ${formState.values.county || ''} ${formState.values.country || ''}`;

  return address.replace(/\s\s+/g, ' ').trim();
};
/**
 * Helper function for search address field
 * @param {object} place
 * @param {function} setFormState
 * @param {function} setChangeKey
 * @param {useState} changeKey
 */
export const onPlaceValueChange = (
  place,
  setFormState,
  setChangeKey,
  changeKey,
  contact = false
) => {
  // Dont reset Contact
  if (contact === true) {
    setFormState((formState) => ({
      ...formState,
      ptsAddressId: place.ptsAddressID,
      ptsPlaceId: place.ptsPlaceID,
      ptsCoordinateId: place.ptsCoordinateID,
      values: {
        ...formState.values,
        placeName: place.PlaceName,
        placeType: place.PlaceType,
        placeORI: place.PlaceORI,
        placeDepID: place.PlaceDepartmentID,
        destination: place.IsDestination,
        addressNumberPrefix: place.AddressNumberPrefix,
        addressNumber: place.AddressNumber,
        addressNumberSuffix: place.AddressNumberSuffix,
        streetName: place.StreetName,
        preDirection: place.PreDirection,
        postDirection: place.PostDirection,
        state: place.State,
        city: place.CityDescription,
        county: place.County,
        country: place.Country,
        postalCode: place.PostalCode,
        postalCodeExtension: place?.PostalCodeExtension,
        fullAddressText: place.FullAddressText,
        streetType: place.StreetType,
        ptsPlaceId: place.ptsPlaceID,
      },
    }));
  } else {
    setFormState((formState) => ({
      ...formState,
      ptsAddressId: place.ptsAddressID,
      ptsPlaceId: place.ptsPlaceID,
      ptsCoordinateId: place.ptsCoordinateID,
      values: {
        ...formState.values,
        addressNumberPrefix: place.AddressNumberPrefix,
        addressNumber: place.AddressNumber,
        addressNumberSuffix: place.AddressNumberSuffix,
        streetName: place.StreetName,
        preDirection: place.PreDirection,
        postDirection: place.PostDirection,
        state: place.State,
        city: place.CityDescription || place.City?.CityDescription,
        county: place.County,
        country: place.Country,
        postalCode: place.PostalCode,
        postalCodeExtension: place?.PostalCodeExtension,
        streetType: place.StreetType,
        textmask1: place.Info && place.Info[0] === '(' ? place.Info : '',
        textmask2: '',
        emailmask: place.Info && place.Info[0] !== '(' ? place.Info : '',
        notes: place?.notes,
        placeId: place.PlaceID,
        addressId: place.AddressID,
        fullAddressText: place?.FullAddressText,
        placeName: place.PlaceName,
        placeType: place.PlaceType,
        placeORI: place.PlaceORI,
        placeDepID: place.PlaceDepartmentID,
        destination: place.IsDestination,
        ptsPlaceId: place.ptsPlaceID,
      },
    }));
  }

  setChangeKey(changeKey + 1);
};
/**
 * Helper function for handling onReset event for address search
 * @param {function} setFormState
 */
export const clearPlace = (setFormState) => {
  setFormState((formState) => ({
    ...formState,
    ...(formState.addressNumberPrefix = ''),
    ...(formState.addressNumber = ''),
    ...(formState.addressNumberSuffix = ''),
    ...(formState.streetName = ''),
    ...(formState.preDirection = ''),
    ...(formState.postDirection = ''),
    ...(formState.state = ''),
    ...(formState.city = ''),
    ...(formState.county = ''),
    ...(formState.country = ''),
    ...(formState.postalCode = ''),
    ...(formState.postalCodeExtension = ''),
    ...(formState.fullAddressText = ''),
    ...(formState.streetType = ''),
    ...(formState.placeName = ''),
    ...(formState.placeORI = ''),
    ...(formState.placeDepID = ''),
    ...(formState.placeType = ''),
    ...(formState.destination = ''),
    ...(formState.partyType = ''),
  }));
};
/**
 * Helper function for handling onReset event for address search
 * @param {function} setFormState
 */
export const clearPartyPlace = (setFormState) => {
  setFormState((formState) => ({
    ...formState,
    ...(formState.values.placeName = ''),
    ...(formState.values.placeORI = ''),
    ...(formState.values.placeDepID = ''),
    ...(formState.values.placeType = ''),
    ...(formState.values.destination = ''),
  }));
};
/**
 * Helper function for search vehicle field
 * @param {object} vehicle
 * @param {function} setFormState
 * @param {function} setChangeKey
 * @param {useState} changeKey
 */
export const onVehicleValueChange = (vehicle, setFormState, setChangeKey, changeKey) => {
  setFormState((formState) => ({
    ...formState,
    ...(formState.values.year = vehicle.Year),
    ...(formState.values.make = vehicle.Make),
    ...(formState.values.model = vehicle.Model),
    ...(formState.values.style = vehicle.Style),
    ...(formState.values.passengerQuantity = vehicle.PassengerSafeQty),
    ...(formState.values.seatQuantity = vehicle.SeatQty),
    ...(formState.values.primaryColor = vehicle.PrimaryColor),
    ...(formState.values.secondaryColor = vehicle.SecondaryColor),
    ...(formState.values.interior = vehicle.InteriorColor),
    ...(formState.values.classification = vehicle.Classification),
    ...(formState.values.brand = vehicle.Brand),
    ...(formState.values.brander = vehicle.Brander),
    ...(formState.values.description = vehicle.Description),
  }));
  setChangeKey(changeKey + 1);
};
