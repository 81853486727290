import store from '../../../config/configureStore';

import { setOffensesTab } from 'reducers/ui/UiFormReducer';

export const generateHandleEditOffense = (ptsIncidentId, ptsOffenseId, stepperNumber) => {
  return (history) => {
    let editUrl = `/incidents/edit/${ptsIncidentId}/offenses/${ptsOffenseId}`;
    history.push(editUrl);
    // redux action for stepper change
    store.store.dispatch(setOffensesTab(stepperNumber));
  };
};
