export const groupBOffenses = [
  '90A',
  '90B',
  '90C',
  '90D',
  '90E',
  '90F',
  '90G',
  '90H',
  '90J',
  '90Z',
];
