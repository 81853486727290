import { getService } from './service';
import { addCreatedUpdatedBy } from 'utils/functions';

export const getLocationHistoryCount = (location, ptsEventID) => {
  const service = getService();
  return service.get({ type: 'location-hist-count', data: { location, ptsEventID } });
};

export const getAddressHistory = (ptsAddressID) => {
  const service = getService('cad-address-history');
  return service.find({ query: { ptsAddressID } });
};

export const getAddressSops = (ptsAddressID) => {
  const service = getService('cad-address-sop');
  const type = 'RMS';
  return service.find({ query: { ptsAddressID, type } });
};

export const deleteAddressSops = async (query) => {
  const service = getService('rms-assigned-sops');
  return await service.remove(query);
};

export const getAddressParties = (ptsAddressID) => {
  const service = getService('cad-address-parties');
  return service.find({ query: { ptsAddressID } });
};

export const saveAddressParty = async (data) => {
  const saveData = addCreatedUpdatedBy(data);
  const service = getService('cad-address-parties');
  return await service.create(saveData);
};

export const saveAddressSOP = async (data) => {
  const saveData = addCreatedUpdatedBy(data);
  const service = getService('record-person-sops');
  return await service.create(saveData);
};

export const deleteAddressSOP = async (query) => {
  const service = getService('rms-assigned-sops');
  return await service.remove(query);
};

export const delAddressParty = async (ROWGUID) => {
  const service = getService('cad-address-parties');
  return await service.remove(ROWGUID);
};

export const updateAddressHistory = (ptsEventID, AddressHistory) => {
  const service = getService('cad-address-history');
  return service.patch(ptsEventID, { AddressHistory });
};

export const getAddressDetails = (ptsAddressID) => {
  const service = getService('ptsaddress');
  return service.find({ query: { ptsAddressID } });
};

export const getAddressCoordinates = (ptsCoordinateID) => {
  const service = getService('cad-address-coordinates');
  return service.get(ptsCoordinateID);
};

export const getPtsCoordinateID = (ptsAddressID) => {
  const service = getService('cad-address-pts-coordinate-id');
  return service.get(ptsAddressID);
};

export const getPrePlanDetails = (ptsAddressID) => {
  const service = getService('cad-address-pre-plan');
  return service.get(ptsAddressID);
};

export const getPrePlanInspections = (ptsAddressID) => {
  const service = getService('cad-address-pre-plan-inspections');
  return service.get(ptsAddressID);
};

export const savePrePlanInspection = async (data) => {
  const service = getService('cad-address-pre-plan-inspections');
  return await service.create(data);
};

export const delPrePlanInspection = async (id) => {
  const service = getService('cad-address-pre-plan-inspections');
  return await service.remove(id);
};

export const getAddressDuplicates = async (address) => {
  const service = getService('rms-address-duplicates');
  return await service.find({ query: address });
};

export const checkMergeStats = async (ptsAddressID) => {
  const service = getService('cad-address-merge');
  return await service.find({ query: { ptsAddressID } });
};

export const addressMerge = async (data) => {
  const service = getService('cad-address-merge');
  return await service.create(data);
};
