import React from 'react';
import { connect } from 'react-redux';

import clsx from 'clsx';
import Draggable from 'react-draggable';
import { makeStyles } from '@material-ui/core/styles';

import TopBar from '../components/TopBar';
import RMSDuplicateDialogModal from '../components/RMSDuplicateDialogModal';
import CodeCrudDialog from 'components/RMSModals/codeCrudDialog';

const drawerWidth = 240;
const drawerHeight = 70;

const useStyles = makeStyles((theme) => ({
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.palette.bgElements,
  },
  appBarBottom: {
    backgroundColor: theme.palette.bgElements,
    zIndex: theme.zIndex.drawer + 1,
    top: 'auto',
    bottom: 0,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: {
    height: drawerHeight,
    backgroundColor: theme.palette.bgElements,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: theme.palette.bgElements,
    padding: '0px 0px',
  },
  content: {
    width: '100%',
    height: '100%',
    display: 'flex',
    backgroundColor: theme.palette.bg,
  },
  contentInner: {
    flex: 1,
    position: 'relative',
    overflow: 'hidden',
    boxSizing: 'border-box',
    padding: `${drawerHeight}px 14px 10px 10px`,

    minHeight: '100vh',
    maxWidth: '100%',
    '& > div': {
      minHeight: '100vh',
    },
  },
  '@media(max-width: 850px)': {
    contentInner: {
      padding: `${drawerHeight}px 10px 10px`,
    },
  },
  floatingBox: {
    position: 'absolute',
    left: '35%',
    top: '15%',
    padding: '0px',
    borderRadius: '4px',
    transition: 'box-shadow 1s',
    zIndex: '1001',
    cursor: 'grabbing',
  },
  floatingBoxPerson: {
    position: 'absolute',
    left: '25%',
    top: '15%',
    padding: '0px',
    borderRadius: '4px',
    transition: 'box-shadow 1s',
    zIndex: '1001',
    cursor: 'grabbing',
  },
}));

const MainLayout = (props) => {
  const { children, contentBackground, uiDuplicateModalData } = props;
  const classes = useStyles();

  const {
    showDuplicateModal,
    duplicateFoundBtnClicked,
    duplicateListOfPerson,
  } = uiDuplicateModalData;

  return (
    <div className={clsx('app-wrapper', contentBackground)}>
      <TopBar />
      <div className={classes.content}>
        {showDuplicateModal && duplicateFoundBtnClicked && (
          <Draggable defaultPosition={{ x: 0, y: 100 }} position={null}>
            <div
              className={
                duplicateListOfPerson.length > 0 ? classes.floatingBoxPerson : classes.floatingBox
              }>
              <RMSDuplicateDialogModal />
            </div>
          </Draggable>
        )}
        <div className={classes.contentInner}>{children}</div>
      </div>
      <CodeCrudDialog />
    </div>
  );
};

const mapStateToProps = (state) => ({
  headerFixed: state.theme.headerFixed,
  headerDrawerToggle: state.theme.headerDrawerToggle,
  contentBackground: state.theme.contentBackground,
  menuType: state.uiMenu.menuTypeState,
  showDuplicateModal: state.uiModal.showDuplicateModal,
  uiDuplicateModalData: state.uiModal,
});

export default connect(mapStateToProps)(MainLayout);
