import React from 'react';

function Army62(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="62" height="62" viewBox="0 0 62 62" {...props}>
      <g transform="translate(0.000000,62.000000) scale(0.100000,-0.100000)" stroke="none">
        <path d="M157 594 c-4 -4 -7 -110 -7 -235 l0 -228 72 -55 c39 -31 79 -56 88 -56 9 0 49 25 88 56 l72 56 -2 231 -3 232 -151 3 c-82 1 -153 -1 -157 -4z m174 -47 c14 -10 15 -16 4 -30 -7 -9 -18 -17 -25 -17 -7 0 -18 8 -25 17 -11 14 -10 20 4 30 9 7 18 12 21 12 3 0 12 -5 21 -12z m64 -107 c29 -27 31 -38 9 -47 -9 -3 -23 3 -35 16 -26 28 -91 29 -119 1 -22 -22 -36 -25 -45 -9 -8 12 21 48 50 60 36 16 113 4 140 -21z m-45 -70 c0 -36 -30 -56 -60 -40 -13 7 -20 21 -20 40 0 28 2 30 40 30 38 0 40 -2 40 -30z m-55 -75 c16 -11 16 -11 90 43 l45 33 0 -24 c0 -18 -15 -36 -55 -66 -30 -22 -59 -41 -65 -41 -6 0 -35 19 -65 41 -40 30 -55 48 -55 66 l0 24 45 -33 c25 -18 52 -37 60 -43z m-26 -69 l41 -29 41 29 c87 62 79 59 79 32 0 -17 -14 -34 -52 -62 -28 -21 -57 -39 -64 -40 -7 0 -37 17 -68 39 -41 29 -56 46 -56 64 0 26 -8 29 79 -33z m-12 -76 l53 -41 53 41 c61 46 67 47 67 21 0 -12 -23 -38 -60 -66 l-60 -46 -60 46 c-37 28 -60 54 -60 66 0 26 6 25 67 -21z" />
      </g>
    </svg>
  );
}

export default Army62;
