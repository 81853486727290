/**
 * Menu item configurations for desktop and mobile
 */

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    label: 'Home',
    tooltip: 'Home',
    link: '/home',
    icon: 'home',
    disabled: false,
  },
  {
    label: 'Incidents',
    tooltip: 'Incidents',
    link: '/incidents',
    icon: 'edit',
    disabled: false,
    permissionType: 'rms',
    permissionName: 'Incidents',
    permissionAgency: 'specific',
  },
  {
    label: 'Citation',
    tooltip: 'Citation',
    link: '/citations',
    icon: 'id-card',
    disabledCheck: true,
    disabled: false,
    permissionType: 'globals',
    permissionName: 'Citations',
    permissionAgency: 'any',
  },
  {
    label: 'Evidence',
    tooltip: 'Evidence',
    link: '/evidence',
    icon: 'fingerprint',
    disabledCheck: true,
    disabled: false,
    permissionType: 'rms',
    permissionName: 'Evidence',
    permissionAgency: 'specific',
  },

  {
    label: 'Warrants',
    tooltip: 'Warrants',
    link: '/warrants',
    icon: 'exclamation',
    disabled: false,
    permissionType: 'globals',
    permissionName: 'Warrants',
    permissionAgency: 'any',
  },

  {
    label: 'Interviews',
    tooltip: 'Interviews',
    link: '/interviews',
    icon: 'question-circle',
    disabled: true,
  },
  {
    label: 'Search',
    tooltip: 'Search',
    link: '#',
    icon: 'search',
    disabled: false,
  },
  {
    label: 'Reports',
    tooltip: 'Reports',
    link: '#',
    icon: 'print',
    disabled: false,
  },
  // {
  //   label: 'Host Val',
  //   tooltip: 'Hosting Validation',
  //   link: '/hosting-validation',
  //   icon: 'user-cog',
  //   disabled: false,
  // },
];
