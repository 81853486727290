export const POPUP_MODAL = 'POPUP_MODAL';
export const SET_SERVICE_NAME = 'SET_SERVICE_NAME';
export const SET_UPDATABLE_CODE_OBJECT = 'SET_UPDATABLE_CODE_OBJECT';
export const SET_MODE = 'SET_MODE';
export const SET_LABEL = 'SET_LABEL';
export const RESET_FORM = 'RESET_FORM';
export const RESET_FIELD = 'RESET_FIELD';

const defaultState = {
  label: '',
  openModal: false,
  mode: '',
  serviceName: '',
  resetField: null,
  updatableCodeObject: {},
};

export const popupModal = (value) => {
  return { type: POPUP_MODAL, payload: value };
};
export const setService = (value) => {
  return { type: SET_SERVICE_NAME, payload: value };
};
export const setUpdatableObject = (value) => {
  return { type: SET_UPDATABLE_CODE_OBJECT, payload: value };
};
export const setMode = (value) => {
  return { type: SET_MODE, payload: value };
};

export const setLabel = (value) => {
  return { type: SET_LABEL, payload: value };
};
export const resetForm = (value) => {
  return { type: RESET_FORM, payload: value };
};
export const resetFieldFunc = (func) => {
  return { type: RESET_FIELD, payload: func };
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case POPUP_MODAL:
      return { ...state, openModal: action.payload };
    case SET_SERVICE_NAME:
      return { ...state, serviceName: action.payload };
    case SET_UPDATABLE_CODE_OBJECT:
      return { ...state, updatableCodeObject: action.payload };
    case SET_MODE:
      return { ...state, mode: action.payload };
    case SET_LABEL:
      return { ...state, label: action.payload };
    case RESET_FORM:
      return {
        label: '',
        open: false,
        mode: '',
        serviceName: '',
        updatableCodeObject: {},
      };
    case RESET_FIELD:
      return {
        ...state,
        resetField: action.payload,
      };
    default:
      return state;
  }
}
