import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';

import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import { Fade, List, ListItem, alpha } from '@mui/material';

import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Fab, Tooltip, Box } from '@material-ui/core';

import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import { Person } from '@material-ui/icons';

import formStyles, { gridStyle, Row, RowInner, ColCard, Fills } from 'utils/formStyles';
import { decodePhoneNo, validatePhoneNo } from 'utils/formStyles';

import { useError } from 'utils/formStyles';
import { validateEmail } from 'utils/functions';

import RMSFormDivider from 'components/RMSFormDivider';
import AddressLookup from 'components/RMSAddressLookup';
import RMSTextField from 'components/RMSTextField';
import RMSDictionary from 'components/RMSAutoComplete/RMSDictonary';
import Dialog from 'components/RMSDialog';
import RMSPersonInfoCard from 'components/RMSPersonInfoCard';
import { SSNTextMask } from 'components/RMSTextMask';
import BackDatedRecordHistory from 'components/BackDatedRecordHistory';
import PersonNameDialog from 'components/RMSRecordsDialogs/EditPersonDialog/PersonNameDialog';

import { getPtsPerson, addOrEditPerson } from 'reducers/SearchReducer';
import { dateTimePicker, formatSaveDate } from 'reducers/TimeReducer';
import { notify } from 'reducers/NotifierReducer';
import { handleError } from 'reducers/ErrorReducer';
import { notifyDataUpdate } from 'reducers/DataUpdateReducer';
import {
  closeAddPerson,
  closeEditPerson,
  showEditPerson,
  showPersonNameDialog,
  setIsRecordFormDirty,
} from 'reducers/PersonDialogsReducer';
import { duplicateCheck, duplicateParamsCheck } from 'reducers/helpers/duplicateCheckHelpers';
import { getFullPermissions } from 'reducers/PermissionsReducer';

const useStyles = makeStyles((theme) => ({
  ...formStyles,
  wrap: {
    padding: theme.spacing(3),
  },
  item: gridStyle(200, 800),
  field: gridStyle(210, 400),
  actions: {
    textAlign: 'right',
    marginTop: 8,
    '& button': {
      marginLeft: 8,
      '& svg': {
        marginRight: theme.spacing(1),
      },
    },
  },
  closeButton: { color: theme.button.color },
  topRowMargin: {
    marginTop: theme.spacing(4),
  },
  duplicateButton: {
    position: 'fixed',
    top: theme.spacing(8),
    right: theme.spacing(2),
    visibility: 'hidden',
    zIndex: 999,
    marginRight: theme.spacing(2),
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.warning.main,
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: theme.palette.warning.main,
    },
    margin: '5px 4px',
    height: '35px',
  },
  visible: {
    visibility: 'visible',
  },
  listItemRoot: {
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.1),
    },
  },
  divContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  divItem: {
    margin: '5px',
  },
}));

function PersonTab(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const permissions = getFullPermissions('globals', 'People', 'any');

  const { personId, close, setIsRecordFormDirty } = props;
  const {
    codeNamePrefixes,
    codeNameSuffixes,
    codeDeathCauses,
    codeGang,
    codeMaritalStatus,
    codeLanguages,
    codeDangerousType,
    codeResident,
    codeCitizenships,
    codeNationalities,
    codeEthnicity,
    codeEyeColor,
    codeHanded,
    codeBloodType,
    codeHairColor,
    codeHairLength,
    codeHairStyle,
    codeSex,
    codeRace,
    codeCounties,
    codeCountries,
    codeCities,
    codeStates,
    codeContactMethodTypes,
    codeContactMethods,
  } = props.dictionary;
  const { editPerson } = props.dialogs;

  const duplicateListOfPerson = useSelector((state) => state.uiModal.duplicateListOfPerson);
  const currentUserName = useSelector((state) => state.user.userData.user.Username);

  const [firstname, setFirstname] = useState('');
  const [middlename, setMiddlename] = useState('');
  const [lastname, setLastname] = useState('');
  const [nickname, setNickname] = useState('');
  const [prefix, setPrefix] = useState(null);
  const [suffix, setSuffix] = useState(null);
  const [genApp, setGenApp] = useState('');
  const [race, setRace] = useState(null);
  const [sex, setSex] = useState(null);
  const [height, setHeight] = useState('');
  const [weight, setWeight] = useState('');
  const [birthdate, setBirthdate] = useState(null);
  const [birthCity, setBirthCity] = useState(null);
  const [birthCounty, setBirthCounty] = useState(null);
  const [birthCountry, setBirthCountry] = useState(null);
  const [isDeceased, setIsDeceased] = useState(false);
  const [deceasedDate, setDeceasedDate] = useState(null);
  const [causeOfDeath, setCauseOfDeath] = useState(null);
  const [localResident, setLocalResident] = useState(null);
  const [isUSCitizen, setIsUSCitizen] = useState(true);
  const [currentCitizenship, setCurrentCitizenship] = useState(null);
  const [birthCitizenship, setBirthCitizenship] = useState(null);
  const [birthNationality, setBirthNationality] = useState(null);
  const [gang, setGang] = useState(null);
  const [maritalStatus, setMaritalStatus] = useState(null);
  const [educationLevel, setEducationLevel] = useState('');
  const [allergies, setAllergies] = useState('');
  const [dna, setDna] = useState('');
  const [notes, setNotes] = useState('');
  const [primarylanguage, setPrimaryLanguage] = useState(null);
  const [whyDangerous, setWhyDangerous] = useState(null);
  const [fpAvailable, setFpAvailable] = useState(false);
  const [overrideJuvenile, setOverrideJuvenile] = useState(false);
  const [publicOfficial, setPublicOfficial] = useState(false);
  const [englishSpeaking, setEnglishSpeaking] = useState(false);
  const [digitalImage, setDigitalImage] = useState(false);
  const [inJail, setInJail] = useState(false);
  const [carryWeapon, setCarryWeapon] = useState(false);
  const [isDangerous, setIsDangerous] = useState(false);
  const [organDonor, setOrganDonor] = useState(false);
  const [exFelon, setExFelon] = useState(false);
  const [protectiveOrder, setProtectiveOrder] = useState(false);
  const [seniorCitizen, setSeniorCitizen] = useState(false);
  const [officer, setOfficer] = useState(false);
  const [sexOffender, setSexOffender] = useState(false);
  const [juvenile, setJuvenile] = useState(false);
  const [military, setMilitary] = useState(false);
  const [probation, setProbation] = useState(false);
  const [ethnicity, setEthnicity] = useState(null);
  const [eyecolor, setEyecolor] = useState(null);
  const [handed, setHanded] = useState(null);
  const [bloodType, setBloodType] = useState(null);
  const [haircolor, setHaircolor] = useState(null);
  const [hairlength, setHairlength] = useState(null);
  const [hairstyle, setHairStyle] = useState(null);
  const [ssn, setSsn] = useState('');
  const [oln, setOln] = useState('');
  const [state, setState] = useState(null);
  const [birthState, setBirthState] = useState(null);
  const [address, setAddress] = useState('');
  const [isPrimary, setIsPrimary] = useState(false);
  const [contactType, setContactType] = useState(null);
  const [contactMethodType, setContactMethodType] = useState(null);
  const [info, setInfo] = useState('');
  const [isDefault, setIsDefault] = useState(false);
  const [contactNotes, setContactNotes] = useState('');
  const [errors, setErrors] = useState();
  const [personDuplicateParams, setPersonDuplicateParams] = useState([]);
  const [showDuplicateFound, setShowDuplicateFound] = useState(false);
  const [showDuplicateListDialog, setShowDuplicateListDialog] = useState(false);
  const [ssnError, setSsnError] = useState(null);
  const [disabledSaveBtn, setDisabledSaveBtn] = useState(false);
  // name chage checking
  const [precNamesValues, setPrevNamesValues] = useState(null);
  const [dataForNamesDialog, setDataForNamesDialog] = useState(null);

  // record history's state
  const [createdBy, setCreatedBy] = useState(null);
  const [created, setCreated] = useState(null);
  const [updatedBy, setUpdatedBy] = useState(null);
  const [updated, setUpdated] = useState(null);
  const [recordHistoryData, setRecordHistoryData] = useState({
    shouldVisible: true,
    isDisabled: true,
    createUpdateInfo: {},
  });
  const [showRecordHistory, setShowRecordHistory] = useState(false);

  //const { err } = useError(errors, setErrors);
  const { err, delErr, isValidAndChanged, formChanged } = useError(errors, setErrors);

  const isNewRecord = !personId;

  useEffect(async () => {
    if (personId) {
      fetchPersonData();
      setPersonDuplicateParams([]);
    }
    if (!personId) {
      const duplicateParams = await duplicateParamsCheck('PerDuplicate');
      setPersonDuplicateParams(duplicateParams);
    }
  }, [personId]);

  useEffect(async () => {
    if (personDuplicateParams.length === 0) return;
    const personObj = duplicatePersonObj();
    const duplicateFound = await duplicateCheck(personDuplicateParams, personObj, 'person');
    if (duplicateFound) setShowDuplicateFound(true);
    else setShowDuplicateFound(false);

    /* Check if SSN is valid or not */
    if (ssn || ssn === '') {
      const { firstPart, secondPart, thirdPart } = splitSSN(ssn);
      const toCheckValue = parseInt(firstPart);
      const ssnNumberCheck = ssn.replace(/-/g, '');

      if (ssnNumberCheck && ssnNumberCheck.length < 9)
        setSsnError('SSN value can not be less than 9 characters.');
      // else if (toCheckValue > 799)
      //   setSsnError('First three digit of a SSN cannot be greater than 799.');
      // else if (firstPart === '000' || secondPart === '00' || thirdPart === '0000')
      //   setSsnError('None of the three parts of a SSN can be all zeros.');
      else setSsnError(null);
    }
  }, [firstname, middlename, lastname, nickname, birthdate, ssn, oln, prefix, suffix, race, sex]);

  const splitSSN = (value) => {
    let firstPart = '';
    let secondPart = '';
    let thirdPart = '';

    if (value === '   -  -    ' || value === '') {
      return { firstPart: 0, secondPart, thirdPart };
    }

    for (let i of value) {
      if (i !== '-') {
        if (firstPart.length < 3) {
          firstPart += i;
        } else {
          if (secondPart.length < 2) {
            secondPart += i;
          } else {
            if (thirdPart.length < 4) {
              thirdPart += i;
            }
          }
        }
      }
    }

    return { firstPart, secondPart, thirdPart };
  };

  const duplicatePersonObj = () => {
    const formattedDate = moment(birthdate);
    return {
      values: {
        firstName: firstname,
        middleName: middlename,
        lastName: lastname,
        nickName: nickname,
        birthdate: formattedDate.isValid() ? formattedDate.format('YYYY-MM-DD') : null,
        ssn: ssn,
        oln: oln,
        prefix: prefix?.Code,
        suffix: suffix?.Code,
        race: race?.Code,
        sex: sex?.Code,
      },
    };
  };

  const fetchPersonData = async () => {
    const data = await getPtsPerson(personId);
    const {
      Age,
      Allergies,
      BirthCitizenship,
      BirthCountry,
      BirthCounty,
      BirthNationality,
      BirthState,
      BloodType: fetchedBloodType,
      Born,
      CauseOfDeath,
      Created,
      CreatedBy,
      CurrentCitizenship,
      DNA,
      Dangerous,
      Deceased,
      Died,
      EducationLevel,
      EnglishLangIndicator,
      Ethnicity: fetchedEthnicity,
      ExFelon,
      EyeColor: fetchedEyeColor,
      FpString,
      FirstName,
      Gang: fetchedGang,
      GeneralAppearance,
      HairColor: fetchedHairColor,
      HairLength: fetchedHairLength,
      HairStyle: fetchedHairStyle,
      Handed: fetchedHanded,
      Height,
      IsOrganDonor,
      IsUSCitizen,
      Juvenile,
      LastName,
      LocalResident,
      MaritalStatus: fetchedMaritalStatus,
      MiddleName,
      Military,
      NickName,
      Notes,
      Officer,
      OverrideJuvenile,
      Prefix,
      PrimaryLanguage,
      Probation,
      ProtectiveOrder,
      PublicOfficial,
      Race: fetchedRace,
      SeniorCitizen,
      Sex: fetchedSex,
      SexOffender,
      Suffix,
      Updated,
      UpdatedBy,
      Weight,
      WhyDangerous,
      ptsBirthCityID,
      HasDigitalImage,
      Incarcerated,
      ConcealedWeaponPermit,
      PrintsAvailable,
    } = data[0];

    setRecordHistoryData({
      shouldVisible: true,
      isDisabled: !Created,
      createUpdateInfo: {
        created: Created,
        createdBy: CreatedBy,
        updated: Updated,
        updatedBy: UpdatedBy,
      },
    });
    setCreated(Created);
    setCreatedBy(CreatedBy);
    setUpdated(Updated);
    setUpdatedBy(UpdatedBy);

    setFirstname(FirstName || '');
    setMiddlename(MiddleName || '');
    setLastname(LastName || '');
    setNickname(NickName || '');
    const prefix = codeNamePrefixes.find((p) => p.Code === Prefix);
    setPrefix(prefix || null);
    const suffix = codeNameSuffixes.find((s) => s.Code === Suffix);
    setSuffix(suffix || null);
    //set Previous names values
    setPrevNamesValues({
      prevFirstName: FirstName,
      prevMiddlename: MiddleName,
      prevLastName: LastName,
      prevNickName: NickName,
      prevPrefix: prefix || null,
      prevSuffix: suffix || null,
    });
    // end
    setGenApp(GeneralAppearance || '');
    const race = codeRace.find((r) => r.Code === fetchedRace);
    setRace(race || null);
    const sex = codeSex.find((s) => s.Code === fetchedSex);
    setSex(sex || null);
    setHeight(Height || '');
    setWeight(Weight || '');
    setBirthdate(dateTimePicker(Born) || null);
    // setAge(Age);
    const city = codeCities.find((c) => c.ptsCityID === ptsBirthCityID);
    setBirthCity(city || null);
    const birthState = codeStates.find((s) => s.Code === BirthState);
    setBirthState(birthState || null);
    const country = codeCountries.find((c) => c.Code === BirthCountry);
    setBirthCountry(country || null);
    const county = codeCounties.find((c) => c.Code === BirthCounty);
    setBirthCounty(county || null);
    setIsDeceased(Deceased);
    setDeceasedDate(dateTimePicker(Died) || null);
    const deathCause = codeDeathCauses.find((d) => d.Code === CauseOfDeath);
    setCauseOfDeath(deathCause || null);
    const resident = codeResident.find((r) => r.Code === LocalResident);
    setLocalResident(resident || null);
    setIsUSCitizen(IsUSCitizen);
    const curCitizen = codeCitizenships.find((c) => c.Code === CurrentCitizenship);
    setCurrentCitizenship(curCitizen || null);
    const birthCitizen = codeCitizenships.find((c) => c.Code === BirthCitizenship);
    setBirthCitizenship(birthCitizen || null);
    const nationality = codeNationalities.find((n) => n.Code === BirthNationality);
    setBirthNationality(nationality || null);
    const gang = codeGang.find((g) => g.Code === fetchedGang);
    setGang(gang || null);
    const marital = codeMaritalStatus.find((m) => m.Code === fetchedMaritalStatus);
    setMaritalStatus(marital || null);
    setEducationLevel(EducationLevel || '');
    setAllergies(Allergies || '');
    setDna(DNA || '');
    setNotes(Notes || '');
    const primLanguage = codeLanguages.find((l) => l.Code === PrimaryLanguage);
    setPrimaryLanguage(primLanguage || null);
    const dangerCause = codeDangerousType.find((d) => d.Code === WhyDangerous);
    setWhyDangerous(dangerCause || null);
    setFpAvailable(PrintsAvailable);
    setOverrideJuvenile(OverrideJuvenile);
    setPublicOfficial(PublicOfficial);
    setEnglishSpeaking(EnglishLangIndicator);
    setDigitalImage(HasDigitalImage);
    setInJail(Incarcerated);
    setCarryWeapon(ConcealedWeaponPermit);
    setIsDangerous(Dangerous);
    setOrganDonor(IsOrganDonor);
    setExFelon(ExFelon);
    setProtectiveOrder(ProtectiveOrder);
    setSeniorCitizen(SeniorCitizen);
    setOfficer(Officer);
    setSexOffender(SexOffender);
    setJuvenile(Juvenile);
    setMilitary(Military);
    setProbation(Probation);
    const ethnicity = codeEthnicity.find((e) => e.Code === fetchedEthnicity);
    setEthnicity(ethnicity || null);
    const eyeColor = codeEyeColor.find((e) => e.Code === fetchedEyeColor);
    setEyecolor(eyeColor || null);
    const handed = codeHanded.find((h) => h.Code === fetchedHanded);
    setHanded(handed || null);
    const bloodtype = codeBloodType.find((b) => b.Code === fetchedBloodType);
    setBloodType(bloodtype || null);
    const hairColor = codeHairColor.find((h) => h.Code == fetchedHairColor);
    setHaircolor(hairColor || null);
    const hairLength = codeHairLength.find((h) => h.Code === fetchedHairLength);
    setHairlength(hairLength || null);
    const hairStyle = codeHairStyle.find((h) => h.Code === fetchedHairStyle);
    setHairStyle(hairStyle || null);
  };
  const saveErrorBeforeSave = async () => {
    if (disabledSaveBtn) return;
    try {
      setDisabledSaveBtn(true);
      setIsRecordFormDirty(false);

      if (contactMethodType && !isValidAndChanged()) return;
      //checking for name changes
      if (precNamesValues) {
        const {
          prevFirstName,
          prevMiddlename,
          prevLastName,
          prevNickName,
          prevSuffix,
          prevPrefix,
        } = precNamesValues || {};
        if (
          (prevFirstName && firstname && prevFirstName !== firstname) ||
          (prevLastName && lastname && prevLastName !== lastname) ||
          (prevMiddlename && middlename && prevMiddlename !== middlename) ||
          (prevNickName && nickname && prevNickName !== nickname) ||
          (prevSuffix?.Code && suffix?.Code && prevSuffix.Code !== suffix.Code) ||
          (prevPrefix?.Code && prefix?.Code && prevPrefix.Code !== prefix.Code)
        ) {
          setDataForNamesDialog({
            ptsPersonID: personId,
            FirstName: prevFirstName,
            MiddleName: prevMiddlename,
            LastName: prevLastName,
            NickName: prevNickName,
            Prefix: prevPrefix,
            Suffix: prevSuffix,
            origin: 'name-change',
          });

          return;
        }
      }
      await save();
    } catch (err) {
      console.log(err);
    } finally {
      setDisabledSaveBtn(false);
    }
  };
  const save = async () => {
    const FullName = `${firstname} ` + (middlename ? `${middlename} ` : '') + lastname;

    let data = {
      person: {
        FirstName: firstname || null,
        MiddleName: middlename || null,
        LastName: lastname || null,
        FullName,
        NickName: nickname || null,
        Prefix: prefix ? prefix.Code : null,
        Suffix: suffix ? suffix.Code : null,
        GeneralAppearance: genApp || null,
        Race: race ? race.Code : null,
        Sex: sex ? sex.Code : null,
        Height: height || null,
        Weight: weight || null,
        Born: formatSaveDate(birthdate),
        ptsBirthCityID: birthCity ? birthCity.ptsCityID : null,
        BirthState: birthState ? birthState.Code : null,
        BirthCounty: birthCounty ? birthCounty.Code : null,
        BirthCountry: birthCountry ? birthCountry.Code : null,
        Deceased: isDeceased,
        Died: formatSaveDate(deceasedDate),
        CauseOfDeath: causeOfDeath ? causeOfDeath.Code : null,
        LocalResident: localResident ? localResident.Code : null,
        IsUSCitizen: isUSCitizen,
        CurrentCitizenship: currentCitizenship ? currentCitizenship.Code : null,
        BirthCitizenship: birthCitizenship ? birthCitizenship.Code : null,
        BirthNationality: birthNationality ? birthNationality.Code : null,
        Gang: gang ? gang.Code : null,
        MaritalStatus: maritalStatus ? maritalStatus.Code : null,
        EducationLevel: educationLevel || null,
        Allergies: allergies || null,
        DNA: dna || null,
        Notes: notes || null,
        PrimaryLanguage: primarylanguage ? primarylanguage.Code : null,
        WhyDangerous: whyDangerous ? whyDangerous.Code : null,
        PrintsAvailable: fpAvailable,
        OverrideJuvenile: overrideJuvenile,
        PublicOfficial: publicOfficial,
        HasDigitalImage: digitalImage,
        Incarcerated: inJail,
        ConcealedWeaponPermit: carryWeapon,
        Dangerous: isDangerous,
        IsOrganDonor: organDonor,
        ExFelon: exFelon,
        ProtectiveOrder: protectiveOrder,
        SeniorCitizen: seniorCitizen,
        Officer: officer,
        SexOffender: sexOffender,
        Juvenile: juvenile,
        Military: military,
        Probation: probation,
        Ethnicity: ethnicity ? ethnicity.Code : null,
        EyeColor: eyecolor ? eyecolor.Code : null,
        Handed: handed ? handed.Code : null,
        BloodType: bloodType ? bloodType.Code : null,
        HairColor: haircolor ? haircolor.Code : null,
        HairLength: hairlength ? hairlength.Code : null,
        HairStyle: hairstyle ? hairstyle.Code : null,
        EnglishLangIndicator: englishSpeaking,
        // ActiveWarrant: false,
        HasDigitizedSignatureImage: false,
        CreatedBy: createdBy,
      },
    };
    if (!personId) {
      const contactCreds = {
        contact: {
          ContactMethod: contactMethodType ? contactMethodType.Code : null, // mail, phone
          MethodType: contactType ? contactType.Code : null,
          Info: info || null,
          IsDefault: isDefault,
          Notes: contactNotes || null,
          ptsAddressID: address ? address.ptsAddressID : null,
          IsPrimary: isPrimary,
          PartyType: 'Person',
        },
        credentials: {
          SSN: ssn,
          OLN: oln,
          State: state ? state.Code : null,
        },
      };
      Object.assign(data, contactCreds);
    }

    if (contactMethodType && contactMethodType.Code === 'Phone' && info.length > 10) {
      data = { ...data, contact: { ...data.contact, ...decodePhoneNo(info) } };
    }

    try {
      const person = await addOrEditPerson(personId, data);

      const { ptsPersonID, Created, CreatedBy, Updated, UpdatedBy } = person;

      if (personId) {
        setRecordHistoryData({
          shouldVisible: true,
          isDisabled: !Created,
          createUpdateInfo: {
            created: Created,
            createdBy: CreatedBy,
            updated: Updated,
            updatedBy: UpdatedBy,
          },
        });
      } else {
        setRecordHistoryData({
          shouldVisible: true,
          isDisabled: !Created,
          createUpdateInfo: {
            created: Created,
            createdBy: CreatedBy,
            updated: Updated,
            updatedBy: UpdatedBy,
          },
        });
      }
      dispatch(
        notifyDataUpdate({
          type: 'Person',
          data: { action: personId ? 'Update' : 'Create', ptsPersonID },
        })
      );
      props.notify('Person data saved', 'success');
      if (isNewRecord) {
        props.closeAddPerson();
        if (editPerson === null) props.showEditPerson({ ptsPersonID, origin: props.origin });
      }
    } catch (err) {
      props.handleError(err);
    }

    if (props.setData) props.setData();
  };

  const renderActions = () => {
    const handleCloseRecordHistoryDialog = () => setShowRecordHistory(false);

    return (
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        {recordHistoryData?.shouldVisible && (
          <Box className="">
            <BackDatedRecordHistory
              showRecordHistory={showRecordHistory}
              setShowRecordHistory={setShowRecordHistory}
              disabled={recordHistoryData?.isDisabled}
              handleCloseRecordHistoryDialog={handleCloseRecordHistoryDialog}
              createUpdateInfo={recordHistoryData?.createUpdateInfo}></BackDatedRecordHistory>
          </Box>
        )}
        <Button
          color="primary"
          variant="contained"
          autoFocus
          onClick={saveErrorBeforeSave}
          disabled={
            !firstname ||
            !lastname ||
            (!permissions.Create && !permissions.Edit) ||
            ssnError ||
            disabledSaveBtn
          }>
          <SaveIcon /> Save
        </Button>
        <Button color="primary" onClick={close} className={classes.closeButton}>
          <CloseIcon /> Close
        </Button>
      </div>
    );
  };

  const renderPrefix = () => {
    const onChange = (ev, val) => {
      setPrefix(val);
      setIsRecordFormDirty(true);
    };
    const error =
      personDuplicateParams.includes('Prefix') && showDuplicateFound ? 'Duplicate Found' : null;
    return (
      <RMSDictionary
        options="codeNamePrefixes"
        className={classes.item}
        onChange={onChange}
        value={prefix}
        formType={personId ? 'edit' : 'add'}
        label="Prefix"
        error={error}
      />
    );
  };

  const renderSuffix = () => {
    const onChange = (ev, val) => {
      setSuffix(val);
      setIsRecordFormDirty(true);
    };
    const error =
      personDuplicateParams.includes('Suffix') && showDuplicateFound ? 'Duplicate Found' : null;
    return (
      <RMSDictionary
        options="codeNameSuffixes"
        className={classes.item}
        onChange={onChange}
        value={suffix}
        formType={personId ? 'edit' : 'add'}
        label="Suffix"
        error={error}
      />
    );
  };

  const renderFirstname = () => {
    const error = personDuplicateParams.includes('FirstName') && showDuplicateFound;
    return (
      <TextField
        inputProps={{
          autoComplete: 'none',
        }}
        label="First Name"
        variant="outlined"
        onChange={(e) => {
          setFirstname(e.target.value);
          setIsRecordFormDirty(true);
        }}
        value={firstname}
        size="small"
        className={classes.item}
        error={error}
        helperText={error && 'Duplicate Found'}
      />
    );
  };

  const renderMiddleName = () => {
    const error = personDuplicateParams.includes('MiddleName') && showDuplicateFound;
    return (
      <TextField
        label="Middle Name"
        variant="outlined"
        onChange={(e) => {
          setMiddlename(e.target.value);
          setIsRecordFormDirty(true);
        }}
        value={middlename}
        size="small"
        className={classes.item}
        error={error}
        helperText={error && 'Duplicate Found'}
        inputProps={{
          autoComplete: 'none',
        }}
      />
    );
  };

  const renderLastName = () => {
    const error = personDuplicateParams.includes('LastName') && showDuplicateFound;
    return (
      <TextField
        label="Last Name"
        variant="outlined"
        onChange={(e) => {
          setLastname(e.target.value);
          setIsRecordFormDirty(true);
        }}
        value={lastname}
        size="small"
        className={classes.item}
        error={error}
        helperText={error && 'Duplicate Found'}
        inputProps={{
          autoComplete: 'none',
        }}
      />
    );
  };

  const renderNickname = () => {
    const error = personDuplicateParams.includes('NickName') && showDuplicateFound;
    return (
      <TextField
        label="Nick Name"
        variant="outlined"
        onChange={(e) => {
          setNickname(e.target.value);
          setIsRecordFormDirty(true);
        }}
        value={nickname}
        size="small"
        className={classes.item}
        error={error}
        helperText={error && 'Duplicate Found'}
        inputProps={{
          autoComplete: 'none',
        }}
      />
    );
  };

  const renderGeneralAppearance = () => {
    return (
      <TextField
        label="General Appearance"
        variant="outlined"
        onChange={(e) => {
          setGenApp(e.target.value);
          setIsRecordFormDirty(true);
        }}
        value={genApp}
        size="small"
        className={classes.item}
        inputProps={{
          autoComplete: 'none',
        }}
      />
    );
  };

  const renderHeight = () => {
    const handleHeight = (e) => {
      setHeight(e.target.value);
      setIsRecordFormDirty(true);
    };
    return (
      <TextField
        label="Height"
        variant="outlined"
        onChange={handleHeight}
        value={height}
        size="small"
        className={classes.item}
        inputProps={{
          autoComplete: 'none',
        }}
      />
    );
  };

  const renderWeight = () => {
    return (
      <TextField
        label="Weight"
        variant="outlined"
        onChange={(e) => {
          setWeight(e.target.value);
          setIsRecordFormDirty(true);
        }}
        value={weight}
        size="small"
        className={classes.item}
        inputProps={{
          autoComplete: 'none',
        }}
      />
    );
  };

  const renderSex = () => {
    const onChange = (ev, val) => {
      setSex(val);
      setIsRecordFormDirty(true);
    };
    const error =
      personDuplicateParams.includes('Sex') && showDuplicateFound ? 'Duplicate Found' : null;
    return (
      <RMSDictionary
        options="codeSex"
        className={classes.item}
        onChange={onChange}
        value={sex}
        formType={personId ? 'edit' : 'add'}
        label="Sex"
        error={error}
      />
    );
  };

  const renderRace = () => {
    const onChange = (ev, val) => {
      setRace(val);
      setIsRecordFormDirty(true);
    };
    const error =
      personDuplicateParams.includes('Race') && showDuplicateFound ? 'Duplicate Found' : null;
    return (
      <RMSDictionary
        options="codeRace"
        className={classes.item}
        onChange={onChange}
        value={race}
        formType={personId ? 'edit' : 'add'}
        label="Race"
        error={error}
      />
    );
  };

  const renderBirthDate = () => {
    const error = personDuplicateParams.includes('Born') && showDuplicateFound;
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          disableOpenOnEnter
          autoComplete="off"
          disableToolbar
          variant="inline"
          inputVariant="outlined"
          format="MM/dd/yyyy"
          margin="normal"
          label="Birth Date"
          value={birthdate}
          onChange={(date) => {
            setBirthdate(date);
            setIsRecordFormDirty(true);
          }}
          autoOk
          size="small"
          className={classes.item}
          error={error}
          helperText={error && 'Duplicate Found'}
        />
      </MuiPickersUtilsProvider>
    );
  };

  const renderDeceased = () => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={isDeceased}
            onChange={() => {
              setIsDeceased(!isDeceased);
              setIsRecordFormDirty(true);
            }}
            color="primary"
          />
        }
        label="Deceased"
        className={classes.item}
      />
    );
  };

  const renderBirthCity = () => {
    const onChange = (ev, val) => {
      setBirthCity(val);
      setIsRecordFormDirty(true);
    };
    return (
      <RMSDictionary
        options="codeCities"
        className={classes.item}
        onChange={onChange}
        value={birthCity}
        formType={personId ? 'edit' : 'add'}
        label="Birth City"
      />
    );
  };

  const renderBirthState = () => {
    const onChange = (ev, val) => {
      setBirthState(val);
      setIsRecordFormDirty(true);
    };
    return (
      <RMSDictionary
        options="codeStates"
        className={classes.item}
        onChange={onChange}
        value={birthState}
        formType={personId ? 'edit' : 'add'}
        label="Birth State"
      />
    );
  };

  const renderBirthCounty = () => {
    const onChange = (ev, val) => {
      setBirthCounty(val);
      setIsRecordFormDirty(true);
    };
    return (
      <RMSDictionary
        options="codeCounties"
        className={classes.item}
        onChange={onChange}
        value={birthCounty}
        formType={personId ? 'edit' : 'add'}
        label="Birth County"
      />
    );
  };

  const renderBirthCountry = () => {
    const onChange = (ev, val) => {
      setBirthCountry(val);
      setIsRecordFormDirty(true);
    };
    return (
      <RMSDictionary
        options="codeCountries"
        className={classes.item}
        onChange={onChange}
        value={birthCountry}
        formType={personId ? 'edit' : 'add'}
        label="Birth Country"
      />
    );
  };

  const renderDeceasedDate = () => {
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          disableOpenOnEnter
          autoComplete="off"
          disableToolbar
          variant="inline"
          inputVariant="outlined"
          format="MM/dd/yyyy"
          margin="normal"
          label="Deceased Date"
          value={deceasedDate}
          onChange={(date) => {
            setDeceasedDate(date);
            setIsRecordFormDirty(true);
          }}
          autoOk
          size="small"
          className={classes.item}
          disabled={!isDeceased}
        />
      </MuiPickersUtilsProvider>
    );
  };

  const renderCauseOfDeath = () => {
    const onChange = (ev, val) => {
      setCauseOfDeath(val);
      setIsRecordFormDirty(true);
    };
    return (
      <RMSDictionary
        options="codeDeathCauses"
        className={classes.item}
        onChange={onChange}
        value={causeOfDeath}
        formType={personId ? 'edit' : 'add'}
        label="Cause of Death"
      />
    );
  };

  const renderUsCitizen = () => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={isUSCitizen}
            onChange={() => {
              setIsUSCitizen(!isUSCitizen);
              setIsRecordFormDirty(true);
            }}
            color="primary"
          />
        }
        label="US Citizen"
        className={classes.item}
      />
    );
  };

  const renderLocalResident = () => {
    const onChange = (ev, val) => {
      setLocalResident(val);
      setIsRecordFormDirty(true);
    };
    return (
      <RMSDictionary
        options="codeResident"
        className={classes.item}
        onChange={onChange}
        value={localResident}
        formType={personId ? 'edit' : 'add'}
        label="Local Resident"
      />
    );
  };

  const renderCurrentCitizenship = () => {
    const onChange = (ev, val) => {
      setCurrentCitizenship(val);
      setIsRecordFormDirty(true);
    };
    return (
      <RMSDictionary
        options="codeCitizenships"
        className={classes.item}
        onChange={onChange}
        value={currentCitizenship}
        formType={personId ? 'edit' : 'add'}
        label="Current Citizenship"
      />
    );
  };

  const renderBirthCitizenship = () => {
    const onChange = (ev, val) => {
      setBirthCitizenship(val);
      setIsRecordFormDirty(true);
    };
    return (
      <RMSDictionary
        options="codeCitizenships"
        className={classes.item}
        onChange={onChange}
        value={birthCitizenship}
        formType={personId ? 'edit' : 'add'}
        label="Birth Citizenship"
      />
    );
  };

  const renderBirthNationality = () => {
    const onChange = (ev, val) => {
      setBirthNationality(val);
      setIsRecordFormDirty(true);
    };
    return (
      <RMSDictionary
        options="codeNationalities"
        className={classes.item}
        onChange={onChange}
        value={birthNationality}
        formType={personId ? 'edit' : 'add'}
        label="Birth Nationality"
      />
    );
  };

  const renderGang = () => {
    const onChange = (ev, val) => {
      setGang(val);
      setIsRecordFormDirty(true);
    };
    return (
      <RMSDictionary
        options="codeGang"
        className={classes.item}
        onChange={onChange}
        value={gang}
        formType={personId ? 'edit' : 'add'}
        label="Gang"
      />
    );
  };

  const renderMaritalStatus = () => {
    const onChange = (ev, val) => {
      setMaritalStatus(val);
      setIsRecordFormDirty(true);
    };
    return (
      <RMSDictionary
        options="codeMaritalStatus"
        className={classes.item}
        onChange={onChange}
        value={maritalStatus}
        formType={personId ? 'edit' : 'add'}
        label="Marital Status"
      />
    );
  };

  const renderEducationLevel = () => {
    return (
      <TextField
        label="Education Level"
        variant="outlined"
        onChange={(e) => {
          setEducationLevel(e.target.value);
          setIsRecordFormDirty(true);
        }}
        value={educationLevel}
        size="small"
        className={classes.item}
      />
    );
  };

  const renderAllergies = () => {
    return (
      <TextField
        label="Allergies"
        variant="outlined"
        onChange={(e) => {
          setAllergies(e.target.value);
          setIsRecordFormDirty(true);
        }}
        value={allergies}
        size="small"
        className={classes.item}
      />
    );
  };

  const renderDna = () => {
    return (
      <TextField
        label="DNA"
        variant="outlined"
        onChange={(e) => {
          setDna(e.target.value);
          setIsRecordFormDirty(true);
        }}
        value={dna}
        size="small"
        className={classes.item}
      />
    );
  };

  const renderNotes = () => {
    return (
      <TextField
        label="Person Notes"
        variant="outlined"
        onChange={(e) => {
          setNotes(e.target.value);
          setIsRecordFormDirty(true);
        }}
        value={notes}
        size="small"
        className={classes.item}
        multiline
        rows={5}
      />
    );
  };

  const renderPrimaryLanguage = () => {
    const onChange = (ev, val) => {
      setPrimaryLanguage(val);
      setIsRecordFormDirty(true);
    };
    return (
      <RMSDictionary
        options="codeLanguages"
        className={classes.item}
        onChange={onChange}
        value={primarylanguage}
        formType={personId ? 'edit' : 'add'}
        label="Primary Language"
      />
    );
  };

  const renderWhyDangerous = () => {
    const onChange = (ev, val) => {
      setWhyDangerous(val);
      setIsRecordFormDirty(true);
    };
    return (
      <RMSDictionary
        options="codeDangerousType"
        className={classes.item}
        onChange={onChange}
        value={whyDangerous}
        label="Why Dangerous"
        formType={personId ? 'edit' : 'add'}
        disabled={!isDangerous}
      />
    );
  };

  const renderFingerPrintAvailable = () => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={fpAvailable}
            onChange={() => {
              setFpAvailable(!fpAvailable);
              setIsRecordFormDirty(true);
            }}
            color="primary"
          />
        }
        label="Finger Print Available"
        className={classes.item}
      />
    );
  };

  const renderOverrideJuvenile = () => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={overrideJuvenile}
            onChange={() => {
              setOverrideJuvenile(!overrideJuvenile);
              setIsRecordFormDirty(true);
            }}
            color="primary"
          />
        }
        label="Override Juvenile"
        className={classes.item}
      />
    );
  };

  const renderPublicOfficial = () => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={publicOfficial}
            onChange={() => {
              setPublicOfficial(!publicOfficial);
              setIsRecordFormDirty(true);
            }}
            color="primary"
          />
        }
        label="Public Official"
        className={classes.item}
      />
    );
  };

  const renderEnglishSpeaking = () => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={englishSpeaking}
            onChange={() => {
              setEnglishSpeaking(!englishSpeaking);
              setIsRecordFormDirty(true);
            }}
            color="primary"
          />
        }
        label="English Speaking"
        className={classes.item}
      />
    );
  };

  const renderDangerous = () => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={isDangerous}
            onChange={() => {
              setIsDangerous(!isDangerous);
              setIsRecordFormDirty(true);
            }}
            color="primary"
          />
        }
        label="Dangerous"
        className={classes.item}
      />
    );
  };

  const renderDigitalImage = () => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={digitalImage}
            onChange={() => {
              setDigitalImage(!digitalImage);
              setIsRecordFormDirty(true);
            }}
            color="primary"
          />
        }
        label="Digital Image"
        className={classes.item}
      />
    );
  };

  const renderInJail = () => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={inJail}
            onChange={() => {
              setInJail(!inJail);
              setIsRecordFormDirty(true);
            }}
            color="primary"
          />
        }
        label="In Jail"
        className={classes.item}
      />
    );
  };

  const renderCarryWeapon = () => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={carryWeapon}
            onChange={() => {
              setCarryWeapon(!carryWeapon);
              setIsRecordFormDirty(true);
            }}
            color="primary"
          />
        }
        label="Carry Weapon"
        className={classes.item}
      />
    );
  };

  const renderOrganDonor = () => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={organDonor}
            onChange={() => {
              setOrganDonor(!organDonor);
              setIsRecordFormDirty(true);
            }}
            color="primary"
          />
        }
        label="Organ Donor"
        className={classes.item}
      />
    );
  };

  const renderExFelon = () => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={exFelon}
            onChange={() => {
              setExFelon(!exFelon);
              setIsRecordFormDirty(true);
            }}
            color="primary"
          />
        }
        label="Ex Felon"
        className={classes.item}
      />
    );
  };

  const renderProtectiveOrder = () => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={protectiveOrder}
            onChange={() => {
              setProtectiveOrder(!protectiveOrder);
              setIsRecordFormDirty(true);
            }}
            color="primary"
          />
        }
        label="Protective Order"
        className={classes.item}
      />
    );
  };

  const renderSeniorCitizen = () => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={seniorCitizen}
            onChange={() => {
              setSeniorCitizen(!seniorCitizen);
              setIsRecordFormDirty(true);
            }}
            color="primary"
          />
        }
        label="Senior Citizen"
        className={classes.item}
      />
    );
  };

  const renderOfficer = () => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={officer}
            onChange={() => {
              setOfficer(!officer);
              setIsRecordFormDirty(true);
            }}
            color="primary"
          />
        }
        label="Officer"
        className={classes.item}
      />
    );
  };

  const renderSexOffender = () => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={sexOffender}
            onChange={() => {
              setSexOffender(!sexOffender);
              setIsRecordFormDirty(true);
            }}
            color="primary"
          />
        }
        label="Sex Offender"
        className={classes.item}
      />
    );
  };

  const renderJuvenile = () => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={juvenile}
            onChange={() => {
              setJuvenile(!juvenile);
              setIsRecordFormDirty(true);
            }}
            color="primary"
          />
        }
        label="Juvenile"
        className={classes.item}
      />
    );
  };

  const renderMilitary = () => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={military}
            onChange={() => {
              setMilitary(!military);
              setIsRecordFormDirty(true);
            }}
            color="primary"
          />
        }
        label="Military"
        className={classes.item}
      />
    );
  };

  const renderProbation = () => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={probation}
            onChange={() => {
              setProbation(!probation);
              setIsRecordFormDirty(true);
            }}
            color="primary"
          />
        }
        label="Probation"
        className={classes.item}
      />
    );
  };

  const renderEthnicity = () => {
    const onChange = (ev, val) => {
      setEthnicity(val);
      setIsRecordFormDirty(true);
    };
    return (
      <RMSDictionary
        options="codeEthnicity"
        className={classes.item}
        onChange={onChange}
        value={ethnicity}
        formType={personId ? 'edit' : 'add'}
        label="Ethnicity"
      />
    );
  };

  const renderEyeColor = () => {
    const onChange = (ev, val) => {
      setEyecolor(val);
      setIsRecordFormDirty(true);
    };
    return (
      <RMSDictionary
        options="codeEyeColor"
        className={classes.item}
        onChange={onChange}
        value={eyecolor}
        formType={personId ? 'edit' : 'add'}
        label="Eye Color"
      />
    );
  };

  const renderHanded = () => {
    const onChange = (ev, val) => {
      setHanded(val);
      setIsRecordFormDirty(true);
    };
    return (
      <RMSDictionary
        options="codeHanded"
        className={classes.item}
        onChange={onChange}
        value={handed}
        formType={personId ? 'edit' : 'add'}
        label="Handed"
      />
    );
  };

  const renderBloodType = () => {
    const onChange = (ev, val) => {
      setBloodType(val);
      setIsRecordFormDirty(true);
    };
    return (
      <RMSDictionary
        options="codeBloodType"
        className={classes.item}
        onChange={onChange}
        value={bloodType}
        formType={personId ? 'edit' : 'add'}
        label="Blood Type"
      />
    );
  };

  const renderHairColor = () => {
    const onChange = (ev, val) => {
      setHaircolor(val);
      setIsRecordFormDirty(true);
    };
    return (
      <RMSDictionary
        options="codeHairColor"
        className={classes.item}
        onChange={onChange}
        value={haircolor}
        formType={personId ? 'edit' : 'add'}
        label="Hair Color"
      />
    );
  };

  const renderHairLength = () => {
    const onChange = (ev, val) => {
      setHairlength(val);
      setIsRecordFormDirty(true);
    };
    return (
      <RMSDictionary
        options="codeHairLength"
        className={classes.item}
        onChange={onChange}
        value={hairlength}
        formType={personId ? 'edit' : 'add'}
        label="Hair Length"
      />
    );
  };

  const renderHairStyle = () => {
    const onChange = (ev, val) => {
      setHairStyle(val);
      setIsRecordFormDirty(true);
    };
    return (
      <RMSDictionary
        options="codeHairStyle"
        className={classes.item}
        onChange={onChange}
        value={hairstyle}
        formType={personId ? 'edit' : 'add'}
        label="Hair Style"
      />
    );
  };

  const renderSsn = () => {
    const error = personDuplicateParams.includes('SSNNumber') && showDuplicateFound;
    return (
      <TextField
        label="SSN"
        variant="outlined"
        onChange={(e) => {
          setSsn(e.target.value);
          setIsRecordFormDirty(true);
        }}
        value={ssn}
        size="small"
        className={classes.item}
        error={error || ssnError}
        helperText={(error && 'Duplicate Found') || ssnError}
        InputProps={{
          inputComponent: SSNTextMask,
        }}
      />
    );
  };

  const renderOln = () => {
    const error = personDuplicateParams.includes('OLNNumber') && showDuplicateFound;
    return (
      <TextField
        label="OLN"
        variant="outlined"
        onChange={(e) => {
          setOln(e.target.value);
          setIsRecordFormDirty(true);
        }}
        value={oln}
        size="small"
        className={classes.item}
        error={error}
        helperText={error && 'Duplicate Found'}
      />
    );
  };

  const renderState = () => {
    const onChange = (ev, val) => {
      setState(val);
      setIsRecordFormDirty(true);
    };
    return (
      <RMSDictionary
        options="codeStates"
        className={classes.item}
        onChange={onChange}
        value={state}
        formType={personId ? 'edit' : 'add'}
        label="State"
      />
    );
  };

  const renderAddressLookup = () => {
    const clearPlace = () => {
      setAddress(null);
      setIsRecordFormDirty(true);
    };
    return (
      <AddressLookup
        className={classes.item}
        ptsPlaces={false}
        ptsAddresses={true}
        googleAddresses={false}
        onAddressValueSet={setAddress}
        defaultLocation={null}
        onReset={clearPlace}
        label="Address Lookup"
      />
    );
  };

  const renderPrimary = () => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={isPrimary}
            onChange={() => {
              setIsPrimary(!isPrimary);
              setIsRecordFormDirty(true);
            }}
            color="primary"
          />
        }
        label="Primary"
        className={classes.item}
      />
    );
  };

  const renderContactType = () => {
    const onChange = (ev, val) => {
      setContactType(val);
      setIsRecordFormDirty(true);
      formChanged();
      delErr('Info');
    };
    return (
      <RMSDictionary
        options="codeContactInfoMethodTypes"
        className={classes.item}
        onChange={onChange}
        value={contactType}
        formType={personId ? 'edit' : 'add'}
        label="Contact Type"
        disabled={!contactMethodType}
      />
    );
  };

  const renderMethodType = () => {
    const onChange = (ev, val) => {
      setContactMethodType(val);
      setIsRecordFormDirty(true);
      formChanged();
    };
    return (
      <RMSDictionary
        options="ContactMethods"
        className={classes.item}
        onChange={onChange}
        value={contactMethodType}
        formType={personId ? 'edit' : 'add'}
        label="Method Type"
      />
    );
  };

  const renderInfo = () => {
    const onChange = (ev, val) => {
      setInfo(val);
      setIsRecordFormDirty(true);
      formChanged();
    };
    const type = contactMethodType?.Code;
    let error;
    if (type === 'Phone') {
      error = err(validatePhoneNo(info), 'Info', 'Invalid phone number');
    }
    if (type === 'Email') {
      error = err(validateEmail(info), 'Info', 'Invalid email address');
    }

    return (
      <RMSTextField
        className={classes.item}
        label="Info"
        value={info}
        onChange={onChange}
        max={100}
        error={error}
        type={type}
        disabled={!contactMethodType}
      />
    );
  };

  const renderDefault = () => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={isDefault}
            onChange={() => {
              setIsDefault(!isDefault);
              setIsRecordFormDirty(true);
            }}
            color="primary"
          />
        }
        label="Default"
        className={classes.item}
      />
    );
  };

  const renderContactNotes = () => {
    return (
      <TextField
        label="Notes"
        variant="outlined"
        onChange={(e) => {
          setContactNotes(e.target.value);
          setIsRecordFormDirty(true);
        }}
        value={contactNotes}
        size="small"
        className={classes.item}
      />
    );
  };

  const closeDuplicateDialog = () => {
    setShowDuplicateListDialog(false);
  };

  const renderDuplicateListActions = () => {
    return (
      <div>
        <Button color="primary" variant="contained" autoFocus onClick={closeDuplicateDialog}>
          Close
        </Button>
      </div>
    );
  };

  const renderDuplicateList = () => {
    return (
      <List>
        {duplicateListOfPerson.map((p) => (
          <ListItem key={p.FirstName} className={classes.listItemRoot}>
            <div className={classes.divContainer}>
              <span className={classes.divItem}>
                <RMSPersonInfoCard data={p} duplicateCheck={true} />
              </span>
              <span className={classes.divItem}>
                <Tooltip title="View Duplicate Person">
                  <Fab
                    size="small"
                    color="secondary"
                    onClick={() => viewDuplicatePerson(p.ptsPersonID)}>
                    <Person />
                  </Fab>
                </Tooltip>
              </span>
            </div>
          </ListItem>
        ))}
      </List>
    );
  };

  const viewDuplicatePerson = (ptsPersonID) => {
    props.showEditPerson(ptsPersonID);
  };

  const showDuplicateFoundButton = () => {
    return (
      <Fade in={showDuplicateFound}>
        <Button
          className={`${classes.duplicateButton} ${showDuplicateFound && classes.visible}`}
          size="medium"
          onClick={() => setShowDuplicateListDialog(true)}>
          Duplicate Found! Show Duplicate List
        </Button>
      </Fade>
    );
  };

  return (
    <>
      {showDuplicateFoundButton()}
      <Row className={`${showDuplicateFound && classes.topRowMargin}`}>
        {dataForNamesDialog && (
          <PersonNameDialog
            data={dataForNamesDialog}
            setDataForNamesDialog={setDataForNamesDialog}
            executePersonSave={save}
          />
        )}
        <ColCard minWidth={500}>
          <RMSFormDivider title="Name" />
          <RowInner>
            {renderPrefix()}
            {renderFirstname()}
            {renderMiddleName()}
            {renderLastName()}
            {renderSuffix()}
            {renderNickname()}
            <Fills className={classes.item} />
          </RowInner>
        </ColCard>
        <ColCard minWidth={500}>
          <RMSFormDivider title="General Details" />
          <RowInner>
            {renderGeneralAppearance()}
            {renderRace()}
            {renderSex()}
            {renderHeight()}
            {renderWeight()}
            <Fills className={classes.item} />
          </RowInner>
        </ColCard>
      </Row>
      <Row>
        <ColCard minWidth={500}>
          <RMSFormDivider title="Age" />
          <RowInner>
            {renderBirthDate()}
            {/* {renderAge()} */}
            {renderBirthCity()}
            {renderBirthState()}
            {renderBirthCounty()}
            {renderBirthCountry()}
            {renderDeceased()}
            {renderDeceasedDate()}
            {renderCauseOfDeath()}
            <Fills className={classes.item} />
          </RowInner>
        </ColCard>
        <ColCard minWidth={500}>
          <RMSFormDivider title="Citizenship" />
          <RowInner>
            {renderLocalResident()}
            {renderUsCitizen()}
            {renderCurrentCitizenship()}
            {renderBirthCitizenship()}
            {renderBirthNationality()}
            <Fills className={classes.item} />
          </RowInner>
        </ColCard>
      </Row>
      <Row>
        <ColCard minWidth={500}>
          <RMSFormDivider title="Advanced Details" />
          <RowInner>
            {renderGang()}
            {renderMaritalStatus()}
            {renderEducationLevel()}
            {renderAllergies()}
            {renderDna()}
            <Fills className={classes.item} />
          </RowInner>
          <RowInner>{renderNotes()}</RowInner>
        </ColCard>
        <ColCard minWidth={500}>
          <RMSFormDivider title="Features" />
          <RowInner>
            {renderEthnicity()}
            {renderEyeColor()}
            {renderHanded()}
            {renderBloodType()}
            {renderHairColor()}
            {renderHairLength()}
            {renderHairStyle()}
            <Fills className={classes.item} />
          </RowInner>
        </ColCard>
      </Row>
      {!personId && (
        <>
          <Row>
            <ColCard minWidth={500}>
              <RMSFormDivider title="Credentials" />
              {renderSsn()}
              <RowInner>
                {renderOln()}
                {renderState()}
                <Fills className={classes.item} />
              </RowInner>
            </ColCard>
            <ColCard minWidth={500}>
              <RMSFormDivider title="Contact" />
              <RowInner>
                {renderAddressLookup()}
                {renderPrimary()}
                {renderMethodType()}
                {renderContactType()}
                {renderInfo()}
                {renderDefault()}
                {renderContactNotes()}
                <Fills className={classes.item} />
              </RowInner>
            </ColCard>
          </Row>
        </>
      )}
      <Row>
        <ColCard minWidth={500}>
          <RMSFormDivider title="Flags" />
          <RowInner>
            {renderFingerPrintAvailable()}
            {renderOverrideJuvenile()}
            {renderPublicOfficial()}
            {renderEnglishSpeaking()}
            {renderPrimaryLanguage()}
            {renderDigitalImage()}
            {renderInJail()}
            {renderCarryWeapon()}
            {renderDangerous()}
            {renderWhyDangerous()}
            {renderOrganDonor()}
            {renderExFelon()}
            {renderProtectiveOrder()}
            {renderSeniorCitizen()}
            {renderOfficer()}
            {renderSexOffender()}
            {renderJuvenile()}
            {renderMilitary()}
            {renderProbation()}
            <Fills className={classes.item} />
          </RowInner>
        </ColCard>
      </Row>
      <div className={classes.actions}>{renderActions()}</div>
      <Dialog
        open={showDuplicateListDialog}
        onClose={closeDuplicateDialog}
        title={'Duplicate Person List'}
        toolbar
        actions={renderDuplicateListActions()}>
        <List>{renderDuplicateList()}</List>
      </Dialog>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    dictionary: state.dictionary,
    dialogs: state.dialogs,
  };
};

export default connect(mapStateToProps, {
  closeAddPerson,
  closeEditPerson,
  notify,
  handleError,
  showEditPerson,
  setIsRecordFormDirty,
})(PersonTab);
