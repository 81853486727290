import React from 'react';

function GangIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="50"
      viewBox="0 0 50 50"
      preserveAspectRatio="xMidYMid meet"
      {...props}>
      <g transform="translate(0.000000,50.000000) scale(0.100000,-0.100000)" stroke="none">
        <path d="M65 445 c-48 -47 -36 -55 80 -55 63 0 105 4 105 10 0 6 -9 10 -21 10 -12 0 -34 14 -49 30 -36 38 -81 40 -115 5z" />
        <path d="M323 448 c-18 -13 -33 -31 -33 -40 0 -16 11 -18 90 -18 79 0 90 2 90 18 0 22 -59 62 -91 62 -13 0 -39 -10 -56 -22z" />
        <path d="M40 345 c0 -61 50 -112 96 -101 30 8 64 61 64 99 l0 27 -80 0 c-79 0 -80 0 -80 -25z m96 -6 c3 -6 15 -9 25 -6 24 6 24 0 -1 -40 -12 -20 -28 -33 -40 -33 -12 0 -28 13 -40 33 -25 40 -25 46 -1 40 10 -3 22 0 25 6 4 6 11 11 16 11 5 0 12 -5 16 -11z" />
        <path d="M103 313 c-23 -9 -14 -20 17 -20 32 0 40 11 14 21 -9 3 -16 6 -17 5 -1 0 -8 -3 -14 -6z" />
        <path d="M300 345 c0 -61 50 -112 96 -101 30 8 64 61 64 99 l0 27 -80 0 c-79 0 -80 0 -80 -25z m96 -6 c3 -6 15 -9 25 -6 24 6 24 0 -1 -40 -12 -20 -28 -33 -40 -33 -12 0 -28 13 -40 33 -25 40 -25 46 -1 40 10 -3 22 0 25 6 4 6 11 11 16 11 5 0 12 -5 16 -11z" />
        <path d="M363 313 c-23 -9 -14 -20 17 -20 32 0 40 11 14 21 -9 3 -16 6 -17 5 -1 0 -8 -3 -14 -6z" />
        <path d="M205 272 c-4 -4 -13 -21 -20 -39 -11 -26 -18 -31 -37 -27 -22 5 -22 5 -5 -10 39 -31 172 -32 214 0 17 12 16 13 -8 14 -19 0 -29 8 -40 33 -12 28 -19 32 -56 34 -23 0 -44 -2 -48 -5z" />
        <path d="M170 132 c0 -32 26 -79 51 -93 11 -6 32 -8 45 -5 29 8 64 60 64 96 0 25 -1 25 -80 25 -77 0 -80 -1 -80 -23z m96 -3 c3 -6 15 -9 25 -6 25 7 24 -14 -2 -47 -26 -33 -52 -33 -78 0 -26 33 -27 54 -2 47 10 -3 22 0 25 6 9 14 23 14 32 0z" />
        <path d="M233 103 c-23 -9 -14 -20 17 -20 32 0 40 11 14 21 -9 3 -16 6 -17 5 -1 0 -8 -3 -14 -6z" />
      </g>
    </svg>
  );
}

export default GangIcon;
