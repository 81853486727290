import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { closeAddressDialog } from 'reducers/PersonDialogsReducer';
import { getAddressHistory } from 'reducers/AddressReducer';
import { handleError } from 'reducers/ErrorReducer';
import SearchMenu, { FilterConsumer } from 'components/RMSSearch/components/SearchMenu';
import History from './History';

function AddressHistory(props) {
  const { ptsAddressID } = props;
  const [data, setData] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const mountedRef = useRef(true);

  useEffect(() => {
    getAddress();
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    };
    // eslint-disable-next-line
  }, []);

  const getAddress = async () => {
    try {
      const data = await getAddressHistory(ptsAddressID);
      if (!mountedRef.current) return;
      setData(processData(data));
      setLoaded(true);
    } catch (err) {
      props.handleError(err, 'Error receiving event location data.');
    }
  };

  const processData = (data) => {
    const processed = data.map((row) => {
      return {
        ...row,
        id: row.ptsEventID,
        CaseID: row.CaseIDs.join(', '),
        AgencyID: [...new Set(row.AgencyIDs)].join(', '),
      };
    });
    processed.sort((a, b) => new Date(b.Received) - new Date(a.Received));
    return processed;
  };

  return (
    <SearchMenu>
      <FilterConsumer>
        {(filter) => <History filter={filter} data={data} loaded={loaded} />}
      </FilterConsumer>
    </SearchMenu>
  );
}

export default connect(null, {
  closeAddressDialog,
  handleError,
})(AddressHistory);
