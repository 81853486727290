import store from '../../config/configureStore';

const SET_CAD_EVENT_TIME_MODAL = 'SET_CAD_EVENT_TIME_MODAL';
const SET_DUPLICATE_MESSAGE_MODAL = 'SET_DUPLICATE_MESSAGE_MODAL';
const SET_DUPLICATE_MODAL = 'SET_DUPLICATE_MODAL';
const SET_DUPLICATE_CONTACT_ADDRESS_LIST = 'SET_DUPLICATE_CONTACT_ADDRESS_LIST';
const SET_DUPLICATE_EMPLOYMENT_ADDRESS_LIST = 'SET_DUPLICATE_EMPLOYMENT_ADDRESS_LIST';
const SET_DUPLICATE_ADDRESS_LIST = 'SET_DUPLICATE_ADDRESS_LIST';
const SET_DUPLICATE_PERSON_LIST = 'SET_DUPLICATE_PERSON_LIST';
const SET_DUPLICATE_VEHICLE_LIST = 'SET_DUPLICATE_VEHICLE_LIST';
const SET_DUPLICATE_PLACE_LIST = 'SET_DUPLICATE_PLACE_LIST';
const SET_DUPLICATE_FOUND_BTN = 'SET_DUPLICATE_FOUND_BTN';

export const setCadEventTimeModalState = (flag) => {
  return async (dispatch) => {
    return dispatch({ type: SET_CAD_EVENT_TIME_MODAL, payload: flag });
  };
};

export const setDuplicateFoundBtn = (payload) => {
  return async (dispatch) => {
    return dispatch({ type: SET_DUPLICATE_FOUND_BTN, payload: payload });
  };
};

export const setDuplicateModal = (payload) => {
  return async (dispatch) => {
    return dispatch({ type: SET_DUPLICATE_MODAL, payload: payload });
  };
};

export const setDuplicateModalMessage = (payload) => {
  return async (dispatch) => {
    return dispatch({ type: SET_DUPLICATE_MESSAGE_MODAL, payload: payload });
  };
};

export const setDuplicateContactAddressList = (payload) => {
  return async (dispatch) => {
    return dispatch({ type: SET_DUPLICATE_CONTACT_ADDRESS_LIST, payload: payload });
  };
};

export const setDuplicateEmploymentAddressList = (payload) => {
  return async (dispatch) => {
    return dispatch({ type: SET_DUPLICATE_EMPLOYMENT_ADDRESS_LIST, payload: payload });
  };
};

export const setDuplicatAddressList = (payload) => {
  return async (dispatch) => {
    return dispatch({ type: SET_DUPLICATE_ADDRESS_LIST, payload: payload });
  };
};

export const setDuplicatePersonList = (payload) => {
  return async (dispatch) => {
    return dispatch({ type: SET_DUPLICATE_PERSON_LIST, payload: payload });
  };
};

export const setDuplicateVehicleList = (payload) => {
  return async (dispatch) => {
    return dispatch({ type: SET_DUPLICATE_VEHICLE_LIST, payload: payload });
  };
};

export const setDuplicatePlaceList = (payload) => {
  return async (dispatch) => {
    return dispatch({ type: SET_DUPLICATE_PLACE_LIST, payload: payload });
  };
};

export const resetDuplicateList = () => {
  const modalState = store.store.getState().uiModal;
  modalState.duplicateListOfAddress = [];
  modalState.duplicateListOfAddressForContact = [];
  modalState.duplicateListOfAddressForEmployment = [];
  modalState.duplicateListOfPlace = [];
  modalState.duplicateListOfVehicle = [];
  modalState.duplicateListOfPerson = [];
};

export default function reducer(
  state = {
    showCadEventTimeModal: false,
    showDuplicateModal: false,
    duplicateFoundBtnClicked: false,
    duplicateModalMessage: '',
    duplicateListOfAddress: [],
    duplicateListOfAddressForContact: [],
    duplicateListOfAddressForEmployment: [],
    duplicateListOfPlace: [],
    duplicateListOfVehicle: [],
    duplicateListOfPerson: [],
  },
  action
) {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case SET_CAD_EVENT_TIME_MODAL:
      return { ...state, showCadEventTimeModal: action.payload };
    case SET_DUPLICATE_FOUND_BTN:
      return {
        ...state,
        duplicateFoundBtnClicked: action.payload,
      };
    case SET_DUPLICATE_MODAL:
      return {
        ...state,
        showDuplicateModal: action.payload,
      };
    case SET_DUPLICATE_MESSAGE_MODAL:
      return {
        ...state,
        duplicateModalMessage: action.payload,
      };
    case SET_DUPLICATE_CONTACT_ADDRESS_LIST:
      return {
        ...state,
        duplicateListOfAddressForContact: action.payload,
      };
    case SET_DUPLICATE_EMPLOYMENT_ADDRESS_LIST:
      return {
        ...state,
        duplicateListOfAddressForEmployment: action.payload,
      };
    case SET_DUPLICATE_ADDRESS_LIST:
      return {
        ...state,
        duplicateListOfAddress: action.payload,
      };
    case SET_DUPLICATE_PERSON_LIST:
      return {
        ...state,
        duplicateListOfPerson: action.payload,
      };
    case SET_DUPLICATE_VEHICLE_LIST:
      return {
        ...state,
        duplicateListOfVehicle: action.payload,
      };
    case SET_DUPLICATE_PLACE_LIST:
      return {
        ...state,
        duplicateListOfPlace: action.payload,
      };
  }
  return state;
}
