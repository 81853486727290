import React, { useState } from 'react';
import { connect } from 'react-redux';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

import Dialog from 'components/RMSDialog';
import TextField from '@material-ui/core/TextField';

import formStyles, { gridStyle, Row, RowInner, ColCard } from 'utils/formStyles';
import PlaceLookup from 'components/RMSPlaceLookup';
import { notify } from 'reducers/NotifierReducer';
import { handleError } from 'reducers/ErrorReducer';
import { notifyDataUpdate } from 'reducers/DataUpdateReducer';
import Dictionary from 'components/RMSAutoComplete/RMSDictonary';
import PersonLookup2 from 'components/RMSPersonLookup/PersonLookup2';
import { closeEditWarrantParty } from 'reducers/PersonDialogsReducer';
import { Autocomplete } from '@mui/material';
import { getService } from 'reducers/service';
import { addCreatedUpdatedBy } from 'utils/functions';

const useStyles = makeStyles(() => ({
  ...formStyles,
  item: gridStyle(200, 800),
  item2: gridStyle('100%', 800),
  field: gridStyle(210, 400),
}));

function WarrantPartiesDialog(props) {
  const classes = useStyles();
  const { data } = props;
  const { ptsWarrantID } = data || {};

  const [partyType, setPartyType] = useState(null);
  const [personData, setPersonData] = useState(null); // [ptsPersonID, ptsPersonName]

  const service = getService('rms-warrant');

  const close = () => {
    props.closeEditWarrantParty();
  };

  const save = async () => {
    const dataObj = addCreatedUpdatedBy({
      ptsWarrantID: ptsWarrantID,
      ptsPartyID: personData?.ptsPersonID,
      WarrantPartyType: partyType?.Code,
      PartyParentType: 'PERSON',
    });
    try {
      await service.create({ data: dataObj, type: 'warrant_party' });
      props.notifyDataUpdate({ type: 'warrants-parties', data: {} });
      props.notify('Party Saved', 'Success');
      close();
    } catch (err) {
      props.handleError(err);
    }
  };

  const renderParentType = () => {
    return (
      <Autocomplete
        options={['Person', 'Place']}
        // onChange={(e) => setIdNumber(e.target.value)}
        value={'Person'}
        disabled
        size="small"
        renderInput={(params) => <TextField {...params} label="Parent Type" variant="outlined" />}
        className={classes.item2}
      />
    );
  };

  const renderPartyType = () => {
    const onChange = (ev, val) => setPartyType(val);
    return (
      <Dictionary
        options="codePartyRelationship"
        className={classes.item2}
        onChange={onChange}
        formType="edit"
        value={partyType}
        label="Party Type"
        type="Warrants"
      />
    );
  };

  const renderPersonSearch = () => {
    return (
      <PersonLookup2
        onChange={(value) => setPersonData(value)}
        label="Person"
        className={classes.item2}
        // error={!warrant.ptsPersonID}
        // helperText={!warrant.ptsPersonID && ' This Field Is Required'}
      />
    );
  };

  const renderActions = () => {
    return (
      <>
        <Button color="primary" variant="contained" autoFocus onClick={save}>
          <SaveIcon /> Save
        </Button>
        <Button color="primary" onClick={close}>
          <CloseIcon /> Close
        </Button>
      </>
    );
  };

  return (
    <Dialog toolbar maxWidth="sm" onClose={close} title="Add Party" actions={renderActions()}>
      <Row>
        <ColCard minWidth={500}>
          <RowInner>
            {renderPartyType()}
            {renderParentType()}
            <RowInner>{renderPersonSearch()}</RowInner>
            {/* {renderPlaceSearch()} */}
          </RowInner>
        </ColCard>
      </Row>
    </Dialog>
  );
}

const mapStateToProps = (state) => {
  return {
    dictionary: state.dictionary,
  };
};

export default connect(mapStateToProps, {
  closeEditWarrantParty,
  notifyDataUpdate,
  notify,
  handleError,
})(WarrantPartiesDialog);
