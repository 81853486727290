/* eslint-disable default-case */
import store from '../../config/configureStore';

export const GET_AGENCY = 'CODE/GET_AGENCY';

export const getAgencies = () => {
  const client = store.store.getState().websocket.websocket;
  return async (dispatch) => {
    try {
      const service = client.service('ptsagencies');
      service.timeout = 200000;
      const codes = await service.find({
        query: {
          $select: ['AgencyID', 'AgencyType'],
          IsDeleted: false,
          AgencyType: '1',
        },
      });
      dispatch({ type: GET_AGENCY, payload: codes.data });
    } catch (error) {
      if (error.code === 401) {
        console.log('Unauthenticated');
      } else {
        console.log(error);
      }
    }
  };
};

export default function reducer(
  state = {
    agencies: [],
  },
  action
) {
  switch (action.type) {
    case GET_AGENCY:
      return { ...state, agencies: action.payload };
  }
  return state;
}
