/* eslint-disable react-hooks/exhaustive-deps */
// import React, { useState, useEffect } from 'react';
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import defaultMenuOptions from './defaultMenuOptions';
import EventTabMenu from './EventTabMenu';
import EventTabDetails from './EventTabDetails';
// import { setEvent } from 'reducers/IncReducer';

const useStyles = makeStyles(() => ({
  tabs: {
    marginTop: 8,
    marginBottom: 8,
  },
  tabDetails: {
    minHeight: 300,
    marginTop: '20px',
  },
}));

function EditEventTabs(props) {
  const classes = useStyles();
  const { event } = props;
  const [menuOptions, setMenuOptions] = useState(defaultMenuOptions);
  const [tab, setTab] = useState(0);

  const toggleMenuOptions = (key) => {
    const newMenuOptions = { ...menuOptions };
    newMenuOptions[key].sort = newMenuOptions[key].sort === 'ASC' ? 'DESC' : 'ASC';
    setMenuOptions(newMenuOptions);
  };

  const changeEventMenu = (tab) => {
    setTab(tab);
  };

  if (event) {
    return (
      <>
        <div className={classes.tabs}>
          <EventTabMenu
            value={tab}
            setValue={changeEventMenu}
            event={event}
            menuOptions={menuOptions}
            toggleMenuOptions={toggleMenuOptions}
          />
        </div>
        <div className={classes.tabDetails}>
          <EventTabDetails event={event} menuOptions={menuOptions} tab={tab} />
        </div>
      </>
    );
  }
}

export default EditEventTabs;
