import { Typography } from '@material-ui/core';
import { Tooltip } from '@mui/material';
import React from 'react';
const styleValue = {
  height: '15px',
  width: '15px',
  borderRadius: '50%',
  backgroundColor: 'gold',
  marginLeft: '20px',
  textAlign: 'center',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'red',
};
const styleValueText = {
  fontSize: '15px',
  marginTop: '20px',
  background: '#e0af34',
  borderRadius: '8px',
  padding: '5px',
  textAlign: 'right',
};
export default function JuvenileIndicator(props) {
  const { classValue, customStyle, IsJuvenile, type, text } = props;
  const style =
    type === 'text' ? { ...styleValueText, ...customStyle } : { ...styleValue, ...customStyle };

  return IsJuvenile ? (
    type === 'text' ? (
      <Tooltip title="Juvenile">
        <Typography style={style}>{text}</Typography>
      </Tooltip>
    ) : (
      <Tooltip title="Juvenile">
        <span style={style}>{text}</span>
      </Tooltip>
    )
  ) : null;
}
