import { FormControl, Grid, makeStyles, MenuItem, Select, TextField } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { InputLabel, Typography } from '@mui/material';
import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import RMSDictionary from 'components/RMSAutoComplete/RMSDictonary';
import { Autocomplete } from '@material-ui/lab';
import DownloadCSV from './DownloadCSV';
import { processParameters } from './reportDataProcessingHelper';
export const maxLimit = [{ Code: 5 }, { Code: 10 }, { Code: 25 }, { Code: 100 }, { Code: 500 }];
const useStyles = makeStyles((theme) => ({
  reportContainer: {
    display: 'flex',
  },
  reportSelection: {
    flex: 0.3,
  },
  reportFilter: {
    flex: 0.7,
  },
  report: {
    paddingBottom: '4px',
  },
}));

const IncidentByLocationFilters = (props) => {
  const classes = useStyles();
  const { reportForm, handleReportFormChange, ReportID } = props;
  const formValue = reportForm[ReportID] || {};

  return (
    <div className={classes.report}>
      <Grid container justifyContent="space-between">
        <Grid item xs={6}>
          <Typography variant="subtitle1" className="py-2">
            {props.title}
          </Typography>
        </Grid>
        <Grid item xs={6} container justify="flex-end">
          <DownloadCSV
            ReportID={ReportID}
            filters={processParameters(ReportID, formValue, 'csv')}
          />
        </Grid>
      </Grid>
      <Grid container justify="left" alignItems="center" spacing={1}>
        <Grid item xs={6} md={3}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableOpenOnEnter
              autoComplete="off"
              fullWidth
              clearable
              size="small"
              inputVariant="outlined"
              showTodayButton={true}
              variant="dialog"
              format="MM/dd/yyyy"
              id="date-picker-inline-from"
              label="Received From Beginning"
              defaultValue={null}
              value={formValue.receivedFromBeginning || null}
              onChange={(date) => handleReportFormChange(ReportID, 'receivedFromBeginning', date)}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={6} md={3}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableOpenOnEnter
              autoComplete="off"
              fullWidth
              clearable
              size="small"
              inputVariant="outlined"
              showTodayButton={true}
              variant="dialog"
              format="MM/dd/yyyy"
              id="date-picker-inline-from"
              label="Received From Ending"
              defaultValue={null}
              value={formValue.receivedFromEnding || null}
              onChange={(date) => handleReportFormChange(ReportID, 'receivedFromEnding', date)}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={4} md={6}>
          <RMSDictionary
            options="codeAddressCategories"
            multiple={true}
            value={formValue.locationCategories || []}
            label="Location Categories"
            compact
            onChange={(ev, newVal) => {
              handleReportFormChange(ReportID, 'locationCategories', newVal);
            }}
          />
        </Grid>

        <Grid item xs={5}>
          <RMSDictionary
            options="codeZones"
            multiple={true}
            value={formValue.zones || []}
            label="Zones"
            compact
            onChange={(ev, newVal) => {
              handleReportFormChange(ReportID, 'zones', newVal);
            }}
          />
        </Grid>

        <Grid item xs={3}>
          <Autocomplete
            size="small"
            autoComplete
            autoSelect={false}
            autoHighlight
            getOptionLabel={(option) => String(option.Code)}
            options={maxLimit}
            value={{ Code: formValue.topRows || '' }}
            key="max rows"
            onChange={(event, newValue) => {
              handleReportFormChange(ReportID, 'topRows', newValue?.Code);
            }}
            renderInput={(params) => (
              <TextField
                autoComplete="off"
                size="small"
                {...params}
                label="Top Rows"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default IncidentByLocationFilters;
