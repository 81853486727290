import { checkJsonValidityAndParse } from 'utils/functions';
import { getWorkflowsStepRoles } from './helpers/workflowHelper';

export const ADD_SHARE_MODAL_WORKFLOW = 'WORKFLOW/ADD_SHARE_MODAL_WORKFLOW';
export const SET_WORKFLOWS = 'WORKFLOW/SET_WORKFLOWS';
export const SET_STEPS = 'WORKFLOW/SET_STEPS';
export const SET_CURRENT_STEP_VALUE = 'WORKFLOW/SET_CURRENT_STEP_VALUE';
export const SET_WORKFLOW_STEP_ROLES = 'WORKFLOW/SET_WORKFLOW_STEP_ROLES';

export const addShareModalWorkflow = (value) => {
  return (dispatch) => {
    dispatch({ type: ADD_SHARE_MODAL_WORKFLOW, payload: value });
  };
};

export const setWorkflows = (value) => {
  return (dispatch) => {
    dispatch({ type: SET_WORKFLOWS, payload: value });
  };
};

export const setSteps = (value) => {
  return (dispatch) => {
    dispatch({ type: SET_STEPS, payload: value });
  };
};

export const setCurrentStepValue = (value) => {
  return (dispatch) => {
    dispatch({ type: SET_CURRENT_STEP_VALUE, payload: value });
  };
};

export const setWorkflowStepRoles = () => {
  return async (dispatch) => {
    const data = await getWorkflowsStepRoles({ type: 'all' });

    const processData = (data || []).map((item) => {
      const obj = {
        ptsWFlowStepRoleID: item.ptsWFlowStepRoleID,
        ptsWFlowStepID: item.ptsWFlowStepID,
        RoleID: item.RoleID,
        advance: item.Advance || false,
        return: item.Return || false,
        remove: item.Remove || false,
        permittedProfiles: (checkJsonValidityAndParse(item.PermittedProfiles) || []).map(
          (profile) => profile.ptsProfileID
        ),
        permittedAgencies: (checkJsonValidityAndParse(item.PermittedAgencies) || []).map(
          (agency) => agency.AgencyID
        ),
      };

      return obj;
    });
    dispatch({ type: SET_WORKFLOW_STEP_ROLES, payload: processData });
  };
};
export default function reducer(
  state = {
    currentIncidentId: 0,
    workflows: [],
    steps: [],
    persons: [],
    currentStepValue: null,
    savedSharedWorkflow: {},
    workFlowStepRoles: [],
  },
  action
) {
  switch (action.type) {
    case ADD_SHARE_MODAL_WORKFLOW:
      return { ...state, savedSharedWorkflow: { ...action.payload } };
    case SET_WORKFLOWS:
      return { ...state, workflows: [...action.payload] };
    case SET_STEPS:
      return { ...state, steps: [...action.payload] };
    case SET_CURRENT_STEP_VALUE:
      return { ...state, currentStepValue: action.payload };
    case SET_WORKFLOW_STEP_ROLES:
      return {
        ...state,
        workFlowStepRoles: action.payload,
      };
    default:
      break;
  }
  return state;
}
