import store from '../../config/configureStore';
import { errorTree } from 'simpl-schema-validation/helpers/errorTree';

export const countOffenseErrors = (ptsOffenseId) => {
  let currentIncidentId = store.store.getState().incident.ptsIncidentId;
  let validationErrors = store.store.getState().incident.validationErrors;

  const errorObj = validationErrors.find((error) => error.ptsIncidentId == currentIncidentId);

  if (!errorObj) {
    return 0;
  }
  let errorCount = 0;

  for (let subStepperError of errorTree['offensesErrors']) {
    if (errorObj && errorObj.errors?.[subStepperError]?.length) {
      errorObj.errors[subStepperError].forEach((error) => {
        if (error.ptsOffenseId === ptsOffenseId) {
          errorCount++;
        }
      });
    }
  }

  return errorCount;
};
