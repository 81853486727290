import moment from 'moment';
import store from '../../config/configureStore';
import { ptsStatute } from '../_dev/ptsStatute';

import { displayDateTime } from 'reducers/TimeReducer';
import { Recommend } from '@mui/icons-material';
import { formatPersonFullName } from 'utils/functions';

export const SET_INC_LIST_HEADER = 'SET_INC_LIST_HEADER';
export const SET_INC_LIST_BODY = 'SET_INC_LIST_BODY';
export const SET_INC_LIST_BODY_FOR_ADDRESS = 'SET_INC_LIST_BODY_FOR_ADDRESS';
export const SET_INC_LIST_HEADER_FOR_ADDRESS = 'SET_INC_LIST_HEADER_FOR_ADDRESS';
export const RESET_INC_LIST_BODY = 'RESET_INC_LIST_BODY';

const initList = {
  actionReport: [],
  actionReportParties: [],
  actionReportReviews: [],
  addresses: [],
  parties: [],
  evidenceParties: [],
  properties: [],
  offenses: [],
  evidence: [],
  narratives: [],
  dispositions: [],
  zones: [],
  tags: [],
  cadNotes: [],
};

/**
 * Helper function for getting the cell headings for incident addresses list in IncGeneralStep
 * @returns {({disablePadding: boolean, numeric: boolean, id: string, label: string}|{disablePadding: boolean, numeric: boolean, id: string, label: string}|{disablePadding: boolean, numeric: boolean, id: string, label: string}|{disablePadding: boolean, numeric: boolean, id: string, label: string}|{disablePadding: boolean, numeric: boolean, id: string, label: string})[]}
 */
const getIncAddressHeaderCells = () => {
  return [
    { id: 'fullAddress', numeric: false, disablePadding: false, label: 'Full Address' },
    { id: 'placeName', numeric: false, disablePadding: false, label: 'Place Name' },
    { id: 'isVictim', numeric: false, disablePadding: false, label: 'Is Victim' },
    { id: 'action', numeric: false, disablePadding: false, label: '' },
  ];
};

const getPtsPersonHeaderCells = () => {
  return [
    {
      id: 'fullName',
      numeric: false,
      disablePadding: false,
      label: 'Person',
      sortable: true,
      hideForSmallScreen: false,
    },
    {
      id: 'partyType',
      numeric: false,
      disablePadding: false,
      label: 'Type',
      sortable: true,
      hideForSmallScreen: false,
    },
    {
      id: 'ssn',
      numeric: false,
      disablePadding: false,
      label: 'SSN/OLN',
      hideForSmallScreen: false,
    },
    {
      id: 'info',
      numeric: false,
      disablePadding: false,
      label: 'Contact Info',
      hideForSmallScreen: true,
    },
    {
      id: 'created',
      numeric: false,
      disablePadding: false,
      label: 'Created By/Time',
      sortable: true,
      hideForSmallScreen: false,
    },
    {
      id: 'updated',
      numeric: false,
      disablePadding: false,
      label: 'Updated By/Time',
      sortable: true,
      hideForSmallScreen: false,
    },
    { id: 'action', numeric: false, disablePadding: false, label: '', hideForSmallScreen: false },
  ];
};

const getIncOffenseHeaderCells = () => {
  return [
    { id: 'statute', numeric: false, disablePadding: false, label: 'Statute', sortable: true },
    { id: 'date', numeric: false, disablePadding: false, label: 'Date', sortable: true },
    {
      id: 'updated',
      numeric: false,
      disablePadding: false,
      label: 'Updated',
      valueFormatter: (params) => displayDateTime(params.value),
      sortable: true,
    },
    { id: 'updatedBy', numeric: false, disablePadding: false, label: 'Updated By', sortable: true },
    { id: 'action', numeric: false, disablePadding: false, label: '' },
  ];
};

const getIncPropertiesHeaderCells = () => {
  return [
    { id: 'parentType', numeric: false, disablePadding: false, label: 'Property', sortable: true },
    {
      id: 'description',
      numeric: false,
      disablePadding: false,
      label: 'Description',
      sortable: true,
    },
    { id: 'created', numeric: false, disablePadding: false, label: 'Created', sortable: true },
    { id: 'createdBy', numeric: false, disablePadding: false, label: 'Created By', sortable: true },
    {
      id: 'updated',
      numeric: false,
      disablePadding: false,
      label: 'Updated',
      valueFormatter: (params) => displayDateTime(params.value),
      sortable: true,
    },
    { id: 'updatedBy', numeric: false, disablePadding: false, label: 'Updated By', sortable: true },
    { id: 'action', numeric: false, disablePadding: false, label: '' },
  ];
};

const getCadNotesHeaderCells = () => {
  return [
    {
      id: 'comment',
      numeric: false,
      disablePadding: false,
      label: 'Notes',
      sortable: true,
    },
    {
      id: 'created',
      numeric: false,
      disablePadding: false,
      label: 'Created',
      // sortable: true,
    },
    {
      id: 'createdBy',
      numeric: false,
      disablePadding: false,
      label: 'CreatedBy',
      // sortable: true,
    },
    {
      id: 'updated',
      numeric: false,
      disablePadding: false,
      label: 'Updated',
      // sortable: true,
    },
    {
      id: 'updatedBy',
      numeric: false,
      disablePadding: false,
      label: 'UpdatedBy',
      // sortable: true,
    },
  ];
};

const getIncEvidenceHeaderCells = () => {
  return [
    {
      id: 'evidenceType',
      numeric: false,
      disablePadding: false,
      label: 'Evidence',
      sortable: true,
    },
    { id: 'collected_info', numeric: false, disablePadding: false, label: 'Other Information' },
    {
      id: 'updated',
      numeric: false,
      disablePadding: false,
      label: 'Updated',
      valueFormatter: (params) => displayDateTime(params.value),
      sortable: true,
    },
    { id: 'updatedBy', numeric: false, disablePadding: false, label: 'Updated By', sortable: true },
    { id: 'action', numeric: false, disablePadding: false, label: '' },
  ];
};

const getIncCustodyHeaderCells = () => {
  return [
    {
      id: 'transactionDate',
      numeric: false,
      disablePadding: false,
      label: 'Transaction Date',
      sortable: true,
      hideForSmallScreen: false,
    },
    {
      id: 'Status',
      numeric: false,
      disablePadding: false,
      label: 'Status',
      sortable: true,
      hideForSmallScreen: false,
    },
    {
      id: 'reason',
      numeric: false,
      disablePadding: false,
      label: 'Reason',
      sortable: true,
      hideForSmallScreen: true,
    },
    {
      id: 'from',
      numeric: false,
      disablePadding: false,
      label: 'From',
      sortable: true,
      hideForSmallScreen: true,
    },
    {
      id: 'to',
      numeric: false,
      disablePadding: false,
      label: 'To',
      sortable: true,
      hideForSmallScreen: false,
    },
    {
      id: 'requiredAction',
      numeric: false,
      disablePadding: false,
      label: 'Required Action',
      sortable: true,
      hideForSmallScreen: true,
    },
    { id: 'action', numeric: false, disablePadding: false, label: '', hideForSmallScreen: false },
  ];
};

const getIncStorageHeaderCells = () => {
  return [
    { id: 'location', numeric: false, disablePadding: false, label: 'Location', sortable: true },
    { id: 'sub', numeric: false, disablePadding: false, label: 'Sub-Location', sortable: true },
    { id: 'date', numeric: false, disablePadding: false, label: 'Date In', sortable: true },
    { id: 'action', numeric: false, disablePadding: false, label: '' },
  ];
};

const getIncNarrativesHeaderCells = () => {
  return [
    { id: 'sequence', numeric: false, disablePadding: false, label: 'Narrative', sortable: true },
    { id: 'author', numeric: false, disablePadding: false, label: 'Author', sortable: true },
    {
      id: 'approvedBy',
      numeric: false,
      disablePadding: false,
      label: 'Approved By',
      sortable: true,
    },
    {
      id: 'updated',
      numeric: false,
      disablePadding: false,
      label: 'Entry Clerk',
      valueFormatter: (params) => displayDateTime(params.value),
      sortable: true,
    },
    { id: 'updatedBy', numeric: false, disablePadding: false, label: 'Updated By', sortable: true },
    { id: 'action', numeric: false, disablePadding: false, label: '' },
  ];
};

const getIncDispositionHeaderCells = () => {
  return [
    {
      id: 'disposition',
      numeric: false,
      disablePadding: false,
      label: 'Disposition',
      sortable: true,
    },
    {
      id: 'updated',
      numeric: false,
      disablePadding: false,
      label: 'Updated',
      valueFormatter: (params) => displayDateTime(params.value),
      sortable: true,
    },
    { id: 'updatedBy', numeric: false, disablePadding: false, label: 'Updated By', sortable: true },
    { id: 'action', numeric: false, disablePadding: false, label: '' },
  ];
};

const getIncAlertHeaderCells = () => {
  return [
    { id: 'alertType', numeric: false, disablePadding: false, label: 'Alert Type', sortable: true },
    {
      id: 'notification',
      numeric: false,
      disablePadding: false,
      label: 'Notification',
      sortable: true,
    },
    {
      id: 'updated',
      numeric: false,
      disablePadding: false,
      label: 'Updated',
      valueFormatter: (params) => displayDateTime(params.value),
      sortable: true,
    },
    { id: 'updatedBy', numeric: false, disablePadding: false, label: 'Updated By', sortable: true },
    { id: 'action', numeric: false, disablePadding: false, label: '' },
  ];
};

const getIncZoneHeaderCells = () => {
  return [
    { id: 'zone', numeric: false, disablePadding: false, label: 'Zone', sortable: true },
    {
      id: 'updated',
      numeric: false,
      disablePadding: false,
      label: 'Updated',
      valueFormatter: (params) => displayDateTime(params.value),
      sortable: true,
    },
    { id: 'updatedBy', numeric: false, disablePadding: false, label: 'Updated By', sortable: true },
    { id: 'action', numeric: false, disablePadding: false, label: '' },
  ];
};

const getIncNotificationHeaderCells = () => {
  return [
    {
      id: 'Notification',
      numeric: false,
      disablePadding: false,
      label: 'Notification',
      sortable: true,
    },
    {
      id: 'updated',
      numeric: false,
      disablePadding: false,
      label: 'Updated',
      valueFormatter: (params) => displayDateTime(params.value),
      sortable: true,
    },
    { id: 'updatedBy', numeric: false, disablePadding: false, label: 'Updated By', sortable: true },
    { id: 'action', numeric: false, disablePadding: false, label: '' },
  ];
};

const getIncCadUnitStatusHeaderCells = () => {
  return [
    {
      id: 'cadUnitStatus',
      numeric: false,
      disablePadding: false,
      label: 'Cad Unit Status',
      sortable: true,
    },
    {
      id: 'updated',
      numeric: false,
      disablePadding: false,
      label: 'Updated',
      valueFormatter: (params) => displayDateTime(params.value),
      sortable: true,
    },
    { id: 'updatedBy', numeric: false, disablePadding: false, label: 'Updated By', sortable: true },
    { id: 'action', numeric: false, disablePadding: false, label: '' },
  ];
};

const getIncCoordinateHeaderCells = () => {
  return [
    {
      id: 'coordinate',
      numeric: false,
      disablePadding: false,
      label: 'Coordinate',
      sortable: true,
    },
    {
      id: 'updated',
      numeric: false,
      disablePadding: false,
      label: 'Updated',
      valueFormatter: (params) => displayDateTime(params.value),
      sortable: true,
    },
    { id: 'updatedBy', numeric: false, disablePadding: false, label: 'Updated By', sortable: true },
    // { id: 'action', numeric: false, disablePadding: false, label: '' },
  ];
};

const getIncTagsHeaderCells = () => {
  return [
    {
      id: 'type',
      numeric: false,
      disablePadding: false,
      label: 'Type',
      sortable: true,
    },
    {
      id: 'value',
      numeric: false,
      disablePadding: false,
      label: 'Value',
      sortable: true,
    },
    {
      id: 'updated',
      numeric: false,
      disablePadding: false,
      label: 'Updated',
      valueFormatter: (params) => displayDateTime(params.value),
      sortable: true,
    },
    { id: 'updatedBy', numeric: false, disablePadding: false, label: 'Updated By', sortable: true },
    { id: 'action', numeric: false, disablePadding: false, label: '' },
  ];
};

const getAvailableOffensesHeaderCells = () => {
  return [
    {
      id: 'Avatar',
      numeric: false,
      disablePadding: false,
      label: '',
      sortable: false,
    },
    {
      id: 'arresteeDate',
      numeric: false,
      disablePadding: false,
      label: 'Arrest Date',
      sortable: true,
    },
    {
      id: 'offensesStatue',
      numeric: false,
      disablePadding: false,
      label: 'Offense',
      sortable: true,
      order: 'asc',
    },
    {
      id: 'arrestOfficer',
      numeric: false,
      disablePadding: false,
      label: 'Arrest Officer',
      sortable: false,
    },
    { id: 'action', numeric: false, disablePadding: false, label: '' },
  ];
};

const getAvailableArresteeHeaderCells = () => {
  return [
    {
      id: 'Avatar',
      numeric: false,
      disablePadding: false,
      label: '',
      sortable: false,
    },
    {
      id: 'arresteeDate',
      numeric: false,
      disablePadding: false,
      label: 'Arrest Date',
      sortable: false,
    },
    {
      id: 'nameSuffix',
      numeric: false,
      disablePadding: false,
      label: 'Offender',
      sortable: true,
    },
    {
      id: 'arrestOfficer',
      numeric: false,
      disablePadding: false,
      label: 'Arrest Officer',
      sortable: false,
    },
    { id: 'action', numeric: false, disablePadding: false, label: '' },
  ];
};

const getAvailablePropertiesHeaderCells = () => {
  return [
    // {
    //   id: 'Avatar',
    //   numeric: false,
    //   disablePadding: false,
    //   label: '',
    //   sortable: false,
    // },
    {
      id: 'status',
      numeric: false,
      disablePadding: false,
      label: 'Property Status',
      sortable: false,
    },
    {
      id: 'statusDate',
      numeric: false,
      disablePadding: false,
      label: 'Status Date',
      sortable: true,
    },
    {
      id: 'offenseStatute',
      numeric: false,
      disablePadding: false,
      label: 'Offense Statute',
      sortable: false,
    },
    { id: 'action', numeric: false, disablePadding: false, label: '' },
  ];
};

export const getListData = (flag) => {
  const incState = store.store.getState().incident;
  const evidenceState = store.store.getState().evidence;
  switch (flag) {
    case 'actionReport':
      let afterActionList = [];
      incState.actionReport.map((aar) => {
        afterActionList.push({
          id: aar?.ptsIncAfterActionId || null,
          ptsIncAfterActionId: aar?.ptsIncAfterActionId || null,
          ptsIncidentId: aar?.ptsIncidentId || null,
          ptsAddressId: aar?.ptsAddressId || null,

          ambushed: aar?.afterActionDetails?.values?.ambushed || null,
          beginDatetime: aar?.afterActionDetails?.values?.beginDatetime || null, // need to review
          condition: aar?.afterActionDetails?.values?.condition || null,
          involvedMultiAgencies: aar?.afterActionDetails?.values?.involvedMultiAgencies || null,
          officerKilledOrAssaulted:
            aar?.afterActionDetails?.values?.officerKilledOrAssaulted || null,
          officerApproachedOffender:
            aar?.afterActionDetails?.values?.officerApproachedOffender || null,
          investigatedByAnotherAgency:
            aar?.afterActionDetails?.values?.investigatedByAnotherAgency || null,
          reasonOfInitialContact: aar?.afterActionDetails?.values?.reasonOfInitialContact || null,
          seniorOfficerPresent: aar?.afterActionDetails?.values?.seniorOfficerPresent || null,
          totalOfficers: aar?.afterActionDetails?.values?.totalOfficers || null,
          totalAgencyOfficers: aar?.afterActionDetails?.values?.totalAgencyOfficers || null,
          totalOffenders: aar?.afterActionDetails?.values?.totalOffenders || null,
          notes: aar?.afterActionDetails?.values?.notes || null,

          updated: aar?.afterActionDetails?.values?.updated || null,
          updatedBy: aar?.afterActionDetails?.values?.updatedBy || null,
          created: aar?.afterActionDetails?.values?.created || null,
          createdBy: aar?.afterActionDetails?.values?.createdBy || null,

          statusDescription: aar?.afterActionDetails?.values?.statusDescription || null,
          officerCompletingReportFullname:
            aar?.afterActionDetails?.values?.officerCompletingReportFullname || null,
          reportDatetime: aar?.afterActionDetails?.values?.reportDatetimeForUiList || null,
          finalReviewedBy: aar?.afterActionDetails?.values?.finalReviewedBy || null,
        });
      });

      afterActionList.sort((a, b) => a.updated - b.updated);

      return afterActionList;
    case 'actionReportParties':
      let afterActionPartiesList = [];
      const actionReportParties = incState?.selectedActionReport?.actionReportParties || [];

      actionReportParties.forEach((arp) => {
        afterActionPartiesList.push({
          ptsIncAfterActionPartyId: arp?.ptsIncAfterActionPartyId || null,
          ptsIncAfterActionId: arp?.ptsIncAfterActionId || null,
          ptsIncidentId: arp?.ptsIncidentId || null,
          ptsPersonId: arp?.ptsPersonId || null,

          partyType: arp?.partyDetails?.values?.partyType || null,
          partyTypeDescription: arp?.partyDetails?.values?.partyTypeDescription || null,

          firstName: arp?.partyDetails?.values?.firstName || null,
          lastName: arp?.partyDetails?.values?.lastName || null,
          middleName: arp?.partyDetails?.values?.middleName || null,
          fullName: arp?.partyDetails?.values?.fullName || null,
          age: arp?.partyDetails?.values?.age || null,
          height: arp?.partyDetails?.values?.height || null,
          weight: arp?.partyDetails?.values?.weight || null,
          race: arp?.partyDetails?.values?.race || null,
          sex: arp?.partyDetails?.values?.sex || null,
          ethnicity: arp?.partyDetails?.values?.ethnicity || null,
          sexDescription: arp?.partyDetails?.values?.sexDescription || null,
          raceDescription: arp?.partyDetails?.values?.raceDescription || null,
          ethnicityDescription: arp?.partyDetails?.values?.ethnicityDescription || null,

          officerYearOfService: arp?.partyDetails?.values?.officerYearOfService || null,
          isOfficerFullTime: arp?.partyDetails?.values?.isOfficerFullTime || null,
          officerOnDuty: arp?.partyDetails?.values?.officerOnDuty || null,
          officerReadilyIdentifiable: arp?.partyDetails?.values?.officerReadilyIdentifiable || null,
          officerInjured: arp?.partyDetails?.values?.officerInjured || null,
          injuries: arp?.partyDetails?.values?.injuries || null,
          officerAssignmentType: arp?.partyDetails?.values?.officerAssignmentType || null,
          didOfficerDischargeFirearm: arp?.partyDetails?.values?.didOfficerDischargeFirearm || null,
          numberOfShotsFired: arp?.partyDetails?.values?.numberOfShotsFired || null,
          distanceFromOffender: arp?.partyDetails?.values?.distanceFromOffender || null,
          officerWeapons: arp?.partyDetails?.values?.officerWeapons || null,
          offenderImpaired: arp?.partyDetails?.values?.offenderImpaired || null,
          offenderImpairments: arp?.partyDetails?.values?.offenderImpairments || null,
          didOffenderResist: arp?.partyDetails?.values?.didOffenderResist || null,
          offenderResistanceUsed: arp?.partyDetails?.values?.offenderResistanceUsed || null,
          offenderThreatToward: arp?.partyDetails?.values?.offenderThreatToward || null,
          offenderArmed: arp?.partyDetails?.values?.offenderArmed || null,
          forceTypesToOffender: arp?.partyDetails?.values?.forceTypesToOffender || null,

          updated: arp?.partyDetails?.values?.updated || null,
          updatedBy: arp?.partyDetails?.values?.updatedBy || null,
          created: arp?.partyDetails?.values?.created || null,
          createdBy: arp?.partyDetails?.values?.createdBy || null,
        });
      });

      afterActionPartiesList.sort((a, b) => a.updated - b.updated);

      return afterActionPartiesList;
    case 'actionReportReviews':
      let afterActionReviewsList = [];
      const actionReportReviews = incState?.selectedActionReport?.actionReportReviews || [];

      actionReportReviews.forEach((arr) => {
        afterActionReviewsList.push({
          ptsIncAfterActionReviewId: arr.ptsIncAfterActionReviewId || null,
          ptsIncAfterActionId: arr.ptsIncAfterActionId || null,
          ptsIncidentId: arr.ptsIncidentId || null,

          reviewType: arr?.reviewDetails?.values?.reviewTypeDescription || null,
          reviewDateTime: arr?.reviewDetails?.values?.reviewDateTime || null,
          reviewerFullName: arr?.reviewDetails?.values?.reviewerFullName || null,
          reviewFinding: arr?.reviewDetails?.values?.reviewFindingDescription || null,
        });
      });

      afterActionReviewsList.sort((a, b) => a.updated - b.updated);

      return afterActionReviewsList;
    case 'addresses':
      let addressesList = [];
      incState.addresses.map((a) => {
        let newAddress = {
          AddressCategory: a.addressDetails.values.addressNumber?.toString().slice(0, 3),
          FullAddressText: a.addressDetails.values.fullAddressText,
          Prefix: a.addressDetails.values.addressNumberPrefix,
          Suffix: a.addressDetails.values.addressNumberSuffix,
          PostDirection: a.addressDetails.values.postDirection,
          PostalCode: a.addressDetails.values.postalCode,
          PostalCodeExt: a.addressDetails.values.postalCodeExtension,
          Number: a.addressDetails.values.addressNumber,
          PreDirection: a.addressDetails.values.preDirection,
          StreetName: a.addressDetails.values.streetName,
          State: a.addressDetails.values.state,
          City: a.addressDetails.values.city,
          StreetType: a.addressDetails.values.streetType,
          UnitType: a.addressIdDetails.values.unitType,
          UnitTypeIdentifier: a.addressIdDetails.values.unitTypeIdentifier,
          created: a.addressDetails.values.created,
          createdBy: a.addressDetails.values.createdBy,
          updated: a.addressDetails.values.updated,
          updatedBy: a.addressDetails.values.updatedBy,
          UpdatedIP: a.addressDetails.values.updatedIp,
          ptsAddressId: a.addressDetails.ptsAddressId,
          isPrimary: a.addressDetails.values.isPrimary,
          id: a.addressDetails.ptsAddressId,
          PlaceName: a.addressDetails.values.addressPlaces,
          isVictim: a.isVictim,
          ptsIncPlaceId: a.addressDetails.values?.ptsIncPlaceId,
          PlaceId: a.addressDetails.values?.placeId,
          partyType: a.addressDetails.values?.partyType === 'VIC' ? 'VICTIM' : '',
          ptsPlaceId: a.addressDetails.values?.ptsPlaceId,
        };

        if (a.addressDetails.values.isPrimary) addressesList.unshift(newAddress);
        else addressesList.push(newAddress);
      });

      return addressesList;
    case 'parties':
      let partiesList = [];
      incState.parties.map((o) => {
        partiesList.push({
          fullName: `${
            o.personDetail?.values.lastName ? o.personDetail.values.lastName + ',' : ''
          } ${o.personDetail?.values?.firstName ? o.personDetail.values.firstName : ''} ${
            o.personDetail?.values?.middleName ? o.personDetail.values.middleName : ''
          }`,
          personFullName: o.personDetail.values.fullName,
          sex: o.personDetail.values.sex || '',
          lastName: o.personDetail.values.lastName || '',
          firstName: o.personDetail.values.firstName || '',
          middleName: o.personDetail.values.middleName || '',
          ethnicity: o.personDetail.values.ethnicity || '',
          race: o.personDetail.values.race || '',
          age: o.personDetail.values.age,
          dob: o.personDetail.values.birthdate
            ? moment(o.personDetail.values.birthdate).format('MM/DD/YYYY')
            : null,
          ssn: o.personDetail.values.ssn,
          oln: o.personDetail.values.oln,
          partyType: o.personDetail.values.PartyTypeDescription || '',
          partyOrder: o.personDetail.values.partyOrder,
          fullAddressText: o.contactDetail.values.fullAddressText,
          email: o.contactDetail.values.emailmask,
          phone1: o.contactDetail.values.textmask1,
          phone2: o.contactDetail.values.textmask2,
          phone3: o.contactDetail.values.textmask3,
          ptsPersonId: o.personDetail.ptsPersonId || o.personDetail.tempPartyId,
          ptsIncPersonId: o.personDetail.ptsIncPersonId,
          ptsImageId: o.personDetail.values.ptsImageId,
          personImage: o.personDetail.values.personImage,
          isMP: o.mpBasicDetail.ptsMPVictimId === undefined ? false : true,
          isArrestee: o.personDetail.isArrestee ? true : false,
          availableOffenses: o.personDetail.values.availableOffenses || [],
          PersonId: o.PersonId,
          activeWarrant: o.personDetail.values.activeWarrant,
          isUnknownParty: o.personDetail.values.isUnknownParty,
          isSociety: o.personDetail.values.isSociety,
          updatedBy: o.personDetail.values.updatedBy,
          updated: o.personDetail.values.updated,
          created: o.personDetail.values.created,
          createdBy: o.personDetail.values.createdBy,
          isVictim: o.personDetail.values.isVictim,
          isOffender: o.personDetail.values.isOffender,
        });
      });

      partiesList.sort((a, b) => a.partyOrder - b.partyOrder);

      return partiesList;
    case 'properties':
      const propertiesList = [];
      incState.properties.forEach((p) => {
        // Getting all the status for the proeprty
        let allIncProperties = incState.incProperties
          .filter(
            (x) =>
              x.incPropertyDetails.incIncidentPropertyId === p.propertyDetails.incIncidentPropertyId
          )
          .sort((a, b) =>
            a.incPropertyDetails.values.updated > b.incPropertyDetails.values.updated ? -1 : 1
          );

        propertiesList.push({
          description: p.propertyDetails.values.description,
          parentType: p.propertyDetails.values.parentType,
          serialNumber: p.propertyDetails.values.serialNumber,
          vin: p.propertyDetails.values.vin,
          plateNumber: p.propertyDetails.values.plateNumber,
          plateState: p.propertyDetails.values.plateState,
          updated: p.propertyDetails.values.updated,
          updatedBy: p.propertyDetails.values.updatedBy,
          created: p.propertyDetails.values.created,
          createdBy: p.propertyDetails.values.createdBy,
          incIncidentPropertyId: p.propertyDetails.incIncidentPropertyId,
          id: p.propertyDetails.incIncidentPropertyId,
          propertyId: p.propertyDetails.propertyId,
          availableOffenses: p.propertyDetails.values.availableOffenses || [],
          latestStatus:
            allIncProperties.length > 0 ? allIncProperties[0].incPropertyDetails.values.status : '', // Including the latest status for the property
          ptsPropertyStorageId:
            p.propertyDetails.values?.ptsPropertyStorageId ||
            p.propertyDetails.values?.ptsPropertyStorageID ||
            null,
        });
      });
      return propertiesList;
    case 'offenses':
      let offensesList = [];
      incState.offenses.map((o) => {
        //let statute = ptsStatute.find((x) => x.Description === o.offenseDetails.values.statute);
        offensesList.push({
          ptsStatuteId: o.offenseDetails.values.statuteDetails.ptsStatuteID,
          statuteCode: o.offenseDetails.values.statuteDetails.Code,
          statute: o.offenseDetails.values.statute,
          date: o.offenseDetails.values.date
            ? `${moment(o.offenseDetails.values.date).format('MM/DD/YYYY hh:mm A')}`
            : null,
          updated: o.offenseDetails.values.updated,
          updatedBy: o.offenseDetails.values.updatedBy,
          created: o.offenseDetails.values.created,
          createdBy: o.offenseDetails.values.createdBy,
          ptsOffenseId: o.offenseDetails.ptsOffenseId || o.offenseDetails.tempOffenseId,
          availableArrestees: o.offenseDetails.values.arrestees,
          id: o.offenseDetails.ptsOffenseId || o.offenseDetails.tempOffenseId,
        });
      });
      return offensesList;
    case 'evidence':
      let evidencesList = [];
      incState.evidences.forEach((e) => {
        evidencesList.push({
          evidenceType: e.itemRecordDetail.values.category, //TODO: Should be named category & change itemEvidenceType to just evidenceType
          serialNumber: e.itemRecordDetail.values.serialNumber,
          plateNumber: e.itemRecordDetail.values.licenseNumber,
          vin: e.itemRecordDetail.values.vin,
          description: e.itemRecordDetail.values.description,
          make: e.itemRecordDetail.values.make,
          model: e.itemRecordDetail.values.model,
          collectedBy: e.itemRecordDetail.values.collectedBy,
          condition: e.itemRecordDetail.values.condition,
          locationCollected: e.itemRecordDetail.values.locationCollected,
          collectedDate: e.itemRecordDetail.values.collectedDate
            ? `${moment(e.itemRecordDetail.values.collectedDate).format('MM/DD/YYYY hh:mm A')}`
            : null,
          updatedBy: e.itemRecordDetail.values.updatedBy,
          updated: e.itemRecordDetail.values.updated,
          created: e.itemRecordDetail.values.created,
          createdBy: e.itemRecordDetail.values.createdBy,
          ptsEvidenceId: e.itemRecordDetail.ptsEvidenceId,
          id: e.itemRecordDetail.ptsEvidenceId,
          itemMakeDescription: e.itemRecordDetail.values.itemMakeDescription,
          ItemModelDescription: e.itemRecordDetail.values.itemModelDescription,
          ItemConditionDescription: e.itemRecordDetail.values.itemConditionDescription,
          evidenceId: e.itemRecordDetail.evidenceId,
          itemEvidenceType: e.itemRecordDetail.values.evidenceType,
        });
      });
      return evidencesList;
    case 'narratives': {
      let narrativesList = [];
      incState.narratives.forEach((n) => {
        narrativesList.push({
          narrativeType: n.narrativeDetail?.values.narrativeType,
          narrativeTitle: n.narrativeDetail?.values.narrativeTitle,
          approvedDT: n.narrativeDetail?.values.approvedDT
            ? `${moment(n.narrativeDetail?.values.approvedDT).format('MM/DD/YYYY hh:mm A')}`
            : null,
          narrativeDT: n.narrativeDetail?.values.narrativeDT
            ? `${moment(n.narrativeDetail?.values.narrativeDT).format('MM/DD/YYYY hh:mm A')}`
            : null,
          approvedBy: n.narrativeDetail?.values.approvedBy,
          authorId: n.narrativeDetail?.values.authorId,
          author: n.narrativeDetail?.values.author,
          entryClerkId: n.narrativeDetail?.values.entryClerkId,
          entryClerk: n.narrativeDetail?.values.entryClerk || '',
          updatedBy: n.narrativeDetail?.values.updatedBy,
          updated: n.narrativeDetail?.values.updated,
          created: n.narrativeDetail?.values.created,
          createdBy: n.narrativeDetail?.values.createdBy,
          ptsNarrativeId: n.narrativeDetail?.values.ptsNarrativeId,
          id: n.narrativeDetail?.values.ptsNarrativeId,
          sequence: n.narrativeDetail?.values.sequence,
        });
      });
      narrativesList = narrativesList.sort(
        (a, b) => parseFloat(a.sequence) - parseFloat(b.sequence)
      );
      return narrativesList;
    }
    case 'dispositions':
      let dispositionList = [];
      incState.dispositions?.forEach((d) => {
        dispositionList.push({
          disposition: d.values.disposition,
          dispositionDescription: d.values.dispositionDescription,
          updatedBy: d.values.updatedBy,
          updated: d.values.updated,
          created: d.values.created,
          createdBy: d.values.createdBy,
          dispositionDT: d.values.filed,
        });
      });
      return dispositionList;
    case 'zones':
      let zonesList = [];
      incState.zones.forEach((d) => {
        zonesList.push({
          zone: d.values.zone,
          updatedBy: d.values.updatedBy,
          updated: d.values.updated,
          created: d.values.created,
          createdBy: d.values.createdBy,
        });
      });
      return zonesList;
    case 'alerts':
      let alertsList = [];
      incState.alerts.forEach((d) => {
        alertsList.push({
          ptsAlertId: d.ptsAlertId,
          alertType: d.values.alertType,
          notification: d.values.notification,
          updatedBy: d.values.updatedBy,
          updated: d.values.updated
            ? `${moment(d.values.updated).format('MM/DD/YYYY hh:mm A')}`
            : null,
        });
      });
      return alertsList;
    case 'notifications':
      let notificationsList = [];
      incState.notifications.forEach((d) => {
        notificationsList.push({
          ptsNotificationId: d.ptsNotificationId,
          notification: d.values.notification,
          updatedBy: d.values.updatedBy,
          updated: d.values.updated
            ? `${moment(d.values.updated).format('MM/DD/YYYY hh:mm A')}`
            : null,
        });

        // console.log(notificationsList);
      });
      return notificationsList;
    case 'cadUnitStatuses':
      let cadUnitStatusesList = [];
      incState.cadUnitStatuses.forEach((d) => {
        cadUnitStatusesList.push({
          cadUnitStatus: d.values.cadUnitStatus,
          updatedBy: d.values.updatedBy,
          updated: d.values.updated
            ? `${moment(d.values.updated).format('MM/DD/YYYY hh:mm A')}`
            : null,
        });
      });
      return cadUnitStatusesList;
    case 'coordinates':
      let coordinatesList = [];
      incState.coordinates.forEach((d) => {
        coordinatesList.push({
          coordinate: d.values.coordinate,
          updatedBy: d.values.updatedBy,
          updated: d.values.updated
            ? `${moment(d.values.updated).format('MM/DD/YYYY hh:mm A')}`
            : null,
        });
      });
      return coordinatesList;
    case 'tags':
      let tagsList = [];
      incState.tags.forEach((t) => {
        tagsList.push({
          ptsTagId: t.values.ptsTagId,
          type: t.values.type,
          typeDescription: t.values.typeDescription,
          value: t.values.value,
          updated: t.values.updated,
          updatedBy: t.values.updatedBy,
          created: t.values.created,
          createdBy: t.values.createdBy,
        });
      });
      return tagsList;
    case 'cadNotes':
      let cadNotesList = [];
      incState.cadNotes.forEach((t) => {
        cadNotesList.push({
          parentType: t.parentType,
          comment: t.Comment,
          ptsCommentId: t.ptsCommentID,
          created: t.created,
          createdBy: t.createdBy,
          updated: t.updated,
          updatedBy: t.updatedBy,
        });
      });
      return cadNotesList;
    case 'evidenceParties':
      let evidencePartiesList = [];
      const selectedIncidentEvidence = incState?.selectedIncidentEvidence;

      let evidenceParties = [];

      if (evidenceState?.selectedEvidence?.evidenceParties?.length) {
        evidenceParties = evidenceState?.selectedEvidence?.evidenceParties;
      } else if (selectedIncidentEvidence?.evidenceParties?.length) {
        evidenceParties = selectedIncidentEvidence?.evidenceParties;
      }

      if (evidenceParties?.length) {
        evidenceParties.map((o) => {
          evidencePartiesList.push({
            fullName: `${
              o.personDetail?.values.lastName ? o.personDetail.values.lastName + ',' : ''
            } ${o.personDetail?.values?.firstName ? o.personDetail.values.firstName : ''} ${
              o.personDetail?.values?.middleName ? o.personDetail.values.middleName : ''
            }`,
            sex: o.personDetail.values.sex || '',
            lastName: o.personDetail.values.lastName || '',
            firstName: o.personDetail.values.firstName || '',
            middleName: o.personDetail.values.middleName || '',
            ethnicity: o.personDetail.values.ethnicity || '',
            race: o.personDetail.values.race || '',
            age: o.personDetail.values.age,
            dob: o.personDetail.values.birthdate
              ? moment(o.personDetail.values.birthdate).format('MM/DD/YYYY')
              : null,
            ssn: o.personDetail.values.ssn,
            oln: o.personDetail.values.oln,
            partyType: o.personDetail.values.PartyTypeDescription || '',
            partyTypeCode: o.personDetail.values.partyType || '',
            partyOrder: o.personDetail.values.partyOrder,
            fullAddressText: o.contactDetail.values.fullAddressText,
            email: o.contactDetail.values.emailmask,
            phone1: o.contactDetail.values.textmask1,
            phone2: o.contactDetail.values.textmask2,
            phone3: o.contactDetail.values.textmask3,
            ptsPersonId: o.personDetail.ptsPersonId || o.personDetail.tempPartyId,
            ptsEvidencePersonId: o.personDetail.ptsEvidencePersonId,
            ptsImageId: o.personDetail.values.ptsImageId,
            personImage: o.personDetail.values.personImage,
            // isMP: o.mpBasicDetail.ptsMPVictimId === undefined ? false : true,
            isArrestee: o.personDetail.isArrestee ? true : false,
            availableOffenses: o.personDetail.values.availableOffenses || [],
            PersonId: o.PersonId,
            activeWarrant: o.personDetail.values.activeWarrant,
            isUnknownParty: o.personDetail.values.isUnknownParty,
            isSociety: o.personDetail.values.isSociety,
            updatedBy: o.personDetail.values.updatedBy,
            updated: o.personDetail.values.updated,
            created: o.personDetail.values.created,
            createdBy: o.personDetail.values.createdBy,
            isVictim: o.personDetail.values.isVictim,
            isOffender: o.personDetail.values.isOffender,
          });
        });

        evidencePartiesList.sort((a, b) => a.partyOrder - b.partyOrder);
      }

      return evidencePartiesList;
    default:
      return;
  }
};

/**
 * Action creator function for setting list body rows / cells
 * @param entityFlag
 * @returns {function(...[*]=)}
 */
export const setListBodyCells = (entityFlag) => {
  return async (dispatch) => {
    switch (entityFlag) {
      case 'actionReport':
        return dispatch({
          type: SET_INC_LIST_BODY,
          payload: { entity: 'actionReport', data: getListData('actionReport') },
        });
      case 'actionReportParties':
        return dispatch({
          type: SET_INC_LIST_BODY,
          payload: { entity: 'actionReportParties', data: getListData('actionReportParties') },
        });
      case 'actionReportReviews':
        return dispatch({
          type: SET_INC_LIST_BODY,
          payload: { entity: 'actionReportReviews', data: getListData('actionReportReviews') },
        });
      case 'addresses':
        return dispatch({
          type: SET_INC_LIST_BODY,
          payload: { entity: 'addresses', data: getListData('addresses') },
        });
      case 'parties':
        return dispatch({
          type: SET_INC_LIST_BODY,
          payload: { entity: 'parties', data: getListData('parties') },
        });
      case 'properties':
        return dispatch({
          type: SET_INC_LIST_BODY,
          payload: { entity: 'properties', data: getListData('properties') },
        });
      case 'offenses':
        return dispatch({
          type: SET_INC_LIST_BODY,
          payload: { entity: 'offenses', data: getListData('offenses') },
        });
      case 'evidence':
        return dispatch({
          type: SET_INC_LIST_BODY,
          payload: { entity: 'evidence', data: getListData('evidence') },
        });
      case 'narratives':
        return dispatch({
          type: SET_INC_LIST_BODY,
          payload: { entity: 'narratives', data: getListData('narratives') },
        });
      case 'dispositions':
        return dispatch({
          type: SET_INC_LIST_BODY,
          payload: { entity: 'dispositions', data: getListData('dispositions') },
        });
      case 'zones':
        return dispatch({
          type: SET_INC_LIST_BODY,
          payload: { entity: 'zones', data: getListData('zones') },
        });
      case 'tags':
        return dispatch({
          type: SET_INC_LIST_BODY,
          payload: { entity: 'tags', data: getListData('tags') },
        });
      case 'cadNotes':
        return dispatch({
          type: SET_INC_LIST_BODY,
          payload: { entity: 'cadNotes', data: getListData('cadNotes') },
        });
      case 'evidenceParties':
        return dispatch({
          type: SET_INC_LIST_BODY,
          payload: { entity: 'evidenceParties', data: getListData('evidenceParties') },
        });
    }
  };
};

/**
 * Action creator function for resetting list body rows / cells
 */
export const resetAllEntitiesList = () => {
  return async (dispatch) => {
    return dispatch({ type: RESET_INC_LIST_BODY, payload: initList });
  };
};

export default function reducer(
  state = {
    incListHeaderState: {
      addresses: getIncAddressHeaderCells(),
      parties: getPtsPersonHeaderCells(),
      evidenceParties: getPtsPersonHeaderCells(),
      properties: getIncPropertiesHeaderCells(),
      offenses: getIncOffenseHeaderCells(),
      evidence: getIncEvidenceHeaderCells(),
      narratives: getIncNarrativesHeaderCells(),
      custody: getIncCustodyHeaderCells(),
      storages: getIncStorageHeaderCells(),
      dispositions: getIncDispositionHeaderCells(),
      alerts: getIncAlertHeaderCells(),
      zones: getIncZoneHeaderCells(),
      notifications: getIncNotificationHeaderCells(),
      cadUnitStatuses: getIncCadUnitStatusHeaderCells(),
      coordinates: getIncCoordinateHeaderCells(),
      tags: getIncTagsHeaderCells(),
      availableOffenses: getAvailableOffensesHeaderCells(),
      availableArrestees: getAvailableArresteeHeaderCells(),
      availableProperties: getAvailablePropertiesHeaderCells(),
      cadNotes: getCadNotesHeaderCells(),
    },
    incListBodyState: initList,
  },
  action
) {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case SET_INC_LIST_BODY:
      if (action.payload.entity === 'actionReport')
        return {
          ...state,
          incListBodyState: {
            ...state.incListBodyState,
            actionReport: action.payload.data,
          },
        };
      else if (action.payload.entity === 'actionReportParties')
        return {
          ...state,
          incListBodyState: {
            ...state.incListBodyState,
            actionReportParties: action.payload.data,
          },
        };
      else if (action.payload.entity === 'actionReportReviews')
        return {
          ...state,
          incListBodyState: {
            ...state.incListBodyState,
            actionReportReviews: action.payload.data,
          },
        };
      else if (action.payload.entity === 'addresses')
        return {
          ...state,
          incListBodyState: {
            ...state.incListBodyState,
            addresses: action.payload.data,
          },
        };
      else if (action.payload.entity === 'parties')
        return {
          ...state,
          incListBodyState: {
            ...state.incListBodyState,
            parties: action.payload.data,
          },
        };
      else if (action.payload.entity === 'evidenceParties')
        return {
          ...state,
          incListBodyState: {
            ...state.incListBodyState,
            evidenceParties: action.payload.data,
          },
        };
      else if (action.payload.entity === 'properties')
        return {
          ...state,
          incListBodyState: {
            ...state.incListBodyState,
            properties: action.payload.data,
          },
        };
      else if (action.payload.entity === 'offenses')
        return {
          ...state,
          incListBodyState: {
            ...state.incListBodyState,
            offenses: action.payload.data,
          },
        };
      else if (action.payload.entity === 'evidence')
        return {
          ...state,
          incListBodyState: {
            ...state.incListBodyState,
            evidence: action.payload.data,
          },
        };
      else if (action.payload.entity === 'narratives')
        return {
          ...state,
          incListBodyState: {
            ...state.incListBodyState,
            narratives: action.payload.data,
          },
        };
      else if (action.payload.entity === 'dispositions')
        return {
          ...state,
          incListBodyState: {
            ...state.incListBodyState,
            dispositions: action.payload.data,
          },
        };
      else if (action.payload.entity === 'zones')
        return {
          ...state,
          incListBodyState: {
            ...state.incListBodyState,
            zones: action.payload.data,
          },
        };
      else if (action.payload.entity === 'notifications')
        return {
          ...state,
          incListBodyState: {
            ...state.incListBodyState,
            notifications: action.payload.data,
          },
        };
      else if (action.payload.entity === 'cadUnitStatuses')
        return {
          ...state,
          incListBodyState: {
            ...state.incListBodyState,
            cadUnitStatuses: action.payload.data,
          },
        };
      else if (action.payload.entity === 'coordinates')
        return {
          ...state,
          incListBodyState: {
            ...state.incListBodyState,
            coordinates: action.payload.data,
          },
        };
      else if (action.payload.entity === 'alerts')
        return {
          ...state,
          incListBodyState: {
            ...state.incListBodyState,
            alerts: action.payload.data,
          },
        };
      else if (action.payload.entity === 'tags')
        return {
          ...state,
          incListBodyState: {
            ...state.incListBodyState,
            tags: action.payload.data,
          },
        };
      else if (action.payload.entity === 'cadNotes')
        return {
          ...state,
          incListBodyState: {
            ...state.incListBodyState,
            cadNotes: action.payload.data,
          },
        };
    //eslint-disable-next-line no-fallthrough
    case RESET_INC_LIST_BODY:
      return {
        ...state,
        incListBodyState: action.payload,
      };
  }
  return state;
}
