import React, { useState } from 'react';
import { connect } from 'react-redux';

import { ListItem, List, Menu, Button, makeStyles } from '@material-ui/core';

import RMSPrintButton from '../RMSButtons/RMSPrintButton';

const useStyles = makeStyles(() => ({
  button: {
    padding: '0px !important',
    '&:hover': {
      background: 'transparent',
    },
    border: 'none',
    background: 'transparent',
    minWidth: (props) => (props.isEditPage ? '40px' : '64px'),
    marginRight: '-8px',
  },
  menu: {
    width: '220px',
    padding: '0px !important',
    margin: '0px !important',
  },
}));

const list = [
  {
    id: 251,
    key: 'selected',
    label: 'Selected',
  },
  {
    id: 252,
    key: 'affidavit',
    label: 'Affidavit',
  },
  {
    id: 253,
    key: 'affidavitwarrantnote',
    label: 'Affidavit, Warrant & Note',
  },
  {
    id: 254,
    key: 'benchwarrant',
    label: 'Bench Warrant',
  },
  {
    id: 255,
    key: 'capias',
    label: 'Capias',
  },
  {
    id: 256,
    key: 'capiasprofine',
    label: 'Capias pro Fine',
  },
  {
    id: 257,
    key: 'complaint',
    label: 'Complaint',
  },
  {
    id: 258,
    key: 'complaintandwarrant',
    label: 'Complaint & Warrant',
  },
  {
    id: 259,
    key: 'notes',
    label: 'Notes',
  },
  {
    id: 260,
    key: 'recall',
    label: 'Recall',
  },
  {
    id: 261,
    key: 'warrant',
    label: 'Warrant',
  },
  {
    id: 262,
    key: 'warrantarrest',
    label: 'Warrant Arrest',
  },
  {
    id: 263,
    key: 'warrantsleeve',
    label: 'Warrant Sleeve',
  },
];

const WarrantPrintsMenu = (props) => {
  const classes = useStyles(props);
  const { tooltipText, size, startDate, endDate, generateReport, selection, type } = props;

  const [anchorElMenu, setAnchorElMenu] = useState(null);

  const handleClickMenu = (event) => {
    setAnchorElMenu(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorElMenu(null);
  };

  return (
    <>
      <Button
        variant="text"
        disableFocusRipple="true"
        disableRipple="true"
        className={classes.button}>
        <RMSPrintButton
          tooltipText={tooltipText}
          onClick={handleClickMenu}
          size={size}
          className={props.buttonClass}
        />
      </Button>
      <Menu
        className={classes.menu}
        anchorEl={anchorElMenu}
        keepMounted
        open={Boolean(anchorElMenu)}
        onClose={handleCloseMenu}
        classes={{ list: 'p-0' }}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}>
        <div className="overflow-hidden dropdown-menu-xl">
          <List className="flex-column">
            {type === 'add' ? (
              <>
                {' '}
                <ListItem
                  button
                  onClick={() => {
                    generateReport('list');
                    handleCloseMenu();
                  }}>
                  <span>List</span>
                </ListItem>
                <ListItem
                  disabled={!startDate || !endDate}
                  button
                  onClick={() => {
                    generateReport('warrantdetaillist', 264);
                    handleCloseMenu();
                  }}>
                  <span>Warrant Detail List</span>
                </ListItem>
              </>
            ) : (
              <></>
            )}
            {list.map((item) => (
              <ListItem
                disabled={type === 'add' && !selection}
                button
                onClick={() => {
                  generateReport(item.key, item.id);
                  handleCloseMenu();
                }}>
                <span>{item.label}</span>
              </ListItem>
            ))}
          </List>
        </div>
      </Menu>
    </>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(WarrantPrintsMenu);
