/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import { connect } from 'react-redux';

import moment from 'moment';
import Slider from 'react-slick';
import Grid from '@material-ui/core/Grid';
import { Card, makeStyles, Typography } from '@material-ui/core';

import avatarPlaceholder from '../../assets/images/avatars/avatar-526x526.png';
import { updateIncPartiesPersonForm } from 'reducers/IncReducer';
import RMSPersonCell from 'components/RMSList/RMSEnhancedCells/RMSPersonCell';

const useStyles = makeStyles(() => ({
  itemWrap: {
    fontWeight: 500,
    fontSize: '13px',
    textAlign: 'center',
    height: '20px',
  },
  itemLabel: {
    display: 'block',
    fontWeight: 400,
    background: '#B6C4D2',
    color: '#0b55a1',
    padding: '5px',
    marginBottom: '3px',
    textTransform: 'uppercase',
    borderRadius: '0.2rem',
  },
  imageItem: {
    display: 'block',
    width: '100%',
    height: '100%',
  },
  imgCon: {
    height: '140px',
    margin: '5px 10px',
    minWidth: '260px',
  },
  sliderStyle: {
    width: '220px !important',
    height: '140px !important',
    textAlign: 'center !important',
  },
  editButton: {
    cursor: 'pointer',
    fontSize: '21px',
    marginLeft: '8px',
    color: '#0153a3',
  },
  listRoot: {
    margin: '0px',
    padding: '0px',
  },
}));

// Slider Settings
const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const RMSPersonInfoCard = (props) => {
  /** region Component Variables and Props */
  const { data, wsClient, photoRefresh, record } = props;
  const classes = useStyles();
  const { persondId } = useParams();
  const [loaded, setLoaded] = React.useState(false);
  const [personInfo, setPersonInfo] = React.useState({});
  const [mugshot, setMugshot] = React.useState([]);
  /** endregion */
  /** region React Hooks */
  React.useEffect(() => {
    setPersonInfo({
      PersonId: data?.PersonID || data?.PersonId,
      PersonID: data?.PersonID || data?.PersonId,
      ptsPersonID: data?.ptsPersonID || data?.ptsPersonID,
      fullName: data?.fullName || data?.FullName,
      ageRangeIncrement: data?.AgeRangeIncrement,
      dob: data?.Born ? moment(data?.Born).format('MM/DD/YYYY') : null,
      firstName: data?.firstName || data?.FirstName,
      middleName: data?.middleName || data?.MiddleName,
      lastName: data?.lastName || data?.LastName,
      birthdate: data?.birthdate || data?.Born,
      suffix: data?.suffix || data?.Suffix,
      race: data?.race || data?.Race,
      sex: data?.sex || data?.Sex,
      hairColor: data?.hairColor || data?.HairColor,
      hairLength: data?.hairLength || data?.HairLength,
      eyeColor: data?.eyeColor || data?.EyeColor,
      height: data?.height || data?.Height,
      weight: data?.weight || data?.Weight,
      age: data?.age || data?.Age,
      ssn: data?.PersonCredentialSSN ? data?.PersonCredentialSSN[0]?.Number : '',
      oln: data?.PersonCredentialSSN ? data?.PersonCredentialOLN[0]?.Number : '',
      fullAddressText: data?.PartiesAddresses?.FullAddressText || data?.FullAddressText || '',
      email: data?.Email || '',
      phone1: data?.Phone1 || '',
      phone2: data?.Phone2 || '',
      handed: data?.handed || data?.Handed,
      ethnicity: data?.ethnicity || data?.Ethnicity,
      bloodType: data?.bloodType || data?.BloodType,
      hairStyle: data?.hairStyle || data?.HairStyle,
      sexOffender: data?.SexOffender,
    });
  }, [data]);
  /** endregion */
  /** region Helper functions */
  React.useEffect(() => {
    if (!wsClient?.websocket) {
      return;
    }
    props.duplicateCheck !== true && getPersonPhotos();
  }, [wsClient, photoRefresh]);

  const getPersonPhotos = async () => {
    if (persondId) {
      const service = wsClient?.websocket.service('record-person-photo');
      service.timeout = 200000;
      setLoaded(false);
      try {
        const result = await service.get(persondId);
        if (result) {
          setLoaded(true);
          let parsedLocalMugshots = result.Mugshots !== null ? JSON.parse(result.Mugshots) : null;
          if (parsedLocalMugshots) {
            setMugshot(parsedLocalMugshots);
          }
        }
        setLoaded(true);
      } catch (error) {
        setLoaded(true);
        console.log(error);
      }
    }
  };
  /** endregion */
  return (
    <Grid container spacing={3} wrap="nowrap">
      {record && (
        <Grid item xs={12} sm={2}>
          <Card className={classes.imgCon}>
            {loaded ? (
              mugshot.length > 0 ? (
                <Slider {...settings} className={classes.sliderStyle}>
                  {mugshot.map((m) => (
                    <a href="#/" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        className="card-img-top rounded"
                        src={'data:image/png;base64, ' + m?.FileObject}
                        style={{
                          objectFit: 'contain',
                          width: '200px',
                          height: '160px',
                          margin: 'auto',
                        }}
                      />
                    </a>
                  ))}
                </Slider>
              ) : (
                <Slider {...settings} className={classes.sliderStyle}>
                  <a href="#/" onClick={(e) => e.preventDefault()} className="card-img-wrapper ">
                    <img
                      alt="..."
                      className="card-img-top rounded"
                      src={avatarPlaceholder}
                      style={{
                        objectFit: 'contain',
                        width: '200px',
                        height: '160px',
                        margin: 'auto',
                      }}
                    />
                  </a>
                </Slider>
              )
            ) : (
              <Typography
                variant="body2"
                color="textSecondary"
                component="p"
                style={{
                  width: '100%',
                  height: '100%',
                  padding: '10px',
                }}>
                Loading...
              </Typography>
            )}
          </Card>
        </Grid>
      )}

      <Grid item xs={12} sm={props.record ? 10 : 12}>
        <RMSPersonCell row={personInfo} duplicateCheck={props.duplicateCheck} />
      </Grid>
    </Grid>
  );
};

RMSPersonInfoCard.propTypes = {
  props: PropTypes,
};

const mapStateToProps = (state) => ({
  wsClient: state.websocket,
});

export default connect(mapStateToProps, { updateIncPartiesPersonForm })(RMSPersonInfoCard);
