import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { formatDate, sortObjArr } from '../../../../utils/functions';
import PerfectScrollbar from 'react-perfect-scrollbar';

const statusWidth = 100;

const useStyles = makeStyles((theme) => ({
  histories: {
    minHeight: 41,
    maxHeight: '550px',
    overflowY: 'auto',
  },
  history:
    theme.palette.type === 'light'
      ? {
          padding: '5px 15px 5px',
          borderBottom: '1px solid #D3D3D3',
          color: 'rgba(0, 0, 0, 0.87)',
        }
      : {
          color: '#d4d6d7',
          padding: '5px 15px 5px',
          borderBottom: '1px solid #D3D3D3',
        },
  '& p': {
    fontSize: 13,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& h4': {
      fontSize: '1em',
      minWidth: 130,
      marginBottom: 0,
    },
  },
  date: {
    maxWidth: 150,
    display: 'inline-block',
  },
  statusTd: {
    flex: `0 0 ${statusWidth}px`,
    width: statusWidth,
    boxSizing: 'border-box',
  },
  left: {
    display: 'flex',
    alignItems: 'center',
  },
  status: {
    textAlign: 'center',
    '& > div': {
      fontSize: '1rem',
      fontWeight: 500,
      padding: '0 5px',
      borderRadius: 5,
    },
    '& i': {
      padding: '2px 10px 1px',
      borderRadius: '4px',
      display: 'inline-block',
      textTransform: 'uppercase',
      fontWeight: 500,
      fontSize: 12,
      fontStyle: 'normal',
    },
  },
  agency: {
    padding: '0 4px',
  },
}));

function History(props) {
  const classes = useStyles();
  const { Unit, Occurred, UnitStatus, AgencyID } = props.history;
  const { colorPalette } = props;
  const statusColor = colorPalette?.Units[UnitStatus];

  return (
    <div className={classes.history}>
      <div className={classes.header}>
        <div className={classes.left}>
          <h4>{Unit}</h4>
          <div className={classes.statusTd}>
            <div className={classes.status}>
              <i style={{ background: statusColor, color: '#000' }}>{UnitStatus}</i>
            </div>
          </div>
          <div className={classes.agency}>{AgencyID}</div>
        </div>
        <div>
          <span className={classes.date}>{formatDate(Occurred)}</span>
        </div>
      </div>
    </div>
  );
}

function HistoryTab(props) {
  const { colorPalette, sortOption } = props;
  const [history, setHistory] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    if (!props.history) return;
    setHistory(sortObjArr(props.history, 'Created', sortOption));
    // eslint-disable-next-line
  }, [props.history, sortOption]);
  if (!history) return '';

  return (
    <div className={classes.histories}>
      <PerfectScrollbar style={{ maxHeight: window.innerHeight - 500 + 'px' }}>
        {history.map((historySingle, idx) => (
          <History key={idx} history={historySingle} colorPalette={colorPalette} />
        ))}
      </PerfectScrollbar>
    </div>
  );
}

export default HistoryTab;
