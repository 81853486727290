import store from '../../config/configureStore';

export const isOffenseValid = (incEntityPtsOffenseId) => {
  let result = false;
  const { offenses } = store.store.getState().incident;

  offenses.forEach((offense) => {
    if (
      offense.offenseDetails.ptsOffenseId === incEntityPtsOffenseId &&
      offense.offenseDetails.values.excludeOffense === false &&
      offense.offenseDetails.values?.statuteDetails?.FBICode !== '0'
    )
      result = true;
  });

  return result;
};
