import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';

import clsx from 'clsx';
import { saveAs } from 'file-saver';

import {
  Divider,
  Grid,
  Tooltip,
  Dialog,
  Button,
  Checkbox,
  TextField,
  DialogActions,
  DialogTitle,
  DialogContentText,
  DialogContent,
  List,
  ListItem,
  ListItemSecondaryAction,
  Typography,
  makeStyles,
  IconButton,
} from '@material-ui/core';
import { alpha } from '@mui/material';

import { addUpdatedBy } from 'utils/functions';

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import GetAppIcon from '@material-ui/icons/GetApp';
import AttachFileIcon from '@material-ui/icons/AttachFile';

import RMSAddButton from 'components/RMSButtons/RMSAddButton';
import RMSDictionary from 'components/RMSAutoComplete/RMSDictonary';
import RMSAutoComplete from 'components/RMSAutoComplete/RMSAutoComplete';

import { getFullPermissions } from 'reducers/PermissionsReducer';
import { showEditAttachment } from 'reducers/PersonDialogsReducer';
import { SET_CITATION_ATTACHMENTS } from 'reducers/CitationReducer';
import { setSelectedAttachmentsForDownload } from 'reducers/AttachmentReducer';
import { displayDateTime } from 'reducers/TimeReducer';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 752,
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    margin: theme.spacing(4, 0, 2),
  },
  attachmentsButton: {},
  attachmentsAddButton: {
    textAlign: 'left',
    margin: '12px',
    paddingRight: '17px',
  },
  attachmentThumbNail: {
    width: 50,
    height: 50,
    margin: 'auto',
    border: '5px solid white',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    objectFit: 'none',
    objectPosition: 'center',
  },
  fileInput: {
    display: 'none',
  },
  dropZone: {
    background: theme.palette.colors.grey1,
    height: 150,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '5px',
    marginBottom: 8,
    '& svg': {
      marginRight: 8,
    },
  },
  dropZoneOver: {
    background: theme.palette.colors.grey1,
    height: 150,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '5px',
    border: `2px dashed #1976d2`,
    marginBottom: 8,
    '& svg': {
      marginRight: 8,
    },
  },
  container: {
    marginTop: '5px',
    marginBottom: '15px',
    padding: '20px',
    border: '1px solid #1976d2',
    borderRadius: '5px',
  },
  fileInfo: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '10px',
  },
  attachment: {
    position: 'relative',
    cursor: 'pointer',
    marginBottom: '5px',
    padding: '5px',
    border: theme.palette.type === 'light' ? '1px solid #d0d0d0' : '1px solid #616161',
    borderRadius: '5px',
    '&:hover': {
      backgroundColor: alpha('#1976d2', 0.2),
    },
  },
  selectedAttachment: {
    position: 'relative',
    cursor: 'pointer',
    // color: 'blue',
    marginBottom: '5px',
    padding: '5px',
    border: theme.palette.type === 'light' ? '1px solid #d0d0d0' : '1px solid #616161',
    borderRadius: '5px',
    backgroundColor: alpha('#1976d2', 0.2),
  },
  attachButton: {
    color: theme.button.color,
    borderColor: theme.button.color,
  },
  closeButton: {
    color: theme.button.color,
    marginRight: '5px',
  },
  ListItemHover: {
    '&:hover': {
      backgroundColor: alpha('#1976d2', 0.2),
    },
  },
  listItem_root: {
    borderTop: '.5px solid ',
    borderColor: theme.palette.type === 'light' ? '#d0d0d0' : '#616161',
    padding: '0px',
    height: '30px',
  },
  default_div: {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    // width: '20em',
    // [theme.breakpoints.down('md')]: {
    //   marginLeft: 'auto',
    //   marginRight: 'auto',
    // },
  },
  paperWidthSm: {
    minWidth: '50%',
  },
}));

const RMSMultiSelectAttachments = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  let {
    setField,
    setStateHolder,
    wsClient,
    isModal,
    user,
    type = 'incidentEntities',
    save,
    isAccordion,
    openAttachment,
    setOpenAttachment,
    warrantId,
    canAdd = true,
    canEdit = true,
    canDelete = true,
    canRead = true,
    attatchmentType = 'global',
    applyMultipleDownload = false,
    componentShouldRender,
    reloadAttachmentList,
  } = props;

  const [attachments, setAttachments] = useState([]);
  const [files, setFiles] = useState([]);
  const [draggedOver, setDraggedOver] = useState(false);
  const [open, setOpen] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [currentAttachmentId, setCurrentAttachmentId] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [permissionObj, setPermissionObj] = useState({});
  const [selectedAttachments, setSelectedAttachments] = useState([]);
  const [selectedAttachmentIndex, setSelectedAttachmentIndex] = useState(0);
  const [codeAttachmentTypes, setCodeAttachmentTypes] = useState(Array(files.length).fill(''));
  const [descriptions, setDescriptions] = useState(Array(files.length).fill(''));
  const [clearanceLevels, setClearanceLevels] = useState(Array(files.length).fill(''));
  const [attachmentFormState, setAttachmentFormState] = useState({
    isValid: false,
    changes: false,
    values: {},
    touched: {},
    errors: {},
    formType: 'edit',
  });

  const selectedDownloadAttachments = useSelector(
    (state) => state.attachments.selectedDownloadAttachments
  );
  const ptsIncidentId = useSelector((state) => state.incident.incident.ptsIncidentId);
  const userAgency = useSelector((state) => state.user.userAgency);
  const secClearanceLevel = useSelector((state) => state.user.userClearanceOptions);

  const inputId = `upload-file`;
  const className = draggedOver || Boolean(files.length) ? classes.dropZoneOver : classes.dropZone;

  useEffect(() => {
    if (props.attachments) {
      const dd = props.attachments.map((item) => {
        if (item?.rawFile) return item;
        return { ...item, url: arrayBufferToBase64(item.dataURL) };
      });
      setAttachments(dd);
    }
  }, [props.attachments, componentShouldRender]);

  useEffect(() => {
    setFiles([]);
  }, [open]);

  useEffect(() => {
    let obj = {
      Read: canRead || false,
      Create: canAdd || false,
      Edit: canEdit || false,
      Delete: canDelete || false,
    };
    if (attatchmentType === 'incident') {
      let p = getFullPermissions('rms', 'Incident Attachment', userAgency);

      obj = {
        Read: canRead && p.Read,
        Create: canAdd && p.Create,
        Edit: canEdit && p.Edit,
        Delete: canDelete && p.Delete,
      };
    }
    setPermissionObj(obj);
  }, [userAgency, canRead, canAdd, canDelete, canEdit]);

  useEffect(() => {
    if (isAccordion && openAttachment) handleAddButton();
  }, [openAttachment]);

  const closeDialog = () => setOpenDeleteDialog(false);

  const handleClick = (ptsAttachmentId, index) => {
    setCurrentAttachmentId(ptsAttachmentId);
    setCurrentIndex(index);
    setOpenDeleteDialog(true);
  };

  const handleRemove = () => {
    handleDelete(currentAttachmentId, currentIndex);
    closeDialog();
  };

  const arrayBufferToBase64 = (buffer) => {
    var binary = '';
    var bytes = new Uint8Array(buffer);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  };

  const readFile = (files) => {
    const promises = [];

    files.forEach((file) => {
      promises.push(
        new Promise((resolve) => {
          const reader = new FileReader();

          reader.onload = function (e) {
            let dataURL = e.target.result;
            dataURL = dataURL.replace(';base64', `;name=${file.name};base64`);
            resolve({ file, dataURL, name: file.name, type: file.type });
          };

          reader.readAsDataURL(file);
        })
      );
    });

    return Promise.all(promises);
  };

  const add = async () => {
    readFile(files).then((filesData) => {
      filesData.forEach((fileData, index) => {
        const updatedData = {
          ...fileData,
          rawFile: fileData.file,
          description: descriptions[index],
          codeAttachmentType: codeAttachmentTypes[index]?.Code || null,
          clearanceLevel: clearanceLevels[index] || '0',
          updatedBy: user.Username,
        };

        if (type === 'citation') {
          const data = {
            file: updatedData.file,
            description: updatedData.description, // Use description from updatedData
            codeAttachmentType: updatedData.codeAttachmentType, // Use codeAttachmentType from updatedData
          };
          save(data);
        } else if (type === 'INCIDENT' || type === 'Warrant') {
          const data = {
            id: type === 'INCIDENT' ? ptsIncidentId : warrantId,
            file: updatedData.file,
            type,
            description: updatedData.description, // Use description from updatedData
            codeAttachmentType: updatedData.codeAttachmentType, // Use codeAttachmentType from updatedData
            clearanceLevel: updatedData.clearanceLevel,
          };
          save(data);
        } else if (type === 'EVIDENCE') {
          const data = {
            id: props.EvidenceId,
            file: updatedData.file,
            type,
            description: updatedData.description, // Use description from updatedData
            codeAttachmentType: updatedData.codeAttachmentType, // Use codeAttachmentType from updatedData
          };

          save(data);
        } else {
          setField(null, updatedData, 'none', 'attachments', setStateHolder);
        }
      });

      setOpen(false);
      if (isAccordion) setOpenAttachment(false);

      // clear state
      setSelectedAttachmentIndex(0);
      setCodeAttachmentTypes(Array(files.length).fill(''));
      setDescriptions(Array(files.length).fill(''));
    });
  };

  const handleAttachmentChange = (event) => {
    const newFiles = event.target.files;

    const uniqueNewFiles = Array.from(newFiles).filter(
      (file) => !files.some((existingFile) => existingFile.name === file.name)
    );

    setFiles([...files, ...uniqueNewFiles]);
  };

  const handleDelete = async (id, index) => {
    let ats = attachments.filter((a, i) => i !== index);
    if (type === 'citation') {
      dispatch({ type: SET_CITATION_ATTACHMENTS, payload: ats });
    } else if (type === 'Warrant') {
      props.setWarrantAttachments(ats);
    } else {
      setField(null, ats, 'none', 'attachments', setStateHolder);
    }
    if (id) {
      const service = wsClient?.websocket.service('rms-attachments');
      service.timeout = 200000;
      await service.remove(id);
    }

    if (reloadAttachmentList) reloadAttachmentList();
  };

  const handleAttachmentSelect = (index) => {
    setSelectedAttachmentIndex(index);
  };

  const handleCodeAttachmentTypeChange = (index, value) => {
    const updatedCodeAttachmentTypes = [...codeAttachmentTypes];
    updatedCodeAttachmentTypes[index] = value;
    setCodeAttachmentTypes(updatedCodeAttachmentTypes);
  };

  const handleClearanceLevelChange = (index, value) => {
    const updatedClearanceLevels = [...clearanceLevels];
    updatedClearanceLevels[index] = value;
    setClearanceLevels(updatedClearanceLevels);
  };

  const handleDescriptionChange = (index, value) => {
    const updatedDescriptions = [...descriptions];
    updatedDescriptions[index] = value;
    setDescriptions(updatedDescriptions);
  };

  const onDrop = (ev) => {
    const droppedFiles = ev.dataTransfer.files;
    if (!droppedFiles.length) return setDraggedOver(false);

    const newFiles = Array.from(droppedFiles);

    // Filter out duplicate files based on file name
    const uniqueNewFiles = newFiles.filter(
      (file) => !files.some((existingFile) => existingFile.name === file.name)
    );

    setFiles([...files, ...uniqueNewFiles]);
    ev.preventDefault();
  };

  const onDragOver = (ev) => {
    if (!draggedOver) setDraggedOver(true);
    ev.preventDefault();
  };

  const onDragLeave = (ev) => {
    if (draggedOver) setDraggedOver(false);
    ev.preventDefault();
  };

  const handleRemoveFile = (indexToRemove) => {
    setFiles((prevFiles) => prevFiles.filter((_, index) => index !== indexToRemove));
  };

  const renderFileInfo = (file) => {
    const idx = file.name.lastIndexOf('.');
    const type = file.name.substr(idx);
    return (
      <>
        <AttachFileIcon type={type} />
        <span>{file.name}</span>
      </>
    );
  };

  const handleAddButton = () => {
    setOpen(true);
  };

  const handleDownload = (data, fileName) => {
    saveAs(data, fileName);
  };

  const handleCloseButton = () => {
    if (isAccordion) setOpenAttachment(false);
    setOpen(false);

    // clear state
    setSelectedAttachmentIndex(0);
    setCodeAttachmentTypes(Array(files.length).fill(''));
    setDescriptions(Array(files.length).fill(''));
  };

  const renderActions = () => {
    return (
      <DialogActions>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              justify="flex-end"
              alignItems="center"
              style={{ padding: 5, margin: '-15px 0px 10px' }}>
              <Button className={classes.closeButton} onClick={() => handleCloseButton()}>
                Close
              </Button>
              <Button
                color="primary"
                variant="contained"
                autoFocus
                onClick={add}
                disabled={!files.length}>
                Save
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </DialogActions>
    );
  };

  const handleEdit = (a) => {
    const data = {
      ParentType: a.ParentType,
      ParentID: a.ParentID,
      Description: a.description,
      FileName: a.name,
      ptsAttachmentID: a.ptsAttachmentId,
      FileType: a.type,
      Updated: a.updated,
      updatedBy: a.updatedBy,
      Created: a.created,
      CreatedBy: a.createdBy,
      codeAttachmentType: a.codeAttachmentType,
      ClearanceLevel: String(a.clearanceLevel),
      IsDeleted: a.IsDeleted,
    };
    const updateValue = addUpdatedBy(data);
    props.showEditAttachment(updateValue);
  };

  const handleToggleSelect = (attachment) => {
    // Step 1: Toggle selection
    const selectedIndex = selectedAttachments.indexOf(attachment.ptsAttachmentId);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = [...selectedAttachments, attachment.ptsAttachmentId];
    } else if (selectedIndex === 0) {
      newSelected = selectedAttachments.slice(1);
    } else if (selectedIndex === selectedAttachments.length - 1) {
      newSelected = selectedAttachments.slice(0, -1);
    } else if (selectedIndex > 0) {
      newSelected = [
        ...selectedAttachments.slice(0, selectedIndex),
        ...selectedAttachments.slice(selectedIndex + 1),
      ];
    }

    setSelectedAttachments(newSelected);

    // Step 2: Update selected attachments for download
    let newList = selectedDownloadAttachments ? [...selectedDownloadAttachments] : [];
    let found = false;

    newList = newList.filter((att) => {
      if (att.ptsAttachmentId === attachment.ptsAttachmentId) {
        found = true;
        return false;
      }
      return true;
    });

    if (!found) {
      newList.push(attachment);
    }

    dispatch(setSelectedAttachmentsForDownload(newList));
  };

  const isSelected = (ptsAttachmentId) => selectedAttachments.indexOf(ptsAttachmentId) !== -1;

  return (
    <div>
      <Grid container xs={12} spacing={2} className={isModal ? '' : 'mb-3'}>
        <Dialog
          toolbar
          onClose={() => {
            setOpen(false);
            if (isAccordion) setOpenAttachment(false);
          }}
          open={open}
          classes={{ paperWidthSm: classes.paperWidthSm }}>
          <DialogTitle
            className={'d-flex justify-content-between align-items-center'}
            id="alert-dialog-title"
            style={{ background: '#1976d2' }}>
            <span style={{ fontSize: '18px', color: 'white' }}>Add Attachment</span>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <div
                className={className}
                onDrop={onDrop}
                onDragOver={onDragOver}
                onDragLeave={onDragLeave}>
                <Tooltip title="Add Attachment">
                  <label htmlFor={inputId}>
                    <Button className={classes.attachButton} variant="outlined" component="span">
                      <GetAppIcon /> Add Attachment
                    </Button>
                  </label>
                </Tooltip>
                <input
                  accept="*"
                  className={classes.fileInput}
                  onChange={handleAttachmentChange}
                  id={inputId}
                  type="file"
                  multiple
                />
              </div>

              <div style={{ color: '#1976d2', fontWeight: 'bold' }}>Attachment List</div>

              <div className={classes.container} style={{ overflowY: 'auto', maxHeight: '210px' }}>
                {Boolean(files.length) ? (
                  <div className={classes.fileInfo}>
                    {files.map((file, index) => (
                      <div
                        key={index}
                        onClick={() => handleAttachmentSelect(index)}
                        className={
                          selectedAttachmentIndex === index
                            ? classes.selectedAttachment
                            : classes.attachment
                        }>
                        {renderFileInfo(file)}
                        <button
                          style={{
                            position: 'absolute',
                            top: '5px',
                            right: '5px',
                            background: 'transparent',
                            border: 'none',
                            cursor: 'pointer',
                            color: 'inherit',
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleRemoveFile(index);
                          }}>
                          ✖
                        </button>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div>The attachment has not been selected.</div>
                )}
              </div>

              {files.length && selectedAttachmentIndex < files.length ? (
                <div>
                  <div style={{ display: 'flex' }}>
                    <div style={{ flexGrow: 1, color: '#1976d2', fontWeight: 'bold' }}>
                      Attachment Details
                    </div>

                    <div style={{ display: 'flex' }}>
                      <div style={{ color: '#1976d2', fontWeight: 'bold' }}>Selected:&nbsp;</div>
                      <div>{files[selectedAttachmentIndex].name}</div>
                    </div>
                  </div>

                  {selectedAttachmentIndex !== null && (
                    <div className={classes.container}>
                      <RMSDictionary
                        compact
                        options="codeAttachmentTypes"
                        value={codeAttachmentTypes[selectedAttachmentIndex]}
                        className={classes.item2}
                        onChange={(e, value) =>
                          handleCodeAttachmentTypeChange(selectedAttachmentIndex, value)
                        }
                        label="Type"
                        style={{ marginBottom: '10px' }} // Add margin bottom to the type field
                      />

                      {type === 'INCIDENT' && (
                        <RMSAutoComplete
                          size="small"
                          id="combo-attachment-clearance-level"
                          autoComplete
                          autoSelect
                          autoHighlight
                          options={secClearanceLevel}
                          label="Clearance Level"
                          serviceName="secClearanceLevel"
                          variant="outlined"
                          title={'Level'}
                          formType={'edit'} // ClearanceLevel dictionary table do not have default option column
                          stateHolder={attachmentFormState}
                          setStateHolder={setAttachmentFormState}
                          stateHolderAttribute="attachmentClearanceLevel" //need to review
                          setField={(e, value) => {
                            handleClearanceLevelChange(selectedAttachmentIndex, value);
                          }}
                          value={clearanceLevels[selectedAttachmentIndex] || '0'} //need to review
                          className="mt-2 mb-2"
                        />
                      )}

                      <TextField
                        label="Description"
                        variant="outlined"
                        size="small"
                        value={descriptions[selectedAttachmentIndex] || ''}
                        onChange={(e) =>
                          handleDescriptionChange(selectedAttachmentIndex, e.target.value)
                        }
                        className={classes.txtField}
                        fullWidth
                      />
                    </div>
                  )}
                </div>
              ) : (
                <></>
              )}
            </DialogContentText>
          </DialogContent>
          {renderActions()}
        </Dialog>
      </Grid>

      {!isAccordion &&
        (isModal ? (
          <div className={classes.attachmentsAddButton}>
            <Tooltip title="Add Attachment">
              <RMSAddButton
                disabled={!permissionObj.Create}
                tooltipText="Add Attachment"
                onClick={() => handleAddButton()}
              />
            </Tooltip>
          </div>
        ) : (
          <>
            <div className={classes.attachmentsButton}>
              <span
                style={{ color: '#fff', background: '#1976d2' }}
                className="mb-1 badge badge-primary mr-2">
                Attachments
              </span>
              <Tooltip title="Add Attachment">
                <IconButton
                  disabled={!permissionObj.Create}
                  edge="end"
                  aria-label="delete"
                  onClick={() => setOpen(true)}>
                  <AttachFileIcon
                    style={{
                      fontSize: '17px',
                      color: permissionObj.Create ? '#1976d2' : '',
                      cursor: permissionObj.Create ? 'pointer' : 'not-allowed',
                    }}
                  />
                </IconButton>
              </Tooltip>
            </div>
            <Divider />
          </>
        ))}

      <Grid container xs={12} spacing={0}>
        <Grid item xs={12} spacing={0}>
          <div className={classes.demo}>
            <List dense disablePadding>
              {permissionObj.Read ? (
                !applyMultipleDownload ? (
                  attachments.map((a, index) => (
                    <ListItem
                      className={clsx(classes.ListItemHover, classes.listItem_root)}
                      style={{ borderWidth: index === 0 ? '0' : '' }}
                      disableGutters
                      disablePadding>
                      <Grid container item xs={10} spacing={2}>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={2}>
                          <div className={classes.default_div}>{a.ptsAttachmentId || ''}</div>
                        </Grid>
                        {type === 'INCIDENT' && (
                          <>
                            <Grid item xs={3}>
                              <div className={classes.default_div}>
                                {a.codeAttachmentType || ''}
                              </div>
                            </Grid>
                          </>
                        )}

                        <Grid item xs={5}>
                          <div className={classes.default_div}>{a.description || ''}</div>
                        </Grid>
                      </Grid>
                      <Grid xs={2}>
                        <div className={clsx(classes.default_div, 'ml-2')}>{a.type || ''}</div>
                        <ListItemSecondaryAction>
                          <Tooltip title="Download">
                            <IconButton
                              color="primary"
                              edge="end"
                              aria-label="delete"
                              className="mt-1">
                              <GetAppIcon
                                onClick={() =>
                                  handleDownload(
                                    a.rawFile ? a.rawFile : new Blob([a.dataURL], { type: a.type }),
                                    a.name
                                  )
                                }
                              />
                            </IconButton>
                          </Tooltip>
                          {['citation', 'INCIDENT'].includes(type) && (
                            <Tooltip title="Edit">
                              <IconButton
                                edge="end"
                                aria-label="Edit"
                                color="primary"
                                // disabled={!a.ptsAttachmentId}
                                disabled={!permissionObj.Edit}>
                                <EditIcon
                                  onClick={() => {
                                    handleEdit(a);
                                  }}
                                />
                              </IconButton>
                            </Tooltip>
                          )}
                          <Tooltip title="Delete">
                            <IconButton
                              edge="end"
                              aria-label="delete"
                              color="primary"
                              disabled={!permissionObj.Delete}>
                              <DeleteIcon onClick={() => handleClick(a.ptsAttachmentId, index)} />
                            </IconButton>
                          </Tooltip>
                        </ListItemSecondaryAction>
                      </Grid>
                    </ListItem>
                  ))
                ) : (
                  attachments.map((a, index) => (
                    <ListItem
                      key={a.ptsAttachmentId}
                      className={clsx(classes.ListItemHover, classes.listItem_root)}
                      style={{ borderWidth: index === 0 ? '0' : '' }}
                      disableGutters
                      disablePadding>
                      <Grid
                        container
                        item
                        xs={10}
                        spacing={2}
                        onClick={() => handleToggleSelect(a)}>
                        <Grid item xs={1} className={classes.default_div}>
                          <div className={classes.default_div}>
                            <Checkbox
                              checked={isSelected(a.ptsAttachmentId)}
                              onClick={() => handleToggleSelect(a)}
                            />
                          </div>
                        </Grid>

                        <Grid item xs={2} className={classes.default_div}>
                          <div>{a.ptsAttachmentId || ''}</div>
                        </Grid>

                        <Grid item xs={2} className={classes.default_div}>
                          <div>{a.codeAttachmentType || ''}</div>
                        </Grid>

                        <Grid item xs={2} className={classes.default_div}>
                          <div>{a.description || ''}</div>
                        </Grid>

                        <Grid item xs={2} className={classes.default_div}>
                          <div>{displayDateTime(a.created) || ''}</div>
                        </Grid>

                        <Grid item xs={2} className={classes.default_div}>
                          <div>{displayDateTime(a.updated) || ''}</div>
                        </Grid>
                      </Grid>
                      <Grid xs={2}>
                        <div
                          className={clsx(classes.default_div, 'ml-2')}
                          onClick={() => handleToggleSelect(a)}>
                          {a.type || ''}
                        </div>
                        <ListItemSecondaryAction>
                          {['citation', 'INCIDENT', 'EVIDENCE'].includes(type) && (
                            <Tooltip title="Edit">
                              <IconButton
                                edge="end"
                                aria-label="Edit"
                                color="primary"
                                // disabled={!a.ptsAttachmentId}
                                disabled={!permissionObj.Edit}>
                                <EditIcon
                                  onClick={() => {
                                    handleEdit(a);
                                  }}
                                />
                              </IconButton>
                            </Tooltip>
                          )}
                          <Tooltip title="Delete">
                            <IconButton
                              edge="end"
                              aria-label="delete"
                              color="primary"
                              disabled={!permissionObj.Delete}>
                              <DeleteIcon onClick={() => handleClick(a.ptsAttachmentId, index)} />
                            </IconButton>
                          </Tooltip>
                        </ListItemSecondaryAction>
                      </Grid>
                    </ListItem>
                  ))
                )
              ) : (
                <div style={{ width: '100%', textAlign: 'center' }}>
                  <h6 className="pt-2">You don't have permission to see the List</h6>
                </div>
              )}
            </List>
          </div>
        </Grid>
      </Grid>
      <Dialog open={openDeleteDialog} onClose={closeDialog} maxWidth={'md'}>
        <DialogTitle style={{ background: '#1976d2', color: 'white' }}>
          {<Typography variant="h5">Remove</Typography>}
        </DialogTitle>
        <DialogContent style={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="subtitle2" gutterBottom component="div">
            Are you sure you want to remove?
          </Typography>
        </DialogContent>
        <DialogActions>
          <div className=" mb-1">
            <Button style={{ background: '#f1f1f1', color: 'black' }} onClick={closeDialog}>
              Cancel
            </Button>
            <Button
              className="ml-2"
              variant="contained"
              style={{ background: '#1976D2', color: 'white' }}
              onClick={handleRemove}>
              Remove
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => ({
  wsClient: state.websocket,
  user: state.user?.userData?.user,
  permissions: state.permissions,
  userAgency: state.user.userAgency,
});

export default connect(mapStateToProps, { showEditAttachment })(RMSMultiSelectAttachments);
