import { useMediaQuery } from '@material-ui/core';
import { Grid } from '@mui/material';
import RMSAddButton from 'components/RMSButtons/RMSAddButton';
import React from 'react';
import { useDispatch } from 'react-redux';
import { handleError } from 'reducers/ErrorReducer';
import { CodeTagTypeIconRender } from 'utils/functions';

function RMSPersonTagsCard(props) {
  const { tags, setTab, setAddTags, setData, personPermissions } = props;
  const isMediumScreenOrSmaller = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const dispatch = useDispatch();
  const onClickAdd = () => {
    if (personPermissions && !personPermissions.Edit) {
      dispatch(
        handleError({
          code: 440,
          message: 'You are not permitted to do this action.',
        })
      );
      return;
    }
    setTab('tags');
    setAddTags(true);
  };

  return (
    <Grid container>
      <Grid item xs={isMediumScreenOrSmaller ? 8 : 9}>
        <div style={{ marginTop: 10, maxHeight: '130px', overflow: 'auto' }}>
          {tags?.map((tag) => {
            if (tag.IsDeleted) return null;
            if (!tag.IsActive) return null;
            const tagData = {
              ptsTagID: tag.ptsTagID,
              created: tag.Created,
              createdBy: tag.CreatedBy,
              updated: tag.Updated,
              updatedBy: tag.UpdatedBy,
              type: tag.Code,
              typeDescription: tag.CodeDesc,
              setData: setData,
              description: tag.Value,
            };
            const TagIcon = CodeTagTypeIconRender(tag?.Icon, 'small', '18px', tagData, true);
            return <span>{TagIcon}</span>;
          })}
        </div>
      </Grid>
      <Grid item xs={isMediumScreenOrSmaller ? 4 : 3}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '55px',
          }}>
          <RMSAddButton onClick={() => onClickAdd()} />
        </div>
      </Grid>
    </Grid>
  );
}

export default RMSPersonTagsCard;
