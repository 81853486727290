import store from '../../config/configureStore';
import { incPropertiesSchema } from 'simpl-schema-validation/schema';
import { generateHandleEditOffense } from 'simpl-schema-validation/helpers/generateHandleEdit';

export const validateAllIncProperty = () => {
  const currentState = store.store.getState().incident;
  const { incProperties, currentOffense, turnOfValidation } = currentState;
  if (turnOfValidation) return [];

  let validationErrors = [];

  let statusCodes = incProperties.map(
    (incProperty) => incProperty.incPropertyDetails.values.status || '0'
  );

  let objToValidate = {
    allStatus: statusCodes,
    incProperties,
  };

  let allIncPropertiesValidationContext = incPropertiesSchema.newContext();

  let result = allIncPropertiesValidationContext.validate(objToValidate, {
    keys: ['allStatus'],
  });

  if (!result) {
    allIncPropertiesValidationContext.validationErrors().forEach((error) => {
      validationErrors.push({
        id: currentOffense,
        ptsOffenseId: currentOffense,
        idType: 'ptsOffenseId',
        errType: error.type,
        field: error.name,
        name: `Offense Properties`,
        screen: 'Offense Property',
        message: allIncPropertiesValidationContext.keyErrorMessage(error.name),
        ptsIncidentId: currentState.ptsIncidentId,
        stepper: 4,
        handleEdit: generateHandleEditOffense(currentState.ptsIncidentId, currentOffense, 4),
        identifier: `${currentState.ptsIncidentId}-${error.type.split('-').pop()}-${
          error.name
        }-OffenseProperty-${currentOffense}`,
      });
    });
  }

  return validationErrors;
};
