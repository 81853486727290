import store from '../../config/configureStore';

import { offenseDetailsSchema } from 'simpl-schema-validation/schema';
import { validateAllOffenses } from '.';
import { generateHandleEditOffense } from 'simpl-schema-validation/helpers/generateHandleEdit';

export const validateOffenses = () => {
  const currentState = store.store.getState().incident;

  const { offenses, incVictims, turnOfValidation } = currentState;
  if (turnOfValidation) return [];

  let validationErrors = [];

  offenses.forEach((offense) => {
    if (
      offense.offenseDetails.values.excludeOffense ||
      offense.offenseDetails.values?.statuteDetails?.FBICode === '0'
    )
      return;

    let structuresEntered = parseInt(offense.offenseDetails.values.structuresEntered);
    if (Number.isNaN(structuresEntered)) {
      structuresEntered = 0;
    }

    const offensesIncVictims = incVictims.filter(
      (incVictim) => incVictim.incVictimDetails.ptsOffenseId === offense.offenseDetails.ptsOffenseId
    );

    let objToValidate = {
      offense: offense.offenseDetails,
      locationCategory: offense.offenseDetails.values.locationCategory,
      biasMotivation: offense.offenseDetails.values.biasMotivation || '',
      criminalActivity: offense.offenseDetails.values.criminalActivity,
      structuresEntered,
      forceCategory: offense.offenseDetails.values.forceCategory,
      methodOfEntry: offense.offenseDetails.values.methodOfEntry,
      offenseAttempted: !offense.offenseDetails.values.offenseAttempted ? false : true,
      offenderSuspectedOfUsing: offense.offenseDetails.values.offenderSuspectedOfUsing,
      ucrCode: offense.offenseDetails.values.statuteDetails
        ? offense.offenseDetails.values.statuteDetails.FBICode
        : null,
      incVictims: offense.offenseDetails.values.victimIds ?? [],
      incSubjects: offense.offenseDetails.values.subjectIds ?? [],
      incArrestees: offense.offenseDetails.values.arresteeIds ?? [],
      victimsList: offensesIncVictims || [],
    };

    let offenseValidationContext = offenseDetailsSchema.newContext();
    let result = offenseValidationContext.validate(objToValidate, {
      keys: [
        'locationCategory',
        'structuresEntered',
        'biasMotivation',
        'criminalActivity',
        'forceCategory',
        'methodOfEntry',
        'offenseAttempted',
        'offenderSuspectedOfUsing',
        'incVictims',
        'incSubjects',
        'incArrestees',
        'statuteDetails',
      ],
    });

    if (!result) {
      offenseValidationContext.validationErrors().forEach((error) => {
        if (offense.offenseDetails.ptsOffenseId) {
          let stepperId;
          if (error.type === 'NIBRS-2019.2-23 Victims') {
            stepperId = 2;
          } else if (error.type === 'NIBRS-2019.2-23 Subjects') {
            stepperId = 1;
          } else if (error.type === 'NIBRS-2019.2-194') {
            stepperId = 5;
          } else {
            stepperId = 0;
          }
          validationErrors.push({
            id: offense.offenseDetails.ptsOffenseId,
            idType: 'ptsOffenseId',
            errType: error.type,
            field: error.name,
            name:
              offense.offenseDetails.values.statuteDetails?.Description +
              ' | ' +
              offense.offenseDetails.values.statuteDetails?.Code,
            screen: 'Offense',
            stepper: stepperId,
            message: offenseValidationContext.keyErrorMessage(error.name),
            ptsIncidentId: currentState.ptsIncidentId,
            ptsOffenseId: offense.offenseDetails.ptsOffenseId,
            handleEdit: generateHandleEditOffense(
              currentState.ptsIncidentId,
              offense.offenseDetails.ptsOffenseId,
              stepperId
            ),
            identifier: `${currentState.ptsIncidentId}-${error.type.split('-').pop()}-${
              error.name
            }-Offense-${offense.offenseDetails.ptsOffenseId}`,
          });
        }
      });
    }
  });

  const allOffensesValidationErrors = validateAllOffenses();

  return validationErrors.concat(allOffensesValidationErrors);
};
