const SET_INC_GENERAL_SELECTED_TAB = 'SET_INC_GENERAL_SELECTED_TAB';
const SET_INC_PARTIES_SELECTED_TAB = 'SET_INC_PARTIES_SELECTED_TAB';
const SET_INC_PROPERTIES_SELECTED_TAB = 'SET_INC_PROPERTIES_SELECTED_TAB';
const SET_INC_OFFENSES_SELECTED_TAB = 'SET_INC_OFFENSES_SELECTED_TAB';
const SET_OFFENSES_SELECTED_TAB = 'SET_OFFENSES_SELECTED_TAB';
const SET_INC_EVIDENCE_SELECTED_TAB = 'SET_INC_EVIDENCE_SELECTED_TAB';
const SET_INC_NARRATIVES_SELECTED_TAB = 'SET_INC_NARRATIVES_SELECTED_TAB';
const SET_INC_FORM_ACTIVE_STEP = 'SET_INC_FORM_ACTIVE_STEP';
const SET_INC_FORM_ACTIVE_STEP_CAD = 'SET_INC_FORM_ACTIVE_STEP_CAD';
const RESET_INC_FORM_ACTIVE_STEP_AND_TAB = 'RESET_INC_FORM_ACTIVE_STEP_AND_TAB';
const SET_VICTIM_DV_SELECTED_TAB = 'SET_VICTIM_DV_SELECTED_TAB';
const SET_PERSON_RECORD_DETAILS_TAB = 'SET_PERSON_RECORD_DETAILS_TAB';

export const setIncGeneralTab = (value) => {
  return async (dispatch) => {
    return dispatch({ type: SET_INC_GENERAL_SELECTED_TAB, payload: value });
  };
};

export const setIncPartiesTab = (value) => {
  return async (dispatch) => {
    return dispatch({ type: SET_INC_PARTIES_SELECTED_TAB, payload: value });
  };
};

export const setIncPropertiesTab = (value) => {
  return async (dispatch) => {
    return dispatch({ type: SET_INC_PROPERTIES_SELECTED_TAB, payload: value });
  };
};

export const setIncOffensesTab = (value) => {
  return async (dispatch) => {
    return dispatch({ type: SET_INC_OFFENSES_SELECTED_TAB, payload: value });
  };
};

export const setOffensesTab = (value) => {
  return async (dispatch) => {
    return dispatch({ type: SET_OFFENSES_SELECTED_TAB, payload: value });
  };
};

export const setVictimDVTab = (value) => {
  return async (dispatch) => {
    return dispatch({ type: SET_VICTIM_DV_SELECTED_TAB, payload: value });
  };
};

export const setIncEvidenceTab = (value) => {
  return async (dispatch) => {
    return dispatch({ type: SET_INC_EVIDENCE_SELECTED_TAB, payload: value });
  };
};

export const setIncNarrativesTab = (value) => {
  return async (dispatch) => {
    return dispatch({ type: SET_INC_NARRATIVES_SELECTED_TAB, payload: value });
  };
};

export const setIncFormActiveStep = (value) => {
  return async (dispatch) => {
    return dispatch({ type: SET_INC_FORM_ACTIVE_STEP, payload: value });
  };
};

export const setIncFormActiveStepCad = (value) => {
  return async (dispatch) => {
    return dispatch({ type: SET_INC_FORM_ACTIVE_STEP_CAD, payload: value });
  };
};

export const setRecordPersonDetailsStep = (value) => {
  return async (dispatch) => {
    return dispatch({ type: SET_PERSON_RECORD_DETAILS_TAB, payload: value });
  };
};

export const resetTncFormActiveStepAndTab = () => {
  return async (dispatch) => {
    const state = {
      incGeneralTabState: 0,
      incPartiesTabState: 0,
      incPropertiesTabState: 0,
      incFormActiveStep: 0,
      incFormActiveStepCad: 0,
      incOffensesTabState: 0,
      offensesTabState: 0,
      victimDVTabState: 0,
      incEvidenceTabState: 0,
      incNarrativesTabState: 0,
      recordPersonDetailsTabState: 0,
    };

    dispatch({ type: RESET_INC_FORM_ACTIVE_STEP_AND_TAB, payload: state });
  };
};

export default function reducer(
  state = {
    incGeneralTabState: 0,
    incPartiesTabState: 0,
    incPropertiesTabState: 0,
    incFormActiveStep: 0,
    incFormActiveStepCad: 0,
    incOffensesTabState: 0,
    offensesTabState: 0,
    victimDVTabState: 0,
    incEvidenceTabState: 0,
    incNarrativesTabState: 0,
    recordPersonDetailsTabState: 0,
  },
  action
) {
  switch (action.type) {
    case SET_INC_GENERAL_SELECTED_TAB:
      return { ...state, incGeneralTabState: action.payload };
    case SET_INC_PARTIES_SELECTED_TAB:
      return { ...state, incPartiesTabState: action.payload };
    case SET_INC_PROPERTIES_SELECTED_TAB:
      return { ...state, incPropertiesTabState: action.payload };
    case SET_INC_OFFENSES_SELECTED_TAB:
      return { ...state, incOffensesTabState: action.payload };
    case SET_OFFENSES_SELECTED_TAB:
      return { ...state, offensesTabState: action.payload };
    case SET_VICTIM_DV_SELECTED_TAB:
      return { ...state, victimDVTabState: action.payload };
    case SET_INC_EVIDENCE_SELECTED_TAB:
      return { ...state, incEvidenceTabState: action.payload };
    case SET_INC_NARRATIVES_SELECTED_TAB:
      return { ...state, incNarrativesTabState: action.payload };
    case SET_INC_FORM_ACTIVE_STEP:
      return { ...state, incFormActiveStep: action.payload };
    case SET_INC_FORM_ACTIVE_STEP_CAD:
      return { ...state, incFormActiveStepCad: action.payload };
    case RESET_INC_FORM_ACTIVE_STEP_AND_TAB:
      return { ...state, ...action.payload };
    case SET_PERSON_RECORD_DETAILS_TAB:
      return { ...state, recordPersonDetailsTabState: action.payload };
    default:
      return state;
  }
}
