import React from 'react';
import { connect } from 'react-redux';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { checkForUpdates } from '../reducers/AppInfoReducer';
import Button from '@material-ui/core/Button';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles(() => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    textAlign: 'center',
    width: 320,
    minHeight: 150,
  },
  footer: {
    fontSize: 13,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'end',
    '& p': {
      marginBottom: 0,
    },
  },
  action: {
    marginBottom: 10,
  },
}));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

function AboutDialog(props) {
  const classes = useStyles();
  const {
    apiVersion,
    buildNo,
    buildTime,
    availBuildNo,
    availBuildTime,
    appVersion,
  } = props.appInfo;
  const updateAvail = buildNo !== availBuildNo || buildTime !== availBuildTime;

  React.useEffect(() => {
    props.checkForUpdates();
  }, []);

  const reload = () => {
    window.location.reload(true);
  };

  return (
    <Dialog onClose={props.close} open={true}>
      <DialogTitle id="customized-dialog-title" onClose={props.close}>
        About
      </DialogTitle>
      <DialogContent dividers>
        <div className={classes.content}>
          <div className={classes.header}>
            <p>
              <strong>PTS</strong>
            </p>
          </div>
          <div className={classes.body}>
            <p>
              App Version: {appVersion} <br /> Api version: {apiVersion} <br /> Build no: {buildNo}{' '}
              <br /> Build date: {buildTime} <br />
              {updateAvail && <span>(update available)</span>}
              {!updateAvail && <span>(up to date)</span>}
            </p>
            {updateAvail && (
              <div className={classes.action}>
                <Button size="small" variant="outlined" className={classes.margin} onClick={reload}>
                  Reload
                </Button>
              </div>
            )}
          </div>
          <div className={classes.footer}>
            <p>
              &copy; 2020 <a href="https://ptssolutions.com">ptssolutions.com</a>, All Rights
              Reserved
            </p>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

const mapStateToProps = (state) => {
  return {
    appInfo: state.appInfo,
  };
};

export default connect(mapStateToProps, { checkForUpdates })(AboutDialog);
