import SimpleSchema from 'simpl-schema';

const findIntersection = (arrayA, arrayB) => {
  let commonValues = arrayA.filter((val) => arrayB.includes(val));
  return commonValues;
};

SimpleSchema.setDefaultMessages({
  messages: {
    en: {
      'NIBRS-2019.2-113':
        'If UCR = 36A, then other UCRs cannot be in (11A, 11B, 11C, 11D, 36B). If UCR = 36B, then other UCRS cannot be in (11A, 11B, 11C, 11D, 36A).',
      'NIBRS-2019.2-114':
        'If UCR eq 120, then other UCRs cannot be in (23A, 23B, 23C, 23D, 23E, 23F, 23G, 23H, 23I, 240).',
      'NIBRS-2019.2-112':
        'If UCR = 11A or 11B or 11C, then other UCRs cannot be in (11D, 13A, 13B, 13C, 36A, 36B). If UCR = 11D, then other UCRS cannot be in (11A, 11B, 11C, 13B, 13C, 36A, 36B).',
      'NIBRS-2019.2-111':
        'If UCR = 13A, then other UCRs cannot be in (09A, 09B, 11A, 11B, 11C, 11D, 120, 13B, 13C). If UCR = 13B, then other UCRs cannot be in (09A, 09B, 11A, 11B, 11C, 11D, 120, 13A, 13C). If UCR = 13C, then other UCRs cannot be in (09A, 09B, 11A, 11B, 11C, 11D, 120, 13A, 13B).',
      'NIBRS-2019.2-35':
        'The Offense Factor Bias Motivation Code must be unique within the Offense.',
      'NIBRS-2019.2-44': 'The Criminal Activity Codes must be unique within the Offense.',
      'NIBRS-2019.2-27':
        'When the Offense Code = 09C (Justifiable Homicide), then only one Offense entity can be submitted.',
      'NIBRS-2019.2-32': 'The Offense Factor must be unique within the Offense.',
      'NIBRS-2019.2-50': 'The Weapon/Force Involved Codes must be unique within the Offense.',
      'NIBRS-2019.2-110':
        'If UCR = 09A, then Other UCRS cannot be in (09B, 13A, 13B, 13C). If UCR = 09B, then other UCRs cannot be in (09A, 13A, 13B, 13C). If UCR = 09C, then only one Offense segment.',
    },
  },
});

export const incidentAllOffensesSchema = new SimpleSchema({
  ucrCodes: {
    type: Array,
    optional: true,
    custom() {
      let ucrCodes = [...this.value];
      // NIBRS-2019.2-113: Victim Connected to Offense
      if (ucrCodes.includes('36A')) {
        let commonCodes = ucrCodes.filter((code) =>
          ['11A', '11B', '11C', '11D', '36B'].includes(code)
        );
        if (commonCodes?.length) {
          return 'NIBRS-2019.2-113';
        }
      }
      if (ucrCodes.includes('36B')) {
        let commonCodes = ucrCodes.filter((code) =>
          ['11A', '11B', '11C', '11D', '36A'].includes(code)
        );
        if (commonCodes?.length) {
          return 'NIBRS-2019.2-113';
        }
      }

      // NIBRS-2019.2-114: Victim Connected to Offense
      if (ucrCodes.includes('120')) {
        let commonCodes = findIntersection(ucrCodes, [
          '23A',
          '23B',
          '23C',
          '23D',
          '23E',
          '23F',
          '23G',
          '23H',
          '23I',
          '240',
        ]);
        if (commonCodes?.length) {
          return 'NIBRS-2019.2-114';
        }
      }
      // NIBRS-2019.2-112: Victim Connected to Offense
      if (findIntersection(ucrCodes, ['11A', '11B', '11C']).length) {
        if (findIntersection(ucrCodes, ['11D', '13A', '13B', '13C', '36A', '36B']).length) {
          return 'NIBRS-2019.2-112';
        }
      }
      if (ucrCodes.includes('11D')) {
        if (findIntersection(ucrCodes, ['11A', '11B', '11C', '13B', '13C', '36A', '36B']).length) {
          return 'NIBRS-2019.2-112';
        }
      }

      // NIBRS-2019.2-110: Victim Connected to Offense
      if (ucrCodes.includes('09A')) {
        if (findIntersection(ucrCodes, ['09B', '13A', '13B', '13C']).length) {
          return 'NIBRS-2019.2-110';
        }
      }
      if (ucrCodes.includes('09B')) {
        if (findIntersection(ucrCodes, ['09A', '13A', '13B', '13C']).length) {
          return 'NIBRS-2019.2-110';
        }
      }

      // NIBRS-2019.2-27: Offense Code or NIBRS-2019.2-110: Victim Connected to Offense
      if (ucrCodes.includes('09C')) {
        if (this.value.length > 1) {
          return 'NIBRS-2019.2-27';
        }
      }

      // NIBRS-2019.2-111: Victim Connected to Offense
      if (ucrCodes.includes('13A')) {
        if (
          findIntersection(ucrCodes, [
            '09A',
            '09B',
            '11A',
            '11B',
            '11C',
            '11D',
            '120',
            '13B',
            '13C',
          ]).length
        ) {
          return 'NIBRS-2019.2-111';
        }
      }
      if (ucrCodes.includes('13B')) {
        if (
          findIntersection(ucrCodes, [
            '09A',
            '09B',
            '11A',
            '11B',
            '11C',
            '11D',
            '120',
            '13A',
            '13C',
          ]).length
        ) {
          return 'NIBRS-2019.2-111';
        }
      }
      if (ucrCodes.includes('13C')) {
        if (
          findIntersection(ucrCodes, [
            '09A',
            '09B',
            '11A',
            '11B',
            '11C',
            '11D',
            '120',
            '13A',
            '13B',
          ]).length
        ) {
          return 'NIBRS-2019.2-111';
        }
      }
    },
  },
  'ucrCodes.$': {
    type: String,
    optional: true,
    blackbox: true,
    custom() {},
  },
  biasMotivationCodes: {
    type: Array,
    optional: true,
    custom() {
      const biasMotivationCodes = [];
      this.value.forEach((value) => {
        if (!biasMotivationCodes.includes(value)) {
          biasMotivationCodes.push(value);
        }
      });

      // NIBRS-2019.2-35: Offense Factor Bias Motivation
      if (biasMotivationCodes.length !== this.value.length) {
        return 'NIBRS-2019.2-35';
      }
    },
  },
  'biasMotivationCodes.$': {
    type: String,
    optional: true,
    blackbox: true,
    custom() {},
  },
  criminalActivityCodes: {
    type: Array,
    optional: true,
    custom() {
      const criminalActivityCodes = [];
      this.value.forEach((value) => {
        if (!criminalActivityCodes.includes(value)) {
          criminalActivityCodes.push(value);
        }
      });

      // NIBRS-2019.2-44: Criminal Activity/Gang Information
      if (criminalActivityCodes.length !== this.value.length) {
        return 'NIBRS-2019.2-44';
      }
    },
  },
  'criminalActivityCodes.$': {
    type: String,
    optional: true,
    blackbox: true,
    custom() {},
  },
  offenderSuspectedOfUsingCodes: {
    type: Array,
    optional: true,
    custom() {
      const offenderSuspectedOfUsingCodes = [];
      this.value.forEach((value) => {
        if (!offenderSuspectedOfUsingCodes.includes(value)) {
          offenderSuspectedOfUsingCodes.push(value);
        }
      });

      // NIBRS-2019.2-32: Offense Factor
      if (offenderSuspectedOfUsingCodes.length !== this.value.length) {
        return 'NIBRS-2019.2-32';
      }
    },
  },
  'offenderSuspectedOfUsingCodes.$': {
    type: String,
    optional: true,
    blackbox: true,
    custom() {},
  },
  forceCategoryCodes: {
    type: Array,
    optional: true,
    custom() {
      const forceCategoryCodes = [];
      this.value.forEach((value) => {
        if (!forceCategoryCodes.includes(value)) {
          forceCategoryCodes.push(value);
        }
      });
      // NIBRS-2019.2-50: Weapon/Force Involved
      if (forceCategoryCodes.length !== this.value.length) {
        return 'NIBRS-2019.2-50';
      }
    },
  },
  'forceCategoryCodes.$': {
    type: String,
    optional: true,
    blackbox: true,
    custom() {},
  },
});
