import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Unit from './Units/Unit';
import { sortObjArr } from 'utils/functions';
import PerfectScrollbar from 'react-perfect-scrollbar';

const useStyles = makeStyles(() => ({
  units: {
    width: '100%',
    minHeight: 41,
    maxHeight: '550px',
    overflowY: 'auto',
  },
}));

function EventUnitsTab(props) {
  const classes = useStyles();
  const { colorPalette, UnitStatuses, ptsEventID, sortOption, agencyFilter } = props;
  const [units, setUnits] = useState([]);

  useEffect(() => {
    if (!units) return;
    const newUnits = props.units.map((unit) => {
      return {
        ...unit,
        UnitStatuses,
        LastActionOccurred: getLastActionOccurred(unit),
      };
    });
    setUnits(sortObjArr(newUnits, 'LastActionOccurred', sortOption));
    // eslint-disable-next-line
  }, [props.units, sortOption, agencyFilter]);

  const getLastActionOccurred = (unit) => {
    let history = [];
    if (UnitStatuses) {
      UnitStatuses.forEach((status) => {
        if (status.Unit === unit.Unit) {
          history.push(status);
        }
      });
      history = sortObjArr(history, 'Occurred', 'ASC');
    }
    return history.length ? history[0].Occurred : unit.Occured;
  };

  const showUnitStatusEdit = (unit) => {
    props.unitStatusDialog({ title: 'New Status', unit, ptsEventID });
  };

  return (
    <div className={classes.units}>
      <PerfectScrollbar style={{ maxHeight: window.innerHeight - 500 + 'px' }}>
        {units.map((unit) => (
          <div key={unit.ptsUnitID}>
            <Unit
              unit={unit}
              colorPalette={colorPalette}
              ptsEventID={ptsEventID}
              showUnitStatusEdit={() => showUnitStatusEdit(unit)}
              parent="event"
            />
          </div>
        ))}
      </PerfectScrollbar>
    </div>
  );
}

export default EventUnitsTab;
