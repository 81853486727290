import React, { useEffect, useState } from 'react';
import {
  Card,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import ArrowDropDownCircleIcon from '@material-ui/icons/ArrowDropDownCircle';
import PlusCircleButton from 'components/RMSButtons/RMSAddButton/plusCircleButton';
import PlaceLookup from 'components/RMSPlaceLookup';
import { alpha } from '@mui/material';

import { connect, useSelector } from 'react-redux';
import RMSDictionary from 'components/RMSAutoComplete/RMSDictonary';

import { Description, Gavel, History, Notes } from '@material-ui/icons';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Row, gridStyle } from 'utils/formStyles';

const PRIMARY_COLOR = '#1977D4';
const useStyles = makeStyles((theme) => ({
  cardView: {
    paddingTop: 0,
    backgroundColor: theme.accordion.background,
    color: 'inherit',
    width: '100%',
    borderRadius: '5px',
  },
  spanHeader: {
    color: theme.palette.colors.label,
    fontWeight: 'bold',
    backgroundColor: theme.palette.customBg,
    width: '100%',
    padding: '3px',
    paddingLeft: '15px',
    borderRadius: '5px',
    // paddingTop: '5px',
  },
  svg_icons: {
    paddingLeft: '5x',
    color: PRIMARY_COLOR,
  },
  svg_icons_rotated: {
    paddingLeft: '5x',
    transform: 'rotate(180deg)',
    color: PRIMARY_COLOR,
  },
  list_div: {
    border: `2px solid #B5B5B5`,
    borderColor: theme.palette.type === 'dark' ? '#7C7C7C' : '#B5B5B5',
    borderRadius: '10px',
    width: '100%',
    marginLeft: '28px',
  },
  listHeader: {
    background: alpha('#1976d2', 0.2),
    color: theme.palette.colorFont,
    borderRadius: '5px',
    paddingTop: '2px',
    paddingRight: '8px',
    height: '30px',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  parent_div: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  default_div: {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  list_root: {
    width: '100%',
    paddingTop: '2px',
    // paddingLeft: '30px',
    overflowY: 'scroll',
  },
  listItem_root: {
    padding: '0px',
  },
  listItem: {
    borderTop: '.5px solid ',
    borderColor: theme.palette.type === 'light' ? '#d0d0d0' : '#616161',
    '&:hover': {
      backgroundColor: alpha('#1976d2', 0.2),
    },
  },
  fabStyle: {
    backgroundColor: theme.palette.colors.chipBg,
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  item2: gridStyle('95%', 800),
}));

function AffidavitsText(props) {
  const { warrant, onChange } = props;
  const classes = useStyles();

  const [cardExpanded, setCardExpanded] = useState(false);

  const renderAffidavitText = () => {
    return (
      <TextField
        label="Affidavit Text"
        variant="outlined"
        onChange={(e) => {
          onChange('AffidavitText', e.target.value);
        }}
        value={warrant.AffidavitText}
        className={classes.item2}
        size="small"
        multiline
        rows={4}
      />
    );
  };

  return (
    <Card className={classes.cardView}>
      <Grid container spacing={1}>
        <Grid item container style={{ marginLeft: '10px', marginBottom: '3px' }} xs={12}>
          <Grid
            item
            xs={12}
            style={{
              marginTop: '6px',
              display: 'flex',
              alignItems: 'center',
              marginBottom: '10px',
            }}>
            <span style={{ marginRight: '5px' }}></span>
            <Notes style={{ color: PRIMARY_COLOR }} />
            <Typography variant="span" className={classes.spanHeader}>
              Affidavits Text
            </Typography>
            <span
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                paddingRight: '20px',
                paddingBottom: '3px',
                paddingTop: '3px',
              }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <ArrowDropDownCircleIcon
                  onClick={() => setCardExpanded(!cardExpanded)}
                  className={cardExpanded ? classes.svg_icons_rotated : classes.svg_icons}
                  style={{
                    marginLeft: '10px',
                  }}
                />
              </div>
            </span>
          </Grid>

          {cardExpanded && (
            <Row>
              <Grid
                container
                item
                xs={12}
                className={classes.list_div}
                spacing={1}
                style={{ paddingTop: '10px', paddingRight: '10px' }}>
                {renderAffidavitText()}
              </Grid>
            </Row>
          )}
        </Grid>
      </Grid>
    </Card>
  );
}

export default connect(null, {})(AffidavitsText);
