import React from 'react';

function BusinessDocumentationIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" {...props}>
      <g transform="translate(0.000000,50.000000) scale(0.100000,-0.100000)" stroke="none">
        <path d="M106 464 c-31 -30 -9 -84 34 -84 24 0 50 26 50 50 0 24 -26 50 -50 50 -10 0 -26 -7 -34 -16z" />
        <path d="M198 468 c16 -16 15 -49 -3 -75 -14 -20 -14 -23 5 -33 33 -17 50 -64 50 -133 0 -56 -3 -65 -20 -70 -17 -4 -20 -14 -20 -71 l0 -66 120 0 120 0 0 168 0 168 -63 62 -63 62 -69 0 c-54 0 -66 -3 -57 -12z m177 -68 l39 -40 -42 0 -42 0 0 40 c0 22 1 40 3 40 2 0 21 -18 42 -40z" />
        <path d="M79 331 c-26 -26 -29 -36 -29 -90 0 -50 3 -62 20 -71 17 -9 20 -21 20 -80 l0 -70 50 0 50 0 0 70 c0 59 3 71 20 80 16 9 20 21 20 68 0 61 -15 93 -52 113 -16 9 -23 8 -30 -2 -7 -11 -11 -11 -15 -1 -7 19 -22 14 -54 -17z m71 -56 c0 -25 -4 -45 -10 -45 -5 0 -10 20 -10 45 0 25 5 45 10 45 6 0 10 -20 10 -45z" />
      </g>
    </svg>
  );
}

export default BusinessDocumentationIcon;
