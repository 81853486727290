import React, { useEffect, useState } from 'react';
import { Card, Grid, List, ListItem, Typography, makeStyles } from '@material-ui/core';

import ArrowDropDownCircleIcon from '@material-ui/icons/ArrowDropDownCircle';

import { alpha } from '@mui/material';
import { getService } from 'reducers/service';
import { connect } from 'react-redux';
import { showEditWarrantParty } from 'reducers/PersonDialogsReducer';
import { hideSpinner, showSpinner } from 'reducers/UiReducer';
import { notify } from 'reducers/NotifierReducer';
import { handleError } from 'reducers/ErrorReducer';
import { History } from '@material-ui/icons';
import { displayDateTime } from 'reducers/TimeReducer';

const PRIMARY_COLOR = '#1977D4';
const useStyles = makeStyles((theme) => ({
  cardView: {
    paddingTop: 0,
    backgroundColor: theme.accordion.background,
    color: 'inherit',
    width: '100%',
    borderRadius: '5px',
  },
  spanHeader: {
    color: theme.palette.colors.label,
    fontWeight: 'bold',
    backgroundColor: theme.palette.customBg,
    width: '100%',
    padding: '3px',
    paddingLeft: '15px',
    borderRadius: '5px',
    // paddingTop: '5px',
  },
  svg_icons: {
    paddingLeft: '5x',
    color: PRIMARY_COLOR,
  },
  svg_icons_rotated: {
    paddingLeft: '5x',
    transform: 'rotate(180deg)',
    color: PRIMARY_COLOR,
  },
  list_div: {
    border: `2px solid #B5B5B5`,
    borderColor: theme.palette.type === 'dark' ? '#7C7C7C' : '#B5B5B5',
    borderRadius: '10px',
    width: '100%',
    marginLeft: '28px',
  },
  listHeader: {
    background: alpha('#1976d2', 0.2),
    color: theme.palette.colorFont,
    borderRadius: '5px',
    paddingTop: '2px',
    paddingRight: '8px',
    height: '30px',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  parent_div: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  default_div: {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  list_root: {
    width: '100%',
    paddingTop: '2px',
    // paddingLeft: '30px',
    overflowY: 'scroll',
  },
  listItem_root: {
    padding: '0px',
  },
  listItem: {
    borderTop: '.5px solid ',
    borderColor: theme.palette.type === 'light' ? '#d0d0d0' : '#616161',
    '&:hover': {
      backgroundColor: alpha('#1976d2', 0.2),
    },
  },
  fabStyle: {
    backgroundColor: theme.palette.colors.chipBg,
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
}));

function WarrantHistory(props) {
  const { ptsWarrantID } = props;
  const service = getService('rms-warrant');
  const classes = useStyles();
  const [cardExpanded, setCardExpanded] = useState(false);

  const [rows, setRows] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [cardHeight, setCardHeight] = useState(0);
  const [selected, setSelected] = useState({});

  const processData = (data) => {
    return data.map((row, idx) => {
      return {
        ...row,
        id: idx + 1,
      };
    });
  };
  useEffect(() => {
    service
      .find({ query: { type: 'warrant_history', data: { ptsWarrantID: props.ptsWarrantID } } })
      .then((res) => {
        setRows(processData(res));

        setLoaded(true);
      });
  }, []);

  const getData = async () => {
    const res = await service.find({
      query: { type: 'warrant_party', data: { ptsWarrantID: ptsWarrantID } },
    });
    setRows(processData(res));
    setLoaded(true);
  };

  useEffect(() => {
    if (rows.length > 0) {
      if (cardExpanded) {
        setCardHeight(45 * rows.length + 8 + 'px');
      } else {
        setCardHeight('90px');
      }
    }
  }, [cardExpanded, rows]);

  const generateHeaderRow = () => {
    return (
      <Grid container item lg={12} xs={12} className={classes.listHeader}>
        <Grid container item xs={12}>
          <Grid item xs={2}>
            Archived
          </Grid>
          <Grid item xs={1}>
            Level
          </Grid>
          <Grid item xs={1}>
            Disposition
          </Grid>
          <Grid item xs={2}>
            Created
          </Grid>
          <Grid item xs={2}>
            Created By
          </Grid>
          <Grid item xs={2}>
            Updated
          </Grid>
          <Grid item xs={2}>
            Updated By
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const generateListItem = (it) => {
    return (
      <Grid container item xs={12} className="items-center">
        <Grid container item xs={2} className={classes.parent_div}>
          <div className={classes.default_div}>{displayDateTime(it.Archived)}</div>
        </Grid>
        <Grid container item xs={1} className={classes.parent_div}>
          <div className={classes.default_div}>{it.Level || ''}</div>
        </Grid>
        <Grid container item xs={1} className={classes.parent_div}>
          <div className={classes.default_div}>{it.Disposition || ''}</div>
        </Grid>
        <Grid container item xs={2} className={classes.parent_div}>
          <div className={classes.default_div}>{displayDateTime(it.Created || '')}</div>
        </Grid>
        <Grid container item xs={2} className={classes.parent_div}>
          <div className={classes.default_div}>{it.CreatedBy || ''}</div>
        </Grid>
        <Grid container item xs={2} className={classes.parent_div}>
          <div className={classes.default_div}>{displayDateTime(it.Updated || '')}</div>
        </Grid>
        <Grid container item xs={2} className={classes.parent_div}>
          <div className={classes.default_div}>{it.UpdatedBy || ''}</div>
        </Grid>
      </Grid>
    );
  };

  return (
    <Card className={classes.cardView}>
      <Grid container spacing={1}>
        <Grid item container style={{ marginLeft: '10px', marginBottom: '3px' }} xs={12}>
          <Grid item xs={12} style={{ marginTop: '6px', display: 'flex', alignItems: 'center' }}>
            <span style={{ marginRight: '5px' }}></span>
            <History style={{ color: PRIMARY_COLOR }} />
            <Typography variant="span" className={classes.spanHeader}>
              History {rows.length > 0 ? `(${rows.length})` : ''}
            </Typography>
            <span
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                paddingRight: '20px',
                paddingBottom: '3px',
                paddingTop: '3px',
              }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <ArrowDropDownCircleIcon
                  onClick={() => setCardExpanded(!cardExpanded)}
                  className={cardExpanded ? classes.svg_icons_rotated : classes.svg_icons}
                  style={{
                    marginLeft: '10px',
                  }}
                />
              </div>
            </span>
          </Grid>
          {rows.length > 0 && (
            <Grid container item xs={12} className={classes.list_div}>
              {generateHeaderRow()}
              <List className={classes.list_root} style={{ height: cardHeight }}>
                {rows.map((it, index) => {
                  return (
                    <ListItem className={classes.listItem_root}>
                      <Grid
                        item
                        container
                        xs={12}
                        style={{ borderWidth: index === 0 ? '0' : '', height: '40px' }}
                        className={classes.listItem}
                        onMouseEnter={(event) => setSelected(it)}
                        onMouseLeave={() => setSelected({})}>
                        {generateListItem(it)}
                      </Grid>
                    </ListItem>
                  );
                })}
              </List>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Card>
  );
}

export default connect(null, {
  showEditWarrantParty,
  showSpinner,
  hideSpinner,
  notify,
  handleError,
})(WarrantHistory);
