import React, { useState, useEffect, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import { closeAddressDialog, closeAddressSOP, showAddressSOP } from 'reducers/PersonDialogsReducer';
import { getAddressSops, saveAddressSOP, deleteAddressSops } from 'reducers/AddressReducer';
import { showSpinner, hideSpinner } from 'reducers/UiReducer';
import { showDisplayDataWindow } from 'reducers/PersonDialogsReducer';
import { handleError } from 'reducers/ErrorReducer';
import PrintsSearch from 'components/RMSSearch/components/PrintsSearch';
import BackDatedRecordHistory from 'components/BackDatedRecordHistory';

import { XGrid } from '@material-ui/x-grid';
import Tooltip from 'components/Tooltip';
import Fab from '@material-ui/core/Fab';
import InfoIcon from '@material-ui/icons/Info';
import { Button, Box } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';
import Dialog from 'components/RMSDialog';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import SOPFilter from './SOPFilter';
import DeleteIcon from '@material-ui/icons/Delete';

import { displayDateTime } from 'reducers/TimeReducer';

const bool = (params) => (params.value ? <CheckIcon /> : <span></span>);

const columns = [
  { field: 'SOPID', headerName: 'SOPID', width: 150 },
  { field: 'DispatcherMessage', headerName: 'Dispatcher Message', width: 130 },
  {
    field: 'Created',
    headerName: 'Created',
    width: 200,
    valueFormatter: (params) => displayDateTime(params.value),
  },
  { field: 'CreatedBy', headerName: 'Created By', width: 150 },
  {
    field: 'Updated',
    headerName: 'Updated',
    width: 200,
    valueFormatter: (params) => displayDateTime(params.value),
  },
  { field: 'UpdatedBy', headerName: 'Updated By', width: 150 },
  {
    field: 'IsDeleted',
    headerName: 'Is Deleted',
    width: 120,
    format: 'bool',
    renderCell: bool,
  },
  { field: 'DeletedBy', headerName: 'Deleted By', width: 120 },
  { field: 'DeletedIP', headerName: 'Deleted IP', width: 120 },
];

const useStyles = makeStyles((theme) => ({
  wrap: {
    padding: theme.spacing(3),
  },
  gridWrap: {
    width: '100%',
    boxSizing: 'border-box',
    height: 600,
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(2),
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
  closeButton: { color: theme.button.color },
}));

function AddressSOPs(props) {
  const { ptsAddressID, addAddressSOP } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [selectedSOP, setSelectedSOP] = useState(null);
  const [selection, setSelection] = useState(null);
  const [hiddenColumns, setHiddenColumns] = useState([]);

  const [recordHistoryData, setRecordHistoryData] = useState({
    shouldVisible: true,
    isDisabled: true,
    createUpdateInfo: {},
  });
  const [showRecordHistory, setShowRecordHistory] = useState(false);

  const mountedRef = useRef(true);

  useEffect(() => {
    getSOPs();
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    };
  }, []);

  const getSOPs = async () => {
    try {
      const data = await getAddressSops(ptsAddressID);
      if (!mountedRef.current) return;
      setData(processData(data));
      setLoaded(true);
    } catch (err) {
      props.handleError(err, 'Error receiving event location data.');
    }
  };

  const processData = (data) => {
    return data.map((row) => {
      if (row.IsDeleted === false) return { ...row, id: row.ROWGUID };
    });
  };

  const viewDetails = () => {
    const item = data.find((row) => row.id === selection);
    if (!item) return;
    props.showDisplayDataWindow({ data: filterData(item), title: item.SOPID });
  };

  const filterData = (rawData) => {
    const {
      SOPID,
      DispatcherMessage,
      AlertMessage,
      IsApproved,
      Approved,
      Flags,
      IsAlertMessageUsed,
      IsPagingMessageUsed,
      PagingMessage,
      ShowToast,
      CreatedBy,
      Updated,
      UpdatedBy,
      IsDeleted,
      DeletedBy,
      DeletedIP,
    } = rawData;
    return {
      SOPID,
      DispatcherMessage,
      AlertMessage,
      IsApproved,
      Approved,
      Flags,
      IsAlertMessageUsed,
      IsPagingMessageUsed,
      PagingMessage,
      ShowToast,
      CreatedBy,
      Updated,
      UpdatedBy,
      IsDeleted,
      DeletedBy,
      DeletedIP,
    };
  };

  const add = () => {
    setRecordHistoryData({
      shouldVisible: true,
      isDisabled: true,
      createUpdateInfo: {},
    });

    dispatch(showAddressSOP({ ptsAddressId: ptsAddressID }));
  };

  const close = () => {
    dispatch(closeAddressSOP());
  };

  const save = async () => {
    dispatch(showSpinner());
    if (addAddressSOP.ptsSOPID) {
      const sopExists = data.find((row) => row.ptsSOPID === selectedSOP);
      if (!sopExists) {
        await deleteAddressSops({
          ptsParentID: ptsAddressID,
          ParentType: 'Address',
          ptsSOPID: addAddressSOP.ptsSOPID,
        });
      }
    }
    await saveAddressSOP({
      ptsParentID: ptsAddressID,
      ParentType: 'Address',
      ptsSOPID: selectedSOP,
    })
      .then(() => {
        close();
        getSOPs();
        dispatch(hideSpinner());
      })
      .catch(() => {
        close();
        getSOPs();
        dispatch(hideSpinner());
      });
  };

  const deleteSOP = async () => {
    const item = data.find((row) => row.id === selection);

    const updatedData = data.filter((row) => row.id !== selection);
    setData(updatedData);

    if (item) {
      dispatch(showSpinner());
      await deleteAddressSops({
        ptsParentID: ptsAddressID,
        ParentType: 'Address',
        ptsSOPID: item.ptsSOPID,
      })
        .then(() => {
          getSOPs();
          dispatch(hideSpinner());
        })
        .catch((err) => {
          console.log(err);
          getSOPs();
          dispatch(hideSpinner());
        });
    }
  };

  const handleCloseRecordHistoryDialog = () => setShowRecordHistory(false);

  const edit = () => {
    const item = data.find((row) => row.id === selection);

    setRecordHistoryData({
      shouldVisible: true,
      isDisabled: !item.Created,
      createUpdateInfo: {
        created: item.Created,
        createdBy: item.CreatedBy,
        updated: item.Updated,
        updatedBy: item.UpdatedBy,
      },
    });

    dispatch(showAddressSOP(item));
  };

  const formContent = () => {
    return (
      <SOPFilter
        selectSOP={setSelectedSOP}
        sopValue={addAddressSOP?.ptsSOPID ? addAddressSOP : null}
      />
    );
  };

  const renderActions = () => {
    return (
      <>
        {recordHistoryData?.shouldVisible && (
          <Box className="">
            <BackDatedRecordHistory
              showRecordHistory={showRecordHistory}
              setShowRecordHistory={setShowRecordHistory}
              disabled={recordHistoryData?.isDisabled}
              handleCloseRecordHistoryDialog={handleCloseRecordHistoryDialog}
              createUpdateInfo={recordHistoryData?.createUpdateInfo}></BackDatedRecordHistory>
          </Box>
        )}
        <Button color="primary" variant="contained" autoFocus onClick={save}>
          <SaveIcon /> Save
        </Button>
        <Button color="primary" onClick={close} className={classes.closeButton}>
          <CloseIcon /> Close
        </Button>
      </>
    );
  };

  return (
    <div className={classes.wrap}>
      <div className={classes.actions}>
        <PrintsSearch title="SOP Records" data={data} cols={columns} hiddenCols={hiddenColumns} />
        <Tooltip title="Edit Attachment">
          <Fab size="small" color="secondary" onClick={add} disabled={!props.canSave}>
            <AddIcon fontSize="small" />
          </Fab>
        </Tooltip>
        {selection !== null && (
          <>
            <Tooltip title="Edit Feature">
              <Fab size="small" color="secondary" onClick={edit}>
                <EditIcon />
              </Fab>
            </Tooltip>
            <Tooltip title="Delete SOP">
              <Fab size="small" color="secondary" onClick={deleteSOP}>
                <DeleteIcon />
              </Fab>
            </Tooltip>

            <Tooltip title="View details">
              <Fab size="small" color="secondary" onClick={viewDetails}>
                <InfoIcon />
              </Fab>
            </Tooltip>
          </>
        )}
      </div>

      {addAddressSOP && (
        <Dialog toolbar onClose={close} title="Add SOP" actions={renderActions()}>
          {formContent()}
        </Dialog>
      )}

      <div className={classes.gridWrap}>
        <XGrid
          columns={columns}
          rows={data}
          loading={!loaded}
          rowHeight={38}
          disableMultipleSelection={true}
          showToolbar
          disableColumnFilter
          onSelectionModelChange={(newSelection) => {
            setSelection(newSelection.selectionModel[0]);
          }}
          onColumnVisibilityChange={(col) => setHiddenColumns([...hiddenColumns, col.field])}
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    addAddressSOP: state.dialogs.addAddressSOP,
  };
};

export default connect(mapStateToProps, {
  closeAddressDialog,
  handleError,
  showDisplayDataWindow,
})(AddressSOPs);
