import React from 'react';

function PaperIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" {...props}>
      <g transform="translate(0.000000,50.000000) scale(0.100000,-0.100000)" stroke="none">
        <path d="M100 330 l0 -169 147 -3 c136 -3 148 -4 151 -22 3 -12 -5 -26 -19 -37 -20 -15 -22 -21 -14 -46 14 -37 56 -44 79 -11 13 19 16 57 16 240 l0 218 -180 0 -180 0 0 -170z" />
        <path d="M56 118 c-20 -29 -20 -67 0 -96 14 -21 21 -22 165 -22 l150 0 -15 22 c-22 32 -20 75 4 90 11 7 20 16 20 20 0 5 -69 8 -154 8 -149 0 -155 -1 -170 -22z" />
      </g>
    </svg>
  );
}

export default PaperIcon;
