import React, { useState, useRef, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';

import Fab from '@material-ui/core/Fab';
import { makeStyles } from '@material-ui/core/styles';

import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import Tooltip from 'components/Tooltip';
import RMSXGrid from 'components/RMSXGrid';
import PrintsSearch2 from 'components/RMSSearch/components/PrintsSearch2';
import QuickFilterSearch from 'components/RMSSearch/components/QuickFilterSearch';

import { getService } from 'reducers/service';
import { notify } from 'reducers/NotifierReducer';
import { handleError } from 'reducers/ErrorReducer';
import { showSpinner, hideSpinner } from 'reducers/UiReducer';
import { getPeopleEmployments } from 'reducers/SearchReducer';
import { displayDateTime } from 'reducers/TimeReducer';
import { getFullPermissions } from 'reducers/PermissionsReducer';
import { showPeopleEmployment } from 'reducers/PersonDialogsReducer';

const useStyles = makeStyles((theme) => ({
  wrap: {
    padding: theme.spacing(3),
  },
  gridWrap: {
    width: '100%',
    boxSizing: 'border-box',
    height: 600,
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(2),
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
  fileInput: {
    display: 'none',
  },
  filterWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
}));

const defaultColumns = [
  'PlaceName',
  'PlaceType',
  'DepartmentName',
  'Position',
  'Shift',
  'Occupation',
  'Pay',
  'Rate',
  'Rank',
  'Status',
  {
    field: 'Hired',
    valueFormatter: (params) => displayDateTime(params.value),
  },
  {
    field: 'Terminated',
    valueFormatter: (params) => displayDateTime(params.value),
  },
  'TerminationReason',
  {
    field: 'Created',
    headerName: 'Created',
    width: 200,
    valueFormatter: (params) => displayDateTime(params.value),
    hide: false,
  },
  { field: 'CreatedBy', headerName: 'Created By', width: 150, hide: false },
  {
    field: 'Updated',
    headerName: 'Updated',
    width: 200,
    valueFormatter: (params) => displayDateTime(params.value),
    hide: false,
  },
  { field: 'UpdatedBy', headerName: 'Updated By', width: 150, hide: false },
];
const Employment = (props) => {
  const classes = useStyles();
  const { ptsPersonID, dataUpdate } = props;
  const [rows, setRows] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [filteredRows, setFilteredRows] = useState([]);
  const [selection, setSelection] = useState(null);
  const [columns, setColumns] = useState(defaultColumns);
  const [isDeleted, setIsDeleted] = useState(false);
  const dispatch = useDispatch();
  const mountedRef = useRef(true);
  const perms = getFullPermissions('globals', 'People', 'any');
  const canEditPerson = perms.Edit;
  useEffect(() => {
    ptsPersonID && ptsPersonID !== true && getData();
    // eslint-disable-next-line
  }, [ptsPersonID, isDeleted]);

  useEffect(() => {
    if (!dataUpdate || dataUpdate.type !== 'employment') return;
    getData();
    // eslint-disable-next-line
  }, [dataUpdate]);

  const getData = async () => {
    try {
      const data = await getPeopleEmployments(ptsPersonID);
      if (!mountedRef.current) return;
      setRows(processData(data.data));
      setLoaded(true);
    } catch (err) {
      props.handleError(err);
    }
  };

  const processData = (data) => {
    return data.map((row, idx) => {
      const Place = row.Place && row.Place.length ? row.Place[0] : null;
      const PlaceName = Place ? Place.PlaceName : null;
      const PlaceType = Place ? Place.PlaceType : null;
      return {
        ...row,
        PlaceName,
        PlaceType,
        id: idx,
      };
    });
  };

  const addContact = () => {
    dispatch(showPeopleEmployment({ ptsPersonID }));
  };

  const edit = () => {
    const item = rows.find((row) => row.id === selection);
    dispatch(showPeopleEmployment({ ...item }));
  };

  const deleteEmployment = async () => {
    const item = rows.find((row) => row.id === selection);
    try {
      props.showSpinner();
      const service = getService('cad-search-people-employment');
      await service.remove({ ptsPersonID: item.ptsPersonID, ptsPlaceID: item.ptsPlaceID });
      dispatch(notify('Contact Deleted', 'warning'));
      setIsDeleted(!isDeleted);
    } catch (err) {
      props.handleError(err);
    } finally {
      props.hideSpinner();
    }
  };

  const renderActions = () => (
    <div className={classes.actions}>
      <Tooltip title="Print">
        <PrintsSearch2 title="Employments" data={filteredRows} cols={columns} />
      </Tooltip>
      <Tooltip title="Add Employment">
        <Fab size="small" color="secondary" disabled={!canEditPerson} onClick={addContact}>
          <AddIcon />
        </Fab>
      </Tooltip>

      {selection !== null && (
        <>
          <Tooltip title="Edit Employment">
            <Fab size="small" color="secondary" disabled={!canEditPerson} onClick={edit}>
              <EditIcon />
            </Fab>
          </Tooltip>
          {/* <Tooltip title="View details">
      <Fab size="small" color="secondary" disabled={!canEditPerson} onClick={viewDetails}>
        <InfoIcon />
      </Fab>
    </Tooltip> */}

          <Tooltip title="Delete Employment" disabled={!canEditPerson} onClick={deleteEmployment}>
            <Fab size="small" color="secondary">
              <DeleteIcon />
            </Fab>
          </Tooltip>
        </>
      )}
    </div>
  );

  return (
    <div className={classes.wrap}>
      <div className={classes.filterWrap}>
        <div className={classes.filterWrap}>
          <QuickFilterSearch
            rows={rows}
            columns={columns}
            setFilteredRows={setFilteredRows}
            style={{ marginTop: 0, paddingBottom: 14 }}
          />
          {renderActions()}
        </div>
      </div>
      <RMSXGrid
        name="PeopleEmployment"
        columns={columns}
        setColumns={setColumns}
        rows={filteredRows}
        loading={!loaded}
        setSelection={setSelection}
      />
    </div>
  );
};
const mapStateToProps = (state) => ({
  dataUpdate: state.dataUpdate,
});
export default connect(mapStateToProps, { handleError, showSpinner, hideSpinner })(Employment);
