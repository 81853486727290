export const formatCodeDispositions = (values, AgencyID) => ({
  AgencyID,
  Code: values.Code,
  Description: values.Description,
});

export const formatCommonCodes = (values) => ({
  AgencyID: values.AgencyID,
  Code: values.Code,
  Description: values.Description4,
});

export const formatCodeVehicleMakeModels = (values) => ({
  MakeCode: values.MakeCode,
  ModelCode: values.ModelCode,
  MakeDescription: values.MakeDescription,
  ModelDescription: values.ModelDescription,
});

export const formatCodeCities = (values) => ({
  CityDescription: values.CityDescription,
  Category: values.Category || '',
  State: values.State || '',
  IsDefault: values.IsDefault || false,
  IsActive: values.IsActive || true,
  IsDeleted: values.IsDeleted || false,
});
