import store from '../../config/configureStore';
import { getService } from 'reducers/service';

export const addPersonCredential = async (data) => {
  const client = store.store.getState().websocket.websocket;

  if (!client) return;
  try {
    const service = client.service('record-person-credentials');
    service.timeout = 20000;
    const result = await service.create(data);
    console.log('Person Credential Save', result);
    return result;
  } catch (e) {
    console.log(e);
  }
};

export const updatePersonCredential = async (data) => {
  const client = store.store.getState().websocket.websocket;

  if (!client) return;
  try {
    const service = client.service('record-person-credentials');
    service.timeout = 20000;
    const result = await service.patch(data.ptsCredentialID, data);
    console.log('Person Credential update', result);
    return result;
  } catch (e) {
    console.log(e);
  }
};

export const addPersonContactMethod = async (data) => {
  const client = store.store.getState().websocket.websocket;

  if (!client) return;
  try {
    const service = client.service('record-person-contact');
    service.timeout = 20000;
    const result = await service.create(data);
    console.log('Person Contact Save', result);
    return result;
  } catch (e) {
    console.log(e);
  }
};

export const updatePersonContactMethod = async (data) => {
  const client = store.store.getState().websocket.websocket;

  if (!client) return;
  try {
    const service = client.service('record-person-contact');
    service.timeout = 20000;
    const result = await service.patch(data.ptsInfoID, data);
    console.log('Person Contact update', result);
    return result;
  } catch (e) {
    console.log(e);
  }
};

export const checkExistingContact = async (data) => {
  const client = store.store.getState().websocket.websocket;

  if (!client) return;
  try {
    const service = client.service('record-person-contact');
    service.timeout = 20000;
    const result = await service.find({
      query: {
        PartyType: data?.PartyType || '',
        ptsParentID: data.ptsParentID,
        ContactMethod: data?.ContactMethod || '',
        MethodType: data?.MethodType || '',
        IsDeleted: false,
      },
    });
    return result.data;
  } catch (e) {
    console.log(e);
  }
};

export const addPersonDNASample = async (data) => {
  const client = store.store.getState().websocket.websocket;

  if (!client) return;
  try {
    const service = client.service('record-person-dnasample');
    service.timeout = 20000;
    const result = await service.create(data);
    console.log('Person DNA Save', result);
    return result;
  } catch (e) {
    console.log(e);
  }
};

export const updatePersonDNASample = async (data) => {
  const client = store.store.getState().websocket.websocket;

  if (!client) return;
  try {
    const service = client.service('record-person-dnasample');
    service.timeout = 20000;
    const result = await service.patch(data.ptsDNASampleID, data);
    console.log('Person DNA update', result);
    return result;
  } catch (e) {
    console.log(e);
  }
};

export const upsertPersonEmployment = async (data) => {
  const client = store.store.getState().websocket.websocket;

  if (!client || !data.ptsPlaceID) return;
  try {
    const service = client.service('record-person-employment');
    service.timeout = 20000;

    const exist = await service.find({
      query: {
        ptsPersonID: data.ptsPersonID,
        ptsPlaceID: data.ptsPlaceID,
      },
    });

    let result;
    if (exist.total > 0) {
      result = await service.update(
        {
          ptsPersonID: data.ptsPersonID,
          ptsPlaceID: data.ptsPlaceID,
          IsDeleted: false,
        },
        data
      );
    } else {
      result = await service.create(data);
    }

    console.log('Person EMP update', result);
    return result;
  } catch (e) {
    console.log(e);
  }
};

export const upsertPersonResource = async (data) => {
  const client = store.store.getState().websocket.websocket;

  if (!client) return;
  try {
    const service = client.service('record-person-cadresources');
    service.timeout = 20000;

    let result;

    if (data.ptsResourceID) {
      result = await service.patch(data.ptsResourceID, data);
    } else {
      result = await service.create(data);
    }

    return result;
  } catch (e) {
    console.log(e);
  }
};

export const addPersonFeature = async (data) => {
  const client = store.store.getState().websocket.websocket;

  if (!client) return;
  try {
    const service = client.service('record-person-feature');
    service.timeout = 20000;
    const result = await service.create(data);
    console.log('Person Feature Save', result);
    return result;
  } catch (e) {
    console.log(e);
  }
};

export const updatePersonFeature = async (data) => {
  const client = store.store.getState().websocket.websocket;

  if (!client) return;
  try {
    const service = client.service('record-person-feature');
    service.timeout = 20000;
    const result = await service.patch(data.ptsFeatureID, data);
    console.log('Person Feature update', result);
    return result;
  } catch (e) {
    console.log(e);
  }
};

export const addPersonVehicleRegistration = async (data) => {
  const client = store.store.getState().websocket.websocket;

  if (!client) return;
  try {
    const service = client.service('record-person-vehicle-registration');
    service.timeout = 20000;
    const result = await service.create(data);
    console.log('Person vehicle  Save', result);
    return result;
  } catch (e) {
    console.log(e);
  }
};

export const updatePersonVehicleRegistration = async (data) => {
  const client = store.store.getState().websocket.websocket;

  if (!client) return;
  try {
    const service = client.service('record-person-vehicle-registration');
    service.timeout = 20000;
    const result = await service.patch(data.ptsRegistrationID, data);
    console.log('Person Feature update', result);
    return result;
  } catch (e) {
    console.log(e);
  }
};

export const addPersonPhoto = async (data) => {
  const client = store.store.getState().websocket.websocket;

  if (!client) return;
  try {
    const service = client.service('record-person-photo');
    service.timeout = 20000;
    const result = await service.create(data);
    console.log('Person Photo Save', result);
    return result;
  } catch (e) {
    console.log(e);
  }
};

export const updatePersonPhoto = async (data) => {
  const client = store.store.getState().websocket.websocket;

  if (!client) return;
  try {
    const service = client.service('record-person-photo');
    service.timeout = 20000;
    const result = await service.patch(data.ptsImageID, data);
    console.log('Person Photo update', result);
    return result;
  } catch (e) {
    console.log(e);
  }
};

export const addPersonName = async (data) => {
  const client = store.store.getState().websocket.websocket;

  if (!client) return;
  try {
    const service = client.service('record-person-name');
    service.timeout = 20000;
    const result = await service.create(data);
    console.log('Person Name Save', result);
    return result;
  } catch (e) {
    console.log(e);
  }
};

export const updatePersonName = async (data) => {
  const client = store.store.getState().websocket.websocket;

  if (!client) return;
  try {
    const service = client.service('record-person-name');
    service.timeout = 20000;
    const result = await service.patch(data.ptsNameID, data);
    console.log('Person Name update', result);
    return result;
  } catch (e) {
    console.log(e);
  }
};

export const upsertPersonAssociate = async (data) => {
  const client = store.store.getState().websocket.websocket;

  if (!client) return;
  try {
    const service = client.service('record-person-associates');
    service.timeout = 20000;
    const exist = await service.find({
      query: {
        ptsPersonID: data.ptsPersonID,
        ptsAssociateID: data.ptsAssociateID,
        IsDeleted: false,
      },
    });

    if (exist.total > 0) {
      await service.update(
        { ptsPersonID: data.ptsPersonID, ptsAssociateID: data.ptsAssociateID },
        data
      );
    } else {
      await service.create(data);
    }
  } catch (e) {
    console.log(e);
  }
};

const formatPersonDetailData = (data) => {
  const personDetail = data.values;

  return {
    person: {
      Age: Number.isInteger(Number(personDetail.age)) ? Number(personDetail.age) : null,
      Born: personDetail.birthdate,
      Race: personDetail.race,
      Height: personDetail.height,
      Weight: personDetail.weight,
      HenryFPString: '',
      FPString: '',
      HasDigitalImage: '',
      Allergies: '',
      Sex: personDetail.sex,
      PrintsAvailable: false,
      DNA: '',
      EducationLevel: '',
      IsUSCitizen: true,
      IsOrganDonor: false,
      HasDigitizedSignatureImage: false,
      EnglishLangIndicator: true,
      CauseOfDeath: '',
      MaritalStatus: '',
      PrimaryLanguage: '',
      BirthCitizenship: '',
      BirthNationality: '',
      LocalResident: '',
      Juvenile: false,
      Deceased: false,
      Incarcerated: false,
      ExFelon: false,
      OverrideJuvenile: false,
      Officer: false,
      PublicOfficial: false,
      ProtectiveOrder: false,
      SexOffender: personDetail.sexOffender,
      Probation: false,
      Military: false,
      ConcealedWeaponPermit: false,
      SeniorCitizen: false,
      Dangerous: false,
      WhyDangerous: '',
      Gang: '',
      Flags: '',
      ActiveWarrant: false,
      FirstName: personDetail.firstName || '',
      MiddleName: personDetail.middleName || '',
      LastName: personDetail.lastName || '',
      Prefix: '',
      Suffix: personDetail.suffix,
      NickName: '',
      FullName: `${personDetail.firstName || ''} ${personDetail.middleName || ''} ${
        personDetail.lastName || ''
      }`,
      Ethnicity: personDetail.ethnicity,
      EyeColor: personDetail.eyeColor,
      HairColor: personDetail.hairColor,
      HairLength: personDetail.hairLength,
      HairStyle: personDetail.hairStyle,
      Handed: personDetail.handed,
      BloodType: personDetail.bloodType,
      AgeRangeIncrement: Number(personDetail.ageRangeIncrement),
      IsDeleted: false,
      attachments: personDetail.attachments,
    },
    credentials: {
      SSN: personDetail.ssn,
      OLN: personDetail.oln,
      State: '',
    },
  };
};

export const upsertPerson = async () => {
  const client = store.store.getState().websocket.websocket;
  if (!client) return;

  const currentState = store.store.getState().incident;
  const { partiesPersonForm } = currentState;

  try {
    const service = client.service('record-person-details');
    service.timeout = 20000;

    let result;
    if (partiesPersonForm?.ptsPersonID) {
      result = await service.patch(
        partiesPersonForm?.ptsPersonID,
        formatPersonDetailData(partiesPersonForm)
      );
    } else {
      const personCreate = client.service('cad-search-people');
      personCreate.timeout = 20000;

      result = await personCreate.create(formatPersonDetailData(partiesPersonForm));
    }

    console.log('Person Save', result);
    return result;
  } catch (e) {
    console.log(e);
  }
};

export const addPersonAddress = async (data) => {
  const client = store.store.getState().websocket.websocket;

  if (!client) return;
  try {
    const service = client.service('record-person-address');
    service.timeout = 20000;
    const result = await service.create(data);
    console.log('Person Name Save', result);
    return result;
  } catch (e) {
    console.log(e);
  }
};

export const updatePersonAddress = async (data) => {
  const client = store.store.getState().websocket.websocket;

  if (!client) return;
  try {
    const service = client.service('record-person-address');
    service.timeout = 20000;
    const result = await service.update(
      { ptsPersonID: data.ptsPersonID, ptsAddressID: data.ptsAddressID },
      data
    );
    console.log('Person Address update', result);
    return result;
  } catch (e) {
    console.log(e);
  }
};

export const addPlace = async (data) => {
  const client = store.store.getState().websocket.websocket;

  if (!client) return;
  try {
    const service = client.service('places');
    service.timeout = 20000;
    const result = await service.create(data);

    const placeAddressService = client.service('place-addresses');
    placeAddressService.timeout = 20000;
    await placeAddressService.create({
      ptsPlaceID: result.ptsPlaceID,
      ptsAddressID: data.ptsAddressID,
    });

    console.log('Place Save', result);
    return result;
  } catch (e) {
    console.log(e);
  }
};

export const updatePlace = async (data) => {
  const client = store.store.getState().websocket.websocket;

  if (!client) return;
  try {
    const service = client.service('places');
    service.timeout = 20000;
    const result = await service.patch(data.ptsPlaceID, data);

    const placeAddressService = client.service('place-addresses');
    placeAddressService.timeout = 20000;

    console.log('Place update', result);
    return result;
  } catch (e) {
    console.log(e);
  }
};

export const removeRecordEntity = async (entity, id, id2 = null) => {
  if (entity === 'dna-sample') entity = 'dnasample';
  if (entity === 'contact-method') entity = 'contact';
  const wsClient = store.store.getState().websocket.websocket;
  const definition = {
    credential: {
      service: 'record-person-credentials',
      key: 'ptsCredentialID',
      multi_id: false,
    },
    dnasample: {
      service: 'record-person-dnasample',
      key: 'ptsDNASampleID',
      multi_id: false,
    },
    address: {
      service: 'record-person-address',
      key: 'ptsAddressID',
      key2: 'ptsPersonID',
      multi_id: true,
    },
    associate: {
      service: 'record-person-associates',
      key: 'ptsAssociateID',
      key2: 'ptsPersonID',
      multi_id: true,
    },
    contact: {
      service: 'record-person-contact',
      key: 'ptsInfoID',
      multi_id: false,
    },
    employment: {
      service: 'record-person-employment',
      key: 'ptsPlaceID',
      key2: 'ptsPersonID',
      multi_id: true,
    },
    feature: {
      service: 'record-person-feature',
      key: 'ptsFeatureID',
      multi_id: false,
    },
    name: {
      service: 'record-person-name',
      key: 'ptsNameID',
      multi_id: false,
    },
    photo: {
      service: 'record-person-photo',
      key: 'ptsImageID',
      multi_id: false,
    },
    resource: {
      service: 'record-person-cadresources',
      key: 'ptsResourceID',
      multi_id: false,
    },
    'vehicle-registration': {
      service: 'record-person-vehicle-registration',
      key: 'ptsRegistrationID',
      multi_id: false,
    },
    tags: {
      service: 'rms-tags-v2',
      key: 'ptsTagID',
      multi_id: false,
    },
  };

  try {
    const serviceName = definition[entity].service;
    const service = wsClient.service(serviceName);
    service.timeout = 20000;

    let query = {
      [definition[entity].key]: Number.parseInt(id),
      IsDeleted: false,
    };

    if (definition[entity].multi_id) {
      query[definition[entity].key2] = Number.parseInt(id2);
    }

    await service.remove(null, { query });
  } catch (error) {
    console.log(error);
  }
};

export const checkMergeStats = async (sourcePtsPersonID, targetPtsPersonID) => {
  const client = store.store.getState().websocket.websocket;

  if (!client) return;

  try {
    const service = client.service('rms-person-merge');
    service.timeout = 20000;

    const result = await service.find({ query: { sourcePtsPersonID, targetPtsPersonID } });

    return result;
  } catch (error) {
    console.log(error);
  }
};

export const personMerge = async (data) => {
  const service = getService('rms-person-merge');
  return await service.create(data);
};

/*** New Changes */

export const getPersonContactInfo = (ptsPersonID) => {
  const service = getService('cad');
  return service.get({
    type: 'get-person-contact-info',
    data: { ptsPersonID },
  });
};

export const getPartyPerson = (ptsPersonID) => {
  const service = getService('cad');
  return service.get({
    type: 'get-party-person',
    data: { ptsPersonID },
  });
};
