import React from 'react';
import { connect } from 'react-redux';
import IconsAgency from '../IconsAgency';

function Icon(props) {
  const { AgencyID, color, dictionary, size = 1 } = props;
  const { Agencies } = dictionary;

  const agency = Agencies.find((a) => a.AgencyID === AgencyID);
  const iconIndex = agency ? agency.Icon : 0;
  const Icon = IconsAgency[iconIndex] || IconsAgency[0];

  return <Icon style={{ color: color, transform: `scale(${size})` }} />;
}

const mapStateToProps = (state) => {
  return {
    dictionary: state.dictionary,
  };
};

export default connect(mapStateToProps)(Icon);
