import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';

import { Fab, Button, makeStyles, TextField, Box } from '@material-ui/core';

import CheckIcon from '@material-ui/icons/Check';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import Tooltip from 'components/Tooltip';
import XGrid3 from 'components/RMSXGrid';
import AddressLookup from 'components/RMSAddressLookup';
import Checkbox2 from 'components/RMSCheckbox/RMSCheckbox2';
import BackDatedRecordHistory from 'components/BackDatedRecordHistory';
import QuickFilterSearch from 'components/RMSSearch/components/QuickFilterSearch';

import {
  savePlaceAddress,
  removePlaceAddress,
  updatePlaceAddress,
  getPlaceAddressData,
} from 'reducers/SearchReducer';
import { handleError } from 'reducers/ErrorReducer';
import { notify } from 'reducers/NotifierReducer';
import { showSpinner, hideSpinner } from 'reducers/UiReducer';
import { displayDateTime } from 'reducers/TimeReducer';
import { showPlaceAddressEdit } from 'reducers/PersonDialogsReducer';

const useStyles = makeStyles((theme) => ({
  wrap: {
    padding: theme.spacing(3),
  },
  gridWrap: {
    width: '100%',
    boxSizing: 'border-box',
    height: 600,
  },
  fileInput: {
    display: 'none',
  },
  form: {
    display: 'flex',
    alignItems: 'center',
    margin: '20px 0 12px',
  },
  filters: {
    display: 'flex',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
    '& > div': {
      marginRight: theme.spacing(2),
    },
  },
  filterWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    marginBottom: 8,
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
  },
  addressWidget: {
    width: 550,
    display: 'inline-block',
  },
  address: {
    display: 'flex',
    margin: '24px 0 16px',
  },
  addressActions: {
    display: 'flex',
    marginLeft: 8,
    '& button': {
      marginLeft: 8,
    },
    '& svg': {
      marginRight: 8,
    },
  },
}));

const bool = (params) => (params.value ? <CheckIcon /> : <span></span>);

const defaultColumns = [
  {
    field: 'IsPrimary',
    headerName: 'IsPrimary',
    width: 100,
    format: 'bool',
    renderCell: bool,
    hide: false,
  },
  { field: 'AddressID', headerName: 'Address ID', width: 150, hide: false },
  { field: 'FullAddressText', headerName: 'Full Address', width: 150, hide: false },
  { field: 'AddressNumber', headerName: 'Address Number', width: 150, hide: false },
  { field: 'StreetName', headerName: 'Street Name', width: 150, hide: false },
  { field: 'StreetType', headerName: 'Street Type', width: 150, hide: false },
  { field: 'City', headerName: 'City', width: 150, hide: false },
  { field: 'State', headerName: 'State', width: 150, hide: false },
  { field: 'PostalCode', headerName: 'Postal Code', width: 150, hide: false },
  { field: 'PostalCodeExtension', headerName: 'Extension', width: 150, hide: false },
  { field: 'Country', headerName: 'Country', width: 150, hide: false },
  { field: 'AddressCategory', headerName: 'Address Category', width: 150, hide: false },
  {
    field: 'Created',
    headerName: 'Created',
    width: 200,
    valueFormatter: (params) => displayDateTime(params.value),
    hide: false,
  },
  { field: 'CreatedBy', headerName: 'Created By', width: 150, hide: false },
  {
    field: 'Updated',
    headerName: 'Updated',
    width: 200,
    valueFormatter: (params) => displayDateTime(params.value),
    hide: false,
  },
  { field: 'UpdatedBy', headerName: 'Updated By', width: 150, hide: false },
];
const PlaceAddress = (props) => {
  const classes = useStyles();
  const { ptsPlaceID } = props;

  const [rows, setRows] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [filteredRows, setFilteredRows] = useState([]);
  const [selection, setSelection] = useState(null);
  const [tables, setTables] = useState(null);
  const [colsSeq, setColsSeq] = useState([]);
  const [columns, setColumns] = useState(defaultColumns);
  const [editing, setEditing] = useState(false);
  const [address, setAddress] = useState(null);
  const [IsPrimary, setIsPrimary] = useState(false);
  // const [primaryAvail, setPrimaryAvail] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);

  const [recordHistoryData, setRecordHistoryData] = React.useState({
    shouldVisible: true,
    isDisabled: true,
    createUpdateInfo: {},
  });
  const [showRecordHistory, setShowRecordHistory] = React.useState(false);

  const mountedRef = useRef(true);

  useEffect(() => {
    const data = processData(props.addressData);

    setRecordHistoryData({
      shouldVisible: true,
      isDisabled: !data[0].Created,
      createUpdateInfo: {
        created: data[0].Created,
        createdBy: data[0].CreatedBy,
        updated: data[0].Updated,
        updatedBy: data[0].UpdatedBy,
      },
    });

    setRows(data);
    setLoaded(true);
  }, [props.addressData]);

  const handleCloseRecordHistoryDialog = () => setShowRecordHistory(false);

  const processData = (data) => {
    return data.map((row, idx) => {
      return {
        ...row,
        id: row.ptsAddressID,
      };
    });
  };

  const onAddAddress = (address) => {
    setAddress(address);
  };

  const onAddClick = () => {
    setRecordHistoryData({
      shouldVisible: true,
      isDisabled: true,
      createUpdateInfo: {},
    });

    setEditing(true);
    setAddress(null);
    // const primaryAvail = rows.reduce((res, obj) => (obj.IsPrimary ? false : res), true);
    // setPrimaryAvail(primaryAvail);
    // const IsPrimary = primaryAvail ? true : false;
    setIsPrimary(false);
    setSelection(null);
  };

  // const onEditClick = () => props.showPlaceAddressEdit(selection);

  const onEditClick = () => {
    const selectedAddress = rows.find((row) => row.ptsAddressID === selection);

    setAddress(selectedAddress);
    setIsPrimary(selectedAddress.IsPrimary);
    setEditing(true);
  };

  const onDeleteClick = async () => {
    if (!window.confirm('Are you sure you want to delete selected address?')) return;
    try {
      await removePlaceAddress({ ptsPlaceID, ptsAddressID: selection });
      const updatedRows = rows.filter((row) => row.ptsAddressID !== selection);
      setRows(updatedRows);
      //props.notify('Address removed', 'success');
      props.setNeedRefresh(!props.needRefresh);
    } catch (err) {
      props.handleError(err, 'Error, Unable to remove address');
    }
  };

  const clearPlace = () => {
    setAddress(null);
  };

  const save = async () => {
    const data = {
      ptsPlaceID,
      ptsAddressID: address.ptsAddressID,
      IsPrimary,
    };

    if (selection) {
      updatePlaceAddress(data)
        .then(() => {
          props.setNeedRefresh(!props.needRefresh);
          setAddress(null);
          setEditing(false);
        })
        .catch(props.handleError);
    } else {
      /* Update the placeAddress record if it already exists or create new placeAddress */
      const dataExist = await getPlaceAddressData(ptsPlaceID, address.ptsAddressID);

      if (dataExist.length > 0) {
        updatePlaceAddress(data)
          .then(() => {
            props.setNeedRefresh(!props.needRefresh);
            setAddress(null);
            setEditing(false);
          })
          .catch(props.handleError);
      } else {
        savePlaceAddress(data)
          .then(() => {
            props.setNeedRefresh(!props.needRefresh);
            setAddress(null);
            setEditing(false);
          })
          .catch(props.handleError);
      }
    }
  };

  const renderIsPrimary = () => {
    const onChange = () => setIsPrimary(!IsPrimary);
    return (
      <Checkbox2
        checked={IsPrimary}
        onChange={onChange}
        label="Is Primary"
        // disabled={!primaryAvail}
      />
    );
  };

  const renderActions = () => {
    return (
      <div className={classes.addressActions}>
        {recordHistoryData?.shouldVisible && (
          <Box className="">
            <BackDatedRecordHistory
              showRecordHistory={showRecordHistory}
              setShowRecordHistory={setShowRecordHistory}
              disabled={recordHistoryData?.isDisabled}
              handleCloseRecordHistoryDialog={handleCloseRecordHistoryDialog}
              createUpdateInfo={recordHistoryData?.createUpdateInfo}></BackDatedRecordHistory>
          </Box>
        )}
        <Button
          color="primary"
          variant="contained"
          autoFocus
          onClick={save}
          disabled={!address || !props.canSave}>
          <SaveIcon /> Save
        </Button>
        <Button color="primary" onClick={() => setEditing(false)}>
          <CloseIcon /> Close
        </Button>
      </div>
    );
  };

  return (
    <div className={classes.wrap}>
      <div className={classes.filterWrap}>
        {editing && (
          <>
            <div className={classes.form}>
              {selection ? (
                <TextField
                  autoComplete="hidden"
                  size="small"
                  label="Address"
                  variant="outlined"
                  value={address?.FullAddressText}
                  fullWidth
                  className={classes.addressWidget}
                  disabled
                />
              ) : (
                <AddressLookup
                  className={classes.addressWidget}
                  ptsPlaces={false}
                  ptsAddresses={true}
                  googleAddresses={false}
                  onPlaceValueSet={onAddAddress}
                  onAddressValueSet={onAddAddress}
                  onReset={clearPlace}
                  label="Search Address"
                  compact="true"
                />
              )}

              {renderIsPrimary()}
            </div>
            <div>{renderActions()}</div>
          </>
        )}
        {!editing && (
          <>
            <div className={classes.filterWrap}>
              <QuickFilterSearch rows={rows} columns={columns} setFilteredRows={setFilteredRows} />
            </div>
            <div className={classes.actions}>
              <Tooltip title="Add Address">
                <Fab size="small" color="secondary" disabled={!props.canSave} onClick={onAddClick}>
                  <AddIcon />
                </Fab>
              </Tooltip>
              {selection && (
                <>
                  <Tooltip title="Edit Address" className="ml-2">
                    <Fab
                      size="small"
                      color="secondary"
                      disabled={!props.canSave}
                      onClick={onEditClick}>
                      <EditIcon />
                    </Fab>
                  </Tooltip>
                  <Tooltip title="Delete Address" disabled={!props.canSave} className="ml-2">
                    <Fab size="small" color="secondary" onClick={onDeleteClick}>
                      <DeleteIcon />
                    </Fab>
                  </Tooltip>
                </>
              )}
            </div>
          </>
        )}
      </div>
      <XGrid3
        name="PlaceAddress"
        columns={columns}
        setColumns={setColumns}
        rows={filteredRows}
        loading={!loaded}
        setSelection={setSelection}
      />
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    editPeopleEmployment: state.dialogs.editPeopleEmployment,
  };
};
export default connect(mapStateToProps, {
  handleError,
  showSpinner,
  hideSpinner,
  showPlaceAddressEdit,
  notify,
})(PlaceAddress);
