import React from 'react';

function MentalHealthIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="50"
      viewBox="0 0 50 50"
      preserveAspectRatio="xMidYMid meet"
      {...props}>
      <g transform="translate(0.000000,50.000000) scale(0.100000,-0.100000)" stroke="none">
        <path d="M156 460 c-41 -13 -94 -68 -106 -110 -17 -60 -11 -94 25 -163 25 -47 35 -79 35 -111 l0 -46 105 0 105 0 0 25 c0 20 5 25 25 25 36 0 75 42 75 79 0 20 7 34 20 41 26 14 25 28 -3 70 -13 19 -29 54 -35 77 -28 96 -136 145 -246 113z m125 -109 c39 -40 39 -82 0 -122 -20 -20 -39 -29 -61 -29 -43 0 -90 47 -90 90 0 43 47 90 90 90 22 0 41 -9 61 -29z" />
        <path d="M210 325 c0 -20 -5 -25 -25 -25 -14 0 -25 -4 -25 -10 0 -5 11 -10 25 -10 20 0 25 -5 25 -25 0 -14 5 -25 10 -25 6 0 10 11 10 25 0 20 5 25 25 25 14 0 25 5 25 10 0 6 -11 10 -25 10 -20 0 -25 5 -25 25 0 14 -4 25 -10 25 -5 0 -10 -11 -10 -25z" />
      </g>
    </svg>
  );
}

export default MentalHealthIcon;
