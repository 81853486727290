import React from 'react';

function MeetingIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" {...props}>
      <g transform="translate(0.000000,50.000000) scale(0.100000,-0.100000)" stroke="none">
        <path d="M129 431 c-28 -28 -29 -34 -26 -98 2 -37 11 -82 20 -100 29 -55 23 -70 -38 -99 -58 -28 -85 -55 -85 -86 0 -16 13 -18 149 -18 l150 0 -11 30 c-6 17 -8 44 -5 60 4 19 2 30 -8 34 -20 7 -19 39 3 84 21 42 28 143 12 172 -14 26 -62 50 -100 50 -22 0 -41 -9 -61 -29z" />
        <path d="M310 421 c17 -33 10 -141 -13 -187 -11 -24 -17 -49 -13 -60 6 -16 9 -15 34 8 15 14 35 28 44 31 31 11 58 68 58 122 0 28 -4 56 -9 63 -12 19 -59 42 -87 42 -21 0 -23 -3 -14 -19z" />
        <path d="M351 186 c-87 -48 -50 -186 49 -186 51 0 100 49 100 99 0 75 -83 124 -149 87z m104 -31 c50 -49 15 -135 -55 -135 -41 0 -80 39 -80 80 0 70 86 105 135 55z" />
        <path d="M390 130 c0 -18 -6 -33 -15 -36 -8 -4 -15 -13 -15 -20 0 -12 4 -12 25 2 19 12 25 25 25 50 0 19 -4 34 -10 34 -5 0 -10 -14 -10 -30z" />
      </g>
    </svg>
  );
}

export default MeetingIcon;
