import React, { Suspense, useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import feathers from '@feathersjs/feathers';
import socketio from '@feathersjs/socketio-client';
import io from 'socket.io-client';
import auth from '@feathersjs/authentication-client';
import * as serviceWorker from './serviceWorker';
import { handleError } from './reducers/ErrorReducer';
import { setWebsocketConnection } from './reducers/WebsocketReducer';
import {
  setUserAuthenticated,
  setUserData,
  logOut,
  getUserClearanceLevel,
  submitLoginForm,
} from './reducers/UserReducer';
import { ClimbingBoxLoader } from 'react-spinners';
import { motion } from 'framer-motion';

import { makeStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

// Layout Blueprints
import { MinimalLayout, MainLayout } from './layout-blueprints';

// Pages
import PageLogin from './pages/PageLogin';
import Page404 from './pages/Page404';
import NotAuthorized from 'pages/NotAuthorized';
import Loading from 'pages/Loading';

import routeDefinitions from './routeDefinitions';
import PageResetPassword from 'pages/PageResetPassword';
import { getAccessPermission, getFullPermissions } from 'reducers/PermissionsReducer';
import routePermissionDefination from 'routePermissionDefination';

let noPermissionShown = false;
let registration = false;
let reloadNeeded = false;
const handleReadPermissionCheckForRoutes = (item, userAgency) => {
  const { name, type, agency } = item || {};
  const permission = getFullPermissions(type, name, agency === 'any' ? 'any' : userAgency);
  return permission.Read ? true : false;
};
const checkNecessaryPermissions = (props, userAgency) => {
  const { pathname } = props.location || {};
  if (pathname === '/incidents' || pathname === '/ibrs') {
    const incidentsPermissions = getFullPermissions('rms', 'Incidents', userAgency);

    if (!incidentsPermissions.Read) {
      return false;
    }
  }
  return true;
};
const ProtectedRoute = ({ component: Component, userAgency, isAuthenticated, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      return isAuthenticated === true ? (
        checkNecessaryPermissions(props, userAgency) ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: '/Main', state: { from: props.location } }} />
        )
      ) : (
        <Redirect to={{ pathname: '/Login', state: { from: props.location } }} />
      );
    }}
  />
);

const LoginRoute = ({ component: Component, isAuthenticated, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated === true ? (
          <Redirect to={{ pathname: '/home', state: { from: props.location } }} />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const Routes = (props) => {
  const {
    isAuthenticated,
    setWebsocketConnection,
    setUserAuthenticated,
    setUserData,
    setLoggedInUserData,
    circularLoading,
    permissions,
    dictionaryLoaded,
  } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const userAgency = useSelector((state) => state.user.userAgency);
  const isLoaded = permissions.loaded;
  const rmsPermission = getAccessPermission('modules', 'RMS', 'any');

  useEffect(() => {
    // handle service worker updates
    const updateApp = (registration) => {
      const waitingServiceWorker = registration.waiting;

      if (waitingServiceWorker) {
        waitingServiceWorker.addEventListener('statechange', (event) => {
          if (event.target.state === 'activated') {
            window.location.reload();
          }
        });
        waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
      }
    };
    const openNotification = (registration) => {
      const notification = {
        title: `Application Update Available!`,
        message: `New release of CAD Application has been released.It is highly recomended to launch the new version!!`,
        position: 'tc',
        level: 'warning',
        autoDismiss: 0,
        action: {
          label: 'LAUNCH!',
          callback: () => updateApp(registration),
        },
      };
      props.notifyPanel(notification, 'success');
    };
    serviceWorker.register({
      onSuccess: () => console.log('service worker registered!'),
      onUpdate: (reg) => {
        if (isAuthenticated) {
          openNotification(reg);
          registration = reg;
          props.registerAppUpdate(reg);
        } else {
          updateApp(reg);
        }
      },
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    async function establishConnection() {
      const client = feathers();
      client.configure(
        socketio(
          io(process.env.REACT_APP_API_URL, {
            transports: ['websocket', 'polling'],
            perMessageDeflate: false,
            timeout: 20000,
            requestTimeout: 20000,
          })
        )
      );
      client.configure(auth({ storageKey: 'auth' }));
      try {
        const user = await client.reAuthenticate();
        setWebsocketConnection(client);

        // setUserData(user);
        // dispatch(setUserAgency(user?.user?.AgencyID || ''));
        dispatch(submitLoginForm(null, null, user));
        // getUserClearanceLevel(user.user.ptsClearanceLevelID);
        // setUserAuthenticated(true);
      } catch (error) {
        if (error.code === 401) {
          setUserAuthenticated(false);
          setUserData(null);
          setWebsocketConnection(client);
        }
        setWebsocketConnection(client);
      }
    }
    establishConnection();
  }, [setWebsocketConnection, setUserAuthenticated, setLoggedInUserData]);

  const pageVariants = {
    initial: {
      opacity: 0,
      scale: 0.99,
      width: '100%',
      height: '100%',
    },
    in: {
      opacity: 1,
      scale: 1,
    },
    out: {
      opacity: 0,
      scale: 1.01,
    },
  };

  const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.4,
  };

  const SuspenseLoading = () => {
    return (
      <div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
        <div className="d-flex align-items-center flex-column px-4">
          <ClimbingBoxLoader color={'#0153a3'} loading={true} />
        </div>
        <div className="text-muted font-size-xl text-center pt-3">
          Please wait while we load the view...
        </div>
      </div>
    );
  };

  if (!dictionaryLoaded && isAuthenticated)
    return (
      <div
        style={{
          width: '100vw',
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <h4 className="pb-5"> Loading</h4>
      </div>
    );
  return (
    <Suspense fallback={<SuspenseLoading />}>
      <Switch>
        <Redirect exact from="/" to="/Login" />
        <Route path={['/Login']}>
          <MinimalLayout>
            <motion.div
              initial="initial"
              animate="in"
              exit="out"
              variants={pageVariants}
              transition={pageTransition}>
              <LoginRoute path="/Login" component={PageLogin} isAuthenticated={isAuthenticated} />
            </motion.div>
          </MinimalLayout>
        </Route>
        <Route path={['/Reset-password/:hash']}>
          <MinimalLayout>
            <PageResetPassword isAuthenticated={isAuthenticated} />
          </MinimalLayout>
        </Route>

        {routeDefinitions.map((route, key) => {
          let Layout = isLoaded ? route.layout : MinimalLayout;
          let component = route.component;
          const isLazy = route.component._payload;

          if (isLoaded && !rmsPermission) {
            component = NotAuthorized;
            Layout = MinimalLayout;
            if (!noPermissionShown) {
              noPermissionShown = true;
              dispatch(
                handleError({
                  code: 440,
                  message: "You don't have enough permission to Login!",
                })
              );
              dispatch(logOut());
            }
          }

          if (routePermissionDefination[route.url]) {
            const parent = routePermissionDefination[route.url].parent;

            const permissionObj = parent
              ? routePermissionDefination[parent]
              : routePermissionDefination[route.url];

            if (!handleReadPermissionCheckForRoutes(permissionObj, userAgency)) {
              return;
            }
          }

          const componentRoute = (
            <>
              <Backdrop className={classes.backdrop} open={circularLoading}>
                <CircularProgress color="inherit" />
              </Backdrop>
              <Layout>
                <motion.div
                  initial="initial"
                  animate="in"
                  exit="out"
                  variants={pageVariants}
                  transition={pageTransition}>
                  {/*TODO: Change this back to protected route for production*/}
                  <ProtectedRoute
                    path={route.url}
                    component={component}
                    isAuthenticated={isAuthenticated}
                    userAgency={userAgency}
                  />
                </motion.div>
              </Layout>
            </>
          );

          if (isLazy) {
            return (
              <Route path={[route.url]} key={key}>
                <Suspense fallback={<SuspenseLoading />}>{componentRoute}</Suspense>
              </Route>
            );
          } else {
            return (
              <Route path={[route.url]} key={key}>
                {componentRoute}
              </Route>
            );
          }
        })}

        <Route>
          <MainLayout>
            <motion.div
              initial="initial"
              animate="in"
              exit="out"
              variants={pageVariants}
              transition={pageTransition}>
              <ProtectedRoute component={Page404} isAuthenticated={isAuthenticated} />
            </motion.div>
          </MainLayout>
        </Route>
      </Switch>
    </Suspense>
  );
};

const mapStateToProps = (state) => ({
  network: state.offline,
  isAuthenticated: state.user.isAuthenticated,
  circularLoading: state.uiMenu.circularLoading,
  permissions: state.permissions,
  loggedInOrNot: state.user.loggedInOrNot,
  dictionaryLoaded: Object.keys(state.dictionary).length > 0,
});

export default connect(mapStateToProps, {
  setWebsocketConnection,
  setUserAuthenticated,
  setUserData,
})(Routes);
