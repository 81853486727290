import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { saveAs } from 'file-saver';

import { Button, Tooltip, makeStyles } from '@material-ui/core';

import GetAppIcon from '@material-ui/icons/GetApp';

import RMSDownloadButton from 'components/RMSButtons/RMSDownloadButton';

const useStyles = makeStyles((theme) => ({
  fabStyle: {
    backgroundColor: theme.palette.colors.chipBg,
    color: 'secondary',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
}));

const DownloadAttachments = (props) => {
  const classes = useStyles();
  const { disabled = false, tooltipText = 'Download', children } = props;

  const selectedDownloadAttachments = useSelector(
    (state) => state.attachments.selectedDownloadAttachments
  );

  const handleMutipleDownload = () => {
    selectedDownloadAttachments.forEach((att) => {
      saveAs(att.rawFile ? att.rawFile : new Blob([att.dataURL], { type: att.type }), att.name);
    });
  };

  return (
    <>
      <RMSDownloadButton
        disabled={disabled}
        tooltipText={tooltipText}
        className={classes.fabStyle}
        onClick={handleMutipleDownload}
      />
      {children}
    </>
  );
};

export default DownloadAttachments;
