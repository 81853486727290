import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { getPersonRecordDetails } from 'reducers/SearchReducer';
import {
  Grid,
  Badge,
  Card,
  Button,
  Box,
  Avatar,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { makeStyles } from '@material-ui/core/styles';
import { genColHeaderName } from 'utils/functions';
import { showPersonRelatedRecordsDetail } from 'reducers/PersonDialogsReducer';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(4),
  },
  arrow: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const categories = ['Bookings', 'CadIncidents', 'Citations', 'RmsIncidents', 'Warrants'];

function RelatedRecords(props) {
  const { ptsPersonID } = props;
  const classes = useStyles();

  const [excludeEmployeeRelatedChecked, setExcludeEmployeeRelatedChecked] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchData();
  }, [excludeEmployeeRelatedChecked]);

  const fetchData = async () => {
    const data = await getPersonRecordDetails(ptsPersonID, excludeEmployeeRelatedChecked);
    const dataArr = [];

    Object.keys(data).map((key) => {
      if (categories.find((cat) => cat === key) && data[key])
        dataArr.push([key, JSON.parse(data[key])]);
    });
    setData(dataArr);
  };

  const RenderPersonItem = (record, idx) => {
    let active = false;
    const showCount = (record) => {
      if (record[0] !== 'Warrants') return record[1].length;
      const count = record[1].filter((item) => item.Disposition === 'Active').length;
      active = count > 0;
      return count > 0 ? (
        <span style={{ color: 'red' }}>{count + '/' + record[1].length}</span>
      ) : (
        <span>{record[1].length}</span>
      );
    };

    const handleCardClick = () => props.showPersonRelatedRecordsDetail(record);
    return (
      <Grid item xs={12} md={4} key={idx}>
        <Card onClick={handleCardClick}>
          <Grid container>
            <Grid item sm={10}>
              <div className="ml-2">
                <div className="display-3">{showCount(record)}</div>
                <div className="divider mt-2 mb-3 border-2 w-25 rounded" />
                <div
                  className="font-weight-bold font-size-sm text-uppercase"
                  style={{ color: active ? 'red' : null }}>
                  {genColHeaderName(record[0])}
                </div>
              </div>
            </Grid>
            <Grid item sm={2} className={classes.arrow}>
              <ChevronRightIcon />
            </Grid>
          </Grid>
        </Card>
      </Grid>
    );
  };

  const handleChangeCheckbox = async (event) => {
    setExcludeEmployeeRelatedChecked(event.target.checked);
  };

  return (
    <div className={classes.root}>
      <FormControlLabel
        label="Exclude Employee Related Records"
        color="default"
        control={
          <Checkbox checked={excludeEmployeeRelatedChecked} onChange={handleChangeCheckbox} />
        }
      />
      <Grid container spacing={2}>
        {data.map((cur, idx) => {
          return RenderPersonItem(cur, idx);
        })}
        {data.length === 0 && <h6> No Record Available. </h6>}
      </Grid>
    </div>
  );
}

export default connect(null, { showPersonRelatedRecordsDetail })(RelatedRecords);
