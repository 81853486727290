import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';

import Fab from '@material-ui/core/Fab';
import { makeStyles } from '@material-ui/core/styles';

import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
// import DeleteIcon from '@material-ui/icons/Delete';

import Tooltip from 'components/Tooltip';
import XGrid3 from 'components/RMSXGrid';
import QuickFilterSearch from 'components/RMSSearch/components/QuickFilterSearch';

import { getService } from 'reducers/service';
import { handleError } from 'reducers/ErrorReducer';
import { showSpinner, hideSpinner } from 'reducers/UiReducer';
import { showVehicleEditReg } from 'reducers/PersonDialogsReducer';
import { displayDate, displayDateTime } from 'reducers/TimeReducer';

const useStyles = makeStyles((theme) => ({
  wrap: {
    padding: theme.spacing(3),
  },
  filterWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    marginBottom: 8,
  },
  lookup: {
    maxWidth: 550,
    marginBottom: 20,
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
  lookupActions: {
    marginLeft: 8,
    '& button': {
      marginLeft: 8,
    },
    '& svg': {
      marginRight: 8,
    },
  },
}));

const defaultColumns = [
  { field: 'VehicleID', headerName: 'Vehicle ID', width: 150, hide: false },
  { field: 'ptsRegistrationID', headerName: 'RegistrationID', width: 150, hide: false },
  { field: 'PlateType', headerName: 'Plate Type', width: 150, hide: false },

  { field: 'PlateColor', headerName: 'Plate Color', width: 150, hide: false },

  { field: 'PlateNumber', headerName: 'Plate Number', width: 150, hide: false },
  { field: 'PlateState', headerName: 'Plate State', width: 150, hide: false },
  { field: 'PlateBackgroundText', headerName: 'BackgroundText', width: 150, hide: false },
  { field: 'PlateDetail', headerName: 'PlateDetail', width: 150, hide: false },
  { field: 'PlateStatus', headerName: 'PlateStatus', width: 150, hide: false },
  {
    field: 'PlateExpiration',
    headerName: 'Plate Expiration',
    valueFormatter: (params) => displayDate(params.value),
  },
  { field: 'OdometerReading', headerName: 'OdometerReading', width: 150, hide: false },
  { field: 'InsuranceCompany', headerName: 'Insurance Company', width: 150, hide: false },
  { field: 'VehicleValue', headerName: 'Vehicle Value', width: 150, hide: false },
  {
    field: 'Created',
    headerName: 'Created',
    width: 200,
    valueFormatter: (params) => displayDateTime(params.value),
    hide: false,
  },
  { field: 'CreatedBy', headerName: 'Created By', width: 150, hide: false },
  {
    field: 'Updated',
    headerName: 'Updated',
    width: 200,
    valueFormatter: (params) => displayDateTime(params.value),
    hide: false,
  },
  { field: 'UpdatedBy', headerName: 'Updated By', width: 150, hide: false },
];

const PersonVehicle = (props) => {
  const classes = useStyles();
  const { ptsPlaceID, dataUpdate } = props;

  const [rows, setRows] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [filteredRows, setFilteredRows] = useState([]);
  const [selection, setSelection] = useState(null);
  const [columns, setColumns] = useState(defaultColumns);

  const mountedRef = useRef(true);

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (dataUpdate && dataUpdate.type === 'vehicle-reg') getData();
  }, [dataUpdate]);

  const getData = async () => {
    if (!ptsPlaceID) return;
    try {
      const service = getService('ptsvehiclereg');
      const result = await service.find({
        query: {
          ptsParentID: ptsPlaceID,
          PartyType: 'Place',
          IsDeleted: 0,
          $sort: {
            Created: -1,
          },
        },
      });
      if (!mountedRef.current) return;
      const data = result?.data || [];
      setRows(processData(data));
      setLoaded(true);
    } catch (err) {
      props.handleError(err);
    }
  };

  const processData = (data) => {
    return data.map((row) => ({ ...row, id: row.ptsRegistrationID }));
  };

  const edit = () => {
    const item = getCurrentItem();
    item && props.showVehicleEditReg({ ...item, disableParty: true, type: 'place' });
  };

  const getCurrentItem = () => {
    const data = rows.find((r) => r.ptsRegistrationID === selection);
    return data ? data : null;
  };

  const renderActions = () => {
    const onAdd = () => {
      props.showVehicleEditReg({
        PartyType: 'Place',
        ptsParentID: ptsPlaceID,
        disableParty: true,
        type: 'place',
      });
    };
    return (
      <div className={classes.actions}>
        <Tooltip title="Add Registration">
          <Fab size="small" color="secondary" disabled={!props.canSave} onClick={onAdd}>
            <AddIcon />
          </Fab>
        </Tooltip>
        {selection !== null && (
          <>
            <Tooltip title="Edit Registration">
              <Fab size="small" color="secondary" disabled={!props.canSave} onClick={edit}>
                <EditIcon />
              </Fab>
            </Tooltip>
            {/* <Tooltip title="Download Credential">
              <Fab size="small" color="secondary" onClick={del}>
                <DeleteIcon />
              </Fab>
            </Tooltip> */}
          </>
        )}
      </div>
    );
  };

  return (
    <div className={classes.wrap}>
      <div className={classes.filterWrap}>
        <QuickFilterSearch rows={rows} columns={columns} setFilteredRows={setFilteredRows} />
        {renderActions()}
      </div>
      <XGrid3
        name="PeopleVehicles"
        columns={columns}
        setColumns={setColumns}
        rows={filteredRows}
        loading={!loaded}
        setSelection={setSelection}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  editPeopleEmployment: state.dialogs.editPeopleEmployment,
  dataUpdate: state.dataUpdate,
});

export default connect(mapStateToProps, {
  handleError,
  showSpinner,
  hideSpinner,
  showVehicleEditReg,
})(PersonVehicle);
