import React from 'react';

import { Fab, Tooltip } from '@material-ui/core';
import LocalPrintshopIcon from '@material-ui/icons/LocalPrintshop';

export default function RMSPrintButton(props) {
  const { tooltipText, onClick, disabled, size } = props;
  return (
    <Tooltip title={tooltipText}>
      <Fab
        disabled={disabled}
        size="small"
        color="secondary"
        aria-label="edit"
        onClick={onClick}
        className={props.className ? props.className : ''}>
        <LocalPrintshopIcon style={{ fontSize: size }} />
      </Fab>
    </Tooltip>
  );
}
