import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import CheckIcon from '@material-ui/icons/Check';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { PinDrop } from '@material-ui/icons';

import Tooltip from 'components/Tooltip';
import RMSXGrid from 'components/RMSXGrid';
import QuickFilterSearch from 'components/RMSSearch/components/QuickFilterSearch';
import PrintsSearch2 from 'components/RMSSearch/components/PrintsSearch2';

import { showSpinner, hideSpinner } from 'reducers/UiReducer';
import { handleError } from 'reducers/ErrorReducer';
import { getPeopleAddresses } from 'reducers/SearchReducer';
import { showAddressDialog, showPeopleAddress } from 'reducers/PersonDialogsReducer';
import { deletePersonAddress } from 'reducers/SearchReducer';
import { notifyDataUpdate } from 'reducers/DataUpdateReducer';
import { notify } from 'reducers/NotifierReducer';
import { getFullPermissions } from 'reducers/PermissionsReducer';
import { displayDateTime } from 'reducers/TimeReducer';

const useStyles = makeStyles((theme) => ({
  wrap: {
    padding: theme.spacing(3),
  },
  gridWrap: {
    width: '100%',
    boxSizing: 'border-box',
    height: 600,
  },
  fileInput: {
    display: 'none',
  },
  form: {
    display: 'flex',
    alignItems: 'center',
    margin: '20px 0 12px',
  },
  filters: {
    display: 'flex',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
    '& > div': {
      marginRight: theme.spacing(2),
    },
  },
  filterWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(2),
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
  addressWidget: {
    width: 550,
    display: 'inline-block',
  },
  address: {
    display: 'flex',
    margin: '24px 0 16px',
  },
  addressActions: {
    marginLeft: 8,
    '& button': {
      marginLeft: 8,
    },
    '& svg': {
      marginRight: 8,
    },
  },
  closeButton: { color: theme.button.color },
}));

const bool = (params) => (params.value ? <CheckIcon /> : <span></span>);

const defaultColumns = [
  { field: 'AddressID', headerName: 'Address ID', width: 150, hide: false },
  { field: 'FullAddressText', headerName: 'Full Address', width: 200, hide: false },
  {
    field: 'IsPrimary',
    headerName: 'Is Primary',
    width: 100,
    format: 'bool',
    renderCell: bool,
    hide: false,
  },
  {
    field: 'Country',
    headerName: 'Country',
    width: 125,
    hide: false,
  },
  {
    field: 'State',
    headerName: 'State',
    width: 100,
    hide: false,
  },
  {
    field: 'StreetName',
    headerName: 'StreetName',
    width: 150,
    hide: false,
  },
  {
    field: 'BldgType',
    headerName: 'BldgType',
    width: 150,
    hide: false,
  },
  {
    field: 'BldgIdentifier',
    headerName: 'Bldg Identifier',
    width: 150,
    hide: false,
  },
  {
    field: 'AddressNumberPrefix',
    headerName: 'Prefix',
    width: 150,
    hide: false,
  },
  {
    field: 'AddressNumber',
    headerName: 'Number',
    width: 150,
    hide: false,
  },
  {
    field: 'AddressNumberSuffix',
    headerName: 'Suffix',
    width: 150,
    hide: false,
  },
  {
    field: 'PreDirection',
    headerName: 'Pre Direction',
    width: 150,
    hide: false,
  },
  {
    field: 'City',
    headerName: 'City',
    width: 150,
    hide: false,
  },
  {
    field: 'PostalCode',
    headerName: 'Postal Code',
    width: 150,
    hide: false,
  },
  {
    field: 'PostalCodeExtension',
    headerName: 'Extension',
    width: 150,
    hide: false,
  },
  {
    field: 'FloorType',
    headerName: 'Floor Type',
    width: 150,
    hide: false,
  },
  {
    field: 'FloorIdentifier',
    headerName: 'Floor Identifier',
    width: 150,
    hide: false,
  },
  {
    field: 'UnitType',
    headerName: 'Unit Type',
    width: 150,
    hide: false,
  },
  {
    field: 'Unit Identifier',
    headerName: 'Unit Identifier',
    width: 150,
    hide: false,
  },
  {
    field: 'created',
    headerName: 'Created',
    width: 200,
    valueFormatter: (params) => displayDateTime(params.value),
  },
  { field: 'createdBy', headerName: 'Created By', width: 150 },
  {
    field: 'updated',
    headerName: 'Updated',
    width: 200,
    valueFormatter: (params) => displayDateTime(params.value),
  },
  { field: 'updatedBy', headerName: 'Updated By', width: 150 },
];

function Addresses(props) {
  const classes = useStyles();
  const { ptsPersonID, dataUpdate, showAddressDialog } = props;

  const [rows, setRows] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [filteredRows, setFilteredRows] = useState([]);
  const [selection, setSelection] = useState(null);
  const [columns, setColumns] = useState(defaultColumns);
  const perms = getFullPermissions('globals', 'People', 'any');
  const canEditPerson = perms.Edit;
  const mountedRef = useRef(true);

  useEffect(() => {
    ptsPersonID && getData();
  }, []);

  useEffect(() => {
    if (dataUpdate && dataUpdate.type === 'Address') getData();
  }, [dataUpdate]);

  const getData = async () => {
    try {
      const data = await getPeopleAddresses({ ptsPersonID });
      if (!mountedRef.current) return;
      setRows(processData(data.data));
      setLoaded(true);
    } catch (err) {
      props.handleError(err);
    }
  };

  const processData = (data) => {
    return data.map((row) => {
      const City = row.Address[0]?.City?.CityDescription;
      return {
        id: row.ptsAddressID,
        IsPrimary: row.IsPrimary,
        ...row.Address[0],
        created: row.Created,
        createdBy: row.CreatedBy,
        updated: row.Updated,
        updatedBy: row.UpdatedBy,
        City,
      };
    });
  };

  const onAddClick = () => props.showPeopleAddress({ ptsPersonID });

  const viewAddress = () => {
    const item = rows.find((row) => row.id === selection);
    if (!item) return;
    const { ptsAddressID } = item;
    showAddressDialog({ ptsAddressID });
  };

  const del = () => {
    const item = rows.find((row) => row.id === selection);
    props.showSpinner();

    deletePersonAddress(ptsPersonID, item.ptsAddressID)
      .then(() => {
        props.notify('Address Deleted', 'warning');
        props.notifyDataUpdate({ type: 'Address', data: {} });
        setSelection(null);
      })
      .catch(props.handleError)
      .finally(props.hideSpinner);
  };

  const renderActions = () => (
    <div className={classes.actions}>
      <Tooltip title="Print">
        <PrintsSearch2 title="Person Addresses" data={filteredRows} cols={columns} />
      </Tooltip>
      <Tooltip title="Add Address">
        <Fab
          className="ml-2"
          size="small"
          color="secondary"
          disabled={!canEditPerson}
          onClick={onAddClick}>
          <AddIcon />
        </Fab>
      </Tooltip>

      {selection !== null && (
        <>
          <Tooltip title="View Address">
            <Fab size="small" color="secondary" disabled={!canEditPerson} onClick={viewAddress}>
              <PinDrop />
            </Fab>
          </Tooltip>
          <Tooltip title="Delete Address">
            <Fab size="small" color="secondary" disabled={!canEditPerson} onClick={del}>
              <DeleteIcon />
            </Fab>
          </Tooltip>
        </>
      )}
    </div>
  );

  return (
    <div className={classes.wrap}>
      <div className={classes.filterWrap}>
        <div className={classes.filterWrap}>
          <QuickFilterSearch
            rows={rows}
            columns={columns}
            setFilteredRows={setFilteredRows}
            setData={props.setData}
            style={{ marginTop: 0, paddingBottom: 14 }}
          />
          {renderActions()}
        </div>
      </div>
      <RMSXGrid
        name="PeopleAddresses"
        columns={columns}
        setColumns={setColumns}
        rows={filteredRows}
        loading={!loaded}
        setSelection={setSelection}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    dataUpdate: state.dataUpdate,
  };
};

export default connect(mapStateToProps, {
  handleError,
  showSpinner,
  hideSpinner,
  showPeopleAddress,
  notifyDataUpdate,
  notify,
  showAddressDialog,
})(Addresses);
