/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { formatDate, sortObjArr } from '../../../../utils/functions';
import PerfectScrollbar from 'react-perfect-scrollbar';

const useStyles = makeStyles((theme) => ({
  notes: {
    textAlign: 'left',
    maxHeight: '550px',
    overflowY: 'auto',
  },
  textField: {
    fontSize: '0.9em',
  },
  note: {
    padding: '10px 15px 5px',
    borderBottom: '1px solid #D3D3D3',
    color: theme.palette.type === 'light' ? 'rgba(0, 0, 0, 0.87)' : '#d4d6d7',
    position: 'relative',
    '& p': {
      fontSize: 13,
      marginBottom: 5,
      paddingRight: 50,
    },
    '&:hover': {
      '& $rowActions': {
        opacity: 1,
      },
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& h4': {
      fontSize: '1em',
      marginBottom: '0.25em',
    },
  },
  actions: {
    padding: '5px 15px',
    textAlign: 'right',
    '& label': {
      marginBottom: 0,
    },
  },
  rowActions: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(3) + 2,
    opacity: 0,
    transition: 'opacity 0.3s',
  },
  editNote: {
    padding: theme.spacing(1, 2),
  },
}));

function Note(props) {
  const classes = useStyles();
  const { CreatedBy, Comment, Created } = props.note;

  return (
    <div className={classes.note}>
      <div className={classes.header}>
        <h4>{CreatedBy}</h4>
        <span>{formatDate(Created)}</span>
      </div>
      <p>{Comment}</p>
    </div>
  );
}

function NotesTab(props) {
  const { sortOption, notes } = props;
  const [sortedNotes, setSortedNotes] = useState([]);
  const classes = useStyles();
  const inputRef = useRef();

  useEffect(() => {
    inputRef.current && inputRef.current.focus();
  }, []);

  useEffect(() => {
    if (!notes) return;
    setSortedNotes(sortObjArr(notes, 'Created', sortOption));
  }, [props.notes, sortOption]);

  return (
    <div className={classes.notes}>
      <PerfectScrollbar style={{ maxHeight: window.innerHeight - 500 + 'px' }}>
        {sortedNotes.map((note, idx) => (
          <Note key={idx} note={note} />
        ))}
      </PerfectScrollbar>
    </div>
  );
}

export default NotesTab;
