export const errorTree = {
  partiesErrors: ['partiesErrors'],
  propertiesErrors: ['propertiesErrors'],
  offensesErrors: [
    'offenseErrors',
    'incOffendersErrors',
    'incVictimErrors',
    'incRelationshipErrors',
    'incPropertiesErrors',
    'arresteesErrors',
  ],
  evidenceErrors: [],
  narrativesErrors: [],
  incIncidentErrors: [],
};
