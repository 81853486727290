import React from 'react';

import { Fab, Tooltip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

export default function RMSAddButton(props) {
  const { tooltipText, onClick, disabled } = props;

  return (
    <Tooltip title={tooltipText || 'Add'}>
      <Fab
        disabled={disabled || false}
        style={{ marginLeft: '10px' }}
        size="small"
        color="secondary"
        aria-label="add">
        <AddIcon onClick={onClick} />
      </Fab>
    </Tooltip>
  );
}
