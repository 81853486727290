export const fields = {
  person: [
    {
      keyword: 'lastName',
      dbField: 'LastName',
      label: 'Last Name',
      type: 'Text',
      value: '',
    },
    {
      keyword: 'firstName',
      dbField: 'FirstName',
      label: 'First Name',
      type: 'Text',
      value: '',
    },
    {
      keyword: 'middleName',
      dbField: 'MiddleName',
      label: 'Mddle Name',
      type: 'Text',
      value: '',
    },
    {
      keyword: 'fullName',
      dbField: 'FullName',
      label: 'Full Name',
      type: 'Text',
      value: '',
    },
    { keyword: 'suffix', dbField: 'Suffix', label: 'Suffix', type: 'Text', value: '' },
    {
      keyword: 'age',
      dbField: 'Age',
      label: 'Age',
      type: 'Integer',
      value: '',
    },
    {
      keyword: 'ethnicity',
      dbField: 'Ethnicity',
      label: 'Ethnicity',
      type: 'Autocomplete',
      option: 'Description',
      codeData: 'codeEthnicity',
      value: '',
    },
    {
      keyword: 'oln',
      dbField: 'OLN',
      label: 'OLN',
      type: 'Text',
      value: '',
    },
    {
      keyword: 'ssn',
      dbField: 'SSN',
      label: 'SSN',
      type: 'Text',
      value: '',
    },
    {
      keyword: 'race',
      dbField: 'Race',
      label: 'Race',
      codeData: 'codeRace',
      option: 'Description',
      type: 'Autocomplete',
      value: '',
    },
    {
      keyword: 'sex',
      dbField: 'Sex',
      label: 'Sex',
      type: 'Autocomplete',
      option: 'Code',
      codeData: 'sexData',
      value: '',
    },
  ],
  address: [
    {
      keyword: 'fullAddressText',
      dbField: 'FullAddressText',
      label: 'Full Address',
      type: 'Text',
      value: '',
    },
    {
      keyword: 'country',
      dbField: 'Country',
      label: 'Country',
      type: 'Text',
      value: '',
    },
    {
      keyword: 'streetName',
      dbField: 'StreetName',
      label: 'Street Name',
      type: 'Text',
      value: '',
    },
    {
      keyword: 'state',
      dbField: 'State',
      label: 'State',
      option: 'Code',
      type: 'Autocomplete',
      codeData: 'codeStates',
      value: '',
    },
  ],
  item: [
    {
      keyword: 'name',
      dbField: 'Name',
      label: 'Name',
      type: 'Text',
      value: '',
    },
    {
      keyword: 'description',
      dbField: 'Description',
      label: 'Description',
      type: 'Text',
      value: '',
    },
    {
      keyword: 'make',
      dbField: 'Make',
      label: 'Make',
      codeData: 'codeItemMakes',
      option: 'Code',
      type: 'Autocomplete',
      value: '',
    },
    {
      keyword: 'model',
      dbField: 'Model',
      label: 'Model',
      codeData: 'codeItemModels',
      option: 'Code',
      type: 'Autocomplete',
      value: '',
    },
    {
      keyword: 'condition',
      dbField: 'Condition',
      label: 'Condition',
      codeData: 'codeItemConditions',
      option: 'Code',
      type: 'Autocomplete',
      value: '',
    },
  ],
  jewelry: [
    {
      keyword: 'description',
      dbField: 'Description',
      label: 'Description',
      type: 'Text',
      value: '',
    },
    {
      keyword: 'sex',
      dbField: 'Sex',
      label: 'Sex',
      type: 'Autocomplete',
      option: 'Code',
      codeData: 'sexData',
      value: '',
    },
    {
      keyword: 'make',
      dbField: 'Make',
      label: 'Make',
      codeData: 'codeItemMakes',
      option: 'Code',
      type: 'Autocomplete',
      value: '',
    },
    {
      keyword: 'model',
      dbField: 'Model',
      label: 'Model',
      codeData: 'codeItemModels',
      option: 'Code',
      type: 'Autocomplete',
      value: '',
    },
    {
      keyword: 'condition',
      dbField: 'Condition',
      label: 'Condition',
      type: 'Text',
      value: '',
    },
  ],
  firearm: [
    {
      keyword: 'description',
      dbField: 'Description',
      label: 'Description',
      type: 'Text',
      value: '',
    },
    {
      keyword: 'make',
      dbField: 'Make',
      label: 'Make',
      codeData: 'codeItemMakes',
      option: 'Code',
      type: 'Autocomplete',
      value: '',
    },
    {
      keyword: 'condition',
      dbField: 'Condition',
      label: 'Condition',
      codeData: 'codeItemConditions',
      option: 'Code',
      type: 'Autocomplete',
      value: '',
    },
    {
      keyword: 'model',
      dbField: 'Model',
      label: 'Model',
      codeData: 'codeItemModels',
      option: 'Code',
      type: 'Autocomplete',
      value: '',
    },
    {
      keyword: 'caliber',
      dbField: 'Caliber',
      label: 'Caliber',
      codeData: 'codeFirearmCaliber',
      option: 'Code',
      type: 'Autocomplete',
      value: '',
    },
  ],
  structure: [
    {
      keyword: 'description',
      dbField: 'Description',
      label: 'Description',
      type: 'Text',
      value: '',
    },
    {
      keyword: 'bathroomQty',
      dbField: 'BathroomQty',
      label: 'BathroomQty',
      type: 'Text',
      value: '',
    },
    {
      keyword: 'bedroomQty',
      dbField: 'BedroomQty',
      label: 'BedroomQty',
      type: 'Text',
      value: '',
    },
    {
      keyword: 'isResidence',
      dbField: 'IsResidence',
      label: 'Is Residence',
      type: 'Checkbox',
      value: '',
    },
  ],
  place: [
    {
      keyword: 'placeName',
      dbField: 'PlaceName',
      label: 'Place Name',
      type: 'Text',
      value: '',
    },
    {
      keyword: 'placeType',
      dbField: 'PlaceType',
      label: 'Place Type',
      codeData: 'codeMethodType',
      option: 'Code',
      type: 'Autocomplete',
      value: '',
    },
  ],
  substance: [
    {
      keyword: 'description',
      dbField: 'Description',
      label: 'Description',
      type: 'Text',
      value: '',
    },
    {
      keyword: 'substanceType',
      dbField: 'SubstanceType',
      label: 'Substance Type',
      codeData: 'codeSubstanceCategory',
      option: 'Code',
      type: 'Autocomplete',
      value: '',
    },
    {
      keyword: 'designator',
      dbField: 'Designator',
      label: 'Designator',
      type: 'Text',
      value: '',
    },
    {
      keyword: 'container',
      dbField: 'Container',
      label: 'Container',
      type: 'Text',
      value: '',
    },
  ],
  vehicle: [
    {
      keyword: 'brand',
      dbField: 'Brand',
      codeData: 'codeVehicleBrands',
      option: 'Code',
      label: 'Brand',
      type: 'Autocomplete',
      value: '',
    },
    {
      keyword: 'brander',
      dbField: 'Brander',
      codeData: 'codeVehicleBranders',
      option: 'Code',
      label: 'Brander',
      type: 'Autocomplete',
      value: '',
    },
    {
      keyword: 'commercial',
      dbField: 'Commercial',
      label: 'Commercial',
      type: 'Text',
      value: '',
    },
    {
      keyword: 'doorQuantity',
      dbField: 'DoorQuantity',
      label: 'Door Quantity',
      type: 'Text',
      value: '',
    },
    {
      keyword: 'make',
      dbField: 'Make',
      codeData: 'codeVehicleMakes',
      option: 'Code',
      label: 'Make',
      type: 'Autocomplete',
      value: '',
    },
    {
      keyword: 'model',
      dbField: 'Model',
      codeData: 'codeVehicleMakesModels',
      option: 'MakeCode',
      label: 'Model',
      type: 'Autocomplete',
      value: '',
    },
    {
      keyword: 'vin',
      dbField: 'VIN',
      label: 'VIN',
      type: 'Text',
      value: '',
    },
    {
      keyword: 'plateNumber',
      dbField: 'PlateNumber',
      label: 'Plate Number',
      type: 'Text',
      value: '',
    },
    {
      keyword: 'plateState',
      dbField: 'PlateState',
      codeData: 'codeStates',
      option: 'Code',
      label: 'Plate State',
      type: 'Autocomplete',
      value: '',
    },
  ],
  evidence: [
    {
      keyword: 'category',
      dbField: 'Category',
      label: 'Category',
      codeData: 'codeEvidenceCategory',
      option: 'Description',
      type: 'Autocomplete',
      value: '',
    },
    {
      keyword: 'evidenceType',
      dbField: 'EvidenceType',
      label: 'Evidence Type',
      codeData: 'codeEvidenceType',
      option: 'Code',
      type: 'Autocomplete',
      value: '',
    },
    {
      keyword: 'description',
      dbField: 'Description',
      label: 'Description',
      type: 'Text',
      value: '',
    },
    {
      keyword: 'daysInEvidence',
      dbField: 'DaysInEvidence',
      label: 'Days In Evidence',
      type: 'Integer',
      value: '',
    },
    {
      keyword: 'locationCollected',
      dbField: 'LocationCollected',
      label: 'Location Collected',
      type: 'Text',
      value: '',
    },
    {
      keyword: 'requiredAction',
      dbField: 'RequiredAction',
      label: 'Required Action',
      codeData: 'codeEvidenceRequiredAction',
      option: 'Code',
      type: 'Autocomplete',
      value: '',
    },
    {
      keyword: 'collectedBy',
      dbField: 'CollectedBy',
      label: 'Collected By',
      type: 'Text',
      value: '',
    },
    {
      keyword: 'submittedBy',
      dbField: 'SubmittedBy',
      label: 'Submitted By',
      type: 'Text',
      value: '',
    },
    {
      keyword: 'temporaryLocation',
      dbField: 'TemporaryLocation',
      label: 'Temporary Location',
      type: 'Text',
      value: '',
    },
    {
      keyword: 'condition',
      dbField: 'Condition',
      label: 'Condition',
      codeData: 'codeItemConditions',
      option: 'Code',
      type: 'Autocomplete',
      value: '',
    },
  ],
};
