import React from 'react';

import { Grid, makeStyles } from '@material-ui/core';

import DraggableDialog from 'components/RMSModals/Dialog';

import { compareDate, displayDateTime } from 'reducers/TimeReducer';

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& h4': {
      fontSize: '1em',
      marginBottom: '0.25em',
    },
    padding: '10px',
  },
}));

const RecordHistoryDialog = (props) => {
  const classes = useStyles();
  const {
    openEditHistoryDialog,
    onCloseEditHistoryDialog,
    title = 'Record History',
    createUpdateInfo,
    children,
  } = props;
  const { createdBy = '', created = '', updatedBy = '', updated = '' } = createUpdateInfo;

  return (
    <>
      <DraggableDialog
        open={openEditHistoryDialog}
        onClose={onCloseEditHistoryDialog}
        title={title}>
        <Grid container xs={12} spacing={2}>
          <Grid item xs={12}>
            <div className={classes.header}>
              <h4>Created By: {createdBy}</h4>
              <span>{displayDateTime(created)}</span>
            </div>
            {!compareDate(created, updated) && (
              <div className={classes.header}>
                <h4>Updated By: {updatedBy}</h4>
                <span>{displayDateTime(updated)}</span>
              </div>
            )}
          </Grid>
          <Grid item xs={12}>
            {children}
          </Grid>
        </Grid>
      </DraggableDialog>
    </>
  );
};

export default RecordHistoryDialog;
