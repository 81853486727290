import React, { useEffect, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { XGrid, GridToolbar } from '@material-ui/x-grid';
import { displayDateTime } from 'reducers/TimeReducer';
import { handleError } from 'reducers/ErrorReducer';
import { showSpinner, hideSpinner } from 'reducers/UiReducer';
import AddIcon from '@material-ui/icons/Add';
import Tooltip from 'components/Tooltip';
import Fab from '@material-ui/core/Fab';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import GetAppIcon from '@material-ui/icons/GetApp';
import CheckIcon from '@material-ui/icons/Check';
import {
  findAttachments,
  downloadAttachment,
  removeAttachment,
} from 'reducers/helpers/fileHelpers';
import { showEditAttachment, showAddAttachment } from 'reducers/PersonDialogsReducer';
import SearchHotKeys from 'components/RMSSearch/SearchHotKeys';
import PrintsSearch2 from 'components/RMSSearch/components/PrintsSearch2';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  wrap: {
    padding: theme.spacing(3),
  },
  gridWrap: {
    width: '100%',
    height: 600,
  },
  gridContainer: {
    '& .MuiDataGrid-root': {
      boxSizing: 'content-box', // Override box-sizing property
    },
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(2),
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
}));

const bool = (params) => (params.value ? <CheckIcon /> : <span></span>);

const columns = [
  { field: 'AttachmentID', headerName: 'AttachmentID', width: 150 },
  { field: 'FileName', headerName: 'File Name', width: 250 },
  { field: 'FileType', headerName: 'File Type', width: 150 },
  { field: 'Description', headerName: 'Description', width: 250 },
  // {
  //   field: 'ViewInWeb',
  //   headerName: 'View In Web',
  //   width: 150,
  //   format: "bool",
  //   renderCell: bool, hide: false
  // },
  // { field: 'codeAttachmentType', headerName: 'Attachment Type', width: 150 },
  {
    field: 'Attached',
    headerName: 'Attached',
    width: 200,
    valueFormatter: (params) => displayDateTime(params.value),
  },
  {
    field: 'Created',
    headerName: 'Created',
    width: 200,
    valueFormatter: (params) => displayDateTime(params.value),
  },
  { field: 'CreatedBy', headerName: 'Created By', width: 150 },
  {
    field: 'Updated',
    headerName: 'Updated',
    width: 200,
    valueFormatter: (params) => displayDateTime(params.value),
  },
  { field: 'UpdatedBy', headerName: 'Updated By', width: 150 },
  {
    field: 'IsDeleted',
    headerName: 'Is Deleted',
    width: 120,
    format: 'bool',
    renderCell: bool,
  },
  { field: 'DeletedBy', headerName: 'Deleted By', width: 120 },
  { field: 'DeletedIP', headerName: 'Deleted IP', width: 120 },
];

function RMSAttachments2(props) {
  const classes = useStyles();
  const { id, type, dataUpdate, permissions } = props;
  const { Create = true, Edit = true } = permissions || {};
  const [data, setData] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [selection, setSelection] = useState(null);
  const [kbdKey, setKbdKey] = useState(null);
  const mountedRef = useRef(true);
  const kbdTimeoutRef = useRef(0);
  const canEditPerson = Edit;

  useEffect(() => {
    getData();
    return () => (mountedRef.current = false);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!dataUpdate) return;
    if (dataUpdate.type === 'file-upload' && dataUpdate.data === type) getData();
    // eslint-disable-next-line
  }, [dataUpdate]);

  useEffect(() => {
    if (kbdKey) handleKeyPress();
    kbdTimeoutRef.current = setTimeout(() => {
      setKbdKey(null);
    }, 500);
  }, [kbdKey]);

  const getData = async () => {
    setLoaded(false);
    try {
      const data = await findAttachments(type, id);
      if (!mountedRef.current) return;
      setData(data.map((t) => ({ ...t, id: t.ptsAttachmentID })));
    } catch (err) {
      props.handleError(err);
    }
    setLoaded(true);
  };

  const handleKeyPress = () => {
    switch (kbdKey) {
      case 'F5':
        getData();
        break;
    }
  };

  const edit = () => {
    const item = data.find((a) => a.ptsAttachmentID === selection);
    if (!item) return;
    props.showEditAttachment(item);
  };

  const del = () => {
    if (!window.confirm('Are you sure you want to delete selected attachment?')) return;
    removeAttachment(selection).then(getData).catch(props.handleError);
  };

  const download = () => {
    const item = data.find((a) => a.ptsAttachmentID === selection);
    if (!item) return;
    downloadAttachment(selection, item.FileName).then().catch(props.handleError);
  };

  const add = () => {
    props.showAddAttachment({ id, type });
  };

  return (
    <div className={classes.wrap}>
      <div className={classes.actions}>
        <Tooltip title="Print">
          <PrintsSearch2 title="Person Attachments" data={data} cols={columns} />
        </Tooltip>
        <Tooltip title="Add Attachment">
          <Fab size="small" color="secondary" disabled={!canEditPerson} onClick={add}>
            <AddIcon />
          </Fab>
        </Tooltip>
        {selection !== null && (
          <>
            <Tooltip title="Download Attachment">
              <Fab size="small" color="secondary" onClick={download}>
                <GetAppIcon />
              </Fab>
            </Tooltip>
            <Tooltip title="Edit Attachment">
              <Fab size="small" color="secondary" disabled={!canEditPerson} onClick={edit}>
                <EditIcon />
              </Fab>
            </Tooltip>
            <Tooltip title="Delete Attachment">
              <Fab size="small" color="secondary" disabled={!canEditPerson} onClick={del}>
                <DeleteIcon />
              </Fab>
            </Tooltip>
          </>
        )}
      </div>
      <div className={clsx(classes.gridWrap, classes.gridContainer)}>
        <XGrid
          columns={columns}
          rows={data}
          loading={!loaded}
          rowHeight={38}
          disableMultipleSelection={true}
          showToolbar
          components={{ Toolbar: GridToolbar }}
          componentsProps={{ panel: { disablePortal: true } }}
          onSelectionModelChange={(newSelection) => {
            setSelection(newSelection.selectionModel[0]);
          }}
        />
      </div>
      <SearchHotKeys setKey={setKbdKey} allowedKeys={['F5']} />
    </div>
  );
}

const mapStateToProps = (state) => ({
  dataUpdate: state.dataUpdate,
});

export default connect(mapStateToProps, {
  showSpinner,
  hideSpinner,
  handleError,
  showEditAttachment,
  showAddAttachment,
})(RMSAttachments2);
