import React from 'react';

function SheriffIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" {...props}>
      <g transform="translate(0.000000,50.000000) scale(0.100000,-0.100000)" stroke="none">
        <path d="M214 433 l-29 -58 -67 0 c-77 -1 -90 -8 -62 -33 10 -10 28 -35 39 -55 19 -37 19 -37 0 -74 -11 -20 -29 -45 -39 -55 -28 -25 -15 -32 62 -33 l67 0 28 -55 c15 -30 32 -55 37 -55 5 0 22 25 37 55 l28 55 67 0 c77 1 90 8 62 33 -10 10 -28 35 -39 55 -19 37 -19 37 0 74 11 20 29 45 39 55 28 25 15 32 -62 33 l-67 0 -28 55 c-15 30 -31 56 -36 58 -4 1 -21 -23 -37 -55z m85 -97 c47 -25 63 -83 37 -135 -35 -66 -137 -66 -172 0 -47 91 44 182 135 135z" />
        <path d="M195 305 c-33 -32 -33 -78 0 -110 32 -33 78 -33 110 0 50 49 15 135 -55 135 -19 0 -40 -9 -55 -25z m95 -15 c11 -11 20 -29 20 -40 0 -26 -34 -60 -60 -60 -26 0 -60 34 -60 60 0 11 9 29 20 40 11 11 29 20 40 20 11 0 29 -9 40 -20z" />
        <path d="M220 275 c-15 -18 -10 -45 13 -59 34 -22 73 27 47 59 -16 19 -44 19 -60 0z" />
      </g>
    </svg>
  );
}

export default SheriffIcon;
