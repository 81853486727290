import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
// import { sortObjArr } from '../../../../utils/functions';
import PerfectScrollbar from 'react-perfect-scrollbar';

const useStyles = makeStyles((theme) => ({
  wrap: {
    maxHeight: 300,
    overflowY: 'auto',
  },
  row: {
    padding: '5px 15px 5px',
    borderBottom: '1px solid #D3D3D3',
    color: theme.palette.type === 'light' ? 'rgba(0, 0, 0, 0.87)' : '#d4d6d7',
    '& p': {
      fontSize: 13,
    },
    '&:hover': {
      '& $rowActions': {
        opacity: 1,
      },
    },
  },
  actions: {
    padding: '5px 15px',
    textAlign: 'right',
    '& label': {
      marginBottom: 0,
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& h4': {
      fontSize: '1em',
      minWidth: 130,
      marginBottom: 0,
    },
  },
  left: {
    display: 'flex',
    alignItems: 'center',
  },
  rowActions: {
    textAlign: 'right',
    opacity: 0,
    transition: 'opacity 0.3s',
    '& label': {
      marginBottom: 0,
    },
  },
  loading: {
    textAlign: 'center',
  },
}));

function LocationsTab(props) {
  const classes = useStyles();
  const { wsClient } = props;
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    if (!wsClient?.websocket) {
      return;
    }

    getLocations();
  }, []);

  const getLocations = async () => {
    const service = wsClient?.websocket.service('cad');
    service.timeout = 200000;

    const result = await service.get({
      type: 'event-locations',
      data: { ptsEventID: 68496 },
    });

    setLocations(result);
  };

  const renderLocations = () => {
    return (
      <>
        {locations.map((location) => (
          <div key={location.ptsLocationAddressID} className={classes.row}>
            <div className={classes.header}>
              <div className={classes.left}>
                <h4>
                  {location.FullAddressText} {location.IsPrimary && <strong>(primary)</strong>}
                </h4>
              </div>
            </div>
          </div>
        ))}
      </>
    );
  };

  return (
    <div className={classes.wrap}>
      <PerfectScrollbar style={{ maxHeight: window.innerHeight - 500 + 'px' }}>
        {renderLocations()}
      </PerfectScrollbar>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    wsClient: state.websocket,
  };
};

export default connect(mapStateToProps, {})(LocationsTab);
