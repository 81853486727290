import React from 'react';

import { Fab, Tooltip } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import ReadOnlyIcon from 'assets/favicon/ReadOnly.png';

export default function RMSEditButton(props) {
  const { onClick, disabled, size, tooltipText } = props;

  if (props?.entityFlag === 'tags' && props.viewOnly) {
    return '';
  }
  return (
    <Tooltip
      title={
        props.viewOnly ? tooltipText?.replace('Edit', 'View') || 'View' : tooltipText || 'Edit'
      }>
      <Fab
        size="small"
        disabled={disabled}
        color="secondary"
        aria-label="edit"
        onClick={onClick}
        className={props.className ? props.className : ''}>
        {props.viewOnly ? (
          <img src={ReadOnlyIcon} width={25} />
        ) : (
          <EditIcon style={{ fontSize: size }} />
        )}
      </Fab>
    </Tooltip>
  );
}
