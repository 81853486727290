import React, { useState } from 'react';
import { connect, useSelector } from 'react-redux';

import { makeStyles, Paper, Button, Tabs, Tab } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import Dialog from 'components/RMSDialog';
import Attachments from 'components/RMSAttachments/RMSAttachments2';
import EditVehicle from './EditVehicle';
import Registrations from './Registrations';
import Towing from './Towing';

import { closeEditVehicle } from 'reducers/PersonDialogsReducer';
import Bulletins from './Bulletins';
import { getFullPermissions } from 'reducers/PermissionsReducer';
import { getTitleForDialog } from 'global-components/ReadOnlyText';

const useStyles = makeStyles((theme) => ({
  content: {
    margin: -theme.spacing(3),
  },
}));

function EditVehicleDialog(props) {
  const classes = useStyles();
  const { vehicleData, type } = props;
  const { ptsVehicleID } = vehicleData;

  const [tab, setTab] = useState('edit'); // edit, registrations, towing, attachments
  const userAgency = useSelector((state) => state.user.userAgency);
  const permission = getFullPermissions('rms', 'Property', userAgency);
  const close = () => {
    props.closeEditVehicle();
  };

  const renderActions = () => {
    return (
      <>
        <Button color="primary" onClick={close}>
          <CloseIcon /> Close
        </Button>
      </>
    );
  };

  return (
    <Dialog
      toolbar
      onClose={close}
      title={getTitleForDialog(permission, 'Edit Person')}
      actions={renderActions()}
      fullScreen>
      <div className={classes.content}>
        <Paper square>
          <Tabs value={tab} onChange={(ev, val) => setTab(val)}>
            <Tab label="Edit Vehicle" value="edit" />
            {type === 'vehicle-towing-record' && (
              <Tab label="Registrations" value="registrations" />
            )}
            <Tab label="Towing" value="towing" />
            <Tab label="Attachments" value="attachments" />
            {type === 'vehicle-towing-record' && <Tab label="Bulletins" value="bulletins" />}
          </Tabs>
        </Paper>
        {tab === 'edit' && (
          <EditVehicle parentPermission={permission} ptsVehicleID={ptsVehicleID} />
        )}
        {tab === 'registrations' && type === 'vehicle-towing-record' && (
          <Registrations ptsVehicleID={ptsVehicleID} parentPermission={permission} />
        )}
        {tab === 'towing' && (
          <Towing ptsVehicleID={ptsVehicleID} parentPermission={permission} type={type} />
        )}
        {tab === 'attachments' && (
          <Attachments type="Vehicle" id={ptsVehicleID} parentPermission={permission} />
        )}
        {tab === 'bulletins' && type === 'vehicle-towing-record' && (
          <Bulletins vehicle={vehicleData} parentPermission={permission} />
        )}
      </div>
    </Dialog>
  );
}

export default connect(null, { closeEditVehicle })(EditVehicleDialog);
