import store from '../../../config/configureStore';
import { setIncFormActiveStep } from 'reducers/ui/UiFormReducer';

export const generateHandleEditIncident = (ptsIncidentId, stepperNumber) => {
  return (history) => {
    let editUrl = `/incidents/edit/${ptsIncidentId}/`;
    history.push(editUrl);
    // redux action for stepper change to offenses
    store.store.dispatch(setIncFormActiveStep(stepperNumber));
  };
};
