import React from 'react';
import RMSRoundedButton from 'components/RMSButtons/RMSRoundedReusableBtn';
import { makeStyles } from '@material-ui/core';
import { HistoryOutlined } from '@material-ui/icons';
import RecordHistoryDialog from './RMSModals/RecordHistoryDialog';

const useStyles = makeStyles((theme) => ({
  fabStyle: {
    backgroundColor: theme.palette.secondary.main,
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.colors.chipBg,
    },
  },
}));
const RecordHistory = (props) => {
  const classes = useStyles();
  const {
    disabled = false,
    tooltipText = 'Record History',
    showRecordHistory,
    setShowRecordHistory,
    handleCloseRecordHistoryDialog,
    createUpdateInfo,
    children,
  } = props;
  return (
    <>
      <RMSRoundedButton
        isBtnDisabled={disabled}
        tooltipText={tooltipText}
        className={classes.fabStyle}
        onClick={() => setShowRecordHistory(true)}>
        <HistoryOutlined />
      </RMSRoundedButton>

      <RecordHistoryDialog
        openEditHistoryDialog={showRecordHistory}
        onCloseEditHistoryDialog={handleCloseRecordHistoryDialog}
        createUpdateInfo={createUpdateInfo}>
        {children}
      </RecordHistoryDialog>
    </>
  );
};

export default RecordHistory;
