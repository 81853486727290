import store from '../../config/configureStore';

import { incSubjectVictimRelationshipSchema } from 'simpl-schema-validation/schema';
import { generateHandleEditOffense } from 'simpl-schema-validation/helpers/generateHandleEdit';
import { validateAllIncSubVicRelations } from '.';
import { getDataOfDictionaryOptions } from 'utils/functions';

export const validateIncRelationships = () => {
  const currentState = store.store.getState().incident;
  const { incRelationships, parties, incVictims, offenses, turnOfValidation } = currentState;
  if (turnOfValidation) return [];
  const validatedVictims = [];

  let validationErrors = [];

  for (let incRelationship of incRelationships) {
    const offensesRelations = [];

    incRelationships.forEach((relationship) => {
      // if (
      //   relationship.incRelationshipDetails.ptsOffenseId ===
      //   incRelationship.incRelationshipDetails?.ptsOffenseId
      // )
      offensesRelations.push(relationship.incRelationshipDetails?.values);
    });

    let victimPerson = parties.find(
      (p) =>
        p.ptsIncPersonId === incRelationship.incRelationshipDetails?.values?.relationship?.victimId
    );

    if (!victimPerson) continue;

    let incVictimPerson = incVictims.find(
      (victim) =>
        victim?.incVictimDetails?.ptsIncPersonId ===
        incRelationship.incRelationshipDetails?.values?.relationship?.victimId
      //   &&
      // victim?.incVictimDetails?.ptsOffenseId ===
      //   incRelationship.incRelationshipDetails?.ptsOffenseId
    );

    if (!incVictimPerson) continue;

    let victimFullName = `${victimPerson.PersonId} | ${
      victimPerson.personDetail.values.lastName || ''
    }, ${victimPerson.personDetail.values.firstName || ''} ${
      victimPerson.personDetail.values.middleName || ''
    }`;

    let victim = {
      victimId: incRelationship.incRelationshipDetails?.relationship?.victimId,
      age: victimPerson.personDetail.values.age,
      category: incVictimPerson.incVictimDetails.values.category,
    };

    let subjectPerson = parties.find(
      (p) =>
        p.ptsIncPersonId ===
        incRelationship.incRelationshipDetails?.values?.relationship?.offenderId
    );

    if (!subjectPerson) continue;

    let subject = {
      offenderId: incRelationship.incRelationshipDetails?.relationship?.offenderId,
      age: subjectPerson.personDetail.values.age,
    };

    let subjectFullName = `${subjectPerson.PersonId} | ${
      subjectPerson.personDetail.values.lastName || ''
    }, ${subjectPerson.personDetail.values.firstName || ''} ${
      subjectPerson.personDetail.values.middleName || ''
    }`;

    // const offense = offenses.find(
    //   (offense) =>
    //     offense.offenseDetails.ptsOffenseId ===
    //       incRelationship.incRelationshipDetails?.ptsOffenseId &&
    //     offense.offenseDetails.values.excludeOffense === false
    // );

    // if (!offense) continue;

    let objToValidate = {
      victim,
      subject,
      // offense: offense.offenseDetails,
      offenderDetails: subjectPerson.personDetail.values,
      victimDetails: victimPerson.personDetail.values,
      relationship: incRelationship.incRelationshipDetails?.values?.relationship?.relationship,
      incident: currentState.incident,
    };
    let keys = ['relationship', 'offenderSex'];

    if (
      !validatedVictims.includes(
        incRelationship.incRelationshipDetails?.values?.relationship?.victimId
      )
    ) {
      let victimOffenders = [];
      let victimSpouses = [];

      for (let relation of offensesRelations) {
        if (
          relation.relationship?.victimId ===
          incRelationship.incRelationshipDetails?.values?.relationship?.victimId
        ) {
          victimOffenders.push(relation.relationship?.offenderId);
          if (
            getDataOfDictionaryOptions(
              'codeVictimSubjectRelationship',
              'Code',
              'Category',
              relation.relationship?.relationship
            ) === 'SE'
          ) {
            victimSpouses.push(relation.relationship?.offenderId);
          }
        }
      }

      objToValidate = {
        ...objToValidate,
        victimOffenders,
        victimSpouses,
      };

      keys = [...keys, 'victimOffenders', 'victimSpouses'];

      validatedVictims.push(incRelationship.incRelationshipDetails?.values?.relationship?.victimId);
    }

    let incSubjectVictimRelationshipValidationContext = incSubjectVictimRelationshipSchema.newContext();
    let result = incSubjectVictimRelationshipValidationContext.validate(objToValidate, {
      keys,
    });

    if (!result) {
      incSubjectVictimRelationshipValidationContext.validationErrors().forEach((error) => {
        validationErrors.push({
          victimId: incRelationship.incRelationshipDetails?.relationship?.victimId,
          offenderId: incRelationship.incRelationshipDetails?.relationship?.offenderId,
          idType: 'incRelationship',
          errType: error.type,
          field: error.name,
          stepper: 3,
          screen: 'Offense Relationship',
          name: `Victim ${victimFullName} - Offender ${subjectFullName}`,
          message: incSubjectVictimRelationshipValidationContext.keyErrorMessage(error.name),
          ptsIncidentId: currentState.ptsIncidentId,
          ptsOffenseId: offenses[0].offenseDetails.ptsOffenseId,
          handleEdit: generateHandleEditOffense(
            currentState.ptsIncidentId,
            offenses[0].offenseDetails.ptsOffenseId,
            3
          ),
          identifier: `${currentState.ptsIncidentId}-${error.type.split('-').pop()}-${
            error.name
          }-OffenseRelationship--${offenses[0].offenseDetails.ptsOffenseId}-${
            incRelationship.incRelationshipDetails?.relationship?.offenderId
          }-${incRelationship.incRelationshipDetails?.relationship?.victimId}`,
        });
      });
    }
  }

  const allRelationErrors = validateAllIncSubVicRelations();
  return [...validationErrors, ...allRelationErrors];
};
