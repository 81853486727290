import { makeStyles, TextField } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Grid, Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';

import { useSelector } from 'react-redux';
import RMSDictionary from 'components/RMSAutoComplete/RMSDictonary';
import PlaceLookup from 'components/RMSPlaceLookup';
import { Autocomplete } from '@material-ui/lab';
import DownloadCSV from './DownloadCSV';
import { processParameters } from './reportDataProcessingHelper';
import PersonLookup2 from 'components/RMSPersonLookup/PersonLookup2';

const useStyles = makeStyles((theme) => ({
  reportContainer: {
    display: 'flex',
  },
  reportSelection: {
    flex: 0.3,
  },
  reportFilter: {
    flex: 0.7,
  },
  report: {
    paddingBottom: '4px',
  },
}));

const WarrantReportFilter = (props) => {
  const classes = useStyles();
  const { reportForm, handleReportFormChange, ReportID } = props;
  const formValue = reportForm[ReportID] || {};

  const codePartyRelationship = useSelector((state) => state.dictionary.codePartyRelationship);

  const [ptsStatueArray, setPtsStatueArray] = useState([]);
  const agencySettings = useSelector((state) => state.agencySettings.settings);
  const userAgency = useSelector((state) => state.user.userAgency);
  const ptsStatute = useSelector((state) => state.dictionary.ptsStatute);
  const ptsFBICode = useSelector((state) => state.dictionary.ptsFBICode);
  const partyTypes = useMemo(() => {
    return codePartyRelationship.filter(
      (p) => p.Entities !== null && p.Entities.includes('Warrant')
    );
  }, codePartyRelationship);

  const legacyExportSetting = agencySettings?.find(
    (item) => item.Path === `${userAgency}.RMS.AllowCitationLegacyExport`
  );
  const isLegacyExportEnable =
    legacyExportSetting && legacyExportSetting?.ValueBoolean === true ? true : false;

  useEffect(() => {
    let sortedArray = ptsStatute
      .filter((s) => s.Description !== '')
      .sort((a, b) => (a.Description > b.Description ? 1 : -1));
    let arr = [];

    sortedArray.forEach((s) => {
      let fbCode = ptsFBICode.find((fb) => fb.ptsFBICodeID === s.ptsFBICodeID)?.FBICode;
      s.FBICode = fbCode;
      arr.push(s);
    });
    if (isLegacyExportEnable) {
      arr = arr.filter((item) => item.StatuteID);
    }

    setPtsStatueArray(arr);
  }, []);

  return (
    <div className={classes.report}>
      <Grid container justifyContent={'space-between'}>
        <Grid item xs={6}>
          <Typography variant="subtitle1" className="py-2">
            {props.title}
          </Typography>
        </Grid>
        <Grid item xs={6} display={'flex'} justifyContent={'flex-end'}>
          <DownloadCSV
            ReportID={ReportID}
            filters={processParameters(ReportID, formValue, 'csv')}
          />
        </Grid>
      </Grid>
      <Grid container justify="left" alignItems="center" spacing={1}>
        <Grid item xs={6} md={2}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableOpenOnEnter
              autoComplete="off"
              fullWidth
              clearable
              size="small"
              inputVariant="outlined"
              showTodayButton={true}
              variant="dialog"
              format="MM/dd/yyyy"
              id="date-picker-inline-from"
              label="Issued From"
              defaultValue={null}
              value={formValue.issuedDateFrom || null}
              onChange={(date) => handleReportFormChange(ReportID, 'issuedDateFrom', date)}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={6} md={2}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableOpenOnEnter
              autoComplete="off"
              fullWidth
              clearable
              size="small"
              inputVariant="outlined"
              showTodayButton={true}
              variant="dialog"
              format="MM/dd/yyyy"
              id="date-picker-inline-from"
              label="Issued To"
              defaultValue={null}
              value={formValue.issuedDateTo || null}
              onChange={(date) => handleReportFormChange(ReportID, 'issuedDateTo', date)}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={12} md={4}>
          <RMSDictionary
            options="codeStatuteSeverity"
            multiple={true}
            value={formValue.offenseSeverities || []}
            label="Offense Severity"
            compact
            onChange={(ev, newVal) => {
              handleReportFormChange(ReportID, 'offenseSeverities', newVal);
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <RMSDictionary
            options="codeWarrantDispositions"
            multiple={true}
            value={formValue.dispositions || []}
            label="Disposition"
            compact
            onChange={(ev, newVal) => {
              handleReportFormChange(ReportID, 'dispositions', newVal);
            }}
          />
        </Grid>
        <Grid item xs={12} md={7}>
          <Autocomplete
            multiple
            size="small"
            autoComplete
            autoSelect={false}
            autoHighlight
            getOptionLabel={(option) => {
              return `${option.Description} | ${option.Code} | ${option.FBICode}`;
            }}
            options={ptsStatueArray}
            key={formValue?.offenses || ''}
            value={formValue?.offenses || []}
            onChange={(event, newValue) => {
              handleReportFormChange(ReportID, 'offenses', newValue);
            }}
            renderInput={(params) => (
              <TextField
                autoComplete="off"
                size="small"
                {...params}
                label="Search Offense"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={5}>
          <PlaceLookup
            multiple={true}
            // className={classes.item2}
            onChange={(value) => handleReportFormChange(ReportID, 'heldBy', value)}
            label="Held By"
          />
        </Grid>

        <Grid item xs={12} md={7}>
          <PersonLookup2
            onPersonChange={(newVal) => {
              handleReportFormChange(ReportID, 'parties', newVal, true);
            }}
            origin="party"
            multiple={true}
            handleError={(err) => console.log({ err })}
          />
        </Grid>
        <Grid item xs={12} md={5}>
          <RMSDictionary
            options={partyTypes}
            multiple={true}
            value={formValue.partyTypes || []}
            label="Party Type"
            compact
            onChange={(ev, newVal) => {
              handleReportFormChange(ReportID, 'partyTypes', newVal);
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default WarrantReportFilter;
