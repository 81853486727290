import React from 'react';
import { connect } from 'react-redux';

import {
  Card,
  CardContent,
  Button,
  FormControl,
  TextField,
  CircularProgress,
  InputAdornment,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Alert, AlertTitle } from '@material-ui/lab';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';

import splashScreen from '../assets/images/login-splash.jpg';
import IconRms from '../assets/images/icon-RMS.svg';
import ptsLogo from '../assets/images/PTS-logo.png';

import { notify } from 'reducers/NotifierReducer';
import { useParams, Redirect } from 'react-router';
import { validateResetPassHash, resetPass } from '../reducers/UserReducer';
import { passValidationErrors } from 'utils/functions';

const useStyles = makeStyles((theme) => ({
  pageWrap: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: `url(${splashScreen})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  card: {
    width: 480,
    maxWidth: '95%',
    background: 'fff',
    '& img': {
      width: '100%',
    },
  },
  content: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  branding: {
    width: '100%',
    height: 200,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#0051a0',
    '& img': {
      width: 150,
      height: 'auto',
    },
  },
  branding2: {
    width: '100%',
    textAlign: 'center',
    padding: '25px 0 5px',
    '& img': {
      height: 50,
      width: 'auto',
    },
  },
  actions: {
    textAlign: 'center',
    marginTop: 30,
    '& button': {
      background: '#0051a0',
      color: '#fff',
      paddingLeft: '4em',
      paddingRight: '4em',
      textTransform: 'uppercase',
      '&:hover': {
        background: '#021090',
      },
    },
  },
  passReset: {
    textAlign: 'right',
    '& span': {
      fontSize: 13,
      color: '#808080',
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
  version: {
    position: 'absolute',
    bottom: '0.5em',
    left: '1em',
    lineHeight: 1,
  },
  invalid: {
    textAlign: 'center',
    fontSize: '1.5em',
    margin: '2em 0',
  },
  alert: {
    marginBottom: 32,
    '&.MuiAlert-standardError': {
      color: 'rgb(84, 18, 18)',
      backgroundColor: 'rgb(250, 234, 234)',
    },
  },
}));

const PageResetPassword = (props) => {
  const classes = useStyles();
  const { appVersion } = props.appInfo;
  const { websocket } = props;
  const [loading, setLoading] = React.useState(false);
  const [view, setView] = React.useState('waiting'); // waiting, valid, invalid, success
  const [newPass, setNewPass] = React.useState('');
  const [confirmNewPass, setConfirmNewPass] = React.useState('');
  const { hash } = useParams();
  const [error, setError] = React.useState(false);
  const [errors, setErrors] = React.useState([]);
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const disabled = Boolean(error || !newPass || loading || newPass !== confirmNewPass);
  const repeatPassErr = newPass === confirmNewPass ? false : "Passwords don't match";

  React.useEffect(() => {
    if (!websocket.websocket) return;
    validateHash();
    // eslint-disable-next-line
  }, [websocket]);

  React.useEffect(() => {
    const validationErrors = passValidationErrors('', newPass);
    if (newPass && validationErrors.length) {
      setError(validationErrors[0]);
    } else {
      if (error) setError(false);
    }
    // eslint-disable-next-line
  }, [newPass]);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const validateHash = async () => {
    try {
      const valid = await validateResetPassHash(hash);
      setView(valid ? 'valid' : 'invalid');
    } catch (err) {
      console.log(err);
    }
  };

  const resetPassword = async () => {
    setLoading(true);
    try {
      await resetPass(hash, newPass);
      props.notify('Password updated!', 'success');
      setNewPass('');
      setConfirmNewPass('');
      setErrors([]);
      setView('success');
    } catch (err) {
      if (err.errors && err.errors.length) {
        setErrors(err.errors);
      } else {
        const message = err.message ? err.message : 'Error resetting password';
        setErrors([message]);
      }
    }
    setLoading(false);
  };

  const handleNewPasswordChange = (event) => {
    setNewPass(event.target.value);
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmNewPass(event.target.value);
  };

  const renderPasswordRestForm = () => {
    return (
      <>
        <div className="mb-3">
          <FormControl size="small" className="w-100">
            <TextField
              autoComplete="hidden"
              size="small"
              required
              variant="outlined"
              label="Password"
              fullWidth
              placeholder="Enter New Password"
              type={showPassword ? 'text' : 'password'}
              value={newPass}
              error={error}
              helperText={error}
              onChange={handleNewPasswordChange}
              disabled={view === 'waiting' || loading}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}>
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
        </div>
        <div className="mb-3">
          <FormControl size="small" className="w-100">
            <TextField
              autoComplete="hidden"
              size="small"
              required
              variant="outlined"
              label="Confirm Password"
              fullWidth
              placeholder="Repeat New Password"
              type={showConfirmPassword ? 'text' : 'password'}
              value={confirmNewPass}
              onChange={handleConfirmPasswordChange}
              error={repeatPassErr}
              helperText={repeatPassErr}
              disabled={view === 'waiting' || loading}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowConfirmPassword}
                      onMouseDown={handleMouseDownPassword}>
                      {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
        </div>
        <div className={classes.actions}>
          <Button
            type="submit"
            variant="contained"
            size="large"
            className="my-2"
            onClick={resetPassword}
            disabled={disabled}>
            Reset Password
          </Button>
        </div>
        {loading && <CircularProgress style={{ marginLeft: 8 }} />}
      </>
    );
  };

  const renderHashInvalid = () => {
    return <p className={classes.invalid}>The link is invalid or expired</p>;
  };

  if (!hash || hash.length !== 36 || view === 'success') return <Redirect to="/" />;

  return (
    <div className={classes.pageWrap}>
      <Card className={classes.card}>
        <div className={classes.branding}>
          <img src={IconRms} alt="MOBILE icon" />
        </div>
        <div className={classes.branding2}>
          <img src={ptsLogo} alt="PTS logo" />
        </div>
        <CardContent>
          {errors.length > 0 && (
            <Alert severity="error" className={classes.alert}>
              <AlertTitle>Password validation errors</AlertTitle>
              <ul>
                {errors.map((err, idx) => (
                  <li key={idx}>{err}</li>
                ))}
              </ul>
            </Alert>
          )}

          {view === 'valid' ? renderPasswordRestForm() : renderHashInvalid()}

          {/* <p style={{ fontWeight: 'bold' }}> v. {appVersion}</p> */}
          <div className={classes.version}> v. {appVersion}</div>
        </CardContent>
      </Card>
    </div>
  );
};

const mapStateToProps = (state) => ({
  themeMode: state.theme.mode,
  appInfo: state.appInfo,
  websocket: state.websocket,
});

export default connect(mapStateToProps, {
  notify,
})(PageResetPassword);
