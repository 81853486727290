import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import AddEntityFormReducer from './AddEntityFormReducer';
import CodeCrudFormReducer from './CodeCrudFormReducer';
import ThemeReducer from './ThemeReducer';
import WebsocketReducer from './WebsocketReducer';
import NotifierReducer from './NotifierReducer';
import UiReducer from './UiReducer';
import UiListReducer from './ui/UiListReducer';
import UiMenuReducer from './ui/UiMenuReducer';
import UiLayoutReducer from './ui/UiLayoutReducer';
import UiFormReducer from './ui/UiFormReducer';
import UiImageReducer from './ui/UiImageReducer';
import UiModalReducer from './ui/UiModalReducer';
import UserReducer from './UserReducer';
import AppInfoReducer from './AppInfoReducer';
import IncReducer from './IncReducer';
import RecordsReducer from './RecordsReducer';
import DuplicateReducer from './DuplicateReducer';
import TimeReducer from './TimeReducer';
import CitationReducer from './CitationReducer';
import PermissionsReducer from './PermissionsReducer';
import WorkFlowReducer from './WorkFlowReducer';
import IBRSReducer from './IBRSReducer';
import AgencySettingsReducer from './AgencySettingsReducer';
import PersonDialogsReducer from './PersonDialogsReducer';
import MapReducer from './MapReducer';
import KeyboardReducer from './KeyboardReducer';
import DataUpdateReducer from './DataUpdateReducer';
import CodeAgencyReducer from './dictionaries/CodeAgencyReducer';
import DictionaryReducer from './DictionaryReducer';
import UIHomeScreenReducer from './ui/UIHomeScreenReducer';
import EventsReducer from './EventsReducer';
import EvidenceReducer from './EvidenceReducer';
import ZonesReducer from './ZonesReducer';
import BulletinReducer from './BulletinReducer';
import ReportsReducer from './ReportsReducer';
import AttachmentReducer from './AttachmentReducer';

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    theme: ThemeReducer,
    websocket: WebsocketReducer,
    notifier: NotifierReducer,
    ui: UiReducer,
    uiList: UiListReducer,
    uiForm: UiFormReducer,
    uiMenu: UiMenuReducer,
    uiImage: UiImageReducer,
    uiLayout: UiLayoutReducer,
    uiModal: UiModalReducer,
    user: UserReducer,
    appInfo: AppInfoReducer,
    incident: IncReducer,
    codeAgencies: CodeAgencyReducer,
    addEntityForm: AddEntityFormReducer,
    codeCrudForm: CodeCrudFormReducer,
    records: RecordsReducer,
    duplicateForm: DuplicateReducer,
    dictionary: DictionaryReducer,
    permissions: PermissionsReducer,
    uiHomeScreen: UIHomeScreenReducer,
    time: TimeReducer,
    citation: CitationReducer,
    workflow: WorkFlowReducer,
    ibrs: IBRSReducer,
    events: EventsReducer,
    evidence: EvidenceReducer,
    agencySettings: AgencySettingsReducer,
    dialogs: PersonDialogsReducer,
    map: MapReducer,
    keyboard: KeyboardReducer,
    dataUpdate: DataUpdateReducer,
    zones: ZonesReducer,
    unreadBulletins: BulletinReducer,
    reports: ReportsReducer,
    attachments: AttachmentReducer,
  });

export default createRootReducer;
