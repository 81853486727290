import React, { useState } from 'react';
import { connect } from 'react-redux';

import { makeStyles, Button } from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import LocalPrintshopIcon from '@material-ui/icons/LocalPrintshop';
import CheckIcon from '@material-ui/icons/Check';

import Dialog from 'components/RMSDialog';
import BulletinContent from './BulletinContent';

import { printRef } from 'utils/functions';

import { closeBulletinDetails } from 'reducers/PersonDialogsReducer';
import { markBulletinRead } from 'reducers/BulletinReducer';
import { getFullPermissions } from 'reducers/PermissionsReducer';
import { getTitleForDialog } from 'global-components/ReadOnlyText';

const useStyles = makeStyles(() => ({
  pageWrap: {
    width: 800,
    maxWidth: '100%',
  },
  iframe: {
    border: 'none',
    overflow: 'hidden',
    width: 1,
    height: 1,
  },
}));

function BulletinDetails(props) {
  const { data } = props;
  const { ptsAlertID, Read } = data;
  const classes = useStyles();
  let title = `Bulletin Details`;

  const [ref, setRef] = useState(null);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const bulletinePermission = getFullPermissions('cad', 'Alerts', 'any');

  if (data.AlertID) title += `- ${data.AlertID}`;

  const close = () => {
    props.closeBulletinDetails();
  };

  const print = () => {
    printRef(ref, title);
  };

  const markRead = () => {
    props.markBulletinRead(ptsAlertID);
    setBtnDisabled(true);
  };

  const renderActions = () => {
    return (
      <>
        <Button
          color="primary"
          variant="contained"
          onClick={markRead}
          disabled={btnDisabled || Read || !bulletinePermission.Edit}>
          <CheckIcon /> Read
        </Button>
        <Button color="primary" variant="contained" autoFocus onClick={print}>
          <LocalPrintshopIcon /> Print
        </Button>
        <Button color="primary" onClick={close}>
          <CloseIcon /> Close
        </Button>
      </>
    );
  };

  return (
    <Dialog
      maxWidth="xs"
      toolbar
      onClose={close}
      title={getTitleForDialog(bulletinePermission, title)}
      actions={renderActions()}>
      <div className={classes.pageWrap}>
        <BulletinContent data={data} title={title} setRef={setRef} />
        <iframe id="print-iframe" className={classes.iframe}></iframe>
      </div>
    </Dialog>
  );
}

export default connect(null, {
  closeBulletinDetails,
  markBulletinRead,
})(BulletinDetails);
