import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextFieldPlus from 'components/RMSTextField/TextFieldPlus';
import store from 'config/configureStore';
import { showEditPlace } from 'reducers/PersonDialogsReducer';
import { IconButton, TextField } from '@material-ui/core';
import { AddIcon } from 'evergreen-ui';
import { getFullPermissions } from 'reducers/PermissionsReducer';

const getService = (serviceName = 'cad') => {
  let service;
  try {
    const state = store.store.getState();
    const client = state.websocket.websocket;
    service = client.service(serviceName);
    service.timeout = 20000;
  } catch (err) {
    throw new Error('Error, service not ready.');
  }
  return service;
};

const useStyles = makeStyles((theme) => ({
  textField: {
    margin: '0 4px 8px',
  },
  activeWarrant: {
    background: theme.palette.error.main,
    color: theme.card.bg,
    width: 'calc(100% + 32px)',
    display: 'inline-block',
    padding: '8px 16px',
    margin: '-8px -16px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: 14,
    '& em': {
      fontStyle: 'normal',
      fontSize: 12,
    },
  },
  option: {
    fontSize: 14,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    '& em': {
      fontStyle: 'normal',
      fontSize: 12,
    },
  },
}));

const btnStyle = {
  margin: '0 8px 0 2px',
  padding: 0,
};

function PlaceLookup(props) {
  const { onChange, ptsPlaceID, dialogs, dataUpdate, multiple } = props;
  const classes = useStyles();
  const [searchMatch, setSearchMatch] = useState([]);
  const [Value, setValue] = useState(multiple ? [] : null);
  const label = props.label ? props.label : 'Place Lookup';
  const mountedRef = useRef(true);
  const throttleRef = useRef(0);
  const addEnabled = dialogs.editPlace === null;
  const placesPermission = getFullPermissions('globals', 'Places', 'any');

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (ptsPlaceID !== undefined) {
      if (ptsPlaceID) {
        getPlace(ptsPlaceID);
      } else {
        setValue(multiple ? [] : null);
      }
    }
    // eslint-disable-next-line
  }, [ptsPlaceID]);

  useEffect(() => {
    if (!dataUpdate) return;
    const { type, data } = dataUpdate;
    if (type === 'Place' && data?.action === 'Create') getPlace(data.ptsPlaceID);
    // eslint-disable-next-line
  }, [dataUpdate]);

  const onSearchSelect = (ev, selection) => {
    setValue(selection);
    onChange(selection);
  };

  const getOptionSelected = (option, value) => {
    const found = searchMatch.find((p) => p.ptsPlaceID === value.ptsPlaceID);
    if (found) {
      return option.ptsPlaceID === value.ptsPlaceID;
    } else {
      return true;
    }
  };

  const renderOption = (option) => {
    const { PlaceName, PlaceID } = option;
    return (
      <span className={classes.option}>
        <span>{PlaceName} </span>
        <em>{PlaceID}</em>
      </span>
    );
  };

  const renderLabel = (option) => {
    const PlaceName = option.PlaceName ? option.PlaceName : '';
    return `${PlaceName}`;
  };

  const onInputChange = (ev, val) => {
    clearTimeout(throttleRef.current);
    throttleRef.current = setTimeout(() => {
      if (val.length > 2) searchPlace(val);
    }, 1000);
  };

  const searchPlace = async (search) => {
    try {
      const service = getService('place-lookup');
      const result = await service.find({ query: { search } });
      if (!mountedRef.current) return;
      if (result) {
        setSearchMatch(result);
      }
    } catch (err) {
      console.log('Place lookup error: ', err);
    }
  };

  const getPlace = async (ptsPlaceID) => {
    try {
      const service = getService('place-lookup');
      const place = await service.get(ptsPlaceID);
      if (!mountedRef.current) return;
      if (place) {
        setSearchMatch([place]);
        setValue(multiple ? [...Value, place] : place);
        onSearchSelect(null, place);
      }
    } catch (err) {
      console.log('Place lookup error: ', err);
    }
  };

  const onAdd = () => {
    props.showEditPlace();
  };

  const startAdornment =
    addEnabled && placesPermission.Created ? (
      <IconButton style={btnStyle} onClick={onAdd}>
        <AddIcon fontSize="small" />
      </IconButton>
    ) : undefined;

  return (
    <Autocomplete
      multiple={multiple}
      disabled={props.disabled}
      // className={props.className}
      fullWidth
      autoHighlight
      autoSelect={false}
      options={searchMatch}
      value={Value}
      getOptionLabel={renderLabel}
      style={props.style}
      size="small"
      onChange={onSearchSelect}
      onInputChange={onInputChange}
      renderInput={(params) => {
        params.label = label;
        params.variant = 'outlined';

        return multiple ? (
          <TextField
            {...params}
            autoComplete="off"
            inputProps={{
              ...params.inputProps,
              autoComplete: 'off',
            }}
            label={label}
            variant="outlined"
            placeholder=""
            size="small"
            fullWidth
          />
        ) : (
          <TextFieldPlus
            {...params}
            InputProps={{
              ...params.InputProps,
              autoComplete: 'off',
              startAdornment,
            }}
          />
        );
      }}
      getOptionSelected={getOptionSelected}
      renderOption={renderOption}
    />
  );
}

const mapStateToProps = (state) => ({
  dialogs: state.dialogs,
  dataUpdate: state.dataUpdate,
});

export default connect(mapStateToProps, { showEditPlace })(PlaceLookup);
