import React, { useState } from 'react';
import { connect } from 'react-redux';
import { closeWarrants } from 'reducers/PersonDialogsReducer';
import Dialog from 'components/RMSDialog';
import { handleError } from 'reducers/ErrorReducer';
import WarrantCard from 'components/WarrantCard';
import { getPersonWarrant } from 'reducers/WarrantReducer';

function WarrantDialog(props) {
  const { Name, warrants, ptsPersonId } = props.data;

  const [warrantsData, setWarrantsData] = useState([]);

  React.useEffect(() => {
    const getWarrantsData = async (ptsPersonId) => {
      const warrants = await getPersonWarrant(ptsPersonId);
      if (!warrants || !warrants.length) return;
      setWarrantsData(warrants);
    };
    if (ptsPersonId) {
      getWarrantsData(ptsPersonId);
    } else {
      setWarrantsData(warrants);
    }
  }, []);

  const close = () => {
    props.closeWarrants();
  };

  return (
    <Dialog toolbar onClose={close} title={`Warrants: ${Name}`}>
      <WarrantCard warrants={warrantsData} />
    </Dialog>
  );
}

export default connect(null, {
  closeWarrants,
  handleError,
})(WarrantDialog);
