import React from 'react';
import { connect } from 'react-redux';
import Dialog from 'components/RMSDialog';
import { closeAddZone } from 'reducers/PersonDialogsReducer';
import Zone from './Zone';

function AddZoneDialog(props) {
  const close = () => props.closeAddZone();

  return (
    <Dialog toolbar fullScreen onClose={close} title="Add Zone">
      <Zone close={close} />
    </Dialog>
  );
}

export default connect(null, {
  closeAddZone,
})(AddZoneDialog);
