import React from 'react';
import { useSelector } from 'react-redux';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  makeStyles,
  Grid,
} from '@material-ui/core';

const useToolbarStyles = makeStyles((theme) => ({
  closeButton: {
    color: theme.button.color,
  },
}));

export default function RMSConfirmationModal(props) {
  const {
    title,
    text,
    saveBtnName,
    btnName,
    noBtnName,
    handleSubmit,
    open,
    closeDialog,
    handleModalSave,
    btnDisabled = false,
    children,
  } = props;
  const classes = useToolbarStyles();
  const savingDisabled = useSelector((state) => state.uiMenu.savingDisabled);

  return (
    <Dialog open={open} onClose={closeDialog} maxWidth={'md'}>
      <DialogTitle style={{ background: '#1976d2', color: 'white' }}>
        {<Typography variant="h5">{title}</Typography>}
      </DialogTitle>
      <DialogContent style={{ display: 'flex', alignItems: 'center' }}>
        <Grid container>
          <Grid container direction="column" item xs={12}>
            {children}
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2" gutterBottom component="div">
              {text}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <div className="mb-1">
          {saveBtnName && (
            <Button
              onClick={handleModalSave}
              disabled={btnDisabled || savingDisabled}
              style={
                btnDisabled || savingDisabled
                  ? { background: '#f1f1f1', color: 'black', width: '80px', marginRight: '5px' }
                  : { background: '#1976d2', color: '#fff', width: '80px', marginRight: '5px' }
              }>
              {saveBtnName || 'Save'}
            </Button>
          )}
          {btnName && (
            <Button
              onClick={handleSubmit}
              style={{
                background: '#1976d2',
                color: '#fff',
                width: '80px',
                marginRight: '5px',
              }}
              variant="contained">
              {btnName}
            </Button>
          )}
          <Button
            onClick={closeDialog}
            style={{ background: '#1976d2', color: '#fff', width: '80px' }}>
            {noBtnName || 'Cancel'}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}
