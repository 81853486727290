import maleBodyOutline from '../../assets/images/rms-imgs/male-body-outline.jpg';
import femaleBodyOutline from '../../assets/images/rms-imgs/female-body-outline.jpg';

const SET_DV_MALE_VICTIM_CONDITION = 'SET_DV_MALE_VICTIM_CONDITION';
const SET_DV_MALE_SUSPECT_CONDITION = 'SET_DV_MALE_SUSPECT_CONDITION';
const SET_DV_FEMALE_VICTIM_CONDITION = 'SET_DV_FEMALE_VICTIM_CONDITION';
const SET_DV_FEMALE_SUSPECT_CONDITION = 'SET_DV_FEMALE_SUSPECT_CONDITION';

export const setDvMaleVictimCondition = (value) => {
  return async (dispatch) => {
    return dispatch({ type: SET_DV_MALE_VICTIM_CONDITION, payload: value });
  };
};

export const setDvMaleSuspectCondition = (value) => {
  return async (dispatch) => {
    return dispatch({ type: SET_DV_MALE_SUSPECT_CONDITION, payload: value });
  };
};

export const setDvFemaleVictimCondition = (value) => {
  return async (dispatch) => {
    return dispatch({ type: SET_DV_FEMALE_VICTIM_CONDITION, payload: value });
  };
};

export const setDvFemaleSuspectCondition = (value) => {
  return async (dispatch) => {
    return dispatch({ type: SET_DV_FEMALE_SUSPECT_CONDITION, payload: value });
  };
};

export default function reducer(
  state = {
    dvMaleVictimConditionState: maleBodyOutline,
    dvMaleSuspectConditionState: maleBodyOutline,
    dvFemaleVictimConditionState: femaleBodyOutline,
    dvFemaleSuspectConditionState: femaleBodyOutline,
  },
  action
) {
  switch (action.type) {
    case SET_DV_MALE_VICTIM_CONDITION:
      return { ...state, dvMaleVictimConditionState: action.payload };
    case SET_DV_MALE_SUSPECT_CONDITION:
      return { ...state, dvMaleSuspectConditionState: action.payload };
    case SET_DV_FEMALE_VICTIM_CONDITION:
      return { ...state, dvFemaleVictimConditionState: action.payload };
    case SET_DV_FEMALE_SUSPECT_CONDITION:
      return { ...state, dvFemaleSuspectConditionState: action.payload };
  }
  return state;
}
