import React from 'react';

function DisclaimerIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" {...props}>
      <g transform="translate(0.000000,50.000000) scale(0.100000,-0.100000)" stroke="none">
        <path d="M267 494 c-4 -4 -7 -56 -7 -116 0 -139 -18 -144 -22 -5 -3 94 -4 102 -23 102 -19 0 -20 -8 -23 -107 -1 -60 -7 -108 -12 -108 -5 0 -10 37 -12 83 -3 74 -5 82 -23 82 -19 0 -20 -8 -23 -183 l-2 -183 29 -30 c27 -26 35 -29 99 -29 84 0 95 7 181 121 64 84 69 104 38 123 -16 9 -24 5 -52 -28 l-34 -39 -3 139 c-3 131 -4 139 -23 139 -18 0 -20 -8 -23 -97 -1 -54 -7 -98 -12 -98 -5 0 -11 53 -12 117 -2 97 -6 118 -18 121 -9 1 -19 0 -23 -4z m-24 -312 c16 -14 20 -13 42 8 28 27 35 8 8 -22 -15 -17 -15 -19 0 -36 27 -30 21 -49 -7 -23 l-25 23 -27 -21 c-35 -28 -44 -18 -14 14 l23 25 -21 23 c-38 40 -23 46 21 9z" />
      </g>
    </svg>
  );
}

export default DisclaimerIcon;
