import React from 'react';

import { Fab, Tooltip } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';

export default function RMSSaveButton(props) {
  const { onClick, disabled, size, tooltipText } = props;

  return (
    <Tooltip title={tooltipText || 'Save'}>
      <Fab
        size="small"
        disabled={disabled}
        color="secondary"
        aria-label="edit"
        className={props.className ? props.className : ''}>
        <SaveIcon onClick={onClick} style={{ fontSize: size }} />
      </Fab>
    </Tooltip>
  );
}
