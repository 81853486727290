import React from 'react';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

import CloseIcon from '@material-ui/icons/Close';

import Dialog from 'components/RMSDialog';
import AddressEdit from 'components/RMSRecordsDialogs/Address/AddressEdit';

import { closePlaceAddressEdit } from 'reducers/PersonDialogsReducer';
import { notifyDataUpdate } from 'reducers/DataUpdateReducer';

const useStyles = makeStyles((theme) => ({}));

function EditPlaceAddressDialog(props) {
  const classes = useStyles();
  const { ptsAddressID } = props;

  const close = () => {
    props.notifyDataUpdate({ type: 'edit-place-address' });
    props.closePlaceAddressEdit();
  };

  const renderActions = () => {
    return (
      <>
        <Button color="primary" onClick={close}>
          <CloseIcon /> Close
        </Button>
      </>
    );
  };

  return (
    <Dialog toolbar onClose={close} title="Address Edit" actions={renderActions()}>
      <AddressEdit ptsAddressID={ptsAddressID} />
    </Dialog>
  );
}

export default connect(null, { closePlaceAddressEdit, notifyDataUpdate })(EditPlaceAddressDialog);
