import React, { useState, useEffect } from 'react';

import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Alert from '@material-ui/lab/Alert';
import { connect } from 'react-redux';
import { Grid, FormControlLabel, Checkbox } from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';

import { form } from './formDeatils';

import { updateFormOpen, incrementCount } from 'reducers/AddEntityFormReducer';
import { addDictionaryValue, updateDictionaryValue } from 'reducers/DictionaryReducer';

const useStyles = makeStyles(() => ({
  dialogBoxTitle: {
    background: '#1976d2',
  },
}));

function RMSAddModal(props) {
  /** region Component Variables and Props */
  const classes = useStyles();
  const {
    addEntityForm,
    updateFormOpen,
    wsClient,
    addDictionaryValue,
    dictionary,
    updateDictionaryValue,
  } = props;

  const client = wsClient.websocket;
  const [serviceName, setServiceName] = useState(null);
  const [fields, setFields] = useState([]);
  const [modalForm, setModalForm] = useState([]);
  const [button, setButton] = useState(null);
  const [primaryKey, setPrimaryKey] = useState(null);
  const [alertBox, setAlertBox] = useState({
    visibility: false,
    message: '',
    severity: '',
  });

  const { setTextField, open, label } = addEntityForm;
  const [parentCodeValue, setParentCodeValue] = useState(null);

  const addCode = (newEntity) => {
    addDictionaryValue(newEntity, serviceName);
  };

  const updateCode = (serviceName, primaryKey, keyValue, value) => {
    let codesArray = dictionary[serviceName];
    let index = codesArray.findIndex((v) => v[primaryKey] === keyValue);

    codesArray[index] = value;

    updateDictionaryValue(codesArray, serviceName);
  };

  const deleteCode = (serviceName, primaryKey, keyValue) => {
    let codesArray = dictionary[serviceName];
    codesArray = codesArray.filter((v) => v[primaryKey] !== keyValue);

    updateDictionaryValue(codesArray, serviceName);
  };

  const addEntity = async (newEntity) => {
    try {
      for (const key in newEntity) {
        if (newEntity[key].length === 0) throw Error('empty_string');
      }
      console.log(newEntity);
      let ifAlreadyExist = await client.service(serviceName.toLowerCase()).find({
        query: {
          Code: newEntity.Code,
        },
      });

      let response;
      if (ifAlreadyExist.total > 0) {
        response = await client.service(serviceName.toLowerCase()).patch(newEntity.Code, newEntity);
      } else {
        response = await client.service(serviceName.toLowerCase()).create(newEntity);
      }

      addCode(newEntity);
      if (setTextField) setTextField(newEntity);
      resetModal(); // called to close the modal.
    } catch (err) {
      if (err.errors && err.errors[0]?.validatorKey?.toLowerCase() === 'not_unique') {
        setAlertBox({
          visibility: true,
          message: `${label} must be unique.`,
          severity: 'error',
        });
      } else if (err.message === 'empty_string') {
        setAlertBox({
          visibility: true,
          message: `You must fill all the fields.`,
          severity: 'warning',
        });
      } else {
        setAlertBox({
          visibility: true,
          message: `Something went wrong.`,
          severity: 'error',
        });
      }
    }
  };

  const editEntity = async (newForm) => {
    try {
      const oldForm = addEntityForm.form;
      for (const key in newForm) {
        if (newForm[key].length === 0) throw Error('empty_string');
      }
      // TODO: SET P_K IN THE BEGINNING.
      const response = await client
        .service(serviceName.toLowerCase())
        .patch(oldForm[primaryKey], newForm);
      // getCode();
      updateCode(serviceName, primaryKey, oldForm[primaryKey], newForm);
      resetModal();
      if (addEntityForm.resetTextField) {
        addEntityForm.resetTextField();
      }
    } catch (err) {
      setAlertBox({
        visibility: true,
        message: `Something went wrong.`,
        severity: 'error',
      });
    }
  };

  const deleteEntity = async () => {
    // console.log('deleteEntity()');
    try {
      // const key = "00";
      // console.log('modalForm["00"]', modalForm["00"]);
      const response = await client.service(serviceName.toLowerCase()).remove(modalForm['00']);
      // getCode();
      deleteCode(serviceName, primaryKey, modalForm['00']);
      resetModal(); // called to close the modal.
      if (addEntityForm.resetTextField) {
        addEntityForm.resetTextField();
      }
    } catch (err) {
      console.log(err);
      setAlertBox({
        visibility: true,
        message: `Something went wrong.`,
        severity: 'error',
      });
    }
  };

  const handleSubmit = () => {
    resetAlertbox();
    if (addEntityForm.type === null || addEntityForm.type === 'add') {
      let newEntity = {};
      fields.forEach((row, i) => {
        row.forEach((col, j) => {
          const key = i + '' + j;
          newEntity[col[0]] = modalForm[key];
        });
      });
      // to fix the not null violation error.
      newEntity = {
        ...newEntity,
        IsDefault: true,
        IsActive: true,
        IsDeleted: false,
        // the following added for code unit
        isDefault: true,
        isActive: true,
        isDeleted: false,
      };
      if (serviceName === 'codeEvidenceStorageSubLocation' && parentCodeValue) {
        newEntity['ParentCode'] = parentCodeValue;
      }
      addEntity(newEntity);
    } else if (addEntityForm.type === 'edit') {
      let newEntity = {};
      fields.forEach((row, i) => {
        row.forEach((col, j) => {
          const key = i + '' + j;
          newEntity[col[0]] = modalForm[key];
        });
      });
      editEntity(newEntity);
    } else if (addEntityForm.type === 'delete') {
      deleteEntity();
    }
  };

  const resetAlertbox = () => {
    setAlertBox({
      visibility: false,
      message: '',
      severity: '',
    });
  };

  const handleChange = (input, row, col) => {
    let key = row + '' + col;
    setModalForm((p) => {
      return {
        ...p,
        [key]: input,
      };
    });
  };

  const resetModal = () => {
    updateFormOpen();
    setFields([]);
    setModalForm([]);
    setServiceName(null);
    setButton(null);
    setPrimaryKey(null);
  };

  useEffect(() => {
    resetAlertbox();
    let found = false;
    form.forEach((entity) => {
      if (
        serviceName === null &&
        !found &&
        (addEntityForm.serviceName
          ? addEntityForm.serviceName.toLowerCase() === entity.serviceName &&
            entity?.label === label
          : entity?.label === label)
      ) {
        let tempFields = [];
        setPrimaryKey(entity.fields[0][0]);
        setServiceName(addEntityForm.serviceName);
        const { parentCode } = addEntityForm.extraValues || {};
        setParentCodeValue(parentCode || null);
        found = true;
        for (let i = 0; i < entity.fields.length; ++i) {
          tempFields.push([...entity.fields[i]]);
        }

        const tempModalForm = [];
        for (let i = 0; i < tempFields.length; ++i) {
          for (let j = 0; j < tempFields[i].length; ++j) {
            tempFields[i][j] = [tempFields[i][j], i, j];
            let key = i + '' + j;
            tempModalForm.push({ [key]: '' });
          }
        }
        if (modalForm.length === 0) {
          tempModalForm.forEach((obj) => {
            setModalForm((p) => {
              return {
                ...p,
                ...obj,
              };
            });
          });
        }
        if (fields.length === 0) setFields(tempFields);
        if (
          (addEntityForm.type === 'edit' || addEntityForm.type === 'delete') &&
          addEntityForm.form != null
        ) {
          for (let i = 0; i < tempFields.length; ++i) {
            for (let j = 0; j < tempFields[i].length; ++j) {
              handleChange(
                addEntityForm.form[tempFields[i][j][0]],
                tempFields[i][j][1],
                tempFields[i][j][2]
              );
            }
          }
        }
        switch (addEntityForm.type) {
          case 'edit':
            setButton('Update');
            break;
          case 'delete':
            setButton('Remove');
            break;
          case 'add':
            setButton('Add');
            break;
          default:
            setButton('Add');
        }
      }
    });
  }, [open]);

  return (
    <div>
      {fields.length > 0 && (
        <Dialog open={open} maxWidth="lg" aria-labelledby="form-dialog-title">
          <DialogTitle
            className={clsx(
              classes.dialogBoxTitle,
              ' d-flex justify-content-between align-items-center'
            )}
            id="alert-dialog-title">
            {button === 'Add' && (
              <span style={{ fontSize: '16px', color: 'white' }}>{`Add ${label}`}</span>
            )}
            {button === 'Update' && (
              <span style={{ fontSize: '16px', color: 'white' }}>{`Update ${label}`}</span>
            )}
            {button === 'Remove' && (
              <span
                style={{
                  fontSize: '16px',
                  color: 'white',
                }}>{`Remove ${label}`}</span>
            )}
          </DialogTitle>
          <DialogContent style={{ padding: '34px 40px 60px' }}>
            <Grid container spacing={3}>
              {button === 'Remove' && `Are you sure you want to remove this ${label}?`}
              {button !== 'Remove' &&
                fields.map((attribute) => {
                  return (
                    <Grid container spacing={3}>
                      {attribute.map((item) => {
                        let key = item[1] + '' + item[2];
                        const edit = item[1] === 0 && item[2] === 0 && button === 'Update';
                        return (
                          <Grid item xs>
                            {item[0] !== 'Priority' ? (
                              <TextField
                                autoComplete="hidden"
                                size="small"
                                required={item[0] === 'ParentCode'}
                                error={item[0] === 'ParentCode' && !parentCodeValue}
                                disabled={edit || item[0] === 'ParentCode'}
                                autoFocus
                                margin="dense"
                                id="name"
                                label={item[0]}
                                type="text"
                                value={item[0] === 'ParentCode' ? parentCodeValue : modalForm[key]}
                                fullWidth
                                onChange={(e) => handleChange(e.target.value, item[1], item[2])}
                              />
                            ) : (
                              <FormControlLabel
                                className="mt-3"
                                control={
                                  <Checkbox
                                    key={key}
                                    checked={modalForm[key]}
                                    onChange={(e) =>
                                      handleChange(e.target.checked, item[1], item[2])
                                    }
                                  />
                                }
                                label="Priority"
                              />
                            )}
                          </Grid>
                        );
                      })}
                    </Grid>
                  );
                })}
            </Grid>
            <Alert
              hidden={!alertBox.visibility}
              style={{ marginLeft: '20px', marginRight: '20px', marginTop: '0px' }}
              severity={alertBox.severity}>
              {alertBox.message}
            </Alert>
          </DialogContent>
          <DialogActions>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid
                  container
                  direction="row"
                  justify="flex-end"
                  alignItems="center"
                  style={{ padding: 5, margin: '-15px 0px 10px' }}>
                  <Button onClick={resetModal} style={{ marginRight: 10 }}>
                    {button === 'Remove' ? 'No' : 'Close'}
                  </Button>
                  <Button
                    onClick={handleSubmit}
                    style={{ background: '#1976d2', color: '#fff' }}
                    variant="contained">
                    {button === 'Remove' ? 'Yes' : button}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      )}

      {fields.length === 0 && (
        <Dialog open={open} maxWidth="lg" aria-labelledby="form-dialog-title">
          <DialogContent>
            <DialogContentText>No such label found in the dataset.</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={() => updateFormOpen({ label })}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  wsClient: state.websocket,
  addEntityForm: state.addEntityForm,
  dictionary: state.dictionary,
});

export default connect(mapStateToProps, {
  updateFormOpen,
  incrementCount,
  addDictionaryValue,
  updateDictionaryValue,
})(RMSAddModal);
