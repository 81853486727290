import React, { useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { ExitToApp } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import { timeout } from 'workbox-core/_private';
import { getService } from 'reducers/service';
import { useSelector } from 'react-redux';
import { reportNameMapper } from './reportDataProcessingHelper';

const DownloadCSV = ({ ReportID, filters }) => {
  const service = getService('ssrs-csv');
  const [data, setData] = useState([]);
  const userAgency = useSelector((state) => state.user.userAgency);
  const csvRef = useRef(null);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const reportingOfficer = useSelector((state) => state.user?.userData?.user?.FullName);

  useEffect(() => {
    if (dataLoaded && csvRef.current) {
      csvRef.current.link.click();
      setDataLoaded(false);
    }
  }, [dataLoaded]);
  const getData = async () => {
    try {
      // Make api call here
      setDataLoaded(false);
      setLoading(true);
      const data = { ...filters, ReportingOfficer: reportingOfficer, AgencyId: userAgency };

      const res = await service.get(ReportID, { query: { ...data } });
      setData(res);

      //calling done function
    } finally {
      setDataLoaded(true);
      setLoading(false);
    }
  };

  return (
    <>
      <IconButton disabled={loading} color="secondary" title="Export to CSV" onClick={getData}>
        <ExitToApp fontSize="large" style={{ backgroundColor: 'transparent' }} />
      </IconButton>
      {<CSVLink ref={csvRef} data={data} filename={reportNameMapper(ReportID)} />}
    </>
  );
};

export default DownloadCSV;
