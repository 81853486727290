import React from 'react';
import { connect } from 'react-redux';
import Dialog from 'components/RMSDialog';
import { closeAddPerson } from 'reducers/PersonDialogsReducer';
import PersonTab from './PersonTab.js';
import {
  resetDuplicateList,
  setDuplicateFoundBtn,
  setDuplicateModal,
} from 'reducers/ui/UiModalReducer.js';

function AddPersonDialog(props) {
  const { data } = props;
  const close = () => {
    props.closeAddPerson();
    props.setDuplicateModal(false);
    props.setDuplicateFoundBtn(false);
    resetDuplicateList();
  };

  return (
    <Dialog toolbar fullScreen onClose={close} title="Add Person">
      <PersonTab close={close} origin={data?.origin} />
    </Dialog>
  );
}

export default connect(null, {
  closeAddPerson,
  setDuplicateFoundBtn,
  setDuplicateModal,
})(AddPersonDialog);
