import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function iconagencytypepoliceIcon(props) {
  return (
    <SvgIcon viewBox="0 0 50 50" {...props}>
      <path d="M44.652 19.727c-.543-2.059-1.05-4.004-1.05-6.727 0-3.39 2.25-7.473 2.27-7.512a1 1 0 0 0-.165-1.195l-4-4a1.03 1.03 0 0 0-1.414 0c-1.305 1.305-4.207 2.145-7.398 2.145-3.11 0-5.864-.82-7.188-2.145a1 1 0 0 0-1.414 0c-1.324 1.324-4.078 2.145-7.184 2.145-3.195 0-6.097-.84-7.402-2.145a1 1 0 0 0-1.414 0l-4 4a1 1 0 0 0-.164 1.195c.02.04 2.27 4.11 2.27 7.512 0 2.781-.512 4.73-1.055 6.793C4.714 22.18 4 24.883 4 29.199c0 12.188 11.121 16.672 17.098 19.082 1.476.598 2.754 1.114 3.304 1.524.18.129.387.195.598.195.21 0 .418-.066.598-.195.55-.41 1.828-.926 3.304-1.524C34.88 45.871 46 41.387 46 29.2c0-4.332-.719-7.062-1.348-9.472Zm-12.55 14.972L25 30.602l-7.102 4.097 1.704-8-6.102-5.398 8.102-.801L25 13l3.3 7.5 8.102.8-6.101 5.4Z" />
    </SvgIcon>
  );
}

export default iconagencytypepoliceIcon;
