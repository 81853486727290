import React, { useState, useRef, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';

import { Fab, makeStyles, Button } from '@material-ui/core';

import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';

import XGrid3 from 'components/RMSXGrid';
import Dialog from 'components/RMSDialog';
import Tooltip from 'components/Tooltip';
import SOPFilter from 'components/RMSRecordsDialogs/Address/SOPFilter';
import QuickFilterSearch from 'components/RMSSearch/components/QuickFilterSearch';

import { handleError } from 'reducers/ErrorReducer';
import { getPeopleSops } from 'reducers/SearchReducer';
import { showSpinner, hideSpinner } from 'reducers/UiReducer';
import { displayDateTime } from 'reducers/TimeReducer';
import { savePeopleSOP, deletePeopleSOP } from 'reducers/PersonReducer';
import { closePlaceSOP, showPlaceSOP } from 'reducers/PersonDialogsReducer';

const useStyles = makeStyles((theme) => ({
  wrap: {
    padding: theme.spacing(3),
  },
  gridWrap: {
    width: '100%',
    boxSizing: 'border-box',
    height: 600,
  },
  filterWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    marginBottom: 8,
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(2),
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
  fileInput: {
    display: 'none',
  },
}));

const defaultColumns = [
  {
    field: 'SOPID',
    headerName: 'Title',
    valueGetter: (params) => params.row.SOP.SOPID,
    width: 200,
  },
  {
    field: 'DispatcherMessage',
    headerName: 'Dispatcher Message',
    valueGetter: (params) => params.row.SOP.DispatcherMessage,
  },
  {
    field: 'AlertMessage',
    headerName: 'Alert Message',
    valueGetter: (params) => params.row.SOP.AlertMessage,
  },
  {
    field: 'IsPagingMessageUsed',
    headerName: 'Is Paging Used',
    valueGetter: (params) => params.row.SOP.IsPagingMessageUsed,
  },
  {
    field: 'ShowPopupNotification',
    headerName: 'Popup Notification',
    valueGetter: (params) => params.row.SOP.ShowPopupNotification,
  },
  {
    field: 'Created',
    headerName: 'Created',
    width: 200,
    valueFormatter: (params) => displayDateTime(params.value),
  },
  { field: 'CreatedBy', headerName: 'Created By', width: 150 },
  {
    field: 'Updated',
    headerName: 'Updated',
    width: 200,
    valueFormatter: (params) => displayDateTime(params.value),
  },
  { field: 'UpdatedBy', headerName: 'Updated By', width: 150 },
  'IsDeleted',
  'Deleted',
  'DeletedBy',
  'DeletedIP',
];

const PlaceSops = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { ptsPlaceID, addPlaceSOP } = props;

  const [rows, setRows] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [filteredRows, setFilteredRows] = useState([]);
  const [selection, setSelection] = useState(null);
  const [columns, setColumns] = useState(defaultColumns);
  const [selectedSOP, setSelectedSOP] = useState(null);

  const user = useSelector((state) => state.user);

  const mountedRef = useRef(true);

  useEffect(() => {
    ptsPlaceID && getData();
  }, []);

  const getData = async () => {
    try {
      const data = await getPeopleSops(ptsPlaceID, 'Place');
      if (!mountedRef.current) return;
      setRows(processData(data.data));
      setLoaded(true);
    } catch (err) {
      props.handleError(err);
    }
  };

  const processData = (data) => {
    return data.map((row, idx) => {
      return {
        ...row,
        id: idx,
      };
    });
  };

  const add = () => {
    dispatch(showPlaceSOP({ ptsPlaceID: ptsPlaceID }));
  };

  const close = () => {
    dispatch(closePlaceSOP());
  };

  const save = async () => {
    const username = user.userData?.user.Username;
    dispatch(showSpinner());
    const saveData = {
      ptsParentID: ptsPlaceID,
      ParentType: 'Place',
      ptsSOPID: selectedSOP,
      CreatedBy: username,
    };
    try {
      await savePeopleSOP(saveData);
      getData();
    } catch (err) {
      dispatch(handleError(err));
    }
    dispatch(hideSpinner());
    close();
  };

  const deleteSOP = async () => {
    if (!window.confirm('Are you sure you want to remove this record?')) return;
    const item = rows.find((row) => row.id === selection);
    const updatedData = rows.filter((row) => row.id !== selection);
    setRows(updatedData);
    if (!item) return;
    dispatch(showSpinner());
    try {
      await deletePeopleSOP({
        ptsParentID: ptsPlaceID,
        ParentType: 'Place',
        ptsSOPID: item.ptsSOPID,
      });
      getData();
    } catch (err) {
      dispatch(handleError(err));
    }
    dispatch(hideSpinner());
  };

  const formContent = () => {
    return <SOPFilter selectSOP={setSelectedSOP} />;
  };

  const renderActions = () => {
    return (
      <>
        <Button
          color="primary"
          variant="contained"
          autoFocus
          disabled={!props.canSave}
          onClick={save}>
          <SaveIcon /> Save
        </Button>
        <Button color="primary" onClick={close}>
          <CloseIcon /> Close
        </Button>
      </>
    );
  };

  return (
    <div className={classes.wrap}>
      <div className={classes.filterWrap}>
        <div className={classes.filterWrap}>
          <QuickFilterSearch rows={rows} columns={columns} setFilteredRows={setFilteredRows} />
        </div>
        <div className={classes.actions}>
          <Tooltip title="Add SOP">
            <Fab size="small" color="secondary" disabled={!props.canSave} onClick={add}>
              <AddIcon />
            </Fab>
          </Tooltip>

          {selection !== null && (
            <Tooltip title="Delete SOP">
              <Fab size="small" color="secondary" disabled={!props.canSave} onClick={deleteSOP}>
                <DeleteIcon />
              </Fab>
            </Tooltip>
          )}
        </div>
      </div>

      {addPlaceSOP && (
        <Dialog toolbar onClose={close} title="Add SOP" actions={renderActions()}>
          {formContent()}
        </Dialog>
      )}
      <XGrid3
        name="placeSops"
        columns={columns}
        setColumns={setColumns}
        rows={filteredRows}
        loading={!loaded}
        setSelection={setSelection}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  addPlaceSOP: state.dialogs.addPlaceSOP,
});

export default connect(mapStateToProps, {
  handleError,
  showSpinner,
  hideSpinner,
})(PlaceSops);
