import React from 'react';

function AnkleBracelet(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="50"
      viewBox="0 0 50 50"
      preserveAspectRatio="xMidYMid meet"
      {...props}>
      <g transform="translate(0.000000,50.000000) scale(0.100000,-0.100000)" stroke="none">
        <path d="M302 408 c3 -21 8 -23 63 -23 55 0 60 2 63 23 3 21 0 22 -63 22 -63 0 -66 -1 -63 -22z" />
        <path d="M284 346 c-3 -13 -4 -35 -2 -47 3 -23 8 -24 81 -27 72 -3 79 -1 84 18 3 11 14 20 24 20 11 0 19 5 19 10 0 6 -9 10 -19 10 -11 0 -23 9 -26 20 -6 18 -15 20 -80 20 -70 0 -75 -1 -81 -24z" />
        <path d="M480 281 c0 -5 5 -13 10 -16 6 -3 10 -2 10 4 0 5 -4 13 -10 16 -5 3 -10 2 -10 -4z" />
        <path d="M289 224 c-17 -21 -94 -59 -147 -74 -32 -8 -42 -16 -40 -28 3 -15 22 -17 163 -19 101 -1 164 2 172 9 16 13 16 37 1 88 l-13 40 -61 0 c-44 0 -65 -5 -75 -16z" />
      </g>
    </svg>
  );
}

export default AnkleBracelet;
