import { getService } from './service';
import { addCreatedUpdatedBy } from 'utils/functions';

export const personLookup = (search) => {
  if (!search) return Promise.resolve([]);
  const service = getService('person-lookup');
  return service.find({ query: { search } });
};

export const personAddressLookup = (search) => {
  if (!search) return Promise.resolve([]);
  const service = getService('person-address-lookup');
  return service.find({ query: { search } });
};

export const savePeopleSOP = async (data) => {
  const saveData = addCreatedUpdatedBy(data);
  const service = getService('record-person-sops');
  return await service.create(saveData);
};

export const deletePeopleSOP = async (query) => {
  const service = getService('rms-assigned-sops');
  return await service.remove(query);
};
