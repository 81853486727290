import { formatPersonFullName } from 'utils/functions';
import store from '../config/configureStore';
import { fields } from './_dev/recordFilterFields';
import {
  updateEvidenceCustody,
  updateEvidenceItem,
  addNewEvidenceStorage,
  updateEvidenceStorage,
  updateEvidenceDisposition,
  addNewEvidenceDisposition,
  addNewEvidenceItem,
  addNewEvidenceCustody,
  addTags,
  processDataForEvidenceCustody,
  addNewEvidenceCustodyBulk,
  updateEvidenceCurrentLocation,
} from './helpers/incHelpers';
import {
  upsertPerson,
  addPersonCredential,
  updatePersonCredential,
  addPersonContactMethod,
  updatePersonContactMethod,
  addPersonDNASample,
  updatePersonDNASample,
  upsertPersonEmployment,
  addPersonFeature,
  updatePersonFeature,
  addPersonVehicleRegistration,
  updatePersonVehicleRegistration,
  addPersonName,
  updatePersonName,
  addPersonPhoto,
  upsertPersonAssociate,
  addPersonAddress,
  updatePersonAddress,
  upsertPersonResource,
  removeRecordEntity,
  updatePersonPhoto,
  addPlace,
  updatePlace,
} from './helpers/recordHelper';
import { formatSaveDate, getCurrentDate, displayDateTime } from './TimeReducer';
import { updateIncEvidenceItemForm } from './IncReducer';
import { setEvidenceCustodyIdForReport } from './EvidenceReducer';

export const FORM_DATA = 'RECORD/FORM_DATA';
export const SET_RECORD_ACTIVE = 'RECORD/SET_RECORD_ACTIVE';
export const SET_RECORD_ADVANCE_SEARCH_KEYWORD = 'RECORD/SET_RECORD_ADVANCE_SEARCH_KEYWORD';
export const SET_RECORD_TYPE = 'RECORD/SET_RECORD_TYPE';
export const SET_RECORD_PERSON_TAGS = 'RECORD/SET_RECORD_PERSON_TAGS';

export const setFormData = (payload) => {
  return async (dispatch) => {
    dispatch({ type: FORM_DATA, payload });
  };
};

export const setRecordActive = (payload) => {
  return async (dispatch) => {
    dispatch({ type: SET_RECORD_ACTIVE, payload });
  };
};

export const setRecordAdvanceSearchKeyword = (payload) => {
  return async (dispatch) => {
    dispatch({ type: SET_RECORD_ADVANCE_SEARCH_KEYWORD, payload });
  };
};

export const removeRecord = (entity, id, id2) => {
  return async () => {
    await removeRecordEntity(entity, id, id2);
  };
};

/**
 * Add Custody to Selected Evidence in bulk way
 */

export const addCustodyToSelectedEvidence = async (
  custodyDetails,
  ptsEvidenceIds,
  evidenceList
) => {
  const evidences = store.store.getState().evidence.activeEvidences;
  const processList = [];
  ptsEvidenceIds.map(async (ptsEvidenceId) => {
    const formDeatils = prepareCustodyData(custodyDetails, ptsEvidenceId, evidenceList);
    const evidence = evidences.find((evidence) => evidence.ptsEvidenceID === ptsEvidenceId);
    const ptsIncidentID = evidence.ptsIncidentID || 0;
    const data = processDataForEvidenceCustody(formDeatils, ptsIncidentID, ptsEvidenceId);
    processList.push(data);
  });
  return await addNewEvidenceCustodyBulk(processList);
};
const prepareCustodyData = (formData, id, list) => {
  const evRecord = list.find((record) => record.ptsEvidenceID === id);
  let newForm = { ...formData };

  if (
    evRecord &&
    evRecord?.evidenceCustodyDetails &&
    evRecord?.evidenceCustodyDetails?.length > 0
  ) {
    const list = evRecord?.evidenceCustodyDetails;

    const latestAction = list[0];
    // console.log('latestAction', latestAction);
    const lastCustodyToValue = latestAction.incEvidenceCustodyDetails.values.custodyTo;
    if (lastCustodyToValue) {
      newForm = {
        ...newForm,
        values: {
          ...newForm.values,
          custodyFrom: newForm.values.custodyFrom || lastCustodyToValue,
        },
      };
    }
  }
  return newForm;
};
/**
 * Add Storage to Selected Evidence in bulk way
 */
export const addStorageToSelectedEvidence = async (custodyDetails, ptsEvidenceIds) => {
  return ptsEvidenceIds.map(async (ptsEvidenceId) => {
    const res = await addNewEvidenceStorage(custodyDetails, 0, ptsEvidenceId);
    console.log('storage created to Evidence in bulk way => ', res);
  });
};

/**
 * Upsert Evidence for Records Only
 */
export const upsertEvidence = async (currentState, dispatch) => {
  // Need to change current date time
  const user = store.store.getState().user.userData?.user.Username;
  const currentUser = store.store.getState().user.userData?.user;
  const currentdate = formatSaveDate(getCurrentDate());
  const selectedEvidence = store.store.getState().evidence.selectedEvidence;

  let newEvidence = {
    itemRecordDetail: { values: {} },
    evidenceParties: [],
    evidenceCustodyDetails: [],
    evidenceStorageDetails: [],
    evidenceDispositionDetail: { values: {} },
  };

  if (selectedEvidence?.itemRecordDetail?.ptsEvidenceId) {
    newEvidence = selectedEvidence;
  }

  if (Object.keys(currentState.evidenceItemForm.values).length !== 0) {
    if (currentState.evidenceItemForm.ptsEvidenceId) {
      const res = await updateEvidenceItem(
        currentState.evidenceItemForm,
        currentState.evidenceItemForm.values?.incidentId || 0
      );
      currentState.evidenceItemForm.evidenceId = res.EvidenceID;

      currentState.evidenceItemForm.values.updated = displayDateTime(res.Updated);
      currentState.evidenceItemForm.values.updatedBy = res.UpdatedBy;

      newEvidence.itemRecordDetail = currentState.evidenceItemForm;

      console.log('Evidence item updated => ', res);
    } else {
      const res = await addNewEvidenceItem(
        currentState.evidenceItemForm,
        currentState.evidenceItemForm.values?.incidentId || 0
      );
      currentState.evidenceItemForm.ptsEvidenceId = res.ptsEvidenceID;
      currentState.evidenceItemForm.evidenceId = res.EvidenceID;

      currentState.evidenceItemForm.values.created = currentdate;
      currentState.evidenceItemForm.values.createdBy = user;
      currentState.evidenceItemForm.values.updated = currentdate;
      currentState.evidenceItemForm.values.updatedBy = user;

      newEvidence.itemRecordDetail = currentState.evidenceItemForm;

      console.log('Evidence item created => ', res);
    }
  }
  currentState.evidenceItemForm.changes = false;

  let isCustodyChanged = false;

  for (let i = 0; i < currentState.incEvidenceCustody.length; i++) {
    if (currentState.incEvidenceCustody[i].incEvidenceCustodyDetails.changes === true) {
      if (currentState.incEvidenceCustody[i].incEvidenceCustodyDetails.ptsEvidenceCustodyId) {
        const res = await updateEvidenceCustody(
          currentState.incEvidenceCustody[i].incEvidenceCustodyDetails,
          currentState.evidenceItemForm.values?.incidentId || 0
        );

        currentState.incEvidenceCustody[i].incEvidenceCustodyDetails.values.updated = currentdate;
        currentState.incEvidenceCustody[
          i
        ].incEvidenceCustodyDetails.values.updatedBy = formatPersonFullName(currentUser);

        newEvidence.evidenceCustodyDetails = currentState.incEvidenceCustody;

        isCustodyChanged = true;

        console.log('Custody Updated', res);
      } else {
        if (currentState.evidenceItemForm.ptsEvidenceId) {
          const res = await addNewEvidenceCustody(
            currentState.incEvidenceCustody[i].incEvidenceCustodyDetails,
            currentState.evidenceItemForm.values?.incidentId || 0,
            currentState.evidenceItemForm.ptsEvidenceId
          );
          currentState.incEvidenceCustody[i].incEvidenceCustodyDetails.ptsEvidenceCustodyId =
            res.ptsEvidenceCustodyID;

          currentState.incEvidenceCustody[i].incEvidenceCustodyDetails.values.created = currentdate;
          currentState.incEvidenceCustody[
            i
          ].incEvidenceCustodyDetails.values.createdBy = formatPersonFullName(currentUser);
          currentState.incEvidenceCustody[i].incEvidenceCustodyDetails.values.updated = currentdate;
          currentState.incEvidenceCustody[
            i
          ].incEvidenceCustodyDetails.values.updatedBy = formatPersonFullName(currentUser);

          isCustodyChanged = true;

          newEvidence.evidenceCustodyDetails = currentState.incEvidenceCustody;

          console.log('Evidence custody created => ', res);
          dispatch(setEvidenceCustodyIdForReport(res?.ptsEvidenceCustodyID));
        }
      }
    }
    currentState.incEvidenceCustody[i].incEvidenceCustodyDetails.changes = false;
  }

  // prepare 'Current location' for saving

  const custodyList = currentState.incEvidenceCustody;

  if (custodyList?.length && isCustodyChanged) {
    custodyList.sort((a, b) => {
      if (!a.incEvidenceCustodyDetails.ptsEvidenceCustodyId) return -1;
      if (!b.incEvidenceCustodyDetails.ptsEvidenceCustodyId) return 1;
      return (
        b.incEvidenceCustodyDetails.ptsEvidenceCustodyId -
        a.incEvidenceCustodyDetails.ptsEvidenceCustodyId
      );
    });

    const custody = custodyList[0].incEvidenceCustodyDetails.values;

    // saving 'Current location' by last Custody's 'Custody To' in database and then store

    if (currentState.evidenceItemForm?.values?.currentLocation !== custody?.custodyTo) {
      const response = await updateEvidenceCurrentLocation(
        currentState.evidenceItemForm.ptsEvidenceId,
        custody?.custodyTo
      );

      newEvidence.itemRecordDetail = {
        ...currentState.evidenceItemForm,
        values: {
          ...currentState.evidenceItemForm.values,
          currentLocation: response?.CurrentLocation,
          updated: displayDateTime(response?.Updated),
          updatedBy: response?.UpdatedBy,
        },
      };

      dispatch(
        updateIncEvidenceItemForm({
          ...currentState.evidenceItemForm,
          values: {
            ...currentState.evidenceItemForm.values,
            currentLocation: response?.CurrentLocation,
            updated: displayDateTime(response?.Updated),
            updatedBy: response?.UpdatedBy,
          },
        })
      );
    }
  }

  for (let i = 0; i < currentState.incEvidenceStorage.length; i++) {
    if (currentState.incEvidenceStorage[i].incEvidenceStorageDetails.changes === true) {
      if (currentState.incEvidenceStorage[i].incEvidenceStorageDetails.ptsEvidenceStorageId) {
        const res = await updateEvidenceStorage(
          currentState.incEvidenceStorage[i].incEvidenceStorageDetails,
          currentState.evidenceItemForm.values?.incidentId || 0
        );

        currentState.incEvidenceStorage[i].incEvidenceStorageDetails = res;
        console.log('Evidence storage updated => ', res);
      } else {
        if (currentState.evidenceItemForm.ptsEvidenceId) {
          const res = await addNewEvidenceStorage(
            currentState.incEvidenceStorage[i].incEvidenceStorageDetails,
            currentState.evidenceItemForm.values?.incidentId || 0,
            currentState.evidenceItemForm.ptsEvidenceId
          );

          currentState.incEvidenceStorage[i].incEvidenceStorageDetails = res;
          console.log('Evidence storage created => ', res);
        }
      }
    }
    currentState.incEvidenceStorage[i].incEvidenceStorageDetails.changes = false;
  }
  if (currentState.incEvidenceStorage?.length) {
    newEvidence.evidenceStorageDetails = currentState.incEvidenceStorage;
  }

  if (currentState.evidenceDispositionForm.changes) {
    if (Object.keys(currentState.evidenceDispositionForm.values).length !== 0) {
      if (currentState.evidenceDispositionForm.ptsEvidenceDispositionId) {
        const res = await updateEvidenceDisposition(
          currentState.evidenceDispositionForm,
          currentState.evidenceItemForm.values?.incidentId || 0
        );

        currentState.evidenceDispositionForm.values.updated = currentdate;
        currentState.evidenceDispositionForm.values.updatedBy = user;

        newEvidence.evidenceDispositionDetail = currentState.evidenceDispositionForm;

        console.log('Evidence disposition updated => ', res);
      } else {
        if (currentState.evidenceItemForm.ptsEvidenceId) {
          const res = await addNewEvidenceDisposition(
            currentState.evidenceDispositionForm,
            currentState.evidenceItemForm.values?.incidentId || 0,
            currentState.evidenceItemForm.ptsEvidenceId
          );
          currentState.evidenceDispositionForm.ptsEvidenceDispositionId =
            res.ptsEvidenceDispositionID;

          currentState.evidenceDispositionForm.values.created = currentdate;
          currentState.evidenceDispositionForm.values.createdBy = user;
          currentState.evidenceDispositionForm.values.updated = currentdate;
          currentState.evidenceDispositionForm.values.updatedBy = user;

          newEvidence.evidenceDispositionDetail = currentState.evidenceDispositionForm;

          console.log('Evidence disposition created => ', res);
        }
      }
      currentState.evidenceDispositionForm.changes = false;
    }
  }

  return newEvidence;
};

export const upsertRecord = (entity, data, opearation) => {
  return async () => {
    if (entity === 'address') {
      if (opearation === 'add') await addPersonAddress(data);
      if (opearation === 'edit') await updatePersonAddress(data);
    } else if (entity === 'person') {
      await upsertPerson(data);
    } else if (entity === 'associate') {
      await upsertPersonAssociate(data);
    } else if (entity === 'contact') {
      if (opearation === 'add') await addPersonContactMethod(data);
      if (opearation === 'edit') await updatePersonContactMethod(data);
    } else if (entity === 'credential') {
      if (opearation === 'add') await addPersonCredential(data);
      if (opearation === 'edit') await updatePersonCredential(data);
    } else if (entity === 'dnasample') {
      if (opearation === 'add') await addPersonDNASample(data);
      if (opearation === 'edit') await updatePersonDNASample(data);
    } else if (entity === 'employment') {
      await upsertPersonEmployment(data);
    } else if (entity === 'feature') {
      if (opearation === 'add') await addPersonFeature(data);
      if (opearation === 'edit') await updatePersonFeature(data);
    } else if (entity === 'name') {
      if (opearation === 'add') await addPersonName(data);
      if (opearation === 'edit') await updatePersonName(data);
    } else if (entity === 'resource') {
      await upsertPersonResource(data);
    } else if (entity === 'vehicle') {
      if (opearation === 'add') await addPersonVehicleRegistration(data);
      if (opearation === 'edit') await updatePersonVehicleRegistration(data);
    } else if (entity === 'photo') {
      if (opearation === 'add') await addPersonPhoto(data);
      if (opearation === 'edit') await updatePersonPhoto(data);
    } else if (entity === 'place') {
      if (opearation === 'add') await addPlace(data);
      if (opearation === 'edit') await updatePlace(data);
    } else if (entity === 'tags') {
      const { form, parentType, ptsParentId } = data;
      await addTags(form, parentType, ptsParentId);
    }
  };
};

export const resetRecordAdvanceSearchKeyword = () => {
  fields.person = fields.person.map((p) => ({ ...p, value: '' }));
  fields.address = fields.address.map((a) => ({ ...a, value: '' }));
  fields.item = fields.item.map((i) => ({ ...i, value: '' }));
  fields.firearm = fields.firearm.map((f) => ({ ...f, value: '' }));
  fields.jewelry = fields.jewelry.map((j) => ({ ...j, value: '' }));
  fields.structure = fields.structure.map((str) => ({ ...str, value: '' }));
  fields.substance = fields.substance.map((sub) => ({ ...sub, value: '' }));
  fields.vehicle = fields.vehicle.map((v) => ({ ...v, value: '' }));
  fields.place = fields.place.map((v) => ({ ...v, value: '' }));
  fields.properties = null;

  return async (dispatch) => {
    dispatch({ type: SET_RECORD_ADVANCE_SEARCH_KEYWORD, payload: fields });
  };
};

export const setSelectedRecordType = (payload) => {
  return async (dispatch) => {
    dispatch({ type: SET_RECORD_TYPE, payload });
  };
};

export const setRecordPersonTags = (payload) => {
  return async (dispatch) => {
    dispatch({ type: SET_RECORD_PERSON_TAGS, payload });
  };
};

export default function reducer(
  state = {
    recordAdvanceSearchKeyword: fields,
    recordActive: false,
    formData: null,
    selectedRecordType: '',
    personTags: [],
  },
  action
) {
  switch (action.type) {
    case FORM_DATA:
      return {
        ...state,
        formData: action.payload,
      };
    case SET_RECORD_ACTIVE:
      return {
        ...state,
        recordActive: action.payload,
      };
    case SET_RECORD_ADVANCE_SEARCH_KEYWORD:
      return {
        ...state,
        recordAdvanceSearchKeyword: action.payload,
      };
    case SET_RECORD_TYPE:
      return {
        ...state,
        selectedRecordType: action.payload,
      };
    case SET_RECORD_PERSON_TAGS:
      return {
        ...state,
        personTags: action.payload,
      };
    default:
      break;
  }
  return state;
}
