import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { displayDateTime } from 'reducers/TimeReducer';
import { makeStyles, useTheme } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  txtcolor: {
    color: theme.palette.colorFont,
    '&.MuiDataGrid-root': {
      color: theme.palette.colorFont,
    },
  },

  context: {
    color: theme.palette.colorFont,
    backgroundColor: theme.palette.background.paper,
    '&.MuiDataGrid-root': {
      color: theme.palette.colorFont,
      backgroundColor: theme.palette.background.paper,
    },
  },
  containter: {
    background: theme.palette.background.paper,
    // margin: '5px 20px',
    padding: '10px 25px',
    height: '170px',
  },
}));
const columns = [
  {
    field: 'BookingAgencyID',
    headerName: 'Agency',
    width: 150,
    hide: false,
  },
  {
    field: 'BookingID',
    headerName: 'Booking ID',
    width: 150,
    hide: false,
  },
  {
    field: 'BookingType',
    headerName: 'Booking Type',
    width: 150,
    hide: false,
  },
  {
    field: 'BeginDate',
    valueFormatter: (params) => displayDateTime(params.value),
    width: 150,
    headerName: 'Begin Date',
    hide: false,
  },
  {
    field: 'ProjectedReleaseDate',

    width: 150,
    headerName: 'Projected Release Date',
    hide: false,
    valueFormatter: (params) => displayDateTime(params.value),
  },
  {
    field: 'ActualReleaseDate',
    width: 150,
    headerName: 'Actual Release Date',
    valueFormatter: (params) => displayDateTime(params.value),
    hide: false,
  },
  // {
  //   field: 'HasBookingAdditionalCharges',
  //   format: 'bool',
  //   width: 150,
  //   headerName: 'Additional Charges',
  //   hide: false,
  // },
  {
    field: 'BondAmount',
    headerName: 'Bond Amount',
    width: 150,
    hide: false,
  },
  {
    field: 'IsBondDenied',
    format: 'bool',
    width: 150,
    headerName: 'Is Bond Denied',
    hide: false,
  },
];

const ExpandedPanelContent = ({ row: charges }) => {
  const classes = useStyles();
  const columns = React.useMemo(
    () => [
      {
        field: 'ArrestDate',
        headerName: 'Arrest Date',
        valueFormatter: (params) => displayDateTime(params.value),

        width: 150,
      },
      {
        field: 'ArrestingAgency',
        headerName: 'Arresting Agency',
        width: 150,
      },
      { field: 'officerName', headerName: 'Officer', width: 150 },

      { field: 'RSA', headerName: 'Statute Number', width: 160 },
      { field: 'StatuteDescription', headerName: 'Description', width: 250 },
      { field: 'ReferenceNumber', headerName: 'Reference Number', width: 120 },
      { field: 'Disposition', headerName: 'Disposition', width: 150 },
      { field: 'individualBondAmount', headerName: 'Bond Amount', type: 'number', width: 120 },
      { field: 'individualIsBondDenied', headerName: 'IsBondDenied', type: 'bool', width: 120 },
    ],
    []
  );
  return (
    <div className={classes.containter}>
      <DataGridPro
        density="compact"
        className={classes.context}
        getRowId={(row) => row.ptsChargeID}
        columns={columns}
        rows={charges}
        sx={{ flex: 1 }}
        hideFooter
      />
    </div>
  );
};

export default function RecordBookingDetails(props) {
  const classes = useStyles();
  const { data: rows } = props;
  const theme = useTheme();

  const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = React.useState([]);

  const handleDetailPanelExpandedRowIdsChange = React.useCallback((newIds) => {
    setDetailPanelExpandedRowIds(newIds);
  }, []);

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ height: '80vh', mt: 1 }}>
        <DataGridPro
          className={classes.txtcolor}
          rows={rows}
          columns={columns}
          getDetailPanelContent={({ row }) => <ExpandedPanelContent row={row.Charges || []} />}
          getDetailPanelHeight={() => 170}
          sx={{
            '& .MuiDataGrid-detailPanelToggleCell': {
              '& svg': {
                color: theme.palette.colorFont, // Change this to your desired color
              },
            },
            '& .MuiDataGrid-columnHeader': {
              '& .MuiSvgIcon-root': {
                color: theme.palette.colorFont, // Change this to your desired color for the column menu icon
              },
            },
          }}
          detailPanelExpandedRowIds={detailPanelExpandedRowIds}
          onDetailPanelExpandedRowIdsChange={handleDetailPanelExpandedRowIdsChange}
        />
      </Box>
    </Box>
  );
}
