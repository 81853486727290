export const prepareEvidenceItemRecordDetailData = (item) => {
  if (!item) return;

  const itemRecordDetail = {
    ptsEvidenceId: item.ptsEvidenceID,
    ptsIncidentId: item.ptsIncidentID,
    incIncidentPropertyId: item.incIncidentPropertyID,
    evidenceId: item.EvidenceID,
    values: {
      ptsEvidenceId: item.ptsEvidenceID,
      evidenceType: item.EvidenceType,
      description: item.Description,
      requiredAction: item.RequiredAction,
      actionDate: item.ActionDate,
      submittedAt: item.SubmittedAt,
      collectedDate: item.DateTimeCollected,
      collectedBy: item.CollectedBy,
      submittedBy: item.SubmittedBy,
      incidentId: item.ptsIncidentID,
      evidenceId: item.EvidenceID,
      category: item.Category,
      locationCollected: item.LocationCollected,
      temporaryLocation: item.TemporaryLocation,
      notes: item.Notes,
      disposalCode: item.DisposalCode,
      offenses: item.Offenses ? JSON.parse(item.Offenses) : null,
      quantity: item.Quantity,
      tagNumber: item.TagNumber,
      make: item.Make,
      itemMakeDescription: item.ItemMakeDescription,
      itemModelDescription: item.ItemModelDescription,
      itemConditionDescription: item.ItemConditionDescription,
      model: item.Model,
      serialNumber: item.SerialNumber,
      vin: item.VIN,
      licenseNumber: item.LicenseNumber,
      condition: item.Condition,
      substanceType: item.SubstanceType,
      substanceClass: item.SubstanceClass,
      measurement: item.Measurement,
      evidenceClearanceLevel: item.ClearanceLevel,
      updated: item.Updated,
      updatedBy: item.UpdatedBy,
      created: item.Created,
      createdBy: item.CreatedBy,
      propertyOwner: item.PropertyOwner,
      propertyOwnerType: item.PropertyOwnerType,
      currentLocation: item.CurrentLocation,
    },
  };

  return itemRecordDetail;
};
