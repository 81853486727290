import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import { uploadPersonPhoto } from 'reducers/helpers/fileHelpers';
import Button from '@material-ui/core/Button';
import CameraIcon from '@material-ui/icons/Camera';
import { Camera } from '@material-ui/icons';
import ClearIcon from '@material-ui/icons/Clear';
import SaveIcon from '@material-ui/icons/Save';
import { notifyDataUpdate } from 'reducers/DataUpdateReducer';
import { notify } from 'reducers/NotifierReducer';
import { handleError } from 'reducers/ErrorReducer';
import StopIcon from '@material-ui/icons/Stop';
import RMSCheckbox from 'components/RMSCheckbox';
import formStyles, {
  gridStyle,
  Row,
  RowInner,
  ColCard,
  Fills,
  formatSaveData,
} from 'utils/formStyles';
import TextField from '@material-ui/core/TextField';
import RMSDictionary from './RMSAutoComplete/RMSDictonary';

const useStyles = makeStyles((theme) => ({
  camApp: {
    marginLeft: '50px',
    marginBottom: '100px',
  },
  item: gridStyle(200, 400),
  form: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  videoBtns: {
    marginTop: theme.spacing(9),
  },
}));
let localStream;
function WebcamPhoto(props) {
  const classes = useStyles();
  const { ptsPersonID, setCamOn, IsMarkType, rows } = props;
  const videoRef = useRef(null);
  const photoRef = useRef(null);
  const [hasPhoto, setHasPhoto] = useState(false);
  const [hasVideo, setHasVideo] = useState(false);
  const [Description, setDescription] = useState('');
  const [ImageTypeCode, setImageTypeCode] = useState(null);
  const [MarkType, setMarkType] = useState(null);
  const [Location, setLocation] = useState(null);
  const [Gang, setGang] = useState(null);
  const [IsMark, setIsMark] = useState(IsMarkType ? true : false);
  const [IsDefault, setIsDefault] = useState(false);

  useEffect(() => {
    getVideo();
    return () => {
      localStream.getTracks().forEach(function (track) {
        track.stop();
      });
    };
  }, [videoRef]);

  const getVideo = () => {
    setHasVideo(true);
    navigator.mediaDevices
      .getUserMedia({
        video: { width: 720, height: 480 },
      })
      .then((stream) => {
        localStream = stream;
        let video = videoRef.current;
        video.srcObject = stream;
        video.play();
      })
      .catch((err) => {
        console.error(err);
        setHasVideo(false);
        setCamOn(false);
      });
  };

  const stopVideo = () => {
    const stream = videoRef.current.srcObject;
    stream.getVideoTracks()[0].stop();
    videoRef.current.srcObject = null;
    setHasVideo(false);
    if (!hasPhoto) {
      setCamOn(false);
    }
  };

  const takePhoto = () => {
    const width = 414;
    const height = width / (16 / 9);

    let video = videoRef.current;
    let photo = photoRef.current;

    photo.width = width;
    photo.height = height;

    let ctx = photo.getContext('2d');
    ctx.drawImage(video, 0, 0, width, height);
    setHasPhoto(true);
    // stopVideo(true);
    const stream = videoRef.current.srcObject;
    stream.getVideoTracks()[0].stop();
    videoRef.current.srcObject = null;
    setHasVideo(false);
  };

  const clearPhoto = () => {
    let photo = photoRef.current;
    let ctx = photo.getContext('2d');
    ctx.clearRect(0, 0, photo.width, photo.height);
    setHasPhoto(false);
    clearForm();
    if (!hasVideo) {
      setCamOn(false);
    }
  };

  const saveSnap = async () => {
    try {
      let photo = photoRef.current;
      photo.toBlob(async (blob) => {
        const saveObj = formatSaveData({
          ImageTypeCode,
          IsMark,
          Location,
          Gang,
          Description,
          MarkType,
          IsDefault,
        });
        saveObj.ptsPersonID = ptsPersonID;
        saveObj.file = blob;

        await uploadPersonPhoto(saveObj);
        props.notifyDataUpdate({ type: 'person-image-upload', data: {} });
        props.notify('Snapshot Uploaded', 'success');
        clearPhoto();
      });
    } catch (err) {
      props.handleError(err);
    }
  };

  const handleDefaultValue = (value) => {
    const hasDefault = rows.find((row) => row.IsDefault);
    if (value && hasDefault) {
      if (
        !window.confirm(
          'This record already has a default photo. Would you like to make this photo the default instead?'
        )
      )
        return;
    }

    setIsDefault(!IsDefault);
  };

  const clearForm = () => {
    setDescription('');
    setImageTypeCode(null);
    setMarkType(null);
    setLocation(null);
    setGang(null);
    setIsMark(IsMarkType ? true : false);
    setIsDefault(false);
  };

  const renderInfoForm = () => {
    return (
      <div className={classes.form}>
        <RMSDictionary
          options="codeMugshotTypes"
          className={classes.item}
          onChange={(ev, val) => setImageTypeCode(val)}
          value={ImageTypeCode}
          formType="add"
          label="Image Type"
          IsMark={IsMarkType ? true : false}
          compact
        />
        {!IsMarkType && (
          <>
            <RMSCheckbox
              selectedCheckbox={IsMark}
              handleFormChange={(e) => {
                setIsMark(e.target.checked);
                if (e.target.checked) {
                  setIsDefault(false);
                }
              }}
              label="Scar, Mark, or Tattoo"
            />
            <RMSCheckbox
              selectedCheckbox={IsDefault}
              handleFormChange={(e) => handleDefaultValue(e.target.checked)}
              label="Is Default"
              disabled={IsMark}
            />
          </>
        )}
        {IsMark && (
          <Row className="ml-1">
            <RowInner>
              <RMSDictionary
                options="codeMarks"
                className={classes.item}
                onChange={(ev, val) => setMarkType(val)}
                value={MarkType}
                formType="add"
                label="Mark Type"
                compact
              />
            </RowInner>
            <RowInner>
              <RMSDictionary
                options="codeMarkLocation"
                className={classes.item}
                onChange={(ev, val) => setLocation(val)}
                value={Location}
                formType="add"
                label="Location"
                compact
              />
            </RowInner>
            <RowInner>
              <RMSDictionary
                options="codeGang"
                className={classes.item}
                onChange={(ev, val) => setGang(val)}
                value={Gang}
                formType="add"
                label="Gang"
                compact
              />
            </RowInner>
            <RowInner>
              <TextField
                label="Description"
                variant="outlined"
                size="small"
                value={Description}
                onChange={(e) => setDescription(e.target.value)}
                className={classes.item}
              />
            </RowInner>
          </Row>
        )}
      </div>
    );
  };

  return (
    <div className={classes.camApp}>
      <Grid container>
        <Grid item lg={!hasVideo && hasPhoto ? 4 : 7}>
          <div className={classes.camera}>
            <div>
              <video ref={videoRef}></video>
            </div>
            {hasVideo && (
              <div className={classes.videoBtns}>
                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  onClick={takePhoto}
                  className="mr-4">
                  <Camera className="mr-2" /> Take Snapshot
                </Button>
                <Button variant="contained" color="secondary" size="small" onClick={stopVideo}>
                  <StopIcon className="mr-2" /> Stop Live Feed
                </Button>
              </div>
            )}
          </div>
        </Grid>
        <Grid item lg={!hasVideo && hasPhoto ? 8 : 5}>
          <div>
            <div>
              <canvas ref={photoRef}></canvas>
            </div>
            {hasPhoto && (
              <>
                {renderInfoForm()}
                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  className="mr-4"
                  onClick={saveSnap}>
                  <SaveIcon className="mr-2" /> Save Snapshot
                </Button>
                <Button variant="contained" color="secondary" size="small" onClick={clearPhoto}>
                  <ClearIcon className="mr-2" /> Clear Image
                </Button>
              </>
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default connect(null, {
  notify,
  handleError,
  notifyDataUpdate,
})(WebcamPhoto);
