import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';

import { XGrid } from '@material-ui/x-grid';
import { makeStyles, Fab } from '@material-ui/core';

import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';

import Tooltip from 'components/Tooltip';

import { handleError } from 'reducers/ErrorReducer';
import { showSpinner, hideSpinner } from 'reducers/UiReducer';
import { displayDateTime } from 'reducers/TimeReducer';
import { showTowingDialog } from 'reducers/PersonDialogsReducer';
import { findVehicleTowing } from 'reducers/helpers/vehicleHelpers';

const useStyles = makeStyles((theme) => ({
  wrap: {
    padding: theme.spacing(3),
  },
  gridWrap: {
    width: '100%',
    boxSizing: 'border-box',
    height: 600,
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(2),
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
}));

const bool = (params) => (params.value ? <CheckIcon /> : <span></span>);

const columns = [
  {
    field: 'IsTowed',
    headerName: 'Towed',
    width: 100,
    format: 'bool',
    renderCell: bool,
  },
  { field: 'PlaceID', headerName: 'Place ID', width: 150 },
  { field: 'PlaceName', headerName: 'Tower Reference', width: 150 },
  { field: 'PersonID', headerName: 'Person ID', width: 150 },
  { field: 'FullName', headerName: 'Driver', width: 150 },
  {
    field: 'Created',
    headerName: 'Created',
    width: 200,
    valueFormatter: (params) => displayDateTime(params.value),
  },
  { field: 'CreatedBy', headerName: 'Created By', width: 150 },
  {
    field: 'Updated',
    headerName: 'Updated',
    width: 200,
    valueFormatter: (params) => displayDateTime(params.value),
  },
  { field: 'UpdatedBy', headerName: 'Updated By', width: 150 },
];

function Towing(props) {
  const classes = useStyles();
  const { ptsVehicleID, towing, type } = props;

  const [data, setData] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [selection, setSelection] = useState(null);

  const mountedRef = useRef(true);

  useEffect(() => {
    return () => (mountedRef.current = false);
  }, []);

  useEffect(() => {
    getData();
  }, [towing]);

  const getData = async () => {
    setLoaded(false);
    try {
      const data = await findVehicleTowing(ptsVehicleID);
      if (!mountedRef.current) return;
      setData(data.map((t) => ({ ...t, id: t.ptsTowingID })));
    } catch (err) {
      props.handleError(err, 'Error getting towing information');
    }
    setLoaded(true);
  };

  const editTowing = () => {
    const item = data.find((t) => t.ptsTowingID === selection);
    if (!item) return;
    const { ptsTowingID, ptsPlaceID } = item;
    props.showTowingDialog({ ptsVehicleID, ptsTowingID, ptsPlaceID });
  };

  return (
    <div className={classes.wrap}>
      <div className={classes.actions}>
        {type === 'vehicle-towing-record' && (
          <Tooltip title="Add Towing">
            <Fab
              size="small"
              disabled={props.parentPermission && !props?.parentPermission.Edit}
              color="secondary"
              onClick={() => props.showTowingDialog({ ptsVehicleID })}>
              <AddIcon />
            </Fab>
          </Tooltip>
        )}
        {type === 'vehicle-towing-record' && selection !== null && (
          <Tooltip title="Edit Towing">
            <Fab
              size="small"
              color="secondary"
              disabled={props.parentPermission && !props?.parentPermission.Edit}
              onClick={editTowing}>
              <EditIcon />
            </Fab>
          </Tooltip>
        )}
      </div>
      <div className={classes.gridWrap}>
        <XGrid
          columns={columns}
          rows={data}
          loading={!loaded}
          rowHeight={38}
          disableMultipleSelection={true}
          showToolbar
          disableColumnFilter
          onSelectionModelChange={(newSelection) => {
            setSelection(newSelection.selectionModel[0]);
          }}
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    towing: state.dialogs.towing,
  };
};

export default connect(mapStateToProps, {
  showSpinner,
  hideSpinner,
  handleError,
  showTowingDialog,
})(Towing);
