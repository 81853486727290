import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';

import DateFnsUtils from '@date-io/date-fns';

import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid, InputAdornment, TextField, Tooltip } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton } from '@mui/material';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { TbClockExclamation } from 'react-icons/tb';
import { MdAutoDelete } from 'react-icons/md';

import formStyles, { gridStyle } from 'utils/formStyles';
import { addCreatedUpdatedBy } from 'utils/functions';

import { getService } from 'reducers/service';
import { closeAddAdditionalOffense } from 'reducers/PersonDialogsReducer';
import { notifyDataUpdate } from 'reducers/DataUpdateReducer';
import { notify } from 'reducers/NotifierReducer';
import { formatSaveDate } from 'reducers/TimeReducer';

const useStyles = makeStyles((theme) => ({
  ...formStyles,
  item: gridStyle(150, 220),
  lookup: gridStyle(300, 550),
  wrap: {
    display: 'flex',
    alignItems: 'center',
    spacing: theme.spacing(3),
  },
  autocompleteContainer: {
    flexGrow: 1, // Autocomplete takes most of the space
    marginRight: theme.spacing(1), // Add margin if needed
    width: '100%',
  },
  filterWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(2),
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
  lookupActions: {
    marginLeft: 8,
    '& button': {
      marginLeft: 8,
    },
    '& svg': {
      marginRight: 8,
    },
  },
  closeButton: { color: theme.button.color },
}));

const AdditionalOffensesDialog = (props) => {
  const classes = useStyles();
  const { ptsWarrantID, setAddForm, type = 'add', primaryOffense, warrant } = props;
  const serviceWarrant = getService('rms-warrant');

  const [rows, setRows] = useState([]);
  const [value, setValue] = useState({});
  const [offenseDate, setOffenseDate] = useState(warrant?.OffenseDate);
  const [offenseCount, setOffenseCount] = useState(warrant?.CountQty);
  const [selection, setSelection] = useState(null);

  const ptsStatute = useSelector((state) => state.dictionary.ptsStatute);
  const ptsFBICode = useSelector((state) => state.dictionary.ptsFBICode);

  useEffect(() => {
    let sortedArray = ptsStatute
      ?.filter((s) => s.Description !== '')
      .sort((a, b) => (a.Description > b.Description ? 1 : -1));
    let arr = [];

    sortedArray?.forEach((s) => {
      let fbObj = ptsFBICode.find((fb) => fb.ptsFBICodeID === s.ptsFBICodeID);

      s.FBICode = fbObj?.FBICode;
      s.FBIDescription = fbObj?.Description;
      s.CrimeAgainst = fbObj?.CrimeAgainst;
      s.AggravatedAssaultOrHomicide = fbObj?.AggravatedAssaultOrHomicide;
      s.BiasMotivation = fbObj?.BiasMotivation;
      s.CargoTheft = fbObj?.CargoTheft;
      s.CriminalActivityOrGangInfo = fbObj?.CriminalActivityOrGangInfo;
      s.InjuryType = fbObj?.InjuryType;
      s.JustifiableHomicide = fbObj?.JustifiableHomicide;
      s.LocationType = fbObj?.LocationType;
      s.MethodOfEntry = fbObj?.MethodOfEntry;
      s.OffenderSuspectedOfUsing = fbObj?.OffenderSuspectedOfUsing;
      s.PropertyLossType = fbObj?.PropertyLossType;
      s.TypeWeaponOrForceInvolved = fbObj?.TypeWeaponOrForceInvolved;
      s.AllowedCriminalActivityOrGangInfo = fbObj?.AllowedCriminalActivityOrGangInfo;
      s.StructureEntered = fbObj?.StructureEntered;
      s.OfficerActivity = fbObj?.OfficerActivity;
      s.NegligentManslaughter = fbObj?.NegligentManslaughter;

      arr.push(s);
    });

    const processOptions = arr.filter((item) => {
      const { EnactmentDate, IsActive, IsDeleted } = item;
      if (
        IsActive &&
        IsDeleted === false &&
        EnactmentDate &&
        new Date(EnactmentDate) <= new Date()
      ) {
        return true;
      }
      return false;
    });

    setRows(processOptions);
  }, [ptsStatute, ptsFBICode]);

  useEffect(() => {
    if (type === 'edit') setValue(primaryOffense);
  }, [type]);

  const save = async () => {
    try {
      if (type === 'add') {
        const filterArr = selection.map((item) => item?.ptsStatuteID);
        const data = addCreatedUpdatedBy({ ptsWarrantID, statuteIDs: filterArr });

        await serviceWarrant.create({
          type: 'warrant_additional_offense',
          data: data,
        });

        props.notifyDataUpdate({ type: 'warrants-offenses' });
      } else {
        // console.log('value ', value);

        await serviceWarrant.patch(ptsWarrantID, {
          type: 'warrant_primary_offense',
          data: {
            ptsWarrantID,
            ptsStatuteID: value.ptsStatuteID,
            OffenseDate: formatSaveDate(offenseDate),
            CountQty: offenseCount,
          },
        });

        props.notifyDataUpdate({ type: 'single-warrant' });
      }

      props.notify('Offenses Saved', 'Success');
      close();
    } catch (err) {
      console.log(err);
      props.notify(err.message, 'Error');
    }
  };

  const renderActions = () => {
    return (
      <div className={classes.actions}>
        <Button
          color="primary"
          variant="contained"
          autoFocus
          onClick={save}
          disabled={
            (type === 'add' && !selection) || (type === 'edit' && (!value || props.viewOnly))
          }>
          <SaveIcon /> Save
        </Button>
        <Button color="primary" onClick={close} className={classes.closeButton}>
          <CloseIcon /> Close
        </Button>
      </div>
    );
  };

  const close = () => {
    // props.closeAddAdditionalOffense();
    setAddForm(false);
  };

  const renderAddOffenseStatute = () => {
    return (
      <Autocomplete
        multiple
        id="tags-outlined"
        options={rows}
        fullWidth
        size="small"
        getOptionLabel={(option) => {
          return `${option.Description} | ${option.Code} | ${option.FBICode} (${option.FBIDescription})`;
        }}
        style={{ Width: '100%' }}
        filterSelectedOptions
        renderOption={(option) => (
          <span>
            ${option.Description} | ${option.Code} | ${option.FBICode} (${option.FBIDescription})
            {option.RepealDate && new Date(option.RepealDate) <= new Date() && (
              <span style={{ marginLeft: '25px' }}>
                <TbClockExclamation size={20} color="red" />{' '}
              </span>
            )}
          </span>
        )}
        renderInput={(params) => (
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              fullWidth
              {...params}
              label="Search Statutes and FBI Codes"
              placeholder="Additional Offenses"
            />
          </Grid>
        )}
        onChange={(event, newValue) => {
          setSelection(newValue);
        }}
      />
    );
  };

  const renderEditOffenseStatute = () => {
    return (
      <Autocomplete
        id="tags-outlined"
        value={value}
        options={rows}
        fullWidth
        size="small"
        getOptionLabel={(option) => {
          return `${option.Description} | ${option.Code} | ${option.FBICode} `;
        }}
        style={{ Width: '100%' }}
        filterSelectedOptions
        renderOption={(option) => (
          <span>
            ${option.Description} | ${option.Code} | ${option.FBICode} ($
            {option.FBIDescription})
            {option.RepealDate && new Date(option.RepealDate) <= new Date() && (
              <span style={{ marginLeft: '25px' }}>
                <TbClockExclamation size={20} color="red" />{' '}
              </span>
            )}
          </span>
        )}
        renderInput={(params) => (
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              fullWidth
              {...params}
              label="Search Statutes and FBI Codes"
              placeholder="Offense Statute"
              InputProps={{
                ...params.InputProps,
                startAdornment: value?.IsDeleted ? (
                  <InputAdornment position="start" className="ml-2">
                    <Tooltip title="Deleted Code">
                      <IconButton size="small">
                        <MdAutoDelete size={25} color="red" />
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                ) : null,
              }}
            />
          </Grid>
        )}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
      />
    );
  };

  const renderOffenseDate = () => {
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          disableOpenOnEnter
          autoComplete="off"
          disableToolbar
          variant="inline"
          inputVariant="outlined"
          format="MM/dd/yyyy"
          margin="normal"
          label="Offense Date"
          value={offenseDate}
          onChange={(date) => setOffenseDate(date)}
          autoOk
          size="small"
        />
      </MuiPickersUtilsProvider>
    );
  };

  const renderCountQty = () => {
    return (
      <TextField
        label="Offense Count"
        variant="outlined"
        onChange={(e) => setOffenseCount(e.target.value)}
        value={offenseCount}
        size="small"
        type="number"
      />
    );
  };

  return (
    <Grid item container xs={12}>
      <Grid item container xs={12} md={9} spacing={1}>
        {type === 'add' ? (
          renderAddOffenseStatute()
        ) : (
          <>
            <Grid item xs={8}>
              {renderEditOffenseStatute()}
            </Grid>
            <Grid item xs={2} style={{ marginTop: '-1.2%' }}>
              {renderOffenseDate()}
            </Grid>
            <Grid item xs={2}>
              {renderCountQty()}
            </Grid>
          </>
        )}
      </Grid>
      <span
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          paddingRight: '20px',
          paddingBottom: '3px',
          paddingTop: '3px',
        }}>
        {renderActions()}
      </span>
    </Grid>
  );
};

export default connect(null, { closeAddAdditionalOffense, notifyDataUpdate, notify })(
  AdditionalOffensesDialog
);
