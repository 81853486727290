import store from '../../config/configureStore';

export const hasIncidentErrors = (ptsIncidentId) => {
  let activeIncidents = store.store.getState().incident.activeIncidents;

  const errorIncident = activeIncidents.find(
    (incident) => incident.ptsIncidentId === ptsIncidentId
  );

  if (errorIncident?.nibrsErrors.length > 0) {
    return true;
  }

  return false;
};

export const hasMissingData = (ptsIncidentId) => {
  let activeIncidents = store.store.getState().incident.activeIncidents;

  const currentIncident = activeIncidents.find(
    (incident) => incident.ptsIncidentId === ptsIncidentId
  );

  return currentIncident?.hiddenData;
};

export const hasNoOffenses = (ptsIncidentId) => {
  let activeIncidents = store.store.getState().incident.activeIncidents;

  const currentIncident = activeIncidents.find(
    (incident) => incident.ptsIncidentId === ptsIncidentId
  );

  if (currentIncident?.offenses.length === 0 && !currentIncident?.hiddenOffenses) {
    return true;
  } else {
    return false;
  }
};

export const hasIbrsIncidentErrors = (ptsIncidentId) => {
  let activeIncidents = store.store.getState().ibrs.ibrsIncidents;

  const errorIncident = activeIncidents.find(
    (incident) => incident.ptsIncidentId === ptsIncidentId
  );

  if (errorIncident?.nibrsErrors.length > 0) {
    return true;
  }

  return false;
};
