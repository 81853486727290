/* Hooks */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
/* Components */
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Alert, Snackbar } from '@mui/material';
import { Grid, Box, Typography } from '@material-ui/core';
/* Services */
import { getService } from 'reducers/service';
import {
  formatCodeDispositions,
  formatCommonCodes,
  formatCodeVehicleMakeModels,
  formatCodeCities,
} from './helper/codeObjectFormatter';
import { popupModal } from './../../../reducers/CodeCrudFormReducer';
import { addDictionaryValue, updateDictionaryValue } from 'reducers/DictionaryReducer';
import { notify } from 'reducers/NotifierReducer';

const obj = {
  Code: '',
  Description: '',
  AgencyID: '',
  MakeCode: '',
  MakeDescription: '',
  ModelCode: '',
  ModelDescription: '',
  CityDescription: '',
  Category: '',
  State: '',
};

const CommonGridLayout = ({ children }) => {
  return (
    <Grid item xs={6} style={{ width: '100%' }}>
      <Box style={{ marginLeft: 10 }}>{children}</Box>
    </Grid>
  );
};
function titleCase(word) {
  return word.length > 0 ? word.at(0).toUpperCase() + word.slice(1).toLowerCase() : '';
}

export default function CodeCrudDialog() {
  const dispatch = useDispatch();

  const [values, setValues] = useState(obj);
  const [openAlert, setOpenAlert] = useState(false);

  const open = useSelector((state) => state.codeCrudForm.openModal);
  const mode = useSelector((state) => state.codeCrudForm.mode);
  const data = useSelector((state) => state.codeCrudForm.updatableCodeObject);
  const label = useSelector((state) => state.codeCrudForm.label);
  const AgencyID = useSelector((state) => state.user.userAgency);
  const resetField = useSelector((state) => state.codeCrudForm.resetField);
  const currentService = useSelector((state) => state.codeCrudForm.serviceName);
  const dictionary = useSelector((state) => state.dictionary);

  /* handler functions -> start */
  const closeDialog = () => dispatch(popupModal(false));

  const handleCloseAlert = () => setOpenAlert(false);

  const handleChange = (key, value) => {
    setValues((prevState) => ({ ...prevState, [key]: value }));
  };

  const handleSubmit = async () => {
    if (mode === 'add') await handleAddCodes();
    else if (mode === 'edit') await handleUpdateCodes();
    else if (mode === 'remove') await handleRemoveCodes();
    closeDialog();
  };
  /* handler functions -> end */

  const makeServerRequest = async (serviceName, payload, duplicationCheckerObj) => {
    let service = getService(serviceName);
    let isExists = await checkDuplication(
      {
        ...duplicationCheckerObj,
        IsDeleted: false,
      },
      serviceName
    );
    if (isExists) return false;
    const res = await service.create(payload);
    const modifiedRes = {
      ptsCityID: res.ptsCityID,
      CityDescription: res.CityDescription,
      State: res.State,
      Category: res.Category,
      IsDefault: res.IsDefault,
      IsNew: true,
    };
    addCode(modifiedRes, currentService);
  };

  const checkDuplication = async (query, serviceName) => {
    const service = getService(serviceName);
    const isAlreadyExist = await service.find({ query });
    if (isAlreadyExist.data.length) {
      dispatch(notify('Item already exist', 'error'));
      return true;
    }
    return false;
  };

  const addCode = (newEntity, tableName) => {
    dispatch(addDictionaryValue(newEntity, tableName));
  };

  const updateCode = (tableName, primaryKey1, primaryKey2, value) => {
    let codesArray = dictionary[tableName];
    let index = codesArray.findIndex((v) => {
      return v[primaryKey1] === value[primaryKey1] && v[primaryKey2] === value[primaryKey2];
    });
    codesArray[index] = value;
    dispatch(updateDictionaryValue(codesArray, tableName));
  };

  const deleteCode = (tableName, primaryKey1, primaryKey2, value) => {
    let codesArray = dictionary[tableName];
    codesArray = codesArray.filter(
      (v) => v[primaryKey1] !== value[primaryKey1] || v[primaryKey2] !== value[primaryKey2]
    );
    dispatch(updateDictionaryValue(codesArray, tableName));
  };

  const handleAddCodes = async () => {
    let serviceName = '';
    let payload = {};
    let duplicationCheckerObj = {};
    switch (currentService) {
      case 'codeDispositions':
        serviceName = 'code-dispositions';
        payload = {
          AgencyID,
          Code: values.Code,
          Description: values.Description,
        };

        duplicationCheckerObj = {
          AgencyID: values.AgencyID,
          Code: values.Code,
        };
        await makeServerRequest(serviceName, payload, duplicationCheckerObj);
        break;
      case 'codeVehicleMakesModels':
        serviceName = 'code-vehicle-make-models';
        payload = formatCodeVehicleMakeModels(values);
        duplicationCheckerObj = { MakeCode: values.MakeCode, ModelCode: values.ModelCode };
        await makeServerRequest(serviceName, payload, duplicationCheckerObj);
        break;
      case 'codeCities':
        serviceName = 'codecities';
        payload = formatCodeCities(values);
        duplicationCheckerObj = {
          CityDescription: values.CityDescription,
          State: values.State,
        };
        if (!payload.CityDescription) {
          dispatch(notify('City Description is required', 'error'));
          return;
        }
        await makeServerRequest(serviceName, payload, duplicationCheckerObj);
        break;
      default:
        serviceName = currentService.toLowerCase();
        payload = formatCommonCodes(values);
        duplicationCheckerObj = { Code: values.Code };
        await makeServerRequest(serviceName, payload, duplicationCheckerObj);
        break;
    }

    //return true;
  };

  const handleUpdateCodes = async () => {
    if (currentService === 'codeDispositions') {
      let service = getService('code-dispositions');
      let response = await service.update(
        { AgencyID: values.AgencyID, Code: values.Code },
        formatCodeDispositions(values, values.AgencyID)
      );
      const payload = {
        AgencyID: response[1].AgencyID,
        Code: response[1].Code,
        Description: response[1].Description,
      };
      updateCode('codeDispositions', 'Code', 'AgencyID', payload);
    } else if (currentService === 'codeVehicleMakesModels') {
      let service = getService('code-vehicle-make-models');
      let response = await service.update(
        { MakeCode: values.MakeCode, ModelCode: values.ModelCode },
        formatCodeVehicleMakeModels(values)
      );
      const payload = response[1];
      updateCode('codeVehicleMakesModels', 'MakeCode', 'ModelCode', payload);
    } else if (currentService === 'codeCities') {
      let service = getService('codecities');
      let response = await service.update(values.ptsCityID, formatCodeCities(values));
      let codesArray = dictionary['codeCities'];
      let index = codesArray.findIndex((v) => {
        return v['ptsCityID'] === values.ptsCityID;
      });
      codesArray[index] = response;
      dispatch(updateDictionaryValue(codesArray, 'codeCities'));
    } else {
      let service = getService(currentService);
      await service.patch(values.Code, formatCommonCodes(values));
    }
    if (resetField) resetField();
  };

  const handleRemoveCodes = async () => {
    let service;
    let removeCode;

    switch (currentService) {
      case 'codeDispositions':
        service = getService('code-dispositions');
        try {
          const key = { Code: values.Code, AgencyID: values.AgencyID };
          removeCode = await service.remove(key);
          deleteCode('codeDispositions', 'Code', 'AgencyID', key);
        } catch (error) {
          console.log('error : ', error);
        }
        break;
      case 'codeVehicleMakesModels':
        service = getService('code-vehicle-make-models');
        try {
          const key = { MakeCode: values.MakeCode, ModelCode: values.ModelCode };

          removeCode = await service.remove(key);

          deleteCode('codeVehicleMakesModels', 'MakeCode', 'ModelCode', key);
        } catch (error) {
          console.log('error : ', error);
        }
        break;
      case 'codeCities':
        service = getService('codecities');
        try {
          removeCode = await service.remove(values.ptsCityID);
          let codesArray = dictionary['codeCities'];
          codesArray = codesArray.filter((v) => v['ptsCityID'] !== values.ptsCityID);
          dispatch(updateDictionaryValue(codesArray, 'codeCities'));
        } catch (error) {
          console.log('error : ', error);
        }
        break;
      default:
        service = getService(currentService);
        removeCode = await service.remove(values.Code);
        break;
    }
    if (resetField) resetField();
  };
  /* Api Calls -> end */

  /* useEffect -> start */
  useEffect(() => {
    if (mode === 'edit' || mode === 'remove') {
      setValues({ ...data });
    } else {
      setValues(obj);
    }
  }, [mode, data]);

  /* Render Functions -> start */
  const commonForm = () => (
    <>
      <Grid container item xs={12} style={{ marginTop: 10 }}>
        <CommonGridLayout>
          {' '}
          <TextField
            disabled={mode === 'edit'}
            id={'id-code'}
            style={{ width: '100%' }}
            type={'text'}
            value={values.Code || ''}
            label={'Code'}
            onChange={(e) => handleChange('Code', e.target.value)}
          />
        </CommonGridLayout>
        <CommonGridLayout>
          <TextField
            id={'id-description'}
            style={{ width: '100%' }}
            type={'text'}
            value={values.Description}
            label={'Description'}
            onChange={(e) => handleChange('Description', e.target.value)}
          />
        </CommonGridLayout>
      </Grid>
    </>
  );

  const codeDispositionForm = () => (
    <>
      {' '}
      <Grid container item xs={12} style={{ marginTop: 10 }}>
        <CommonGridLayout>
          <TextField
            disabled
            id={'id-agency'}
            style={{ width: '100%' }}
            type={'text'}
            defaultValue={values.AgencyID || AgencyID}
            label={'Agency ID'}
          />
        </CommonGridLayout>
      </Grid>
      {commonForm()}
    </>
  );

  const codeCitiesForm = () => (
    <>
      {' '}
      <Grid container item xs={12} style={{ marginTop: 10 }}>
        <CommonGridLayout>
          <TextField
            id={'id-code-cities'}
            style={{ width: '100%' }}
            type={'text'}
            defaultValue={values.CityDescription}
            label={'City Description'}
            onChange={(e) => handleChange('CityDescription', e.target.value)}
          />
        </CommonGridLayout>
        {/* <CommonGridLayout>
          <TextField
            id={'id-city-category'}
            style={{ width: '100%' }}
            type={'text'}
            defaultValue={values.Category}
            label={'Category'}
            onChange={(e) => handleChange('Category', e.target.value)}
          />
        </CommonGridLayout> */}
        <CommonGridLayout>
          <TextField
            id={'id-city-state'}
            style={{ width: '100%' }}
            type={'text'}
            defaultValue={values.State}
            label={'State'}
            onChange={(e) => handleChange('State', e.target.value)}
          />
        </CommonGridLayout>
      </Grid>
    </>
  );

  const codeVehicleMakeModelForm = () => (
    <>
      <Grid container item xs={12} style={{ marginTop: 10 }}>
        <Grid item xs={6} style={{ width: '100%' }}>
          <Box style={{ marginLeft: 10 }}>
            <TextField
              disabled={mode === 'edit'}
              id={'id-makecode'}
              style={{ width: '100%' }}
              type={'text'}
              value={values.MakeCode || ''}
              label={'MakeCode'}
              onChange={(e) => handleChange('MakeCode', e.target.value)}
            />
          </Box>
        </Grid>
        <Grid item xs={6} style={{ width: '100%' }}>
          <Box style={{ marginLeft: 10 }}>
            <TextField
              id={'id-makedescription'}
              style={{ width: '100%' }}
              type={'text'}
              value={values.MakeDescription}
              label={'MakeDescription'}
              onChange={(e) => handleChange('MakeDescription', e.target.value)}
            />
          </Box>
        </Grid>
        <Grid item xs={6} style={{ width: '100%' }}>
          <Box style={{ marginLeft: 10 }}>
            <TextField
              disabled={mode === 'edit'}
              id={'id-modelcode'}
              style={{ width: '100%' }}
              type={'text'}
              value={values.ModelCode || ''}
              label={'ModelCode'}
              onChange={(e) => handleChange('ModelCode', e.target.value)}
            />
          </Box>
        </Grid>
        <Grid item xs={6} style={{ width: '100%' }}>
          <Box style={{ marginLeft: 10 }}>
            <TextField
              id={'id-modeldescription'}
              style={{ width: '100%' }}
              type={'text'}
              value={values.ModelDescription}
              label={'ModelDescription'}
              onChange={(e) => handleChange('ModelDescription', e.target.value)}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );

  const renderFunc = () => {
    if (mode === 'remove') return 'Are you sure you want to remove this code?';
    else {
      switch (currentService) {
        case 'codeDispositions':
          return codeDispositionForm();
        case 'codeVehicleMakesModels':
          return codeVehicleMakeModelForm();
        case 'codeCities':
          return codeCitiesForm();
        default:
          return commonForm();
      }
    }
  };

  const setButtonName = () => {
    return mode === 'add' ? 'Create' : mode === 'edit' ? 'Update' : 'Remove';
  };

  const displayAlert = () => (
    <Snackbar
      open={openAlert}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      autoHideDuration={6000}
      onClose={handleCloseAlert}>
      <Alert onClose={handleCloseAlert} severity="warning" sx={{ width: '100%' }}>
        Item Already Exists!!
      </Alert>
    </Snackbar>
  );
  /* Render Functions -> end */

  return (
    <Dialog open={open} onClose={closeDialog} maxWidth={'sm'} fullWidth={true}>
      <DialogTitle style={{ background: '#1976d2', color: 'white' }}>
        {<Typography variant="h5">{titleCase(mode) + ' ' + titleCase(label)}</Typography>}
      </DialogTitle>
      <DialogContent>
        {displayAlert()}
        <div style={{ padding: 10 }}>
          <Grid container>
            <Grid item xs={12}>
              <Grid container>{renderFunc()}</Grid>
            </Grid>
          </Grid>
        </div>
      </DialogContent>
      <DialogActions>
        <div className=" mb-1">
          <Button style={{ background: '#f1f1f1', color: 'black' }} onClick={closeDialog}>
            Cancel
          </Button>
          <Button
            className="ml-2"
            variant="contained"
            style={{ background: '#1976D2', color: 'white' }}
            onClick={handleSubmit}>
            {setButtonName()}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}
