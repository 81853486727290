import SimpleSchema from 'simpl-schema';

import store from '../../config/configureStore';

import { incidentHasArrestee } from '../helpers/incidentHelper';

import { getCategoryOfCode } from 'utils/functions';

SimpleSchema.setDefaultMessages({
  messages: {
    en: {
      'NIBRS-Arrestee-Status':
        'When there is an arrestee in Incident then the IBR Status needs to be "Cleared by arrest".',
      'NIBRS-Clearance-Status-Date':
        'When the IBR Clearance status is "Cleared Exceptionally", the exceptional Clearance Status Date is required.',
    },
  },
});

export const incIncidentSchema = new SimpleSchema({
  ibrStatus: {
    type: String,
    label: 'IBR Status',
    optional: true,
    custom() {
      const codeIBRStatus = store.store.getState().dictionary.codeIBRStatus;
      const ibsStatusCategory = getCategoryOfCode(codeIBRStatus, this.obj.ibrStatus);

      // NIBRS-Arrestee-Status
      if (incidentHasArrestee(this.obj.offenses) && ibsStatusCategory !== 'cbyarrest') {
        return 'NIBRS-Arrestee-Status';
      }
    },
  },
  ibrSubStatusDate: {
    type: Date,
    label: 'IBR Sub Status Date',
    optional: true,
    custom() {
      const codeIBRStatus = store.store.getState().dictionary.codeIBRStatus;
      const ibsStatusCategory = getCategoryOfCode(codeIBRStatus, this.obj.ibrStatus);

      // NIBRS-Clearance-Status-Date
      if (ibsStatusCategory === 'cexception' && !this.obj.ibrSubStatusDate) {
        return 'NIBRS-Clearance-Status-Date';
      }
    },
  },
});
