import store from '../../config/configureStore';

export const countPartiesErrors = (ptsIncPersonId) => {
  let currentIncidentId = parseInt(store.store.getState().incident.ptsIncidentId);
  let validationErrors = store.store.getState().incident.validationErrors;

  const errorObj = validationErrors.find((error) => error.ptsIncidentId === currentIncidentId);

  if (!errorObj?.errors?.partiesErrors) {
    return 0;
  }
  let errorCount = 0;

  for (let error of errorObj.errors.partiesErrors) {
    if (error.ptsIncPersonId === ptsIncPersonId) {
      errorCount++;
    }
  }
  return errorCount;
};
