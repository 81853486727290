import store from '../config/configureStore';
import moment from 'moment';
import { getService } from './service';
import mtz from 'moment-timezone';
import { handleError } from './ErrorReducer';

const localTZoffset = new Date().getTimezoneOffset();
const TIME_SETTINGS = 'TIME_SETTINGS';
const TIME_OFFSET = 'TIME_OFFSET';

// Used to display date and time on the screen
export function displayDateTime(date) {
  return formatTimeDate(date, 'L HH:mm:ss', 'L LT');
}

// Used to display time on the screen
export function displayTime(date) {
  return formatTimeDate(date, 'HH:mm:ss', 'LT');
}

// display time without seconds
export function displayTimeNoSeconds(date) {
  return formatTimeDate(date, 'HH:mm', 'LT');
}

export function getDateFormat() {
  return 'MM/dd/yyyy';
}

// Used to display date on the screen
export function displayDate(date) {
  return formatTimeDate(date, 'L', 'L');
}

let timeSyncInitiated = false;
export const timeSyncInit = () => {
  return async (dispatch) => {
    if (timeSyncInitiated) return;
    timeSyncInitiated = true;
    setInterval(() => {
      const state = store.store.getState();
      const { isAuthenticated } = state.user;
      if (isAuthenticated) {
        dispatch(getServerTimeSettings());
      }
    }, 5 * 60 * 1000);
  };
};

let timeOffsetSet = false;
export const getServerTimeSettings = () => async (dispatch) => {
  const service = getService('time');
  try {
    const time1 = new Date().getTime();
    const result = await service.get(0);
    if (timeOffsetSet) {
      delete result.timeOffset;
    } else {
      timeOffsetSet = true;
    }
    const time2 = new Date().getTime();
    result.clockDiff = Math.round(time1 + (time2 - time1) / 2 - result.timestamp);
    result.localTZoffset = new Date().getTimezoneOffset();
    dispatch({ type: TIME_SETTINGS, payload: result });
  } catch (err) {
    dispatch(handleError(err));
  }
};

// const useLocalTime = process.env.REACT_APP_LOCAL_TIME_ON === 'true';
const useLocalTime = true; // Agency time doesn't work well currently
const defaultState = {
  format24h: false, //  Do we use 24 hour format to display time or not
  dbTZoffset: 0, //      Difference between server time and UTC time (including time offset if server in the wrong timezone)
  clockDiff: 0, //      Time difference between server and local machine in ms. Used to sync internal clock
  localTime: useLocalTime, //   Time should be displayed in local or agency time [default: false]
  localTZoffset,
  timeOffset: 0,
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case TIME_SETTINGS:
      return { ...state, ...action.payload, localTime: true };
    case TIME_OFFSET:
      return { ...state, ...action.payload, localTime: true };
    default:
      break;
  }
  return state;
}

export const getFormat24 = () => {
  const state = store.store.getState();
  return state.time.format24h;
};

export const dateTimePicker = (date) => {
  return parseInDate(date);
};

export const formatSaveDate = (date) => {
  const calcOffset = getApiTimeOffset(date);
  const state = store.store.getState();
  const { localTime, timeOffset } = state.time;
  if (!date) return null;
  const localTZoffset = getLocalTimeOffset(date);
  const m = moment(date);
  if (!m.isValid()) return null;
  let d = m.utc();
  if (localTime) {
    d = m.add(-calcOffset, 'minutes');
  } else {
    d = m.add(-timeOffset - localTZoffset, 'minutes');
  }
  return d.format('YYYY-MM-DD HH:mm:ss');
};

export const formatSaveDateFrom = (date) => {
  if (!date) return null;
  const state = store.store.getState();
  const { timeOffset } = state.time;
  const d = moment(date).format('YYYY-MM-DD') + ' 00:00:00.000';
  const m = moment(d).subtract(timeOffset, 'minutes');
  if (!m.isValid()) return null;
  const result = m.format('YYYY-MM-DD HH:mm:ss.SSS');
  return result;
};

export const formatSaveDateTo = (date) => {
  if (!date) return null;
  const state = store.store.getState();
  const { timeOffset } = state.time;
  const d = moment(date).format('YYYY-MM-DD') + ' 23:59:59.999';
  const m = moment(d).subtract(timeOffset, 'minutes');
  if (!m.isValid()) return null;
  const result = m.format('YYYY-MM-DD HH:mm:ss.SSS');
  return result;
};

export function formatTimeDate(date, militaryFormat, localFormat) {
  // OK
  if (!date) return '';
  const state = store.store.getState();
  const { format24h } = state.time;
  const m = parseInDate(date, state);
  const format = format24h ? militaryFormat : localFormat;
  return m.format(format);
}

export const parseInDate = (date, state = null) => {
  if (!state) state = store.store.getState();
  let { localTime, timeOffset } = state.time;
  localTime = true;
  if (!date) return null;
  let m = moment(date);
  if (!m.isValid()) return null;
  const apiOffset = getApiTimeOffset(date);
  const localOffset = getLocalTimeOffset(date);
  if (m._tzm === undefined) {
    m = m.add(apiOffset - localOffset, 'minutes'); // fix local sql time - without Z at the end
  }

  if (!localTime) {
    m = m.add(timeOffset - apiOffset + localOffset, 'minutes');
  }
  return m;
};

// For displaying date time widget
export function getDateTimeFormat() {
  const state = store.store.getState();
  const { format24h } = state.time;
  return format24h ? 'MM/dd/yyyy HH:mm' : 'MM/dd/yyyy hh:mm a';
}

export function getTimeFromDate(date) {
  return moment(formatSaveDate(date)).format('HH:mm:ss.SSS');
}
// Functions that need to be tested - copied from functions

/** converts number of minutes to String hours and minutes [hh:mm] (must be positive) */
export function minsToHours(min) {
  const mins = min % 60;
  const hours = Math.floor(min / 60);
  return `00${hours}`.substr(-2) + ':' + `00${mins}`.substr(-2);
}

export function isValidDate(date) {
  if (typeof date !== 'string' || date.length > 24) return false;
  var dateReg = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/;
  if (date.match(dateReg) !== null && moment(date).isValid()) return true;
  return false;
}

export function getCurrentDate() {
  const state = store.store.getState();
  const { localTime, dbTZoffset, localTZoffset, clockDiff, timeOffset } = state.time;
  const time = new Date().getTime() - clockDiff;
  let m = moment(time).utc();
  if (!localTime) {
    m = m.utc().add(timeOffset + localTZoffset - dbTZoffset, 'minutes');
  }
  return m;
}

/** Returs passed time in minutes */
export function getPassedTime(date) {
  if (typeof date !== 'string' || date.length > 24) return 0;
  const m = parseInDate(date);
  if (!m.isValid()) return 0;
  const currentDate = moment(getCurrentDate());
  const passedTime = currentDate.diff(m, 'minutes');
  return passedTime;
}

export function getDatabaseDateTimeFormat(date) {
  const state = store.store.getState();
  const { format24h } = state.time;

  const preferredFormat = format24h ? 'MM-DD-YYYY HH:mm:ss' : 'MM-DD-YYYY hh:mm:ss A';
  const dateTime = moment(date).format(preferredFormat);

  return dateTime;
}

export function getTimePeriod(timePeriod) {
  let fromDate,
    toDate,
    date,
    month,
    year,
    daysInMonth,
    day,
    daysInPreviousMonth,
    previousMonth,
    yearOfPreviousMonth;

  fromDate = new Date();
  toDate = new Date();

  date = fromDate.getDate();
  month = fromDate.getMonth();
  previousMonth = month - 1;
  year = fromDate.getFullYear();
  day = fromDate.getDay();
  daysInMonth = new Date(year, month + 1, 0).getDate();
  yearOfPreviousMonth = previousMonth === 11 ? year - 1 : year;
  daysInPreviousMonth = new Date(yearOfPreviousMonth, previousMonth + 1, 0).getDate();

  if (timePeriod === 'Yesterday') {
    fromDate.setDate(date - 1);
    toDate.setDate(date - 1);
  } else if (timePeriod === 'This Week') {
    fromDate.setDate(date - day);
  } else if (timePeriod === 'This Month') {
    fromDate.setDate(1);
  } else if (timePeriod === 'This Year') {
    fromDate.setDate(1);
    fromDate.setMonth(0);
  } else if (timePeriod === 'Last Week') {
    fromDate.setDate(date - day);
    fromDate.setDate(fromDate.getDate() - 7);

    toDate = new Date(fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate() + 6);
  } else if (timePeriod === 'Last Month') {
    fromDate.setFullYear(yearOfPreviousMonth, previousMonth, 1);
    toDate.setFullYear(yearOfPreviousMonth, previousMonth, daysInPreviousMonth);
  } else if (timePeriod === 'Last Year') {
    fromDate.setFullYear(year - 1, 0, 1);
    toDate.setFullYear(year - 1, 11, 31);
  } else if (timePeriod === 'Last 7 Days') {
    fromDate.setDate(date - 7);
  } else if (timePeriod === 'Last 30 Days') {
    fromDate.setDate(date - 30);
  } else if (timePeriod === 'Last 365 Days') {
    fromDate.setDate(date - 365);
  } else if (timePeriod === 'Custom') {
    fromDate = null;
    toDate = null;
  }

  if (timePeriod !== 'Custom') {
    fromDate.setHours(0, 0, 0);
    toDate.setHours(23, 59, 0);
  }

  return { fromDate, toDate };
}

export const getApiTimeOffset = (date) => {
  const state = store.store.getState();
  const x = mtz(date);
  if (!x.isValid()) {
    return state.time.dbTZoffset;
  }
  const apiTimeZone = state.time.apiTimeZone;
  return -mtz.tz(date, apiTimeZone).utcOffset();
};

export const getLocalTimeOffset = (date) => new Date(date).getTimezoneOffset();

export function compareDate(date1, date2) {
  date1 = moment(date1).format('YYYY-MM-DD HH:mm:ss');
  date2 = moment(date2).format('YYYY-MM-DD HH:mm:ss');
  return moment(date1).isSame(date2);
}
