import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import RMSXGrid from 'components/RMSXGrid';
import QuickFilterSearch from 'components/RMSSearch/components/QuickFilterSearch';
import Tooltip from 'components/Tooltip';
import PrintsSearch2 from 'components/RMSSearch/components/PrintsSearch2';

import { showPersonDnaSample } from 'reducers/PersonDialogsReducer';
import { showSpinner, hideSpinner } from 'reducers/UiReducer';
import { notify } from 'reducers/NotifierReducer';
import { notifyDataUpdate } from 'reducers/DataUpdateReducer';
import { handleError } from 'reducers/ErrorReducer';
import { displayDateTime } from 'reducers/TimeReducer';
import {
  getPersonDnaSamples,
  getPlaceByID,
  getPtsPerson,
  removeDnaSample,
} from 'reducers/SearchReducer';
import { getFullPermissions } from 'reducers/PermissionsReducer';

const useStyles = makeStyles((theme) => ({
  wrap: {
    padding: theme.spacing(3),
  },
  filterWrap: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(2),
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
}));

const defaultColumns = [
  { field: 'DNASampleID', headerName: 'Sample ID' },
  {
    field: 'Taken',
    headerName: 'Taken Date',
    valueFormatter: (params) => displayDateTime(params.value),
  },
  'TakenBy',
  'Type',
  'Method',
  'Reason',
  'Status',
  'CrimeLab',
  {
    field: 'CrimeLabSentDateTime',
    headerName: 'Sent Date',
    valueFormatter: (params) => displayDateTime(params.value),
  },
  {
    field: 'Created',
    headerName: 'Created',
    width: 200,
    valueFormatter: (params) => displayDateTime(params.value),
    hide: false,
  },
  { field: 'CreatedBy', headerName: 'Created By', width: 150, hide: false },
  {
    field: 'Updated',
    headerName: 'Updated',
    width: 200,
    valueFormatter: (params) => displayDateTime(params.value),
    hide: false,
  },
  { field: 'UpdatedBy', headerName: 'Updated By', width: 150, hide: false },
];

function DnaSamples(props) {
  const classes = useStyles();
  const { ptsPersonID, dataUpdate } = props;

  const [columns, setColumns] = useState(defaultColumns);
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [selection, setSelection] = useState(null);
  const [loaded, setLoaded] = useState(true);
  const perms = getFullPermissions('globals', 'People', 'any');
  const canEditPerson = perms.Edit;
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (dataUpdate?.type === 'dna-sample') fetchData();
  }, [dataUpdate]);

  const fetchData = async () => {
    const samples = await getPersonDnaSamples(ptsPersonID);
    const processed = await process(samples);
    setRows(processed);
  };

  const process = async (samples) => {
    const processed = samples.map(async (sample) => {
      const { ptsCrimeLabPlaceID, ptsTakenByPersonID } = sample;
      if (ptsCrimeLabPlaceID) {
        const crimeLabPlace = await getPlaceByID(ptsCrimeLabPlaceID);
        if (crimeLabPlace) sample.CrimeLab = crimeLabPlace.PlaceName;
      }
      if (ptsTakenByPersonID) {
        const takenByPerson = await getPtsPerson(ptsTakenByPersonID);
        if (takenByPerson.length > 0) sample.TakenBy = takenByPerson[0].FullName;
      }
      return {
        ...sample,
        id: sample.ptsDNASampleID,
      };
    });
    return Promise.all(processed);
  };

  const handleAdd = () => props.showPersonDnaSample({ ptsPersonID });

  const edit = () => {
    const item = rows.find((row) => row.id === selection);
    props.showPersonDnaSample({ ...item });
  };

  const del = () => {
    const item = rows.find((row) => row.id === selection);
    props.showSpinner();

    removeDnaSample(item.ptsDNASampleID)
      .then(() => {
        props.notify('DNA Sample Deleted', 'warning');
        props.notifyDataUpdate({ type: 'dna-sample', data: {} });
      })
      .catch(props.handleError)
      .finally(props.hideSpinner);
  };

  return (
    <div className={classes.wrap}>
      <div className={classes.filterWrap}>
        <div className={classes.filterWrap}>
          <QuickFilterSearch
            rows={rows}
            columns={columns}
            setFilteredRows={setFilteredRows}
            style={{ marginTop: 0, paddingBottom: 14 }}
          />
        </div>
        <div className={classes.actions}>
          <Tooltip title="Print">
            <PrintsSearch2 title="DNA Samples" data={filteredRows} cols={columns} />
          </Tooltip>
          <Tooltip title="Add Sample">
            <Fab size="small" color="secondary" disabled={!canEditPerson} onClick={handleAdd}>
              <AddIcon />
            </Fab>
          </Tooltip>
          {selection !== null && (
            <>
              <Tooltip title="Edit Sample">
                <Fab size="small" color="secondary" disabled={!canEditPerson} onClick={edit}>
                  <EditIcon />
                </Fab>
              </Tooltip>
              <Tooltip title="Delete Sample">
                <Fab size="small" color="secondary" disabled={!canEditPerson} onClick={del}>
                  <DeleteIcon />
                </Fab>
              </Tooltip>
            </>
          )}
        </div>
      </div>
      <RMSXGrid
        name="PeopleDnaSamples"
        columns={columns}
        setColumns={setColumns}
        rows={filteredRows}
        setSelection={setSelection}
      />
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    dataUpdate: state.dataUpdate,
  };
};
export default connect(mapStateToProps, {
  showPersonDnaSample,
  showSpinner,
  hideSpinner,
  notify,
  notifyDataUpdate,
  handleError,
})(DnaSamples);
