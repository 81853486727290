import store from '../../config/configureStore';

import { partiesSchema } from 'simpl-schema-validation/schema';
import { isOffenseValid } from '../helpers/schemaValidationHelper';

export const validateParties = () => {
  const currentState = store.store.getState().incident;
  const {
    parties,
    incVictims,
    incArrestees,
    offenses,
    incSubjects,
    partiesPersonForm,
    turnOfValidation,
  } = currentState;
  if (turnOfValidation) return [];

  let validationErrors = [];

  for (let party of parties) {
    // TODO: will refactor this
    let name = '';
    if (party.PersonId) {
      name += `${party.PersonId}` + ' | ';
    }
    if (party.personDetail.values.lastName) {
      name += `${party.personDetail.values.lastName}`;
    }
    name += ', ';
    if (party.personDetail.values.firstName) {
      name += party.personDetail.values.firstName;
    }
    name += ' ';
    if (party.personDetail.values.middleName) {
      name += party.personDetail.values.middleName;
    }

    let availableIncVictimOffenses = [];
    let victims = [];
    incVictims.forEach((incVictim) => {
      if (
        incVictim.incVictimDetails.ptsIncPersonId === party.ptsIncPersonId &&
        isOffenseValid(incVictim.incVictimDetails.ptsOffenseId)
      ) {
        availableIncVictimOffenses.push(incVictim.incVictimDetails.ptsOffenseId);
        victims.push(incVictim);
      }
    });

    let victimOffenses = [];
    offenses.forEach((offense) => {
      if (
        availableIncVictimOffenses.includes(offense.offenseDetails.ptsOffenseId) &&
        offense.offenseDetails.values.excludeOffense === false &&
        offense.offenseDetails.values?.statuteDetails?.FBICode !== '0'
      ) {
        victimOffenses.push(offense);
      }
    });

    let availableIncSubjectOffenses = [];
    let offenders = [];
    incSubjects.forEach((incSubject) => {
      if (
        incSubject.incSubjectDetails.ptsIncPersonId === party.ptsIncPersonId &&
        isOffenseValid(incSubject.incSubjectDetails.ptsOffenseId)
      ) {
        availableIncSubjectOffenses.push(incSubject.incSubjectDetails.ptsOffenseId);
        offenders.push(incSubject);
      }
    });

    let subjectOffenses = [];
    offenses.forEach((offense) => {
      if (
        availableIncSubjectOffenses.includes(offense.offenseDetails.ptsOffenseId) &&
        offense.offenseDetails.values.excludeOffense === false &&
        offense.offenseDetails.values?.statuteDetails?.FBICode !== '0'
      ) {
        subjectOffenses.push(offense);
      }
    });

    let objToValidate = {
      age: party.personDetail.values.age ? parseInt(party.personDetail.values.age) : 0,
      sex: party.personDetail.values.sex,
      race: party.personDetail.values.race,
      ethnicity: party.personDetail.values.ethnicity,
      victims: victims,
      victimOffenses: victimOffenses,
      offenders: offenders,
      offenderOffenses: subjectOffenses,
      personForm: party.personDetail,
    };

    let keys = ['age', 'sex', 'race', 'ethnicity'];

    let arrestee = incArrestees.find(
      (incArrestee) =>
        incArrestee.incArresteeDetails.ptsIncPersonId === party.ptsIncPersonId &&
        isOffenseValid(incArrestee.incArresteeDetails.ptsOffenseId)
    );

    let isArrestee = arrestee ? true : party.personDetail.values.isArrestee ? true : false;

    if (isArrestee) {
      objToValidate = {
        ...objToValidate,
        arresteeAge: party.personDetail.values.age ? parseInt(party.personDetail.values.age) : 0,
        arresteeRace: party.personDetail.values.race,
        arresteeSex: party.personDetail.values.sex,
      };
      keys = ['age', 'arresteeAge', 'arresteeRace', 'arresteeSex', 'sex'];
    }

    let partiesValidationContext = partiesSchema.newContext();
    let result = partiesValidationContext.validate(objToValidate, {
      keys: keys,
    });

    if (!result) {
      partiesValidationContext.validationErrors().forEach((error) => {
        validationErrors.push({
          id: party.ptsIncPersonId,
          idType: 'ptsIncPersonId',
          errType: error.type,
          field: error.name,
          screen: 'Party',
          name,
          message: partiesValidationContext.keyErrorMessage(error.name),
          ptsIncidentId: currentState.ptsIncidentId,
          partyType: party.personDetail.values.partyType,
          ptsIncPersonId: party.ptsIncPersonId,
          identifier: `${currentState.ptsIncidentId}-${error.type.split('-').pop()}-${
            error.name
          }-Party-${party.ptsIncPersonId}-${party.personDetail.values.partyType}`,
        });
      });
    }
  }

  return validationErrors;
};
