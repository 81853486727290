import React, { useEffect, useState } from 'react';

import Dialog from 'components/RMSDialog';
import { closeAddWarrant } from 'reducers/PersonDialogsReducer';
import { connect } from 'react-redux';
import WarrantTab from './WarrantTab';

function AddWarrantDialog(props) {
  const [isCloseClick, setIsCloseClick] = useState(false);
  const close = () => {
    //props.closeAddWarrant();
    setIsCloseClick(true);
  };

  return (
    <Dialog toolbar fullScreen onClose={close} title="Add Warrant">
      <WarrantTab isCloseClick={isCloseClick} setIsCloseClick={setIsCloseClick} />
    </Dialog>
  );
}

export default connect(null, {
  closeAddWarrant,
})(AddWarrantDialog);
