import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';

import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';

import Tooltip from 'components/Tooltip';
import RMSXGrid from 'components/RMSXGrid';
import PrintsSearch2 from 'components/RMSSearch/components/PrintsSearch2';
import QuickFilterSearch from 'components/RMSSearch/components/QuickFilterSearch';

import { notify } from 'reducers/NotifierReducer';
import { handleError } from 'reducers/ErrorReducer';
import { showSpinner, hideSpinner } from 'reducers/UiReducer';
import { displayDateTime } from 'reducers/TimeReducer';
import { getFullPermissions } from 'reducers/PermissionsReducer';
import { showPersonNameDialog } from 'reducers/PersonDialogsReducer';
import { getPeopleNames, deletePersonName } from 'reducers/SearchReducer';

const useStyles = makeStyles((theme) => ({
  wrap: {
    padding: theme.spacing(3),
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(2),
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
  filterWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
}));

const defaultColumns = [
  { field: 'ptsNameID', headerName: 'Name ID' },
  'NameType',
  'FirstName',
  'MiddleName',
  'LastName',
  'NickName',
  'Prefix',
  'Suffix',
  {
    field: 'Created',
    headerName: 'Created',
    width: 200,
    valueFormatter: (params) => displayDateTime(params.value),
    hide: false,
  },
  { field: 'CreatedBy', headerName: 'Created By', width: 150, hide: false },
  {
    field: 'Updated',
    headerName: 'Updated',
    width: 200,
    valueFormatter: (params) => displayDateTime(params.value),
    hide: false,
  },
  { field: 'UpdatedBy', headerName: 'Updated By', width: 150, hide: false },
];
const Names = (props) => {
  const classes = useStyles();
  const { ptsPersonID, dataUpdate } = props;

  const [rows, setRows] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [filteredRows, setFilteredRows] = useState([]);
  const [selection, setSelection] = useState(null);
  const [columns, setColumns] = useState(defaultColumns);
  const [isDeleted, setIsDeleted] = useState(false);
  const perms = getFullPermissions('globals', 'People', 'any');
  const canEditPerson = perms.Edit;
  const mountedRef = useRef(true);

  useEffect(() => {
    ptsPersonID && getData();
  }, [isDeleted]);

  useEffect(() => {
    if (!dataUpdate) return;
    dataUpdate.type === 'person-name' && getData();
  }, [dataUpdate]);

  const getData = async () => {
    try {
      const data = await getPeopleNames(ptsPersonID);
      if (!mountedRef.current) return;
      setRows(processData(data.data));
      setLoaded(true);
    } catch (err) {
      props.handleError(err);
    }
  };

  const processData = (data) => {
    return data.map((row) => ({ ...row, id: row.ptsNameID }));
  };

  const handleAdd = () => {
    props.showPersonNameDialog({ ptsPersonID });
  };

  const edit = () => {
    const item = rows.find((row) => row.id === selection);
    props.showPersonNameDialog({ ...item });
  };

  const deleteName = () => {
    const item = rows.find((row) => row.id === selection);
    props.showSpinner();

    deletePersonName(item.ptsNameID)
      .then(() => {
        props.notify('Name Deleted', 'warning');
        setIsDeleted(!isDeleted);
      })
      .catch(props.handleError)
      .finally(props.hideSpinner);
  };

  const renderActions = () => (
    <div className={classes.actions}>
      <Tooltip title="Print">
        <PrintsSearch2 title="Names" data={filteredRows} cols={columns} />
      </Tooltip>
      <Tooltip title="Add Name">
        <Fab size="small" color="secondary" disabled={!canEditPerson} onClick={handleAdd}>
          <AddIcon />
        </Fab>
      </Tooltip>

      {selection !== null && (
        <>
          <Tooltip title="Edit Name">
            <Fab size="small" color="secondary" disabled={!canEditPerson} onClick={edit}>
              <EditIcon />
            </Fab>
          </Tooltip>

          <Tooltip title="Delete Name">
            <Fab size="small" color="secondary" disabled={!canEditPerson} onClick={deleteName}>
              <DeleteIcon />
            </Fab>
          </Tooltip>
        </>
      )}
    </div>
  );

  return (
    <div className={classes.wrap}>
      <div className={classes.filterWrap}>
        <div className={classes.filterWrap}>
          <QuickFilterSearch
            rows={rows}
            columns={columns}
            setFilteredRows={setFilteredRows}
            setData={props.setData}
            style={{ marginTop: 0, paddingBottom: 14 }}
          />
          {renderActions()}
        </div>
      </div>
      <RMSXGrid
        name="PeopleNames"
        columns={columns}
        setColumns={setColumns}
        rows={filteredRows}
        loading={!loaded}
        setSelection={setSelection}
      />
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    editPeopleEmployment: state.dialogs.editPeopleEmployment,
    dataUpdate: state.dataUpdate,
  };
};
export default connect(mapStateToProps, {
  handleError,
  showPersonNameDialog,
  showSpinner,
  hideSpinner,
  notify,
})(Names);
