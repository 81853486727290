export const SET_RECEIVED_FROM_DATE = 'SET_RECEIVED_FROM_DATE';
export const SET_RECEIVED_TO_DATE = 'SET_RECEIVED_TO_DATE';
export const SET_QUICK_FILTER_VALUE = 'SET_QUICK_FILTER_VALUE';
export const SET_EVENT_TYPE_VALUE = 'SET_EVENT_TYPE_VALUE';
export const SET_PLACE_OR_ADDRESS_VALUE = 'SET_PLACE_OR_ADDRESS_VALUE';
export const SET_DISPOSITION_VALUE = 'SET_DISPOSITION_VALUE';
export const SET_TAG_TYPE_VALUE = 'SET_TAG_TYPE_VALUE';
export const SET_TAG_VALUE = 'SET_TAG_VALUE';
export const SET_PARTY_TYPE_VALUE = 'SET_PARTY_TYPE_VALUE';
export const SET_PARTY_NAME_VALUE = 'SET_PARTY_NAME_VALUE';
export const SET_TIME_PERIOD_VALUE = 'SET_TIME_PERIOD_VALUE';
export const GET_EVENTS = 'GET_EVENTS';
export const SET_TOTAL_EVENTS = 'SET_TOTAL_EVENTS';

export const setReceivedFromDate = (date) => {
  return (dispatch) => {
    dispatch({ type: SET_RECEIVED_FROM_DATE, payload: date });
  };
};

export const setReceivedToDate = (date) => {
  return (dispatch) => {
    dispatch({ type: SET_RECEIVED_TO_DATE, payload: date });
  };
};

export const setQuickFilterValue = (value) => {
  return (dispatch) => {
    dispatch({ type: SET_QUICK_FILTER_VALUE, payload: value });
  };
};

export const setEventTypeValue = (value) => {
  return (dispatch) => {
    dispatch({ type: SET_EVENT_TYPE_VALUE, payload: value });
  };
};

export const setPlaceOrAddressValue = (value) => {
  return (dispatch) => {
    dispatch({ type: SET_PLACE_OR_ADDRESS_VALUE, payload: value });
  };
};

export const setDispositionValue = (value) => {
  return (dispatch) => {
    dispatch({ type: SET_DISPOSITION_VALUE, payload: value });
  };
};

export const setTagTypeValue = (value) => {
  return (dispatch) => {
    dispatch({ type: SET_TAG_TYPE_VALUE, payload: value });
  };
};

export const setTagValue = (value) => {
  return (dispatch) => {
    dispatch({ type: SET_TAG_VALUE, payload: value });
  };
};

export const setPartyTypeValue = (value) => {
  return (dispatch) => {
    dispatch({ type: SET_PARTY_TYPE_VALUE, payload: value });
  };
};

export const setPartyNameValue = (value) => {
  return (dispatch) => {
    dispatch({ type: SET_PARTY_NAME_VALUE, payload: value });
  };
};

export const setTimePeriodValue = (value) => {
  return (dispatch) => {
    dispatch({ type: SET_TIME_PERIOD_VALUE, payload: value });
  };
};

export const getEvents = (data) => {
  return async (dispatch) => {
    return dispatch({ type: GET_EVENTS, payload: data });
  };
};

export const setTotalEvents = (data) => {
  return async (dispatch) => {
    return dispatch({ type: SET_TOTAL_EVENTS, payload: data });
  };
};

export default function reducer(
  state = {
    activeEvents: [],
    totalEvents: 0,
    eventFilter: {
      receivedFrom: null,
      receivedTo: null,
      quickFilter: '',
      eventType: '',
      placeOrAddress: '',
      disposition: '',
      tagType: '',
      tagValue: '',
      partyType: '',
      partyName: null,
      timePeriod: 'Custom',
    },
  },
  action
) {
  switch (action.type) {
    case SET_RECEIVED_FROM_DATE:
      return {
        ...state,
        eventFilter: { ...state.eventFilter, receivedFrom: action.payload },
      };
    case SET_RECEIVED_TO_DATE:
      return { ...state, eventFilter: { ...state.eventFilter, receivedTo: action.payload } };
    case SET_QUICK_FILTER_VALUE:
      return { ...state, eventFilter: { ...state.eventFilter, quickFilter: action.payload } };
    case SET_EVENT_TYPE_VALUE:
      return { ...state, eventFilter: { ...state.eventFilter, eventType: action.payload } };
    case SET_PLACE_OR_ADDRESS_VALUE:
      return { ...state, eventFilter: { ...state.eventFilter, placeOrAddress: action.payload } };
    case SET_DISPOSITION_VALUE:
      return { ...state, eventFilter: { ...state.eventFilter, disposition: action.payload } };
    case SET_TAG_TYPE_VALUE:
      return { ...state, eventFilter: { ...state.eventFilter, tagType: action.payload } };
    case SET_TAG_VALUE:
      return { ...state, eventFilter: { ...state.eventFilter, tagValue: action.payload } };
    case SET_PARTY_TYPE_VALUE:
      return { ...state, eventFilter: { ...state.eventFilter, partyType: action.payload } };
    case SET_PARTY_NAME_VALUE:
      return { ...state, eventFilter: { ...state.eventFilter, partyName: action.payload } };
    case SET_TIME_PERIOD_VALUE:
      return { ...state, eventFilter: { ...state.eventFilter, timePeriod: action.payload } };
    case GET_EVENTS:
      return { ...state, activeEvents: action.payload };
    case SET_TOTAL_EVENTS:
      return { ...state, totalEvents: action.payload };
  }

  return state;
}
