import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function police2Icon(props) {
  return (
    <SvgIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="850"
      height="850"
      viewBox="-64.184 0.624 850 850">
      <path
        stroke="#000"
        strokeMiterlimit="10"
        d="M703.716 356.38c-22.084-66.178-22.979-136.421-2.582-203.136l19.469-63.685L631.669.625l-16.58 9.571c-81.285 46.931-160.316 46.931-241.602 0l-12.475-7.201-12.475 7.201c-81.285 46.931-160.317 46.931-241.603 0L90.355.625 1.419 89.562l19.469 63.685c20.397 66.713 19.504 136.958-2.583 203.136-55.595 166.563 22.789 347.501 182.323 420.87l160.382 73.762 160.38-73.762c159.539-73.369 237.922-254.307 182.326-420.873z"
      />
    </SvgIcon>
  );
}

export default police2Icon;
