import React from 'react';

function GrandFatherIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="50"
      viewBox="0 0 50 50"
      {...props}
      preserveAspectRatio="xMidYMid meet">
      <g transform="translate(0.000000,50.000000) scale(0.100000,-0.100000)" stroke="none">
        <path d="M216 464 c-31 -30 -9 -84 34 -84 24 0 50 26 50 50 0 24 -26 50 -50 50 -10 0 -26 -7 -34 -16z" />
        <path d="M162 337 c-18 -19 -22 -35 -22 -85 0 -44 4 -64 14 -68 9 -3 16 -31 21 -80 7 -73 7 -74 34 -74 26 0 29 3 35 53 3 28 6 72 7 97 0 25 4 54 8 65 7 18 10 17 26 -15 11 -19 18 -43 16 -52 -1 -10 3 -18 8 -18 6 0 11 5 11 10 0 6 5 10 10 10 6 0 10 -33 10 -80 0 -47 4 -80 10 -80 13 0 13 167 0 175 -6 4 -26 37 -45 75 -19 38 -44 74 -54 80 -32 16 -66 12 -89 -13z" />
      </g>
    </svg>
  );
}

export default GrandFatherIcon;
