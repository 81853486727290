import React, { Fragment, useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { connect, useSelector } from 'react-redux';

import clsx from 'clsx';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Grid,
  TableCell,
  Badge,
  Avatar,
  Typography,
  lighten,
  makeStyles,
  withStyles,
  useMediaQuery,
} from '@material-ui/core';

import { colors } from '../../../../config/colorVariables';
import {
  countPartiesErrors,
  countArresteesErrors,
  countIncVictimErrors,
} from 'simpl-schema-validation/CountErrors';

import { updateIncPartiesPersonForm } from '../../../../reducers/IncReducer';
import { upsertRecord } from 'reducers/RecordsReducer';
import { displayDateTime } from 'reducers/TimeReducer';
import { describe } from 'reducers/helpers/incHelpers';
import { CodeTagTypeIconRender, IsJuvenile } from 'utils/functions';
import JuvenileIndicator from 'global-components/JuvenileIndicator';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '300px',
    padding: '20px',
  },
  avatar: {
    backgroundColor: colors.cyan,
    height: '55px',
    width: '55px',
  },
  incidentLabel: {
    padding: '3px 0px',
    marginRight: '25px',
    marginTop: '3px !important',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color:
      theme.palette.type === 'light'
        ? lighten(theme.palette.text.primary, 0.1)
        : theme.palette.text.primary,
  },
  warrantIncidentLabel: {
    padding: '3px 0px',
    marginRight: '25px',
    marginTop: '3px !important',
    color: theme.card.bg,
  },
  warrantLabel: {
    padding: '3px 0px',
    marginRight: '25px',
    marginTop: '3px !important',
    color: theme.card.warrantColor,
  },
  female: {
    backgroundColor: colors.pink,
    height: '55px',
    width: '55px',
  },
  other: {
    backgroundColor: colors.teal,
    height: '55px',
    width: '55px',
  },
  cellRoot: {
    border: 'none',
    // verticalAlign: 'bottom',
  },
  detailsText:
    theme.palette.type === 'light'
      ? {
          color: lighten(theme.palette.text.primary, 0.4),
        }
      : {
          color: lighten(theme.palette.text.secondary, 2.5),
        },
  warrantDetailsText: {
    color: theme.card.bg,
  },
  loadingText: {
    width: '100%',
    height: '100%',
    padding: '10px',
  },
  dialogBoxForAttachment: {
    minWidth: '700px',
    maxHeight: '850px',
    minHeight: '350px',
  },
  dialogBoxTitle: {
    background: '#1976d2',
  },
  chipStyle: {
    marginTop: '5px',
    marginLeft: '10px',
    backgroundColor: '#B6C4D2',
    color: '#0b55a1',
    fontSize: '15px',
    padding: '10px',
  },
  badgeStyle: {
    backgroundColor: '#B6C4D2',
    color: '#0b55a1',
    top: '10px',
    right: '14px',
  },
  warrant: {
    background: theme.palette.error.main,
    color: theme.card.bg,
  },
  personStyle: {
    paddingTop: '10px',
  },
  deletedStyle: {
    width: '120px',
    position: 'absolute',
    textAlign: 'center',
    marginRight: '20px',
    right: '0px',
    bottom: '20px',
    fontSize: '15px',
    marginTop: '20px',
    background: '#D33D1D',
    borderRadius: '8px',
    padding: '5px',
  },
}));

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: 10,
    top: 5,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}))(Badge);

const RMSPersonCell = (props) => {
  /** region Component Variables and Props */
  const classes = useStyles();
  const history = useHistory();
  const {
    row,
    wsClient,
    duplicateCheck,
    drag,
    nibrsErrors,
    infoCard,
    incident,
    dispositionIntCode,
    dictionary,
  } = props;

  const { codeWarrantDispositions } = dictionary;
  const [entityType, setEntityType] = useState('');
  const [personImage, setPersonImage] = useState(null);
  const [badgeHasError, setBadgeHasError] = useState(false);
  const turnOfValidation = useSelector((state) => state.incident.turnOfValidation);
  const { codeEthnicity, codeRace, codeTagTypes } = useSelector((state) => state.dictionary);
  const isMediumScreenOrSmaller = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const isLgScreenOrSmaller = useMediaQuery((theme) => theme.breakpoints.down('lg'));

  /** endregion */
  /** region React Hooks */
  useEffect(() => {
    const parts = history.location.pathname.split('/');
    setEntityType(parts[1]);
  }, [row.ptsPersonID]);

  useEffect(() => {
    if (row.simpleListType === 'arrestees') {
      if (countArresteesErrors(row.ptsIncPersonId)) {
        setBadgeHasError(true);
      } else {
        setBadgeHasError(false);
      }
    } else if (row.simpleListType === 'victims') {
      if (row.ptsIncPersonId) {
        if (countIncVictimErrors(row.ptsIncPersonId)) {
          setBadgeHasError(true);
        } else {
          setBadgeHasError(false);
        }
      } else if (row.ptsIncPlaceId) {
        if (countIncVictimErrors(row.ptsIncPlaceId)) {
          setBadgeHasError(true);
        } else {
          setBadgeHasError(false);
        }
      }
    } else if (!row.simpleListType) {
      if (countPartiesErrors(row.ptsIncPersonId)) {
        setBadgeHasError(true);
      } else {
        setBadgeHasError(false);
      }
    } else {
      setBadgeHasError(false);
    }
  }, [row.simpleListType, row.ptsIncPersonId, nibrsErrors, row.ptsIncPlaceId]);

  useEffect(() => {
    if (!wsClient?.websocket) {
      return;
    }
    getPersonPhotos();
  }, [wsClient]);
  /** endregion */
  /** region Helper Functions */
  function dragStart(event) {
    event.dataTransfer.setData('Text', event.target.id);
  }

  function arrayBufferToBase64(buffer) {
    var binary = '';
    var bytes = new Uint8Array(buffer);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }

  const getPersonPhotos = async () => {
    const service = wsClient?.websocket.service('rms-party-lock-photo');
    service.timeout = 200000;
    if (!row.ptsImageId) return;

    const result = await service.get(row.ptsImageId);

    if (result.length !== 0) {
      setPersonImage(result[0].FileObject);
      var base64Data = arrayBufferToBase64(result[0].FileObject);
      setPersonImage(base64Data);
    }
  };

  const calculateAge = (birthday) => {
    const b = new Date(birthday);
    var ageDifMs = Date.now() - b.getTime();
    var ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  };

  const getClassName = (warrantIncidentLabelClass, defaultClass, warrantClass) => {
    if (entityType === 'warrants') {
      const value =
        codeWarrantDispositions.find((item) => item.IntCode === dispositionIntCode) || null;
      if (value && value.Code === 'ACT') return warrantClass;
    }
    return row.activeWarrant ? warrantIncidentLabelClass : defaultClass;
  };

  const generateSexField = (value) => {
    let entity_sex = { M: 'MALE', F: 'FEMALE', U: 'UNKNOWN' };
    if (value) {
      if (value.length > 1) return value;
      else return entity_sex[value];
    } else {
      return '';
    }
  };

  /** endregion */
  if (!row.PersonId && (entityType === 'records' || entityType === 'persons'))
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} className="mt-3">
          <Typography color="textSecondary" variant="row" className={classes.loadingText}>
            Loading
          </Typography>
        </Grid>
      </Grid>
    );
  if (row.PersonId || entityType !== 'records' || row.ptsIncPlaceId)
    return (
      <Fragment>
        {!row.ptsIncPlaceId && (
          <TableCell
            onDragStart={drag ? dragStart : null}
            draggable={drag}
            id={`${row.firstName || ''} ${row.middleName || ''} ${row.lastName || ''} ${
              row.suffix || ''
            } (${describe(row.race, codeRace) || ''} ${generateSexField(row.sex) || ''} ${
              row.dob || ''
            })`}
            className={getClassName(classes.warrant)}
            key={row.fullName}>
            <span style={{ display: 'none' }}>{row.fullName}</span>
            <td>
              <div className="d-flex align-items-center">
                {!['records', 'persons', 'warrants'].includes(entityType) && !duplicateCheck && (
                  <div style={{ marginRight: '28px' }}>
                    <Badge badgeContent={'MP'} color="secondary" invisible={!row.isMP}>
                      <StyledBadge
                        badgeContent={badgeHasError && !turnOfValidation ? '!' : 0}
                        color="error">
                        {personImage === null && (
                          <Avatar
                            aria-label="avatar"
                            className={clsx(
                              row.sex === 'M' && classes.avatar,
                              row.sex === 'F' && classes.female,
                              row.sex === 'U' && classes.other
                            )}>
                            <FontAwesomeIcon icon="user" />
                          </Avatar>
                        )}

                        {personImage !== null && (
                          <Avatar
                            alt={row.firstName}
                            className={classes.avatar}
                            src={'data:image/png;base64, ' + personImage}
                          />
                        )}
                      </StyledBadge>
                    </Badge>
                  </div>
                )}

                <div>
                  <div style={{ display: 'flex' }}>
                    <Typography
                      variant={entityType === 'records' || entityType === 'persons' ? 'h4' : 'h5'}
                      className={getClassName(
                        classes.warrantIncidentLabel,
                        classes.incidentLabel,
                        classes.warrantLabel
                      )}>
                      {row.PersonId} {row.PersonId ? ` | ` : ``}{' '}
                      {`${row.lastName || ''}${row.suffix ? ` ${row.suffix}` : ''}${
                        row.lastName && (row.firstName || row.middleName) ? ', ' : ''
                      }${row.firstName || ''} ${row.middleName || ''}`.trim()}
                    </Typography>
                  </div>
                  <div
                    className={entityType === 'persons' ? classes.personStyle : ''}
                    style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography
                      variant="h6"
                      className={clsx(
                        getClassName(classes.warrantDetailsText, classes.detailsText),
                        ' d-block'
                      )}>
                      DOB: {row.dob}
                    </Typography>
                    {incident && (
                      <JuvenileIndicator
                        IsJuvenile={IsJuvenile('parties', {
                          dob: row.dob,
                          age: row.age,
                          occurred: incident.values?.occurred || incident.values?.created,
                        })}
                        text="J"
                        customStyle={{ width: '30px', height: '30px', marginRight: '35px' }}
                      />
                    )}
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <div style={{ paddingRight: '10px' }}>
                      <Typography
                        variant="h6"
                        className={clsx(
                          getClassName(classes.warrantDetailsText, classes.detailsText),
                          ' d-block'
                        )}>
                        Age: {row.age ? row.age : row.dob ? calculateAge(row.dob) : null}
                      </Typography>
                      <Typography
                        variant="h6"
                        className={clsx(
                          getClassName(classes.warrantDetailsText, classes.detailsText),
                          ' d-block'
                        )}>
                        Sex: {generateSexField(row.sex)}
                      </Typography>
                    </div>
                    <div style={{ paddingLeft: '10px' }}>
                      <Typography
                        variant="h6"
                        className={clsx(
                          getClassName(classes.warrantDetailsText, classes.detailsText),
                          ' d-block'
                        )}>
                        Race: {describe(row.race, codeRace)}
                      </Typography>
                      <Typography
                        variant="h6"
                        className={clsx(
                          getClassName(classes.warrantDetailsText, classes.detailsText),
                          ' d-block'
                        )}>
                        Ethnicity: {describe(row.ethnicity, codeEthnicity)}
                      </Typography>
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </TableCell>
        )}
        {row.ptsIncPlaceId && (
          <TableCell
            onDragStart={drag ? dragStart : null}
            draggable={drag}
            id={`${row.PlaceId || ''}, ${row.PlaceName || ''}`}
            className={getClassName(classes.warrant)}
            key={row.PlaceName}>
            <span style={{ display: 'none' }}>{row.PlaceName}</span>
            <td>
              <div className="d-flex align-items-center">
                {entityType !== 'records' && entityType !== 'persons' && !duplicateCheck && (
                  <div style={{ marginRight: '28px' }}>
                    <Badge badgeContent={'MP'} color="secondary" invisible={!row.isMP}>
                      <StyledBadge
                        badgeContent={badgeHasError && !turnOfValidation ? '!' : 0}
                        color="error">
                        <Avatar aria-label="avatar" className={classes.avatar}>
                          <FontAwesomeIcon icon="user" />
                        </Avatar>
                      </StyledBadge>
                    </Badge>
                  </div>
                )}

                <div>
                  <div style={{ display: 'flex' }}>
                    <Typography
                      variant={entityType === 'records' || entityType === 'persons' ? 'h4' : 'h5'}
                      className={getClassName(classes.warrantIncidentLabel, classes.incidentLabel)}>
                      {row.PlaceId} {row.PlaceId ? ` | ` : ``} {row.PlaceName || ''}
                    </Typography>
                  </div>
                </div>
              </div>
            </td>
          </TableCell>
        )}
        <TableCell className={getClassName(classes.warrant)} key={row.partyType}>
          {row.partyType}
        </TableCell>
        <TableCell className={getClassName(classes.warrant)} key={row.ssn}>
          <Typography
            variant="body"
            className={clsx(
              getClassName(classes.warrantDetailsText, classes.detailsText),
              ' d-block'
            )}>
            {row.ssn && row.ssn != 0 ? 'SSN: ' + row.ssn : ''}
          </Typography>
          <Typography
            variant="body"
            className={clsx(
              getClassName(classes.warrantDetailsText, classes.detailsText),
              ' d-block'
            )}>
            {row.oln && row.oln != 0 ? 'OLN: ' + row.oln : ''}
          </Typography>
        </TableCell>
        {((!isMediumScreenOrSmaller && props.origin !== 'warrantsInfoCard') ||
          (props.origin === 'warrantsInfoCard' && !isLgScreenOrSmaller)) && (
          <TableCell className={getClassName(classes.warrant)} key="info">
            <td st>
              <div className="d-flex align-items-center justify-content-center">
                <div>
                  {!row.ptsIncPlaceId && (
                    <Typography
                      variant="body"
                      className={clsx(
                        getClassName(classes.warrantDetailsText, classes.detailsText),
                        ' d-block'
                      )}>
                      <span> Email: </span> {row.email}
                    </Typography>
                  )}
                  {!row.ptsIncPlaceId && (
                    <Typography
                      variant="body"
                      className={clsx(
                        getClassName(classes.warrantDetailsText, classes.detailsText),
                        ' d-block'
                      )}>
                      <span> Phone: </span> {row.phone1 || row.phone2}
                    </Typography>
                  )}
                  <Typography
                    variant="body"
                    className={clsx(
                      getClassName(classes.warrantDetailsText, classes.detailsText),
                      ' d-block'
                    )}>
                    <span> Address: </span> {row.fullAddressText || row.FullAddressText}
                  </Typography>
                </div>
                {infoCard && entityType !== 'warrants' && (
                  <JuvenileIndicator
                    IsJuvenile={IsJuvenile('person', {
                      dob: row.dob,
                      age: row.age,
                    })}
                    text="JUVENILE"
                    type="text"
                    customStyle={{
                      width: '120px',
                      position: 'absolute',
                      textAlign: 'center',
                      marginRight: row.IsDeleted ? '150px' : '35px',
                      right: '0px',
                      bottom: '20px',
                    }}
                  />
                )}
                {row.IsDeleted && <Typography className={classes.deletedStyle}>Deleted</Typography>}
              </div>
            </td>
          </TableCell>
        )}
        <TableCell className={getClassName(classes.warrant)} key="created">
          <Typography
            variant="body"
            className={clsx(
              getClassName(classes.warrantDetailsText, classes.detailsText),
              ' d-block'
            )}>
            {row.createdBy}
          </Typography>
          <Typography
            variant="body"
            className={clsx(
              getClassName(classes.warrantDetailsText, classes.detailsText),
              ' d-block'
            )}>
            {displayDateTime(row.created)}
          </Typography>
        </TableCell>
        <TableCell className={getClassName(classes.warrant)} key="updated">
          <Typography
            variant="body"
            className={clsx(
              getClassName(classes.warrantDetailsText, classes.detailsText),
              ' d-block'
            )}>
            {row.updatedBy}
          </Typography>
          <Typography
            variant="body"
            className={clsx(
              getClassName(classes.warrantDetailsText, classes.detailsText),
              ' d-block'
            )}>
            {displayDateTime(row.updated)}
          </Typography>
        </TableCell>
      </Fragment>
    );
};

const mapStateToProps = (state) => ({
  wsClient: state.websocket,
  partiesPersonForm: state.incident.partiesPersonForm,
  nibrsErrors: state.incident.nibrsErrors,
  dictionary: state.dictionary,
});

export default connect(mapStateToProps, {
  upsertRecord,
  updateIncPartiesPersonForm,
})(RMSPersonCell);
