import React from 'react';
import { connect } from 'react-redux';

import {
  Card,
  CardContent,
  Button,
  FormControl,
  TextField,
  InputAdornment,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { setPasswordFieldShouldReset, submitLoginForm } from '../reducers/UserReducer';
import { checkForUpdates } from '../reducers/AppInfoReducer';
import splashScreen from '../assets/images/login-splash.jpg';
import IconRms from '../assets/images/icon-RMS.svg';
import ptsLogo from '../assets/images/PTS-logo.png';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';

import { notify } from 'reducers/NotifierReducer';
import { sendPassResetLink } from '../reducers/UserReducer';
import { validateEmail } from 'utils/functions';

const useStyles = makeStyles((theme) => ({
  pageWrap: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: `url(${splashScreen})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  card: {
    width: 480,
    maxWidth: '95%',
    background: 'fff',
    '& img': {
      width: '100%',
    },
  },
  content: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  branding: {
    width: '100%',
    height: 200,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#0051a0',
    '& img': {
      width: 150,
      height: 'auto',
    },
  },
  branding2: {
    width: '100%',
    textAlign: 'center',
    padding: '25px 0 5px',
    '& img': {
      height: 50,
      width: 'auto',
    },
  },
  actions: {
    textAlign: 'center',
    marginTop: 30,
    '& button': {
      background: '#0051a0',
      color: '#fff',
      paddingLeft: '4em',
      paddingRight: '4em',
      textTransform: 'uppercase',
      '&:hover': {
        background: '#021090',
      },
    },
  },
  passReset: {
    textAlign: 'right',
    '& span': {
      fontSize: 13,
      color: '#808080',
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
  version: {
    position: 'absolute',
    bottom: '0.5em',
    left: '1em',
    lineHeight: 1,
  },
}));

const PageLogin = (props) => {
  const { themeMode, checkForUpdates } = props;
  const { appVersion } = props.appInfo;
  const classes = useStyles();
  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [view, setView] = React.useState('login'); // login, pass-reset
  const [showPassword, setShowPassword] = React.useState(false);

  React.useEffect(() => {
    props.checkForUpdates();
  }, []);

  React.useEffect(() => {
    if (props.passwordFieldShouldReset) {
      setPassword('');
    }
    props.setPasswordFieldShouldReset(false);
  }, [props.passwordFieldShouldReset]);

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleResetEmailChange = (event) => {
    setEmail(event.target.value.toLowerCase());
  };

  const submitForm = (event) => {
    event.preventDefault();
    props.submitLoginForm(username, password);
  };

  const passResetSubmit = async (event) => {
    event.preventDefault();
    try {
      await sendPassResetLink(email);
      setEmail('');
      setView('login');
      props.notify(
        'Reset password link will be sent to the email provided, if it exists in our database.'
      );
    } catch (err) {
      console.log(err);
      props.notify('Error sending password reset link', 'error');
    }
  };

  const renderForgotPassword = () => {
    const valid = validateEmail(email);
    const error = valid || !email ? '' : 'Please enter valid e-mail address';
    return (
      <form onSubmit={passResetSubmit}>
        <div className="mb-3">
          <FormControl size="small" className="w-100">
            <TextField
              autoComplete="hidden"
              size="small"
              required
              variant="outlined"
              label="E-mail address"
              fullWidth
              placeholder="Enter your e-mail address"
              type="email"
              value={email}
              onChange={handleResetEmailChange}
              error={error}
              helperText={error}
            />
          </FormControl>
        </div>
        <div className={classes.passReset}>
          <span onClick={() => setView('login')}>Sign in</span>
        </div>

        <div className={classes.actions}>
          <Button type="submit" variant="contained" size="large" className="my-2">
            Send reset link
          </Button>
        </div>
      </form>
    );
  };

  return (
    <div className={classes.pageWrap}>
      <Card className={classes.card}>
        <div className={classes.branding}>
          <img src={IconRms} alt="MOBILE icon" />
        </div>
        <div className={classes.branding2}>
          <img src={ptsLogo} alt="PTS logo" />
        </div>
        <CardContent>
          {view === 'login' && (
            <form onSubmit={submitForm}>
              <div className="mb-3">
                <FormControl size="small" className="w-100">
                  <TextField
                    autoComplete="hidden"
                    size="small"
                    required
                    variant="outlined"
                    label="Username"
                    fullWidth
                    placeholder="Enter your username"
                    type="text"
                    value={username}
                    onChange={handleUsernameChange}
                  />
                </FormControl>
              </div>
              <div className="mb-3">
                <FormControl size="small" className="w-100">
                  <TextField
                    inputRef={(input) => props.passwordFieldShouldReset === true && input?.focus()}
                    size="small"
                    required
                    variant="outlined"
                    label="Password"
                    fullWidth
                    placeholder="Enter your password"
                    type={showPassword ? 'text' : 'password'}
                    value={password}
                    onChange={handlePasswordChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}>
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              </div>

              <div className={classes.passReset}>
                <span onClick={() => setView('pass-reset')}>Password reset</span>
              </div>

              <div className={classes.actions}>
                <Button type="submit" variant="contained" size="large" className="my-2">
                  Login
                </Button>
              </div>
            </form>
          )}
          {view === 'pass-reset' && renderForgotPassword()}

          <div className={classes.version}> v. {appVersion}</div>
        </CardContent>
      </Card>
    </div>
  );
};

const mapStateToProps = (state) => ({
  themeMode: state.theme.mode,
  appInfo: state.appInfo,
  passwordFieldShouldReset: state.user.passwordFieldShouldReset,
});

export default connect(mapStateToProps, {
  submitLoginForm,
  checkForUpdates,
  notify,
  setPasswordFieldShouldReset,
})(PageLogin);
