import store from '../../config/configureStore';

import { getService } from './service';

const checkForAddress = async (addressForm, addressType, searchParams) => {
  const client = store.store.getState().websocket.websocket;
  const modalState = store.store.getState().uiModal;
  const values = addressForm.values;
  let paramCount = 0;

  if (Object.keys(values).length === 0) return false;

  if (!client) return;
  try {
    const service = client.service('rms-address-duplicates');
    service.timeout = 20000;
    let queryData = {
      AddressNumber: values.addressNumber || null,
      AddressNumberPrefix: values.addressNumberPrefix || null,
      AddressNumberSuffix: values.addressNumberSuffix || null,
      StreetName: values.streetName || null,
      StreetType: values.streetType || null,
      PostDirection: values.postDirection || null,
      PreDirection: values.preDirection || null,
      ptsCityID: values.cityId || null,
      State: values.state || null,
      PostalCode: values.postalCode || null,
      PostalCodeExtension: values.postalCodeExtension || null,
    };

    searchParams.map((s) => {
      if (queryData[s] !== null) paramCount++;
    });

    if (paramCount !== searchParams.length) return;

    let result = await service.find({
      query: queryData,
    });

    let duplicateFound = false;
    if (result.length > 0) {
      if (addressForm.ptsAddressID || addressForm.ptsAddressId) {
        let addressId = addressForm.ptsAddressID || addressForm.ptsAddressId;
        result = result.filter((r) => r.ptsAddressID !== addressId);

        if (result.length > 0) {
          duplicateFound = true;
        } else {
          duplicateFound = false;
        }
      } else {
        duplicateFound = true;
      }
    }

    if (addressType === 'general') {
      modalState.duplicateListOfAddress = result;
    } else if (addressType === 'contact') {
      modalState.duplicateListOfAddressForContact = result;
    } else if (addressType === 'employment') {
      modalState.duplicateListOfAddressForEmployment = result;
    }

    return duplicateFound;
  } catch (e) {
    console.log(e);
  }
};

const checkForPerson = async (personForm, searchParams) => {
  const client = store.store.getState().websocket.websocket;
  const modalState = store.store.getState().uiModal;
  let values = personForm.values;
  let paramCount = 0;

  if (Object.keys(values).length === 0) return false;

  if (!client) return;
  try {
    const service = client.service('rms-person-duplicates');
    service.timeout = 20000;
    let queryData = {
      FirstName: values.firstName || null,
      LastName: values.lastName || null,
      MiddleName: values.middleName || null,
      NickName: values.nickName || null,
      Prefix: values.prefix || null,
      Suffix: values.suffix || null,
      Born: values.birthdate || null,
      Race: values.race || null,
      Sex: values.sex || null,
      OLNNumber: values.oln || null,
      SSNNumber: values.ssn || null,
    };

    searchParams.map((s) => {
      if (queryData[s] !== null) paramCount++;
    });

    if (paramCount !== searchParams.length) return;

    let result = await service.find({
      query: queryData,
    });

    let duplicateFound = false;
    if (result.length > 0) {
      if (personForm.ptsPersonID || personForm.ptsPersonId) {
        let personId = personForm.ptsPersonID || personForm.ptsPersonId;
        result = result.filter((r) => r.ptsPersonID !== personId);

        if (result.length > 0) {
          duplicateFound = true;
        } else {
          duplicateFound = false;
        }
      } else {
        duplicateFound = true;
      }
    }
    modalState.duplicateListOfPerson = result;

    return duplicateFound;
  } catch (e) {
    console.log(e);
  }
};

const checkForPlace = async (values) => {
  const client = store.store.getState().websocket.websocket;
  const modalState = store.store.getState().uiModal;

  if (Object.keys(values).length === 0) return false;

  if (!client) return;
  try {
    const service = client.service('rms-place-duplicates');
    service.timeout = 20000;
    let queryData = {
      PlaceName: values.PlaceName || '',
    };

    let result = await service.find({
      query: queryData,
    });

    let duplicateFound = false;
    if (result.length > 0) {
      if (values.ptsPlaceID || values.ptsPlaceId) {
        let placeId = values.ptsPlaceID || values.ptsPlaceId;
        result = result.filter((r) => r.ptsPlaceID !== placeId);

        if (result.length > 0) {
          duplicateFound = true;
        } else {
          duplicateFound = false;
        }
      } else {
        duplicateFound = true;
      }
    }
    modalState.duplicateListOfPlace = result;

    return duplicateFound;
  } catch (e) {
    console.log(e);
  }
};

const checkForVehicle = async (vehicleForm) => {
  const client = store.store.getState().websocket.websocket;
  const modalState = store.store.getState().uiModal;
  let values = vehicleForm.values;

  if (!client) return;
  try {
    const service = client.service('rms-vehicle-duplicates');
    service.timeout = 20000;
    let queryData = {
      PlateState: values.plateState || '',
      PlateNumber: values.plateNumber || '',
      VIN: values.vin || '',
    };

    let result = await service.find({
      query: queryData,
    });

    let duplicateFound = false;
    if (result.length > 0) {
      if (vehicleForm.ptsVehicleID || vehicleForm.ptsVehicleId) {
        let vehicleId = vehicleForm.ptsVehicleID || vehicleForm.ptsVehicleId;
        result = result.filter((r) => r.ptsVehicleID !== vehicleId);

        if (result.length > 0) {
          duplicateFound = true;
        } else {
          duplicateFound = false;
        }
      } else {
        duplicateFound = true;
      }
    }

    modalState.duplicateListOfVehicle = result;

    return duplicateFound;
  } catch (e) {
    console.log(e);
  }
};

export const duplicateCheck = async (searchParams, form, entity, addressType = 'general') => {
  const modalState = store.store.getState().uiModal;
  let duplicateFound = false;

  if (entity === 'person') {
    duplicateFound = await checkForPerson(form, searchParams);
    if (duplicateFound) {
      modalState.showDuplicateModal = duplicateFound;
      modalState.duplicateModalMessage = 'Duplicate List';
    }
  } else if (entity === 'address') {
    duplicateFound = await checkForAddress(form, addressType, searchParams);

    if (duplicateFound) {
      modalState.showDuplicateModal = duplicateFound;
      modalState.duplicateModalMessage = 'Duplicate List';
    }
  } else if (entity === 'vehicle') {
    duplicateFound = await checkForVehicle(form);
    if (duplicateFound) {
      modalState.showDuplicateModal = duplicateFound;
      modalState.duplicateModalMessage = 'Duplicate List';
    }
  } else if (entity === 'place') {
    duplicateFound = await checkForPlace(form);
    if (duplicateFound) {
      modalState.showDuplicateModal = duplicateFound;
      modalState.duplicateModalMessage = 'Duplicate List';
    }
  }

  return duplicateFound;
};

export const duplicateParamsCheck = async (key) => {
  const service = getService('cdr');
  const paramsValue = await service.find({ query: { CDRKey: key, ValueBoolean: true } });
  let paramsArr = [];

  paramsValue.map((p) => {
    let paramPath = p.Path.split('.');
    let param = paramPath[paramPath.length - 1];

    if (param !== 'CheckForDuplicates' && param !== 'UnitIdentifier') {
      if (param === 'DOB') paramsArr.push('Born');
      else if (param === 'OLN') paramsArr.push('OLNNumber');
      else if (param === 'Social') paramsArr.push('SSNNumber');
      else if (param === 'AddressNum') paramsArr.push('AddressNumber');
      else if (param === 'AddressNumPrefix') paramsArr.push('AddressNumberPrefix');
      else if (param === 'AddressNumSuffix') paramsArr.push('AddressNumberSuffix');
      else if (param === 'CityDescription') paramsArr.push('ptsCityID');
      else paramsArr.push(param);
    }
  });

  return paramsArr;
};
