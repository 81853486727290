import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { sortObjArr } from '../../../../utils/functions';
import PerfectScrollbar from 'react-perfect-scrollbar';

const statusWidth = 100;

const useStyles = makeStyles((theme) => ({
  cases: {
    maxHeight: '550px',
    overflowY: 'auto',
  },
  case: {
    padding: '5px 15px 5px',
    borderBottom: '1px solid #D3D3D3',
    color: theme.palette.type === 'light' ? 'rgba(0, 0, 0, 0.87)' : '#d4d6d7',
    '& p': {
      fontSize: 13,
    },
  },
  edit: {
    padding: `${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(1)}px`,
  },
  actions: {
    padding: '5px 15px',
    textAlign: 'right',
    '& label': {
      marginBottom: 0,
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& h4': {
      fontSize: '1em',
      minWidth: 130,
      marginBottom: 0,
    },
  },
  date: {
    maxWidth: 150,
    display: 'inline-block',
  },
  statusTd: {
    flex: `0 0 ${statusWidth}px`,
    width: statusWidth,
    boxSizing: 'border-box',
  },
  left: {
    display: 'flex',
    alignItems: 'center',
  },
  status: {
    textAlign: 'center',
    '& > div': {
      fontSize: '1rem',
      fontWeight: 500,
      padding: '0 5px',
      borderRadius: 5,
    },
    '& i': {
      padding: '2px 10px 1px',
      borderRadius: '4px',
      display: 'inline-block',
      textTransform: 'uppercase',
      fontWeight: 500,
      fontSize: 12,
      fontStyle: 'normal',
    },
  },
}));

function Case(props) {
  const classes = useStyles();
  const { AgencyId, CaseId } = props.singleCase;

  return (
    <div className={classes.case}>
      <PerfectScrollbar style={{ maxHeight: window.innerHeight - 500 + 'px' }}>
        <div className={classes.header}>
          <div className={classes.left}>
            <h4>{CaseId}</h4>
          </div>
          <div>
            <span className={classes.date}>{AgencyId}</span>
          </div>
        </div>
      </PerfectScrollbar>
    </div>
  );
}

function CaseIDTab(props) {
  const { ColorScheme, sortOption } = props;
  const [cases, setCases] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    if (!props.cases) return;
    setCases(sortObjArr(props.cases, 'CaseId', sortOption));
  }, [props.cases, sortOption]);

  const renderCaseIds = () => {
    return (
      <>
        {cases.map((singleCase) => (
          <Case key={singleCase.CaseId} singleCase={singleCase} ColorScheme={ColorScheme} />
        ))}
      </>
    );
  };

  if (!cases) return '';
  return <div className={classes.cases}>{renderCaseIds()}</div>;
}

export default CaseIDTab;
