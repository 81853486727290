import React, { useState } from 'react';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Grid from '@material-ui/core/Grid';
import { IconButton } from '@material-ui/core';
import { connect, useDispatch } from 'react-redux';

import {
  updateFormOpen,
  setModalForm,
  setModalFormType,
  setModalResetTextFieldFunction,
  setModalServiceName,
} from 'reducers/AddEntityFormReducer';
import {
  popupModal,
  setService,
  setMode,
  setLabel,
  setUpdatableObject,
  resetFieldFunc,
  /* resetForm, */
} from 'reducers/CodeCrudFormReducer';

function RMSComboBoxListItem(props) {
  const {
    option,
    label,
    title,
    updateFormOpen,
    setModalForm,
    setModalFormType,
    resetField,
    setModalResetTextFieldFunction,
    serviceName,
    setModalServiceName,
    permission,
    hasDifferentPrimaryKey,
    renderOption,
  } = props;

  const dispatch = useDispatch();
  const [optionsVisibility, setOptionsVisibility] = useState(false);

  return (
    <React.Fragment>
      <Grid
        container
        onMouseOver={() => {
          setOptionsVisibility(true);
        }}
        onMouseLeave={() => {
          setOptionsVisibility(false);
        }}>
        <Grid justify="flex-start" container sm={9}>
          {props.noTitle ? option : renderOption}
        </Grid>
        <Grid justify="flex-end" container sm={3}>
          {permission.Edit && (
            <>
              <IconButton
                hidden={!optionsVisibility}
                style={{ margin: 0, padding: 0 }}
                aria-label="edit"
                onClick={() => {
                  if (hasDifferentPrimaryKey) {
                    dispatch(setMode('edit'));
                    dispatch(setService(serviceName));
                    dispatch(setUpdatableObject(option));
                    dispatch(setLabel(label));
                    dispatch(popupModal(true));
                    dispatch(resetFieldFunc(resetField));
                  } else {
                    if (serviceName) setModalServiceName(serviceName);
                    setModalForm(option);
                    setModalFormType('edit');
                    updateFormOpen(label);
                    setModalResetTextFieldFunction(resetField);
                  }
                }}>
                <EditIcon style={{ fontSize: '17px' }} />
              </IconButton>
            </>
          )}

          {permission.Delete && (
            <>
              <span style={{ marginLeft: '5px' }}></span>
              <IconButton
                style={{ margin: 0, padding: 0 }}
                aria-label="delete"
                hidden={!optionsVisibility}
                onClick={() => {
                  if (!hasDifferentPrimaryKey) {
                    if (serviceName) setModalServiceName(serviceName);
                    setModalForm(option);
                    setModalFormType('delete');
                    updateFormOpen(label);
                    setModalResetTextFieldFunction(resetField);
                  } else {
                    dispatch(setMode('remove'));
                    dispatch(setService(serviceName));
                    dispatch(setLabel(label));
                    dispatch(setUpdatableObject(option));
                    dispatch(popupModal(true));
                    dispatch(resetFieldFunc(resetField));
                  }
                }}>
                <DeleteIcon style={{ fontSize: '17px' }} />
              </IconButton>
            </>
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

const mapStateToProps = () => ({});

export default connect(mapStateToProps, {
  updateFormOpen,
  setModalForm,
  setModalFormType,
  setModalResetTextFieldFunction,
  setModalServiceName,
})(RMSComboBoxListItem);
