import { makeStyles } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Autocomplete, Grid, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { useSelector } from 'react-redux';
import RMSDictionary from 'components/RMSAutoComplete/RMSDictonary';

const useStyles = makeStyles((theme) => ({
  reportContainer: {
    display: 'flex',
  },
  reportSelection: {
    flex: 0.3,
  },
  reportFilter: {
    flex: 0.7,
  },
  report: {
    paddingBottom: '4px',
  },
}));

const IncidentSeverityByZoneReport = (props) => {
  const classes = useStyles();
  const { reportForm, handleReportFormChange, ReportID } = props;
  const formValue = reportForm[ReportID] || {};
  const [ptsStatueArray, setPtsStatueArray] = useState([]);
  const agencySettings = useSelector((state) => state.agencySettings.settings);
  const userAgency = useSelector((state) => state.user.userAgency);
  const ptsStatute = useSelector((state) => state.dictionary.ptsStatute);
  const ptsFBICode = useSelector((state) => state.dictionary.ptsFBICode);

  const legacyExportSetting = agencySettings?.find(
    (item) => item.Path === `${userAgency}.RMS.AllowCitationLegacyExport`
  );
  const isLegacyExportEnable =
    legacyExportSetting && legacyExportSetting?.ValueBoolean === true ? true : false;

  useEffect(() => {
    let sortedArray = ptsStatute
      .filter((s) => s.Description !== '')
      .sort((a, b) => (a.Description > b.Description ? 1 : -1));
    let arr = [];

    sortedArray.forEach((s) => {
      let fbCode = ptsFBICode.find((fb) => fb.ptsFBICodeID === s.ptsFBICodeID)?.FBICode;
      s.FBICode = fbCode;
      arr.push(s);
    });
    if (isLegacyExportEnable) {
      arr = arr.filter((item) => item.StatuteID);
    }

    setPtsStatueArray(arr);
  }, []);

  return (
    <div className={classes.report}>
      <Typography variant="subtitle1" className="py-2">
        {props.title}
      </Typography>
      <Grid container justify="left" alignItems="center" spacing={1}>
        <Grid item xs={4}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableOpenOnEnter
              autoComplete="off"
              fullWidth
              clearable
              size="small"
              inputVariant="outlined"
              showTodayButton={true}
              variant="dialog"
              format="MM/dd/yyyy"
              id="date-picker-inline-from"
              label="occurred From Beginning"
              defaultValue={null}
              value={formValue.occurredFromBeginning || null}
              onChange={(date) => handleReportFormChange(ReportID, 'occurredFromBeginning', date)}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={4}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableOpenOnEnter
              autoComplete="off"
              fullWidth
              clearable
              size="small"
              inputVariant="outlined"
              showTodayButton={true}
              variant="dialog"
              format="MM/dd/yyyy"
              id="date-picker-inline-from"
              label="occurred From  Ending"
              defaultValue={null}
              value={formValue.occurredFromEnding || null}
              onChange={(date) => handleReportFormChange(ReportID, 'occurredFromEnding', date)}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={4}>
          <RMSDictionary
            options="codeStatuteSeverity"
            multiple={true}
            value={formValue.offenseSeverities || []}
            label="Offense Severity"
            compact
            onChange={(ev, newVal) => {
              handleReportFormChange(ReportID, 'offenseSeverities', newVal);
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <Autocomplete
            multiple
            size="small"
            autoComplete
            autoSelect={false}
            autoHighlight
            getOptionLabel={(option) => {
              return `${option.Description} | ${option.Code} | ${option.FBICode}`;
            }}
            options={ptsStatueArray}
            key={formValue?.offenses || ''}
            value={formValue?.offenses || []}
            onChange={(event, newValue) => {
              handleReportFormChange(ReportID, 'offenses', newValue);
            }}
            renderInput={(params) => (
              <TextField
                autoComplete="off"
                size="small"
                {...params}
                label="Search offense"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default IncidentSeverityByZoneReport;
