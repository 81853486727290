import React from 'react';

function SexOffenderIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="50"
      viewBox="0 0 50 50"
      preserveAspectRatio="xMidYMid meet"
      {...props}>
      <g transform="translate(0.000000,50.000000) scale(0.100000,-0.100000)" stroke="none">
        <path d="M208 453 c-75 -3 -88 -6 -88 -21 0 -10 -18 -39 -39 -65 -67 -80 -74  -139 -36 -271 l16 -56 110 0 c123 0 120 -3 98 86 -9 36 -15 44 -28 38 -31 -13 -71 -16 -71 -5 0 6 9 11 21 11 11 0 29 5 39 10 15 8 26 4 53 -20 19 -16 42 -30 51 -30 9 0 16 -5 16 -11 0 -8 -11 -9 -31 -5 -26 5 -30 3 -25 -10 3 -9 6 -27 6 -40 0 -23 2 -24 79 -24 l79 0 12 86 c7 48 10 105 7 129 -4 26 -2 46 5 53 9 9 9 12 -2 12 -8 0 -43 -28 -77 -62 -34 -34 -66 -59 -69 -55 -4 4 3 18 16 32 42 45 11 40 -35 -5 -54 -53 -61 -32 -8 22 51 52 20 49 -34 -4 -23 -23 -46 -39 -50 -35 -4 4 15 30 42 57 27 27 46 53 42 57 -4 4 -27 -13 -52 -37 -25 -24 -48 -41 -52 -37 -4 4 23 38 60 75 37 38 65 71 61 74 -3 4 -21 4 -40 1 -26 -4 -34 -2 -34 9 0 16 39 28 91 28 15 0 31 5 34 10 4 6 -10 9 -37 8 -24 -1 -82 -4 -130 -5z" />
      </g>
    </svg>
  );
}

export default SexOffenderIcon;
